

<div class="login-container" style="overflow: hidden !important;">

    <img class="align-center" src="./assets/images/logo.png" alt="Global Pos Logo" style="width: 60%; 
  padding-bottom: 40px; text-align: center;">

      <div style="margin-top: -20px;">

        <label for="bLocation" class="label">Language</label>
        <nb-select fullWidth (selectedChange)="changeLocation($event)" [(ngModel)]="DefaultLanguage" name="bLocation" style="width: 94%;">
          <nb-option value="en">English</nb-option>
        </nb-select>
      </div>
<br>
<div class="row ">
  <div class="col-12 ">
  <div class="form-group">
    <input #posInput type="password" 
    [(ngModel)]="objSD.PosPin" (keypress)="numberOnly($event)" (keydown.enter)="Login()" 
    autofocus placeholder="User ID" class="form-control" maxlength="20"  style="width: 94%; border:1px solid gray;" />
    <p class="caption status-danger" *ngIf="showlblPosPin" style=" gray; display:none!important">Please enter Pos Pin</p>
  </div>
  </div></div>
  <!-- <div class="form-group">
      <input type="text" [(ngModel)]="objSD.PosPin" placeholder="User ID" class="form-control"/>
  </div> -->

  <div class="row " >
    <div class="col-3 ">
      <button type="button" value="1" class=" btnsize btn btn-light waves-effect Calculator-button " (click)="Click1()">1</button>
    </div>
    <div class="col-3">
      <button type="button" value="2" class="btnsize btn btn-light waves-effect Calculator-button" (click)="Click2()">2</button>
    </div>
    <div class="col-3">
      <button type="button" value="3" class="btnsize btn btn-light waves-effect Calculator-button" (click)="Click3()">3</button>
    </div>
    <div class="col-3">
      <button type="button" value="4" class="btnsize btn btn-light waves-effect Calculator-button" (click)="Click4()">4</button>
    </div>
  </div>
  <div class="row">

    <div class="col-3">
      <button type="button" value="5" class="btnsize btn btn-light waves-effect Calculator-button" (click)="Click5()">5</button>
    </div>
    <div class="col-3">
      <button type="button" value="6" class="btnsize btn btn-light waves-effect Calculator-button" (click)="Click6()">6</button>
    </div>
    <div class="col-3">
      <button type="button" value="7" class="btnsize btn btn-light waves-effect Calculator-button" (click)="Click7()">7</button>
    </div>
    <div class="col-3">
      <button type="button" value="8" class="btnsize btn btn-light waves-effect Calculator-button" (click)="Click8()">8</button>
    </div>
  </div>

  <div class="row">
    <div class="col-3">
      <button type="button" class="btnsize all-clear function btn btn-danger btn-sm Calculator-button" style="background-color: #c82333;" value="all-clear" (click)="ClickAC()">Clear</button>

    </div>
    <div class="col-3">
      <button type="button" value="9" class="btnsize btn btn-light waves-effect Calculator-button" (click)="Click9()">9</button>
    </div>
    <div class="col-3">
      <button type="button" value="0" class="btnsize btn btn-light waves-effect Calculator-button" (click)="Click0()">0</button>
    </div>
    <div class="col-3">
      <button type="button" class="btnsize decimal function btn btn-secondary Calculator-button" value="." style="background-color: #5a6268;" (click)="ClickDecimal()">Enter</button>
    </div>

  </div>
  <div class="row">
    <div class="col-9">
      <p style="text-align:center; font-weight: bold;">
        {{VersionNo}}
      </p>
    </div>
    <p class="caption status-danger" *ngIf="showlblInvalidComp" style="display:none!important">Please enter Valid Company Name</p>
    <p class="caption status-danger" *ngIf="showlblPin" style="display:none!important">Please enter Valid Pin</p>
    <p class="caption status-danger" *ngIf="showlblInvalidCounter" style="display:none!important">Please enter Valid Counter Name</p>
    <p class="caption status-danger" *ngIf="showlblSubsEndDate">Your subscription has expired, kindly contact the support team!</p>
    <p class="caption status-danger" *ngIf="showlblSubsActive"> Your service is Inactive please contact the support team! </p>
  </div>

  </div>