import { Component } from '@angular/core';
import { DataserviseService } from '../../../shared/dataservice';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilitiesService } from '../../../shared/utilities.service';


@Component({
  selector: 'ngx-a4sizeinvoice',
  templateUrl: './a4sizeinvoice.component.html',
  styleUrls: ['./a4sizeinvoice.component.scss']
})
export class A4sizeinvoiceComponent  {
  ObjCompany: Company = new Company();
  CompanyName: string = '';
  OrderId: string = '';
  InvalidCompanyName: boolean = false;
  ObjOrderMaster: OrderMaster = new OrderMaster();
  ArrOrderDetail: OrderDetail[] = [];
  ObjCurrency: Country = new Country();
  ObjCompanyInfo: CompanyInfo = new CompanyInfo();

  // ObjCompanyInfo: CompanyInfo = new CompanyInfo();
  CurrencyCode: any = '';
  // QRUrl: any = '';
  // elementType = 'img';

  constructor(private router: Router, private route: ActivatedRoute, private dataservice: DataserviseService, private activatedRoute: ActivatedRoute, private Utilities: UtilitiesService)
  {  }

  lstUser = [];
  CompName:any;
  IsFoodCatering: boolean = false;
  InvoiceType: any;
  InvoiceNameEnglish:any;
  InvoiceNameArabic:any;
  ngOnInit(): void {debugger
    // this.CompName = this.Utilities.getURLCompanyName();
    this.InvalidCompanyName = false;
    
    this.activatedRoute.paramMap.subscribe(x => {
      // this.CompanyName = x.get('CompanyName');
      this.OrderId = x.get('id');
      this.InvoiceType = x.get('invType');
      // this.IsFoodCatering = this.IsFoodCateringCopy == null ? false : this.IsFoodCateringCopy;
      
      if(this.InvoiceType=='PO'){
        this.InvoiceNameEnglish='Purchase Order';
        this.InvoiceNameArabic = 'أمر شراء';
      }
      if(this.InvoiceType=='GRN'){
        this.InvoiceNameEnglish='Goods Receiving Notes';
        this.InvoiceNameArabic = 'استلام ملاحظات للبضائع';
      }
      if(this.InvoiceType=='PR'){
        this.InvoiceNameEnglish='Purchase Requisition';
        this.InvoiceNameArabic = 'طلب شراء';
      }
      if(this.InvoiceType=='OS'){
        this.InvoiceNameEnglish='Opening Stock';
        this.InvoiceNameArabic = 'افتتاح المخزون';
      }
      if(this.InvoiceType=='SRN'){
        this.InvoiceNameEnglish='Stock Receiving Note';
        this.InvoiceNameArabic = 'مذكرة استلام المخزون';
      }
      if(this.InvoiceType=='STN'){
        this.InvoiceNameEnglish='Stock Transfer Note';
        this.InvoiceNameArabic = 'مذكرة تحويل المخزون';
      }
      if(this.InvoiceType=='SD'){
        this.InvoiceNameEnglish='Stock Damage';
        this.InvoiceNameArabic = 'ضرر المخزون';
      }
      if(this.InvoiceType=='RP'){
        this.InvoiceNameEnglish='Purchase Return';
        this.InvoiceNameArabic = 'عودة شراء';
      }

      if(this.InvoiceType=='IPC'){
        this.InvoiceNameEnglish='Inventory Physical Count Report';
        this.InvoiceNameArabic = 'تقرير الجرد المادي للمخزون';
      }
      debugger
      if(this.InvoiceType=='RSR'){
        this.InvoiceNameEnglish='Store Requisition Report';
        this.InvoiceNameArabic = 'تقرير طلبات المتجر';
      }
      this.getMasterDetailData(this.OrderId);
      // if (this.CompanyName == null) {
      //   this.InvalidCompanyName = true;
      //   return;
      // }
    });


    
debugger
  this.lstUser = JSON.parse(localStorage.getItem('sessionObject'+this.Utilities.getURLCompanyName()));
  // this.QRUrl = this.Utilities.getQRURL(this.CompanyName, this.OrderId, this.lstUser[0]['CountryCode']);
    // console.log(this.QRUrl);

  }

  printComponent() {
    window.print();
  }
  LoyaltyPoints: any = 0;
  ReturnAmount: any;
  async getMasterDetailData(POID) {
    this.ObjOrderMaster = new OrderMaster();
    this.ArrOrderDetail = [];
    // this._PaymentDetails = [];
debugger
    await this.dataservice.get('Receipt/GetA4InvoiceData/' + this.OrderId + '/' + this.Utilities.getSubscriptionID()+ '/' +this.InvoiceType )
      .subscribe(data => {debugger
        debugger
        if (this.isArrayHasValue(data[0]['CompanyData'])) {
          this.InvalidCompanyName = false;
          this.ObjCompany.CompanyID = data[0]['CompanyData'][0].CompanyID;
          this.ObjCompany.CompanyName = data[0]['CompanyData'][0].CompanyName;
          this.ObjCompany.outletsCount = data[0]['CompanyData'][0].outletsCount;
          this.ObjCompany.CountryID = data[0]['CompanyData'][0].CountryID;
          this.ObjCompany.hearbout = data[0]['CompanyData'][0].hearbout;
          this.ObjCompany.Mobile = data[0]['CompanyData'][0].Mobile;
          this.ObjCompany.IsOTPRequired = data[0]['CompanyData'][0].IsOTPRequired;
          this.ObjCompany.CurrencyCode = data[0]['CompanyData'][0].CurrencyCode;
          this.ObjCompany.Rate = data[0]['CompanyData'][0].Rate;
          this.ObjCompany.NumOfDecimal = data[0]['CompanyData'][0].NumOfDecimal;
          this.ObjCompany.CountryCode = data[0]['CompanyData'][0].CountryCode;
        }
        else {
          this.InvalidCompanyName = true;
          // this.QRUrl = 'Invalid company name!';
          // alert('Invalid company name!');
        }
        if (data[0]['MasterData']) {debugger
          this.ObjOrderMaster = data[0]['MasterData'][0];
          
        }
        if (this.isArrayHasValue(data[0]['DetailData'])) {
          this.ArrOrderDetail = data[0]['DetailData'];
        }
        else {
          this.ArrOrderDetail = [];
        }

        // if (this.isArrayHasValue(data[0]['VoucherData'])) {
          
        //   this.ArrVoucherData = data[0]['VoucherData'];
        // }
        // else {
        //   this.ArrVoucherData = [];
        // }

        if (data[0]['Currency']) {
          this.ObjCurrency = data[0]['Currency'][0];
          this.CurrencyCode = this.ObjCurrency.CurrencyCode;
        }

        if (data[0]['CompanyInfo']) {
          this.ObjCompanyInfo = data[0]['CompanyInfo'][0];
        }

        // if (data[0]['ConsumedPoints'][0] && data[0]['ConsumedPoints'][0].ConsumedPoints != null && data[0]['ConsumedPoints'][0].ConsumedPoints != undefined) {
        //   this.LoyaltyPoints = data[0]['ConsumedPoints'][0].ConsumedPoints;
        // }
        // else {
        //   this.LoyaltyPoints = 0;
        // }
      
        // debugger

        // if (this.isArrayHasValue(data[0]['PaymentDetails'])) {
        //   this._PaymentDetails = data[0]['PaymentDetails'];
        // }
        // if (this.isArrayHasValue(data[0]['ReturnAmount'])) {debugger
        //   this.ReturnAmount = data[0].ReturnAmount[0].CashReturned;
        // }
       

      }, error => alert(this.Utilities.getconnectionmsg()));
  }


  isArrayHasValue(obj: any[]): boolean {
    let isArrayWithLength = Boolean(Array.isArray(obj) && obj.length);
    return isArrayWithLength;
  }

}

// class Company {
//   CompanyID: any;
//   CompanyName: any;
//   outletsCount: any;
//   CountryID: any;
//   hearbout: any;
//   Mobile: any;
//   CurrencyCode: any;
//   Rate: any;
//   NumOfDecimal: any;
//   CountryCode: any;
//   IsOTPRequired: boolean;
// }

class OrderMaster {
  POID: any; POInvoiceNo: any; QuotationRef: any; OrderDate: any; DeliveryDate: any; Description: any; OrderStatus: any; TotalAmount: any; InvoiceDiscount: any; TotalLineDiscount: any; InvoiceTaxAmount: any; TotalLineTaxAmount: any; NetAmount: any; ShippingAmount: any; SupplierID: any; SupplierName: any; ContactPerson: any; Phone: any; MobilePhone: any; Email: any; CityID: any; CountryID: any; Address1: any; Address2: any; AccountNo: any; VatRegistrationNo: any; OutletName: any; ArabicOutletName: any; AreaName: any; outletAddress: any; ArabicArea: any; ArabicAddress: any; OutletPhone; SupplierInvNo: any;
  TotalQty: any;
}

class OrderDetail {
  RowID: any; POID: any; ProductID: any; itemName: any; Quantity: any; Price: any; TaxID: any; TaxAmount: any; LineDiscount: any; TotalAmount: any; PackingId: any; NetAmount: any; RequiredDate: any;
}

class Company {
  CompanyID: any;
  CompanyName: any;
  outletsCount: any;
  CountryID: any;
  hearbout: any;
  Mobile: any;
  CurrencyCode: any;
  Rate: any;
  NumOfDecimal: any;
  CountryCode: any;
  IsOTPRequired: boolean;
}
class Country {
  CurrencyCode: any = '';
  Rate: any = '';
  NumOfDecimal: any = '';
  CountryName: any = '';
  CountryCode: any = '';
}

class CompanyInfo {
  ID: any;
  TextColor: any = '';
  BaseColor: any = '';
  Title: any = '';
  Message: any = '';
  MainImagePath: any = '';
  MainImageName: any = '';
  IconImagePath: any = '';
  IconImageName: any = '';
  LogoImagePath: any = '';
  LogoImageName: any = '';
  BrandName: any = '';
  VATNo: any = '';
  ReceiptMsg: any = '';
}
