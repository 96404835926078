import { Component, OnInit, Inject } from '@angular/core';
import { DataserviseService } from '../../../shared/dataservice';
import { UtilitiesService } from '../../../shared/utilities.service';
import { SpinnerService } from '../../../shared/spinner';
import { PopupService } from '../../../shared/popup';
import { DatePipe } from '@angular/common';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslatePipe } from '../../../shared/pipe/translate.pipe';
import { CashDiscountPopupComponent } from '../cashdiscount-popup/cashdiscount-popup.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'ngx-voucher-popup',
  templateUrl: './voucher-popup.component.html',
  styleUrls: ['./voucher-popup.component.scss']
})
export class VoucherPopupComponent implements OnInit {
  lstVoucher = [];
  ObjvoucherData: voucher = new voucher();
  ObjdiscountVoucher: discountVoucher = new discountVoucher();
  DiscountVoucher: any = this.translate.transform('DiscountVoucher');
  APPLY: any = this.translate.transform('Apply');
  Voucher: any = this.translate.transform('Voucher');
  Subtoal: any;
  showlblExceedAmount: boolean = false;
  UserRoleID: any;
  sessionObjectdemocompany = [];
  OutletID: any;
  ObjOutletInfo: any;
  Total: any;
  lblExceedAmount: any;
  constructor(public dialog: MatDialog, private translate: TranslatePipe, public dialogRef: MatDialogRef<VoucherPopupComponent>, @Inject(MAT_DIALOG_DATA) public parentData: any, private UtilitiesService: UtilitiesService, private dataservice: DataserviseService, private _spinnerService: SpinnerService, private popup: PopupService, private datePipe: DatePipe) { }


  ngOnInit(): void {

    debugger

    if (localStorage.getItem('sessionObject' + this.UtilitiesService.getURLCompanyName()) != null) {
      this.sessionObjectdemocompany = JSON.parse(localStorage.getItem('sessionObject' + this.UtilitiesService.getURLCompanyName()));
      this.UserRoleID = this.sessionObjectdemocompany[0][0]["GroupID"];
    }
    this._spinnerService.show();
    this.Subtoal = this.parentData["Subtoal"];
    this.OutletID = this.parentData["OutletID"];
    this.ObjOutletInfo = this.parentData["ObjOutletInfo"];
    this.Total = this.parentData["Total"];
    this.dataservice.get('Pos/GetVoucher/' + this.UtilitiesService.getSubscriptionID() + '/' + this.datePipe.transform(this.UtilitiesService.GetCurrentDateTime(), 'yyyy-MM-dd') + '/' + this.UserRoleID + '/' + this.OutletID).subscribe(data => {
      this.lstVoucher = data;
      console.log(this.lstVoucher);
      if (this.isArrayHasValue(this.lstVoucher)) {
        this.ObjvoucherData.DiscVoucherID = this.lstVoucher[0].DiscVoucherID;
        this.ObjvoucherData.PromoCode = this.lstVoucher[0].VoucherCode;

        this.ObjdiscountVoucher.DiscVoucherID = this.lstVoucher[0].DiscVoucherID;
        this.ObjdiscountVoucher.VoucherCode = this.lstVoucher[0].VoucherCode;
        this.ObjdiscountVoucher.VoucherDesc = this.lstVoucher[0].VoucherDesc;
        this.ObjdiscountVoucher.IsPercent = this.lstVoucher[0].IsPercent;
        this.ObjdiscountVoucher.Amount = this.lstVoucher[0].Amount;
        this.ObjdiscountVoucher.MinOrderValue = this.lstVoucher[0].MinOrderValue;
        this.ObjdiscountVoucher.UsagePerUser = this.lstVoucher[0].UsagePerUser;
        this.ObjdiscountVoucher.UsagePerVoucher = this.lstVoucher[0].UsagePerVoucher;
        this.ObjdiscountVoucher.VoucherExpiryDate = this.lstVoucher[0].VoucherExpiryDate;
        this.ObjdiscountVoucher.OutletIds = this.lstVoucher[0].OutletIds;
        this.ObjdiscountVoucher.Status = this.lstVoucher[0].Status;
        this.ObjdiscountVoucher.CompanyId = this.lstVoucher[0].CompanyId;


      }
      this._spinnerService.hide();
    })
  }

  // VoucherDropdown() {
  // }

  btnclose(): void {
    debugger
    this.dialogRef.close();
  }

  applyVoucher() {
    debugger
    if (!this.showlblExceedAmount)
      this.dialogRef.close(this.ObjdiscountVoucher);
  }



  changeVoucher(event): void {
    debugger
    this.ObjvoucherData.DiscVoucherID = event.DiscVoucherID;
    this.ObjvoucherData.Amount = event.Amount;
    this.ObjvoucherData.IsPercent = event.IsPercent;

    this.ObjdiscountVoucher.DiscVoucherID = event.DiscVoucherID;
    this.ObjdiscountVoucher.VoucherCode = event.VoucherCode;
    this.ObjdiscountVoucher.VoucherDesc = event.VoucherDesc;
    this.ObjdiscountVoucher.IsPercent = event.IsPercent;
    this.ObjdiscountVoucher.Amount = event.Amount;
    this.ObjdiscountVoucher.MinOrderValue = event.MinOrderValue;
    this.ObjdiscountVoucher.UsagePerUser = event.UsagePerUser;
    this.ObjdiscountVoucher.UsagePerVoucher = event.UsagePerVoucher;
    this.ObjdiscountVoucher.VoucherExpiryDate = event.VoucherExpiryDate;
    this.ObjdiscountVoucher.OutletIds = event.OutletIds;
    this.ObjdiscountVoucher.Status = event.Status;
    this.ObjdiscountVoucher.CompanyId = event.CompanyId;

    if (this.ObjdiscountVoucher.IsPercent == false) {
      this.ObjdiscountVoucher.Amount = 0;
      this.CalcPopup();
    }


  }

  CalcPopup() {

    const dialogRef = this.dialog.open(CashDiscountPopupComponent, {
      width: '430px',
      height: '450px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      debugger


      if (result != undefined) {
        this.ObjdiscountVoucher.Amount = parseFloat(result);

        if (this.ObjOutletInfo.IsTaxInclusive == false && this.ObjdiscountVoucher.Amount > this.Subtoal) {
          debugger
          this.lblExceedAmount = "Discount Amount Exceed from SubTotal Amount";
          this.showlblExceedAmount = true;
          return
        }
        else if (this.ObjOutletInfo.IsTaxInclusive == true && this.ObjdiscountVoucher.Amount > this.Total) {
          debugger
          this.lblExceedAmount = "Discount Amount Exceed from Total Amount";
          this.showlblExceedAmount = true;
          return
        }
        else {
          this.ObjdiscountVoucher.Amount = parseFloat(result);
          this.lblExceedAmount = "";
          this.showlblExceedAmount = false;
        }
        //if (this.ObjdiscountVoucher.Amount > this.Subtoal) {
        //  this.showlblExceedAmount = true;
        //}
        //else {
        //  this.showlblExceedAmount = false;
        //}
      }
      else {
      }

    });
  }

  //onValueChange(event){
  //  debugger
  //  if(this.ObjdiscountVoucher.Amount > this.Subtoal){debugger
  //    this.showlblExceedAmount = true;
  //    return
  //  }
  //  this.ObjdiscountVoucher.Amount = event;
  //  this.showlblExceedAmount = false;

  //}
  onValueChange(event) {
    debugger
    if (this.ObjOutletInfo.IsTaxInclusive == false && this.ObjdiscountVoucher.Amount > this.Subtoal) {
      debugger
      this.lblExceedAmount = "Discount Amount Exceed from SubTotal Amount";
      this.showlblExceedAmount = true;
      return
    }
    else if (this.ObjOutletInfo.IsTaxInclusive == true && this.ObjdiscountVoucher.Amount > this.Total) {
      debugger
      this.lblExceedAmount = "Discount Amount Exceed from Total Amount";
      this.showlblExceedAmount = true;
      return
    }
    else {
      this.ObjdiscountVoucher.Amount = event;
      this.showlblExceedAmount = false;
    }
  }
  isArrayHasValue(obj: any[]): boolean {
    let isArrayWithLength = Boolean(Array.isArray(obj) && obj.length);
    return isArrayWithLength;
  }
}

export class voucher {
  DiscVoucherID: any;
  Amount: any;
  IsPercent: any;
  PromoCode: any
}
export class discountVoucher {
  DiscVoucherID: any;
  VoucherCode: any;
  VoucherDesc: any;
  IsPercent: any;
  Amount: any;
  MinOrderValue: any;
  UsagePerUser: any;
  UsagePerVoucher: any;
  VoucherExpiryDate: any;
  OutletIds: any;
  Status: any;
  CompanyId: any;
}
