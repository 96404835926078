import { Component, OnInit, Inject} from '@angular/core';
import { DataserviseService } from '../../../shared/dataservice';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PopupService } from '../../../shared/popup';
import { UtilitiesService } from '../../../shared/utilities.service';
import { DecimalPipe } from '@angular/common';
import { TranslatePipe } from '../../../shared/pipe/translate.pipe';

@Component({
  selector: 'ngx-holdorderpopup',
  templateUrl: './holdorderpopup.component.html',
  styleUrls: ['./holdorderpopup.component.scss']
})
export class HoldOrderPopupComponent implements OnInit {

  lstarray = [];
  CompanyId: any;
  OutletId: any;
  POSID: any;
  culture: any = 'en';
  ShiftID: any;
  DayID: any;
  NumOfDecimal: any;
  HoldOrders: any = this.translatePipe.transform('HoldOrders');
  IsVoidbtnActive: any;

  constructor(private _DecimalPipe: DecimalPipe, private translatePipe: TranslatePipe, private dataservice: DataserviseService, public dialogRef: MatDialogRef<HoldOrderPopupComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private Utilities: UtilitiesService, private popup: PopupService)
  {
    debugger
    this.CompanyId = this.Utilities.getSubscriptionID();

    this.OutletId = this.data.OutletId;
    this.POSID = this.data.POSID;
    this.ShiftID = this.data.ShiftID;
    this.DayID = this.data.DayID;
    this.NumOfDecimal = this.data.NumOfDecimal;
    this.IsVoidbtnActive = this.data.IsVoidbtnActive;
  }

  
  ngOnInit(): void {
  debugger 
    this.LoadGrid();

  }

  settings = {
    pager: {
      display: true,
      perPage: 15
    },
    actions: {
      columnTitle: this.translatePipe.transform('Actions'),
      add: false,
      edit:false
    },
    mode: 'external',

    
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
      confirmDelete: true
    },
  
    columns: {
      ReferenceNo: {
        title: this.translatePipe.transform('ReferenceNo'),
        type: 'string',
      },
      OrderDate: {
        title: this.translatePipe.transform('OrderDate'),
        type: 'string',
      }, 

      TotalAmount: {
        title: this.translatePipe.transform('TotalAmount'),
        type: 'html',
        valuePrepareFunction: (value, row, cell) => {
          return this._DecimalPipe.transform(cell.row.data.TotalAmount, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal);
        },
      },
      TotalTaxAmount: {
        title: this.translatePipe.transform('TotalTaxAmount'),
        type: 'html',
        valuePrepareFunction: (value, row, cell) => {
          return this._DecimalPipe.transform(cell.row.data.TotalTaxAmount, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal);
        },
      },
      TotalDiscount: {
        title: this.translatePipe.transform('TotalDiscount'),
        type: 'html',
        valuePrepareFunction: (value, row, cell) => {
          return this._DecimalPipe.transform(cell.row.data.TotalDiscount, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal);
        },
      },
      NetAmount: {
        title: this.translatePipe.transform('NetAmount'),
        type: 'html',
        valuePrepareFunction: (value, row, cell) => {
          return this._DecimalPipe.transform(cell.row.data.NetAmount, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal);
        },
      },
    },
    attr: {
      class: 'table table-bordered handicursor',
    },
    hideSubHeader: false,
  };

  selectedRows: any = [];


  AddModifier(): void {debugger
    this.dialogRef.close(this.selectedRows);
  }

  LoadGrid() {
    debugger
    this.lstarray = [];

    this.dataservice.get('retailpos/GetHoldOrderLookup/' + this.CompanyId + '/' + this.OutletId + '/' + this.POSID + '/' + this.ShiftID + '/' + this.DayID).subscribe(data => {
    debugger
      if (this.isArrayHasValue(data[0].HoldOrders)) {debugger
        this.lstarray = data[0].HoldOrders;
      }
    })
  }

  isArrayHasValue(obj: any[]): boolean {
    let isArrayWithLength = Boolean(Array.isArray(obj) && obj.length);
    return isArrayWithLength;
  }



  onUserRowSelect(event): void {
    debugger;

    this.dialogRef.close(event.data);

  }

  btnclose(): void {
    debugger
    this.dialogRef.close(undefined);
  }

  deletemsg: any;
  rowDelete(event) {
    if (this.IsVoidbtnActive == false) {
      this.popup.ShowPopupCustom('danger', 'User doesn`t have permission to delete a record.');
      return;
    }

    if (window.confirm('Are you sure you want to delete?')) {
      this.dataservice.get('retailpos/DeleteHoldOrder/' + event.data.OrderID).subscribe(data => {
        if (this.isArrayHasValue(data[0]['Result'])) {
          debugger
          this.deletemsg = data[0]["Result"];
          if (this.deletemsg[0].Column1 == 'succ') {
            this.popup.ShowPopup('Delete');
            this.LoadGrid();
          } else {
            this.popup.ShowPopup('Error');
          }

        }
      })
    }

  }
}
