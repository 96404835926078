
import { Component, OnInit, Inject } from '@angular/core';
import { DataserviseService } from '../../shared/dataservice';
import { PopupService } from '../../shared/popup';
import { UtilitiesService } from '../../shared/utilities.service';
import { SpinnerService } from '../../shared/spinner';
import { OrderListPopupComponent } from '../../pages/ordermanagements/orderlistpopup/orderlistpopup.component';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslatePipe } from '../../shared/pipe/translate.pipe';
import { DecimalPipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { SafeResourceUrl } from '@angular/platform-browser';



@Component({
  selector: 'ngx-salereturnorderreturnpopuop',
  templateUrl: './salereturnorderreturnpopuop.component.html',
  styleUrls: ['./salereturnorderreturnpopuop.component.scss'],

})


export class SaleReturnOrderReturnComponent implements OnInit {

  ArrPaidOrders: SaleReturnOrders[] = [];
  ObjSearchFilters: SearchFilters = new SearchFilters();


  OrderNo: any = this.translate.transform('OrderNo');
  PhoneNo: any = this.translate.transform('PhoneNo');
  FromDate: any = this.translate.transform('FromDate');
  ToDate1: any = this.translate.transform('ToDate');
  Search: any = this.translate.transform('Search');
  PaidOrderslist: any = this.translate.transform('PaidOrderslist');
  PaidOrders: any = this.translate.transform('PaidOrders');
  OrderDate: any = this.translate.transform('OrderDate');
  Invaliddate: any = this.translate.transform('Invaliddate');

  SaleReturnList: any = this.translate.transform('SaleReturnList');
  CreditNoteNo: any = this.translate.transform('CreditNoteNo');

  CompanyId: any;
  CreatedBy: any = '';
  CreatedOn: any = '';
  lstDetail: any = [];
  NumOfDecimal: any;
  companyName:any;
  CountryCode: any;
  constructor(private sanitizer: DomSanitizer, private router: Router, private translate: TranslatePipe, public dialogRef: MatDialogRef<SaleReturnOrderReturnComponent>, @Inject(MAT_DIALOG_DATA) public parentData: any, public dialog: MatDialog, private _spinnerService: SpinnerService, private Utilities: UtilitiesService, private dataservice: DataserviseService, private popup: PopupService, private _DecimalPipe: DecimalPipe) { }

  ngOnInit(): void {
    debugger
    this.CompanyId = this.Utilities.getSubscriptionID();
    this.CreatedBy = this.Utilities.getUserId();
    this.CreatedOn = this.Utilities.GetCurrentDateTime();

    this.ObjSearchFilters.OutletId = this.parentData.OutletId;
    this.NumOfDecimal = this.parentData.NumOfDecimal;

    this.companyName = JSON.parse(localStorage.getItem('counterID&CompanyID' + this.Utilities.getURLCompanyName()))[0].CompanyName;
    this.CountryCode = JSON.parse(localStorage.getItem('counterID&CompanyID' + this.companyName))[0].CountryCode;
    this.getGridData();

  }

  showPerPage = 5;
  settings = {
    
    attr: {
      class: 'ng2-smart-row',
    },
    pager: {
      display: true,
      perPage: 15
    },
    actions: {
      columnTitle: this.translate.transform('Actions'),
      add: false,
    },
    mode: 'external',

    edit: {
      editButtonContent: '<i class="fa">&#xf02f;</i>',
      confirmEdit: true,
    },
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
      confirmDelete: true
    },

    columns: {
     
      ReturnInvoice: {
        title: this.translate.transform('CreditNoteNo'),
      },
      ReturnDate: {
        title: this.translate.transform('CreditNoteDate'),
      },
      Customer: {
        title: this.translate.transform('CustomerName'),
      },
      PhoneNo: {
        title: this.translate.transform('TelephoneNo'),
      },
      PaidBy: {
        title: this.translate.transform('PayType'),
      },
      NetAmount: {
        title: this.translate.transform('Amount'),
        type: 'html',
        valuePrepareFunction: (value, row, cell) => {
          return this._DecimalPipe.transform(cell.row.data.NetAmount, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal);
        },
      },

    },
  
    hideSubHeader: false,
  };


  rowEdit(event) {

    this.ReceiptURL = this.Utilities.getSaleReturnReceiptURL(this.Utilities.getEncrypt(this.Utilities.getCompanyName()), this.Utilities.getEncrypt(event.data.ReturnID), this.CountryCode);

    this.checkIframeLoaded();

  }
  ReceiptURL: any = '';
  async checkIframeLoaded() {
    debugger
    // Get a handle to the iframe element
    var iframe = document.getElementById('saleretReceipt') as HTMLIFrameElement;
    var iframeDoc = iframe.contentDocument || iframe.contentWindow.document;

    // Check if loading is complete
    if (iframeDoc.readyState == 'complete') {
      //iframe.contentWindow.alert("Hello");
      //await iframe.contentWindow.print();
      // The loading is complete, call the function we want executed once the iframe is loaded
      await this.onLoad();
     
      return false;
    }

    // If we are here, it is not loaded. Set things up so we check   the status again in 100 milliseconds
    window.setTimeout(this.checkIframeLoaded, 1000);
  }
  onLoad() {
 
    if (this.ReceiptURL != '') {
      setTimeout(() => {
        window.frames["saleretReceipt"].focus();
        window.frames["saleretReceipt"].print();
      }, 1800);
     

    }
  }


  FormRightsvalue = [];
  formRightsmessage: boolean = false;
  message: any;

  checkRights(operation): boolean {
    this.formRightsmessage = false;

    this.FormRightsvalue = this.dataservice.checkFormRights('SaleReturn', operation);
    if (this.FormRightsvalue[0] == false) {
      this.formRightsmessage = true;
      this.message = this.FormRightsvalue[1];
    }

    return this.formRightsmessage;
  }

  deletemsg: any;
  rowDelete(event) {
    if (this.checkRights('Del'))
      return;

    if (window.confirm('Are you sure you want to delete?')) {
      this.dataservice.get('Utilities/DeleteMaster/' + event.data.ReturnID + '/' + this.Utilities.getUserId() + '/' + 'salereturn').subscribe(data => {
        if (this.isArrayHasValue(data[0]['msg'])) {
          debugger
          this.deletemsg = data[0]["msg"];
          if (this.deletemsg[0].Result == 'succ') {
            this.popup.ShowPopup('Delete');
            this.getGridData();
          } else {
            this.popup.ShowPopup('Error');
          }

        }
      })
    }

  }


  InvalidDateFrom: boolean = false;
  InvalidDateTo: boolean = false;

  _DateFrom: Date = new Date();
  _DateTo: Date = new Date();

  getGridData(): void {
    this._spinnerService.show();

    this.ObjSearchFilters.CompanyId = this.Utilities.getSubscriptionID();
    this.ObjSearchFilters.Channel = 'POS';

    this.ObjSearchFilters.DateFrom = this.Utilities.getCalenderDate(this._DateFrom);
    this.ObjSearchFilters.DateTo = this.Utilities.getCalenderDate(this._DateTo);
    this.ObjSearchFilters.culture = sessionStorage.getItem('LanguageCulture');


    this.dataservice.post('pos/GetSaleRetun/', this.ObjSearchFilters)
      .subscribe(data => {
        debugger
        if (this.isArrayHasValue(data[0]['SaleOrders'])) {
          this.ArrPaidOrders = data[0]['SaleOrders'];
        } else {
          this.ArrPaidOrders = [];
        }


        this._spinnerService.hide();
      }, error => alert('error'));

  }

  onUserRowSelect(event): void {
    debugger
    if (this.checkRights('Edit'))
      return;

    this.dialogRef.close(event);

  }

  isArrayHasValue(obj: any[]): boolean {
    let isArrayWithLength = Boolean(Array.isArray(obj) && obj.length);
    return isArrayWithLength;
  }
}

class SaleReturnOrders {
  ReturnID: any;
  ReturnInvoice: any;
  OrderID: any;
  ReturnDate: any;
  Customer: any;
  PayType: any;
  PhoneNo: any;
  NetAmount: any;
}
class SearchFilters {
  CompanyId: any;
  Channel: any = '';
  Id: any = '';
  PhoneNo: any = '';
  DateFrom: any = new Date();
  DateTo: any = new Date();
  culture: any;
  OutletId: any;
}
