import { Component, OnInit,Inject } from '@angular/core';
import { DataserviseService } from '../../shared/dataservice';
import { TranslatePipe } from '../../shared/pipe/translate.pipe';
import { PopupService } from '../../shared/popup';
import { SpinnerService } from '../../shared/spinner';
import { UtilitiesService } from '../../shared/utilities.service';
import { MatDialogRef, MAT_DIALOG_DATA,MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'ngx-receiptpopup',
  templateUrl: './receiptpopup.component.html',
  styleUrls: ['./receiptpopup.component.scss']
})
export class ReceiptpopupComponent implements OnInit {
  ObjGridFilterData: filterdata = new filterdata();
  lstReceiptData=[];

  ReceiptNo : any = this.translate.transform('ReceiptNo');
  Search : any = this.translate.transform('Search');
  SearchReceipt : any = this.translate.transform('SearchReceipt');

  constructor( private translate : TranslatePipe,private _spinnerService: SpinnerService, private Utilities: UtilitiesService, private dataservice: DataserviseService, private popup: PopupService, public dialog: MatDialog,public dialogRef: MatDialogRef<ReceiptpopupComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
  }

  getGridData(): void {debugger
    if((this.ObjGridFilterData.ReceiptNo ==undefined || this.ObjGridFilterData.ReceiptNo == '') && (this.ObjGridFilterData.PhoneNo == undefined ||this.ObjGridFilterData.PhoneNo == ''))
    return
    this.ObjGridFilterData.CompanyId = this.Utilities.getSubscriptionID();
    this._spinnerService.show();
    this.dataservice.post('CustomerReceipt/GetFilterCustomerReceiptGridData/', this.ObjGridFilterData)
      .subscribe(data => {debugger
        if (this.isArrayHasValue(data[0]['lstCustomerReceipt'])) {
          this.lstReceiptData = data[0]['lstCustomerReceipt'];
        } 
        else {
          this.lstReceiptData = [];
        }
       
        this._spinnerService.hide();
      }, error => alert('error'));

  }


  public onUserRowSelect(event) {debugger
    var customerRow = {
      ReceiptID: event.data.ReceiptID,
    }
    let body = [customerRow]
    this.dialogRef.close(body);
  }

  btnclose(){debugger
    this.dialogRef.close();
  }


  isArrayHasValue(obj: any[]): boolean {
    let isArrayWithLength = Boolean(Array.isArray(obj) && obj.length);
    return isArrayWithLength;
  }

  settings = {
    actions: {
      columnTitle: '',
      add: false,
      edit: false,
      delete:false
    },
    mode:'external',   
    pager:{
      display: false,
      perPage: 5,
    },
    edit:{
      editButtonContent: '<i class="nb-edit"></i>',
    },
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',  
      confirmDelete: true,
    },
    columns: {
      ReceiptNo: {
        title: this.translate.transform('ReceiptNo'),
      },
      Note: {
        title: this.translate.transform('Note'),
      },
      Balance: {
        title: this.translate.transform('Balance'),
      },
      PaymentType: {
        title: this.translate.transform('PaymentType'),
      },

    },
    attr: {
      class: 'table table-bordered',
    },
    hideSubHeader: true,
  };

}


class filterdata{
  ReceiptNo:any;
  PhoneNo:any;
  CompanyId:any;
  branchId:any;
}
