import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span style="color:#9fa2ab!important;font-size:0.8375rem!important">
      Powered by <b><a href=" https://globalinformix.com//" target="_blank" style="color:#9fa2ab!important;color: #9fa2ab;text-decoration: unset;">Global Informix,</a></b> 2022
    </span>

  `,
})
export class FooterComponent {
}

// <div class="socials">
// <a href="#" target="_blank" class="ion ion-social-github"></a>
// <a href="#" target="_blank" class="ion ion-social-facebook"></a>
// <a href="#" target="_blank" class="ion ion-social-twitter"></a>
// <a href="#" target="_blank" class="ion ion-social-linkedin"></a>
// </div>
