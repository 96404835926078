import { Component, OnInit ,Inject} from '@angular/core';
import { DataserviseService } from '../../../shared/dataservice';
import { UtilitiesService } from '../../../shared/utilities.service';
import { SpinnerService } from '../../../shared/spinner';
import { PopupService } from '../../../shared/popup';
import { DatePipe } from '@angular/common';
import { MatDialogRef,MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { TranslatePipe } from '../../../shared/pipe/translate.pipe';
import { AboutUsComponent } from '../../../about-us/about-us.component';

@Component({
  selector: 'ngx-holdorderconfirmpopup',
  templateUrl: './holdorderconfirmpopup.component.html',
  styleUrls: ['./holdorderconfirmpopup.component.scss']
})
export class HoldOrderConfirmPopupComponent implements OnInit {

  ReferenceNo:any;
  body: any;

  lblReferenceNo: any = this.translate.transform('ReferenceNo');
  lblYes: any = this.translate.transform('Yes');
  lblNo: any = this.translate.transform('No');
  lblHoldOrder: any = this.translate.transform('HoldOrder');
  OrderFrom: any;


  constructor(private dialog: MatDialog, private translate: TranslatePipe, public dialogRef: MatDialogRef<HoldOrderConfirmPopupComponent>, @Inject(MAT_DIALOG_DATA) public parentData: any,private UtilitiesService: UtilitiesService,private dataservice: DataserviseService,private _spinnerService: SpinnerService,private popup: PopupService, private datePipe: DatePipe) { }


  ngOnInit(): void {

    debugger
    this.body = this.parentData.body;
    this.ReferenceNo = this.parentData.ReferenceNo;
    this.OrderFrom = this.parentData.OrderFrom;
  }

  val: any;
  btnclose() : void
  {
    debugger
    this.dialogRef.close(this.val);
  }

  SaveOrder(): any {
    if (this.OrderFrom == 'restaurant') {
      this.SaveholdOrder();
    }
    else {
      this.HoldOrder();
    }
  }

  HoldOrder() {
    debugger
    this._spinnerService.show();
    this.body[0].ReferenceNo = this.ReferenceNo;

    //let body = [this.ObjRetailMaster, this.ArrRetailDetail, lstPaymentDetail, lstVoucherPaymentDetail, this.Utilities.getUserId(), this.Utilities.getSubscriptionID(), this.ObjRetailMaster.OutletID, this.ObjRetailMaster.POSID, CurrentDate]
    this.dataservice.post('retailpos/HoldOrderData/', this.body).subscribe(data => {
      debugger
      if (data['Table'][0].msg == 'succ') {
        this.val = 'succ';
        let message = 'holdorder';// + data['Table'][0].OrderInvoiceNo;
        let dialogRef = this.dialog.open(AboutUsComponent,
          {
            width: '500px',
            height: '200px',

            data: { message },
          });

      } else {
        alert('Error when hold order. please try again')
      }
      this.dialogRef.close(this.val);
      this._spinnerService.hide();
    });
  }

  SaveholdOrder() {
    this._spinnerService.show();
    this.body.push({ ReferenceNo: this.ReferenceNo})

    this.dataservice.post('pos/SaveHoldOrder/', this.body).subscribe(data => {
      debugger
   
      if (this.isArrayHasValue(data['Table'])) {
        let msg = data['Table'][0].msg;

        if (parseInt(msg) > 0) {
          this.val = 'succ';
          let message = 'holdorder';
          let dialogRef = this.dialog.open(AboutUsComponent,
            {
              width: '500px',
              height: '200px',

              data: { message },
            });

        } else {
          alert('Error when hold order or session closed. please try again')
        }

      }
      this._spinnerService.hide();
      this.dialogRef.close(this.val);
     
    });

  }

  isArrayHasValue(obj: any[]): boolean {
    let isArrayWithLength = Boolean(Array.isArray(obj) && obj.length);
    return isArrayWithLength;
  }
}

