<link href="//stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet">
<div class="child1" style="max-width: 100% !important ;">
  {{lblProducts}}
  <img src="./assets/images/lookupclose.png" (click)="btnclose()" style="float: right; width: 25px;cursor: pointer;">
</div>
<hr />
<div class="table-responsive">
  <ng2-smart-table [settings]="settings" (userRowSelect)="onUserRowSelect($event)" [source]="lstarray"></ng2-smart-table>
</div>


