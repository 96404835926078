

<div class="child1" style="max-width: 100% !important ;">{{SelectTables}}
    <img src="./assets/images/lookupclose.png" (click)="btnclose()" style="float: right; width: 25px;cursor: pointer; outline: none;" tabindex="1">
  </div>
  <hr style="margin-top: 10px;"/>
  
  
  <nb-tabset  style="overflow-x: auto;overflow-y: hidden;padding-bottom: 5px;" (changeTab)="onChangeTab($event)" tabindex="2">
    <nb-tab *ngFor="let data of lstTableGroupInfo;let i = index" tabTitle="{{cultures == 'en' ? data.Section : data.ArabicSection}}" 
    active="{{data.ID == SectionID}}" >  </nb-tab> 
  </nb-tabset>
  
  <div class="col-lg-12" style="padding-top: 20px;">
  <div class="row" >
    <div class="col-md-3 mb-2" *ngFor="let row of lstDineinTableInfo;">
      <div class="card-body" [ngClass]="{'cardGree' : row.Status == 0  ,'cardRed':row.Status == 1 , 'Red': row.Status > 1}" (click)="ClickTable(row,row.Status)">          
        <h5>{{row.TableName}}</h5>
       <div [ngClass]=" row.Status == '0' ? 'FreetableAmount' : 'BookedtableAmount'" > {{TotalAmount}}: {{row.Netamount | number:'1.'+NumOfDecimal}}</div>
       <div [ngClass]=" row.Status == '0' ? 'FreetableAmount' : 'BookedtableAmount'">{{row.UserName}}</div>
        </div>
    </div>
  </div>
  </div>
  
  
  