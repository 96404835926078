import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { debug } from 'console';
@Component({
  selector: 'ngx-alert-popup',
  templateUrl: './alert-popup.component.html',
  styleUrls: ['./alert-popup.component.scss']
})
export class AlertPopupComponent implements OnInit {
  lbl: any = 'please select some items first!';
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    debugger;
    if (this.data != null)
      this.lbl = this.data.data;
    else
      this.lbl = 'please select some items first!';
  }
  ngOnInit(): void {
    debugger;
    //if (this.lbl == '')
    //  this.lbl = 'please select some items first!';

  }

}
