import { Component, OnInit, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslatePipe } from '../../shared/pipe/translate.pipe';

@Component({
  selector: 'ngx-modifier-popup',
  templateUrl: './modifier-popup.component.html',
  styleUrls: ['./modifier-popup.component.scss']
})
export class ModifierPopupComponent implements OnInit {

  constructor(private translate: TranslatePipe, public dialogRef: MatDialogRef<ModifierPopupComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {

  }
  lstData: any = [];
  lstModifier: any = [];
  lstSelectedProduct: any = [];
  ArrProductSize: any = [];
  ProductPrice: any;
  AddtoBasket : any = this.translate.transform('AddtoBasket');
  ObjCompany: Company = new Company();
  ObjResturentInfo: ResturentInfo = new ResturentInfo();
  cultures:any;
  lstBasket=[];
  ngOnInit(): void {
    debugger;
    this.lstData = this.data["lstData"];
    this.lstModifier = this.data["lstModifier"];
    this.lstSelectedProduct = this.data["lstSelectedProduct"];
    this.ArrProductSize = this.data["lstProductSize"];
    this.cultures = this.data["culture"];
    this.lstBasket = this.data["lstBasket"];

    this.lstSelectedProduct.ProductSizeName = this.ArrProductSize[0].Description;
    this.lstSelectedProduct.sizeProductName = this.lstSelectedProduct.ProductName +'('+ this.ArrProductSize[0].Description +')';
    this.lstSelectedProduct.ProductSizeID = this.ArrProductSize[0].ProductSizeID;
    this.lstSelectedProduct.ProductDetailID = this.ArrProductSize[0].ProductDetailID;
    if (sessionStorage.getItem('ObjCompany') != null && sessionStorage.getItem('ObjCompany') != undefined) {
      this.ObjCompany = JSON.parse(sessionStorage.getItem('ObjCompany'));
    }

    if (sessionStorage.getItem('ResturentInfo') != null && sessionStorage.getItem('ResturentInfo') != undefined) {
      this.ObjResturentInfo = JSON.parse(sessionStorage.getItem('ResturentInfo'));
    }
    this.lstSelectedProduct = this.data.lstSelectedProduct;
  }

  ValidationOn: boolean = false;
  lstSelectedModifier: any = [];
  PrevoiusVal: any = 0;
  ModifierPrice: number = 0;
  AddToBasket(_objProduct): void {debugger
     //let rowid = Math.floor(100000 + Math.random() * 900000);
    let rowid;
    if (this.lstBasket.length > 0) {
      const lastElement = this.lstBasket[this.lstBasket.length - 1];
      rowid = lastElement.rowid+1;
    } else {
      rowid = 1;
    }

    this.ValidationOn = false;
    for (var i = 0; i < this.lstData.length; i++) {
      if (this.lstData[i].IsSelected == false && this.lstData[i].InputType == 'rd') {
        this.lstData[i].class = 'required-label--error';
        this.ValidationOn = true;
      } 
      else {
        this.lstData[i].class = 'required-label';
      }
    }

    if (this.ValidationOn) {
      for (var i = 0; i < this.lstData.length; i++) {
        if (this.lstData[i].IsSelected == false && this.lstData[i].InputType == 'rd') {
          this.scroll(this.lstData[i].ListId);
          return;
        }
      }
      return;
    }
    debugger

    for (var i = 0; i < this.lstModifier.length; i++) {
      if (this.lstModifier[i].IsSelected == true) {
        this.lstSelectedModifier.push({
          'AllowMultipleSeletion': this.lstModifier[i].AllowMultipleSeletion,
          'DetailId': this.lstModifier[i].DetailId,
          'ModifierId': this.lstModifier[i].ModifierId,
          'IsSelected': this.lstModifier[i].IsSelected,
          'ListName': this.lstModifier[i].ListName,
          'MasterId': this.lstModifier[i].MasterId,
          'MaxModifiers': this.lstModifier[i].MaxModifiers,
          'MinModifiers': this.lstModifier[i].MinModifiers,
          'Modifires': this.lstModifier[i].Modifires,
          'Price': this.lstModifier[i].Price,
          'PriceStatic': this.lstModifier[i].PriceStatic,
          'ProductID': this.lstModifier[i].ProductID,
          'rowid': rowid,
          'AName': this.lstModifier[i].AName,
          'LoyaltyPoints': this.lstModifier[i].LoyaltyPoints,
          'IsBogo': this.lstModifier[i].IsBogo,
          'PriceWithOutVAT': null,
          'TaxAmount': null,
          'ProductDiscount': null
        })
      }
      //else {

      //  this.lstSelectedModifier.push({
      //    'AllowMultipleSeletion': null,
      //    'DetailId': null,
      //    'ModifierId': null,
      //    'IsSelected': null,
      //    'ListName': null,
      //    'MasterId': null,
      //    'MaxModifiers': null,
      //    'MinModifiers': null,
      //    'Modifires': null,
      //    'Price': null,
      //    'PriceStatic': null,
      //    'ProductID': null,
      //    'rowid': null,
      //    'AName': null,

      //  })
      //}
    }
    var array = {
      "selectedmodifiers": this.lstSelectedModifier,
      "selectedproduct": this.lstSelectedProduct,
      "rowid": rowid
    }
    this.dialogRef.close(array);

  }
  scroll(id) {
    document.querySelector('#' + id + '').scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
  rdModifierSelected(lst, rdval): void {
    debugger
    this.lstModifier.forEach((item, index) => {
      if (item.MasterId == parseInt(rdval.target.value))
        this.lstModifier[index].IsSelected = 0;
    })
    // this.lstSelectedProduct.sizeProductName = lst.ListName +'('+ lst.Modifires +')';
    if (lst.Price == null || lst.Price == undefined || lst.Price == '') {
      this.lstModifier.find(item => item.DetailId == lst.DetailId).IsSelected = true;
      this.lstData.find(item => item.MasterId == lst.MasterId).IsSelected = true; //for validation only
      return;
    }


    this.lstModifier.find(item => item.DetailId == lst.DetailId).IsSelected = true;
    this.lstData.find(item => item.MasterId == lst.MasterId).IsSelected = true; //for validation only
    if (this.PrevoiusVal > 0) {

      //this.ProductPriceInBasket -= this.PrevoiusVal * this.Quantity;
      //this.ProductPriceInBasketDiscounted -= this.PrevoiusVal * this.Quantity;

      //let modifierPrice = lst.Price * this.Quantity;
      //this.ProductPriceInBasket += modifierPrice;
      //this.ProductPriceInBasketDiscounted += modifierPrice;
      ////this.ProductPriceInBasket += parseInt(lst.Price);
      this.ModifierPrice = parseFloat(lst.Price);
    }
    else {
      if (lst.Price != null && lst.Price != undefined && lst.Price != '') {
        //this.ProductPriceInBasket += parseInt(lst.Price);
        //let modifierPrice = lst.Price * this.Quantity;
        //this.ProductPriceInBasket += modifierPrice;
        //this.ProductPriceInBasketDiscounted += modifierPrice;
        this.ModifierPrice = parseFloat(lst.Price);
      }

    }

    if (lst.Price != null && lst.Price != undefined && lst.Price != '') {
      this.PrevoiusVal = parseFloat(lst.Price);
    }

  }
  chkModifierSelected(lst, val): void {
    let SelectedModifires: any = this.lstModifier.filter(o => o.IsSelected === true && o.MasterId == lst.MasterId).length + 1;
    debugger
    if ((val.currentTarget.checked)) {

      if (SelectedModifires > parseInt(lst.MaxModifiers)) {
        val.currentTarget.checked = false;
        return;
      }
      if (lst.Price != null && lst.Price != undefined && lst.Price != '') {
        //let modifierPrice = lst.Price * this.Quantity;
        //this.ProductPriceInBasket += modifierPrice;
        //this.ProductPriceInBasketDiscounted += modifierPrice;
        this.ModifierPrice += parseFloat(lst.Price);
      }

      this.lstModifier.find(item => item.DetailId == lst.DetailId).IsSelected = true;
      this.lstData.find(item => item.MasterId == lst.MasterId).IsSelected = true;
    } else {
      if (lst.Price != null && lst.Price != undefined && lst.Price != '') {
        //let modifierPrice = lst.Price * this.Quantity;
        //this.ProductPriceInBasket -= modifierPrice;
        //this.ProductPriceInBasketDiscounted -= modifierPrice;
        this.ModifierPrice -= parseFloat(lst.Price);
      }

      this.lstModifier.find(item => item.DetailId == lst.DetailId).IsSelected = false;
      this.lstData.find(item => item.MasterId == lst.MasterId).IsSelected = false;//for validation only
    }

  }

  PricePrevoiusVal:any = 0;
  ProductPriceInBasket:any;
  ProductPriceInBasketDiscounted:number = 0;
  Quantity: any = 1;
  ProductDetailID:any;
  rdProductSizeSelected(row, rdval): void {debugger
    this.ArrProductSize.forEach((items, index) => {
      if (items.ProductDetailID == parseInt(rdval.target.value))
        this.ArrProductSize[index].IsSelected = false;
    })
    this.ProductDetailID = row.ProductDetailID;
    this.ArrProductSize.find(item => item.ProductDetailID == row.ProductDetailID).IsSelected = true;

    if (this.PricePrevoiusVal > 0) {
      this.ProductPriceInBasket -= this.PricePrevoiusVal * this.Quantity;
      this.ProductPriceInBasketDiscounted -= this.PricePrevoiusVal * this.Quantity;
      let Price = row.Price * this.Quantity;
      let DisPrice = row.DiscountPrice * this.Quantity;
      this.ProductPriceInBasket = Price;
      this.ProductPriceInBasketDiscounted = DisPrice;
      this.lstSelectedProduct.Price = Price;
      this.lstSelectedProduct.DiscountPrice = row.DiscountPrice;
      this.lstSelectedProduct.ProductDetailID = row.ProductDetailID;
      this.lstSelectedProduct.ProductSizeID = row.ProductSizeID;
      this.lstSelectedProduct.ProductSizeName = row.Description;
      if(row.Description !=null){
        this.lstSelectedProduct.sizeProductName = this.lstSelectedProduct.ProductName +'('+ row.Description +')';
      }
      else{
        this.lstSelectedProduct.sizeProductName = this.lstSelectedProduct.ProductName
      }
    }
    else {
      if (row.Price != null && row.Price != undefined && row.Price != '') {
        let Price = row.Price * this.Quantity;
        let DisPrice = row.DiscountPrice * this.Quantity;
        this.ProductPriceInBasket = Price;
        this.ProductPriceInBasketDiscounted = DisPrice;
        this.lstSelectedProduct.Price = row.Price;
        if(row.Description !=null){
          this.lstSelectedProduct.sizeProductName = this.lstSelectedProduct.ProductName +'('+ row.Description +')';
        }
        else{
          this.lstSelectedProduct.sizeProductName = this.lstSelectedProduct.ProductName
        }
        this.lstSelectedProduct.DiscountPrice = row.DiscountPrice;
        this.lstSelectedProduct.ProductDetailID = row.ProductDetailID;
        this.lstSelectedProduct.ProductSizeID = row.ProductSizeID;
        this.lstSelectedProduct.ProductSizeName = row.Description;

      }
    }
    if (row.Price != null && row.Price != undefined && row.Price != '') {
      this.PricePrevoiusVal = parseFloat(row.Price);
    }
  }
//   specialInstructionInputChange(event){debugger
// this.lstSelectedProduct
//   }
  
}
export class Company {
  CompanyID: any;
  CompanyName: any;
  outletsCount: any;
  CountryID: any;
  hearbout: any;
  Mobile: any;
  CurrencyCode: any;
  Rate: any;
  NumOfDecimal: any;
  CountryCode: any;
  IsOTPRequired: boolean;
}
export class ResturentInfo {
  ID: any;
  TextColor: any = '';
  BaseColor: any = '';
  Title: any = '';
  Message: any = '';
  MainImagePath: any = '';
  MainImageName: any = '';
  IconImagePath: any = '';
  IconImageName: any = '';
  RestaurantName: any = '';
  RestaurantNameDisplay: any = '';
}
