import { Component, OnInit, Inject} from '@angular/core';
import { DataserviseService } from '../../../../shared/dataservice';
import { PopupService } from '../../../../shared/popup';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrderMaster } from './../liveorder.component';
import { NbToastrService } from '@nebular/theme';
import { UtilitiesService } from '../../../../shared/utilities.service';
import { SpinnerService} from '../../../../shared/spinner';
@Component({
  selector: 'ngx-liveorderpopup',
  styleUrls: ['./liveorderpopup.component.scss'],
  templateUrl: './liveorderpopup.component.html',
})

export class LiveOrderPopupComponent implements OnInit {

  ObjOrderMaster: OrderMaster = new OrderMaster();
  constructor(private dataservice: DataserviseService, private popup: PopupService, private toastrService: NbToastrService, public dialogRef: MatDialogRef<LiveOrderPopupComponent>, @Inject(MAT_DIALOG_DATA) public data: any , private Utilities: UtilitiesService, private _spinner:SpinnerService) {


    this.ObjOrderMaster = this.data.data;
   
  }
  ngOnInit(): void {
    console.log(this.ObjOrderMaster);
    this.PrepTime = parseInt(this.ObjOrderMaster.PrepInOutlet);
  }
  ClosePopup(): void {
    this.dialogRef.close(undefined);
  }

  setAuditFields(): void {
    this.ObjOrderMaster.CreatedBy = this.Utilities.getUserId();
    this.ObjOrderMaster.CreatedOn = this.Utilities.GetCurrentDateTime();
    this.ObjOrderMaster.ModifyBy = this.Utilities.getUserId();
    this.ObjOrderMaster.ModifyOn = this.Utilities.GetCurrentDateTime();
    this.ObjOrderMaster.CompanyId = this.Utilities.getSubscriptionID();
  }

  AcceptOrder(): void {

    if (this.ObjOrderMaster.OrderID == undefined || this.ObjOrderMaster.OrderID == '') {
      alert('Please select order!');
      return;
    }
    this.setAuditFields();

    this.ObjOrderMaster.PrepIn = this.PrepTime;
    this._spinner.show();
    this.dataservice.post('LiveOrder/UpdateLiveOrder/', this.ObjOrderMaster)
      .subscribe(result => {
        if (result) {
          if (result == 'SUCCESS') {
            this._spinner.hide();
            this.dialogRef.close(result);
          }
          else {
            setTimeout(() => {this._spinner.hide(); alert('No orders found!')})
          }
        }
        

      }, error => alert('error'));
  }

  PrepTime: any = 1;


  Plus(): void {
    this.PrepTime = this.PrepTime + 1;
  }
  Minus(): void {
    if (this.PrepTime === 0) {
      return;
    }
    this.PrepTime = this.PrepTime - 1;
  
  }
 
}
