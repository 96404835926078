<nb-card style="border: none;">
    <nb-card-header style="padding: 0px;padding-bottom: 5px;">
      <div class="parent1"  style="display: block;padding-top: 0px; border-bottom: 1px solid #edf1f7 !important;">
        <div class="child1" style="max-width: none;">{{SpecialInstruction}}<img src="./assets/images/lookupclose.png" (click)="btnclose()" class="cross-icon" style="float: right;cursor: pointer;margin-top: -5px;" /></div>
      </div>
    </nb-card-header>
      <nb-card-body >
            <div class="form-group" style="padding-top: 15px;">
                <label class="label">{{SpecialInstruction}}</label>
                <textarea nbInput fullWidth [(ngModel)]="txtboxComment" rows="6" style="resize: none;"></textarea>
                  </div>
            
       <div class="form-group">
        <button nbButton status="success" style="width:100px;float: right;" (click)="commentClick()">{{Comment}}</button>
        
      </div>
      </nb-card-body>
  
    </nb-card>
    