


 <div class="child1" style="max-width: 100% !important ;">{{TableSetting}}
  <img src="./assets/images/lookupclose.png" (click)="btnclose()" style="float: right; width: 25px;cursor: pointer; outline: none;" tabindex="1">
</div>
<hr style="margin-top: 10px;"/>


<nb-tabset  style="overflow-x: auto;overflow-y: hidden;padding-bottom: 5px;" (changeTab)="onChangeTab($event)" tabindex="2">
  <!-- <nb-tab *ngFor="let data of lstTableGroupInfo;let i = index" tabTitle="{{cultures == 'en' ? data.Section : data.ArabicSection}}" active="{{data.ID == SectionID}}" > </nb-tab> -->
  <nb-tab tabTitle="{{TransferTable}}" [active]="TransferTable">
     <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
    <div class="row">
      <div class="clsM2 form-group col-lg-3 col-md-3 col-xs-3 col-sm-3">
        <div class="algStyle">
          <label class="label">{{OrderNo}}</label>

        </div>
        <div class="algStyle">
          <label class="label"><strong>{{ObjOrderMaster.OrderInvoiceNo}}</strong></label>
        </div>
      </div>
      <div class="clsM2 form-group col-lg-3 col-md-3 col-xs-3 col-sm-3">
        <div class="algStyle">
          <label class="label">{{OrderDate}}</label>

        </div>
        <div class="algStyle">
          <label class="label"><strong>{{ObjOrderMaster.OrderDate }}</strong></label>
        </div>
      </div>

      <div class="clsM1 form-group col-lg-3 col-md-3 col-xs-4 col-sm-3">
        <div class="">
          <label class="label">{{OrderType}}</label>
        </div>
        <div class="">
          <label class="label"><strong>{{ObjOrderMaster.OrderType}}</strong></label>
        </div>
      </div>
      <div class="clsM2 form-group col-lg-3 col-md-3 col-xs-3 col-sm-3">
        <div class="algStyle">
          <label class="label">{{TableNo}}</label>

        </div>
        <div class="algStyle">
          <label class="label"><strong>{{ObjOrderMaster.TableNumber}}</strong></label>
        </div>
      </div>

      <div class="clsM1 form-group col-lg-3 col-md-3 col-xs-3 col-sm-3">
        <div class="">
          <label class="label">{{Customer}}</label>
        </div>
        <div class="">
          <label class="label"><strong>{{ObjOrderMaster.CustomerName}}</strong></label>
        </div>
      </div>
      <div class="clsM2 form-group col-lg-3 col-md-3 col-xs-3 col-sm-3">
        <div class="algStyle">
          <label class="label">{{PhoneNo}}</label>

        </div>
        <div>
          <label class="label"><strong>{{ObjOrderMaster.PhoneNo}}</strong></label>
        </div>
      </div>

    </div>
  </div>
      <hr/>
      <div class="row">
        <h4 style="width: 100%; text-align: center; font-size: 25px; color: #600404;">{{TransferDineOrderToAnotherTable}}</h4>
        <p style=" width: 100%; text-align: center; font-size: 19px;">{{Pleaseselectdestinationtablewheretotransferorderofcurrenttable}}</p>
      </div>
<div class="row" style="padding-right: 17px;">

  <div class="col-md-2"></div>
  <div  class="col-md-8"> 
    <div id="main_search">
    <input id="main_search_input" readonly [(ngModel)]="TableName" type="text" placeholder="Select Table" />
    <button id="search_button" (click)="openDineInTablePopup()"> <i class="fas fa-search fontsize"  ></i> </button>&nbsp;&nbsp;
    <button nbButton  status="danger" (click)="TableUpdate()"   >{{TransferTable}}</button>
    <p *ngIf="reserved" style="color: red;"> you can not transfer to this table, Its already in used.</p>
    <p *ngIf="selecttable" style="color: red;  padding-top: 4px;"> select destination table first.</p>
    </div>
  </div>
  <div class="col-md-2"></div>
      
  </div> 
</nb-tab>

  <nb-tab tabTitle="{{MergeTable}}" [active]="MergeTable"> 
    <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
    <div class="row">
      <div class="clsM2 form-group col-lg-3 col-md-3 col-xs-3 col-sm-3">
        <div class="algStyle">
          <label class="label">{{OrderNo}}</label>
        </div>
        <div class="algStyle">
          <label class="label"><strong>{{ObjOrderMaster.OrderInvoiceNo}}</strong></label>
        </div>
      </div>
      <div class="clsM2 form-group col-lg-3 col-md-3 col-xs-3 col-sm-3">
        <div class="algStyle">
          <label class="label">{{OrderDate}}</label>
        </div>
        <div class="algStyle">
          <label class="label"><strong>{{ObjOrderMaster.OrderDate }}</strong></label>
        </div>
      </div>

      <div class="clsM1 form-group col-lg-3 col-md-3 col-xs-4 col-sm-3">
        <div class="">
          <label class="label">{{OrderType}}</label>
        </div>
        <div class="">
          <label class="label"><strong>{{ObjOrderMaster.OrderType}}</strong></label>
        </div>
      </div>
      <div class="clsM2 form-group col-lg-3 col-md-3 col-xs-3 col-sm-3">
        <div class="algStyle">
          <label class="label">{{TableNo}}</label>
        </div>
        <div class="algStyle">
          <label class="label"><strong>{{ObjOrderMaster.TableNumber}}</strong></label>
        </div>
      </div>

      <div class="clsM1 form-group col-lg-3 col-md-3 col-xs-3 col-sm-3">
        <div class="">
          <label class="label">{{Customer}}</label>
        </div>
        <div class="">
          <label class="label"><strong>{{ObjOrderMaster.CustomerName}}</strong></label>
        </div>
      </div>
      <div class="clsM2 form-group col-lg-3 col-md-3 col-xs-3 col-sm-3">
        <div class="algStyle">
          <label class="label">{{PhoneNo}}</label>
        </div>
        <div>
          <label class="label"><strong>{{ObjOrderMaster.PhoneNo}}</strong></label>
        </div>
      </div>

    </div>
  </div>
      <hr/>
      <div class="row">
        <h4 style="width: 100%; text-align: center; font-size: 25px; color: #600404;">{{MergeDineOrderToAnotherTable}}</h4>
        <p style=" width: 100%; text-align: center; font-size: 19px;">{{Pleaseselectdestinationtablewheretomergeorderofcurrenttable}}</p>
      </div>
<div class="row" style="padding-right: 17px;">

  <div class="col-md-2"></div>
  <div  class="col-md-8"> 
    <div id="main_search">
    <input id="main_search_input" readonly [(ngModel)]="TableName" type="text" placeholder="Select Table" />
    <button id="search_button" (click)="openDineInTablePopup()"> <i class="fas fa-search fontsize"  ></i> </button>&nbsp;&nbsp;
    <button nbButton  status="danger" (click)="MergeTableclick()" >{{MergeTable}}</button>
    <p *ngIf="reserved" style="color: red;"> you can not Merger to this table, Its alredy in used.</p>
    <p *ngIf="selecttable" style="color: red;  padding-top: 4px;"> select destination table first.</p>
    </div>
  </div>
  <div class="col-md-2"></div>
      
  </div> 
 </nb-tab>
  <!-- <nb-tab tabTitle="{{JoinTable}}" [active]="JoinTable">    
  <p>Join Table</p>
 </nb-tab>  -->
</nb-tabset>
<!-- <br>
<ng-template [ngIf]="ActiveTabTransferTable || ActiveTabMergeTable || ActiveTabJoinTable || ActiveTabSplitCheck">
      <div *ngIf="ActiveTabTransferTable">
        <div class="row">
          Active Tab mERGE Table
        </div>
      </div>
      <div *ngIf="ActiveTabMergeTable">
        <div class="row">
          Active Tab mERGE Table
        </div>
      </div>
      <div *ngIf="ActiveTabJoinTable">
        <div class="row">
          Active Tab Join Table
        </div>
      </div>
      <div *ngIf="ActiveTabSplitCheck">
        <div class="row">
          Active Tab Split Check
        </div>
      </div>
</ng-template> -->


<!-- <div class="col-lg-12" style="padding-top: 20px;">
<div class="row" >
  <div class="col-md-3 mb-2" *ngFor="let row of lstDineinTableInfo;">
    <div class="card-body" [ngClass]=" row.Status == '0' ? 'cardGree' : 'cardRed'" (click)="ClickTable(row,row.Status)">          
      <h5>{{row.TableName}}</h5>
     <div [ngClass]=" row.Status == '0' ? 'FreetableAmount' : 'BookedtableAmount'" > {{TotalAmount}}: {{row.Netamount}}</div>
      </div>
  </div>
</div>
</div> -->



<!-- 

<link href="//stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet">
<nb-card>  
  <nb-card-body >
    <div class="row parent1">
      <div class="col-md-2 ">
        <div class="child1">
          {{TransferTable}}
        </div>
      </div>
      <div class="col-md-8"></div>
      <div class="col-md-2">
        <span  (click)="dialogRef.close()" style="float: right; font-size: 25px; color: #05a9e6; font-weight: 600;">
          <i class="fal fa-times"></i>       
        </span>
      </div>
    </div>
    
    <hr/>

    <div class="row col-lg-12 col-md-12 col-xs-12 col-sm-12">
     
      <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
      
        <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
          <div class="row">
            <div class="clsM2 form-group col-lg-4 col-md-4 col-xs-4 col-sm-4">
              <div class="algStyle">
                <label class="label">{{OrderNo}}</label>
              </div>
              <div class="algStyle">
                <label class="label"><strong>{{ObjOrderMaster.OrderInvoiceNo}}</strong></label>
              </div>
            </div>
            <div class="clsM2 form-group col-lg-4 col-md-4 col-xs-4 col-sm-4">
              <div class="algStyle">
                <label class="label">{{OrderDate}}</label>
              </div>
              <div class="algStyle">
                <label class="label"><strong>{{ObjOrderMaster.OrderDate }}</strong></label>
              </div>
            </div>
            <div class="clsM1 form-group col-lg-4 col-md-4 col-xs-4 col-sm-4">
              <div class="">
                <label class="label">{{OrderType}}</label>
              </div>
              <div class="">
                <label class="label"><strong>{{ObjOrderMaster.OrderType}}</strong></label>
              </div>
            </div>
            <div class="clsM2 form-group col-lg-4 col-md-4 col-xs-4 col-sm-4">
              <div class="algStyle">
                <label class="label">{{TableNo}}</label>
              </div>
              <div class="algStyle">
                <label class="label"><strong>{{ObjOrderMaster.TableNumber}}</strong></label>
              </div>
            </div>
            <div class="clsM1 form-group col-lg-4 col-md-4 col-xs-4 col-sm-4">
              <div class="">
                <label class="label">{{Customer}}</label>
              </div>
              <div class="">
                <label class="label"><strong>{{ObjOrderMaster.CustomerName}}</strong></label>
              </div>
            </div>
            <div class="clsM2 form-group col-lg-4 col-md-4 col-xs-4 col-sm-4">
              <div class="algStyle">
                <label class="label">{{PhoneNo}}</label>
              </div>
              <div>
                <label class="label"><strong>{{ObjOrderMaster.PhoneNo}}</strong></label>
              </div>
            </div>
          </div>
        </div>
            <hr/>
            <div class="row">
              <h4 style="width: 100%; text-align: center; font-size: 25px; color: #600404;">{{TransferDineOrderToAnotherTable}}</h4>
                <p style=" width: 100%; text-align: center; font-size: 19px;">{{Pleaseselectdestinationtablewheretotransferorderofcurrenttable}}</p>
            </div>
      <div class="row" style="padding-right: 17px;">
        <div class="col-md-2"></div>
        <div  class="col-md-8"> 
          <div id="main_search">
          <input id="main_search_input" readonly [(ngModel)]="TableName" type="text" placeholder="Select Table" />
          <button id="search_button" (click)="openDineInTablePopup()"> <i class="fas fa-search fontsize"  ></i> </button>
&nbsp;&nbsp;
          <button nbButton  status="danger" (click)="TableUpdate()"   >{{TransferTable}}</button>
          <p *ngIf="reserved" style="color: red;"> you can not transfer to this table, Its alredy in used.</p>
              <p *ngIf="selecttable" style="color: red;  padding-top: 4px;"> select destination table first.</p>

          </div>
          
        </div>
        <div class="col-md-2"></div>
        </div>
      </div>
    </div>
  </nb-card-body>
</nb-card>
 -->
