import { Component, OnInit, Inject } from '@angular/core';
import { UtilitiesService } from '../../shared/utilities.service';
import { DataserviseService } from '../../shared/dataservice';
import { TranslatePipe } from '../../shared/pipe/translate.pipe';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'ngx-categorymenupopup',
  templateUrl: './categorymenupopup.component.html',
  styleUrls: ['./categorymenupopup.component.scss']
})
export class CategorymenupopupComponent implements OnInit {

  constructor(private Utilities: UtilitiesService, private dataservice: DataserviseService, private translate: TranslatePipe, public dialog: MatDialog, public dialogRef: MatDialogRef<CategorymenupopupComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }
  lstCategories: any = [];
  SelectedBranchID: any;
  culture:any;
  lstSalesType:any =[];
  IsSalesTypePopup: boolean = false;
  counterID: any;
  ngOnInit(): void {
    debugger;
    this.SelectedBranchID= this.data.outletID;
    this.culture= this.data.culture;
    this.IsSalesTypePopup = this.data.IsSalesTypePopup;
    this.counterID = this.data.counterID
    if(this.IsSalesTypePopup)
    this.lstSalesType = this.data.SalesType;
    
    if(!this.IsSalesTypePopup)
    this.GetCategories();
  }
  GetCategories(): void {
    this.lstCategories = [];
    this.dataservice.get('pos/GetCategoryData/' + this.Utilities.getSubscriptionID() + '/' + this.SelectedBranchID + '/' + this.counterID)
      .subscribe(data => {
        if (this.isArrayHasValue(data[0]['CategoryData'])) {
          this.lstCategories = data[0]['CategoryData'];
        }

      }, error => alert(this.translate.transform('error')));
  }
  getProduct(catID): void {

    this.dialogRef.close(catID);
  }

  getSelectedTypeData(ID,TypeName){debugger
    let selectedSalesType = [ID,TypeName]
    this.dialogRef.close(selectedSalesType);
  }

  isArrayHasValue(obj: any[]): boolean {
    let isArrayWithLength = Boolean(Array.isArray(obj) && obj.length);
    return isArrayWithLength;
  }

}
