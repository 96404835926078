import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataserviseService } from '../../shared/dataservice';
import { UtilitiesService } from '../../shared/utilities.service';
import { PopupService } from '../../shared/popup';
import { TranslatePipe } from '../../shared/pipe/translate.pipe';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'ngx-recallrestaurantorders',
  templateUrl: './recallrestaurantorders.component.html',
  styleUrls: ['./recallrestaurantorders.component.scss']
})
export class RecallRestaurantOrdersComponent implements OnInit {


  constructor(private _popup: PopupService, private translate: TranslatePipe, private UtilitiesService: UtilitiesService, private popup: PopupService, private dataservice: DataserviseService, public dialog: MatDialog, public dialogRef: MatDialogRef<RecallRestaurantOrdersComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public http: HttpClient) { }

  cultures: any;
  HoldOrders: any = this.translate.transform('HoldOrders');
  lblCancelOrder: any = this.translate.transform('CancelOrder');
  lblSelectOrder: any = this.translate.transform('SelectOrder');
  // lblAcceptOrder: any = this.translate.transform('AcceptOrder');
  PrintOrder: any = this.translate.transform('PrintOrder');
  RePrintOrder: any = this.translate.transform('RePrintOrder');
  ReferenceNo: any = this.translate.transform('ReferenceNo');
  OrderDate: any = this.translate.transform('OrderDate');
  OrderType: any = this.translate.transform('OrderType');
  TableNo: any = this.translate.transform('TableNo.');
  Customer: any = this.translate.transform('Customer');
  PhoneNo: any = this.translate.transform('PhoneNo');
  Remarks: any = this.translate.transform('Remarks');
  Qty: any = this.translate.transform('Qty');
  Description: any = this.translate.transform('Description');
  Amount: any = this.translate.transform('Amount');
  SubTotal: any = this.translate.transform('SubTotal');
  Discount: any = this.translate.transform('Discount');
  Tax: any = this.translate.transform('Tax');
  DeliveryFee: any = this.translate.transform('Delivery Fee');
  NetAmount: any = this.translate.transform('NetAmount');
  SpecialInstruction: any = this.translate.transform('SpecialInstruction');
  YoucannotprinttheReceiptmorethen: any = this.translate.transform('YoucannotprinttheReceiptmorethen');
  times: any = this.translate.transform('times');
  DayID: any;

  POSID: any;

  ngOnInit(): void {
    debugger

    if (sessionStorage.getItem('ObjCompany') != null && sessionStorage.getItem('ObjCompany') != undefined) {
      this.ObjCompany = JSON.parse(sessionStorage.getItem('ObjCompany'));
    }
    if (localStorage.getItem('SelectedBranchRow' + this.UtilitiesService.getURLCompanyName()) != null && localStorage.getItem('SelectedBranchRow' + this.UtilitiesService.getURLCompanyName()) != undefined) {
      this.SelectedBrancRow = JSON.parse(localStorage.getItem('SelectedBranchRow' + this.UtilitiesService.getURLCompanyName()));
      this.SelectedOutletID = this.SelectedBrancRow.ID;
    }

    if (localStorage.getItem('sessionObject' + this.UtilitiesService.getURLCompanyName()) != null && localStorage.getItem('sessionObject' + this.UtilitiesService.getURLCompanyName()) != undefined) {
      this.lstUser = JSON.parse(localStorage.getItem('sessionObject' + this.UtilitiesService.getURLCompanyName()));
    }
    this.cultures = this.data["culture"];

    this.DayID = this.data["DayID"];
    this.POSID = this.data["POSID"];

    this.LoadGrid();
  }

  ObjCompany: Company = new Company();
  ObjOrderMaster: OrderMaster = new OrderMaster();
  ArrOrderDetail: OrderDetail[] = [];
  SearchInList: any = '';
  HighlightRow: any;
  CurrencyCode: any = '';
  SelectedBrancRow: any;
  SelectedOutletID: any;

  lstarray = [];

  lstBasket: any = [];
  lstSelectModifier: any = [];
  OrderID: any;
  barertoken: any;
  btnclose(): void {
    debugger
    this.dialogRef.close();
  }
  lstOrderIds: any = [];
  LoadGrid() {
    debugger;
    this.lstarray = [];
    this.lstOrderIds = [];

    if (this.SelectedOutletID != undefined && this.SelectedOutletID != null) {
      this.dataservice.get('pos/GetHoldOrders/' + this.UtilitiesService.getSubscriptionID() + '/' + this.SelectedOutletID + '/' + localStorage.getItem('LanguageCulture' + this.UtilitiesService.getURLCompanyName()) + '/' + this.DayID +'/'+this.POSID).subscribe(data => {

        debugger;
        if (this.isArrayHasValue(data[0]["Pendingprder"])) {
          this.lstarray = data[0]["Pendingprder"];

            this.lstarray.forEach(e => {
              this.lstOrderIds.push( e["OrderID"]);
            });

          //console.log(this.lstarray);
          //console.log(this.lstOrderIds);
          this.getMasterDetailData(data[0]['Pendingprder'][0]["OrderID"], 0);
        }

      });

    }
    else {
      alert('Please Select Outlet first.');
    }
  }

  SelectOrder(): void {
    debugger

      if (this.OrderID != undefined && this.OrderID != null && this.OrderID != '') {

        this.dialogRef.close(this.OrderID);
      }
      else {
        alert('Please select Order first.');
      }
  }


  RefreshOrders(): void {

    this.LoadGrid();
  }

  OrderIDMaster: any;
  OrderIDIndex: any;
  chkDinerights : any = [];
  chkAllow : boolean = false;
  getMasterDetailData(OrderID, index): void {

    this.OrderIDMaster = OrderID;
    this.OrderIDIndex = index;
    this.HighlightRow = index;
    this.ObjOrderMaster = new OrderMaster();
    this.OrderID = OrderID;

    this.dataservice.get('pos/GetHoldOrdersMasterDetail/' + this.UtilitiesService.getSubscriptionID() + '/' + OrderID + '/' + localStorage.getItem('LanguageCulture' + this.UtilitiesService.getURLCompanyName())+'/'+this.UtilitiesService.getUserId())
      .subscribe(data => {

        debugger
        if (data[0]['Master']) {
          this.ObjOrderMaster = data[0]['Master'][0];
          if(this.ObjOrderMaster.OtherChannel == 'Deliverect' && this.ObjOrderMaster.DeliverectIsOrderAccepted !=1)
          this.lblSelectOrder = this.translate.transform('AcceptOrder');
          else
          this.lblSelectOrder = this.translate.transform('SelectOrder');
        }

        if (this.isArrayHasValue(data[0]['Details'])) {
          this.ArrOrderDetail = data[0]['Details'];
          console.log(this.ArrOrderDetail)
        }
        else {
          this.ArrOrderDetail = [];
        }
        if (this.isArrayHasValue(data[0]['chkDinerights'])) {
          this.chkDinerights = data[0]['chkDinerights']; 
          this.chkAllow = this.chkDinerights[0]['Allow'];      
        }
        // this.getReceipt();

      }, error => alert('error'));
  }

  lstUser = [];

  DeleteOrders(OrderID, IsSingleOrder): void {
    debugger;
    if (window.confirm('Are you sure you want to delete?')) {
      this.dataservice.get('pos/VoidHoldOrder/' + this.UtilitiesService.getSubscriptionID() + '/' + OrderID + '/' + IsSingleOrder).subscribe(data => {

        debugger;
        if (this.isArrayHasValue(data[0]["DeleteMsg"])) {
          let res = data[0]["DeleteMsg"][0];
          if (res.status == 'success') {
            this.popup.ShowPopupCustom(res.status, res.msg);
            this.LoadGrid();
          }
          else {
            this.popup.ShowPopupCustom(res.status, res.msg);
          }
   
        }

        if (this.lstarray.length < 1) {
          this.dialogRef.close(undefined);
        }
      });
    }
  }

  isArrayHasValue(obj: any[]): boolean {
    let isArrayWithLength = Boolean(Array.isArray(obj) && obj.length);
    return isArrayWithLength;
  }
}
class OrderMaster {
  OrderID: any;
  TableNumber: any;
  OrderInvoiceNo: any = '';
  PrepIn: any = '';
  PrepInOutlet: any = '';
  OrderPerpStatus: any = 'ALL';
  Brand: any = '';
  OutletID: any = '';
  OutletId: any = '';
  OutletName: any = '';
  OrderType: any = '';
  Address: any = '';
  CustomerID: any = '';
  CustomerName: any = '';
  CustomerEmail: any = '';
  CountryCode: any = '';
  PhoneNo: any = '';
  PayType: any = '';
  Channel: any = '';
  PickupFrom: any = '';
  Remarks: any = '';
  SubTotal: any = '';
  TotalTaxAmount: any = '';
  TotalAmount: any = '';
  DeliveryFee: any = '';
  NetAmount: any = '';
  TotalDiscount: any = '';
  TaxID: any = '';
  TaxDescription: any = '';
  TaxPercent: any = '';
  OrderDate: any;
  OrderDateReal: any;
  CreatedOn: any;
  CreatedBy: any;
  ModifyOn: any;
  ModifyBy: any;
  RejectReasonID: any;
  RejectReason: any;
  RiderID: any;
  CompanyId: any;
  RejectReasonDetail: any = '';
  AddressPK: any = '';
  ShiftID: any;
  IsExtraCharge: any;

  MaxReceiptPrintOut: any;
  MaxKitchenReceiptPrintOut: any;
  ReceiptPrintOutCount: any;
  KitchenReceiptPrintOutCount: any;
  IsMaxPolicyOn: boolean;
  DeliverectOrderId: any;
  OtherChannel: any;
  UserId : any;
  DeliverectIsOrderAccepted:any;
  DeliverectIsOrderCancel:any;
  ReferenceNo: any;
}
class OrderDetail {
  OrderDetailID: any;
  OrderID: any;
  ProductId: any;
  ProductName: any = '';
  Quantity: any;
  UnitID: any;
  AddOnID: any;
  AddOnName: any;
}

class Company {
  CompanyID: any;
  CompanyName: any;
  outletsCount: any;
  CountryID: any;
  hearbout: any;
  Mobile: any;
  CurrencyCode: any;
  Rate: any;
  NumOfDecimal: any;
  CountryCode: any;
  IsOTPRequired: boolean;
}
