import { Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import {environment} from '../../environments/environment';
import { Router } from '@angular/router';
import { AppConfigService } from './app-config.service';

@Injectable()
export class DataserviseService {
public _TokenKey: string;
// baseURL = environment.baseUrl;
// baseURL:any;

constructor(private _http: HttpClient, private router: Router, private _config: AppConfigService ) { }


get(url: string): Observable<any> {
    // var isConnected = this.isNetworkAvailable();
    // if (!isConnected) {
    //   alert('Network Not Availble')
    //   return;
    // }
    // let uniqueName = this.generateUniqueName();
    // let options = this.getHeaders('login', this._TokenKey);
    return this._http.get(this._config.environment.baseUrl + url);
  }



  post(url: string , model: any): Observable<any> {
    // var isConnected = this.isNetworkAvailable();
    // if (!isConnected) {
    //   alert('Network Not Availble')
    //   return;
    // }
    // let body = JSON.stringify(model);
    return this._http.post(this._config.environment.baseUrl + url , model);
  }
  postNamiPay(url: string, model: any): Observable<any> {
    // var isConnected = this.isNetworkAvailable();
    // if (!isConnected) {
    //   alert('Network Not Availble')
    //   return;
    // }
    // let body = JSON.stringify(model);
    return this._http.post(url, model);
  }
  put(url: string, id: number, model: any): Observable<any> {
    // var isConnected = this.isNetworkAvailable();
    // if (!isConnected) {
    //   alert('Network Not Availble')
    //   return;
    // }

    return this._http.put(this._config.environment.baseUrl + url + '/' + id, model);
      // .map((response: Response) => {
      //   return <any>response.json();
      // })
      // .catch((error: any) => this.handleError(error));
  }

  delete(url: string, id: number): Observable<any> {
    // var isConnected = this.isNetworkAvailable();
    // if (!isConnected) {
    //   alert('Network Not Availble')
    //   return;
    // }

    return this._http.delete(this._config.environment.baseUrl + url + '/' + id);
      // .map((response: Response) => {
      //   return <any>response.json();
      // })
      // .catch((error: any) => this.handleError(error));
  }

  isNetworkAvailable(): boolean {
    if (!navigator.onLine) {
      // alert("Connection with the server is lost, please try again.");
      return false;
    }
    return true;
  }

  generateUniqueName() {
    let uniqueNum: any = Math.floor(100000 + Math.random() * 900000);
    uniqueNum = 'app' + uniqueNum;
    return uniqueNum;
  }

  values = [];
  checkFormRights(formID, Action): Array<any> {
    this.values = [];
    const lstformRights = JSON.parse(sessionStorage.getItem('FormRights'));
    if (Action == 'Allow') {

     if (!lstformRights.find(x => x.EntityCode.trim() ==  formID.trim() && x.Allow == true)) {
      this.router.navigateByUrl('/login');
     }
    }


    if (Action == 'New') {
     if ( lstformRights.find(x => x.EntityCode ==  formID && x.New == true)) {
      const formRights = true;
      this.values.push(formRights);
      return this.values;
     } else {
       const formRights = false;
       this.values.push(formRights);
       const message = 'You don\'t have Permission to Save Record.';
       this.values.push(message);
       return this.values;
      }
    }

    if (Action == 'Edit') {
     if (lstformRights.find(x => x.EntityCode ==  formID && x.Edit == true)) {
      const formRights = true;
      this.values.push(formRights);
      return this.values;
     } else {
      const formRights = false;
      this.values.push(formRights);
      const message = 'You don\'t have Permission to Edit Record.';
      this.values.push(message);
      return this.values;
     }
    }

    if (Action == 'Del') {
      if (lstformRights.find(x => x.EntityCode ==  formID && x.Del == true)) {
        const formRights = true;
        this.values.push(formRights);
        return this.values;
      } else {
        const formRights = false;
        this.values.push(formRights);
        const message = 'You don\'t have Permission to Delete Record.';
        this.values.push(message);
        return this.values;
       }
    }

    if (Action == 'Printing') {
      if (lstformRights.find(x => x.EntityCode ==  formID && x.Printing == true)) {
        const formRights = true;
        this.values.push(formRights);
        return this.values;
      } else {
        const formRights = false;
        this.values.push(formRights);
        const message = 'You don\'t have Permission for Print out.';
        this.values.push(message);
        return this.values;
       }
    }

  }


}
