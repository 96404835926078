import { Component, ChangeDetectionStrategy ,ViewChild,ElementRef} from '@angular/core';
import { DataserviseService } from '../../../shared/dataservice';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LiveOrderPopupComponent } from './popup/liveorderpopup.component';
import { RejectPopupComponent } from './rejectpopup/rejectpopup.component';
import { PopupService } from '../../../shared/popup';
import { RiderpopupComponent } from './riderpopup/riderpopup.component';
import { NewOrderPopupComponent } from './neworderpopup/neworderpopup.component';
import { Router } from '@angular/router';
import { UtilitiesService } from '../../../shared/utilities.service';
import { SpinnerService } from '../../../shared/spinner';
import { TranslatePipe } from '../../../shared/pipe/translate.pipe';
import { map, delay } from "rxjs/operators";
import { RouterModule, Routes } from '@angular/router';


enum OrderPerpStatus {
  All = 'All',
  New = 'New',
  InKitchen = 'InKitchen',
  InRoute = 'InRoute',
  Rejected = 'REJECTED',
  Delivered = 'Delivered',
  Cancel = 'Cancel',
}

enum TabTitle {
  All = 'All',
  New = 'New',
  InKitchen = 'In Kitchen',
  InRoute = 'In Route/Ready',
}

@Component({
  selector: 'ngx-liveorder',
  styleUrls: ['./liveorder.component.scss'],
  templateUrl: './liveorder.component.html',

})


export class LiveOrderComponent {

  ObjOrderMaster: OrderMaster = new OrderMaster();
  CompanyId: any;
  ArrOrderDetail: OrderDetail[] = [];
  ArrVoucherData: VoucherData[] = [];

  lstOnlyOrderNo: any = [];
  ObjCurrency: Country = new Country();

  HighlightRow: any; 
  SearchInList: any = '';
  LiveOrderUser: any = '0';
  OrderType: any = '';
  dataRefresher: any;
audio:any;
 
  title = 'app';
  elementType = 'url';
  value = 'Tehsen Ahmed';
  logo: any = '../../../assets/images/globalinformix.png';

  constructor(private dataservice: DataserviseService, public dialog: MatDialog, private _popup: PopupService, public router: Router, private Utilities: UtilitiesService,private _spinnerService: SpinnerService, private translatePipe : TranslatePipe) {

  }

  printComponent(cmpName) {

    let printContents = document.getElementById(cmpName).innerHTML;
    let originalContents = document.body.innerHTML;

    //document.body.innerHTML = printContents;

    window.print();
    //document.body.innerHTML = originalContents;
  }


  ngOnInit(): void {

    debugger

    // this.router.navigate(['/role']);

    const url = this.Utilities.getshiftEndReport(this.Utilities.getEncrypt(2),this.Utilities.getEncrypt(23), this.Utilities.getEncrypt('2022-03-18'));
    console.log(url);
 

    this.dataservice.checkFormRights('LiveOrder', 'Allow');
    this.audio = new Audio('./assets/images/week7-brrring.mp3');
    this.ObjOrderMaster.CreatedBy = this.Utilities.getUserId();
    this.ObjOrderMaster.ModifyBy = this.Utilities.getUserId();
    if (this.Utilities.getLoginUserId() != undefined && this.Utilities.getLoginUserId() != '')
      this.LiveOrderUser = this.Utilities.getLoginUserId();

    this.CompanyId = this.Utilities.getSubscriptionID();
    this.GetLiveOrdersStatusWise(OrderPerpStatus.All);
  }


  refreshData() {
  
    let dialogRef;

    if (this.firstItem != undefined && this.firstItem != null && this.firstItem != '') {
      this.dataRefresher =
        setInterval(() => {
          this.dataservice.get('LiveOrder/IsNewOrder/' + this.CompanyId + '/' + this.firstItem)
            .subscribe(result => {
              if (result != undefined && result) {

                //if (dialogRef != undefined) {
                //  dialogRef.afterClosed().subscribe(rr => {
                //  });
                //}

                  //dialogRef = this.dialog.open(NewOrderPopupComponent, {
                  //  width: '400px',
                  //});
                debugger
                this.setActiveTAB(this.translatePipe.transform('NEW'));
                this.GetLiveOrdersStatusWise(OrderPerpStatus.New);
                if (this.NewCount > 0){
                  this.playAudio();
                }
                //dialogRef.afterClosed().subscribe(rr => {
                //});
              
              }
            }, error => console.log(this.Utilities.getconnectionmsg()));
        }, 10000);
    }
    
  }

  cancelPageRefresh() {
    if (this.dataRefresher) {
      clearInterval(this.dataRefresher);
    }
  }

  ngOnDestroy() {
    if (this.audio) {
      this.audio.pause();
    }
    this.cancelPageRefresh();
  }


  PreTime: any = 60;

  DateDiff(dt1, dt2): any {
    return Math.round((dt2.getTime() - dt1.getTime()) / 60000);
}


  AllCount: any;
  NewCount: any;
  InKitchenCount: any;
  InRouteCount: any;
  PrepIn: any;
  _OrderId: any;

  firstItem: any = 'ORD00000000';

  GetLiveOrdersStatusWise(Status): void {
    
    this.lstOnlyOrderNo = [];
  
    this._spinnerService.show();
    this.dataservice.get('LiveOrder/GetLiveOrdersStatusWise/' + Status + '/' + this.LiveOrderUser + '/' + this.CompanyId)
      .subscribe(data => {
        
        if (this.isArrayHasValue(data[0]['OrdersStatusWise'])) {
          
          this.lstOnlyOrderNo = data[0]['OrdersStatusWise'];
          this.ObjCurrency = data[0]['BaseCurrencyData'][0];
          this.AllCount = this.lstOnlyOrderNo[0].AllCount;
          this.NewCount = this.lstOnlyOrderNo[0].NewCount;
          this.InKitchenCount = this.lstOnlyOrderNo[0].InKitchen;
          this.InRouteCount = this.lstOnlyOrderNo[0].InRoute;
          let OrderID = data[0]['OrdersStatusWise'][0].OrderID;
          this._OrderId = data[0]['OrdersStatusWise'][0].OrderID;
          this.getMasterDetailData(OrderID, Status, 0);
          this._spinnerService.hide();

          if ((Status == 'All' || Status == 'New') && this.NewCount > 0 )
            this.firstItem = this.lstOnlyOrderNo.filter(x => typeof x !== undefined && x.OrderPerpStatus == 'New').shift().OrderInvoiceNo;

          if(this.NewCount > 0){
            this.playAudio();
          }

          this.refreshData();
        }
        else {
          this.lstOnlyOrderNo = [];
          this.AllCount = 0;
          this.NewCount = 0;
          this.InKitchenCount = 0;
          this.InRouteCount = 0;
          this.CustomBadgeText = '';
          this.ObjOrderMaster = new OrderMaster();
          this.ArrOrderDetail = [];
          setTimeout(() => { this._spinnerService.hide() }, 100);
          this.cancelPageRefresh();

        }
        this.refreshData();
      }, error => console.log(this.Utilities.getconnectionmsg()));
  }


  async getMasterDetailData(OrderID, Status, index) {


    this._spinnerService.show();

    this.ObjOrderMaster = new OrderMaster();
    this.HighlightRow = index;
    this.OrderType = this.ObjOrderMaster.OrderType;
debugger
    

    await this.dataservice
      .get('LiveOrder/GetLiveOrderData/' + OrderID + '/' + Status)
      .pipe(delay(1000))
      .toPromise().then((data: any) => {

        if (data[0]['MasterData']) {
          this.ObjOrderMaster = data[0]['MasterData'][0];
          if (this.ObjOrderMaster.OrderType != 'N/A' && this.ObjOrderMaster.OrderType == 'Pickup') {
            this.OrderType = 'Ready to Collect';
          }
          else if (this.ObjOrderMaster.OrderType != 'N/A' && this.ObjOrderMaster.OrderType == 'Delivery') {
            this.OrderType = 'Ready to dispatch';
          }
          else {
            this.OrderType = this.ObjOrderMaster.OrderType;
          }
        }
        if (this.isArrayHasValue(data[0]['DetailData'])) {
          this.ArrOrderDetail = data[0]['DetailData'];
        }
        else {
          this.ArrOrderDetail = [];
        }

        if (this.isArrayHasValue(data[0]['VoucherData'])) {
          this.ArrVoucherData = data[0]['VoucherData'];
        }
        else {
          this.ArrVoucherData = [];
        }
        debugger
        if (data[0]['ConsumedPoints'][0] && data[0]['ConsumedPoints'][0].ConsumedPoints != null && data[0]['ConsumedPoints'][0].ConsumedPoints != undefined) {
          this.LoyaltyPoints = data[0]['ConsumedPoints'][0].ConsumedPoints;
        }
        else {
          this.LoyaltyPoints = 0;
        }

        this._spinnerService.hide();
      }, error => console.log(this.Utilities.getconnectionmsg()));
      //this.getReceipt(OrderID);
      this._spinnerService.hide();

  }
  LoyaltyPoints: any;
  //getMasterDetailData(OrderID, Status, index): void {
  //  this._spinnerService.show();

  //  this.ObjOrderMaster = new OrderMaster();
  //  this.HighlightRow = index;
  //  this.OrderType = this.ObjOrderMaster.OrderType;
  //  
  //  //this.dataservice.get('LiveOrder/GetLiveOrderData/' + OrderID + '/' + Status).subscribe(data => {
  //      if (data[0]['MasterData']) {
  //        this.ObjOrderMaster = data[0]['MasterData'][0];
  //        if (this.ObjOrderMaster.OrderType != 'N/A' && this.ObjOrderMaster.OrderType == 'Pickup') {
  //          this.OrderType = 'Ready to Collect';
  //        }
  //        else if (this.ObjOrderMaster.OrderType != 'N/A' && this.ObjOrderMaster.OrderType == 'Delivery') {
  //          this.OrderType = 'Ready to dispatch';
  //        }
  //        else {
  //          this.OrderType = this.ObjOrderMaster.OrderType;
  //        }
  //      }
  //      if (this.isArrayHasValue(data[0]['DetailData'])) {
  //        this.ArrOrderDetail = data[0]['DetailData'];
  //      }
  //      else {
  //        this.ArrOrderDetail = [];
  //      }

  //      if (this.isArrayHasValue(data[0]['VoucherData'])) {
  //        this.ArrVoucherData = data[0]['VoucherData'];
  //      }
  //      else {
  //        this.ArrVoucherData = [];
  //      }
  //      this._spinnerService.hide();
  //    }, error => console.log('error'));

  //  this.getReceipt(OrderID);
  //}

  ReceiptURL: any = '';
  lstUser = [];

  getReceipt(): void {
    debugger
    const CompanyName = this.Utilities.getCompanyName();
    const CountryCode = this.Utilities.getUserCountryCode();
    const url = this.Utilities.getReceiptURL(this.Utilities.getEncrypt(CompanyName), this.Utilities.getEncrypt(this._OrderId), CountryCode, this.ObjOrderMaster.OutletId, 'true', 'false');
    this.ReceiptURL = url;

    console.log( this.ReceiptURL);
  }

  CustomBadgeText: any = '';

  onChangeTab(event): void {
    
    debugger

    this.CustomBadgeText = '';
  
    if (event.tabTitle == this.translatePipe.transform('ALL')) {
      this.setActiveTAB(this.translatePipe.transform('ALL'));
      this.GetLiveOrdersStatusWise(OrderPerpStatus.All);
    }

    if (event.tabTitle == this.translatePipe.transform('NEW')) {
      this.setActiveTAB(this.translatePipe.transform('NEW'));
      this.CustomBadgeText = 'New';//event.tabTitle;
      this.GetLiveOrdersStatusWise(OrderPerpStatus.New);
    }

    if (event.tabTitle ==  this.translatePipe.transform('INKitchen')) {
      this.setActiveTAB(this.translatePipe.transform('INKitchen'));
      this.CustomBadgeText = 'In Kitchen';//event.tabTitle;
      this.GetLiveOrdersStatusWise(OrderPerpStatus.InKitchen);
    }
    if (event.tabTitle == this.translatePipe.transform('INRouteReady')) {
      this.setActiveTAB(this.translatePipe.transform('INRouteReady'));
      this.CustomBadgeText = 'In Route/Ready';//event.tabTitle;
      this.GetLiveOrdersStatusWise(OrderPerpStatus.InRoute);
    } 

  }
  setActiveTAB(tab: any): void {
    debugger
    if (tab == this.translatePipe.transform('ALL')) {
      this.ActiveTabAll = true;
      this.ActiveTabNew = false;
      this.ActiveTabInKitchen = false;
      this.ActiveTabReadyToCollect = false;
      this.CustomBadgeText = '';
    }
    else if (tab == this.translatePipe.transform('NEW')) {
      this.ActiveTabAll = false;
      this.ActiveTabNew = true;
      this.ActiveTabInKitchen = false;
      this.ActiveTabReadyToCollect = false;
      this.CustomBadgeText = 'New';
    }
    else if (tab == this.translatePipe.transform('INKitchen')) {
      this.ActiveTabAll = false;
      this.ActiveTabNew = false;
      this.ActiveTabInKitchen = true;
      this.ActiveTabReadyToCollect = false;
      this.CustomBadgeText = 'In Kitchen';
      
    }
    else if (tab == this.translatePipe.transform('INRouteReady')) {
      this.ActiveTabAll = false;
      this.ActiveTabNew = false;
      this.ActiveTabInKitchen = false;
      this.ActiveTabReadyToCollect = true;
      this.CustomBadgeText = 'In Route/Ready';
    }
    else {
      this.ActiveTabAll = false;
      this.ActiveTabNew = false;
      this.ActiveTabInKitchen = false;
      this.ActiveTabReadyToCollect = false;
    }
  }
  ActiveTabAll: boolean = true;
  ActiveTabNew: boolean = false;
  ActiveTabInKitchen: boolean = false;
  ActiveTabReadyToCollect: boolean = false;

  setAuditFields(): void {
    this.ObjOrderMaster.CreatedBy = this.Utilities.getUserId();
    this.ObjOrderMaster.CreatedOn = this.Utilities.GetCurrentDateTime();
    this.ObjOrderMaster.ModifyBy = this.Utilities.getUserId();
    this.ObjOrderMaster.ModifyOn = this.Utilities.GetCurrentDateTime();
    this.ObjOrderMaster.CompanyId = this.Utilities.getSubscriptionID();
    this.ObjOrderMaster.CompanyName = this.Utilities.getCompanyName();
  }
  openPopup(): void {

    this.setAuditFields();

    this.ObjOrderMaster.OrderPerpStatus = OrderPerpStatus.InKitchen;
    let data = this.ObjOrderMaster;

    let dialogRef = this.dialog.open(LiveOrderPopupComponent, {
      width: '700px',
      data: { data },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        this.setActiveTAB(this.translatePipe.transform('INKitchen'));
        this.GetLiveOrdersStatusWise(OrderPerpStatus.InKitchen);
        this.audio.pause();

      }

    });
  }

  ReadyToCollect(): void {
    debugger
    if (this.ObjOrderMaster.OrderType == 'Pickup') {
      this.setAuditFields();
      this.ObjOrderMaster.OrderPerpStatus = OrderPerpStatus.InRoute;

      this._spinnerService.show();
      this.dataservice.post('LiveOrder/UpdateLiveOrder/', this.ObjOrderMaster)
        .subscribe(result => {
          if (result) {
            if (result == 'SUCCESS') {
              this.setActiveTAB( this.translatePipe.transform('INRouteReady'));
              this._spinnerService.hide();
              this._popup.ShowPopup('Update');
              this.GetLiveOrdersStatusWise(OrderPerpStatus.InRoute);
            }
            else {
              setTimeout(() => {
                this._spinnerService.hide(); alert('No orders found!');
              }, 100);
            }
          }

        }, error => console.log(this.Utilities.getconnectionmsg()));
    }
    else {
      debugger
      this.setAuditFields();

      let data = this.ObjOrderMaster;

      let dialogRef = this.dialog.open(RiderpopupComponent, {
        width: '700px',
        data: { data },
      });

      dialogRef.afterClosed().subscribe(result => {
        debugger
        if (result != undefined && result != null ) {
          
          if (this.ObjOrderMaster.OrderID == undefined || this.ObjOrderMaster.OrderID == '') {
            alert('Please select order!');
            return;
          }
          this.ObjOrderMaster.RiderID = result;

          this.setAuditFields();
          this.ObjOrderMaster.OrderPerpStatus = OrderPerpStatus.InRoute;

          this._spinnerService.show();
          this.dataservice.post('LiveOrder/UpdateLiveOrder/', this.ObjOrderMaster)
            .subscribe(result => {
              if (result) {
                if (result == 'SUCCESS') {
                  this.setActiveTAB( this.translatePipe.transform('INRouteReady'));
                  this._spinnerService.hide();
                  this._popup.ShowPopup('Update');
                  this.GetLiveOrdersStatusWise(OrderPerpStatus.InRoute);
                }
                else {
                  setTimeout(() => {
                    this._spinnerService.hide(); alert('No orders found!');
                  }, 100);
                }
              }

            }, error => console.log(this.Utilities.getconnectionmsg()));
        }

      });
    }


    
    // if (this.ObjOrderMaster.OrderID == undefined || this.ObjOrderMaster.OrderID == '') 
    // {
    //   alert('Please select order!');
    //   return;
    // }

    // this.ObjOrderMaster.OrderPerpStatus = OrderPerpStatus.InRoute;
    // this.dataservice.post('LiveOrder/UpdateLiveOrder/', this.ObjOrderMaster)
    //   .subscribe(result => {
    //     if (result) {
    //       if (result == 'SUCCESS') {
    //         this.setActiveTAB('InRoute');
    //         this._popup.ShowPopup('Update');
    //         this.GetLiveOrdersStatusWise(OrderPerpStatus.InRoute);
    //       }
    //       else {
    //         this._popup.ShowPopup('Error');
    //       }
    //     }

    //   }, error => alert('error'));
  }

  RejectOrder(val): void {

    if (this.ObjOrderMaster.OrderID == undefined || this.ObjOrderMaster.OrderID == '') {
      alert('Please select order!');
      return;
    }
    this.setAuditFields();

    if (val == 'REJECTED') {
      this.ObjOrderMaster.OrderPerpStatus = OrderPerpStatus.Rejected;
    }
    else {
      this.ObjOrderMaster.OrderPerpStatus = OrderPerpStatus.Cancel;
    }

    let data = this.ObjOrderMaster;

    let dialogRef = this.dialog.open(RejectPopupComponent, {
      width: '700px',
      
      data: { data },
    });

    dialogRef.afterClosed().subscribe(result => {
      
      if (result != undefined) {
        this.setActiveTAB( this.translatePipe.transform('ALL'));
        this.GetLiveOrdersStatusWise(OrderPerpStatus.All);
        this.audio.pause();
      }
    });


    //this.ObjOrderMaster.PrepIn = 0;
    //if (val === 'Reject') {

    //  this.ObjOrderMaster.OrderPerpStatus = OrderPerpStatus.Rejected;
    //}
    //else {
    //  this.ObjOrderMaster.OrderPerpStatus = OrderPerpStatus.Cancel;
    //}
    //this.ObjOrderMaster.OrderPerpStatus = OrderPerpStatus.Rejected;
    //this.dataservice.post('LiveOrder/UpdateRejectLiveOrder/', this.ObjOrderMaster)
    //  .subscribe(result => {
    //    if (result) {
    //      if (result == 'SUCCESS') {
    //        this._popup.ShowPopup('Update');
    //        this.GetLiveOrdersStatusWise(OrderPerpStatus.New);
    //      }
    //      else {
    //        alert('error');
    //      }
    //    }

    //  }, error => alert('error'));
  }

  CompleteOrder(): void {

    if (this.ObjOrderMaster.OrderID == undefined || this.ObjOrderMaster.OrderID == '') {
      alert('Please select order!');
      return;
    }
    this.setAuditFields();

    this.ObjOrderMaster.OrderPerpStatus = OrderPerpStatus.Delivered;
    this._spinnerService.show();
    this.dataservice.post('LiveOrder/UpdateLiveOrder/', this.ObjOrderMaster)
      .subscribe(result => {
        if (result) {
          debugger
          if (result == 'SUCCESS') {
            
            if (this.InRouteCount > 1) {
              this.setActiveTAB( this.translatePipe.transform('INRouteReady'));
              this.GetLiveOrdersStatusWise(OrderPerpStatus.InRoute);
            } else {
              this.setActiveTAB( this.translatePipe.transform('ALL'));
              this.GetLiveOrdersStatusWise(OrderPerpStatus.All);
            }
            
            this._spinnerService.hide();
            this._popup.ShowPopup('Update');
            

          }
          else {
           setTimeout(() => {
             this._spinnerService.hide(); this._popup.ShowPopup('Error')}, 100);
          }
        }

      }, error => console.log(this.Utilities.getconnectionmsg()));
  }

  RefreshOrders(): void {
    let status = OrderPerpStatus.All;

    if (this.ActiveTabNew)
      status = OrderPerpStatus.New;
    else if (this.ActiveTabInKitchen)
      status = OrderPerpStatus.InKitchen;
    else if (this.ActiveTabReadyToCollect)
      status = OrderPerpStatus.InRoute;

    this.GetLiveOrdersStatusWise(status);
  }


  isArrayHasValue(obj: any[]): boolean {
    let isArrayWithLength = Boolean(Array.isArray(obj) && obj.length);
    return isArrayWithLength;
  }

  playAudio() {




    this.audio.load();
    this.audio.preload = 'auto';
    this.audio.pause();
    this.audio.play();
    this.audio.autoplay = true;
    this.audio.loop = true;

    this.audio.play().catch((e) => {
      /* error handler */
    });
  }



}
export class OrderMaster {
  OrderID: any;
  OrderInvoiceNo: any = '';
  PrepIn: any = '';
  PrepInOutlet: any = '';
  OrderPerpStatus: any = 'ALL';
  Brand: any = '';
  OutletID: any = '';
  OutletId: any = '';
  OutletName: any = '';
  OrderType: any = '';
  Address: any = '';
  CustomerID: any = '';
  CustomerName: any = '';
  CustomerEmail: any = '';
  CountryCode: any = '';
  PhoneNo: any = '';
  PayType: any = '';
  Channel: any = '';
  DeliveryTime: any= '';
  PickupFrom: any = '';
  Remarks: any = '';
  SubTotal: any = '';
  TotalTaxAmount: any = '';
  TotalAmount: any = '';
  DeliveryFee: any = '';
  NetAmount: any = '';
  TotalDiscount: any = '';
  TaxID: any = '';
  TaxDescription: any = '';
  TaxPercent: any = '';
  OrderDate: any;
  OrderDateReal: any;
  CreatedOn: any;
  CreatedBy: any;
  ModifyOn: any;
  ModifyBy: any;
  RejectReasonID: any;
  RejectReason: any;
  RiderID: any;
  CompanyId: any;
  RejectReasonDetail: any = '';
  AddressPK: any = '';
  OrderPayStatus: any = '';
  PromoCode: any;
  PromoAmount: any;
  CompanyName : any;
  OrderplaceTime : any;
  CountryName: any;
  LoyaltyAmount: any;
  ShiftID : any;
  IsExtraCharge : any;
  DeliverectIsOrderCancel:any;

}
export class OrderDetail {
  OrderDetailID: any;
  OrderID: any;
  ProductId: any;
  ProductName: any = '';
  Quantity: any;
  UnitID: any;
  AddOnID: any;
  AddOnName: any;
  ProductDetailID: any;
  ProductSizeName: any = '';
  Amount: any;
  Description: any = '';
  OrignalAmount: any;
}
class Country {
  CurrencyCode: any = '';
  Rate: any = '';
  NumOfDecimal: any = '';
  CountryName: any = '';
  CountryCode: any = '';
}
export class VoucherData {
  RowID: any;
  OrderId: any;
  VoucherCode: any;
  VoucherId: any;
  IsPercent: any;
  VoucherAmount: any;
  CompanyId: any;
}
