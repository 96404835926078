import { Component, OnInit, Inject} from '@angular/core';
import { DataserviseService } from '../../../shared/dataservice';
import { PopupService } from '../../../shared/popup';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  NbToastrService,
} from '@nebular/theme';
import { UtilitiesService } from '../../../shared/utilities.service';
import { TranslatePipe } from '../../../shared/pipe/translate.pipe';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'ngx-retailitempoppup',
  templateUrl: './retailitempoppup.component.html',
  styleUrls: ['./retailitempoppup.component.scss']
})
export class RetailItempoppupComponent implements OnInit {

  lstarray = [];
  OutletId: any;
  UserId: any;
  culture: any = 'en';
  CounterId: any;
  ModuleCode: any;

  lblProducts: any = this.translatePipe.transform('Products');
  NumOfDecimal: any;

  constructor(private translatePipe: TranslatePipe, private dataservice: DataserviseService, private popup: PopupService, private toastrService: NbToastrService, public dialogRef: MatDialogRef<RetailItempoppupComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private Utilities: UtilitiesService, private _DecimalPipe: DecimalPipe) {
    debugger
    this.OutletId = this.data.OutletId;
    this.UserId = this.data.UserId;
    this.culture = this.data.Culture;
    this.CounterId = this.data.CounterId;
    this.ModuleCode = this.data.ModuleCode;
    this.NumOfDecimal = this.data.NumOfDecimal;
  }


  ngOnInit(): void {
    debugger
    this.LoadGrid();

  }

  settings = {
    actions: {
      columnTitle: '',
      add: false,
      delete: false,
      edit: false,
    },

    columns: {
      ProductName: {
        title: this.translatePipe.transform('ProductName'),
        type: 'string',
      },
      CategoryName: {
        title: this.translatePipe.transform('CategoryName'),
        type: 'string',
      },

      Barcode: {
        title: this.translatePipe.transform('Barcode'),
        type: 'string',
      },
      UnitName: {
        title: this.translatePipe.transform('UnitName'),
        type: 'string',
      },

      UnitCost: {
        title: this.translatePipe.transform('UnitCost'),
        type: 'html',
        valuePrepareFunction: (value, row, cell) => {
          return this._DecimalPipe.transform(cell.row.data.UnitCost, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal);
        },
      },
    },
    attr: {
      class: 'table table-bordered handicursor',
    },
    hideSubHeader: false,
  };

  LoadGrid() {
    debugger
    this.lstarray = [];

    this.dataservice.get('retailpos/GetPOSSetup/' + this.Utilities.getSubscriptionID() + '/' + this.OutletId + '/' + this.UserId + '/' + this.culture + '/' + this.CounterId + '/' + this.ModuleCode).subscribe(data => {
      debugger
      if (this.isArrayHasValue(data[0].Items)) {
        debugger
        this.lstarray = data[0].Items;
      }
    })
  }

  isArrayHasValue(obj: any[]): boolean {
    let isArrayWithLength = Boolean(Array.isArray(obj) && obj.length);
    return isArrayWithLength;
  }


  selectedvalue: any = [];
  onUserRowSelect(event): void {
    debugger;
    this.selectedvalue = [{ 'Barcode': event.data["Barcode"], 'ProductName': event.data["ProductName"], 'ProductID': event.data["ProductID"], 'UnitName': event.data["UnitName"], "UnitID": event.data["UnitID"], "TaxabilityID": event.data["TaxabilityID"], "TaxRate": event.data["TaxRateActual"], "ExtraChargePercentage": event.data["ExtraChargePercentage"], "UnitCost": event.data["UnitCost"], "Price": event.data["UnitCost"], "Type": event.data["Type"] }]

    this.dialogRef.close(event.data);
  }

  btnclose(): void {
    debugger
    this.dialogRef.close(undefined);
  }
}
