import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslatePipe } from '../shared/pipe/translate.pipe';

@Component({
  selector: 'ngx-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss'],
})
export class AboutUsComponent {
  // firstFormGroup: FormGroup;
  // secondFormGroup: FormGroup;
  // isEditable = false;

  showPopupIsDayShiftClosed: boolean = false;
  showPopupKitchenDisplayUnderProcess: boolean = false;
  showPopupIsSessionClosed: boolean = false;
  showPopupHoldOrder: boolean = false;
  showECRPaymentPopup: boolean = false;
  IsAccessCashierScreen: boolean = false;
  InvalidCompany: boolean = false;
  InvalidPIN: boolean = false;
  InvalidCounter: boolean = false;
  EnterPOSPin: boolean = false;

  PleaseOpenshiftforselectedBranch = this.translate.transform('PleaseOpenshiftforselectedBranch');
  SessionClosed = this.translate.transform('SessionClosed');
  OrderHold = this.translate.transform('OrderHold');
  IsAccessCashierScreenmsg = this.translate.transform('IsAccessCashierScreenmsg');

  msgInvalidCompany = this.translate.transform('msgInvalidCompany');
  msgInvalidPIN = this.translate.transform('msgInvalidPIN');
  msgInvalidCounter = this.translate.transform('msgInvalidCounter');
  msgPOSPin = this.translate.transform('msgPOSPin');
  constructor(private translate: TranslatePipe, public dialogRef: MatDialogRef<AboutUsComponent>, @Inject(MAT_DIALOG_DATA) public parentData: any) { }

  ngOnInit(): void {
    debugger;
    if (this.parentData.message == 'DayShiftClosed')
      this.showPopupIsDayShiftClosed = true;
    else if (this.parentData.message == 'KitchenDisplay')
      this.showPopupKitchenDisplayUnderProcess = true;
    else if (this.parentData.message == 'sessionclosed')
      this.showPopupIsSessionClosed = true;
    else if (this.parentData.message == 'holdorder')
      this.showPopupHoldOrder = true;
    else if (this.parentData.message == 'IsAccessCashierScreen')
      this.IsAccessCashierScreen = true;
    else if (this.parentData.message == 'InvalidCompany')
      this.InvalidCompany = true;
    else if (this.parentData.message == 'InvalidPIN')
      this.InvalidPIN = true;
    else if (this.parentData.message == 'InvalidCounter')
      this.InvalidCounter = true;
    else if (this.parentData.message == 'EnterPOSPin')
      this.EnterPOSPin = true;
    else
      this.showECRPaymentPopup = true;

    if (this.showECRPaymentPopup == true)
      this.ECRPaymentmessageHandling();
  }
  btnclose(): void {
    this.dialogRef.close(this.parentData);
  }
  showsuccessimg: boolean = false;
  status: string = '';
  ECRPaymentmessageHandling() {

    console.log(this.parentData);
    // Get the "transactionData" value from the JSON
    const transactionData = this.parentData.transactionData;
    console.log(transactionData);
    // Remove \u0002; and \u0003 from the transaction data, then split by semicolon
    const splitTransactionData: string[] = transactionData.replace(/\u0002;|\u0003/g, '').split(';');
    console.log(splitTransactionData);
    // Check if "APPROVED" is present in the array
      if (splitTransactionData.includes('APPROVED')) {
      this.showsuccessimg = true;
    }
    this.status = splitTransactionData[2];
  }
}
