import { Component, ɵangular_packages_core_core_bp, ChangeDetectorRef } from '@angular/core';
import { DataserviseService } from '../../shared/dataservice';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { UtilitiesService } from '../../shared/utilities.service';
import {TranslatePipe} from '../../shared/pipe/translate.pipe';

@Component({
  selector: 'ngx-shiftendreceipt',
  templateUrl: './shiftendreceipt.component.html',
  styleUrls: ['./shiftendreceipt.component.scss'],
})

export class shiftendreceiptComponent {
  ObjCompany: Company = new Company();
  CompanyName: string = '';
  daydate: any;
  shiftId: any;

  InvalidCompanyName: boolean = false;
  // ObjOrderMaster: OrderMaster = new OrderMaster();

  // ArrVoucherData: VoucherData[] = [];
  ObjCurrency: Country = new Country();

  ObjCompanyInfo: CompanyInfo = new CompanyInfo();
  ObjDayInfo: DayInfo = new DayInfo();

  ObjSalessummary: Salessummary = new Salessummary();
  objCashOUt: CashOUt = new CashOUt();
  ObjSales: Sales = new Sales();
  ArrSalesDetail = [];
  ArrProductDetail = [];
  ArrCoupansDetail = [];
  ArrPOSTenders = [];
  ArrWorkedHour: any;
  ArrOvershort: any;
  ArrCashOutData = [];
  CurrencyCode: any = '';
  QRUrl: any = '';
  TotalSale: any;
  TotalCoupans: any = 0.00;

  TotalCashIn: any = 0;
  TotalCashOUt: any = 0;
  AvgSalePerEmployee: any = 0;

  ShiftEndReport : any = this.translate.transform('ShiftEndReport');
  PrintDate : any = this.translate.transform('PrintDate');
  ShiftStartDate : any = this.translate.transform('ShiftStartDate');
  ShiftEndDate : any = this.translate.transform('ShiftEndDate');
  EmployeeNamelabel : any = this.translate.transform('EmployeeName');
  HoursWorked : any = this.translate.transform('HoursWorked');
  Products : any = this.translate.transform('ProductDetails');
  Noofcustomers : any = this.translate.transform('Noofcustomers');
  NumberofTransactions : any = this.translate.transform('NumberofTransactions');
  TotalSubtotalExclVAT : any = this.translate.transform('TotalSubtotalExclVAT');
  TotalDiscountCoupons : any = this.translate.transform('TotalDiscountCoupons');
  TotalTaxAmount : any = this.translate.transform('TotalTaxAmount');
  TotalNetAmountInclVAT : any = this.translate.transform('TotalNetAmountInclVAT');
  CashInAmount : any = this.translate.transform('CashInAmount');
  AverageNumberofCustomers : any = this.translate.transform('AverageNumberofCustomers');
  AverageTransactionsValue : any = this.translate.transform('AverageTransactionsValue');
  AveragevaluePerCustomers : any = this.translate.transform('AveragevaluePerCustomers');
  Coupons : any = this.translate.transform('Coupons');
  ProductTotal : any = this.translate.transform('ProductTotal');
  CouponsTotal : any = this.translate.transform('CouponsTotal');
  CancelVoidAmount : any = this.translate.transform('CancelVoidAmount');
  POSTendered : any = this.translate.transform('POSTendered');
  Sales : any = this.translate.transform('Sales');
  OverShortBaseCurrency : any = this.translate.transform('OverShortBaseCurrency');
  Printorder : any = this.translate.transform('Printorder');
  Item : any = this.translate.transform('Item');
  Qty : any = this.translate.transform('Qty');
  Amount : any = this.translate.transform('Totals');
  POSCashOut : any = this.translate.transform('POSCashOut');
  TIPAmount : any = this.translate.transform('TIPAmount');
  SalesReturnAmount: any = this.translate.transform('SalesReturnAmount');




  printdate = this.Utilities.GetCurrentDateTime()


  constructor(private translate: TranslatePipe, private router: Router, private route: ActivatedRoute, private dataservice: DataserviseService, private activatedRoute: ActivatedRoute, private Utilities: UtilitiesService, private cdr: ChangeDetectorRef) { }


  title = 'app';
  elementType = 'img';
  elementType1 = 'url';
  logo: any = '../../../assets/images/globalinformix.png';
  lstUser = [];
  ngOnInit(): void {

    debugger


    this.InvalidCompanyName = false;
    debugger
    this.activatedRoute.paramMap.subscribe(x => {
      this.CompanyName = x.get('CompanyId');
      this.shiftId = x.get('ShiftId');
      this.daydate = x.get('daydate');



      if (this.CompanyName == null) {
        this.InvalidCompanyName = true;
        return;
      }
    });


    // const body = [this.Utilities.getDecrypt(this.Utilities.getCompanyName())];
    // const body = [this.Utilities.getCompanyName()]
    // this.dataservice.post('OrderOnline/GetCompanyData/', body)
    //   .subscribe(data => {

    //     debugger
    //     if (this.isArrayHasValue(data[0]['CompanyData'])) {
    //       this.InvalidCompanyName = false;
    //       this.ObjCompany.CompanyID = data[0]['CompanyData'][0].CompanyID;
    //       this.ObjCompany.CompanyName = data[0]['CompanyData'][0].CompanyName;
    //       this.ObjCompany.outletsCount = data[0]['CompanyData'][0].outletsCount;
    //       this.ObjCompany.CountryID = data[0]['CompanyData'][0].CountryID;
    //       this.ObjCompany.hearbout = data[0]['CompanyData'][0].hearbout;
    //       this.ObjCompany.Mobile = data[0]['CompanyData'][0].Mobile;
    //       this.ObjCompany.IsOTPRequired = data[0]['CompanyData'][0].IsOTPRequired;
    //       this.ObjCompany.CurrencyCode = data[0]['CompanyData'][0].CurrencyCode;
    //       this.ObjCompany.Rate = data[0]['CompanyData'][0].Rate;
    //       this.ObjCompany.NumOfDecimal = data[0]['CompanyData'][0].NumOfDecimal;
    //       this.ObjCompany.CountryCode = data[0]['CompanyData'][0].CountryCode;

    //       this.CompanyName = this.Utilities.getDecrypt(this.CompanyName);
    //       this.getMasterDetailData(this.Utilities.getDecrypt(this.shiftId), this.Utilities.getDecrypt(this.daydate));
    //     }
    //     else {
    //       this.InvalidCompanyName = true;
    //       this.QRUrl = 'Invalid company name!';
    //       alert('Invalid company name!');
    //     }
    //   }, error => alert(this.Utilities.getconnectionmsg()));
    
    this.getMasterDetailData(this.Utilities.getDecrypt(this.shiftId), this.Utilities.getDecrypt(this.daydate));
    debugger
    this.lstUser = JSON.parse(localStorage.getItem('sessionObject'+this.Utilities.getURLCompanyName()));


  }

  printComponent() {
    //debugger
    //let myContainer = document.getElementById('invoice-POS') as HTMLInputElement;

    //var docprint = window.open('', 'PRINT', '');
    //docprint.document.write(myContainer.innerHTML);
    //docprint.document.close();
    //docprint.print();
    //docprint.close();

    window.print();
  }

  LoyaltyPoints: any = 0;
  WorkedHour: any = 0;
  Overshort: any = 0;
  EmployeeName : any = '';
  TotalNetAmount : any;
  IsRcptProWise : boolean = false;
  ShiftDayReportType : any;
  TotalLoyaltyAmount:any
  
 async getMasterDetailData(shiftId, daydate){
    // this.ObjOrderMaster = new OrderMaster();
    // this.ArrOrderDetail = [];

    await this.dataservice.get('Receipt/GetShiftEndReceipt/' + this.Utilities.getCompanyName() + '/' + daydate + '/' + shiftId+ '/' + localStorage.getItem('LanguageCulture'+this.Utilities.getURLCompanyName()))
      .subscribe(data => {

        debugger

        if (this.isArrayHasValue(data[0]['CompanyData'])) 
        {
          this.InvalidCompanyName = false;
          this.ObjCompany.CompanyID = data[0]['CompanyData'][0].CompanyID;
          this.ObjCompany.CompanyName = data[0]['CompanyData'][0].CompanyName;
          this.ObjCompany.outletsCount = data[0]['CompanyData'][0].outletsCount;
          this.ObjCompany.CountryID = data[0]['CompanyData'][0].CountryID;
          this.ObjCompany.hearbout = data[0]['CompanyData'][0].hearbout;
          this.ObjCompany.Mobile = data[0]['CompanyData'][0].Mobile;
          this.ObjCompany.IsOTPRequired = data[0]['CompanyData'][0].IsOTPRequired;
          this.ObjCompany.CurrencyCode = data[0]['CompanyData'][0].CurrencyCode;
          this.ObjCompany.Rate = data[0]['CompanyData'][0].Rate;
          this.ObjCompany.NumOfDecimal = data[0]['CompanyData'][0].NumOfDecimal;
          this.ObjCompany.CountryCode = data[0]['CompanyData'][0].CountryCode;
          this.CompanyName = this.Utilities.getDecrypt(this.CompanyName);
          // this.getMasterDetailData(this.Utilities.getDecrypt(this.shiftId), this.Utilities.getDecrypt(this.daydate));
        }
        else 
        {
          this.InvalidCompanyName = true;
          this.QRUrl = 'Invalid company name!';
          alert('Invalid company name!');
        }
        if (this.isArrayHasValue(data[0]['Product'])) 
        {
          this.ArrSalesDetail = data[0]['Product'];
          this.TotalSale  = this.ArrSalesDetail.map(a => a.NetAmount).reduce(function(a, b)
          {
             return a + b;
           });

           if(this.IsRcptProWise)
           {
            this.Products  = this.translate.transform('ProductDetails');
            }
           else
           {
            this.Products  = this.translate.transform('CategoryDetails');
           }
           
        }
        else {
          this.ArrSalesDetail = [];
        }
        if (this.isArrayHasValue(data[0]['ProductDetails'])) {
          this.ArrProductDetail = data[0]['ProductDetails'];         
        }
        else {
          this.ArrProductDetail = [];
        }
        if (this.ArrSalesDetail.length > 0)
        { this.TotalSale = this.ArrSalesDetail.map(a => a.NetAmount).reduce(function (a, b) { return a + b; });}
        else
        {  this.TotalSale = 0;}

        if (data[0]['Salessummary']) 
        { this.ObjSalessummary = data[0]['Salessummary'][0] }
        if (data[0]['CompanyInfo']) 
        {this.ObjCompanyInfo = data[0]['CompanyInfo'][0];}
        if (data[0]['Currency']) 
        {
          this.ObjCurrency = data[0]['Currency'][0];
          this.CurrencyCode = this.ObjCurrency.CurrencyCode;
        }

        if (data[0]['LoyaltyAmount']) 
        {
           this.TotalLoyaltyAmount = data[0]['LoyaltyAmount'];
        }
        if (this.isArrayHasValue(data[0]['Coupons'])) 
        {
          this.ArrCoupansDetail = data[0]['Coupons'];
          this.TotalCoupans = this.ArrCoupansDetail.map(a => a.PromoAmount).reduce(function (a, b) {
            return a + b;
          });
        }
        else 
        {
          this.ArrCoupansDetail = [];
          this.TotalCoupans = 0.00;
        }
        if (this.isArrayHasValue(data[0]['POSTenders']))
        {
          this.ArrPOSTenders = data[0]['POSTenders'];
        }
        else 
        {
          this.ArrPOSTenders = [];
        }
        if (this.isArrayHasValue(data[0]['CashOutData'])) 
        {
          this.ArrCashOutData = data[0]['CashOutData'];
        }
        else 
        {
          this.ArrCashOutData = [];
        }
        if (this.isArrayHasValue(data[0]['WorkedHour'])) 
        {
          this.ArrWorkedHour = data[0]['WorkedHour'];
          this.WorkedHour = this.ArrWorkedHour[0].WorkedHour
        }
        else 
        {
          this.ArrWorkedHour = 0.00;
        }
debugger
        if (this.isArrayHasValue(data[0]['Overshort'])) 
        {
          this.ArrOvershort = data[0]['Overshort'];
          // this.Overshort = this.ArrOvershort[0].Overshort
        }
        else 
        {
          this.ArrOvershort = [];
          this.Overshort = 0.00;
        }
        if (this.isArrayHasValue(data[0]['EmployeeName'])) 
        {
          this.EmployeeName = data[0]['EmployeeName'][0].EmployeeName;          
        }
        if (data[0]['ShiftDayReportType']) 
        { 
          this.ShiftDayReportType = data[0].ShiftDayReportType[0]["ShiftDayReportType"] 
         
          if(this.ShiftDayReportType == 1)
          {this.IsRcptProWise = true;}
          else if(this.ShiftDayReportType == 2)
          {this.IsRcptProWise = false;}
          else
          {
            this.IsRcptProWise = false;
          }
        }
        let sum = this.ObjSalessummary.TotalAmountNoTax + this.ObjSalessummary.TotalTaxAmount ;
        this.TotalNetAmount = sum - this.TotalCoupans;
        this.cdr.detectChanges();
        window.print();
      });
  }


  isArrayHasValue(obj: any[]): boolean {
    let isArrayWithLength = Boolean(Array.isArray(obj) && obj.length);
    return isArrayWithLength;
  }

}

class Salessummary {
  NoOfTrans: any;
  NoOfCust: any;
  TotalAmountNoTax: any;
  AvgCust: any;
  AvgTrans: any;
  AvgPerValueCust: any;
  TotalTaxAmount : any;
  ShiftDate : any;
  ShiftEndDate : any;
  TotalDiscount : any;
  NetAmount : any;
  CashInAmount : any;
  ExtraChargeAmount:any;
  ExtraChargeDescription:any;
  CancelVoidAmount : any;
  TIPAmount : any;
  SalesReturnAmount: any = 0;
}
class CashOUt {
  CashOutLocal: any;
  CashOutForeign: any;
}
class Sales {
  PayType: any;
  Amount: any
}
class DayInfo {
  DayDate: any;
  StartTime: any;
  EndTime: any;
  Name: any;
}
class Country {
  CurrencyCode: any = '';
  Rate: any = '';
  NumOfDecimal: any = '';
  CountryName: any = '';
  CountryCode: any = '';
}
class Company {
  CompanyID: any;
  CompanyName: any;
  outletsCount: any;
  CountryID: any;
  hearbout: any;
  Mobile: any;
  CurrencyCode: any;
  Rate: any;
  NumOfDecimal: any;
  CountryCode: any;
  IsOTPRequired: boolean;
}


class CompanyInfo {
  ID: any;
  TextColor: any = '';
  BaseColor: any = '';
  Title: any = '';
  Message: any = '';
  MainImagePath: any = '';
  MainImageName: any = '';
  IconImagePath: any = '';
  IconImageName: any = '';
  LogoImagePath: any = '';
  LogoImageName: any = '';
  BrandName: any = '';
  VATNo: any = '';
  ReceiptMsg: any = '';
}
