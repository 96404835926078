import { Component, OnInit, Inject} from '@angular/core';
import { DataserviseService } from '../../../../shared/dataservice';
import { PopupService } from '../../../../shared/popup';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrderMaster } from './../liveorder.component';
import { NbToastrService } from '@nebular/theme';
import { SpinnerService } from '../../../../shared/spinner';
import { TranslatePipe } from '../../../../shared/pipe/translate.pipe';

import { UtilitiesService } from '../../../../shared/utilities.service';
enum OrderPerpStatus {
  All = 'All',
  New = 'New',
  InKitchen = 'InKitchen',
  InRoute = 'InRoute',
  Rejected = 'REJECTED',
  Delivered = 'Delivered',
  Cancel = 'Cancel',
}
@Component({
  selector: 'ngx-rejectpopup',
  styleUrls: ['./rejectpopup.component.scss'],
  templateUrl: './rejectpopup.component.html',
})

export class RejectPopupComponent implements OnInit {

  ObjOrderMaster: OrderMaster = new OrderMaster();
  btnText: any = '';

  constructor(private Utilities: UtilitiesService, private dataservice: DataserviseService, private popup: PopupService, private toastrService: NbToastrService, public dialogRef: MatDialogRef<RejectPopupComponent>, @Inject(MAT_DIALOG_DATA) public data: any,private _spinnerService: SpinnerService,private translatePipe : TranslatePipe) {
  debugger
    if(this.data.data)
    this.ObjOrderMaster = this.data.data;
    if(this.data.parentdata){
      this.ObjOrderMaster.OrderID = this.data.parentdata
    }
  }
  ngOnInit(): void {
    this.GetDropdownSetups();

    if (this.ObjOrderMaster.OrderPerpStatus == 'REJECTED') {
      this.btnText = this.translatePipe.transform('Reject');// 'Reject';
    }
    else 
    {
      this.btnText = this.translatePipe.transform('Cancel');//'Cancel';
    }
    this.ObjOrderMaster.RejectReasonDetail = '';

    //console.log(this.ObjOrderMaster);
  }
  ClosePopup(): void {
    this.dialogRef.close(undefined);
  }

  ddlRejectReasons: any = [];
  RejectReasonID: any;

  GetDropdownSetups(): void {

    this.dataservice.get('LiveOrder/GetSetupData/' + 'G')
      .subscribe(data => {

        if (this.isArrayHasValue(data[0]['RejectReasons'])) {
          this.ddlRejectReasons = data[0]['RejectReasons'];
          this.ObjOrderMaster.RejectReasonID = this.ddlRejectReasons[0].RejectReasonID;
        }
      }, error => alert('error'));

  }

  setAuditFields(): void {
    this.ObjOrderMaster.CreatedBy = this.Utilities.getUserId();
    this.ObjOrderMaster.CreatedOn = this.Utilities.GetCurrentDateTime();
    this.ObjOrderMaster.ModifyBy = this.Utilities.getUserId();
    this.ObjOrderMaster.ModifyOn = this.Utilities.GetCurrentDateTime();
    this.ObjOrderMaster.CompanyId = this.Utilities.getSubscriptionID();
  }

  maxChars: number = 500;

  RejectOrder(): void {debugger

    this.ObjOrderMaster.RejectReason = document.getElementById('IdRejectDD').innerText;
    if (this.ObjOrderMaster.OrderID == undefined || this.ObjOrderMaster.OrderID == '') {
      alert('Please select order!');
      return;
    }
    if (this.ObjOrderMaster.RejectReasonID == undefined || this.ObjOrderMaster.RejectReasonID == '') {
      alert('Please select Reject reason!');
      return;
    }
    this.setAuditFields();
    this._spinnerService.show();
    debugger
    this.dataservice.post('LiveOrder/UpdateLiveOrder/', this.ObjOrderMaster)
      .subscribe(result => {
        if (result) {
          if (result == 'SUCCESS') {
            this._spinnerService.hide();
            this.dialogRef.close(result);
          }
          else {
            setTimeout(() => {this._spinnerService.hide();alert('No orders found!')}, 3000);
          }
        }

      }, error => alert('error'));
  }

  OnRejectedReasonChange(values){
    debugger
    this.ddlRejectReasons.filter
  }
  isArrayHasValue(obj: any[]): boolean {
    let isArrayWithLength = Boolean(Array.isArray(obj) && obj.length);
    return isArrayWithLength;
  }
 
}
