<nb-card style="margin-bottom:0px!important">
  <nb-card-header>
    <!-- <div class="form-group col-md-12">
      <div class="row">
        <div class="col-md-10 cls2">
          {{btnText}} Order
        </div>

        <div class="col-md-2 cls2">
          OrderInvoiceNo:123
        </div>
      </div>
      </div> -->
      
  </nb-card-header>

  <nb-card-body>
    <div class="form-group col-md-12">
      <div class="row">
        <div class="col-md-3">
        </div>
        <div class="">
          <label class="label clsf">{{Pleaseselectareason}}</label>
        </div>
      </div>

      <div class="row">
        <div class="form-group" style="width:100%;text-align:center">
          <nb-select [(selected)]="objOpenDrawer.ReasonID" style="width:100%">
            <nb-option *ngFor="let _data of ddlRejectReasons" [value]="_data.ID">{{_data.ReasonDescription}}</nb-option>
          </nb-select>

        </div>
      </div>

      <div class="row">
        <div class="col-md-3">
        </div>
        <div class="">
          <label class="label clsf">{{Description}}</label>
        </div>
      </div>

      <div class="row">
        <div class="form-group" style="width:100%;text-align:center">
          <textarea nbInput style="width:100%" [(ngModel)]="objOpenDrawer.ReasonDescription" [maxlength]="maxChars"></textarea>
          <br />
          <label style="font-size:12px"> {{CharachtersRemaining}} {{RejectReason.length}}/{{maxChars}}</label>
        </div>
      </div>

   

    </div>

    <div class="form-group col-md-12">

      <div class="row">
        <div class="col-md-10">
        </div>

        <div class="col-md-2">
        </div>

      </div>
    </div>

    <div class="form-group col-md-12 col-sm-12">

      <div class="row">
        <div class="col-md-8 col-sm-6 cls2">
          <button nbButton status="basic" (click)="ClosePopup()">{{Close}}</button>
        </div>

        <div class="col-md-4 col-sm-6 cls2">
          <button nbButton status="danger" (click)="OpenCashdrawerClcik()">{{OpenCashdrawer}}</button>
        </div>

      </div>
    </div>
  </nb-card-body>
</nb-card>
