<div class="row" style="background-color: #fd7e14; height: 45px;">
        <div class="col-md-12 pt-2" ><label style="font-weight: 600;color: white; font-size: 15px; text-align: center; width: 100%;">{{ItemBumping}}</label></div>
  
</div>
            <div class="row pt-2" style="height:480px;overflow-y:scroll">
                    <div class="col-md-12" style=" border-color: #fd7e14;  ">                    
            
                            <div *ngFor="let itemdtl of lstarrayDetails" >
                                <div  style="height: 35px; display: flex;border-bottom: 1px solid #dbd6d6; font-size: 13px; color: #434444; font-weight: 600;">
                                <div style="width: 90%; margin-top: 5px;">
                                     <span *ngIf="itemdtl.AddOnID == 0">{{itemdtl.Quantity}}X </span>
                                     <span *ngIf="itemdtl.AddOnID == 0" style="margin: 10px;">{{itemdtl.ProductName}}</span>
                                     <span *ngIf="itemdtl.AddOnID != 0" style="padding-left:27px ;">{{itemdtl.AddOnName}}</span>
                                     </div>
                                <div style="width: 10%; text-align: end;margin-top: 5px;"><nb-checkbox status="success" (change)="checkValue(itemdtl,$event)" *ngIf="ITemClickEvent" ></nb-checkbox></div>                                    
                                </div>
                                <!-- <div style="height: 35px; display: flex;border-bottom: 1px solid #dbd6d6; font-size: 13px; color: #434444; font-weight: 600;">
                                <div><span *ngIf="itemdtl.AddOnID == 0">{{itemdtl.Quantity}}X </span> </div>
                                <div style="padding-left:15px ;">
                                    <span *ngIf="itemdtl.AddOnID == 0">{{itemdtl.ProductName}} </span>
                                    <span *ngIf="itemdtl.AddOnID != 0" style="float:left ;">{{itemdtl.AddOnName}}</span> 
                                </div>
                                <div style="width: 10%; text-align: end;margin-top: 5px;"><nb-checkbox status="success" (change)="checkValue(itemdtl,$event)" *ngIf="ITemClickEvent" ></nb-checkbox></div>
                            </div>  -->
                          
                        </div>
                    </div>    
            </div>
   
    
    
        <div class="row fixedDiv" style="padding-top: 5px; background-color: #434444;">
            <div class="col-md-4 col-lg-4 col-sm-6 col-xs-6 colHeight" style="text-align: center;">
                <button class="btnHW" style="background-color: #00d68f; color: white;font-size: 16px; font-weight: 600; border-style: none;" (click)="BumpClick()">Bump</button>
            </div>
            <div class="col-md-4 col-lg-4 col-sm-6 col-xs-6 colHeight" style="text-align: center;" >
              
            </div>         
            <div class="col-md-4 col-lg-4 col-sm-6 col-xs-6 colHeight"  style="text-align: center;">
                <button class="btnHW" style="background-color: #fe5252; color: white;font-size: 16px; font-weight: 600; border-style: none;" (click)="Close()" >Close</button>
            </div>
                 
        </div>
            