import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataserviseService } from '../../shared/dataservice';
import { UtilitiesService } from '../../shared/utilities.service';
import { OutletInfo } from '../pos/pos-retail.component'
import { PopupService } from '../../shared/popup';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { SpinnerService } from '../../shared/spinner';
import { ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { DecimalPipe, getLocaleDateFormat } from '@angular/common';
import { LocalDataSource } from 'ng2-smart-table';
import { TranslatePipe } from '../../shared/pipe/translate.pipe';
import { AboutUsComponent } from '../../about-us/about-us.component';
import { DeliveryPopupComponent } from '../delivery-popup/delivery-popup.component';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'ngx-calculator-popup',
  templateUrl: './calculator-popup.component.html',
  styleUrls: ['./calculator-popup.component.scss']
})
export class CalculatorPopupComponent implements OnInit {
  @ViewChild("printfarme") printfarme: ElementRef;

  TotalAmount: any;
  cashPaidAmount = '';
  ReturnAmount: any = 0;
  TipAmount: any = 0;
  // Calc_value:any;
  lstbasketdata = [];
  CompanyId: number;
  MobileNo: any;
  MaxCreditLimit: any;
  GuestCutomer = [];
  basketList = [];
  selectedProducts = [];
  DeliveryFee: number = 0;
  rdPaymentMethod: any = 'Cash';
  objoutletInfo: OutletInfo = new OutletInfo();
  // objPaymentDetail: paymentDetail =new paymentDetail();
  HoldOrderID: any = 0;
  Remarks: any;
  Subtoal: any = '';
  DiscountAmont: any = '';
  VAT: any = '';
  OrderType: any = 'N/A';
  OrderTypeID: any = 0;
  OrderPerpStatus: any = 'New';
  DiscountCode: any;
  TimeStampSelected: any = '30';
  TaxId: number = 0;
  lstSelectedModifiers: any = [];
  UserName: any;
  TableName: any;
  noOfCustomers: any;
  CustomerAddressID: any;
  IsComingKOT: boolean;
  KotOrderID: any;
  ReceiptURL: any;
  sumAmount: any = 0;
  logo = '../../../assets/images/globalinformix.png';
  // ReportUrl: SafeResourceUrl;
  // UrlString: string = "http://localhost:64764/api";

  moneyImg: string = './assets/images/money.PNG';
  lstVoucherPaymentDetail: any = [];
  ObjCompany: Company = new Company();
  CompanyName: string = '';
  OrderId: string = '';
  InvalidCompanyName: boolean = false;
  ObjOrderMaster: OrderMaster = new OrderMaster();
  ArrOrderDetail: OrderDetail[] = [];
  ObjCurrency: Country = new Country();
  ObjCompanyInfo: CompanyInfo = new CompanyInfo();
  PromoAmount: any = 0;
  PromoCode: any;
  CurrencyCode: any = '';
  NumOfDecimal: any = '';
  QRUrl: any = '';
  POSID: any;
  title = 'app';
  elementType = 'url';
  lstPaymentDetail = [];
  IsCashPaidAmountClear: boolean = true;
  loyaltypoints: any = 0;
  AvailingAmount: any = 0;

  IsExtraCharge: boolean = false;
  ExtraChargeDescription: any = '';
  ExtraChargeDescriptionArabic: any = '';
  ExtraChargePercentage: number = 0;
  ExtraChargeAmount: number = 0;
  IsCalculateTIPAmount: boolean = false;
  Back: any = this.translate.transform('Back')
  TotalPayment: any = this.translate.transform('TotalPayment')
  PaymentMethods: any = this.translate.transform('PaymentMethods')
  CLEAR: any = this.translate.transform('Clear')
  PAY: any = this.translate.transform('PAY')
  ReturnedCash: any = this.translate.transform('ReturnedCash')
  Reference: any = this.translate.transform('Reference')
  lblTotalPayment: any = this.translate.transform('TotalPayment')
  pleaseenterAmount: any = this.translate.transform('pleaseenterAmount')
  SessionClosed: any = this.translate.transform('SessionClosed')
  Customer: any = this.translate.transform('Customer')
  pleaseselectCustomer: any = this.translate.transform('Pleaseselectcustomer')
  ShowCustomerLookup: boolean = false;
  ShowERCPayButton: boolean = false;
  custName: any;

  settings = {
    actions: {
      columnTitle: '',
      add: false,
      edit: false,
    },
    mode: 'external',


    edit: {
      editButtonContent: '<i class="nb-edit"></i>',
      saveButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
      confirmSave: true
    },
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
      confirmDelete: true
    },
    columns: {
      // paymentName: {
      //   title: 'Payment Name'
      // },
      payMode: {
        title: this.translate.transform('PayMode')// 'Pay Mode'
      },
      payTime: {
        title: this.translate.transform('PayTime')//'Pay Time'
      },

      Amount: {
        title: this.translate.transform('CustomerPaid')//'Customer Paid'
      },
      NetAmount: {
        title: this.translate.transform('NetAmount')// 'Net Amount'
      },
      Reference: {
        title: this.translate.transform('Reference')//'Reference'
      },
    },
    attr: {
      class: 'table table-bordered'
    },
    hideSubHeader: true
  };
  source1: LocalDataSource;
  lstPaymentMode = [];
  rdPaymentcode: any;
  ECRIntegrationID: any;
  ActivateTipAmount: any;
  CustomerID: any;
  Status: any;
  SelectedBranchID: any;
  DayID: any;
  ShiftID: any;
  OrderPerpStatus123: any = 'New';
  SpecialRequest: any;
  IsCateringFood: boolean = false;
  objCateringFood: CateringFood = new CateringFood();
  lstComboProducts = [];
  constructor(private translate: TranslatePipe, public dialog: MatDialog, private domSanitizer: DomSanitizer, private router: ActivatedRoute, public dialogRef: MatDialogRef<CalculatorPopupComponent>, @Inject(MAT_DIALOG_DATA) public parentData: any, private dataservice: DataserviseService, private Utilities: UtilitiesService, private popup: PopupService, private spinerService: SpinnerService, private _decimalPipe: DecimalPipe, public http: HttpClient) {
  }

  ngOnInit(): void {
    // this.ReportUrl = this._domSanitizer.bypassSecurityTrustResourceUrl(this.UrlString);
    this.HoldOrderID = this.parentData["HoldOrderID"];
    this.TotalAmount = this.parentData["BillAmount"];
    this.NumOfDecimal = this.parentData['NumOfDecimal'];
    this.cashPaidAmount = this._decimalPipe.transform(this.TotalAmount, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal)
    this.arrTempAmount = this.cashPaidAmount;
    this.basketList = this.parentData["lstbasket"];
    this.lstbasketdata = this.parentData["lstbasket"];
    this.selectedProducts = this.parentData["lstSelectedProduct"]
    this.DeliveryFee = this.parentData["DeliveryFee"];
    this.objoutletInfo = this.parentData["outletInfo"];
    this.Subtoal = this.parentData["Subtoal"];
    this.DiscountAmont = this.parentData["DiscountAmont"];
    this.PromoCode = this.parentData['PromoCode'];
    this.PromoAmount = this.parentData['PromoAmount'];
    this.VAT = this.parentData["VAT"];
    this.lstSelectedModifiers = this.parentData["lstSelectedModifiers"];
    this.IsComingKOT = this.parentData['IsComingKOT'];
    this.KotOrderID = this.parentData['KotOrderID'];
    this.CurrencyCode = this.parentData['Currency'];
    this.CustomerID = this.parentData['CustomerID'];
    this.custName = this.parentData['custName'];
    this.Status = this.parentData['Status'];
    this.OrderType = this.parentData['OrderType'];
    this.SelectedBranchID = this.parentData['SelectedBranchID'];
    this.POSID = this.parentData['POSID'];
    this.DayID = this.parentData['DayID'];
    this.ShiftID = this.parentData['ShiftID'];
    this.OrderPerpStatus123 = this.parentData['OrderPerpStatus'];
    this.CompanyId = this.Utilities.getSubscriptionID();
    this.UserName = this.Utilities.getLoginUserName();
    this.TableName = this.parentData['TableName'];
    this.noOfCustomers = this.parentData['noOfCustomers'];
    this.CustomerAddressID = this.parentData['CustAddressID'];
    this.OrderTypeID = this.parentData['OrderTypeID'];
    this.lstVoucherPaymentDetail = this.parentData['VoucherPaymentDetail'];
    this.loyaltypoints = this.parentData['loyaltypoints'];
    this.AvailingAmount = this.parentData['AvailingAmount'];
    this.MobileNo = this.parentData['MobileNo'];

    this.IsExtraCharge = this.parentData['IsExtraCharge'];
    this.ExtraChargeDescription = this.parentData['ExtraChargeDescription'];
    this.ExtraChargeDescriptionArabic = this.parentData['ExtraChargeDescriptionArabic'];
    this.ExtraChargeAmount = this.parentData['ExtraChargeAmount'];
    this.IsCalculateTIPAmount = this.parentData['IsCalculateTIPAmount'];

    this.IsLoyaltySelected = this.parentData['IsLoyaltySelected'];
    this.Consumed_LoyaltyPoints = this.parentData['Consumed_LoyaltyPoints'];
    this.Consumed_LoyaltyAmount = this.parentData['Consumed_LoyaltyAmount'];
    debugger
    this.IsCateringFood = this.parentData['IsCateringFood'];
    if (this.parentData['objCateringFood'])
      this.objCateringFood = this.parentData['objCateringFood'];

    this.lstComboProducts = this.parentData['lstComboProducts'];

    // this.SpecialRequest = this.parentData['SpecialRequest'];
    this.get_paymentmodes();

  }

  get_paymentmodes() {
    this.dataservice.get('pos/GetPaymentModes/' + this.CompanyId + '/' + localStorage.getItem('LanguageCulture' + this.Utilities.getURLCompanyName()) + '/' + this.POSID + '/' + this.SelectedBranchID ).subscribe(data => {
      if (this.isArrayHasValue(data)) {
        console.log(data)
        this.lstPaymentMode = data;
        this.rdPaymentcode = this.lstPaymentMode[0].Code;
        this.rdPaymentMethod = this.lstPaymentMode[0].PaymentName;
        this.ActivateTipAmount = this.lstPaymentMode[0].ActivateTipAmount;
      }
    })
  }

  ArrMasterData: any = [];
  ArrSelectedDetailData: any = [];

  getSelectedDetailData(): void {
    // if (this.ArrRegister.length > 0)
    //   this.ArrRegister = new Array();
    // this.ArrRegister.push({ 'FirstName': this.replaceAstrophe(this.ObjRegister.FirstName), 'LastName': this.replaceAstrophe(this.ObjRegister.LastName), 'PhoneNo': this.ObjRegister.PhoneNo, 'Email': this.ObjRegister.Email, 'Area': this.ObjRegister.Area, 'Flat': this.ObjRegister.Flat, 'Floor': this.ObjRegister.Floor, 'Road': this.ObjRegister.Road, 'Block': this.ObjRegister.Block, 'BuildingNo': this.ObjRegister.BuildingNo, 'BuildingName': this.replaceAstrophe(this.ObjRegister.BuildingName), 'AdditionalDirection': this.replaceAstrophe(this.ObjRegister.AdditionalDirection), 'Deliveryat': this.ObjRegister.Deliveryat, 'Address': this.replaceAstrophe(this.ObjRegister.Address), 'CountryCode': this.ObjRegister.CountryCode });
    // if (this.ArrMasterData.length > 0)
    //   this.ArrMasterData = new Array();
    debugger;
    this.ArrMasterData.push({
      'DeliveryFee': this.DeliveryFee, 'OrderDate': this.Utilities.GetCurrentDateTime(), 'PayType': this.rdPaymentMethod, 'OrderPayStatus': 'Paid', 'OutletID': this.objoutletInfo.BranchID, 'Channel': 'POS', 'Remarks': this.Remarks, 'CustomerID': this.CustomerID == undefined ? 0 : this.CustomerID,
      'TotalAmount': this.Subtoal, 'NetAmount': this.TotalAmount, 'TotalDiscount': this.DiscountAmont, 'OrderType': this.OrderType, 'OrderPerpStatus': 'Delivered', 'DiscountCode': this.DiscountCode, 'DeliveryTime': this.TimeStampSelected, 'TaxID': this.TaxId, 'TotalTaxAmount': this.VAT, 'CompanyId': this.CompanyId, 'POSID': this.POSID, 'ShiftID': this.ShiftID, 'DayID': this.DayID,
      'PromoCode': this.PromoCode, 'PromoAmount': this.PromoAmount, 'TableName': this.TableName, 'noOfCustomers': this.noOfCustomers, 'CustAddressID': this.CustomerAddressID == undefined ? 0 : this.CustomerAddressID, 'OrderTypeID': this.OrderTypeID
      , 'IsExtraCharge': this.IsExtraCharge, 'ExtraChargeDescription': this.ExtraChargeDescription
      , 'ExtraChargeAmount': this.ExtraChargeAmount, 'ExtraChargeDescriptionArabic': this.ExtraChargeDescriptionArabic, 'TipAmount': this.TipAmount
      , 'CustomerName': this.objCateringFood.CustomerName
      , 'EventName': this.objCateringFood.EventName
      , 'EventLocation': this.objCateringFood.EventLocation
      , 'EventMap': this.objCateringFood.EventMap
      , 'BookingDate': this.Utilities.GetCurrentDateTime()
      // ,'BookingDate':this.objCateringFood.BookingDate
      // ,'DeliveryDate':this.objCateringFood.DeliveryDate
      , 'DeliveryDate': this.Utilities.GetCurrentDateTime()
      , 'ExpectedPeople': this.objCateringFood.ExpectedPeople
      , 'EventDescription': this.objCateringFood.EventDiscription
      , 'BudgetAmount': this.objCateringFood.BugetAmount
    });

    this.ArrSelectedDetailData = new Array();
    for (var i = 0; i < this.lstbasketdata.length; i++) {
      debugger
      this.ArrSelectedDetailData.push({
        'ProductId': this.lstbasketdata[i].ProductID,
        'Quantity': this.lstbasketdata[i].Quantity,
        'AddOnID': null,
        'ProductCategoryID': this.lstbasketdata[i].CatagoryID,
        'ProductDetailID': this.lstbasketdata[i].ProductDetailID,
        'ProductSizeID': this.lstbasketdata[i].sizeProductID,
        'SpecialRequest': this.lstbasketdata[i].SpecialRequest,
        'UnitPrice': this.lstbasketdata[i].Price,
        'TaxAmount': this.lstbasketdata[i].TaxAmount,
        'TaxPercent': this.lstbasketdata[i].TaxPercent,
        'ProductDiscount': this.lstbasketdata[i].ProductDiscount,
        'PriceWithOutVAT': this.lstbasketdata[i].PriceWithOutVAT,
        'DiffPriceWithOutVAT': this.lstbasketdata[i].DiffPriceWithOutVAT,
        'DiffTaxAmount': this.lstbasketdata[i].DiffTaxAmount,
        'DiffProductDiscount': this.lstbasketdata[i].DiffProductDiscount,


        'ProductModifireReferenceKey': this.lstbasketdata[i].rowid,
        'ExtraChargePercentage': this.lstbasketdata[i].ExtraChargePercentage
      });

      for (var j = 0; j < this.lstSelectedModifiers[i].length; j++) {
        debugger
        if (this.lstbasketdata[i].ProductID == this.lstSelectedModifiers[i][j].ProductID && this.lstbasketdata[i].rowid == this.lstSelectedModifiers[i][j].rowid) {
          this.ArrSelectedDetailData.push({
            'ProductId': this.lstSelectedModifiers[i][j].ProductID,
            'Quantity': this.lstbasketdata[i].Quantity,
            'ProductCategoryID': this.lstbasketdata[i].CatagoryID,
            'ProductDetailID': this.lstbasketdata[i].ProductDetailID,
            'ProductSizeID': this.lstbasketdata[i].sizeProductID,
            'AddOnID': this.lstSelectedModifiers[i][j].ModifierId,
            'UnitPrice': this.lstSelectedModifiers[i][j].Price,
            'TaxAmount': this.lstSelectedModifiers[i][j].TaxAmount,
            'ProductDiscount': this.lstSelectedModifiers[i][j].ProductDiscount,
            'PriceWithOutVAT': this.lstSelectedModifiers[i][j].PriceWithOutVAT,
            'TaxPercent': this.lstbasketdata[i].TaxPercent,
            'DiffPriceWithOutVAT': this.lstbasketdata[i].DiffPriceWithOutVAT,
            'DiffTaxAmount': this.lstbasketdata[i].DiffTaxAmount,
            'DiffProductDiscount': this.lstbasketdata[i].DiffProductDiscount,
            'ProductModifireReferenceKey': this.lstSelectedModifiers[i][j].rowid,


          });

        }

      }

      //ComboProduct Detail
      for (var k = 0; k < this.lstComboProducts.length; k++) {
        debugger
        if (this.lstComboProducts[k].MainProductID == this.lstbasketdata[i].ProductID && this.lstComboProducts[k].rowid == this.lstbasketdata[i].rowid && this.lstComboProducts[k].IsComboItem == 1) {
          debugger
          this.ArrSelectedDetailData.push({
            'ComboProductId': this.lstComboProducts[k].MainProductID == undefined ? this.lstComboProducts[k].MainProductID : this.lstComboProducts[k].MainProductID,
            'UnitPrice': this.lstComboProducts[k].Price,
            'ProductId': this.lstComboProducts[k].ProductId == undefined ? this.lstComboProducts[k].ProductID : this.lstComboProducts[k].ProductId,
            'ProductID': this.lstComboProducts[k].ProductId == undefined ? this.lstComboProducts[k].ProductID : this.lstComboProducts[k].ProductId,
            'Quantity': this.lstbasketdata[i].Quantity,
            'AddOnID': null,
            'ProductCategoryID': this.lstbasketdata[i].CatagoryID,
            'TaxAmount': this.lstbasketdata[i].TaxAmount,
            'TaxPercent': this.lstbasketdata[i].TaxPercent,
            'ProductDetailID': this.lstbasketdata[i].ProductDetailID,
            'ProductDiscount': this.lstbasketdata[i].ProductDiscount,
            'PriceWithOutVAT': this.lstbasketdata[i].PriceWithOutVAT,
            'DiffPriceWithOutVAT': this.lstbasketdata[i].DiffPriceWithOutVAT,
            'DiffProductDiscount': this.lstbasketdata[i].DiffProductDiscount,
            'DiffTaxAmount': this.lstbasketdata[i].DiffTaxAmount,
            'ProductModifireReferenceKey': this.lstComboProducts[k].rowid,
            'Printed': this.lstbasketdata[i].Printed,
            'DelFlag': this.lstbasketdata[i].DelFlag,

          });
        }
      }
    }

  }

  replaceAstrophe(val): any {
    return val.replaceAll("'", "''");
  }

  receiptArray = [];
  totalItems: any;
  totalQuantity: any;
  IsSessionClosed: boolean = false;
  IsLoyaltySelected: boolean = false;
  Consumed_LoyaltyAmount: any = 0.0;
  Consumed_LoyaltyPoints: any = 0;

  paybill() {
    debugger
    if (!this.IsComingKOT) {
      this.getSelectedDetailData();
      var CurrentDate = this.Utilities.GetCurrentDate();
      var OrderType = this.ArrMasterData[0].OrderType;
      let body = [this.ArrMasterData, this.ArrSelectedDetailData, this.GuestCutomer, this.MobileNo, this.CompanyId, this.cashPaidAmount, this.ReturnAmount, this.UserName, this.lstPaymentDetail, this.Utilities.getUserId(), CurrentDate, OrderType, this.SelectedBranchID, this.lstVoucherPaymentDetail, this.POSID, this.loyaltypoints, this.AvailingAmount, this.CurrencyCode, this.objoutletInfo.IsZatcaInvoiceOn, this.IsLoyaltySelected, this.Consumed_LoyaltyPoints, this.Consumed_LoyaltyAmount, this.HoldOrderID, this.objoutletInfo.CertificateType]
      this.dataservice.post('pos/SaveOrderData/', body).subscribe(data => {
        debugger
        if (data['Table'][0].msg == 'sessionclosed') {
          this.IsSessionClosed = true;
          let message = 'sessionclosed';
          let dialogRef = this.dialog.open(AboutUsComponent,
            {
              width: '500px',
              height: '200px',

              data: { message },
            });

          dialogRef.afterClosed().subscribe(result => {
            if (result != undefined) {


            }

          });
          // this.grid_lstPaymentDetail = [];
          // this.source1 = new LocalDataSource(this.grid_lstPaymentDetail);

          return
        }
        else if (data["Table"].length > 0) {
          this.receiptArray = data["Table"];
          this.totalItems = data["Table1"][0].noOfItems;
          this.totalQuantity = data["Table2"][0].noOfqty;
          sessionStorage.setItem('orderid', this.receiptArray = data["Table"][0]["OrderID"]);
          debugger;
          let returnbody = [this.receiptArray, data["Table4"], data["Table5"],true]
          this.popup.ShowPopup('Insert');
          this.dialogRef.close(returnbody);

        }
      });
    }
    else {
      this.AddItemInKOT();
    }

  }
  lstUser = [];
  checkUrl() {
    //debugger
    this.lstUser = JSON.parse(localStorage.getItem('sessionObject' + this.Utilities.getURLCompanyName()));
    // this.ReceiptURL = this.Utilities.getReceiptURL(this.Utilities.getEncrypt(this.Utilities.getCompanyName()), this.Utilities.getEncrypt(sessionStorage.getItem('orderid')), this.lstUser[0]['CountryCode']);
    this.ReceiptURL = this.Utilities.getDigiPosReceiptURL(this.Utilities.getEncrypt(this.Utilities.getCompanyName()), this.Utilities.getEncrypt(sessionStorage.getItem('orderid')), this.lstUser[0]['CountryCode']);



  }

  async printfnc() {
    window.document.write("hello");

    window.document.write("<ngx-qrcode [elementType]='elementType'   [value] = " + this.ReceiptURL + " cssClass = 'aclass' errorCorrectionLevel = 'L' ></ngx-qrcode>");
    window.document.write("");
    window.focus(); // necessary for IE >= 10*-/
    window.print();
    //printWindow.close();
  }

  AddItemInKOT(): void {
    //debugger  
    this.getSelectedKOTDetailData();
    var CurrentDate = this.Utilities.GetCurrentDateTime();
    let body = [this.ArrSelectedDetailData, this.KotOrderID, this.Utilities.getSubscriptionID(), this.Subtoal, this.TotalAmount, this.DiscountAmont, 'Paid', this.lstPaymentDetail, this.Utilities.getUserId(), CurrentDate, this.OrderType, this.SelectedBranchID, this.OrderPerpStatus123, this.PromoCode, this.PromoAmount, this.VAT, this.OrderTypeID, this.TableName, this.CustomerID == undefined ? 0 : this.CustomerID, this.CustomerAddressID == undefined ? 0 : this.CustomerAddressID, this.lstVoucherPaymentDetail, this.IsExtraCharge, this.ExtraChargeDescription, this.ExtraChargeAmount, this.ExtraChargeDescriptionArabic, this.TipAmount, this.CurrencyCode, this.objoutletInfo.IsZatcaInvoiceOn, this.POSID, this.ShiftID, this.objoutletInfo.CertificateType]
    this.dataservice.post('pos/SaveKOTorders/', body).subscribe(data => {
      debugger
      if (this.isArrayHasValue(data["Table"])) {
        this.receiptArray = data["Table"];
        this.totalItems = data["Table1"][0].noOfItems;
        this.totalQuantity = data["Table2"][0].noOfqty;
        //var printContents = document.getElementById('invoice-POS').innerHTML;
        //var originalContents = document.body.innerHTML;
        sessionStorage.setItem('orderid', this.receiptArray = data["Table"][0]["OrderID"]);
        //this.checkUrl();
        //document.body.innerHTML = printContents;
        //window.print();
        //document.body.innerHTML = originalContents;
        // this.printDivs();
        //this.printfnc();
        //debugger;
        let returnbody = [this.receiptArray, data["Table4"], data["Table5"],false]
        this.popup.ShowPopup('Insert');
        this.dialogRef.close(returnbody);

      } else {
        this.popup.ShowPopup('Error');
        this.dialogRef.close(undefined);
      }
      // if (data == 'Insert') {

      //   this.popup.ShowPopup('Insert');
      //   this.receiptArray = data["Table"];
      //   this.totalItems = data["Table1"][0].noOfItems;
      //   this.totalQuantity = data["Table2"][0].noOfqty;
      //   Storage.setItem('orderid',this.receiptArray = data["Table"][0]["OrderID"]) ;
      //   this.checkUrl();


      //   this.lstbasketdata = [];
      //   this.lstSelectedModifiers = [];
      //   this.ArrMasterData = [];
      //   this.ArrSelectedDetailData = [];
      //   this.GuestCutomer = []
      //   this.MobileNo
      //   this.TotalAmount = 0;
      //   this.ReturnAmount = 0;
      //   this.Subtoal = 0;
      //   this.DiscountAmont = 0;
      //   this.VAT = 0;
      //   this.lstPaymentDetail = [];
      //   this.grid_lstPaymentDetail = [];
      //   this.dialogRef.close(this.receiptArray);
      // }

    });

  }

  ECRInfo: any;
  getSelectedKOTDetailData(): void {
    this.ArrSelectedDetailData = new Array();
    debugger


    for (var i = 0; i < this.lstbasketdata.length; i++) {
      debugger
      this.ArrSelectedDetailData.push({
        'ProductID': this.lstbasketdata[i].ProductID,
        'Quantity': this.lstbasketdata[i].Quantity,
        'AddOnID': null,
        'ProductCategoryID': this.lstbasketdata[i].ProductCategoryID,
        'ProductDetailID': this.lstbasketdata[i].ProductDetailID,
        'ProductSizeID': this.lstbasketdata[i].ProductSizeID,
        'SpecialRequest': this.lstbasketdata[i].SpecialRequest,
        'DelFlag': this.lstbasketdata[i].DelFlag,
        'Printed': this.lstbasketdata[i].Printed,
        'RejectReasonID': this.lstbasketdata[i].RejectReasonID,
        'RejectedReason': this.lstbasketdata[i].RejectedReason,
        'UnitPrice': this.lstbasketdata[i].Price,
        'TaxAmount': this.lstbasketdata[i].TaxAmount,
        'TaxPercent': this.lstbasketdata[i].TaxPercent,
        'ProductDiscount': this.lstbasketdata[i].ProductDiscount,
        'PriceWithOutVAT': this.lstbasketdata[i].PriceWithOutVAT,
        'DeletedBy': this.lstbasketdata[i].DeletedBy,
        'DeletedOn': this.lstbasketdata[i].DeletedOn,
        'ProductModifireReferenceKey': this.lstbasketdata[i].rowid,
        'ExtraChargePercentage': this.lstbasketdata[i].ExtraChargePercentage
      });

      if (this.lstSelectedModifiers.length > 0) {
        for (var j = 0; j < this.lstSelectedModifiers[i].length; j++) {
          debugger
          if (this.lstbasketdata[i].ProductID == this.lstSelectedModifiers[i][j].ProductID && this.lstbasketdata[i].rowid == this.lstSelectedModifiers[i][j].rowid) {
            debugger
            this.ArrSelectedDetailData.push({
              'ProductID': this.lstSelectedModifiers[i][j].ProductID,
              'Quantity': this.lstbasketdata[i].Quantity,
              'AddOnID': this.lstSelectedModifiers[i][j].ModifierId,
              'ProductCategoryID': this.lstbasketdata[i].ProductCategoryID,
              'ProductDetailID': this.lstbasketdata[i].ProductDetailID,
              'ProductSizeID': this.lstbasketdata[i].ProductSizeID,
              'UnitPrice': this.lstSelectedModifiers[i][j].Price,
              'TaxPercent': this.lstbasketdata[i].TaxPercent,
              'ProductModifireReferenceKey': this.lstSelectedModifiers[i][j].rowid,
              'Printed': this.lstbasketdata[i].Printed,
              'DelFlag': this.lstbasketdata[i].DelFlag,
              'TaxAmount': this.lstSelectedModifiers[i][j].TaxAmount,
              'ProductDiscount': this.lstSelectedModifiers[i][j].ProductDiscount,
              'PriceWithOutVAT': this.lstSelectedModifiers[i][j].PriceWithOutVAT,
            });

          }
        }
      }

      //ComboProduct Detail
      for (var k = 0; k < this.lstComboProducts.length; k++) {
        debugger
        if (this.lstComboProducts[k].MainProductID == this.lstbasketdata[i].ProductID && this.lstComboProducts[k].rowid == this.lstbasketdata[i].rowid && this.lstComboProducts[k].IsComboItem == 1) {
          debugger
          this.ArrSelectedDetailData.push({
            'ComboProductId': this.lstComboProducts[k].MainProductID == undefined ? this.lstComboProducts[k].MainProductID : this.lstComboProducts[k].MainProductID,
            'UnitPrice': this.lstComboProducts[k].Price,
            'ProductId': this.lstComboProducts[k].ProductId == undefined ? this.lstComboProducts[k].ProductID : this.lstComboProducts[k].ProductId,
            'ProductID': this.lstComboProducts[k].ProductId == undefined ? this.lstComboProducts[k].ProductID : this.lstComboProducts[k].ProductId,
            'Quantity': this.lstbasketdata[i].Quantity,
            'AddOnID': null,
            'ProductCategoryID': this.lstbasketdata[i].CatagoryID,
            'TaxPercent': this.lstbasketdata[i].TaxPercent,
            'ProductDetailID': this.lstbasketdata[i].ProductDetailID,
            'ProductDiscount': this.lstbasketdata[i].ProductDiscount,
            'PriceWithOutVAT': this.lstbasketdata[i].PriceWithOutVAT,
            'TaxAmount': this.lstbasketdata[i].TaxAmount,
            'DiffPriceWithOutVAT': this.lstbasketdata[i].DiffPriceWithOutVAT,
            'DiffProductDiscount': this.lstbasketdata[i].DiffProductDiscount,
            'DiffTaxAmount': this.lstbasketdata[i].DiffTaxAmount,
            'ProductModifireReferenceKey': this.lstComboProducts[k].rowid,
            'Printed': this.lstbasketdata[i].Printed,
            'DelFlag': this.lstbasketdata[i].DelFlag

          });
        }
      }

    }

  }

  PaymentTypeChangeFn(value) {
    debugger
    this.rdPaymentcode = value.Code;
    this.rdPaymentMethod = value.PaymentName;
    this.ActivateTipAmount = value.ActivateTipAmount;
    this.ECRIntegrationID = value.ECRIntegrationID;
    this.returnValue();
    if (this.ECRIntegrationID == 2) {
      this.ECRInfo = value;
      this.ShowERCPayButton = true;
    }
    else {
      this.ShowERCPayButton = false;
    }
  }
  NamiPayTCPIPConnection(info) {


    let body = JSON.stringify({ "ip": info.IPaddress, "port": parseInt(info.PortNo) });
    const headers = { 'accept': 'application/json', 'content-type': 'application/json' };
    debugger
    let url = info.ECRurlConnect + 'tcp-ip/connect'
    this.http.post<any>(url, body, { headers }).subscribe(data => {
      debugger
      if (data == 0) {
        this.NamiPayTCIPRegistration(info)
      }
    });
  }
  NamiPayTCIPRegistration(info) {
    const formattedDate = this.formatUnixTimestamp();

    const headers = { 'accept': 'application/json', 'content-type': 'application/json' };
    let url = info.ECRurlConnect + 'performTransactionTCPIP'
    let bodytrans = JSON.stringify({
      "date": formattedDate,
      "ecrReferenceNo": info.referenceno,
      "amount": this.TotalAmount,
      "cashBackAmount": "0",
      "txnType": "17"
    })
    this.http.post<any>(url, bodytrans, { headers }).subscribe(data => {
      if (data.statusCode != 102) {
        this.NamiPayTCIPStartSession(info)

      }
    });
  }
  NamiPayTCIPStartSession(info) {
    const formattedDate = this.formatUnixTimestamp();
    const headers = { 'accept': 'application/json', 'content-type': 'application/json' };
    let url = info.ECRurlConnect + 'performTransactionTCPIP'
    let bodytrans = JSON.stringify({
      "date": formattedDate,
      "ecrReferenceNo": info.referenceno,
      "amount": this.TotalAmount,
      "cashBackAmount": "0",
      "txnType": "18"
    })
    this.http.post<any>(url, bodytrans, { headers }).subscribe(data => {
      if (data.statusCode != 102) {
        this.NamiPayTCIPPerformTransaction(info)

      }
    });
  }
  NamiPayTCIPPerformTransaction(info) {
    const formattedDate = this.formatUnixTimestamp();
    const headers = { 'accept': 'application/json', 'content-type': 'application/json' };
    let url = info.ECRurlConnect + 'performTransactionTCPIP'
    let bodytrans = JSON.stringify(
      {
        "date": formattedDate
        , "ecrReferenceNo": info.referenceno
        , "amount": (this.TotalAmount * 100)
        , "cashBackAmount": ""
        , "print": "1"
        , "cashRegisterNo": "12345678"
        , "txnType": 0
      })
    this.http.post<any>(url, bodytrans, { headers }).subscribe(data => {
      const transactionData = data.transaction;
      let dialogRef = this.dialog.open(AboutUsComponent,
        {
          width: '500px',
          height: '340px',

          data: { transactionData },
        });

      dialogRef.afterClosed().subscribe(result => {
        if (result != undefined) {
          const transactionData = result.transactionData;
          // Remove \u0002; and \u0003 from the transaction data, then split by semicolon
          const splitTransactionData: string[] = transactionData.replace(/\u0002;|\u0003/g, '').split(';');
          if (splitTransactionData.includes("APPROVED")) {
            this.clickEnter();
          }
        }

      });
    });
  }
  ercAmountlessthentotalAmount: boolean = false;
  ClickECRPaybutton() {
    debugger
    const gridsum: number = this.grid_lstPaymentDetail.length > 0
      ? this.grid_lstPaymentDetail.reduce((acc, item) => acc + item.NetAmount, 0) : 0;
    let totalsum = parseFloat(gridsum.toFixed(3)) + parseFloat(this.cashPaidAmount);
    if (totalsum < this.TotalAmount.toFixed(3)) {
      this.ercAmountlessthentotalAmount = true;
      return;
    }
    this.ercAmountlessthentotalAmount = false;
    if (this.ECRInfo.commType == 1) {
      this.NamiPayTCPIPConnection(this.ECRInfo)
    }
    else if (this.ECRInfo.commType == 2) {
      this.NamiPayCommConnection(this.ECRInfo)
    }
  }
  formatUnixTimestamp(): string {
    const date = new Date();

    const year = date.getUTCFullYear().toString().substring(2);
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const day = date.getUTCDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    const formattedDate = `${day}${month}${year}${hours}${minutes}${seconds}`;
    debugger;
    return formattedDate;
  }


  NamiPayCommConnection(info) {
    let urlConnectCOMM = "http://localhost:9099/com/connect";
    let urlTransactionCOM = "http://localhost:9099/performTransactionCOM";
    let reqData = JSON.stringify({
      "port": parseInt(info.serial_port), "baudRate": info.serial_baud_rate,
      "parity": info.serial_parity, "dataBits": info.serial_data_bits,
      "stopBits": parseInt(info.serial_stopBits)
    });
    this.dataservice.postNamiPay(info.ECRurlConnectCOMM, reqData)
      .subscribe(data => {
        console.log(data);
      }, error => alert('error'));
  }
  printDivs() {
    //debugger
    var currDateTime = new Date().toLocaleString();
    window.document.write('<body style="margin: 0px;">');
    window.document.write('<div style="text-align: center;">');
    window.document.write('<img  src=' + this.logo + ' style="width: 138px;">');
    window.document.write('</div>');
    window.document.write('<p style="font-size: 12px;font-family:calibri; text-align: center;border:1px solid black;margin: 0px;margin-top:5px">Sale Receipt</p>');
    window.document.write('<p style="font-size: 12px;font-family:calibri;margin: 0px;">Invoice No.: ' + this.receiptArray[0].OrderInvoiceNo + '</p>');
    window.document.write('<p style="font-size: 12px;font-family:calibri;margin: 0px;">Date & Time: ' + currDateTime + ' </p>');
    window.document.write('<p style="font-size: 12px;font-family:calibri;margin: 0px;">Payment Type: ' + this.receiptArray[0].PayType + ' </p>');
    window.document.write('<p style="font-size: 12px;font-family:calibri;margin: 0px;">User: ' + this.UserName + ' </p>');
    window.document.write('<p style="font-size: 12px;font-family:calibri;margin: 0px;">Counter: 001 </p>');
    window.document.write('<table style="" width="100%">');
    window.document.write('<tr style="border:1px solid black;font-size: 12px;font-family:calibri;font-weight: 800;">');
    window.document.write('<td>Qty</td>');
    window.document.write('<td>Price</td>');
    window.document.write('<td>Discount</td>');
    window.document.write('<td>Total</td>');
    window.document.write('</tr>');
    for (let i = 0; i < this.receiptArray.length; i++) {
      window.document.write('<tr style="font-size: 10px;font-family:calibri;">');
      window.document.write('<td style="font-size: 10px">' + this.receiptArray[i].Description + '</td>');
      window.document.write('</tr>');
      window.document.write('<tr style="font-size: 10px;font-family:calibri;">');
      window.document.write('<td>' + this.receiptArray[i].Quantity + '</td>');
      window.document.write('<td>' + this.receiptArray[i].Rate + '</td>');
      window.document.write('<td>' + this.receiptArray[i].DiscountAmount + '</td>');
      window.document.write('<td>' + this.receiptArray[i].Amount + '</td>');
      window.document.write('</tr>');


    }
    window.document.write('</table>');
    window.document.write('<p style="font-size: 12px;font-family:calibri;margin: 0px;">Total Items/Qty:  <span style="float: right;">' + this.totalItems + '/' + this.totalQuantity + '</span></p>');
    window.document.write('<hr style="border:1px dashed black"/>');
    window.document.write('<p style="text-align: right;font-size: 12px;font-family:calibri;margin: 0px;"> Total: ' + this.receiptArray[0].NetAmount + '</p>');
    window.document.write('<p style="text-align: right;font-size: 12px;font-family:calibri;margin: 0px;">Discount: ' + this.receiptArray[0].TotalDiscount + '</p>');
    window.document.write('<p style="text-align: right;font-size: 12px;font-family:calibri;margin: 0px;">Cash Paid: ' + this.cashPaidAmount + '</p>');
    window.document.write('<p style="text-align: right;font-size: 12px;font-family:calibri;margin: 0px;">Cash Balance: ' + this.ReturnAmount + '</p>');
    window.document.write('<p style="text-align: center;font-size: 12px;font-family:calibri;">Powered by Global Informix</p>');
    window.document.write(' <ngx-qrcode [elementType]="elementType"    [value] = ' + this.QRUrl + '                    cssClass = "aclass"                    errorCorrectionLevel = "L" >      </ngx-qrcode>');

    window.document.write('</div>');
    window.document.write('</body>');

    window.document.close(); //this seems to be the thing doing the trick
    window.focus();
    window.print();
    window.close();
    window.document.close();
    // return true;

  }
  getReceipt(): void {



  }

  getMasterDetailData(OrderID): void {
    debugger;
    this.ObjOrderMaster = new OrderMaster();
    this.ObjCompany.CompanyID = this.Utilities.getSubscriptionID();
    this.dataservice.get('Receipt/GetOrderData/' + OrderID + '/' + this.ObjCompany.CompanyID)
      .subscribe(data => {
        if (data[0]['MasterData']) {
          this.ObjOrderMaster = data[0]['MasterData'][0];
        }
        if (this.isArrayHasValue(data[0]['DetailData'])) {
          this.ArrOrderDetail = data[0]['DetailData'];
        }
        else {
          this.ArrOrderDetail = [];
        }

        if (data[0]['Currency']) {
          this.ObjCurrency = data[0]['Currency'][0];
          this.CurrencyCode = this.ObjCurrency.CurrencyCode;
        }

        if (data[0]['CompanyInfo']) {
          this.ObjCompanyInfo = data[0]['CompanyInfo'][0];
        }

      }, error => alert('error'));
  }
  isArrayHasValue(obj: any[]): boolean {
    let isArrayWithLength = Boolean(Array.isArray(obj) && obj.length);
    return isArrayWithLength;
  }

  // copyCashPaidAmount:any;
  onDelete(event) {
    //debugger;
    this.enterAmount = false;
    this.ReturnAmount = '';
    this.cashPaidAmount = '';
    this.tempAmount = 0;
    if (event) {
      this.grid_lstPaymentDetail.splice(event.index, 1);
      this.lstPaymentDetail.splice(event.index, 1);
      this.source1 = new LocalDataSource(this.grid_lstPaymentDetail);

      // this.copyCashPaidAmount='';
      this.grid_lstPaymentDetail.forEach(a => this.tempAmount += +a.Amount);
      this.cashPaidAmount = this._decimalPipe.transform(this.TotalAmount - this.tempAmount, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal);

      this.paymentAmount = 0;
      this.paymentAmount = parseFloat(this.cashPaidAmount);
      this.grid_lstPaymentDetail.forEach(a => this.paymentAmount += +a.Amount);
      if (parseFloat(this.paymentAmount.toFixed(3)) > parseFloat(this.TotalAmount.toFixed(3)) && this.rdPaymentcode == 1) {
        this.ReturnAmount = this._decimalPipe.transform(this.paymentAmount - this.TotalAmount, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal);
        this.TipAmount = 0;
      }
      else {
        if (this.IsCalculateTIPAmount == true && this.ActivateTipAmount == 1)
          this.TipAmount = this._decimalPipe.transform(this.paymentAmount - this.TotalAmount, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal);
        else
          this.TipAmount = 0;
        this.ReturnAmount = 0;
      }
    }
  }


  // inputValueChange(event) {
  //   debugger
  //   if(event != ""){
  //     this.ReturnAmount = this.TotalAmount - event;
  //     this.ReturnAmount = this._decimalPipe.transform(this.ReturnAmount, '1.2-2')
  //   }
  //   else
  //   this.ReturnAmount = 0;
  // }


  calcButtonclick(value) {
    //debugger
    if (value == 'AC' || value == "") {
      this.cashPaidAmount = '';
      this.arrTempAmount = '';
      this.ReturnAmount = '';
      return
    }
    else {
      debugger
      if (this.IsCashPaidAmountClear == true) {
        this.cashPaidAmount = '';
        this.IsCashPaidAmountClear = false;
      }
      this.cashPaidAmount = this.cashPaidAmount + value;
      this.cashPaidAmount = this.cashPaidAmount.substring(0, 15);
      var dotlength = this.cashPaidAmount.split(".").length - 1;

      if (dotlength > 1) {
        this.cashPaidAmount = this.cashPaidAmount.slice(0, -1);
        return
      }
      else {
        this.arrTempAmount = this.cashPaidAmount
        this.arrTempAmount = this._decimalPipe.transform(this.arrTempAmount, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal)
        this.returnValue();
      }
    }
  }

  tempAmount: number = 0;
  arrTempAmount: any = '';
  inputValueChange(value) {
    debugger
    const charCode = (value.which) ? value.which : value.keyCode;
    var eventKey = value.key;
    // value = parseFloat(value)
    if (value == 'AC' || value == "") {
      this.cashPaidAmount = '';
      this.arrTempAmount = '';
      this.ReturnAmount = '';
      return
    }
    else {
      this.arrTempAmount = ''
      this.arrTempAmount = this.arrTempAmount + value;
      this.cashPaidAmount = this.arrTempAmount;
      this.arrTempAmount = this._decimalPipe.transform(this.arrTempAmount, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal);
      this.returnValue();
    }
  }

  returnValue() {
    debugger
    var copyCashPaidAmount = parseFloat(this.cashPaidAmount);
    this.grid_lstPaymentDetail.forEach(a => copyCashPaidAmount += +a.Amount);
    let amount = this._decimalPipe.transform(copyCashPaidAmount - this.TotalAmount, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal);
    if (copyCashPaidAmount > this.TotalAmount) { /*&& this.rdPaymentcode == 1*/
      this.ReturnAmount = amount;
      this.TipAmount = amount;
    } else {
      this.ReturnAmount = 0;
      this.TipAmount = 0;
    }
    //else {
    //  if (this.IsCalculateTIPAmount == true && this.rdPaymentcode != 5)
    //    this.TipAmount = amount;
    //  else
    //    this.TipAmount = 0;
    //  this.ReturnAmount = amount;
    //}
    if (this.rdPaymentcode == 5) {
      this.ShowCustomerLookup = true;
    }
    else {
      this.ShowCustomerLookup = false;
      this.SelectCustomer = false;
    }
    if (this.IsCalculateTIPAmount == true && this.ActivateTipAmount == 1) {
      this.ReturnAmount = 0;
    } else {
      this.TipAmount = 0;
    }
    //if (this.IsCalculateTIPAmount == true && (this.rdPaymentcode == 2 || this.rdPaymentcode == 3
    //  || this.rdPaymentcode == 4 || this.rdPaymentcode == 6 || this.rdPaymentcode == 8
    //  || this.rdPaymentcode == 12 || this.rdPaymentcode == 15 || this.rdPaymentcode == 16
    //  || this.rdPaymentcode == 26 || this.rdPaymentcode == 27)) {
    //  this.ReturnAmount = 0;
    //} else {
    //  this.TipAmount = 0;
    //}
    //2 Debit Card     3 Credit Card     4 AMEX    6 Visa Card    8 B - Wallet
    //12 MADA    15 Benefit Pay    16 Master Card    26 Jahez Credit    27 Delivery Credit
  }
  expectedAmount: any;
  changeEvent() {
    debugger

    //   this.lstPaymentDetail.push({'CustomerID':this.CustomerID,'CustomerPaid':this.arrTempAmount,'Rate':1,'LocalCustomerPaid':this.arrTempAmount,
    //   'NetAmount': expectedAmount,'PaymentMode':this.rdPaymentMethod,'PaymentCode':this.rdPaymentcode,
    //   'PayTime': this.paytime,'PayDate' : this.Currdate,
    //   'Status': this.Status, 'Remarks': this.arrRemarks, 'CreatedBy': this.UserName, 'CreatedOn': this.Currdate
    // });
  }

  grid_lstPaymentDetail = [];
  enterAmount: boolean = false;
  SelectCustomer: boolean = false;
  maxlimit: boolean = false;
  paymentAmount: number = 0;
  paytime: any;
  Currdate: any;
  arrRemarks: any;
  clickEnter() {

    if (this.IsSessionClosed == true) {
      let message = 'sessionclosed';
      let dialogRef = this.dialog.open(AboutUsComponent,
        {
          width: '500px',
          height: '200px',

          data: { message },
        });

      dialogRef.afterClosed().subscribe(result => {
        if (result != undefined) {
        }

      });
      return
    }
    else if (!this.arrTempAmount || !this.cashPaidAmount) {
      this.enterAmount = true;
      return

    }
    else {
      this.arrTempAmount = this.arrTempAmount.replace(',', '');
      this.enterAmount = false;
      this.tempAmount = parseFloat(this.cashPaidAmount.replace(',', '')) + this.tempAmount;
      debugger
      //  this.returnValue += parseFloat(this.cashPaidAmount);
      // this.cashPaidAmount = this.grid_lstPaymentDetail.amount - this.TotalAmount;
    }


    if (this.Remarks == null || this.Remarks == undefined || this.Remarks == '') {
      this.arrRemarks = null;
    }
    else {
      this.arrRemarks = this.Remarks;
    }
    if (this.CustomerID == null || this.CustomerID == undefined || this.CustomerID == '') {
      this.CustomerID = null;
    }

    if (this.rdPaymentcode == 5 && (this.custId == null || this.custId == undefined || this.custId == '')) {
      this.SelectCustomer = true;
      return
    }
    else {
      this.SelectCustomer = false;
    }
    if (this.rdPaymentcode == 5 && this.MaxCreditLimit != null && this.MaxCreditLimit != undefined && this.cashPaidAmount > this.MaxCreditLimit) {
      this.maxlimit = true;
      return;
    }
    else {
      this.maxlimit = false;
    }
    this.paytime = new Date().toLocaleTimeString([], { hour: '2-digit', minute: "2-digit" });
    var nowDate = new Date();
    this.Currdate = nowDate.getFullYear() + '/' + (nowDate.getMonth() + 1) + '/' + nowDate.getDate();
    debugger
    let remainingAmount
    if (this.ReturnAmount)
      remainingAmount = +this.cashPaidAmount - this.ReturnAmount;
    else if (this.TipAmount)
      remainingAmount = +this.cashPaidAmount - this.ReturnAmount;
    else
      remainingAmount = +this.cashPaidAmount;
    this.grid_lstPaymentDetail.push({ 'paymentName': this.CurrencyCode, 'payTime': this.paytime, 'payMode': this.rdPaymentMethod, 'Amount': this.cashPaidAmount.replace(',', ''), 'NetAmount': remainingAmount, 'Reference': this.Remarks });
    this.paymentAmount = 0
    this.grid_lstPaymentDetail.forEach(a => this.paymentAmount += +a.Amount);

    debugger;
    if (this.ReturnAmount) {
      this.expectedAmount = +this.cashPaidAmount - this.ReturnAmount.replaceAll(',', '');
      this.expectedAmount = parseFloat(this.expectedAmount.toFixed(3)) + '';
      this.expectedAmount = this._decimalPipe.transform(this.expectedAmount, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal)

      // this.grid_lstPaymentDetail.push({ 'paymentName': this.CurrencyCode, 'payTime': this.paytime, 'payMode': this.rdPaymentMethod, 'Amount': this.cashPaidAmount,'NetAmount':this.expectedAmount});
      // this.paymentAmount=0
      // this.grid_lstPaymentDetail.forEach(a => this.paymentAmount += +a.Amount);
      // this.source1 = new LocalDataSource(this.grid_lstPaymentDetail);
      // this.source1.update(this.grid_lstPaymentDetail,this.grid_lstPaymentDetail);
    }
    if (this.TipAmount) {
      this.expectedAmount = +this.cashPaidAmount - this.TipAmount.replaceAll(',', '');
      this.expectedAmount = parseFloat(this.expectedAmount.toFixed(3)) + '';
      this.expectedAmount = this._decimalPipe.transform(this.expectedAmount, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal)

      // this.grid_lstPaymentDetail.push({ 'paymentName': this.CurrencyCode, 'payTime': this.paytime, 'payMode': this.rdPaymentMethod, 'Amount': this.cashPaidAmount,'NetAmount':this.expectedAmount});
      // this.paymentAmount=0
      // this.grid_lstPaymentDetail.forEach(a => this.paymentAmount += +a.Amount);
      // this.source1 = new LocalDataSource(this.grid_lstPaymentDetail);
      // this.source1.update(this.grid_lstPaymentDetail,this.grid_lstPaymentDetail);
    }
    // if(parseFloat(this.paymentAmount.toFixed(3)) > parseFloat(this.TotalAmount.toFixed(3)) ){
    // this.ReturnAmount =  this.paymentAmount - this.TotalAmount;
    // this.ReturnAmount = this._decimalPipe.transform(this.ReturnAmount, '1.'+this.NumOfDecimal+'-'+this.NumOfDecimal)}
    this.lstPaymentDetail.push({
      'CustomerID': this.CustomerID, 'CustomerPaid': this.cashPaidAmount.replace(',', ''), 'Rate': 1, 'LocalCustomerPaid': this.cashPaidAmount.replace(',', ''),
      'NetAmount': this.expectedAmount == undefined ? this.cashPaidAmount.replace(',', '') : this.expectedAmount.replace(',', ''), 'PaymentMode': this.rdPaymentMethod, 'PaymentCode': this.rdPaymentcode,
      'PayTime': this.paytime, 'PayDate': this.Currdate,
      'Status': this.Status, 'Remarks': this.arrRemarks, 'CreatedBy': this.UserName, 'CreatedOn': this.Currdate
    });

    if (parseFloat(this.TotalAmount.toFixed(3)) > parseFloat(this.paymentAmount.toFixed(3))) {
      this.cashPaidAmount = this._decimalPipe.transform(this.TotalAmount - this.paymentAmount + '', '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal);
    }
    else {
      this.cashPaidAmount = '';
    }
    this.source1 = new LocalDataSource(this.grid_lstPaymentDetail);
    this.source1.update(this.grid_lstPaymentDetail, this.grid_lstPaymentDetail);
    this.Remarks = null;

    if (parseFloat(this.tempAmount.toFixed(3)) >= parseFloat(this.TotalAmount.toFixed(3))) {
      //if (this.rdPaymentcode == 1) {
      this.ReturnAmount = this.TotalAmount - this.tempAmount;
      this.ReturnAmount = this._decimalPipe.transform(this.ReturnAmount, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal)
      this.TipAmount = this.ReturnAmount > 0 ? this.ReturnAmount : -(this.ReturnAmount);
      //}
      //else {
      //  if (this.IsCalculateTIPAmount == true)
      //    this.TipAmount = this.tempAmount - this.TotalAmount;
      //  else
      //    this.TipAmount = 0;
      //  this.ReturnAmount = 0;
      //}
      //debugger
      //if (this.IsCalculateTIPAmount == true && (this.rdPaymentcode == 2 || this.rdPaymentcode == 3
      //  || this.rdPaymentcode == 4 || this.rdPaymentcode == 6 || this.rdPaymentcode == 8
      //  || this.rdPaymentcode == 12 || this.rdPaymentcode == 15 || this.rdPaymentcode == 16
      //  || this.rdPaymentcode == 26 || this.rdPaymentcode == 27)) {
      //  this.ReturnAmount = 0;
      //} else {
      //  this.TipAmount = 0;
      //}
      if (this.IsCalculateTIPAmount == true && this.ActivateTipAmount == 1) {
        this.ReturnAmount = 0;
      } else {
        this.TipAmount = 0;
      }
      if (this.IsCateringFood)
        this.PayBillCatering();
      else
        this.paybill();
    }

  }

  backClick() {
    //debugger
    this.dialogRef.close();
  }

  numberOnly(event, value): boolean {
    //debugger
    const charCode = (event.which) ? event.which : event.keyCode;
    var eventKey = event.key;
    if (event.which == 46 && value.indexOf('.') != -1) {
      event.preventDefault();
    } // prevent if already dot

    else if (charCode == 46) {
      return true;
    }

    else if ((charCode < 48 || charCode > 57)) {
      return false;
    }

    else { return true; }

  }
  //   clickAll(){
  // this.cashPaidAmount = this.TotalAmount;
  // this.arrTempAmount = this._decimalPipe.transform(this.cashPaidAmount, '1.2-2');
  // this.arrTempAmount = this.arrTempAmount.replace(',', '');
  // this.grid_lstPaymentDetail = [];
  // this.lstPaymentDetail = [];
  // this.clickEnter();
  //   }
  //SelectedCutomer
  //CustomerId = th
  //custName = this
  custId: any;
  //LoyaltyPointsInfo
  //lstLoyaltyPointsData
  removeNumber() {
    debugger
    this.cashPaidAmount = this.cashPaidAmount.slice(0, -1);
    this.returnValue();
  }
  openDialogCustomerLookUp() {

    let dialogRef = this.dialog.open(DeliveryPopupComponent, {
      width: '1150px',
      height: '600px',
      data: { outletID: this.SelectedBranchID }
    });


    dialogRef.afterClosed().subscribe(result => {
      if (result[0] != undefined) {


        this.custId = result[0].custID;
        this.custName = result[0].custName;
        this.CustomerAddressID = result[0].CustAddressID;
        this.MobileNo = result[0].PhoneNo;
        this.CustomerID = this.custId
        this.MaxCreditLimit = result[0].MaxCreditLimit;
      }
      //if (result[1] != undefined) {
      //  this.LoyaltyPointsInfo = result[1];
      //}
      //else {
      //  this.lstLoyaltyPointsData = [];
      //}
      //if (result[2] != undefined) {
      //  this.lstLoyaltyPointsData = result[2];
      //  for (var i = 0; i < this.lstBasket.length; i++) {

      //    for (var j = 0; j < this.lstLoyaltyPointsData.length; j++) {

      //      if (this.lstLoyaltyPointsData[j].ProductID == this.lstBasket[i].ProductID && this.lstLoyaltyPointsData[j].ProductDetailID == this.lstBasket[i].ProductDetailID) {
      //        this.lstBasket[i].LoyalityID = this.lstLoyaltyPointsData[j].LoyalityID;
      //        this.lstBasket[i].LoyaltyDetailId = this.lstLoyaltyPointsData[j].LoyaltyDetailId;
      //        this.lstBasket[i].Points = this.lstLoyaltyPointsData[j].Points;
      //        this.lstBasket[i].ValuePoints = this.lstLoyaltyPointsData[j].ValuePoints;
      //        this.lstBasket[i].AvailingAmount = this.lstLoyaltyPointsData[j].AvailingAmount;
      //      }
      //    }
      //  }
      //  this.setloyalty(0, 0);
      //}
      //else {
      //  this.lstLoyaltyPointsData = [];
      //}

    });
  }


  PayBillCatering() {
    debugger
    // if (!this.IsComingKOT) {
    this.getSelectedDetailData();
    var CurrentDate = this.Utilities.GetCurrentDate();
    var OrderType = this.ArrMasterData[0].OrderType;

    let body = [this.ArrMasterData, this.ArrSelectedDetailData, this.GuestCutomer, this.MobileNo, this.CompanyId, this.cashPaidAmount, this.ReturnAmount,
    this.UserName, this.lstPaymentDetail, this.Utilities.getUserId(), CurrentDate, OrderType, this.SelectedBranchID, this.lstVoucherPaymentDetail,
    this.POSID, this.loyaltypoints, this.AvailingAmount, this.CurrencyCode, this.objoutletInfo.IsZatcaInvoiceOn, this.IsLoyaltySelected,
    this.Consumed_LoyaltyPoints, this.Consumed_LoyaltyAmount]

    this.dataservice.post('FoodCatering/SaveCateringOrderData/', body).subscribe(data => {
      debugger
      if (data['Table'][0].msg == 'sessionclosed') {
        this.IsSessionClosed = true;
        let message = 'sessionclosed';
        let dialogRef = this.dialog.open(AboutUsComponent,
          {
            width: '500px',
            height: '200px',

            data: { message },
          });

        dialogRef.afterClosed().subscribe(result => {
          if (result != undefined) {
          }

        });
        return
      }
      else if (data["Table"].length > 0) {
        this.receiptArray = data["Table"];
        this.totalItems = data["Table1"][0].noOfItems;
        this.totalQuantity = data["Table2"][0].noOfqty;
        sessionStorage.setItem('orderid', this.receiptArray = data["Table"][0]["OrderID"]);
        debugger;
        let returnbody = [this.receiptArray, data["Table4"], data["Table5"]]
        this.popup.ShowPopup('Insert');
        this.dialogRef.close(returnbody);
      }
    });
    // }
    // else {
    //   this.AddItemInKOT();
    // }

  }

}
class Company {
  CompanyID: any;
  CompanyName: any;
  outletsCount: any;
  CountryID: any;
  hearbout: any;
  Mobile: any;
  CurrencyCode: any;
  Rate: any;
  NumOfDecimal: any;
  CountryCode: any;
  IsOTPRequired: boolean;
}

class OrderMaster {
  OrderID: any;
  OrderInvoiceNo: any = '';
  PrepIn: any = '';
  PrepInOutlet: any = '';
  OrderPerpStatus: any = 'ALL';
  Brand: any = '';
  OutletID: any = '';
  OutletId: any = '';
  OutletName: any = '';
  AreaName: any = '';
  outletAddress: any = '';
  OrderType: any = '';
  Address: any = '';
  CustomerID: any = '';
  CustomerName: any = '';
  CustomerEmail: any = '';
  CountryCode: any = '';
  PhoneNo: any = '';
  PayType: any = '';
  Channel: any = '';
  PickupFrom: any = '';
  Remarks: any = '';
  SubTotal: any = '';
  TotalTaxAmount: any = '';
  TotalAmount: any = '';
  DeliveryFee: any = '';
  NetAmount: any = '';
  TotalDiscount: any = '';
  TaxID: any = '';
  TaxDescription: any = '';
  TaxPercent: any = '';
  OrderDate: any;
  OrderDateReal: any;
  CreatedOn: any;
  CreatedBy: any;
  ModifyOn: any;
  ModifyBy: any;
  RejectReasonID: any;
  RejectReason: any;
  RiderID: any;
  CompanyId: any;
  RejectReasonDetail: any = '';
  AddressPK: any = '';
}

class OrderDetail {
  OrderDetailID: any;
  OrderID: any;
  ProductId: any;
  ProductName: any = '';
  Quantity: any;
  UnitID: any;
  AddOnID: any;
  AddOnName: any;
  Amount: any;
}

class Country {
  CurrencyCode: any = '';
  Rate: any = '';
  NumOfDecimal: any = '';
  CountryName: any = '';
  CountryCode: any = '';
}

class CompanyInfo {
  ID: any
  TextColor: any = '';
  BaseColor: any = '';
  Title: any = '';
  Message: any = '';
  MainImagePath: any = '';
  MainImageName: any = '';
  IconImagePath: any = '';
  IconImageName: any = '';
  LogoImagePath: any = '';
  LogoImageName: any = '';

}

// class paymentDetail{
//   CustomerPaid:	number;
//   Rate:number = 1;
//   LocalCustomerPaid:number;
//   NetAmount:number;
//   PaymentMode:any;
//   PaymentCode:any;
//   PayTime:any;
//   PayDate:any;
//   Status:	any;
//   Remarks:any;
//   CreatedBy:any;
//   CreatedOn:any;
//   ModifyBy:any;
//   ModifyOn:any;
// }

class CateringFood {
  CustomerName: any;
  EventName: any;
  EventLocation: any;
  EventMap: any
  BookingDate: any
  // ,'BookingDate':this.objCateringFood.BookingDate
  // ,'DeliveryDate':this.objCateringFood.DeliveryDate
  DeliveryDate: any;
  ExpectedPeople: any;
  EventDiscription: any
  BugetAmount: any
}
