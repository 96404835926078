import { Component, ChangeDetectorRef } from '@angular/core';
import { DataserviseService } from '../../shared/dataservice';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilitiesService } from '../../shared/utilities.service';
import { TranslatePipe } from '../../shared/pipe/translate.pipe';

@Component({
  selector: 'ngx-a4sizereceipt',
  templateUrl: './a4sizereceipt.component.html',
  styleUrls: ['./a4sizereceipt.component.scss']
})
export class A4sizereceiptComponent  {
  ObjCompany: Company = new Company();
  CompanyName: string = '';
  OrderId: string = '';
  InvalidCompanyName: boolean = false;
  ObjOrderMaster: OrderMaster = new OrderMaster();
  ArrOrderDetail: OrderDetail[] = [];
  ArrVoucherData: VoucherData[] = [];
  ObjCurrency: Country = new Country();
  ObjCompanyInfo: CompanyInfo = new CompanyInfo();
  _PaymentDetails: PaymentDetails[] = [];
  CurrencyCode: any = '';
  QRUrl: any = '';
  elementType = 'img';
  SubTotal: any = this.translate.transform('NetTotal');
  NetAmount: any = this.translate.transform('lblFinalTotal');

  constructor(private translate: TranslatePipe, private router: Router, private route: ActivatedRoute, private dataservice: DataserviseService, private activatedRoute: ActivatedRoute, private Utilities: UtilitiesService, private cdr: ChangeDetectorRef)
  {  }

  lstUser = [];
  CompName:any;
  IsFoodCatering: boolean = false;
  IsFoodCateringCopy: any;
  ngOnInit(): void {debugger
    // this.CompName = this.Utilities.getURLCompanyName();
    this.InvalidCompanyName = false;
    debugger
    this.activatedRoute.paramMap.subscribe(x => {
      this.CompanyName = x.get('CompanyName');
      this.OrderId = x.get('OrderId');
      this.IsFoodCateringCopy = x.get('IsFoodCatering');
      this.IsFoodCatering = this.IsFoodCateringCopy == null ? false : this.IsFoodCateringCopy;

      // this.getCompanyData();

      
      this.getMasterDetailData(this.Utilities.getDecrypt(this.OrderId));
      if (this.CompanyName == null) {
        this.InvalidCompanyName = true;
        return;
      }
    });


    
debugger
  this.lstUser = JSON.parse(localStorage.getItem('sessionObject'+this.Utilities.getURLCompanyName()));
  // this.QRUrl = this.Utilities.getQRURL(this.CompanyName, this.OrderId, this.lstUser[0][0]['CountryCode']);

  //   console.log(this.QRUrl);

  }

  printComponent() {
    window.print();
  }
  LoyaltyPoints: any = 0;
  ReturnAmount: any;
  async getMasterDetailData(OrderID) {
    this.ObjOrderMaster = new OrderMaster();
    this.ArrOrderDetail = [];
    this._PaymentDetails = [];

    await this.dataservice.get('Receipt/GetOrderDataWithCompanyName/' + OrderID + '/' + this.Utilities.getDecrypt(this.CompanyName)+ '/' + this.IsFoodCatering )
      .subscribe(data => {
        debugger
        if (this.isArrayHasValue(data[0]['CompanyData'])) {
          this.InvalidCompanyName = false;
          this.ObjCompany.CompanyID = data[0]['CompanyData'][0].CompanyID;
          this.ObjCompany.CompanyName = data[0]['CompanyData'][0].CompanyName;
          this.ObjCompany.outletsCount = data[0]['CompanyData'][0].outletsCount;
          this.ObjCompany.CountryID = data[0]['CompanyData'][0].CountryID;
          this.ObjCompany.hearbout = data[0]['CompanyData'][0].hearbout;
          this.ObjCompany.Mobile = data[0]['CompanyData'][0].Mobile;
          this.ObjCompany.IsOTPRequired = data[0]['CompanyData'][0].IsOTPRequired;
          this.ObjCompany.CurrencyCode = data[0]['CompanyData'][0].CurrencyCode;
          this.ObjCompany.Rate = data[0]['CompanyData'][0].Rate;
          this.ObjCompany.NumOfDecimal = data[0]['CompanyData'][0].NumOfDecimal;
          this.ObjCompany.CountryCode = data[0]['CompanyData'][0].CountryCode;
        }
        else {
          this.InvalidCompanyName = true;
          this.QRUrl = 'Invalid company name!';
          alert('Invalid company name!');
        }
        if (data[0]['MasterData']) {debugger
          this.ObjOrderMaster = data[0]['MasterData'][0];
          console.log('ObjOrderMaster')
          console.log(this.ObjOrderMaster)
          
        }
        if (this.isArrayHasValue(data[0]['DetailData'])) {
          this.ArrOrderDetail = data[0]['DetailData'];
        }
        else {
          this.ArrOrderDetail = [];
        }

        if (this.isArrayHasValue(data[0]['VoucherData'])) {
          
          this.ArrVoucherData = data[0]['VoucherData'];
        }
        else {
          this.ArrVoucherData = [];
        }

        if (data[0]['Currency']) {
          this.ObjCurrency = data[0]['Currency'][0];
          this.CurrencyCode = this.ObjCurrency.CurrencyCode;
        }

        if (data[0]['CompanyInfo']) {
          this.ObjCompanyInfo = data[0]['CompanyInfo'][0];
          console.log(this.ObjCompanyInfo)
        }
        if (data[0]['ConsumedPoints'][0] && data[0]['ConsumedPoints'][0].ConsumedPoints != null && data[0]['ConsumedPoints'][0].ConsumedPoints != undefined) {
          this.LoyaltyPoints = data[0]['ConsumedPoints'][0].ConsumedPoints;
        }
        else {
          this.LoyaltyPoints = 0;
        }
        
        if (data[0]['QRcodeBase64']) {
          this.QRUrl = data[0]['QRcodeBase64'][0].Base64;
          console.log(    this.QRUrl);
        
        }
        debugger

        if (this.isArrayHasValue(data[0]['PaymentDetails'])) {
          this._PaymentDetails = data[0]['PaymentDetails'];
        }
        if (this.isArrayHasValue(data[0]['ReturnAmount'])) {debugger
          this.ReturnAmount = data[0].ReturnAmount[0].CashReturned;
        }

        setTimeout(() => {
          // this.cdr.detectChanges();
        window.print();
      }, 10);
       
      }, error => alert(this.Utilities.getconnectionmsg()));
  }


  isArrayHasValue(obj: any[]): boolean {
    let isArrayWithLength = Boolean(Array.isArray(obj) && obj.length);
    return isArrayWithLength;
  }

}
class Company {
  CompanyID: any;
  CompanyName: any;
  outletsCount: any;
  CountryID: any;
  hearbout: any;
  Mobile: any;
  CurrencyCode: any;
  Rate: any;
  NumOfDecimal: any;
  CountryCode: any;
  IsOTPRequired: boolean;
}

class OrderMaster {
  OrderID: any;
  OrderInvoiceNo: any = '';
  PrepIn: any = '';
  PrepInOutlet: any = '';
  OrderPerpStatus: any = 'ALL';
  Brand: any = '';
  OutletID: any = '';
  OutletId: any = '';
  OutletName: any = '';
  AreaName: any = '';
  outletAddress: any = '';
  OrderType: any = '';
  Address: any = '';
  CustomerID: any = '';
  CustomerName: any = '';
  CustomerEmail: any = '';
  CountryCode: any = '';
  PhoneNo: any = '';
  PayType: any = '';
  Channel: any = '';
  PickupFrom: any = '';
  Remarks: any = '';
  SubTotal: any = '';
  TotalTaxAmount: any = '';
  TotalAmount: any = '';
  DeliveryFee: any = '';
  NetAmount: any = '';
  TotalDiscount: any = '';
  TaxID: any = '';
  TaxDescription: any = '';
  TaxPercent: any = '';
  OrderDate: any;
  OrderDateReal: any;
  OrderTime: any;
  CreatedOn: any;
  CreatedBy: any;
  ModifyOn: any;
  ModifyBy: any;
  RejectReasonID: any;
  RejectReason: any;
  RiderID: any;
  CompanyId: any;
  RejectReasonDetail: any = '';
  AddressPK: any = '';
  Cashier: any = '';
  OutletPhone: any = '';
  TableNumber: any;
  AdditionalDirection: any = '';
  PromoCode: any;
  PromoAmount: any;
  LoyaltyAmount: any;
  ArabicOutletName : any;
  OrderNo : any;
  Company : any;
  VATNo : any;
  IsExtraCharge:any;
  ArabicAddress:any;
  ArabicArea:any;
  CustomerNameArabic:any;
  BuildingNo:any;
  StreetName:any;
  District:any;
  DistrictArabic:any;
  CountryName:any;
  City:any;
  ZipCode:any;
  AdditionalNo:any;
  CRNumber:any;
  COID:any;

}

class OrderDetail {
  OrderDetailID: any;
  OrderID: any;
  ProductId: any;
  ProductName: any = '';
  Quantity: any;
  UnitID: any;
  AddOnID: any;
  AddOnName: any;
  Amount: any;
  ProductDetailID: any;
  ProductSizeName: any = '';
  Description: any = '';
  OrignalAmount: any;
  AProductName : any;
}

class Country {
  CurrencyCode: any = '';
  Rate: any = '';
  NumOfDecimal: any = '';
  CountryName: any = '';
  ACountryName: any = '';
  CountryCode: any = '';
  Mobile:any;
}

class CompanyInfo {
  ID: any;
  TextColor: any = '';
  BaseColor: any = '';
  Title: any = '';
  Message: any = '';
  MainImagePath: any = '';
  MainImageName: any = '';
  IconImagePath: any = '';
  IconImageName: any = '';
  LogoImagePath: any = '';
  LogoImageName: any = '';
  BrandName: any = '';
  VATNo: any = '';
  ReceiptMsg: any = '';
  BankNameArabic:any;
  IBAN:any;
  CompanyRegName:any;
  CompanyRegNameAra:any;
  BuildingNo:any;
  AccountNumber:any;
  StreetName:any;
  StreetNameArabic:any;
  District:any;
  DistrictArabic:any;
  CityName:any;
  CityNameArabic:any;
  PostalZone:any;
  AdditionalNo:any;
  Mobile:any;
  CRNNo:any;
  COIDNO:any;
  CustomerNameArabic:any;
  BankName:any;
  // BuildingNo:any;

}
class VoucherData {
  RowID: any;
  OrderId: any;
  VoucherCode: any;
  VoucherId: any;
  IsPercent: any;
  VoucherAmount: any;
  CompanyId: any;
}

class PaymentDetails {
  Description: any;
  NetAmount: any;
}

