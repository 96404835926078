
import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataserviseService } from '../../../shared/dataservice';
import { UtilitiesService } from '../../../shared/utilities.service';
import { OutletInfo } from '../../pos/pos-retail.component'
import { PopupService } from '../../../shared/popup';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { SpinnerService } from '../../../shared/spinner';
import { ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { DecimalPipe, getLocaleDateFormat } from '@angular/common';
import { LocalDataSource } from 'ng2-smart-table';
import { TranslatePipe } from '../../../shared/pipe/translate.pipe';
import { AboutUsComponent } from '../../../about-us/about-us.component';
import { DeliveryPopupComponent } from '../../delivery-popup/delivery-popup.component';
import { RetailMaster, RetailDetail } from '../../retailpos/retailpos.component';

@Component({
  selector: 'ngx-retailcalculator-popup',
  templateUrl: './retailcalculator-popup.component.html',
  styleUrls: ['./retailcalculator-popup.component.scss']
})
export class RetailCalculatorPopupComponent implements OnInit {
  @ViewChild("printfarme") printfarme: ElementRef;

  TotalAmount: any;
  cashPaidAmount = '';
  ReturnAmount: any = 0;
  TipAmount: any = 0;
  // Calc_value:any;

  CompanyId: number;
  MobileNo: any;
  MaxCreditLimit: any;
  GuestCutomer = [];

  rdPaymentMethod: any = 'Cash';
  objoutletInfo: OutletInfo = new OutletInfo();

  ObjRetailMaster: RetailMaster = new RetailMaster();
  ArrRetailDetail: RetailDetail[] = [];
  ObjRetail: RetailDetail = new RetailDetail();
  // objPaymentDetail: paymentDetail =new paymentDetail();

  Remarks: any;

  VAT: any = '';
  OrderType: any = 'N/A';
  OrderTypeID: any = 0;
  OrderPerpStatus: any = 'New';
  DiscountCode: any;
  TimeStampSelected: any = '30';
  TaxId: number = 0;
  lstSelectedModifiers: any = [];
  UserName: any;
  TableName: any;
  noOfCustomers: any;
  CustomerAddressID: any;
  IsComingKOT: boolean;
  KotOrderID: any;
  ReceiptURL: any;
  sumAmount: any = 0;
  logo = '../../../assets/images/globalinformix.png';
  // ReportUrl: SafeResourceUrl;
  // UrlString: string = "http://localhost:64764/api";

  moneyImg: string = './assets/images/money.PNG';
  lstVoucherPaymentDetail: any = [];
  ObjCompany: Company = new Company();
  CompanyName: string = '';
  OrderId: string = '';
  InvalidCompanyName: boolean = false;
  HoldOrderID: any = 0;

  ObjCompanyInfo: CompanyInfo = new CompanyInfo();

  CurrencyCode: any = '';
  NumOfDecimal: any = '';
  QRUrl: any = '';
  POSID: any;
  title = 'app';
  elementType = 'url';
  lstPaymentDetail = [];
  IsCashPaidAmountClear: boolean = true;
  loyaltypoints: any = 0;
  AvailingAmount: any = 0;

  IsExtraCharge: boolean = false;
  ExtraChargeDescription: any = '';
  ExtraChargeDescriptionArabic: any = '';
  ExtraChargePercentage: number = 0;
  ExtraChargeAmount: number = 0;
  IsCalculateTIPAmount: boolean = false;
  Back: any = this.translate.transform('Back')
  TotalPayment: any = this.translate.transform('TotalPayment')
  PaymentMethods: any = this.translate.transform('PaymentMethods')
  CLEAR: any = this.translate.transform('Clear')
  PAY: any = this.translate.transform('PAY')
  ReturnedCash: any = this.translate.transform('ReturnedCash')
  Reference: any = this.translate.transform('Reference')
  lblTotalPayment: any = this.translate.transform('TotalPayment')
  pleaseenterAmount: any = this.translate.transform('pleaseenterAmount')
  SessionClosed: any = this.translate.transform('SessionClosed')
  Customer: any = this.translate.transform('Customer')
  pleaseselectCustomer: any = this.translate.transform('Pleaseselectcustomer')
  ShowCustomerLookup: boolean = false;
  custName: any;

  settings = {
    actions: {
      columnTitle: '',
      add: false,
      edit: false,
    },
    mode: 'external',


    edit: {
      editButtonContent: '<i class="nb-edit"></i>',
      saveButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
      confirmSave: true
    },
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
      confirmDelete: true
    },
    columns: {
      // paymentName: {
      //   title: 'Payment Name'
      // },
      payMode: {
        title: this.translate.transform('PayMode')// 'Pay Mode'
      },
      payTime: {
        title: this.translate.transform('PayTime')//'Pay Time'
      },

      Amount: {
        title: this.translate.transform('CustomerPaid')//'Customer Paid'
      },
      NetAmount: {
        title: this.translate.transform('NetAmount')// 'Net Amount'
      },
      Reference: {
        title: this.translate.transform('Reference')//'Reference'
      },
    },
    attr: {
      class: 'table table-bordered'
    },
    hideSubHeader: true
  };
  source1: LocalDataSource;
  lstPaymentMode = [];
  rdPaymentcode: any;
  ActivateTipAmount: any;
  CustomerID: any;
  Status: any;
  DayID: any;
  ShiftID: any;
  OrderPerpStatus123: any = 'New';
  SpecialRequest: any;


  constructor(private translate: TranslatePipe, public dialog: MatDialog, private domSanitizer: DomSanitizer, private router: ActivatedRoute, public dialogRef: MatDialogRef<RetailCalculatorPopupComponent>, @Inject(MAT_DIALOG_DATA) public parentData: any, private dataservice: DataserviseService, private Utilities: UtilitiesService, private popup: PopupService, private spinerService: SpinnerService, private _decimalPipe: DecimalPipe) {
    this.ObjRetailMaster = this.parentData["ObjRetailMaster"];
    this.ArrRetailDetail = this.parentData["ArrRetailDetail"];
    console.log(this.ObjRetailMaster);
    console.log(this.ArrRetailDetail);
  }

  ngOnInit(): void {

    debugger
    this.CompanyId = this.Utilities.getSubscriptionID();

    this.TotalAmount = this.ObjRetailMaster.NetAmount;
    this.NumOfDecimal = this.parentData['NumOfDecimal'];
    this.cashPaidAmount = this._decimalPipe.transform(this.TotalAmount, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal)
    this.arrTempAmount = this.cashPaidAmount;

    this.objoutletInfo = this.parentData["outletInfo"];

    if (this.parentData["HoldOrderID"] != undefined)
      this.HoldOrderID = this.parentData["HoldOrderID"];
    
    this.CustomerID = this.ObjRetailMaster.CustomerID;
    this.CustomerAddressID = this.ObjRetailMaster.CustAddressID;

    this.CurrencyCode = this.parentData['CurrencyCode'];
    
 

    this.get_paymentmodes();

  }

  get_paymentmodes() {
    this.dataservice.get('pos/GetPaymentModes/' + this.CompanyId + '/' + localStorage.getItem('LanguageCulture' + this.Utilities.getURLCompanyName()) + '/' + this.ObjRetailMaster.POSID +'/'+this.ObjRetailMaster.OutletID).subscribe(data => {
      if (this.isArrayHasValue(data)) {
        //console.log(data)
        this.lstPaymentMode = data;
        this.rdPaymentcode = this.lstPaymentMode[0].Code;
        this.rdPaymentMethod = this.lstPaymentMode[0].PaymentName;
        this.ActivateTipAmount = this.lstPaymentMode[0].ActivateTipAmount;
      }
    })
  }

  replaceAstrophe(val): any {
    return val.replaceAll("'", "''");
  }

  receiptArray = [];
  totalItems: any;
  totalQuantity: any;
  IsSessionClosed: boolean = false;
  IsLoyaltySelected: boolean = false;
  Consumed_LoyaltyAmount: any = 0.0;
  Consumed_LoyaltyPoints: any = 0;

  paybill() {
    debugger
    
    this.ObjRetailMaster.PayType = this.rdPaymentMethod;

      var CurrentDate = this.Utilities.GetCurrentDate();

      let body = [this.ObjRetailMaster, this.ArrRetailDetail, this.lstPaymentDetail, this.lstVoucherPaymentDetail, this.Utilities.getUserId(), this.Utilities.getSubscriptionID(), this.ObjRetailMaster.OutletID, this.ObjRetailMaster.POSID, CurrentDate, this.HoldOrderID]
      this.dataservice.post('retailpos/SaveOrderData/', body).subscribe(data => {
        debugger
        if (data['Table'][0].msg == 'sessionclosed') {
          this.IsSessionClosed = true;
          let message = 'sessionclosed';
          let dialogRef = this.dialog.open(AboutUsComponent,
            {
              width: '500px',
              height: '200px',

              data: { message },
            });

          dialogRef.afterClosed().subscribe(result => {
            if (result != undefined) {


            }

          });
          // this.grid_lstPaymentDetail = [];
          // this.source1 = new LocalDataSource(this.grid_lstPaymentDetail);

          return
        }
        else if (data["Table"].length > 0) {
          this.receiptArray = data["Table"];
          this.totalItems = data["Table1"][0].noOfItems;
          this.totalQuantity = data["Table2"][0].noOfqty;
          sessionStorage.setItem('orderid', this.receiptArray = data["Table"][0]["OrderID"]);
          debugger;
          let returnbody = [this.receiptArray, data["Table4"], data["Table5"]]
          this.popup.ShowPopup('Insert');
          this.dialogRef.close(returnbody);

        }
      });


  }
  lstUser = [];
  checkUrl() {
    //debugger
    this.lstUser = JSON.parse(localStorage.getItem('sessionObject' + this.Utilities.getURLCompanyName()));
    // this.ReceiptURL = this.Utilities.getReceiptURL(this.Utilities.getEncrypt(this.Utilities.getCompanyName()), this.Utilities.getEncrypt(sessionStorage.getItem('orderid')), this.lstUser[0]['CountryCode']);
    this.ReceiptURL = this.Utilities.getDigiPosReceiptURL(this.Utilities.getEncrypt(this.Utilities.getCompanyName()), this.Utilities.getEncrypt(sessionStorage.getItem('orderid')), this.lstUser[0]['CountryCode']);



  }

  async printfnc() {
    window.document.write("hello");

    window.document.write("<ngx-qrcode [elementType]='elementType'   [value] = " + this.ReceiptURL + " cssClass = 'aclass' errorCorrectionLevel = 'L' ></ngx-qrcode>");
    window.document.write("");
    window.focus(); // necessary for IE >= 10*-/
    window.print();
    //printWindow.close();
  }




  PaymentTypeChangeFn(value) {
    debugger
    this.rdPaymentcode = value.Code;
    this.rdPaymentMethod = value.PaymentName;
    this.ActivateTipAmount = value.ActivateTipAmount;
    this.returnValue();
  }




  isArrayHasValue(obj: any[]): boolean {
    let isArrayWithLength = Boolean(Array.isArray(obj) && obj.length);
    return isArrayWithLength;
  }

  // copyCashPaidAmount:any;
  onDelete(event) {
    //debugger;
    this.enterAmount = false;
    this.ReturnAmount = '';
    this.cashPaidAmount = '';
    this.tempAmount = 0;
    if (event) {
      this.grid_lstPaymentDetail.splice(event.index, 1);
      this.lstPaymentDetail.splice(event.index, 1);
      this.source1 = new LocalDataSource(this.grid_lstPaymentDetail);

      // this.copyCashPaidAmount='';
      this.grid_lstPaymentDetail.forEach(a => this.tempAmount += +a.Amount);
      this.cashPaidAmount = this._decimalPipe.transform(this.TotalAmount - this.tempAmount, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal);

      this.paymentAmount = 0;
      this.paymentAmount = parseFloat(this.cashPaidAmount);
      this.grid_lstPaymentDetail.forEach(a => this.paymentAmount += +a.Amount);
      if (parseFloat(this.paymentAmount.toFixed(3)) > parseFloat(this.TotalAmount.toFixed(3)) && this.rdPaymentcode == 1) {
        this.ReturnAmount = this._decimalPipe.transform(this.paymentAmount - this.TotalAmount, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal);
        this.TipAmount = 0;
      }
      else {
        if (this.IsCalculateTIPAmount == true && this.ActivateTipAmount == 1)
          this.TipAmount = this._decimalPipe.transform(this.paymentAmount - this.TotalAmount, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal);
        else
          this.TipAmount = 0;
        this.ReturnAmount = 0;
      }
    }
  }


  calcButtonclick(value) {
    //debugger
    if (value == 'AC' || value == "") {
      this.cashPaidAmount = '';
      this.arrTempAmount = '';
      this.ReturnAmount = '';
      return
    }
    else {
      debugger
      if (this.IsCashPaidAmountClear == true) {
        this.cashPaidAmount = '';
        this.IsCashPaidAmountClear = false;
      }
      this.cashPaidAmount = this.cashPaidAmount + value;
      this.cashPaidAmount = this.cashPaidAmount.substring(0, 15);
      var dotlength = this.cashPaidAmount.split(".").length - 1;

      if (dotlength > 1) {
        this.cashPaidAmount = this.cashPaidAmount.slice(0, -1);
        return
      }
      else {
        this.arrTempAmount = this.cashPaidAmount
        this.arrTempAmount = this._decimalPipe.transform(this.arrTempAmount, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal)
        this.returnValue();
      }
    }
  }

  tempAmount: number = 0;
  arrTempAmount: any = '';
  inputValueChange(value) {
    debugger
    const charCode = (value.which) ? value.which : value.keyCode;
    var eventKey = value.key;
    // value = parseFloat(value)
    if (value == 'AC' || value == "") {
      this.cashPaidAmount = '';
      this.arrTempAmount = '';
      this.ReturnAmount = '';
      return
    }
    else {
      this.arrTempAmount = ''
      this.arrTempAmount = this.arrTempAmount + value;
      this.cashPaidAmount = this.arrTempAmount;
      this.arrTempAmount = this._decimalPipe.transform(this.arrTempAmount, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal);
      this.returnValue();
    }
  }

  returnValue() {
    debugger
    var copyCashPaidAmount = parseFloat(this.cashPaidAmount);
    this.grid_lstPaymentDetail.forEach(a => copyCashPaidAmount += +a.Amount);
    let amount = this._decimalPipe.transform(copyCashPaidAmount - this.TotalAmount, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal);
    if (copyCashPaidAmount > this.TotalAmount) { /*&& this.rdPaymentcode == 1*/
      this.ReturnAmount = amount;
      this.TipAmount = amount;
    }
    //else {
    //  if (this.IsCalculateTIPAmount == true && this.rdPaymentcode != 5)
    //    this.TipAmount = amount;
    //  else
    //    this.TipAmount = 0;
    //  this.ReturnAmount = amount;
    //}
    if (this.rdPaymentcode == 5) {
      this.ShowCustomerLookup = true;
    }
    else {
      this.ShowCustomerLookup = false;
      this.SelectCustomer = false;
    }
    if (this.IsCalculateTIPAmount == true && this.ActivateTipAmount == 1) {
      this.ReturnAmount = 0;
    } else {
      this.TipAmount = 0;
    }

  }
  expectedAmount: any;
  changeEvent() {
    debugger


  }

  grid_lstPaymentDetail = [];
  enterAmount: boolean = false;
  SelectCustomer: boolean = false;
  maxlimit: boolean = false;
  paymentAmount: number = 0;
  paytime: any;
  Currdate: any;
  arrRemarks: any;
  clickEnter() {

    if (this.IsSessionClosed == true) {
      let message = 'sessionclosed';
      let dialogRef = this.dialog.open(AboutUsComponent,
        {
          width: '500px',
          height: '200px',

          data: { message },
        });

      dialogRef.afterClosed().subscribe(result => {
        if (result != undefined) {
        }

      });
      return
    }
    else if (!this.arrTempAmount || !this.cashPaidAmount) {
      this.enterAmount = true;
      return

    }
    else {
      this.arrTempAmount = this.arrTempAmount.replace(',', '');
      this.enterAmount = false;
      this.tempAmount = parseFloat(this.cashPaidAmount.replace(',', '')) + this.tempAmount;
      debugger
      //  this.returnValue += parseFloat(this.cashPaidAmount);
      // this.cashPaidAmount = this.grid_lstPaymentDetail.amount - this.TotalAmount;
    }


    if (this.Remarks == null || this.Remarks == undefined || this.Remarks == '') {
      this.arrRemarks = null;
    }
    else {
      this.arrRemarks = this.Remarks;
    }
    if (this.CustomerID == null || this.CustomerID == undefined || this.CustomerID == '') {
      this.CustomerID = null;
    }

    if (this.rdPaymentcode == 5 && (this.CustomerID == null || this.CustomerID == undefined || this.CustomerID == '')) {
      this.SelectCustomer = true;
      return
    }
    else {
      this.SelectCustomer = false;
    }
    if (this.rdPaymentcode == 5 && this.MaxCreditLimit != null && this.MaxCreditLimit != undefined && this.cashPaidAmount > this.MaxCreditLimit) {
      this.maxlimit = true;
      return;
    }
    else {
      this.maxlimit = false;
    }
    this.paytime = new Date().toLocaleTimeString([], { hour: '2-digit', minute: "2-digit" });
    var nowDate = new Date();
    this.Currdate = nowDate.getFullYear() + '/' + (nowDate.getMonth() + 1) + '/' + nowDate.getDate();
    debugger
    let remainingAmount
    if (this.ReturnAmount)
      remainingAmount = +this.cashPaidAmount - this.ReturnAmount;
   else if (this.TipAmount)
      remainingAmount = +this.cashPaidAmount - this.ReturnAmount;
    else
      remainingAmount = +this.cashPaidAmount ;
    this.grid_lstPaymentDetail.push({ 'paymentName': this.CurrencyCode, 'payTime': this.paytime, 'payMode': this.rdPaymentMethod, 'Amount': this.cashPaidAmount.replace(',', ''), 'NetAmount': remainingAmount, 'Reference': this.Remarks });
    this.paymentAmount = 0
    this.grid_lstPaymentDetail.forEach(a => this.paymentAmount += +a.Amount);

    debugger;
    if (this.ReturnAmount) {
      this.expectedAmount = +this.cashPaidAmount - this.ReturnAmount.replaceAll(',', '');
      this.expectedAmount = parseFloat(this.expectedAmount.toFixed(3)) + '';
      this.expectedAmount = this._decimalPipe.transform(this.expectedAmount, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal)


    }
    if (this.TipAmount) {
      this.expectedAmount = +this.cashPaidAmount - this.TipAmount.replaceAll(',', '');
      this.expectedAmount = parseFloat(this.expectedAmount.toFixed(3)) + '';
      this.expectedAmount = this._decimalPipe.transform(this.expectedAmount, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal)

    
    }

    this.lstPaymentDetail.push({
      'CustomerID': this.CustomerID, 'CustomerPaid': this.cashPaidAmount.replace(',', ''), 'Rate': 1, 'LocalCustomerPaid': this.cashPaidAmount.replace(',', ''),
      'NetAmount': this.expectedAmount == undefined ? this.cashPaidAmount.replace(',', '') : this.expectedAmount.replace(',', ''), 'PaymentMode': this.rdPaymentMethod, 'PaymentCode': this.rdPaymentcode,
      'PayTime': this.paytime, 'PayDate': this.Currdate,
      'Status': this.Status, 'Remarks': this.arrRemarks, 'CreatedBy': this.UserName, 'CreatedOn': this.Currdate
    });

    if (parseFloat(this.TotalAmount.toFixed(3)) > parseFloat(this.paymentAmount.toFixed(3))) {
      this.cashPaidAmount = this._decimalPipe.transform(this.TotalAmount - this.paymentAmount + '', '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal);
    }
    else {
      this.cashPaidAmount = '';
    }
    this.source1 = new LocalDataSource(this.grid_lstPaymentDetail);
    this.source1.update(this.grid_lstPaymentDetail, this.grid_lstPaymentDetail);
    this.Remarks = null;

    if (parseFloat(this.tempAmount.toFixed(3)) >= parseFloat(this.TotalAmount.toFixed(3))) {
      //if (this.rdPaymentcode == 1) {
      this.ReturnAmount = this.TotalAmount - this.tempAmount;
      this.ReturnAmount = this._decimalPipe.transform(this.ReturnAmount, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal)
      this.TipAmount = this.ReturnAmount > 0 ? this.ReturnAmount : -(this.ReturnAmount);
     
      if (this.IsCalculateTIPAmount == true && this.ActivateTipAmount == 1) {
        this.ReturnAmount = 0;
      } else {
        this.TipAmount = 0;
      }
      this.paybill();

   
    }

  }

  backClick() {
    //debugger
    this.dialogRef.close();
  }

  numberOnly(event, value): boolean {
    //debugger
    const charCode = (event.which) ? event.which : event.keyCode;
    var eventKey = event.key;
    if (event.which == 46 && value.indexOf('.') != -1) {
      event.preventDefault();
    } // prevent if already dot

    else if (charCode == 46) {
      return true;
    }

    else if ((charCode < 48 || charCode > 57)) {
      return false;
    }

    else { return true; }

  }

  removeNumber() {
    this.cashPaidAmount = this.cashPaidAmount.slice(0, -1);
    this.returnValue();
  }
  openDialogCustomerLookUp() {

    let dialogRef = this.dialog.open(DeliveryPopupComponent, {
      width: '1150px',
      height: '600px',
      data: { outletID: this.objoutletInfo.BranchID }
    });


    dialogRef.afterClosed().subscribe(result => {
      if (result[0] != undefined) {

        this.CustomerID = result[0].CustAddressID;
        this.custName = result[0].custName;
        this.CustomerAddressID = result[0].CustAddressID;
        this.MobileNo = result[0].PhoneNo;
        this.MaxCreditLimit = result[0].MaxCreditLimit;

        this.ObjRetailMaster.CustomerID = this.CustomerID;
        this.ObjRetailMaster.CustAddressID = this.CustomerAddressID;
      }
      

    });
  }


}
class Company {
  CompanyID: any;
  CompanyName: any;
  outletsCount: any;
  CountryID: any;
  hearbout: any;
  Mobile: any;
  CurrencyCode: any;
  Rate: any;
  NumOfDecimal: any;
  CountryCode: any;
  IsOTPRequired: boolean;
}



class Country {
  CurrencyCode: any = '';
  Rate: any = '';
  NumOfDecimal: any = '';
  CountryName: any = '';
  CountryCode: any = '';
}

class CompanyInfo {
  ID: any
  TextColor: any = '';
  BaseColor: any = '';
  Title: any = '';
  Message: any = '';
  MainImagePath: any = '';
  MainImageName: any = '';
  IconImagePath: any = '';
  IconImageName: any = '';
  LogoImagePath: any = '';
  LogoImageName: any = '';

}

