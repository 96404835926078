import { Component, OnInit } from '@angular/core';
import { Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslatePipe } from '../../../shared/pipe/translate.pipe';

@Component({
  selector: 'ngx-qtyboard-popup',
  templateUrl: './qtyboard-popup.component.html',
  styleUrls: ['./qtyboard-popup.component.scss']
})
export class QtyboardPopupComponent implements OnInit {
  txtboxAmount: any = '';
  txtamt: HTMLInputElement = document.getElementById("txtboxAmount") as HTMLInputElement;
  
  OK :  any = this.translate.transform('OK');
  Close : any = this.translate.transform('Close');
  constructor(private translate: TranslatePipe,public dialogRef: MatDialogRef<QtyboardPopupComponent>, @Inject(MAT_DIALOG_DATA) public parentData: any,) { }

  ngOnInit(): void {debugger
  }

  numberOnly(event, value): boolean {debugger
    const charCode = (event.which) ? event.which : event.keyCode;
    var eventKey = event.key;
    if(event.which == 46 && !this.parentData.IsQtyInDecimal && this.parentData.IsQtyPopUp)
    event.preventDefault();
    if (event.which == 46 && value.indexOf('.') != -1) {
      event.preventDefault();
    } // prevent if already dot

    else if (charCode == 46) {
      return true;
    }

    else if ((charCode < 48 || charCode > 57)) {
      return false;
    }

    else { return true; }

  }

  calcButtonclick(value){debugger
    document.getElementById('txtboxAmount').focus();
    if(value == 'AC' || value == ""){
    this.txtboxAmount = '';
    return
    }
    else {
      const reg = /^-?\d*(\.\d{0,3})?$/;

      if (!reg.test(this.txtboxAmount)) {
        return;
      }

      this.txtboxAmount = this.txtboxAmount + value;
      this.txtboxAmount = this.txtboxAmount.substring(0, 12);
    }

    

  }
  ClickDecimal(): void {debugger
    var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

    if (!format.test(this.txtboxAmount)) {
      if(this.parentData.IsQtyInDecimal || !this.parentData.IsQtyPopUp){
        this.txtboxAmount = this.txtboxAmount + '.';
      }
      if (this.txtboxAmount.length > 11 && this.txtboxAmount[this.txtboxAmount.length - 1] === "."){
      var value = this.txtboxAmount;
      this.txtboxAmount = value.slice(0, -1);
      }     
      
    }

  }

  removeNumber(){
    this.txtboxAmount = this.txtboxAmount.slice(0, -1);
  }


  inputValueChange(value){
    debugger
    // value = parseFloat(value)
    if(value == 'AC' || value == ""){
    this.txtboxAmount = '';
    // this.arrTempAmount = '';
    return
    }
    else{
      if (this.txtboxAmount.length > 11 && this.txtboxAmount[this.txtboxAmount.length - 1] === "."){
      var value = this.txtboxAmount;
      this.txtboxAmount = value.slice(0, -1);
      }
      // value.slice(-1) === "."
      // this.txtboxAmount = value
      // this.arrTempAmount =''
      // this.arrTempAmount = this.arrTempAmount + value;  
        // this.txtboxAmount = this.arrTempAmount;
        // this.arrTempAmount = this._decimalPipe.transform(this.arrTempAmount, '1.'+this.NumOfDecimal+'-'+this.NumOfDecimal)
      
    }
  }

  close(){
    this.dialogRef.close();
  }
  ok() {
    debugger
    if (this.txtboxAmount == undefined || this.txtboxAmount == '') {
      this.dialogRef.close();
      return;
    }

    const decimalPattern: RegExp = /^(?:\d+)?(?:\.\d+)?$/;

    if (!decimalPattern.test(this.txtboxAmount)) {
      alert('Please enter valid value.')
    } else {
      this.dialogRef.close(this.txtboxAmount);
    }
  }
}
