<link href="//stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet">

<nb-card>
  <nb-card-header>
    <ngx-header></ngx-header>
    <div class="parent1">
      <div class="child1">{{'LiveOrders' | translate}}</div>

    </div>
  </nb-card-header>
  <!--<div (click)="open()">Open popup</div>-->
  <nb-card-body>

    <nb-tabset (changeTab)="onChangeTab($event)">
      <nb-tab tabTitle="{{'ALL' | translate}}" [active]="ActiveTabAll"
              badgeText="{{AllCount == 0 ? '' : AllCount}}"
              badgePosition="top right"
              badgeStatus="success" [disabled]="AllCount == undefined || AllCount < 1">
      </nb-tab>

      <nb-tab tabTitle="{{'NEW' | translate}}" [active]="ActiveTabNew"
              badgeText="{{NewCount == 0 ? '' : NewCount}}"
              badgePosition="top right"
              badgeStatus="danger" [disabled]="NewCount == undefined || NewCount < 1">
      </nb-tab>
      <nb-tab tabTitle="{{'INKitchen' | translate}}" [active]="ActiveTabInKitchen"
              badgeText="{{InKitchenCount == 0 ? '' : InKitchenCount}}"
              badgePosition="top right"
              badgeStatus="success" [disabled]="InKitchenCount == undefined || InKitchenCount < 1">

      </nb-tab>
      <nb-tab tabTitle="{{'INRouteReady' | translate}}" [active]="ActiveTabReadyToCollect"
              badgeText="{{InRouteCount == 0 ? '' : InRouteCount}}"
              badgePosition="top right"
              badgeStatus="success" [disabled]="InRouteCount == undefined || InRouteCount < 1">
      </nb-tab>


    </nb-tabset>

    <ng-template [ngIf]="ActiveTabAll || ActiveTabNew || ActiveTabInKitchen || ActiveTabReadyToCollect">
      <div class="row col-lg-12 col-md-12 col-xs-12 col-sm-12">

        <div class="col-lg-4 col-md-4 col-xs-4 col-sm-4 HideOnMobile" style="background-color: #EFF4F9;min-height: 30px">
          <div class="row">
            <input type="text" nbInput fullWidth status="basic" autocomplete="off" [(ngModel)]="SearchInList" fieldSize="medium" name="LiveOrdersearch" style=" border-radius: unset;background-color: #EFF4F9" placeholder="search...">

          </div>
          <div class="row" style="overflow-x: auto; max-height: 430px;">
            <div id="orders" style="background-color: white;width:100%">

              <div *ngFor="let data of lstOnlyOrderNo | filter:SearchInList;let i = index" class="Order-list" [ngClass]="{'selected': i === HighlightRow}">
                <div class="row form-group col-lg-12 col-md-12 col-xs-12 col-sm-12" style="cursor: pointer" (click)="getMasterDetailData(data.OrderID, 'All',i)">
                  <div class="form-group col-lg-12 col-md-12 col-xs-12 col-sm-12">

                    <div class="row">
                      <div class="col1">
                        <p class="numberorderNo mb-1" style="color: #292929"><strong>{{data.OrderInvoiceNo}}</strong></p>
                        <small style="display:block!important;font-weight:500;font-size:58%!important">{{data.Channel}}, {{data.OrderDate}}</small>
                        <small style="display:block!important;font-weight:500;font-size:58%!important">{{data.Status}}</small>
                        <!-- <small style="display:block!important;font-weight:500;font-size:58%!important">{{data.Channel}}, {{data.OrderDateReal | datediff}}</small> -->
                      </div>
                      <div class="col2" *ngIf="ActiveTabInKitchen">
                        <div class="labelPrep">Prep In</div>
                        <div class="blink_me numberCircle">
                          <span class="numberPrep">{{ data.PrepIn | mindiff: data.ModifyOn : data.PrepIn }}</span>
                          <span class="Prep">MIN</span>
                        </div>

                      </div>
                      <div class="col2" *ngIf="ActiveTabNew">
                        <div class="labelPrep"></div>
                        <div style="text-align:right">
                          <label class="label"></label>
                          <span class="dot"></span>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

            </div>
          </div>
          <div class="row">
            <br><br>
            <button nbButton fullWidth status="primary" (click)="RefreshOrders()" style="position:absolute;bottom:1px;width:95%">{{'Refresh' | translate}}</button>
          </div>
        </div>

        <div class="col-lg-8 col-md-8 col-xs-8 col-sm-8">
          <div class="HRVisible col-lg-12 col-md-12 col-xs-12 col-sm-12">
            <div class="row">
              <div class="orderCard__header px-3 col-lg-12 col-md-12 col-xs-12 col-sm-12" style="margin-top:-5px;">
                <span class="label clsB1"><strong>{{ObjOrderMaster.OrderInvoiceNo}}</strong></span>

                <span class="label clsB2">{{ObjOrderMaster.OrderDateReal | datediff}}</span>



                <span class="clsB3 badge badge-lg p-2 f-size-11 badge-accepted badge-acceptedNew" style="height: 30px;padding-top: 9px!important" [ngClass]="{'badge-acceptedNew':CustomBadgeText == 'New','badge-acceptedKitchen':CustomBadgeText == 'In Kitchen','badge-acceptedInRoute':CustomBadgeText == 'In Route/Ready'}" *ngIf="CustomBadgeText != '' && CustomBadgeText != undefined">{{CustomBadgeText }}</span>

                <span *ngIf="AllCount > 0" class="badgeprint badge-acceptedPrint printbtn" style="padding-top: 9px !important" (click)="getReceipt()" >{{'PrintOrder' | translate}} <i class="fa fa-print"></i></span>

                <iframe name="orderreceipt" [src]="ReceiptURL | safeUrl" width="0" height="0" frameborder="0"></iframe>
              </div>

            </div>
          </div>

          <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
            <div class="row">
              <div class="clsM1 form-group col-lg-7 col-md-7 col-xs-7 col-sm-7">
                <div class="">

                </div>
                <div class="">

                </div>
              </div>
              <div class="clsM2 form-group col-lg-5 col-md-5 col-xs-5 col-sm-5">
                <div class="algStyle">
                </div>
                <div>

                </div>
              </div>

              <!--<div class="clsM1 form-group col-lg-7 col-md-7 col-xs-7 col-sm-7" *ngIf="ActiveTabInKitchen || ActiveTabReadyToCollect">
    <div class="">
      <label class="label">Brand</label>
    </div>
    <div class="">
      <label class="label"><strong>{{ObjOrderMaster.Brand}}</strong></label>
    </div>
  </div>-->
              <div class="clsM2 form-group col-lg-5 col-md-5 col-xs-5 col-sm-5">
                <div class="algStyle">
                  <label class="label">{{'Outlets' | translate}}</label>

                </div>
                <div class="algStyle">
                  <label class="label"><strong>{{ObjOrderMaster.OutletName}}</strong></label>
                </div>
              </div>

              <div class="clsM1 form-group col-lg-7 col-md-7 col-xs-7 col-sm-7">
                <div class="">
                  <label class="label">{{'OrderTypes' | translate}}</label>
                </div>
                <div class="">
                  <label class="label"><strong>{{ObjOrderMaster.OrderType}}</strong></label>
                </div>
              </div>
              <div class="clsM2 form-group col-lg-5 col-md-5 col-xs-5 col-sm-5">
                <div class="algStyle">
                  <label class="label">{{'OrderDate' | translate}}</label>

                </div>
                <div class="algStyle">
                  <label class="label"><strong>{{ObjOrderMaster.OrderDate}}</strong></label>
                </div>
              </div>

              <div class="clsM1 form-group col-lg-7 col-md-7 col-xs-7 col-sm-7">
                <div class="algStyle">
                  <label class="label" *ngIf="ObjOrderMaster.OrderType == 'Pickup'">{{'Pickupat' | translate}}</label>
                  <label class="label" *ngIf="ObjOrderMaster.OrderType == 'Delivery'">{{'Deliveryat' | translate}}</label>
                </div>
                <div class="algStyle">
                  <label class="label"><strong>{{ObjOrderMaster.OrderDate}} {{ObjOrderMaster.DeliveryTime != '' ? ' - ' + ObjOrderMaster.DeliveryTime : ''}}</strong></label>
                </div>
              </div>

              <div class="clsM2 form-group col-lg-5 col-md-5 col-xs-5 col-sm-5">
                <div class="">
                  <label class="label">{{'Customer' | translate}}</label>
                </div>
                <div class="">
                  <label class="label"><strong>{{ObjOrderMaster.CustomerName}}</strong></label>
                </div>
              </div>

              <div class="clsM1 form-group col-lg-7 col-md-7 col-xs-7 col-sm-7">
                <div class="algStyle">
                  <label class="label">{{'PhoneNo' | translate}}</label>

                </div>
                <div>
                  <label class="label"><strong>{{ObjOrderMaster.PhoneNo}}</strong></label>
                </div>
              </div>


              <div class="clsM2 form-group col-lg-5 col-md-5 col-xs-5 col-sm-5">
                <div class="">
                  <label class="label">{{'Payment' | translate}}</label>
                </div>
                <div class="">
                  <label class="label"><strong>{{ObjOrderMaster.PayType}}</strong></label>
                </div>
              </div>
              <div class="clsM1 form-group col-lg-7 col-md-7 col-xs-7 col-sm-7">
                <div class="algStyle">
                  <label class="label">{{'Channel' | translate}}</label>

                </div>
                <div class="algStyle">
                  <label class="label"><strong>{{ObjOrderMaster.Channel}}</strong></label>
                </div>
              </div>


              <div class="clsM2 form-group col-lg-5 col-md-5 col-xs-5 col-sm-5" *ngIf="ObjOrderMaster.OrderType == 'Pickup'">
                <div class="">
                  <label class="label">{{'Pickupfrom' | translate}}</label>
                </div>
                <div class="">
                  <label class="label"><strong>{{ObjOrderMaster.OutletName}}</strong></label>
                </div>
              </div>

              <div class="clsM2 form-group col-lg-5 col-md-5 col-xs-5 col-sm-5" *ngIf="ObjOrderMaster.OrderType == 'Delivery'">
                <div class="">
                  <label class="label">{{'Deliveryto' | translate}}</label>
                </div>
                <div class="">
                  <label class="label" *ngIf="ObjOrderMaster.CountryName != 'pakistan'"><strong>{{ObjOrderMaster.Address}}</strong></label>
                  <label class="label" *ngIf="ObjOrderMaster.CountryName == 'pakistan'"><strong>{{ObjOrderMaster.AddressPK}}</strong></label>
                </div>
              </div>

              <!--<div class="clsM2 form-group col-lg-5 col-md-5 col-xs-5 col-sm-5">
    <div class="algStyle">
      <label class="label">Delivery to</label>
    </div>
    <div class="algStyle">
      <label class="label"><strong>{{ObjOrderMaster.Address}}</strong></label>
    </div>
  </div>-->


              <div class="form-group col-md-12">

                <div>
                  <div>
                    <label class="label">{{'Notes' | translate}}</label>
                  </div>
                  <div>
                    <label class="label"><strong>{{ObjOrderMaster.Remarks}}</strong></label>
                  </div>

                </div>
                <hr style="margin-bottom:0px" />
              </div>

            </div>
          </div>
          <div class="form-group col-lg-12 col-md-12 col-xs-12 col-sm-12">
            <div class="row">
              <div class="cls1 col-lg-1 col-md-1 col-xs-1 col-sm-1">
                <label class="label">{{'Qty' | translate}}</label>
              </div>
              <div class="cls2 col-lg-9 col-md-9 col-xs-9 col-sm-9">
                <label class="label">{{'Description' | translate}}</label>
              </div>
              <div class="cls3 col-lg-2 col-md-2 col-xs-2 col-sm-2">
                <label class="label">{{'Amount' | translate}}</label>
              </div>

            </div>
            <hr class="hrdivider" />
            <div class="row" *ngFor="let dod of ArrOrderDetail">
              <div class="cls1 col-lg-1 col-md-1 col-xs-1 col-sm-1">
                <label class="label" *ngIf="dod.AddOnID == 0">{{dod.Quantity}}</label>
              </div>
              <div class="cls2 col-lg-9 col-md-9 col-xs-9 col-sm-9" *ngIf="dod.AddOnID == 0">
                <label class="label">{{dod.ProductName}} {{dod.ProductSizeName == 'N/A' ? '' : '(' + dod.ProductSizeName +')'}}</label>
              </div>
              <div class="cls2 col-lg-9 col-md-9 col-xs-9 col-sm-9" *ngIf="dod.AddOnID != 0">
                <label class="label" style="color:#797979!important">{{dod.AddOnName}}</label>
              </div>

              <div class="cls3 col-lg-2 col-md-2 col-xs-2 col-sm-2">
                <label class="label" [ngStyle]="{'color':dod.AddOnID != 0 ? '#7d7b7b' : '#444' }" *ngIf="dod.OrignalAmount != undefined && dod.OrignalAmount != ''">{{ObjCurrency.CurrencyCode}}&nbsp;{{dod.OrignalAmount|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}} </label>
              </div>
            </div>
          </div>
          <div class="form-group">
            <hr class="hrdivider" />
          </div>
          <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">

            <div *ngIf="ArrVoucherData?.length > 0">
              <div class="row" *ngFor="let d of ArrVoucherData;">

                <div class="cls1 col-lg-6 col-md-6 col-xs-6 col-sm-6">
                </div>
                <div class="cls2 col-lg-4 col-md-4 col-xs-4 col-sm-4">
                  <label class="label">{{'Voucher' | translate}}: [ {{d.VoucherCode}} ]</label>
                </div>
                <div class="cls3 col-lg-2 col-md-2 col-xs-2 col-sm-2">
                  <label class="label">{{ObjCurrency.CurrencyCode}}&nbsp;(-{{d.VoucherAmount|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}})</label>
                </div>
              </div>
            </div>

            <div class="row" *ngIf="ObjOrderMaster.TotalDiscount > 0">
              <div class="cls1 col-lg-6 col-md-6 col-xs-6 col-sm-6">
              </div>
              <div class="cls2 col-lg-4 col-md-4 col-xs-4 col-sm-4">
                <label class="label">{{'Discount' | translate}}:</label>
              </div>
              <div class="cls3 col-lg-2 col-md-2 col-xs-2 col-sm-2">
                <label class="label">{{ObjCurrency.CurrencyCode}}&nbsp;(-{{ObjOrderMaster.TotalDiscount|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}})</label>
              </div>

            </div>

            <div class="row" *ngIf="ObjOrderMaster.LoyaltyAmount > 0">
              <div class="cls1 col-lg-6 col-md-6 col-xs-6 col-sm-6">
              </div>
              <div class="cls2 col-lg-4 col-md-4 col-xs-4 col-sm-4">
                <label class="label">{{'Discount' | translate}} ({{'RewardPoints' | translate}}):</label>
              </div>
              <div class="cls3 col-lg-2 col-md-2 col-xs-2 col-sm-2">
                <label class="label">{{ObjCurrency.CurrencyCode}}&nbsp;(-{{ObjOrderMaster.LoyaltyAmount|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}})</label>
              </div>

            </div>

            <div class="row">
              <div class="cls1 col-lg-6 col-md-6 col-xs-6 col-sm-6">
              </div>
              <div class="cls2 col-lg-4 col-md-4 col-xs-4 col-sm-4">
                <label class="label">{{'NetTotal' | translate}}:</label>
              </div>
              <div class="cls3 col-lg-2 col-md-2 col-xs-2 col-sm-2">
                <label class="label">{{ObjCurrency.CurrencyCode}}&nbsp;{{ObjOrderMaster.TotalAmount|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</label>
              </div>

            </div>



            <div class="row">
              <div class="cls1 col-lg-6 col-md-6 col-xs-6 col-sm-6">
              </div>
              <div class="cls2 col-lg-4 col-md-4 col-xs-4 col-sm-4">
                <label class="label">{{'Tax' | translate}}:</label>
              </div>
              <div class="cls3 col-lg-2 col-md-2 col-xs-2 col-sm-2">
                <label class="label">{{ObjCurrency.CurrencyCode}}&nbsp;{{ObjOrderMaster.TotalTaxAmount|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</label>
              </div>
            </div>
            <div class="row" *ngIf="ObjOrderMaster.OrderType == 'Delivery'">
              <div class="cls1 col-lg-6 col-md-6 col-xs-6 col-sm-6">
              </div>
              <div class="cls2 col-lg-4 col-md-4 col-xs-4 col-sm-4">
                <label class="label">{{'DeliveryFee' | translate}}:</label>
              </div>
              <div class="cls3 col-lg-2 col-md-2 col-xs-2 col-sm-2">
                <label class="label">{{ObjCurrency.CurrencyCode}}&nbsp;{{ObjOrderMaster.DeliveryFee|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</label>
              </div>
            </div>




            <div class="row">
              <div class="cls1 col-lg-6 col-md-6 col-xs-6 col-sm-6">
              </div>
              <div class="cls2 col-lg-4 col-md-4 col-xs-4 col-sm-4">
                <label class="label"><strong>{{'lblFinalTotal' | translate}}:</strong></label>
              </div>
              <div class="cls3 col-lg-2 col-md-2 col-xs-2 col-sm-2">
                <label class="label"><strong>{{ObjCurrency.CurrencyCode}}&nbsp;{{ObjOrderMaster.NetAmount|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</strong></label>
              </div>

            </div>

          </div>



          <div *ngIf="ActiveTabNew" class="form-group col-lg-12 col-md-12 col-xs-12 col-sm-12" style="margin-bottom: auto;">

            <div class="row">
              <div class="form-group col-lg-6 col-md-6 col-xs-6 col-sm-6">
                <button nbButton fullWidth status="danger" (click)="RejectOrder('REJECTED')">{{'Reject' | translate}}</button>
              </div>

              <div class="col-lg-6 col-md-6 col-xs-6 col-sm-6">
                <button nbButton fullWidth status="success" (click)="openPopup()">{{'Accept' | translate}}</button>
              </div>

            </div>
          </div>


          <div *ngIf="ActiveTabInKitchen" class="form-group form-group col-lg-12 col-md-12 col-xs-12 col-sm-12" style="margin-bottom: -5px;">

            <div class="row">
              <!--<div class="form-group col-lg-2 col-md-2 col-xs-2 col-sm-2">-->
              <!--<button nbButton fullWidth status="danger">Close</button>-->
              <!--</div>-->
              <div class="form-group col-lg-6 col-md-6 col-xs-6 col-sm-6">
                <button nbButton fullWidth status="danger" (click)="RejectOrder('Cancel')">{{'Cancel' | translate}}</button>
              </div>
              <div class="col-lg-6 col-md-6 col-xs-6 col-sm-6">
                <button nbButton fullWidth status="success" (click)="ReadyToCollect()">{{OrderType}}</button>
              </div>

            </div>
          </div>

          <div *ngIf="ActiveTabReadyToCollect" class="form-group col-md-12" style="margin-bottom: auto;">

            <div class="row">
              <div class="form-group col-lg-6 col-md-6 col-xs-6 col-sm-6">
                <button nbButton fullWidth status="danger" (click)="RejectOrder('REJECTED')">{{'Reject' | translate}}</button>
              </div>
              <div class="col-md-6">
                <button nbButton fullWidth status="success" (click)="CompleteOrder()">{{'Complete' | translate}}</button>
              </div>

            </div>
          </div>

        </div>
      </div>
    </ng-template>



  </nb-card-body>
  <nb-card-footer>
    <ngx-footer></ngx-footer>
  </nb-card-footer>



</nb-card>

