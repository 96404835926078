import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, CanActivate, Params, RouterStateSnapshot } from '@angular/router';
import { DataserviseService } from './dataservice';

@Injectable()

export class AuthGuard implements CanActivate {
    routeUrl: string = "";
    user: any;
    MenusArray = [];
    found: boolean = false;
    constructor(private router: Router, private route: ActivatedRoute, private _userservice: DataserviseService) {
        this.routeUrl = this.router.url;
        // console.log(this.routeUrl)
        // console.log(location.href); 
    }

    canActivate() {
        debugger
        // console.log(this.route.snapshot.data['title']);
        // this._userservice.checkFormRights('ItemsModifiers','Allow');

        // this.route.queryParams.subscribe(params => {
        //     this.routeUrl = params['name'];
        //   });
        //   console.log(this.routeUrl);

        // console.log(this.routeUrl);
        this.MenusArray = JSON.parse(sessionStorage.getItem('FormRights'));

        for (var i = 0; i < this.MenusArray.length; i++) {
            if (this.MenusArray[i].Path == this.routeUrl) {
                this.router.navigate([this.routeUrl]);
                return true
            }
            else {
                this.router.navigate(['/login']);
                return false;
            }
        }
        
        // if (this.MenusArray.indexOf(this.routeUrl)) {debugger
        //     this.router.navigate(['/login']);
        //     return false
        // }
        // else{
        //     this.router.navigate([this.routeUrl]);
        //     return true;
        // }

    }

}





// console.log('Host:' + window.location.host);
// console.log('Hostname:' + window.location.hostname);
// console.log('Origin:' + window.location.origin);
// console.log('Port:' + window.location.port);
// console.log('Search String:' + window.location.search);
// this.route.queryParams.subscribe(params => {debugger
//     this.urlname = params['name'];
//   });
//           console.log(this.router.url);
//           let urlarr = this.router.parseUrl(this.router.url).queryParams['key'] || '';
//           console.log(urlarr);
//           this.urlname = this.route.snapshot.params;
//           console.log(this.router.parseUrl(this.router.url));
//           const snapshot: RouterStateSnapshot = this.router.routerState.snapshot;
//           console.log(snapshot); 
