<!-- wrong -->
<div id="invoice-POS" class="invoice-POS" >
  <div>
    <div class="logo" style="display: inline;">
      <img [src]="this.ObjCompanyInfo.LogoImageName" style="width: 15vh;border-radius: 5px;" />
    </div>
    <div *ngIf="ObjOrderMaster.OutletName != 'N/A'" style="float:right;font-weight:700;max-width: 40%;">
      <div style="font-size: 20px;">{{ObjOrderMaster.OutletName}}</div>
      <div style="font-size: 22px;">{{ObjOrderMaster.ArabicOutletName}}</div>
      <div>{{ObjOrderMaster.outletAddress}} {{ObjOrderMaster.AreaName}}</div>
      <div style="font-size: 14px;">{{ObjOrderMaster.ArabicArea}} {{ObjOrderMaster.ArabicAddress}}</div>
      <div *ngIf="ObjCompanyInfo.VATNo != 'N/A'">الرقم الضريبي / VAT No. : {{ObjCompanyInfo.VATNo}}</div>
      <div *ngIf="ObjOrderMaster.OutletPhone != 'N/A'">Tel : {{ObjOrderMaster.OutletPhone}}</div>
    </div>

  </div>

  <label style="width: 100%;font-size: 20px;font-weight: 600;text-align: center;padding: 8px;margin-bottom: 0px;">{{InvoiceNameArabic}} / {{InvoiceNameEnglish}}</label>
  <div class="row" style="margin-bottom: 10px;">
    <div style="border: 1px solid black;width: 48%;height: 102px;margin-right: 12px;margin-left: 12px;">
      <div style="padding-left: 5px;display: inline-block;">
        <div style="font-weight:600;"> تفاصيل الفاتورة / {{InvoiceType}} Detail</div>
        <div style="font-size: 14px"><span>رقم الفاتورة / {{InvoiceType}} No. </span> {{ObjOrderMaster.POInvoiceNo}}</div>
        <div style="font-size: 14px"><span>تاريخ الفاتوره / {{InvoiceType}} Date: </span> {{ObjOrderMaster.OrderDate}}</div>
        <div style="font-size: 14px" *ngIf="ObjOrderMaster.SupplierInvNo"><span>رقم فاتورة المورد / Supplier Invoice No. </span> {{ObjOrderMaster.SupplierInvNo}}</div>
      </div>
      <!-- <div style="float: right;">
        <ngx-qrcode [elementType]="elementType" [value]="QRUrl" [width]=100 cssClass="aclass" errorCorrectionLevel="L"> </ngx-qrcode>
      </div> -->
    </div>

    <div style="border: 1px solid black;width: 48%;" *ngIf="ObjOrderMaster.SupplierName">
      <div style="padding-left: 5px;min-height: 100px;">
        <div style="font-weight:600;">Supplier Detail:</div>
        <div style="font-size: 14px"><span>Supplier: {{ObjOrderMaster.SupplierName}} </span> <span style="float: right;padding-right: 5px;">Contact Person: {{ObjOrderMaster.ContactPerson}}</span></div>
        <div style="font-size: 14px"><span>Tel: {{ObjOrderMaster.Phone}} </span> <span style="float: right;padding-right: 5px;">الرقم الضريبي / VAT No.: {{ObjOrderMaster.VatRegistrationNo}}</span> </div>
        <div style="font-size: 14px"><span>Email: {{ObjOrderMaster.Email}} </span> <span style="float: right;padding-right: 5px;"> Mobile: {{ObjOrderMaster.MobilePhone}}</span></div>
        <div style="font-size: 14px">Address: {{ObjOrderMaster.Address1}} </div>
      </div>
    </div>

  </div>
  <div id="table">
    <ng-container *ngIf="InvoiceType != 'PR'">
      <table style="border: 1px solid black;width: 100%;min-height: 850px !important;">
        <thead>
          <tr>
            <th style="border-right: 1px solid;border-bottom: 1px solid;text-align: center;">Sr. No<br /> رقم</th>
            <th style="border-right: 1px solid;border-bottom: 1px solid;text-align: center;width: 50px !important">ITEM DESCRIPTION<br /> وصف السلعة</th>
            <th *ngIf="InvoiceType == 'RSR'" style="border-right: 1px solid;border-bottom: 1px solid;text-align: center;width: 50px !important">Required Date<br /> وصف السلعة</th>
            <th *ngIf="InvoiceType != 'RSR'" style="border-right: 1px solid;border-bottom: 1px solid;text-align: center;">Unit<br />وحدة </th>
            <th style="border-right: 1px solid;border-bottom: 1px solid;text-align: center;">QTY<br />كمية </th>
            <th *ngIf="InvoiceType != 'SRN' &&  InvoiceType != 'STN' &&  InvoiceType != 'OS' && InvoiceType != 'RSR'" style="border-right: 1px solid;border-bottom: 1px solid;text-align: center;padding-left: 5px;padding-right: 5px;">Rate<br />سعر</th>
            <th *ngIf="InvoiceType != 'SRN' &&  InvoiceType != 'STN' &&  InvoiceType != 'OS' && InvoiceType != 'RSR'" style="border-right: 1px solid;border-bottom: 1px solid;text-align: center;">Discount<br />تخفيض </th>
            <th *ngIf="InvoiceType != 'SRN' &&  InvoiceType != 'STN' &&  InvoiceType != 'OS' && InvoiceType != 'RSR'" style="border-right: 1px solid;border-bottom: 1px solid;text-align: center;">Tax<br />ضريبة </th>
            <th *ngIf="InvoiceType != 'SRN' &&  InvoiceType != 'STN' &&  InvoiceType != 'OS' && InvoiceType != 'RSR'" style="border-bottom:1px solid ;text-align: center;padding-left: 5px;padding-right: 5px;">AMOUNT<br />المبلغ</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of ArrOrderDetail; let i = index" style="vertical-align: text-top;">
            <td style="border-right: 1px solid;text-align: center;width: 5% !important;height: 10px;">{{row.SrNo}}</td>
            <td style="border-right: 1px solid;padding-left: 5px;;width: 40% !important;height: 10px;">{{row.itemName}}</td>
            <td *ngIf="InvoiceType == 'RSR'" style="border-right: 1px solid;text-align: center;width:10%;height: 10px;">{{row.RequiredDate}}</td>
            <td *ngIf="InvoiceType != 'RSR'" style="border-right: 1px solid;padding-left: 5px;;width: 10% !important;height: 10px;text-align: center;">{{row.Unit}}</td>
            <td style="border-right: 1px solid;text-align: center;width:5%;height: 10px;">{{row.Quantity}}</td>
            <td *ngIf="InvoiceType != 'SRN' &&  InvoiceType != 'STN' &&  InvoiceType != 'OS' && InvoiceType != 'RSR'" style="border-right: 1px solid;text-align: center;width: 10%;height: 10px;">{{row.Price}}</td>
            <td *ngIf="InvoiceType != 'SRN' &&  InvoiceType != 'STN' &&  InvoiceType != 'OS' && InvoiceType != 'RSR'" style="border-right: 1px solid;text-align: center;width: 10%;height: 10px;">{{row.LineDiscount}}</td>
            <td *ngIf="InvoiceType != 'SRN' &&  InvoiceType != 'STN' &&  InvoiceType != 'OS' && InvoiceType != 'RSR'" style="border-right: 1px solid;text-align: center;width: 10%;height: 10px;">{{row.TaxAmount}}</td>
            <td *ngIf="InvoiceType != 'SRN' &&  InvoiceType != 'STN' &&  InvoiceType != 'OS' && InvoiceType != 'RSR'" style="border-right: 1px solid;text-align: center;width:10%;height: 10px;">{{row.NetAmount|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</td>
          </tr>
          <tr>
            <td style="border-right: 1px solid;width:5%;"></td>
            <td style="border-right: 1px solid;width:40%;"></td>
            <td *ngIf="InvoiceType == 'RSR'" style="border-right: 1px solid;width:10%;"></td>
            <td *ngIf="InvoiceType != 'RSR'" style="border-right: 1px solid;width:10%;"></td>
            <td style="border-right: 1px solid;width:5%;"></td>
            <td *ngIf="InvoiceType != 'SRN' &&  InvoiceType != 'STN' &&  InvoiceType != 'OS' && InvoiceType != 'RSR'" style="border-right: 1px solid;width:10%;"></td>
            <td *ngIf="InvoiceType != 'SRN' &&  InvoiceType != 'STN' &&  InvoiceType != 'OS' && InvoiceType != 'RSR'" style="border-right: 1px solid;width:10% ;"></td>
            <td *ngIf="InvoiceType != 'SRN' &&  InvoiceType != 'STN' &&  InvoiceType != 'OS' && InvoiceType != 'RSR'" style="border-right: 1px solid;width:10% ;"></td>
            <td *ngIf="InvoiceType != 'SRN' &&  InvoiceType != 'STN' &&  InvoiceType != 'OS' && InvoiceType != 'RSR'" style="border-right: 1px solid;width:10% ;"></td>  
          </tr>
        </tbody>
      </table>
    </ng-container>

    <ng-container *ngIf="InvoiceType == 'PR'">
      <table style="border: 1px solid black;width: 100%;min-height: 850px !important;">
        <thead>
          <tr>
            <th style="border-right: 1px solid;border-bottom: 1px solid;text-align: center;">Sr. No<br /> رقم</th>
            <th style="border-right: 1px solid;border-bottom: 1px solid;text-align: center;width: 50px !important">ITEM DESCRIPTION<br /> وصف السلعة</th>
            <th style="border-right: 1px solid;border-bottom: 1px solid;text-align: center;width:15px !important">Required Date<br /> التاريخ المطلوب</th>
            <th style="border-right: 1px solid;border-bottom: 1px solid;text-align: center;padding-left: 5px;padding-right: 5px;">Rate<br />سعر</th>
            <th style="border-right: 1px solid;border-bottom: 1px solid;text-align: center;">QTY<br />كمية </th>

            <th style="border-bottom:1px solid ;text-align: center;padding-left: 5px;padding-right: 5px;">AMOUNT<br />المبلغ</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of ArrOrderDetail; let i = index" style="vertical-align: text-top;">
            <td style="border-right: 1px solid;text-align: center;width: 5% !important;height: 10px;">{{row.SrNo}}</td>
            <td style="border-right: 1px solid;padding-left: 5px;width: 40% !important;height: 10px;">{{row.itemName}}</td>
            <td style="border-right: 1px solid;text-align: center; width: 15% !important; height: 10px;">{{row.RequiredDate}}</td>
            <td style="border-right: 1px solid;text-align: center;width: 10%;height: 10px;">{{row.Price|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</td>
            <td style="border-right: 1px solid;text-align: center;width:5%;height: 10px;">{{row.Quantity|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</td>

            <td style="border-right: 1px solid;text-align: center;width:10%;height: 10px;">{{row.NetAmount|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</td>
          </tr>
          <tr>
            <td style="border-right: 1px solid;width:5%;"></td>
            <td style="border-right: 1px solid;width:40%;"></td>
            <td style="border-right: 1px solid;width:15%;"></td>
            <td style="border-right: 1px solid;width:10%;"></td>
            <td style="border-right: 1px solid;width:5%;"></td>
            <td style="border-right: 1px solid;width:10%;"></td>
          </tr>
        </tbody>
      </table>
    </ng-container>



    <ng-container *ngIf="InvoiceType != 'PR'">
      <table *ngIf="InvoiceType != 'SRN' &&  InvoiceType != 'STN' &&  InvoiceType != 'OS'" style="border: 1px solid black;width: 100%;border-top: none;">
        <tr *ngIf="InvoiceType != 'RSR'" style="border-bottom: 1px solid;">
          <td style="text-align:right;font-weight: 600">Sub Total / <span>المجموع النهائي</span></td>
          <td style="text-align:right !important;font-weight: 600">{{CurrencyCode}}</td>
          <td style="text-align:right !important;font-weight: 600">{{ObjOrderMaster.TotalAmount|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</td>
        </tr>

        <tr *ngIf="InvoiceType != 'RSR'" style="border-bottom: 1px solid;">
          <td style="text-align:right;font-weight: 600">Net Amount / <span>المجموع يشمل ضريبة القيمة المضافة</span></td>
          <td style="text-align:right !important;font-weight: 600">{{CurrencyCode}}</td>
          <td style="text-align:right !important;font-weight: 600">{{ObjOrderMaster.NetAmount|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</td>
        </tr>
        <tr *ngIf="InvoiceType == 'RSR'" style="border-bottom: 1px solid;">
          <td style="text-align:right;font-weight: 600">Total Quantity / <span>الكمية الإجمالية</span></td>
          <td style="text-align:right !important;font-weight: 600">{{ObjOrderMaster.TotalQty}}</td>
        </tr>
      </table>
    </ng-container>
    <ng-container *ngIf="InvoiceType == 'PR'">
      <table style="border: 1px solid black;width: 100%;border-top: none;">
        <tr style="border-bottom: 1px solid;">
          <td style="text-align:right;font-weight: 600">Total Quantity / <span>الكمية الإجمالية</span></td>
          <td style="text-align:right !important;font-weight: 600"></td>
          <td style="text-align:right !important;font-weight: 600">{{ObjOrderMaster.TotalQty|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</td>
        </tr>

        <tr style="border-bottom: 1px solid;">
          <td style="text-align:right;font-weight: 600">Total Amount / <span>المجموع يشمل ضريبة القيمة المضافة</span></td>
          <td style="text-align:right !important;font-weight: 600">{{CurrencyCode}}</td>
          <td style="text-align:right !important;font-weight: 600">{{ObjOrderMaster.TotalAmount|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</td>
        </tr>
      </table>
    </ng-container>
  </div>

  <br />
  <br />
  <br />
  <br />
  <div style="display:flex;">
  <span style="width:50% ;"><p style="border-bottom:1px solid;width:80%;padding-bottom:60px;text-align: center;font-weight: 600;font-size: 14px;">Prepared By / أُعدت بواسطة</p></span>
  <span style="width:50% ;text-align: -webkit-center"><p style="border-bottom:1px solid;width:80%;padding-bottom:60px;text-align: center;font-weight: 600;font-size: 14px;">Approved By / تمت الموافقة عليه من قبل</p></span>
  <span style="width:50% ;text-align: -webkit-right;"><p style="border-bottom:1px solid;width:80%;padding-bottom:60px;text-align: center;font-weight: 600;font-size: 14px;">Received By / استلمت من قبل</p></span>
</div>
  <!-- <div class="signature-row footer">
    <label>Prepared By / أُعدت بواسطة</label>
    <label>Approved By / تمت الموافقة عليه من قبل</label>
    <label>Received By / استلمت من قبل</label>
  </div> -->


  <!-- <div id="bot">
    <div id="legalcopy">
      <button class="label clsB1 doNotPrint" style="border:0" (click)="printComponent()">Print order</button>
    </div>
  </div> -->




</div>
  
