

<nb-card style="border: none;">
    <nb-card-header style="padding: 0px;padding-bottom: 5px;">
      <div class="parent1"  style="display: block;padding-top: 0px; border-bottom: 1px solid #edf1f7 !important;">
        <div class="child1" style="max-width: none;">{{Voucher}}<img src="./assets/images/lookupclose.png" (click)="btnclose()" class="cross-icon" style="float: right;cursor: pointer;margin-top: -5px;" /></div>
      </div>
    </nb-card-header>
    <nb-card-body>
      <div class="form-group" style="padding-top: 15px;">
        <label for="bCompanyId" class="label">{{DiscountVoucher}}</label>
        <nb-select fullWidth (selectedChange)="changeVoucher($event)">
          <!-- [(selected)]="DiscVoucherID" -->
          <nb-option *ngFor="let _data of lstVoucher" [value]="_data">{{_data.VoucherDesc}}</nb-option>
        </nb-select>
      </div>

      <!--<div *ngIf="lstVoucher?.length > 0 && !ObjdiscountVoucher.IsPercent " class="form-group">
        <label class="label">{{'Amount'}}</label>
        <input type="text" nbInput fullWidth width="100%" [(ngModel)]="ObjdiscountVoucher.Amount" (ngModelChange)="onValueChange($event)" readonly>
        <p class="caption status-danger" *ngIf="showlblExceedAmount">{{'Discount Amount Exceed from SubTotal Amount'}}</p>
      </div>-->

    <div *ngIf="lstVoucher?.length > 0 && !ObjdiscountVoucher.IsPercent " class="form-group">
      <label class="label">{{Amount}}</label>
      <div>
        <input nbInput fullWidth [(ngModel)]="ObjdiscountVoucher.Amount" readonly>
        <i class="far fa-keyboard" (click)="CalcPopup()" style="cursor: pointer;cursor: pointer;padding-right: 10px;position: relative;float: right;margin-top: -30px;font-size: 22px;"></i>
      </div>
      <p class="caption status-danger" *ngIf="showlblExceedAmount">{{lblExceedAmount}}</p>
    </div>

      <!-- <p *ngIf = "shiftexists" style="color: red;">Please Closed all shift first.</p> -->

      <div class="form-group">
        <button [disabled]="!ObjvoucherData.DiscVoucherID" nbButton status="success" style="width:100px;float: right;" (click)="applyVoucher()">{{APPLY}}</button>

      </div>
    </nb-card-body>
  
    </nb-card>
