import { Component, OnInit, Inject } from '@angular/core';
import { DataserviseService } from '../../../../shared/dataservice';
import { PopupService } from '../../../../shared/popup';
import { UtilitiesService } from '../../../../shared/utilities.service';
import { SpinnerService } from '../../../../shared/spinner';
import { OrderListPopupComponent } from '../../../../pages/ordermanagements/orderlistpopup/orderlistpopup.component';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslatePipe } from '../../../../shared/pipe/translate.pipe';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'ngx-retailsalereturnitempopup',
  templateUrl: './retailsalereturnitempopup.component.html',
  styleUrls: ['./retailsalereturnitempopup.component.scss']
})


export class RetailSaleReturnItemComponent implements OnInit {

  OrderNo: any = this.translate.transform('OrderNo');
  PhoneNo: any = this.translate.transform('PhoneNo');
  FromDate: any = this.translate.transform('FromDate');
  ToDate1: any = this.translate.transform('ToDate');
  Search: any = this.translate.transform('Search');
  PaidOrderslist: any = this.translate.transform('PaidOrderslist');
  PaidOrders: any = this.translate.transform('PaidOrders');
  OrderDate: any = this.translate.transform('OrderDate');
  Items: any = this.translate.transform('Items');

  Description: any = this.translate.transform('Description');
  ItemDescription: any = this.translate.transform('ItemDescription');
  RefundPaymentType: any = this.translate.transform('RefundPaymentType');
  ReturnQty: any = this.translate.transform('ReturnQty');
  Price: any = this.translate.transform('Price');
  Amount: any = this.translate.transform('Amount');
  Action: any = this.translate.transform('Action');
  Qty: any = this.translate.transform('Qty');
  Select: any = this.translate.transform('Select');

  showPerPage = 5;
  settings = {
    actions: {
      columnTitle: '',
      add: false,
      edit: false,
      delete: false,
    },
    mode: 'external',
    pager: {
      display: true,
      perPage: this.showPerPage,
    },

    columns: {

      ProductName: {
        title: this.translate.transform('ProductName'),
      },
      Quantity: {
        title: this.translate.transform('Quantity'),
      },
   
      Price: {
        title: this.translate.transform('Price'),
      },
      TotalAmount: {
        title: this.translate.transform('TotalAmount'),
      },

      //TotalAmount: {
      //  title: this.translate.transform('TotalAmount'),
      //  type: 'html',
      //  valuePrepareFunction: (value, row, cell) => {
      //    return this._DecimalPipe.transform(cell.row.data.NetAmount, '1.' + this.ObjCountry.NumOfDecimal + '-' + this.ObjCountry.NumOfDecimal);
      //  },
      //},

    },
    attr: {
      class: 'table table-bordered handicursor',
    },
    hideSubHeader: false,
  };

  CompanyId: any;
  CreatedBy: any = '';
  CreatedOn: any = '';
  lstDetail: any = [];
  culture: any;
  NumOfDecimal: any;
  CurrencyCode: any;

  constructor(private translate: TranslatePipe, public dialogRef: MatDialogRef<RetailSaleReturnItemComponent>, @Inject(MAT_DIALOG_DATA) public parentData: any, public dialog: MatDialog, private _spinnerService: SpinnerService, private Utilities: UtilitiesService, private dataservice: DataserviseService, private popup: PopupService, private _DecimalPipe: DecimalPipe) { }

  ngOnInit(): void {
    debugger
    this.CompanyId = this.Utilities.getSubscriptionID();
    this.CreatedBy = this.Utilities.getUserId();
    this.CreatedOn = this.Utilities.GetCurrentDateTime();
    this.culture = sessionStorage.getItem('LanguageCulture');
    this.NumOfDecimal = this.parentData.NumOfDecimal;
    this.CurrencyCode = this.parentData.CurrencyCode;

    debugger
    this.getOrder(this.parentData.data);
  }


  getOrder(Id): void {

    debugger
  
    this.dataservice.get('retailpos/GetOrderById/' + Id)
      .subscribe(Rdata => {
        debugger
        if (this.isArrayHasValue(Rdata[0]['OrderDetail'])) {
          this.lstDetail = Rdata[0]['OrderDetail'];
        }
      }, error => alert('error'));

  }

  onUserRowSelect(event): void {
    debugger
    let lst: any = [];
 
    lst.push(event);
    this.dialogRef.close(lst);

  }

  isArrayHasValue(obj: any[]): boolean {
    let isArrayWithLength = Boolean(Array.isArray(obj) && obj.length);
    return isArrayWithLength;
  }
}


