import { Component, OnInit } from '@angular/core';
import { DataserviseService } from 'app/shared/dataservice';
import { PopupService } from 'app/shared/popup';
import { UtilitiesService } from 'app/shared/utilities.service';
import { Inject } from '@angular/core';
import { MatDialogRef,MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'ngx-loyality-popup',
  templateUrl: './loyality-popup.component.html',
  styleUrls: ['./loyality-popup.component.scss']
})
export class LoyalityPopupComponent implements OnInit {
  isLoyaltyProgramAvailable: boolean = false;
  LoyaltyRemainingPoints: any = 0;
  LoyaltyRemainingAmount: any = 0.0;
  loyaltypoints: any = 0.0;
  isLoyaltySelected: boolean = false;
 
  AvailingAmount: any = 0.0;
CurrencyCode:any;
NumOfDecimal:any;
OrderTotal: any;
ObjTaxInfo = new TaxInfo();
ObjLoyalty: Loyalty = new Loyalty();
LoyaltyAmountConsume:any;
IsLoyaltyOnAllItems:boolean = false;

constructor( private Utilities: UtilitiesService, private dataservice: DataserviseService, private popup: PopupService, public dialog: MatDialog,public dialogRef: MatDialogRef<LoyalityPopupComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }


  ngOnInit(): void {debugger
    this.CurrencyCode = this.data.CurrencyCode;
    this.NumOfDecimal = this.data.NumOfDecimal;
    this.CustomerID = this.data.CustomerID;
    this.loyaltypoints = this.data.loyaltypoints;
    this.AvailingAmount = this.data.AvailingAmount;
    this.OrderTotal = this.data.Total;
    // this.CurrentDate = this.Utilities.GetCurrentDateTime();
    if(this.data.LoyaltyPointsInfo){
    this.LoyaltyAmountConsume = this.data.LoyaltyPointsInfo[0].Limitperorder;
    this.IsLoyaltyOnAllItems = this.data.LoyaltyPointsInfo[0].IsLoyaltyOnAllItems;
    }
    if(this.CustomerID)
    this.getRemainigPoints();
  }

  chkloyalty(val): void {

    debugger
    if (val.currentTarget.checked) {

      if (this.LoyaltyRemainingAmount > this.OrderTotal) {
        alert("you can't consume loyalty at this time!");
        val.currentTarget.checked = false;
        return;
      }
      val.currentTarget.checked = true;
      this.isLoyaltySelected = true;
      // this.setloyalty(0, 0);
      // this.getTaxinformation(true);

    } else {
      val.currentTarget.checked = false;
      this.isLoyaltySelected = false;
      // this.setloyalty(0, 0);
      // this.getTaxinformation(false);
    }

  }

  
  // setloyalty(Points, Quantity): void {
  //   debugger
  //   if (!this.isLoyaltyProgramAvailable)
  //     return;

  //   this.loyaltypoints = 0;
  //   this.AvailingAmount = 0;
  //   let modifireamount = 0;
  //   let Productsum: number = 0;

  //   let modifirepoints = 0;
  //   let productPoints: number = 0;
  //   let modifireavailingmount = 0;
  //   let productavailingmount: number = 0;

  //   if (this.ObjLoyalty.IsLoyaltyOnAllItems == true) {
  //     let price: any = 0.0;
  //     debugger
  //     if (this.lstBasketProduct.length > 0) {
  //       Productsum = this.lstBasketProduct.map(a => a.DiscountPrice > 0 ? a.DiscountPrice * a.Quantity : a.Price * a.Quantity).reduce(function (a, b) {
  //         return a + b;
  //       });


  //       if (this.lstModifier.length > 0) {
  //         for (var i = 0; i < this.lstBasketProduct.length; i++) {
  //           for (var j = 0; j < this.lstModifier.length; j++) {
  //             debugger
  //             if (this.lstModifier[j].Price != null && this.lstModifier[j].Price != undefined && this.lstModifier[j].IsSelected == true) {
  //               debugger
  //               let Quant = this.lstBasketProduct.find(t => t.rowid == this.lstModifier[j].rowid).Quantity;
  //               modifireamount += this.lstModifier[j].Price * Quant;
  //             }
  //           }
  //         }
  //       }
  //     }
  //     price = Productsum + modifireamount - this.LoyaltyAmountC;
  //     let availingamount = this.dataservice.getloyaltyAvailingAmount(parseFloat(price), this.ObjLoyalty.Perordervalue);
  //     this.AvailingAmount = availingamount; 
  //     this.loyaltypoints = this.dataservice.getloyaltypoints(parseFloat(availingamount), this.ObjLoyalty.ValuePoint);
  //   }

  //   else {
  //     debugger
  //     if (this.lstBasketProduct.length > 0) {

  //       for (var i = 0; i < this.lstBasketProduct.length; i++) {
  //         if (this.lstBasketProduct[i].Points != null && this.lstBasketProduct[i].Points != undefined) {
  //           let Quant = this.lstBasketProduct[i].Quantity;
  //           productPoints +=  this.lstBasketProduct[i].Points * Quant;
  //           productavailingmount += this.lstBasketProduct[i].AvailingAmount * Quant;
  //         }
  //       }

  //       if (this.lstModifier.length > 0) {
  //         for (var i = 0; i < this.lstBasketProduct.length; i++) {
  //           for (var j = 0; j < this.lstModifier.length; j++) {
  //             debugger
  //             if (this.lstModifier[i].Points != null && this.lstModifier[i].Points != undefined && this.lstModifier[i].IsSelected == true) {
  //               debugger
  //               let Quant = this.lstBasketProduct.find(t => t.rowid == this.lstModifier[i].rowid).Quantity;
  //               modifirepoints = this.lstModifier[i].Points * Quant;
  //               modifireavailingmount = this.lstModifier[i].AvailingAmount * Quant;
  //             }
  //           }
  //         }
  //       }
  //     }

  //     this.loyaltypoints = productPoints + modifirepoints;
  //     this.AvailingAmount = productavailingmount + modifireavailingmount;
  //   }

  // }

  // AreaId: any = '0';
  // getTaxinformation(isloyaltyCheck): void {
  //   this.ObjTaxInfo = new TaxInfo();

  //   let branchid = this.localStorage.geten('SelectedBranch' + this.CompanyIdL);
  //   if (this.localStorage.geten('AreaId' + this.CompanyIdL) != null) {
  //     this.AreaId = this.localStorage.geten('AreaId' + this.CompanyIdL);
  //   }
  //   debugger
  //   let customerid = this.ObjRegister.CustomerID == undefined || this.ObjRegister.CustomerID == null ? -1 : this.ObjRegister.CustomerID;

  //   this.dataservice.get('OrderOnline/GetProductDetailDataforBasket/' + branchid + '/' + this.ObjCompany.CompanyID + '/' + this.OrderType + '/' + this.AreaId + '/' + this.dataservice.GetCurrentDateNoTime() + '/' + this.ProductIds + '/' + customerid)
  //     .subscribe(data => {
  //       if (this.isArrayHasValue(data[0]['SelectedProducts'])) {
  //         this.lstBasketProductUpdated = data[0]['SelectedProducts'];

  //         for (var i = 0; i < this.lstBasketProduct.length; i++) {

  //           for (var j = 0; j < this.lstBasketProductUpdated.length; j++) {

  //             if (this.lstBasketProductUpdated[j].ProductID == this.lstBasketProduct[i].ProductID && this.lstBasketProductUpdated[j].ProductDetailID == this.lstBasketProduct[i].ProductDetailID) {
  //               this.lstBasketProduct[i].Price = this.lstBasketProductUpdated[j].Price;
  //               this.lstBasketProduct[i].DiscountPrice = this.lstBasketProductUpdated[j].DiscountPrice;

  //             }
  //           }

  //         }
  //       }

  //       if (this.isArrayHasValue(data[0]['LoyaltyData'])) {
  //         this.ObjLoyalty = data[0]['LoyaltyData'][0];
  //         debugger
  //         this.isLoyaltyProgramAvailable = true;
  //         this.localStorage.seten('lsLoyalty' + this.ObjCompany.CompanyID, this.ObjLoyalty);
  //       } else {
  //         this.isLoyaltyProgramAvailable = false;
  //         this.localStorage.delete('lsLoyalty' + this.ObjCompany.CompanyID);
  //       }
  //       debugger
  //       if (data[0]['RemainingPoints'][0].RemainingPoints && data[0]['RemainingPoints'][0].RemainingPoints != null) {
  //         this.LoyaltyPoints = data[0]['RemainingPoints'][0].RemainingPoints;
  //         this.LoyaltyPointsC = data[0]['RemainingPoints'][0].RemainingPoints;
  //         this.LoyaltyAmount = data[0]['RemainingPoints'][0].RemainingAmount;
  //         this.LoyaltyAmountC = data[0]['RemainingPoints'][0].RemainingAmount;
  //       } else {
  //         this.LoyaltyPoints = 0;
  //         this.LoyaltyPointsC = 0;
  //       }

  //       if (this.isArrayHasValue(data[0]['OutletInfo'])) {
  //         this.ObjTaxInfo = data[0]['OutletInfo'][0];

  //         if (this.OrderType == 'Pickup') {
  //           this.DeliveryFee = 0.0;
  //         } else {
  //           this.DeliveryFee = this.ObjTaxInfo.DeliveryFee;
  //         }
  //         this.localStorage.seten('DeliveryFee' + this.CompanyIdL, this.DeliveryFee);

  //         this.reCalculate(this.ObjTaxInfo.IsTaxInclusive, this.ObjTaxInfo.IsTaxOnDiscountAmount, this.ObjTaxInfo.TaxRate, isloyaltyCheck);
  //       }



  //     }, error => alert(this.dataservice.getconnectionmsg()));
  // }

  applyLoyality(){
    let body = [{'isLoyaltySelected':this.isLoyaltySelected,'LoyaltyRemainingPoints': this.LoyaltyRemainingPoints,'LoyaltyRemainingAmount':this.LoyaltyRemainingAmount}]
    this.dialogRef.close(body);
  }
  CustomerID:any;
  CurrentDate:any;
  getRemainigPoints(){
    // this._spinnerService.show();
    this.dataservice.get('pos/GetLoyalityRemainingPoints/'+ this.Utilities.getSubscriptionID()+'/'+this.CustomerID+'/'+ this.Utilities.GetCurrentDate())
      .subscribe(data => {debugger
        if (this.isArrayHasValue(data[0]['LoyalityRemainingPoints'])) {
           this.LoyaltyRemainingPoints = data[0]['LoyalityRemainingPoints'][0].RemainingPoints;
           this.LoyaltyRemainingAmount = data[0]['LoyalityRemainingPoints'][0].RemainingAmount;
        } 
        else {
          // this.lstCustomerData = [];
        }

        // this._spinnerService.hide();
      }, error => alert('error'));
  }

  isArrayHasValue(obj: any[]): boolean {
    let isArrayWithLength = Boolean(Array.isArray(obj) && obj.length);
    return isArrayWithLength;
  }
}

class TaxInfo {
  IsOrderDlvy: boolean = false;
  IsOrderPickup: boolean = false;
  DeliveryStartTime: any;
  DeliveryEndTime: any;
  PickupStartTime: any;
  PickupEndTime: any;
  MinOrderValue: any;
  Address: any = '';
  Area: any = '';
  DlvyPrepMinutes: any;
  DlvyplusPrepMinutes: any;
  BranchID: any;
  BranchName: any;
  DeliveryFee: any;
  TaxRate: any;
  IsTaxInclusive: boolean;
  IsTaxOnDiscountAmount: boolean;
}
export class Loyalty {
  LoyalityID: any;
  Perordervalue: any;
  ValuePoint: any;
  Limitperorder: any;
  Pointexpiry: any;
  Active: any;
  CompanyId: any;
  IsLoyaltyOnAllItems: boolean;
}