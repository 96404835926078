<nb-card>
  <nb-card-header>
    <div>
      {{lblVoidItem}} <span class="label"> | {{ItemName}}</span>
      <img src="./assets/images/lookupclose.png" (click)="ClosePopup()" style="float: right; width: 25px;cursor: pointer;">
      <hr />
    </div>
  </nb-card-header>
  <nb-card-body>
    <div class="row">

      <div class="col-md-6 col-sm-12">
        <div class="form-group">
          <label for="bArea" class="label">{{Pleaseselectareason}}</label>
          <nb-select [(selected)]="RejectReasonID" style="width:100%">
            <nb-option *ngFor="let _data of ddlRejectReasons" [value]="_data.RejectReasonID">{{_data.RejectReason}}</nb-option>
          </nb-select>
        </div>
      </div>

      <div class="col-md-6 col-sm-12">
        <div class="form-group">
          <label for="bFlat" class="label">{{lblOrignalQty}}</label>
          <input nbInput fullWidth [(ngModel)]="OrignalQty" name="oq" placeholder="oq" readonly>
        </div>
      </div>

      <div class="col-md-6 col-sm-12">
        <div class="form-group">
          <label for="bFlat" class="label">{{Description}}</label>
          <textarea nbInput style="width:100%" [(ngModel)]="RejectReason" [maxlength]="maxChars"></textarea>
          <br />
          <label style="font-size:12px"> {{CharachtersRemaining}} {{RejectReason.length}}/{{maxChars}}</label>
        </div>
      </div>

      <div class="col-md-6 col-sm-12">
        <div class="form-group">
          <label for="bFlat" class="label">{{lblVoidQuantity}}</label>
          <input nbInput fullWidth [(ngModel)]="VoidQty" name="vq" placeholder="vq" [disabled]="VoidQty == 1">
          <i class="far fa-keyboard" (click)="CalcPopup()" style="cursor: pointer;cursor: pointer;padding-right: 10px;position: relative;float: right;margin-top: -30px;font-size: 22px;"></i>
        </div>
        <p class="caption status-danger" *ngIf="qtyValidation">{{VoidQtyshouldbeless}}</p>
      </div>


    </div>

    <div class="form-group col-md-12">

      <div class="row">
        <div class="col-md-10">

        </div>

        <div class="col-md-2">
        </div>

      </div>
    </div>

    <div class="form-group col-md-12 col-sm-12">

      <div class="row">
        <div class="col-md-10 col-sm-6 cls2">
          <button nbButton status="basic" (click)="ClosePopup()">{{Close}}</button>
        </div>

        <div class="col-md-2 col-sm-6 cls2">
          <button nbButton status="danger" (click)="VoidItem()">{{Void}}</button>
        </div>

      </div>
    </div>
  </nb-card-body>
</nb-card>
