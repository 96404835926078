import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrderListComponent } from '../orderlist/orderlist.component';

import { OrderMaster, OrderDetail, VoucherData } from '../../live/liveorder/liveorder.component';
import { DataserviseService } from '../../../shared/dataservice';
import { PopupService } from '../../../shared/popup';
import { MatDialog } from '@angular/material/dialog';
// import { RejectPopupComponent } from '../rejectpopup/rejectpopup.component';
import { UtilitiesService } from '../../../shared/utilities.service';
import { RejectPopupComponent } from '../../live/liveorder/rejectpopup/rejectpopup.component';
import { TranslatePipe } from '../../../shared/pipe/translate.pipe';

enum OrderPerpStatus {
  All = 'All',
  New = 'New',
  InKitchen = 'InKitchen',
  InRoute = 'InRoute',
  Rejected = 'REJECTED',
  Delivered = 'Delivered',
  Cancel = 'Cancel',
}

@Component({
  selector: 'ngx-orderlistpopup',
  styleUrls: ['./orderlistpopup.component.scss'],
  templateUrl: './orderlistpopup.component.html',

})



export class OrderListPopupComponent implements OnInit {

  ObjOrderMaster: OrderMaster = new OrderMaster();
  ArrOrderDetail: OrderDetail[] = [];
  ArrVoucherData: VoucherData[] = [];
  ObjCurrency: Country = new Country();
  IsCancelOrderButtonActive: boolean = false;
  IsOpenCheckButtonActive: boolean = false;
  ReceiptURL: any = '';
  ShowPrintButton: boolean = false;
  cultre: any = localStorage.getItem('LanguageCulture'+this.Utilities.getURLCompanyName());
  ApplicationCode: any;
  validationPrintReceipt : boolean = false;
  MaxReceiptPrintOut : any;  
  MaxKitchenReceiptPrintOut : any;
  ReceiptPrintOutCount : any;
  KitchenReceiptPrintOutCount : any;
  IsMaxPolicyOn : any;
  ShiftClosed: any = this.translate.transform('ShiftClosed');

  IsPrintFromRetail: boolean = false;

  constructor(private translate: TranslatePipe, private Utilities: UtilitiesService, public dialog: MatDialog, public dialogRef: MatDialogRef<OrderListComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private dataservice: DataserviseService, private _popup: PopupService) {

    // OrderCannotbeCanceledDuetoShiftAlreadyClosed: any = this.translate.transform('OrderCannotbeCanceledDuetoShiftAlreadyClosed');


  }


  printComponent() {
    window.print();
  }

  
  ngOnInit(): void {
    debugger
    this.ObjOrderMaster = this.data.data.data;
    this.getMasterDetailData(this.ObjOrderMaster.OrderID);
    this.IsCancelOrderButtonActive = this.data.IsCancelOrderButtonActive;
    this.IsOpenCheckButtonActive = this.data.IsOpenCheckButtonActive;
    this.ApplicationCode = JSON.parse(localStorage.getItem('sessionObject' + this.Utilities.getURLCompanyName()))[0][0].ApplicationCode;

    
    if (sessionStorage.getItem('RetailPrint' + this.Utilities.getURLCompanyName()) != undefined && sessionStorage.getItem('RetailPrint' + this.Utilities.getURLCompanyName()) != null) {
      this.IsPrintFromRetail = true;
      
    }
  }
  lstPrinterserversetting: any = [];
  ClosePopup(): void {
    this.dialogRef.close(undefined);
  }
  OrderType: any = '';
  IsDirectPrint: boolean = true;


  getMasterDetailData(OrderID): void {
    this.validationPrintReceipt = false;
    this.ObjOrderMaster = new OrderMaster();
    this.ObjCurrency = new Country();

    this.dataservice.get('OrderList/GetOrderData/' + OrderID + '/' + this.Utilities.getSubscriptionID() + '/' + localStorage.getItem('LanguageCulture'+this.Utilities.getURLCompanyName()))
      .subscribe(data => {
        if (data[0]['MasterData']) {
          debugger
          this.ObjOrderMaster = data[0]['MasterData'][0];
          this.MaxKitchenReceiptPrintOut = data[0]['MasterData'][0]["MaxKitchenReceiptPrintOut"];
          this.MaxReceiptPrintOut = data[0]['MasterData'][0]["MaxReceiptPrintOut"];
          this.IsMaxPolicyOn = data[0]['MasterData'][0]["IsMaxPolicyOn"];
          this.ReceiptPrintOutCount = data[0]['MasterData'][0]["ReceiptPrintOutCount"];
          this.KitchenReceiptPrintOutCount = data[0]['MasterData'][0]["KitchenReceiptPrintOutCount"];

          if (this.ObjOrderMaster.OrderType != 'N/A' && this.ObjOrderMaster.OrderType == 'Pickup') {
            this.OrderType = 'Ready to Collect';
          }
          else if (this.ObjOrderMaster.OrderType != 'N/A' && this.ObjOrderMaster.OrderType == 'Delivery') {
            this.OrderType = 'Ready to dispatch';
          }
          else {
            this.OrderType = this.ObjOrderMaster.OrderType;
          }

        }
        if (this.isArrayHasValue(data[0]['DetailData'])) {
          this.ArrOrderDetail = data[0]['DetailData'];
        }
        else {
          this.ArrOrderDetail = [];
        }
        if (this.isArrayHasValue(data[0]['printerserversetting'])) {
          this.lstPrinterserversetting = data[0]['printerserversetting'];
        }
        debugger
        if (data[0]['Currency']) {
          this.ObjCurrency = data[0]['Currency'][0];
          this.CountryCheck();
        }
        if (this.isArrayHasValue(data[0]['IsDirectPrint'])) {
          this.IsDirectPrint = data[0]['IsDirectPrint'][0].IsDirectPrint;
        }
        if (this.isArrayHasValue(data[0]['VoucherData'])) {
          this.ArrVoucherData = data[0]['VoucherData'];
        }
        else {
          this.ArrVoucherData = [];
        }

        if (data[0]['ConsumedPoints'][0] && data[0]['ConsumedPoints'][0].ConsumedPoints != null && data[0]['ConsumedPoints'][0].ConsumedPoints != undefined) {
          this.LoyaltyPoints = data[0]['ConsumedPoints'][0].ConsumedPoints;
        }
        else {
          this.LoyaltyPoints = 0;
        }

      //this.getReceipt();
        this.showprint();

      }, error => alert('error'));
  }


  win;

  KitchenRePrintClick() {
    debugger
    this.lstUser = JSON.parse(localStorage.getItem('sessionObject' + this.Utilities.getURLCompanyName()))[0];
    if (this.lstPrinterserversetting.length > 0) {
      if (this.lstPrinterserversetting[0]['IsKOTDirectPrint'] == 2) 
      {
        if(this.IsMaxPolicyOn)
        {
          if(this.MaxKitchenReceiptPrintOut > this.KitchenReceiptPrintOutCount )
          {
            let link = this.lstPrinterserversetting[0]['ServerLink'] + '/Default?O=' + this.lstPrinterserversetting[0]['OrderID'] + '&C=' + this.Utilities.getSubscriptionID() + '&type=KRePrint' + '&OutletID=' + this.ObjOrderMaster.OutletId + '&ShiftID=' + this.ObjOrderMaster.ShiftID;
            this.win = window.open(link, '_blank', 'width=300,height=200');
            this.KitchenReceiptPrintOutCount = this.KitchenReceiptPrintOutCount + 1;

          }
          else
          {
            this.validationPrintReceipt = true;
          }
        }
        else
        {
          let link = this.lstPrinterserversetting[0]['ServerLink'] + '/Default?O=' + this.lstPrinterserversetting[0]['OrderID'] + '&C=' + this.Utilities.getSubscriptionID() + '&type=KRePrint' + '&OutletID=' + this.ObjOrderMaster.OutletId + '&ShiftID=' + this.ObjOrderMaster.ShiftID;
          this.win = window.open(link, '_blank', 'width=300,height=200');
        }

      
      }
      if (this.lstPrinterserversetting[0]['IsKOTDirectPrint'] == 3) {
        // this.ReceiptURL = this.Utilities.getReceiptURL(this.Utilities.getEncrypt(this.Utilities.getCompanyName()), this.Utilities.getEncrypt(this.lstPrinterserversetting[0]['OrderID']), this.lstUser[0]['CountryCode']);
        this.ReceiptURL = this.Utilities.getKitchenReceiptURL(this.Utilities.getEncrypt(this.Utilities.getCompanyName()), this.Utilities.getEncrypt(this.lstPrinterserversetting[0]['OrderID']), this.lstUser[0]['CountryCode']);
        this.checkIframeLoaded();
      }
    }
    else {
        // this.ReceiptURL = this.Utilities.getReceiptURL(this.Utilities.getEncrypt(this.Utilities.getCompanyName()), this.Utilities.getEncrypt(this.lstPrinterserversetting[0]['OrderID']), this.lstUser[0]['CountryCode']);
        this.ReceiptURL = this.Utilities.getKitchenReceiptURL(this.Utilities.getEncrypt(this.Utilities.getCompanyName()), this.Utilities.getEncrypt(this.ObjOrderMaster.OrderID), this.lstUser[0]['CountryCode']);
      this.checkIframeLoaded();
    }
  }
  ReceiptRePrintClick() {
    debugger
    this.lstUser = JSON.parse(localStorage.getItem('sessionObject' + this.Utilities.getURLCompanyName()))[0];
    if (this.lstPrinterserversetting.length > 0) {
      if (this.lstPrinterserversetting[0]['IsReceiptDirectPrint'] == 2) 
      {
        if(this.IsMaxPolicyOn)
      {
        if(this.MaxReceiptPrintOut > this.ReceiptPrintOutCount  )
        {
          let link = this.lstPrinterserversetting[0]['ServerLink'] + '/Default?O=' + this.lstPrinterserversetting[0]['OrderID'] + '&C=' + this.Utilities.getSubscriptionID() + '&type=ReceiptREPRINT' + '&OutletID=' + this.ObjOrderMaster.OutletId + '&ShiftID=' + this.ObjOrderMaster.ShiftID;
          this.win = window.open(link, '_blank', 'width=300,height=200');
          this.ReceiptPrintOutCount = this.ReceiptPrintOutCount + 1;
        }
        else
        {
          this.validationPrintReceipt = true;
        }
      }
      else
      {
        let link = this.lstPrinterserversetting[0]['ServerLink'] + '/Default?O=' + this.lstPrinterserversetting[0]['OrderID'] + '&C=' + this.Utilities.getSubscriptionID() + '&type=ReceiptREPRINT' + '&OutletID=' + this.ObjOrderMaster.OutletId + '&ShiftID=' + this.ObjOrderMaster.ShiftID;
        this.win = window.open(link, '_blank', 'width=300,height=200');
      }
  
      }
      if (this.lstPrinterserversetting[0]['IsReceiptDirectPrint'] == 3) {
        this.ReceiptURL = this.Utilities.getReceiptURL(this.Utilities.getEncrypt(this.Utilities.getCompanyName()), this.Utilities.getEncrypt(this.lstPrinterserversetting[0]['OrderID']), this.lstUser[0]['CountryCode'],this.ObjOrderMaster.OutletId,'true','false');
        this.checkIframeLoaded();
      }
       if (this.lstPrinterserversetting[0]['IsReceiptDirectPrint'] == 4) {
        const url = this.Utilities.geta4SizeReport(this.Utilities.getEncrypt(this.Utilities.getCompanyName()), this.Utilities.getEncrypt(this.lstPrinterserversetting[0]['OrderID']));
      this.ReceiptURL = url;
      this.checkIframeLoaded();
      }
    }
    else {
      this.ReceiptURL = this.Utilities.getReceiptURL(this.Utilities.getEncrypt(this.Utilities.getCompanyName()), this.Utilities.getEncrypt(this.ObjOrderMaster.OrderID), this.lstUser[0]['CountryCode'],this.ObjOrderMaster.OutletId,'true','false');
      this.checkIframeLoaded();
    }
  }


  A4Print() {debugger
      const url = this.Utilities.geta4SizeReport(this.Utilities.getEncrypt(this.Utilities.getCompanyName()), this.Utilities.getEncrypt(this.lstPrinterserversetting[0]['OrderID']));
      this.ReceiptURL = url;
      console.log(this.ReceiptURL)
      this.checkIframeLoaded();
  }

  async checkIframeLoaded() {
    //// Get a handle to the iframe element
    //var iframe = document.getElementById('orderreceipt') as HTMLIFrameElement;

    //if (iframe) {
    // try {
    //   var iframeDoc = iframe.contentDocument || iframe.contentWindow.document;

    //   // Check if loading is complete
    //   if (iframeDoc.readyState === 'complete' || iframeDoc.readyState === 'interactive') {
    //     // The loading is complete, call the function we want executed once the iframe is loaded
    //     await this.onLoad();
    //     return false;
    //   } else {
    //     // If not loaded, set up an event listener for the 'load' event
    //     iframe.addEventListener('load', async () => {
    //       await this.onLoad();
    //     });
    //   }
    // } catch (error) {
    //   console.error("Error accessing iframe content:", error);
    // }
    //} else {
    // console.error("Iframe with id 'printf' not found.");
    //}

    //// If we are here, it is not loaded. Set things up so we check the status again in 100 milliseconds
    //// You might want to uncomment the following line if you want to keep checking
    //// window.setTimeout(() => this.checkIframeLoaded(), 100);
  }


  //async checkIframeLoaded() {
  //  debugger
  //  // Get a handle to the iframe element
  //  var iframe = document.getElementById('orderreceipt') as HTMLIFrameElement;
  //  var iframeDoc = iframe.contentDocument || iframe.contentWindow.document;

  //  // Check if loading is complete
  //  if (iframeDoc.readyState == 'complete') {
  //    //iframe.contentWindow.alert("Hello");
  //    //await iframe.contentWindow.print();
  //    // The loading is complete, call the function we want executed once the iframe is loaded
  //    await this.onLoad();
  //    return false;
  //  }

  //  // If we are here, it is not loaded. Set things up so we check   the status again in 100 milliseconds
  //  //window.setTimeout(this.checkIframeLoaded, 100);
  //}
  //onLoad() {
  // let timeout = this.Utilities.GetTimeOut();
  // if (this.ReceiptURL != '') {
  //   setTimeout(() => {
  //     window.frames["orderreceipt"].focus();
  //     window.frames["orderreceipt"].print();
  //   }, timeout);

  // }
  //}

  showprint() {
    setTimeout(() => {
      this.ShowPrintButton = true;
    }, 650);
  }
  LoyaltyPoints: any;
  lstUser = [];
  getReceipt(): void {

    this.lstUser = JSON.parse(localStorage.getItem('sessionObject'+this.Utilities.getURLCompanyName()))[0];
    const CompanyName = this.Utilities.getCompanyName();
    const url = this.Utilities.getReceiptURL(this.Utilities.getEncrypt(CompanyName), this.Utilities.getEncrypt(this.ObjOrderMaster.OrderID), this.lstUser[0]['CountryCode'],this.ObjOrderMaster.OutletId,'true','false');
    this.ReceiptURL = url;
  }

  isCountryPk: boolean = false;
  CountryCheck(): void {

    if (this.ObjCurrency.CountryName.toLowerCase() == 'pakistan' || this.ObjCurrency.CountryCode.toLowerCase() == 'pk') {
      this.isCountryPk = true;
    } else {
      this.isCountryPk = false;
    }

  }
  setAuditFields(): void {
    debugger
    this.ObjOrderMaster.CreatedBy = this.Utilities.getUserId();
    this.ObjOrderMaster.CreatedOn = this.Utilities.GetCurrentDateTime();
    this.ObjOrderMaster.ModifyBy = this.Utilities.getUserId();
    this.ObjOrderMaster.ModifyOn = this.Utilities.GetCurrentDateTime();
    this.ObjOrderMaster.CompanyId = this.Utilities.getSubscriptionID();
  }

  OrderStatusChange(val): void {

    debugger
    if (this.ObjOrderMaster.OrderID == undefined || this.ObjOrderMaster.OrderID == '') {
      alert('Please select order!');
      return;
    }

    if (val == OrderPerpStatus.Delivered) {
      this.ObjOrderMaster.OrderPerpStatus = OrderPerpStatus.Delivered;
    }
    else if (val == OrderPerpStatus.Rejected) {
      this.ObjOrderMaster.OrderPerpStatus = OrderPerpStatus.Rejected;
    }
    else if (val == OrderPerpStatus.InKitchen) {
      this.ObjOrderMaster.OrderPerpStatus = OrderPerpStatus.InKitchen;
    }
    else if (val == OrderPerpStatus.InRoute) {
      this.ObjOrderMaster.OrderPerpStatus = OrderPerpStatus.InRoute;
    }
    else {
      this.ObjOrderMaster.OrderPerpStatus = OrderPerpStatus.Cancel;
    }

    this.setAuditFields();

    this.dataservice.post('OrderList/UpdateLiveOrder/', this.ObjOrderMaster)
      .subscribe(result => {
        if (result) {
          if (result == 'SUCCESS') {
            this.dialogRef.close(result);
          }
          else {
            alert('error during updating!');
          }
        }

      }, error => alert('error'));
  }

  isArrayHasValue(obj: any[]): boolean {
    let isArrayWithLength = Boolean(Array.isArray(obj) && obj.length);
    return isArrayWithLength;
  }

  Reject(): void {

    let data = this.ObjOrderMaster;
    let dialogRef = this.dialog.open(RejectPopupComponent, {
      autoFocus: false,
      width: '600px',
      maxHeight: '90vh',
      data: { data },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        this.dialogRef.close('SUCCESS');
      }

    });

  }

 YoucannotprinttheReceiptmorethen: any = this.translate.transform('YoucannotprinttheReceiptmorethen');
  times: any = this.translate.transform('times');

  CancelOrder(): void {
    debugger
    this.dataservice.get('OrderList/CheckShiftStatus/' + this.ObjOrderMaster.ShiftID + '/' + this.Utilities.getSubscriptionID())
      .subscribe(result => {
        debugger

        if (result[0].ShiftStatus[0].Column1 == 1) {
          alert(this.translate.transform('OrderCannotbeCanceledDuetoShiftAlreadyClosed'))
        }
        else {
          if (this.ObjOrderMaster.OrderID != undefined && this.ObjOrderMaster.OrderID != null && this.ObjOrderMaster.OrderID != '') {
            this.ObjOrderMaster.OrderPerpStatus = 'Cancel';
            this.ObjOrderMaster.Channel = 'POSRestaurants';

            let data = this.ObjOrderMaster;

            let dialogpopup = this.dialog.open(RejectPopupComponent, {
              width: '700px',

              data: { data },
            })

            dialogpopup.afterClosed().subscribe(result => {

              if (result != undefined) {
                //this._popup.ShowPopup('Update');
                this.afterClosedMethod(this.lstPrinterserversetting);
                //  this.LoadGrid();
              }
            });

          }
          else {
            alert('Please select order first.');
          }
        }


      }
      )

  }

  ShiftNotExist:boolean = false;
  OpenCheck(): void {debugger
    if (window.confirm('Are you sure you want to Open Check the selected Order?')) 
    {
    this.ObjOrderMaster.ModifyBy = this.Utilities.getUserId();
    if (this.ObjOrderMaster.OrderID != undefined && this.ObjOrderMaster.OrderID != null && this.ObjOrderMaster.OrderID != '') {
      this.dataservice.post('OrderList/OpenCheck/', this.ObjOrderMaster).subscribe(result => {debugger
        if (result != undefined) {debugger
          if(result == 'Update'){
          this._popup.ShowPopup('Update')
          this.dialogRef.close('Update');
          }
          else if(result == 'ShiftNotExist'){
            this.ShiftNotExist = true;
          }
        }
      })
    }
      else {
        alert('Please select order first.');
      }
  }
  }

  async afterClosedMethod(result) {
    debugger
    if (result[0].IsReceiptDirectPrint == 4) {

      const url = this.Utilities.geta4SizeReport(this.Utilities.getEncrypt(this.Utilities.getCompanyName()), this.Utilities.getEncrypt(result[0].OrderID));
      this.ReceiptURL = url;
      this.checkIframeLoaded();
      // this.checkIframeLoaded();
    }
    if (result[0].IsReceiptDirectPrint == 3) {
      if (result[0].IsKOTDirectPrint == 2 && this.IsDirectPrint == true) {
        let link = result[0].ServerLink + '/Default?O=' + result[0].OrderID + '&C=' + result[0].CompanyId + '&type=KP' + '&OutletID=' + this.ObjOrderMaster.OutletId + '&ShiftID=' + this.ObjOrderMaster.ShiftID + '&POSID=' + this.lstUser[0]['CountryCode'];
        this.win = window.open(link, '_blank', 'width=300,height=200');
        this.ReceiptURL = this.Utilities.getDigiPosReceiptURL(this.Utilities.getEncrypt(this.Utilities.getCompanyName()), this.Utilities.getEncrypt(result[0].OrderID), this.lstUser[0]['CountryCode']);
      }
      // this.ReceiptURL = this.Utilities.getReceiptURL(this.Utilities.getEncrypt(this.Utilities.getCompanyName()), this.Utilities.getEncrypt(sessionStorage.getItem('orderid')), this.CountryCode);
      this.ReceiptURL = this.Utilities.getDigiPosReceiptURL(this.Utilities.getEncrypt(this.Utilities.getCompanyName()), this.Utilities.getEncrypt(result[0].OrderID), this.lstUser[0]['CountryCode']);
      this.checkIframeLoaded();
    }
    else if (result[0].IsReceiptDirectPrint == 2) {
      if (this.IsMaxPolicyOn) {
        if (this.ReceiptPrintOutCount == undefined || this.ReceiptPrintOutCount == null) { this.ReceiptPrintOutCount = 0; }
        if (this.MaxReceiptPrintOut > this.ReceiptPrintOutCount) {
          let link = result[0].ServerLink + '/Default?O=' + result[0].OrderID + '&C=' + result[0].CompanyId + '&type=RP' + '&OutletID=' + this.ObjOrderMaster.OutletId + '&ShiftID=' + this.ObjOrderMaster.ShiftID + '&AddnDeleteItems=' + false + '&POSID=' + result[0].POSID;
          this.win = await window.open(link, '_blank', 'width=300,height=200');
          this.ReceiptPrintOutCount = this.ReceiptPrintOutCount + 1;
        }
        else {
          this.validationPrintReceipt = true;
        }
      }
      else {
        let link = result[0].ServerLink + '/Default?O=' + result[0].OrderID + '&C=' + result[0].CompanyId + '&type=RP' + '&OutletID=' + this.ObjOrderMaster.OutletId + '&ShiftID=' + this.ObjOrderMaster.ShiftID + '&AddnDeleteItems=' + false + '&POSID=' + result[0].POSID;
        this.win = await window.open(link, '_blank', 'width=300,height=200');
      }

    }


  }
}
class Country {
  CurrencyCode: any = '';
  Rate: any = '';
  NumOfDecimal: any = '';
  CountryName: any = '';
  CountryCode: any = '';
}
