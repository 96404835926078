import { Component, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import {DataserviseService} from '../../../shared/dataservice';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PopupService } from '../../../shared/popup';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'ngx-navbar',
    styleUrls: ['./navbar.component.scss'],
    templateUrl: './navbar.component.html',
})


export class NavBarComponent {



    constructor(private dataservice: DataserviseService, public dialog: MatDialog, private _popup: PopupService, private sanitizer: DomSanitizer) {
  
    }
  @ViewChild('frame') iframe: ElementRef;

    ngAfterViewInit() {
    }

  ngOnInit(): void {
  }

  OrderNowVisible: boolean = true;
  OrderNowClose(): void {
    this.OrderNowVisible = false;
  }
  
  open(): void {
    
    this.iframe.nativeElement.setAttribute('src', '/pages/live/orderonline');
  }
}
