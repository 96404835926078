<link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous" />
<div class="doNotPrint">
  <div class="row mainDiv">


    <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-xs-1" style="background-color:#2b436e;padding: 0px;height: 100vh;overflow-y: scroll;">
      <div class="menusection">
        <div (click)="menuClick('home')" class="menuparentdiv background">
          <div (click)="homeClick()" [ngClass]="[tab == 'home' ? 'activemenu menuclass' : 'bottomborder menuclass']">
            <!-- [ngStyle]="{'border-bottom':tab != 'home' ? 'none' : '1px solid #9d9d9c !important' }" -->
            <img src="assets/images/resturantMenu/home.svg" class="menusvg" />
            <p class="menuIconText">{{Home}}</p>
          </div>
        </div>

        <div (click)="menuClick(menu.EntityCode)" [ngClass]="[tab == menu.EntityCode ? 'background' : 'menuparentdiv']" *ngFor="let menu of lstPosMenu">
          <div [ngClass]="[tab == menu.EntityCode ? 'activemenu menuclass' : 'menuclass']">
            <img src="assets/images/resturantMenu/{{menu.Icon}}" class="menusvg" />
            <p class="menuIconText">{{menu.EntityName}}</p>
          </div>
        </div>

        <!-- <div (click)="menuClick('pendOrder')" [ngClass]="[tab == 'pendOrder' ? 'background' : 'menuparentdiv']">
    <div [ngClass]="[tab == 'pendOrder' ? 'activemenu menuclass' : 'menuclass']">
      <img src="assets/images/resturantMenu/pendingorder.svg" class="menusvg" />
      <p class="menuIconText">Pending Orders</p>
    </div>
  </div>
  <div (click)="prntrec()" [ngClass]="[tab == 'print' ? 'background' : 'menuparentdiv']">
    <div [ngClass]="[tab == 'print' ? 'activemenu menuclass' : 'menuclass']">
      <img src="assets/images/resturantMenu/printreceipt.svg" class="menusvg" />
      <p class="menuIconText">Print Receipt</p>
    </div>
  </div>
  <div (click)="menuClick('paidorder')" [ngClass]="[tab == 'paidorder' ? 'background' : 'menuparentdiv']">
    <div [ngClass]="[tab == 'paidorder' ? 'activemenu menuclass' : 'menuclass']">
      <img src="assets/images/resturantMenu/paidorder.svg" class="menusvg" />
      <p class="menuIconText">Paid Orders</p>
    </div>
  </div>
  <div (click)="menuClick('transfertable')" [ngClass]="[tab == 'transfertable' ? 'background' : 'menuparentdiv']">
    <div [ngClass]="[tab == 'transfertable' ? 'activemenu menuclass' : 'menuclass']">
      <img src="assets/images/resturantMenu/transfertable.svg" class="menusvg" /><p class="menuIconText">Transfer Table</p>
    </div>
  </div>
  <div (click)="menuClick('splitbill')" [ngClass]="[tab == 'splitbill' ? 'background' : 'menuparentdiv']">
    <div [ngClass]="[tab == 'splitbill' ? 'activemenu menuclass' : 'menuclass']">
      <img src="assets/images/resturantMenu/splitbill.svg" class="menusvg" /><p class="menuIconText">Split Bills</p>
    </div>
  </div> -->
        <div (click)="menuClick('logout')" [ngClass]="[tab == 'logout' ? 'background' : 'menuparentdiv']">
          <div [ngClass]="[tab == 'logout' ? 'activemenu menuclass' : 'menuclass']">
            <img src="assets/images/resturantMenu/printreceipt.svg" class="menusvg" /><p class="menuIconText">{{Logout}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-8 col-lg-8 col-md-7 col-sm-7 col-xs-7" style="background-color: #f6f6f6;padding-top: 5px;">
      <!-- <div class="parent1 paddBotton">
        <div class="child1 lblwidth" style="width: 85px;">Wel Come</div>
        <div class="child4"> <hr class="between" /></div>
      </div> -->
    
      <div style="width: auto; overflow-x: scroll; overflow-y: hidden; white-space: nowrap; height: 88px" id="scrollDiv">
        <figure *ngIf="IsSalesTypeButton" style="position: relative;background-color: #cccccc;" (click)="OpenDialogSalesTypePopup()" class="cardtype card-product-grid card-lg menucalss">
          <span class="backcircle" style="background:none">
            <i  style="color:white; padding-left: 10px;padding-top: 10px;" aria-hidden="true"></i>
          </span>
          <a class="title" style="text-align:center;font-weight: 700;">Sales Type</a>
        </figure>
        <figure *ngFor="let row of lstOrderType; let i = index" [ngClass]="[row.ID == OrderTypeID ? 'cardtype card-product-grid card-lg cardtypeselected menucalss' : 'cardtype card-product-grid card-lg menucalss']" style="position: relative;" (click)="figureClick(row.ID,row.Type)">
          <span class="backcircle">
            <i class={{row.icon}} style="color:white; padding-left: 10px;padding-top: 10px;" aria-hidden="true"></i>
          </span>
          <a [ngClass]="[row.ID == OrderTypeID ? 'selectedtitle' : 'title']" style="text-align:center">{{row.Type}}</a>
        </figure>
        
      </div>
      <div class="parent1 paddBotton">
        <div class="child1 lblwidth"><a (click)="openDialogCategoryMenuPopup()" style="text-align: center;cursor: pointer">{{CategoryMenu}}</a></div>
        <!-- <div class="child4">  -->
        <hr class="betweenCategory" />
        <!-- </div> -->

        <label class="label" style="margin: 0px;padding-right: 5px;padding-left: 10px;">{{lblSearchBarcode}}</label>
        <div>
          <input name="Barcode" [(ngModel)]="SearchBarcode" (change)="BarcodeChange(SearchBarcode)" style="width: 85%;" />
          <i class="fas fa-search fontsize"  style="cursor: pointer;cursor: pointer;background: lightgray;border: 1px solid;padding-top: 6px;padding-bottom: 3px;padding-right: 5px;border-left: none;padding-left: 5px;"></i>
        </div>

      </div>

    
      <!-- <ngx-slick-carousel #slickModal="slick-carousel" [config]="brandslideConfig" class="slickCarousal"> -->
       
        <div style="width: auto; overflow-x: scroll; overflow-y: hidden; white-space: nowrap;padding-bottom: 5px;" id="scrollDiv">
          <figure *ngFor="let row of lstCategories; let i = index"  [ngClass]="[row.Code == productstr ? 'cardCategory card-product-grid card-lg cardtypeselected menucalss' : 'cardCategory card-product-grid card-lg menucalss']">
            <a (click)="getProduct(row.Code)" class="img-wrap">
              <img src={{row.ImageName}}>
            </a>
            <a *ngIf="cultures != 'ar'" title="{{row.Name}}" [ngClass]="[row.Code == productstr ? 'selectedtitle title' : 'title']" style="text-align:center;font-size: 16px;padding-left: 8px;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;"> {{row.Name}}</a>
            <a *ngIf="cultures == 'ar'" title="{{row.AName}}" [ngClass]="[row.Code == productstr ? 'selectedtitle title' : 'title']" style="text-align:center;font-size: 16px;padding-left: 8px;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;"> {{row.AName}}</a>
            <!-- <a title="{{row.Description}}" (click)="getProduct(row.Code)" [ngClass]="[row.Code == productstr ? 'selectedtitle title' : 'title']" style="text-align:center;font-size: 12px;padding-left: 8px;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;"> {{row.Description}}</a> -->
          </figure>
        </div>
      <!-- </ngx-slick-carousel> -->

      <div class="parent1 paddBotton">
        <div class="child1">{{Signature}}</div>
        <div class="child4copy"> <hr class="between" /></div>
      </div>

      <!-- <div style="overflow-y: auto;height: 330px;max-height: 330px;"> -->
      <div class="productDiv">
        <div *ngFor="let row of lstProduct; let i = index">
          <figure class="cardProduct card-product-grid card-lg menucalss" (mouseenter)="hoverEvent(i,true)" (mouseleave)="hoverEvent(i,false)"  *ngIf="row.ProductIdRank == 1" style="margin-bottom: 10px;float: left;"  (click)="PushintoBakset(row)" >
            <img class="productImg" src={{row.ImageName}}>
            <span class="productImgSpan" >
              <!-- <h5 class="productName">{{row.ProductName}} </h5> -->
              <h5 *ngIf="cultures != 'ar'" class="productName" style="line-height: 20px;">{{row.PName}} </h5>
              <h5 *ngIf="cultures == 'ar'" class="productName" style="line-height: 20px;margin-bottom: 15px;">{{row.AProductName}} </h5>
              <p style="margin-top: -12px;font-size: 13px;"><label style="font-size: 10px;">{{CurrencyCode}}</label> {{row.Price | number:'1.'+NumOfDecimal}} </p>
            </span>
            <div *ngIf="IsInventoryActivate && showStock && i == indexID && row.Balance < MinimumBalanceQty" class="stock-message" >
              Remainig Stock Quantity is {{row.Balance}}
            </div>
          </figure>
          <!-- <span class="only-show-on-hover">you see me on hovering the div next to me</span> -->
        
        </div>
        <div *ngIf="IsBarcodeFound" style="background: white;color: red;background: white;text-align: center; padding: 10px;border-radius: 10px;">{{ProductNotFound}}</div>
        <!-- <span style="font-weight: 500;color:#fd7e14">
          You'll earn <strong>&nbsp;{{loyaltypoints | number:'1.'+NumOfDecimal +'-'+NumOfDecimal+''}}</strong> loyalty points = {{CurrencyCode}}&nbsp;{{AvailingAmount| number:'1.'+NumOfDecimal +'-'+NumOfDecimal+''}} cashback when you complete this order.
        </span>   -->
      
      </div>


      <div class="footer-59391">
        <div class="container" style="padding: 0px;">
          <div class="row align-items-center">
            <div class="col-lg-12 order-2 order-lg-1 mb-3 mb-lg-0" style="padding: 0px;">
              <ul class="list-unstyled nav-links m-0 nav-left">
                <li class="col-lg-3 col-sm-6 hidepoweredbytext" style="padding: 0px;text-align: center;vertical-align: top;"><a>Powered by <b>Global Informix,</b> 2022</a></li>
                <li class="col-lg-3 col-md-3 col-sm-3 footertext"><a>{{Branch}}: {{SelectedBranchName}}</a></li>
                <li class="col-lg-2 col-md-3 col-sm-3 footertext"><a>{{User}}: {{userName}}</a></li>
                <li class="col-lg-2 col-md-3 col-sm-3 footertext"><a>{{Counter}}:{{counterName}}</a></li>
                <li class="col-lg-2 col-md-3 col-sm-3 footertext"><a>{{StartDate}} : {{DayStartDate}}</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-xs-4 custCol margTop">
      <div class="row" style="padding-top: 5px;">
        <div style="width: 70%;">
          <label for="bBarcode" class="label" style="margin: 0px;">{{Customer}}</label>
          <input name="Customer" [(ngModel)]="custName" class="custInput" />
          <!-- <button class="btn btn-secondary" type="button" style="margin-top:-5px;" (click)="openDialogCustomerLookUp()"> -->
          <i class="fas fa-search fontsize" (click)="openDialogCustomerLookUp()" style="margin-left: -18px;cursor: pointer;position: absolute;top: 28px;"></i>
          <!-- </button> -->
        </div>
        <div style="width: 30%;">
          <label for="bBarcode" class="label" style="margin: 0px;">{{Table}}</label>
          <input name="Table" [(ngModel)]="TableName" class="fontsize" style="width: 90%; text-align: center;margin-left: 1px;" readonly />
        </div>
      </div>


      <!-- <div style="display: flex;padding-top: 5px;" *ngIf="SelectedBranchName">
        <p style="font-size: 12px;margin-bottom: 0px; margin-right:10px ;">Outlet:</p>
        <p style="font-size: 12px;margin-bottom: 0px;"><b>{{SelectedBranchName}}</b></p>
      </div> -->

      <div style="display: flex; padding-top: 2px;" [style.visibility]="SelectedOrderInvoice ? 'visible' : 'hidden'">
        <p style="font-size: 12px;margin-bottom: 0px; margin-right:10px;">{{OrderNo}}:</p>
        <p style="font-size: 12px;margin-bottom: 0px;"><b>{{SelectedOrderInvoice}}</b></p>
      </div>
      <!-- <div class="datetimeDiv">
        <button class="addCustomer " (click)="openAddCustomer()">Add Customer</button>
        <p style="font-size: 13px;margin-bottom: 5px;">{{this.currentdate | date:'medium'}}</p>
      </div> -->
      <div class="parent1" style="padding-top: 0px;">
        <div class="billheading">{{Bills}}</div>
        <div class="child4 HideOnMobile"> <hr class="between" /></div>
      </div>


      <!-- <div style="height: 320px;overflow-y: auto;"> -->
      <div>
        <div id="billDiv" [ngClass]="[buttonCount > 3 ? 'billdivextraheight' : 'billdiv']">
          <div class="row" *ngFor="let row of lstBasket; let i = index" [ngClass]="[row.DelFlag == 1 ? 'padd_btm_background' : 'padd_btm']">
            <div class="col-md-1" title="Void Item" style="padding:0px;margin-top:-4px">
              <i *ngIf="IsVoidButtonActive || row.IsNewItemPunch == 1" (click)="removeitem(i,row)" class="fa fa-trash-alt" style="font-size: 13px;margin-top: 8px;cursor: pointer;"></i>
            </div>
            <!-- <div class="col-md-6" style="font-size: 12px;padding: 0px;color: #2b436e;"><b>{{row.sizeProductName}}</b></div> -->
            <div *ngIf="cultures != 'ar'" class="col-md-6" style="font-size: 12px;padding: 0px;color: #2b436e;"><b>{{row.PName}}</b><span *ngIf="row.ProductSizeName"> ({{row.ProductSizeName}})</span></div>
            <div *ngIf="cultures == 'ar'" class="col-md-6" style="font-size: 12px;padding: 0px;color: #2b436e;"><b>{{row.AProductName}}</b></div>
            <div class="col-md-5" style="font-size: 12px;color: rgb(234,88,30);padding: 0px;font-weight: 900;">
              <span (click)="PriceChangePopup(row,i)">{{row.Price | number:'1.'+NumOfDecimal}}</span>
              <b style="padding-left: 12px;">{{'x' + row.Quantity}}</b>
              <span style="float: right;">{{row.Amount | number:'1.'+NumOfDecimal}}</span>
            </div>
            <!-- <div class="col-md-1" style="font-size: 10px;color: rgb(234,88,30);padding: 0px;"><b>{{'x' + row.Quantity}}</b></div> -->
            <!-- <div class="col-md-2" style="font-size: 10px;color: rgb(234,88,30);padding: 0px;text-align: end;">{{row.Amount | number:'1.'+NumOfDecimal}}</div> -->
            <div *ngFor="let objCombo of lstComboProducts;" style="display: block; width: 100%;">
              <ul class="text-black-500 text-xs leading-normal" style="margin:0;padding-left:25px" *ngIf="objCombo.MainProductID == row.ProductID && objCombo.rowid == row.rowid">
                <li style="font-size: 10px;">
                  <span *ngIf="cultures != 'ar'">{{objCombo.ProductName}} </span>
                  <span *ngIf="cultures == 'ar'">{{objCombo.AName}} </span>
                  <span *ngIf="objCombo.Price != undefined">({{CurrencyCode}}&nbsp;{{objCombo.Price| number:'1.'+NumOfDecimal}})</span>
                </li>
              </ul>
            </div>

            <div *ngFor="let m of lstSelectModifier[i];" style="display: block; width: 100%;">
              <ul class="text-black-500 text-xs leading-normal" style="margin:0;padding-left:25px" *ngIf=" m.IsSelected == true && m.ProductID == row.ProductID && row.rowid == m.rowid">
                <li style="font-size: 10px;">
                  <span *ngIf="cultures != 'ar'">{{m.Modifires}} </span>
                  <span *ngIf="cultures == 'ar'">{{m.AName}} </span>
                  <span *ngIf="m.Price != undefined">({{CurrencyCode}}&nbsp;{{m.PriceStatic| number:'1.'+NumOfDecimal}})</span>
                </li>
              </ul>
            </div>

            <span *ngIf="row.SpecialRequest" style="font-size: 10px;padding-left: 25px;min-width: 150px;"><b>Note:</b> {{row.SpecialRequest}}</span>
            <div *ngIf="row.IsSelected !=1" class="touch-buttons">
              <div (click)="minuqunty(row,i)" style="margin-left: 40px;">
                <a [routerLink]="" class="touch-buttons__button">
                  <i class="fa fa-minus-square" style="color: #fd7e14;font-size: 18px;"></i>
                </a>
              </div>
              <div class="touch-buttons touch-buttons__amount" (click)="QtyChangePopup(row,i)" style="font-size: 12px;font-weight: 600;line-height: 15px;background: bisque;margin-right: 10px;margin-left: 10px;">
                {{row.Quantity}}
              </div>
              <div (click)="plusqunty(row,i)">
                <a [routerLink]="" class=" touch-buttons__button">
                  <i class="fa fa-plus-square" style="color: #fd7e14;font-size: 18px;"></i>
                </a>
              </div>
              <div style="padding-left: 20px;" (click)="specialInstructionPopup(row,i)"><i class="fas fa-comment-edit" style="color: #fd7e14;font-size: 18px;"></i></div>

            </div>
          </div>
        </div>
      </div>
      <hr style="margin-top: 0px;margin-bottom: 5px;">
      <footer class="page-footer font-small stylish-color-dark" style="position: relative;bottom: 5px;">
        <div class="container text-center text-md-left" style="padding: 0px;">

          <div class="row">
            <div class="col-md-6 col-sm-6 col-xs-6 mx-auto" style="padding: 0px;">
              <ul class="list-unstyled" style="margin-bottom: 0px;">
                <li>
                  <a style="color: #2b436e;font-size: 13px"><b>{{Subtotal}}</b></a>
                </li>
                <li *ngIf="IsExtraCharge">
                  <a style="color: #2b436e;font-size: 13px"><b>{{ExtraChargeDescription}}</b></a>
                </li>
                <li style="display: none;">
                  <a style="color: #2b436e;font-size: 13px"><b>{{Discount}}</b></a>
                </li>
                <li>
                  <a style="color: #2b436e;font-size: 13px;"><b>{{CouponDiscount}} </b></a><span title="{{ObjdiscountVoucher.VoucherDesc}}" *ngIf="PromoAmount" style="color: red;font-weight: 600;" (click)="removeCoupon()"> [X]</span>
                </li>
                 <li *ngIf="IsLoyaltySelected">
                  <a style="color: #2b436e;font-size: 13px;"  ><b>Reward Points</b></a><span style="color: red;font-weight: 600;" (click)="removeLoyalty()"> [X]</span>
                </li> 
                <li>
                  <a style="color: darkgray;font-size: 13px"><b>{{lblVAT}} ({{TaxRate}} %)</b></a>
                </li>
                <li style="width: 200px !important">
                  <a style="color: #2b436e;font-size: 13px"><b>{{lblTotal}}</b></a>
                </li>
              </ul>
            </div>
            <div class="col-md-6 col-sm-6 col-xs-6 mx-auto" style="padding: 0px;text-align: right;">
              <ul class="list-unstyled" style="margin-bottom: 0px;">
                <li>
                  <a style="color: #2b436e;font-size: 13px"><b>{{CurrencyCode}}&nbsp;&nbsp;{{Subtoal| number:'1.'+NumOfDecimal}}</b></a>
                </li>
                <li *ngIf="IsExtraCharge">
                  <a style="color: #2b436e;font-size: 13px"><b>{{CurrencyCode}}&nbsp;&nbsp;{{ExtraChargeAmount| number:'1.'+NumOfDecimal}}</b></a>
                </li>
                <li style="display: none;">
                  <a style="color: #2b436e;font-size: 13px"><b>{{CurrencyCode}}&nbsp;&nbsp;-{{DiscountAmont| number:'1.'+NumOfDecimal}}</b></a>
                </li>
                <li>
                  <a style="color: #2b436e;font-size: 13px"><b>{{CurrencyCode}}&nbsp;&nbsp;-{{PromoAmountforVisibility| number:'1.'+NumOfDecimal}}</b></a>
                </li>
                 <li *ngIf="IsLoyaltySelected">
                  <a style="color: #2b436e;font-size: 13px"><b>{{CurrencyCode}}&nbsp;&nbsp;-{{Consumed_LoyaltyAmount| number:'1.'+NumOfDecimal}}</b></a>
                </li> 
                <li>
                  <a style="color: darkgray;font-size: 13px"><b>{{CurrencyCode}}&nbsp;&nbsp;{{VAT| number:'1.'+NumOfDecimal}}</b></a>
                </li>
                <li>
                  <a style="color: #2b436e;font-size: 13px"><b>{{CurrencyCode}}&nbsp;&nbsp;{{Total| number:'1.'+NumOfDecimal}}</b></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- Footer Links -->

        <hr style="margin: 5px;">

        <!-- <ul class="list-unstyled list-inline text-center py-2">
          <li class="list-inline-item">
            <h5 class="mb-1">Register for free</h5>
          </li>
          <li class="list-inline-item">
            <a  class="btn btn-danger btn-rounded">Sign up!</a>
          </li>
        </ul>
        <hr> -->
        <!-- <span style="display: flex;justify-content: space-between;" class="pb-1"> -->
        <!--<div class="row" style="display: flex; justify-content: space-between">
          <div class="col-md-auto mb-1"><button *ngIf="IsKotButtonActive" class="btn btnfinish" (click)="KOTclick()" style="padding-right: 2px !important;padding-left: 7px !important; white-space: nowrap;overflow: hidden;text-overflow: ellipsis;     width: 92px !important;">{{KOT}}</button></div>
          <div class="col-md-auto mb-1"><button class="btn btnfinish" (click)="clearclick()" style="padding-right: 2px !important;padding-left: 7px !important; white-space: nowrap;overflow: hidden;text-overflow: ellipsis;     width: 92px !important;">{{CLEAR}}</button></div>
          <div class="col-md-auto mb-1"><button *ngIf="IsPayButtonActive" class="btn btnfinish" (click)="openfinishPopup()" style="padding-right: 2px !important;padding-left: 7px !important; white-space: nowrap;overflow: hidden;text-overflow: ellipsis;     width: 92px !important;">{{PAY}}</button></div>
          <div class="col-md-auto mb-1"><button *ngIf="IsKotButtonActive" class="btn btnfinish" style="padding-right: 2px !important;padding-left: 7px !important; white-space: nowrap;overflow: hidden;text-overflow: ellipsis;    width: 92px !important;" (click)="VoucherPopup()">Discount</button></div>
          <div class="col-md-auto mb-1"><button class="btn btnfinish" style="padding-right: 2px !important;padding-left: 7px !important; white-space: nowrap;overflow: hidden;text-overflow: ellipsis;     width: 92px !important;">Loyality</button></div>
          <div class="col-md-auto mb-1"><button *ngIf="IsPrintButtonDisplay" class="btn btnfinish" style="padding-right: 2px !important;padding-left: 7px !important; white-space: nowrap;overflow: hidden;text-overflow: ellipsis;     width: 92px !important;" (click)="KOTOrderPrint()">Print</button></div>
        </div>-->


      <div class="row">

        <div [ngClass]="[IsKotButtonActive == true ? 'divclassforvisibitly col-sm col-md col-lg' : 'displaynonebutton divclassforvisibitly col-sm col-md col-lg']"><button [ngClass]="[IsKotButtonActive == true ? 'buttoncss btn btnfinish' : 'displaynonebutton']" [disabled]="loading" (click)="KOTclick()">{{KOT}}</button></div>
        <div class="divclassforvisibitly col-sm col-md col-lg"><button class="buttoncss btn btnfinish" (click)="clearclick()">{{CLEAR}}</button></div>
        <div [ngClass]="[IsPayButtonActive == true ? 'divclassforvisibitly col-sm col-md col-lg' : 'displaynonebutton divclassforvisibitly col-sm col-md col-lg']"><button [ngClass]="[IsPayButtonActive == true ? 'buttoncss btn btnfinish' : 'displaynonebutton']" [disabled]="loading" (click)="openfinishPopup()">{{PAY}}</button></div>
        <div [ngClass]="[IsDisCountButtonActive == true ? 'divclassforvisibitly col-sm col-md col-lg' : 'displaynonebutton divclassforvisibitly col-sm col-md col-lg']"><button [ngClass]="[IsDisCountButtonActive == true ? 'buttoncss btn btnfinish' : 'displaynonebutton']" (click)="VoucherPopup()">{{Discount}}</button></div>
        <div [ngClass]="[IsLoyalityButtonActive == true ? 'divclassforvisibitly col-sm col-md col-lg' : 'displaynonebutton divclassforvisibitly col-sm col-md col-lg']"><button [ngClass]="[IsLoyalityButtonActive == true ? 'buttoncss btn btnfinish' : 'displaynonebutton']" (click)="LoyalityPopup()">{{Loyality}}</button></div>

        <div [ngClass]="[IsHoldOrderBtnActive == true ? 'divclassforvisibitly col-sm col-md col-lg' : 'displaynonebutton divclassforvisibitly col-sm col-md col-lg']"><button [ngClass]="[IsHoldOrderBtnActive == true ? 'buttoncss btn btnfinish' : 'displaynonebutton']" (click)="HoldOrderClick()">{{lblHoldOrder}}</button></div>
        <div [ngClass]="[IsCustomerBtnActive == true ? 'divclassforvisibitly col-sm col-md col-lg' : 'displaynonebutton divclassforvisibitly col-sm col-md col-lg']"><button [ngClass]="[IsCustomerBtnActive == true ? 'buttoncss btn btnfinish' : 'displaynonebutton']" (click)="openDialogCustomerLookUp()">{{Customer}}</button></div>

        <div [ngClass]="[IsRecallBtnActive == true ? 'divclassforvisibitly col-sm col-md col-lg' : 'displaynonebutton divclassforvisibitly col-sm col-md col-lg']"><button [ngClass]="[IsRecallBtnActive == true ? 'buttoncss btn btnfinish' : 'displaynonebutton']" (click)="OpenRecallPopup()">{{lblRecall}}</button></div>

        <div [ngClass]="[(IsPrintButtonActive == true && IsPrintButtonDisplay == true) ? 'divclassforvisibitly col-sm col-md col-lg' : 'displaynonebutton divclassforvisibitly col-sm col-md col-lg']"><button [ngClass]="[(IsPrintButtonActive == true && IsPrintButtonDisplay == true)  ? 'buttoncss btn btnfinish' : 'displaynonebutton']" (click)="KOTOrderPrint()">{{Print}}</button></div>
        <label style="color:Red; font-size: 13px;" *ngIf="printvalidation">{{YoucannotprinttheReceiptmorethen}} {{MaxKitchenReceiptPrintOut}} {{times}}</label>
      </div>
        

        <!-- </span> -->
        <!-- <div class="row" style="display: flex; justify-content: space-between" >
          <div class="col-4 " ><button *ngIf="IsKotButtonActive" class="btn btnfinish"  style="padding-right: 2px !important;padding-left: 7px !important; white-space: nowrap;overflow: hidden;text-overflow: ellipsis;    width: 92px !important;" (click)="VoucherPopup()">Discount</button></div>
          <div class="col-4 "><button class="btn btnfinish"  style="padding-right: 2px !important;padding-left: 7px !important; white-space: nowrap;overflow: hidden;text-overflow: ellipsis;     width: 92px !important;">Loyality</button></div>
          <div class="col-4 "><button *ngIf="IsPrintButtonDisplay" class="btn btnfinish"  style="padding-right: 2px !important;padding-left: 7px !important; white-space: nowrap;overflow: hidden;text-overflow: ellipsis;     width: 92px !important;" (click)="KOTOrderPrint()">Print</button></div>
        </div> -->
        <!--<div class="row" style="display: flex; justify-content: space-between" >

        </div>-->
        <!-- <ul class="list-unstyled list-inline text-center">
          <li class="list-inline-item">
            <a class="btn-floating btn-fb mx-1">
              <button class="btn btnfinish" (click)="KOTclick()">KOT</button>
            </a>
          </li>
          <li class="list-inline-item">
            <a class="btn-floating btn-tw mx-1">
              <button class="btn btnfinish" (click)="clearclick()">CLEAR</button>
            </a>
          </li>
          <li class="list-inline-item">
            <a class="btn-floating btn-tw mx-1">
              <button class="btn btnfinish" (click)="openfinishPopup()">PAY</button>
            </a>
          </li>
        </ul> -->

      </footer>

    </div>
    <iframe name="printf" #printfarme id="printf" [src]="ReceiptURL | Urlsafe" (load)="checkIframeLoaded()" width="0" height="0" frameborder="0"></iframe>
  </div>

</div>



<!--<div class="printme">
  <div id="invoice-POS" class="invoice-POS">

    <div id="top">
      <div class="logo" [ngStyle]="{'background': 'url(' + logo + ')', 'background-repeat': 'no-repeat', 'height':'70px', 'width':'300px', 'background-size': '200px 65px', 'background-position': 'center'}"></div>

    </div>

    <div id="mid">
      <div class="info" *ngIf="SelectedBranchName != undefined && SelectedBranchName != ''">
        <div class="hd">{{SelectedBranchName}}</div>-->
<!--<div class="mdatal">{{ObjOrderMaster.outletAddress}}</div>
<div class="mdatal">{{ObjOrderMaster.AreaName}}</div>-->
<!--</div>


<div class="info" style="border-top: 2px dotted #eee;" *ngIf="custName != undefined">
  <div class="mdatal" style="font-weight: 700">{{custName}}</div>



  cu-->
<!--<div class="mdatal">{{ObjOrderMaster.PhoneNo}}</div>

<div class="mdatal" *ngIf="ObjCurrency.CountryName != 'Pakistan'">{{ObjOrderMaster.Address}}</div>
<div style="border-bottom: 2px dotted #eee;" class="mdatal" *ngIf="ObjCurrency.CountryName == 'Pakistan'">{{ObjOrderMaster.AddressPK}}</div>-->
<!--</div>





<div style="display:flex" *ngIf="SelectedOrderInvoice != undefined && SelectedOrderInvoice != ''">
  <br />
  <div class="mdatal">Order No. :&nbsp;</div>
  <div class="mdatad">{{SelectedOrderInvoice}}</div>-->
<!--<div class="mrgn mdatal">Date :&nbsp;</div>
<div class="mdatad"> {{ObjOrderMaster.OrderDate}}</div>-->
<!--</div>


</div>

<div id="bot">


  <div id="table">
    <table>
      <thead>
        <tr>
          <th>Description</th>
          <th class="qty">Qty</th>
          <th>Amount</th>
        </tr>
      </thead>

      <tbody>
        <ng-container *ngFor="let row of lstBasket; let i = index">
          <tr>
            <td style="background-color:#fff!important">{{row.ProductName}}</td>
            <td style="background-color:#fff!important" class="qty">{{row.Quantity}}</td>
            <td style="background-color:#fff!important">{{row.Price|number:'1.'+NumOfDecimal}}</td>
          </tr>
          <tr *ngFor="let m of lstSelectModifier[i];">
            <td style="text-indent: 10px;background-color:#fff!important" *ngIf="m.IsSelected == true && m.ProductID == row.ProductID && row.rowid == m.rowid">{{m.Modifires}}</td>
            <td style="background-color:#fff!important"></td>
            <td style="background-color:#fff!important">{{m.PriceStatic| number:'1.'+NumOfDecimal}}</td>
          </tr>

        </ng-container>



      </tbody>
      <tfoot>
        <tr>
          <td colspan="1">Sub Total</td>
          <td>{{CurrencyCode}}</td>
          <td>{{Subtoal| number:'1.'+NumOfDecimal}}</td>
        </tr>
        <tr>
          <td colspan="1">Discount</td>
          <td>{{CurrencyCode}}</td>
          <td>{{DiscountAmont| number:'1.'+NumOfDecimal}}</td>
        </tr>
        <tr>
          <td colspan="1">Tax</td>
          <td>{{CurrencyCode}}</td>
          <td>{{VAT| number:'1.'+NumOfDecimal}}</td>
        </tr>
        /t-->
<!--<tr *ngIf="ObjOrderMaster.OrderType == 'Delivery'">
  <td colspan="1">Delivery Fee</td>
  <td>{{CurrencyCode}}</td>
  <td>{{Subtoal| number:'1.'+NumOfDecimal}}</td>
</tr>-->
<!--<tr>
              <td colspan="1">Net Amount</td>
              <td>{{CurrencyCode}}</td>
              <td>{{Total| number:'1.'+NumOfDecimal}}</td>
            </tr>
          </tfoot>
        </table>
      </div>

      <div id="legalcopy">
        <p class="legal">
          <strong>Thank you for shopping! come again</strong><br />

      </div>

    </div>
  </div>
</div>-->
