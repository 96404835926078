import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataserviseService } from '../../shared/dataservice';
import { UtilitiesService } from '../../shared/utilities.service';
import { PopupService } from '../../shared/popup';
import { RejectPopupComponent } from '../../pages/live/liveorder/rejectpopup/rejectpopup.component';
import { TranslatePipe } from '../../shared/pipe/translate.pipe';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'ngx-cateringpendingorderpopup',
  templateUrl: './cateringpendingorderpopup.component.html',
  styleUrls: ['./cateringpendingorderpopup.component.scss']
})
export class CateringpendingorderpopupComponent implements OnInit {

 
  constructor(private _popup: PopupService, private translate: TranslatePipe, private UtilitiesService: UtilitiesService, private popup: PopupService, private dataservice: DataserviseService, public dialog: MatDialog, public dialogRef: MatDialogRef<CateringpendingorderpopupComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public http: HttpClient) { }
  cultures: any;
  PendingOrders: any = this.translate.transform('PendingOrders');
  lblCancelOrder: any = this.translate.transform('CancelOrder');
  lblSelectOrder: any = this.translate.transform('SelectOrder');
  PrintOrder: any = this.translate.transform('PrintOrder');
  RePrintOrder: any = this.translate.transform('RePrintOrder');
  OrderNo: any = this.translate.transform('OrderNo.');
  OrderDate: any = this.translate.transform('OrderDate');
  OrderType: any = this.translate.transform('OrderType');
  TableNo: any = this.translate.transform('TableNo.');
  Customer: any = this.translate.transform('Customer');
  PhoneNo: any = this.translate.transform('PhoneNo');
  Qty: any = this.translate.transform('Qty');
  Description: any = this.translate.transform('Description');
  Amount: any = this.translate.transform('Amount');
  SubTotal: any = this.translate.transform('SubTotal');
  Discount: any = this.translate.transform('Discount');
  Tax: any = this.translate.transform('Tax');
  DeliveryFee: any = this.translate.transform('Delivery Fee');
  NetAmount: any = this.translate.transform('NetAmount');
  SpecialInstruction: any = this.translate.transform('SpecialInstruction');
  YoucannotprinttheReceiptmorethen: any = this.translate.transform('YoucannotprinttheReceiptmorethen');
  times: any = this.translate.transform('times');
  DayID: any;
  IsCancelOrderButtonActive: boolean = false;

  MaxReceiptPrintOut: any;
  MaxKitchenReceiptPrintOut: any;
  ReceiptPrintOutCount: any;
  KitchenReceiptPrintOutCount: any;

  ngOnInit(): void {  debugger

    if (sessionStorage.getItem('ObjCompany') != null && sessionStorage.getItem('ObjCompany') != undefined) {
      this.ObjCompany = JSON.parse(sessionStorage.getItem('ObjCompany')!);
    }
    if (localStorage.getItem('SelectedBranchRow' + this.UtilitiesService.getURLCompanyName()) != null && localStorage.getItem('SelectedBranchRow' + this.UtilitiesService.getURLCompanyName()) != undefined) {
      this.SelectedBrancRow = JSON.parse(localStorage.getItem('SelectedBranchRow' + this.UtilitiesService.getURLCompanyName())!);
      this.SelectedOutletID = this.SelectedBrancRow.ID;
    }

    if (localStorage.getItem('sessionObject' + this.UtilitiesService.getURLCompanyName()) != null && localStorage.getItem('sessionObject' + this.UtilitiesService.getURLCompanyName()) != undefined) {
      this.lstUser = JSON.parse(localStorage.getItem('sessionObject' + this.UtilitiesService.getURLCompanyName())!);
    }
    this.cultures = this.data["culture"];
    this.IsCancelOrderButtonActive = this.data["data"];
debugger
    this.DayID = this.data["DayID"];
    this.LoadGrid();
  }

  ObjCompany: Company = new Company();
  ObjOrderMaster: OrderMaster = new OrderMaster();
  ArrOrderDetail: OrderDetail[] = [];
  SearchInList: any = '';
  HighlightRow: any;
  CurrencyCode: any = '';
  SelectedBrancRow: any;
  SelectedOutletID: any;

  lstarray = [];
  lstPrinterserversetting = [];
  lstBasket: any = [];
  lstSelectModifier: any = [];
  OrderID: any;
  barertoken: any;

  btnclose(): void {
    debugger
    this.dialogRef.close();
  }
  LoadGrid() {
    debugger;
    this.lstarray = [];
    this.validationPrintReceipt = false;
    if (this.SelectedOutletID != undefined && this.SelectedOutletID != null) {
      this.dataservice.get('FoodCatering/GetCateringPendingOrders/' + this.UtilitiesService.getSubscriptionID() + '/' + this.SelectedOutletID + '/' + localStorage.getItem('LanguageCulture' + this.UtilitiesService.getURLCompanyName())).subscribe(data => {
        debugger;
        if (this.isArrayHasValue(data[0]["PendingOrders"])) {
          this.lstarray = data[0]["PendingOrders"];
          console.log(this.lstarray);
          this.getMasterDetailData(data[0]['PendingOrders'][0]["OrderID"], 0);
        }
        if (this.isArrayHasValue(data[0]["printerserversetting"])) {
          this.lstPrinterserversetting = data[0]["printerserversetting"];
        }

      });

    }
    else {
      alert('Please Select Outlet first.');
    }
  }
  win;
  KitchenRePrintClick() {
    debugger
    this.validationPrintReceipt = false;
    if (this.lstPrinterserversetting.length > 0) {
      if (this.lstPrinterserversetting[0]['IsKOTDirectPrint'] == 2) {
        if (this.ObjOrderMaster.IsMaxPolicyOn) {
          if (this.ObjOrderMaster.MaxKitchenReceiptPrintOut > this.ObjOrderMaster.KitchenReceiptPrintOutCount) {
            let link = this.lstPrinterserversetting[0]['ServerLink'] + '/Default?O=' + this.OrderID + '&C=' + this.UtilitiesService.getSubscriptionID() + '&type=KRePrint' + '&OutletID=' + this.ObjOrderMaster.OutletId + '&ShiftID=' + this.ObjOrderMaster.ShiftID;
            this.win = window.open(link, '_blank', 'width=300,height=200');
            this.ObjOrderMaster.KitchenReceiptPrintOutCount = parseInt(this.ObjOrderMaster.KitchenReceiptPrintOutCount) + 1;
          }
          else {
            this.validationPrintReceipt = true;
          }
        }
        else {
          let link = this.lstPrinterserversetting[0]['ServerLink'] + '/Default?O=' + this.OrderID + '&C=' + this.UtilitiesService.getSubscriptionID() + '&type=KRePrint' + '&OutletID=' + this.ObjOrderMaster.OutletId + '&ShiftID=' + this.ObjOrderMaster.ShiftID;
          this.win = window.open(link, '_blank', 'width=300,height=200');
        }
      }
      if (this.lstPrinterserversetting[0]['IsKOTDirectPrint'] == 3) {
        // this.ReceiptURL = this.UtilitiesService.getReceiptURL(this.UtilitiesService.getEncrypt(this.UtilitiesService.getCompanyName()), this.UtilitiesService.getEncrypt(this.OrderID), this.lstUser[0]['CountryCode']);
        this.ReceiptURL = this.UtilitiesService.getKitchenReceiptURL(this.UtilitiesService.getEncrypt(this.UtilitiesService.getCompanyName()), this.UtilitiesService.getEncrypt(this.OrderID), this.lstUser[0][0]['CountryCode']);
        this.checkIframeLoaded();
      }
    }
    else {
      // this.ReceiptURL = this.UtilitiesService.getReceiptURL(this.UtilitiesService.getEncrypt(this.UtilitiesService.getCompanyName()), this.UtilitiesService.getEncrypt(this.OrderID), this.lstUser[0]['CountryCode']);
      this.ReceiptURL = this.UtilitiesService.getKitchenReceiptURL(this.UtilitiesService.getEncrypt(this.UtilitiesService.getCompanyName()), this.UtilitiesService.getEncrypt(this.OrderID), this.lstUser[0][0]['CountryCode']);
      this.checkIframeLoaded();
    }
  }
  validationPrintReceipt: boolean = false;
  ReceiptRePrintClick() {
    debugger
    this.validationPrintReceipt = false;
    if (this.lstPrinterserversetting.length > 0) {
      if (this.lstPrinterserversetting[0]['IsReceiptDirectPrint'] == 2) {

        if (this.ObjOrderMaster.IsMaxPolicyOn) {
          if (this.ObjOrderMaster.MaxReceiptPrintOut > this.ObjOrderMaster.ReceiptPrintOutCount) {
            let link = this.lstPrinterserversetting[0]['ServerLink'] + '/Default?O=' + this.OrderID + '&C=' + this.UtilitiesService.getSubscriptionID() + '&type=ReceiptREPRINT' + '&OutletID=' + this.ObjOrderMaster.OutletId + '&ShiftID=' + this.ObjOrderMaster.ShiftID;
            this.win = window.open(link, '_blank', 'width=300,height=200');
            this.ObjOrderMaster.ReceiptPrintOutCount = parseInt(this.ObjOrderMaster.ReceiptPrintOutCount) + 1;

          }
          else {
            this.validationPrintReceipt = true;
          }
        }
        else {
          let link = this.lstPrinterserversetting[0]['ServerLink'] + '/Default?O=' + this.OrderID + '&C=' + this.UtilitiesService.getSubscriptionID() + '&type=ReceiptREPRINT' + '&OutletID=' + this.ObjOrderMaster.OutletId + '&ShiftID=' + this.ObjOrderMaster.ShiftID;
          this.win = window.open(link, '_blank', 'width=300,height=200');
        }


      }
      if (this.lstPrinterserversetting[0]['IsReceiptDirectPrint'] == 3) {
        // this.ReceiptURL = this.UtilitiesService.getReceiptURL(this.UtilitiesService.getEncrypt(this.UtilitiesService.getCompanyName()), this.UtilitiesService.getEncrypt(this.OrderID), this.lstUser[0][0]['CountryCode']);
        this.ReceiptURL = this.UtilitiesService.getDigiPosReceiptURL(this.UtilitiesService.getEncrypt(this.UtilitiesService.getCompanyName()), this.UtilitiesService.getEncrypt(this.OrderID), this.lstUser[0][0]['CountryCode']); 
        this.checkIframeLoaded();
      }
      if (this.lstPrinterserversetting[0]['IsReceiptDirectPrint'] == 4) {

        const url = this.UtilitiesService.geta4SizeReport(this.UtilitiesService.getEncrypt(this.UtilitiesService.getCompanyName()), this.UtilitiesService.getEncrypt(this.OrderID));
        this.ReceiptURL = url;
        this.checkIframeLoaded();


      }


    } else {
         // this.ReceiptURL = this.UtilitiesService.getReceiptURL(this.UtilitiesService.getEncrypt(this.UtilitiesService.getCompanyName()), this.UtilitiesService.getEncrypt(this.OrderID), this.lstUser[0][0]['CountryCode']);
         this.ReceiptURL = this.UtilitiesService.getDigiPosReceiptURL(this.UtilitiesService.getEncrypt(this.UtilitiesService.getCompanyName()), this.UtilitiesService.getEncrypt(this.OrderID), this.lstUser[0][0]['CountryCode']);  
      this.checkIframeLoaded();
    }


  }
  CancelOrderClickPrint() {
    debugger
    this.validationPrintReceipt = false;
    if (this.lstPrinterserversetting.length > 0) {
      if (this.lstPrinterserversetting[0]['IsReceiptDirectPrint'] == 2) {
        // if(this.ObjOrderMaster.ReceiptPrintOutCount < this.ObjOrderMaster.MaxReceiptPrintOut)
        // {
        let link = this.lstPrinterserversetting[0]['ServerLink'] + '/Default?O=' + this.OrderID + '&C=' + this.UtilitiesService.getSubscriptionID() + '&type=CANCELORDERPRINT' + '&OutletID=' + this.ObjOrderMaster.OutletId + '&ShiftID=' + this.ObjOrderMaster.ShiftID;
        this.win = window.open(link, '_blank', 'width=300,height=200');
       
      }
      if (this.lstPrinterserversetting[0]['IsReceiptDirectPrint'] == 3) {
          // this.ReceiptURL = this.UtilitiesService.getReceiptURL(this.UtilitiesService.getEncrypt(this.UtilitiesService.getCompanyName()), this.UtilitiesService.getEncrypt(this.OrderID), this.lstUser[0][0]['CountryCode']);
          this.ReceiptURL = this.UtilitiesService.getDigiPosReceiptURL(this.UtilitiesService.getEncrypt(this.UtilitiesService.getCompanyName()), this.UtilitiesService.getEncrypt(this.OrderID), this.lstUser[0][0]['CountryCode']);
        this.checkIframeLoaded();
      }
    }
    else {
         this.ReceiptURL = this.UtilitiesService.getDigiPosReceiptURL(this.UtilitiesService.getEncrypt(this.UtilitiesService.getCompanyName()), this.UtilitiesService.getEncrypt(this.OrderID), this.lstUser[0][0]['CountryCode']);  
      this.checkIframeLoaded();
    }
  }
  async checkIframeLoaded() {
    debugger
    // Get a handle to the iframe element
    var iframe = document.getElementById('orderreceipt') as HTMLIFrameElement;
    var iframeDoc = iframe.contentDocument || iframe.contentWindow.document;

    // Check if loading is complete
    if (iframeDoc.readyState == 'complete') {
      //iframe.contentWindow.alert("Hello");
      //await iframe.contentWindow.print();
      // The loading is complete, call the function we want executed once the iframe is loaded
      await this.onLoad();
      return false;
    }

    // If we are here, it is not loaded. Set things up so we check   the status again in 100 milliseconds
    window.setTimeout(this.checkIframeLoaded, 100);
  }
  onLoad() {
    if (this.ReceiptURL != '') {
      setTimeout(() => {
        window.frames["orderreceipt"].focus();
        window.frames["orderreceipt"].print();
      }, 1800);

    }
  }
  ReviewClick(event) {
    debugger
    this.OrderID = event.OrderID;
    this.getorderdetails(event.OrderID)
  }

  SelectOrder(): void {
    debugger
    if( this.ObjOrderMaster.OrderType == 'Dine In' && this.chkAllow || this.ObjOrderMaster.UserId == this.UtilitiesService.getUserId())
    {
      if (this.OrderID != undefined && this.OrderID != null && this.OrderID != '') {
        this.dialogRef.close(this.OrderID);
      }
      else {
        alert('Please select Order first.');
      }  
    }
    else if(this.ObjOrderMaster.OrderType != 'Dine In')
    {
      if (this.OrderID != undefined && this.OrderID != null && this.OrderID != '') {
        this.dialogRef.close(this.OrderID);
      }
      else {
        alert('Please select Order first.');
      }
    }
    else
    {
      alert('You can not update this order, please contact your admin.')
    }
    
  }

  CancelOrder(): void {
    if (this.OrderID != undefined && this.OrderID != null && this.OrderID != '') {
      this.ObjOrderMaster.OrderPerpStatus = 'Cancel';
      this.ObjOrderMaster.Channel = 'POSRestaurants';

      let data = this.ObjOrderMaster;

      let dialogpopup = this.dialog.open(RejectPopupComponent, {
        width: '700px',

        data: { data },
      })

      dialogpopup.afterClosed().subscribe(result => {
        debugger

        if (result != undefined && result != 'SUCCESS') {
          this.popup.ShowPopup('Update')
          this.LoadGrid();
        }
        if (result == 'SUCCESS') {
          this.dialogRef.close(result);
          this.CancelOrderClickPrint();

        }
      });

    }
    else {
      alert('Please select order first.');
    }
  }
  async GetAccessToken() {
    const headers = { 'accept': 'application/json', 'content-type': 'application/json' };
    const body = `{\"audience\": \"https://api.staging.deliverect.com\",\"grant_type\": \"token\",\"client_id\": \"5aaCR7XQeWX1Oejy\",\"client_secret\": \"wZVaEJ9dKPi0A7TmKUQjorAwHCJEBR9I\"}`;
    await this.http.post<any>('https://api.staging.deliverect.com/oauth/token', body, { headers }).subscribe(data => {
      debugger
      this.barertoken = data["access_token"];
      //this.UpdateOrderStatus();
    });

  }
  async UpdateOrderStatus() {
    /*POST REQUEST*/
    const headers = { 'accept': 'application/json', 'content-type': 'application/json', 'authorization': 'Bearer ' + this.barertoken };
    debugger
    const body = "{\"orderId\":\"" + this.ObjOrderMaster.DeliverectOrderId + "\",\"status\":110,\"reason\":\"cancellation\",\"timeStamp\":\"2019-09-05 07:44:15.000Z\",\"receiptId\":0}";
    await this.http.post<any>('https://api.staging.deliverect.com/orderStatus/orderId', body, { headers }).subscribe(data => {
      console.log('updated data in delivercet' + data);
    });
  }
 
  getorderdetails(orderid: any) {
    debugger

    this.dataservice.get('pos/GetOrdersdetails/' + this.UtilitiesService.getSubscriptionID() + '/' + orderid).subscribe(data => {
      debugger
      if (this.isArrayHasValue(data[0]['Ordersdetails'])) {
        this.lstBasket = data[0]['Ordersdetails'];
      }
      if (this.isArrayHasValue(data[0]['OrdersModifiers'])) {

        this.lstSelectModifier = [];

        let lst = data[0]['OrdersModifiers'];
        for (let i = 0; i < this.lstBasket.length; i++) {
          let row = lst.filter(t => t.ProductID == this.lstBasket[i].ProductID);
          this.lstSelectModifier.push(row);
        }
      }

    });
  }

  RefreshOrders(): void {

    this.LoadGrid();
  }

  OrderIDMaster: any;
  OrderIDIndex: any;
  chkDinerights : any = [];
  chkAllow : boolean = false;
  getMasterDetailData(OrderID, index): void {
    this.OrderIDMaster = OrderID;
    this.OrderIDIndex = index;
    this.validationPrintReceipt = false;
    this.HighlightRow = index;
    this.ObjOrderMaster = new OrderMaster();
    this.OrderID = OrderID;

    this.dataservice.get('FoodCatering/GetCateringPendingOrderDetail/' + this.UtilitiesService.getSubscriptionID() + '/' + OrderID + '/' + localStorage.getItem('LanguageCulture' + this.UtilitiesService.getURLCompanyName())+'/'+this.UtilitiesService.getUserId())
      .subscribe(data => {

        debugger
        if (data[0]['Master']) {
          this.ObjOrderMaster = data[0]['Master'][0];
        }
        if (this.isArrayHasValue(data[0]['Details'])) {
          this.ArrOrderDetail = data[0]['Details'];
        }
        else {
          this.ArrOrderDetail = [];
        }
        if (this.isArrayHasValue(data[0]['chkDinerights'])) {
          this.chkDinerights = data[0]['chkDinerights']; 
          this.chkAllow = this.chkDinerights[0]['Allow'];      
        }
        this.getReceipt();

      }, error => alert('error'));
  }
  ReceiptURL: any = '';
  lstUser = [];
  getReceipt(): void {


    const CompanyName = this.UtilitiesService.getCompanyName();
      // const url = this.UtilitiesService.getReceiptURL(this.UtilitiesService.getEncrypt(CompanyName), this.UtilitiesService.getEncrypt(this.ObjOrderMaster.OrderID), this.lstUser[0][0]['CountryCode']);
      const url = this.UtilitiesService.getDigiPosReceiptURL(this.UtilitiesService.getEncrypt(this.UtilitiesService.getCompanyName()), this.UtilitiesService.getEncrypt(this.OrderID), this.lstUser[0][0]['CountryCode']);    
    this.ReceiptURL = url;
  }


  isArrayHasValue(obj: any[]): boolean {
    let isArrayWithLength = Boolean(Array.isArray(obj) && obj.length);
    return isArrayWithLength;
  }




}
class OrderMaster {
  OrderID: any;
  TableNumber: any;
  OrderInvoiceNo: any = '';
  PrepIn: any = '';
  PrepInOutlet: any = '';
  OrderPerpStatus: any = 'ALL';
  Brand: any = '';
  OutletID: any = '';
  OutletId: any = '';
  OutletName: any = '';
  OrderType: any = '';
  Address: any = '';
  CustomerID: any = '';
  CustomerName: any = '';
  CustomerEmail: any = '';
  CountryCode: any = '';
  PhoneNo: any = '';
  PayType: any = '';
  Channel: any = '';
  PickupFrom: any = '';
  Remarks: any = '';
  SubTotal: any = '';
  TotalTaxAmount: any = '';
  TotalAmount: any = '';
  DeliveryFee: any = '';
  NetAmount: any = '';
  TotalDiscount: any = '';
  TaxID: any = '';
  TaxDescription: any = '';
  TaxPercent: any = '';
  OrderDate: any;
  OrderDateReal: any;
  CreatedOn: any;
  CreatedBy: any;
  ModifyOn: any;
  ModifyBy: any;
  RejectReasonID: any;
  RejectReason: any;
  RiderID: any;
  CompanyId: any;
  RejectReasonDetail: any = '';
  AddressPK: any = '';
  ShiftID: any;
  IsExtraCharge: any;

  MaxReceiptPrintOut: any;
  MaxKitchenReceiptPrintOut: any;
  ReceiptPrintOutCount: any;
  KitchenReceiptPrintOutCount: any;
  IsMaxPolicyOn: boolean;
  DeliverectOrderId: any;
  OtherChannel: any;
  UserId : any;
}
class OrderDetail {
  OrderDetailID: any;
  OrderID: any;
  ProductId: any;
  ProductName: any = '';
  Quantity: any;
  UnitID: any;
  AddOnID: any;
  AddOnName: any;
}

class Company {
  CompanyID: any;
  CompanyName: any;
  outletsCount: any;
  CountryID: any;
  hearbout: any;
  Mobile: any;
  CurrencyCode: any;
  Rate: any;
  NumOfDecimal: any;
  CountryCode: any;
  IsOTPRequired: boolean;
}
