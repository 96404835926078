<!-- ************************* 1st Work Comment ******************************** -->
<!-- <nb-card>


  <nb-card-body> -->
<!-- <div class="row">
  <div class="col-md-12 col-sm-12">
    <img src="./assets/images/lookupclose.png" (click)="dialogRef.close()" class="cross-icon" style="float: right; height: 30px;" />
    <div class="form-group">
      <h5>Pending Orders</h5>
      <div class="table-responsive" style="width:100%;">
        <table class="table table-bordered table-striped">

          <thead>
            <tr>

              <th class="gridheader">Order No</th>
              <th class="gridheader">Order Time</th>
              <th class="gridheader">Waiter</th>
              <th class="gridheader">Table</th>
              <th class="gridheader">Order Type</th>
              <th class="gridheader">Review</th>
            </tr>
          </thead>
          <tbody>

            <tr  *ngFor="let data of lstarray" >
              <td class="griddata">{{data.OrderInvoiceNo}}</td>
              <td class="griddata">{{data.OrderDate}}</td>
              <td class="griddata">{{data.Waiter}}</td>
              <td class="griddata">{{data.TableNumber}}</td>
              <td class="griddata">{{data.OrderType}}</td>
              <td >
                <button  style="width:100px;   background-color: #2b436e !important;color: #05a9e6 !important;    width: 100px !important;  font-size: 0.875rem !important;    padding: 0.6875rem 1.125rem !important;   border: none !important;   border-radius: 0.25rem !important;   border-color: #2b436e !important; font-weight: 700 !important;" (click)="ReviewClick(data)" >Review</button>
              </td>
            </tr>
            <tr *ngIf="lstarray.length < 1">
              <td colspan="10" style="text-align:center;font-size:12px;">No data</td>
            </tr>
          </tbody>
        </table>

      </div>
      <br />
    </div>

  </div>
</div> -->
<!--
    </nb-card-body>
    </nb-card> -->
<!-- ************************* 1st Work Comment ******************************** -->
<!-- *****************************2nd Work commment **************************************************** -->
<!-- <div class="col-md-12 col-sm-12">

     <div class="form-group">
   <div class="child1">Pending Orders</div>
   <div class="row" style="height: 5px;"></div>



   <div class="row">
     <div class="col-md-5">

       <div class="row">

         <div class="col-md-3">
           <button  style="width:100px;   background-color: #2b436e !important;color: #05a9e6 !important;    width: 100px !important;  font-size: 0.875rem !important;  height: 35px;    border: none !important;   border-radius: 0.25rem !important;   border-color: #2b436e !important; font-weight: 700 !important;" (click)="SelectOrder()" >Select Order</button>
         </div>
         <div class="col-md-3">
           <button  style="width:100px;   background-color: #2b436e !important;color: #05a9e6 !important;    width: 100px !important;  font-size: 0.875rem !important;  height: 35px;    border: none !important;   border-radius: 0.25rem !important;   border-color: #2b436e !important; font-weight: 700 !important;" (click)="CancelOrder()" >Cancel Order</button>
         </div>
         <div class="col-md-3">
           <button  style="width:100px;   background-color: #2b436e !important;color: #05a9e6 !important;    width: 100px !important;  font-size: 0.875rem !important;  height: 35px;    border: none !important;   border-radius: 0.25rem !important;   border-color: #2b436e !important; font-weight: 700 !important;"  >Print order</button>
         </div>


       </div>

       <div class="table-responsive" style="padding-top: 7px;">
         <table class="table table-bordered table-striped">

           <thead>
             <tr>

               <th class="gridheader">Order ID</th>
               <th class="gridheader">Product Name</th>
               <th class="gridheader">Amount</th>
             </tr>
           </thead>
           <tbody>

             <tr  *ngFor="let data of lstBasket; let i = index" >
               <td class="griddata Profontzize" >{{data.OrderInvoiceNo}}</td>
               <td class="griddata Profontzize">
                 {{data.ProductName}}

                 <div *ngFor="let row of lstSelectModifier[i]">

                   <div class="MOdfontzize" style="color: #848484; padding-left: 10px;" >
                     {{row.Modifires}}
                   </div>

                 </div>


               </td>
               <td class="griddata Profontzize">
                 {{data.Amount}}

               </td>
             </tr>
             <tr *ngIf="lstBasket.length < 1">
               <td colspan="10" style="text-align:center;font-size:12px;">No data</td>
             </tr>
           </tbody>
         </table>
       </div>

     </div>

     <div class="col-md-7">

       <div class="table-responsive" >
         <table class="table table-bordered table-striped">

           <thead>
             <tr>

               <th class="gridheader">Order No</th>
               <th class="gridheader">Order Time</th>
               <th class="gridheader">Waiter</th>
               <th class="gridheader">Table</th>
               <th class="gridheader">Order Type</th>
               <th class="gridheader">Review</th>
             </tr>
           </thead>
           <tbody>

             <tr  *ngFor="let data of lstarray" >
               <td class="griddata">{{data.OrderInvoiceNo}}</td>
               <td class="griddata">{{data.OrderDate}}</td>
               <td class="griddata">{{data.Waiter}}</td>
               <td class="griddata">{{data.TableNumber}}</td>
               <td class="griddata">{{data.OrderType}}</td>
               <td >
                 <button  style="width:100px;   background-color: #2b436e !important;color: #05a9e6 !important;    width: 100px !important;  font-size: 0.875rem !important;    padding: 0.6875rem 1.125rem !important;   border: none !important;   border-radius: 0.25rem !important;   border-color: #2b436e !important; font-weight: 700 !important;" (click)="ReviewClick(data)" >Review</button>
               </td>
             </tr>
             <tr *ngIf="lstarray.length < 1">
               <td colspan="10" style="text-align:center;font-size:12px;">No data</td>
             </tr>
           </tbody>
         </table>
       </div>


     </div>


   </div>




-->
<!-- ***************************** 2nd Work commment **************************************************** -->
<!-- ***************************** 3rd Work Start **************************************************** -->



<link href="//stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet">

<nb-card>


  <nb-card-body>


    <div class="child1" style="max-width: 100% !important ;">
      {{PendingOrders}}
      <img src="./assets/images/lookupclose.png" (click)="btnclose()" style="float: right; width: 25px;cursor: pointer;">
    </div>

    <hr style="margin-top: 10px;" />

    <div class="row col-lg-12 col-md-12 col-xs-12 col-sm-12">

      <div class="col-lg-4 col-md-4 col-xs-4 col-sm-4 HideOnMobile" style="background-color: #EFF4F9;min-height: 30px ; border-right:1px solid lightgray; ">
        <div class="row" style="margin-bottom: 5px;">
          <!-- display: flex;align-items: center; -->
          <!-- <label class="label" style="padding-left: 15px;font-size: 14px !important;font-weight: 700 !important;margin-bottom: 0px;">Order Type</label> -->
          <nb-select [(selected)]="SearchInListSalesType" style="max-width: 100%;width: 100%;" [(ngModel)]="SearchInListSalesType" (ngModelChange)="OnSalesTypeChange()">
            <nb-option value="0">All</nb-option>
            <nb-option *ngFor="let option of lstSalesType" [value]="option.Type">{{option.Type}}</nb-option>
          </nb-select>
        </div>
        <div class="row">
          <input type="text" nbInput fullWidth status="basic" [(ngModel)]="SearchInList" fieldSize="medium" name="search" style=" border-radius: unset;background-color: #EFF4F9" placeholder="Search...">

        </div>
        <div class="row" style="overflow-x: auto; max-height: 430px;">
          <div id="orders" style="background-color: white;width:100%">

            <div *ngFor="let data of lstarray | filter:SearchInList;let i = index" class="Order-list" [ngClass]="{'selected': i === HighlightRow}">
              <div class="row form-group col-lg-12 col-md-12 col-xs-12 col-sm-12" (click)="getMasterDetailData(data.OrderID,i)" style="cursor: pointer">
                <div class="form-group col-lg-12 col-md-12 col-xs-12 col-sm-12">

                  <div class="row">
                    <div class="col1">
                      <p class="numberorderNo mb-1" style="color: #292929"><strong>{{data.OrderInvoiceNo}}</strong></p>
                      <small *ngIf="data.OtherChannel != null" style="font-size: 14px; font-weight:700;float: right;" class="Takeawaycolor">{{data.OtherChannel}}</small>
                      <small style="display:block!important;font-weight:500;font-size:58%!important"><span style="font-size: 15px;font-weight: 700;" [ngClass]="{'Deliverycolor' : data.OrderTypeID == '3','Takeawaycolor' : data.OrderTypeID == '2','NAcolor' : data.OrderTypeID == 'N/A','DineColor' : data.OrderTypeID == '1'}">{{data.OrderType}} <span *ngIf="data.TableNumber">({{data.TableNumber}})</span></span></small>
                      <small *ngIf="data.OtherChannel != null " style="font-weight:700;font-size:10px !important;float: right;">{{data.DeliverectIsAlreadyPaid && data.DeliverectIsOrderCancel ? 'Cancel Order' : data.DeliverectIsAlreadyPaid ? 'Paid Order' : 'Unpaid Order' }}</small>
                      <small style="display:block!important;font-weight:500;font-size:58%!important">{{data.OrderDate }}</small>

                      <small style="display:block!important;font-weight:500;font-size:58%!important">{{data.Status}}</small>
                      <!-- <small style="display:block!important;font-weight:600;font-size:90%!important">{{data.OrderType}}</small> -->
                      <small style="display:block!important;font-weight:600;font-size:70%!important">Total: {{ObjCompany[0]["CurrencyCode"]}} {{data.NetAmount}}</small>
                      <small style="display:block!important;font-weight:600;font-size:70%!important">Counter: {{CounterName}}</small>
                      <small style="display:block!important;font-weight:600;font-size:65%!important" *ngIf="data.PhoneNo">Phone No.: {{data.PhoneNo}}</small>
                    </div>
                    <div class="col2" *ngIf="ActiveTabInKitchen">
                      <div class="labelPrep">Prep In</div>
                      <div class="blink_me numberCircle">
                        <span class="numberPrep">{{ data.PrepIn | mindiff: data.ModifyOn : data.PrepIn }}</span>
                        <span class="Prep">MIN</span>
                      </div>

                    </div>
                    <!-- <div class="col2" *ngIf="ActiveTabNew">
                      <div class="labelPrep"></div>
                      <div style="text-align:right">
                        <label class="label"></label>
                        <span class="dot"></span>
                      </div>
                    </div> -->
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>
        <!-- <div class="row">
          <br><br>
          <button nbButton fullWidth status="primary"  (click)="RefreshOrders()"  style="position:absolute;bottom:1px;width:95%">Refresh</button>
        </div> -->
      </div>

      <div class="col-lg-8 col-md-8 col-xs-8 col-sm-8">
        <div class="HRVisible col-lg-12 col-md-12 col-xs-12 col-sm-12">
          <div class="row">
            <div class="orderCard__header px-3 col-lg-12 col-md-12 col-xs-12 col-sm-12" style="margin-top:-5px;">



              <div class="row ">
                <div class="form-group col-lg-3 col-md-3 col-xs-3 col-sm-3 ">
                  <button *ngIf="!ObjOrderMaster.DeliverectIsOrderCancel" nbButton fullWidth status="danger" class="float-right" (click)="SelectOrder()">{{lblSelectOrder}}</button>
                </div>
                <div class="col-md-3" *ngIf="IsCancelOrderButtonActive">
                  <button nbButton fullWidth status="success" class="float-right" (click)="CancelOrder()">{{lblCancelOrder}}</button>
                </div>
                <div class="col-md-3" *ngIf="IsPendingOrderPrintReciept">
                  <!-- <button nbButton fullWidth class="label clsB1" (click)="getReceipt()" style="border:0">Print order</button> -->
                  <span style=" display: inline-block !important;text-align: center !important; white-space: nowrap !important; vertical-align: baseline !important; border-radius: 0.25rem !important; background-color: #355692; color: #fff;height: 38px; padding-top: 9px !important; border: 0;  cursor: pointer; width: 100%;" (click)="ReceiptRePrintClick()">{{PrintOrder}} <i class="fa fa-print"></i></span>
                </div>
                <div class="col-md-3" *ngIf="IsPendingOrderKitchenPrint">
                  <span style=" display: inline-block !important;text-align: center !important; white-space: nowrap !important; vertical-align: baseline !important; border-radius: 0.25rem !important; background-color: #355692; color: #fff;height: 38px; padding-top: 9px !important; border: 0;  cursor: pointer; width: 135px;" (click)="KitchenRePrintClick()">{{RePrintOrder}} <i class="fa fa-print"></i></span>
                </div>
                <p style="color:red;" *ngIf="validationPrintReceipt">{{YoucannotprinttheReceiptmorethen}} {{ObjOrderMaster.MaxReceiptPrintOut}} {{times}}. </p>
                <iframe name="orderreceipt" #printfarme id="orderreceipt" [src]="ReceiptURL | Urlsafe" width="0" height="0" frameborder="0"></iframe>
              </div>



              <!-- <span class="clsB3 badge badge-lg p-2 f-size-11 badge-accepted" style="height: 30px;padding-top: 9px!important" [ngClass]="{'badge-acceptedNew':CustomBadgeText == 'New','badge-acceptedKitchen':CustomBadgeText == 'In Kitchen','badge-acceptedInRoute':CustomBadgeText == 'In Route/Ready'}" *ngIf="CustomBadgeText != '' && CustomBadgeText != undefined">{{CustomBadgeText}}</span> -->


            </div>

          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
          <div class="row">


            <div class="clsM2 form-group col-lg-4 col-md-4 col-xs-4 col-sm-4">
              <div class="algStyle">
                <label class="label">{{OrderNo}}</label>

              </div>
              <div class="algStyle">
                <label class="label"><strong>{{ObjOrderMaster.OrderInvoiceNo}}</strong></label>
              </div>
            </div>
            <div class="clsM2 form-group col-lg-4 col-md-4 col-xs-4 col-sm-4">
              <div class="algStyle">
                <label class="label">{{OrderDate}}</label>

              </div>
              <div class="algStyle">
                <label class="label"><strong>{{ObjOrderMaster.OrderDate }}</strong></label>
              </div>
            </div>

            <div class="clsM1 form-group col-lg-4 col-md-4 col-xs-4 col-sm-4">
              <div class="">
                <label class="label">{{OrderType}}</label>
              </div>
              <div class="">
                <label class="label"><strong>{{ObjOrderMaster.OrderType}}</strong></label>
              </div>
            </div>
            <div class="clsM2 form-group col-lg-4 col-md-4 col-xs-4 col-sm-4">
              <div class="algStyle">
                <label class="label">{{TableNo}}</label>

              </div>
              <div class="algStyle">
                <label class="label"><strong>{{ObjOrderMaster.TableNumber}}</strong></label>
              </div>
            </div>

            <div class="clsM1 form-group col-lg-4 col-md-4 col-xs-4 col-sm-4">
              <div class="">
                <label class="label">{{Customer}}</label>
              </div>
              <div class="">
                <label class="label"><strong>{{ObjOrderMaster.CustomerName}}</strong></label>
              </div>
            </div>
            <div class="clsM2 form-group col-lg-4 col-md-4 col-xs-4 col-sm-4">
              <div class="algStyle">
                <label class="label">{{PhoneNo}}</label>

              </div>
              <div>
                <label class="label"><strong>{{ObjOrderMaster.PhoneNo}}</strong></label>
              </div>
            </div>
            <div *ngIf="ObjOrderMaster.Remarks" class="clsM1 form-group col-lg-4 col-md-4 col-xs-4 col-sm-4">
              <div>
                <label class="label">{{Remarks}}</label>
              </div>
              <div class="">
                <label class="label"><strong>{{ObjOrderMaster.Remarks}}</strong></label>
              </div>
            </div>

          </div>
        </div>
        <div class="form-group col-lg-12 col-md-12 col-xs-12 col-sm-12">
          <div class="row">
            <div class="cls1 col-lg-1 col-md-1 col-xs-1 col-sm-1">
              <label class="label">{{Qty}}</label>
            </div>
            <div class="cls2 col-lg-3 col-md-3 col-xs-3 col-sm-3">
              <label class="label">{{Description}}</label>
            </div>
            <div class="cls2 col-lg-5 col-md-5 col-xs-5 col-sm-5">
              <label class="label">{{SpecialInstruction}}</label>
            </div>
            <div class="cls3 col-lg-3 col-md-3 col-xs-3 col-sm-3">
              <label class="label">{{Amount}}</label>
            </div>

          </div>
          <hr class="hrdivider" />
          <div *ngFor="let dod of ArrOrderDetail" [ngClass]="[dod.DelFlag == 1 ? 'padd_btm_background row' : 'row']">
            <div class="cls1 col-lg-1 col-md-1 col-xs-1 col-sm-1">
              <label class="label" *ngIf="dod.AddOnID == 0">{{dod.Quantity}}</label>
            </div>
            <div class="cls2 col-lg-3 col-md-3 col-xs-3 col-sm-3" *ngIf="dod.AddOnID == 0">
              <label class="label" [ngClass]="[dod.ComboProductID != null ? 'ComboColor' : '#434444']">{{cultures == 'en' ? dod.ProductName : dod.AProductName}} <span *ngIf="dod.ProductSizeName || dod.AProductSizeName">({{cultures == 'en' ? dod.ProductSizeName : dod.AProductSizeName}})</span></label>
            </div>
            <div class="cls2 col-lg-3 col-md-3 col-xs-3 col-sm-3" *ngIf="dod.AddOnID != 0">
              <label class="label" style="color:#797979!important">{{cultures == 'en' ? dod.AddOnName : dod.ArabicAddOnName }}</label>
            </div>
            <div class="cls2 col-lg-5 col-md-5 col-xs-5 col-sm-5" *ngIf="dod.AddOnID == 0">
              <label class="label">{{dod.SpecialRequest}}</label>
            </div>
            <div class="cls3 col-lg-3 col-md-3 col-xs-3 col-sm-3">

              <label class="label" style="text-align: left;" *ngIf="dod.Amount != undefined && dod.Amount != ''">{{ObjCompany[0]["CurrencyCode"]}}</label>
              <label class="label" style="text-align: right !important;width: 66%;">{{dod.Amount|number:'1.3-3'}}</label>

              <!-- <label class="label" [ngStyle]="{'color':dod.AddOnID != 0 ? '#7d7b7b' : '#444' }" *ngIf="dod.Amount != undefined && dod.Amount != ''">
                {{ObjCompany[0]["CurrencyCode"]}}&nbsp;{{dod.Amount|number:'1.3-3'}}
              </label> -->
            </div>
          </div>
        </div>
        <div class="form-group">
          <hr class="hrdivider" />
        </div>
        <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">



          <div class="row">
            <div class="cls1 col-lg-6 col-md-6 col-xs-6 col-sm-6">
            </div>
            <div class="cls2 col-lg-3 col-md-3 col-xs-3 col-sm-3">
              <label class="label">{{Discount}}:</label>
            </div>
            <div class="cls3 col-lg-3 col-md-3 col-xs-3 col-sm-3">


              <label class="label" style="text-align: left;">{{ObjCompany[0]["CurrencyCode"]}}</label>
              <label class="label" style="text-align: right !important;width: 66%;">{{ObjOrderMaster.TotalDiscount|number:'1.3-3'}}</label>
              <!-- <label class="label">{{ObjCompany[0]["CurrencyCode"]}}&nbsp;{{ObjOrderMaster.TotalDiscount|number:'1.3-3'}}</label> -->
            </div>

          </div>
          <div class="row">
            <div class="cls1 col-lg-6 col-md-6 col-xs-6 col-sm-6">

            </div>
            <div class="cls2 col-lg-3 col-md-3 col-xs-3 col-sm-3">
              <label class="label">{{SubTotal}}:</label>
            </div>
            <div class="cls3 col-lg-3 col-md-3 col-xs-3 col-sm-3">

              <label class="label" style="text-align: left;">{{ObjCompany[0]["CurrencyCode"]}}</label>
              <label class="label" style="text-align: right !important;width: 66%;">{{ObjOrderMaster.TotalAmount|number:'1.3-3'}}</label>

              <!-- <label class="label">{{ObjCompany[0]["CurrencyCode"]}}&nbsp;{{ObjOrderMaster.TotalAmount|number:'1.3-3'}}</label> -->
            </div>

          </div>
          <div *ngIf="ObjOrderMaster.IsExtraCharge == 1">
            <div class="row">
              <div class="cls1 col-lg-6 col-md-6 col-xs-6 col-sm-6">
              </div>
              <div class="cls2 col-lg-3 col-md-3 col-xs-3 col-sm-3">
                <label class="label">{{ObjOrderMaster.ExtraChargeDescription}}:</label>
              </div>
              <div class="cls3 col-lg-3 col-md-3 col-xs-3 col-sm-3">
                <label class="label" style="text-align: left;">{{ObjCompany[0]["CurrencyCode"]}}</label>
                <label class="label" style="text-align: right !important;width: 66%;">{{ObjOrderMaster.ExtraChargeAmount|number:'1.3-3'}}</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="cls1 col-lg-6 col-md-6 col-xs-6 col-sm-6">
            </div>
            <div class="cls2 col-lg-3 col-md-3 col-xs-3 col-sm-3">
              <label class="label">{{Tax}}:</label>
            </div>
            <div class="cls3 col-lg-3 col-md-3 col-xs-3 col-sm-3">

              <label class="label" style="text-align: left;">{{ObjCompany[0]["CurrencyCode"]}}</label>
              <label class="label" style="text-align: right !important;width: 66%;">{{ObjOrderMaster.TotalTaxAmount|number:'1.3-3'}}</label>

              <!-- <label class="label">{{ObjCompany[0]["CurrencyCode"]}}&nbsp;{{ObjOrderMaster.TotalTaxAmount|number:'1.3-3'}}</label> -->
            </div>
          </div>
          <div class="row" *ngIf="ObjOrderMaster.OrderType == 'Delivery'">
            <div class="cls1 col-lg-6 col-md-6 col-xs-6 col-sm-6">
            </div>
            <div class="cls2 col-lg-3 col-md-3 col-xs-3 col-sm-3">
              <label class="label">{{DeliveryFee}}:</label>
            </div>
            <div class="cls3 col-lg-3 col-md-3 col-xs-3 col-sm-3">

              <label class="label" style="text-align: left;">{{ObjCompany[0]["CurrencyCode"]}}</label>
              <label class="label" style="text-align: right !important;width: 66%;">{{ObjOrderMaster.DeliveryFee|number:'1.3-3'}}</label>

              <!-- <label class="label">{{ObjCompany[0]["CurrencyCode"]}}&nbsp;{{ObjOrderMaster.DeliveryFee|number:'1.3-3'}}</label> -->
            </div>
          </div>
          <div class="row">
            <div class="cls1 col-lg-6 col-md-6 col-xs-6 col-sm-6">
            </div>
            <div class="cls2 col-lg-3 col-md-3 col-xs-3 col-sm-3">
              <label class="label"><strong>{{NetAmount}}:</strong></label>
            </div>
            <div class="cls3 col-lg-3 col-md-3 col-xs-3 col-sm-3">

              <label class="label" style="text-align: left;">{{ObjCompany[0]["CurrencyCode"]}}</label>
              <label class="label" style="text-align: right !important;width: 66%;">{{ObjOrderMaster.NetAmount|number:'1.3-3'}}</label>

              <!-- <label class="label"><strong>{{ObjCompany[0]["CurrencyCode"]}}&nbsp;{{ObjOrderMaster.NetAmount|number:'1.3-3'}}</strong></label> -->
            </div>

          </div>

        </div>



        <!-- <div *ngIf="ActiveTabNew" class="form-group col-lg-12 col-md-12 col-xs-12 col-sm-12" style="margin-bottom: auto;">

          <div class="row">
            <div class="form-group col-lg-6 col-md-6 col-xs-6 col-sm-6">
              <button nbButton fullWidth status="danger" (click)="RejectOrder('REJECTED')">Reject</button>
            </div>

            <div class="col-lg-6 col-md-6 col-xs-6 col-sm-6">
              <button nbButton fullWidth status="success" (click)="openPopup()">Accept</button>
            </div>

          </div>
        </div>


        <div *ngIf="ActiveTabInKitchen" class="form-group form-group col-lg-12 col-md-12 col-xs-12 col-sm-12" style="margin-bottom: -5px;">

          <div class="row">

            <div class="form-group col-lg-6 col-md-6 col-xs-6 col-sm-6">
              <button nbButton fullWidth status="danger" (click)="RejectOrder('Cancel')">Cancel</button>
            </div>
            <div class="col-lg-6 col-md-6 col-xs-6 col-sm-6">
              <button nbButton fullWidth status="success" (click)="ReadyToCollect()">{{OrderType}}</button>
            </div>

          </div>
        </div> -->



      </div>
    </div>



  </nb-card-body>
</nb-card>

