import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UtilitiesService } from 'app/shared/utilities.service';

@Component({
  selector: 'ngx-selectbranch',
  templateUrl: './selectbranch.component.html',
  styleUrls: ['./selectbranch.component.scss'],
})
export class SelectbranchComponent implements OnInit {
  str: any = 0;
  lstOrderType: any = [];
  SelectedBrancRow: any;
  constructor(public dialogRef: MatDialogRef<SelectbranchComponent>, @Inject(MAT_DIALOG_DATA) public parentData: any, private _utility: UtilitiesService) {
  }


  ngOnInit(): void {
    debugger;
    this.lstOrderType = this.parentData['body'][0];
    this.str = this.lstOrderType[0]['ID'];

    if (localStorage.getItem('SelectedBranchRow' + this._utility.getURLCompanyName()) != null && localStorage.getItem('SelectedBranchRow' + this._utility.getURLCompanyName()) != undefined && this._utility.ApplicationCode == 'POS') {
      this.SelectedBrancRow = JSON.parse( localStorage.getItem('SelectedBranchRow' + this._utility.getURLCompanyName()));
    } else {
  if (localStorage.getItem('SelectedBranchRow' + this._utility.getURLCompanyName()) != null && localStorage.getItem('SelectedBranchRow' + this._utility.getURLCompanyName()) != undefined) {
    this.SelectedBrancRow = JSON.parse( localStorage.getItem('SelectedBranchRow' + this._utility.getURLCompanyName()));
  }

}

this.str = this.SelectedBrancRow.ID;
  }

  btnclose(): void {
    debugger;
    this.dialogRef.close();
  }

  figureClick(selectedvar) {

    debugger;
    localStorage.removeItem('SelectedBranchRow' + this._utility.getURLCompanyName());
    localStorage.setItem('SelectedBranchRow' + this._utility.getURLCompanyName(), JSON.stringify(selectedvar));
    this.dialogRef.close(selectedvar);
    // this.str = selectedvar;

  }

}
