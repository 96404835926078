<div>
  <div class="calculator card">

    <input id="txtbox_amount" style="border: 2px solid black;" type="text" maxlength="5" class="calculator-screen" [(ngModel)]="txtAmount" (ngModelChange)="inputValueChange($event)" onpaste="return false" ondrop="return false;" (keypress)="numberOnly($event)" />

    <div class="calculator-keys">
      <button type="button" value="7" class="btn btn-light waves-effect" (click)="calcButtonclick('7')">7</button>
      <button type="button" value="8" class="btn btn-light waves-effect" (click)="calcButtonclick('8')">8</button>
      <button type="button" value="9" class="btn btn-light waves-effect" (click)="calcButtonclick('9')">9</button>
      <button type="button" value="4" class="btn btn-light waves-effect" (click)="calcButtonclick('4')">4</button>
      <button type="button" value="5" class="btn btn-light waves-effect" (click)="calcButtonclick('5')">5</button>
      <button type="button" value="6" class="btn btn-light waves-effect" (click)="calcButtonclick('6')">6</button>
      <button type="button" value="1" class="btn btn-light waves-effect" (click)="calcButtonclick('1')">1</button>
      <button type="button" value="2" class="btn btn-light waves-effect" (click)="calcButtonclick('2')">2</button>
      <button type="button" value="3" class="btn btn-light waves-effect" (click)="calcButtonclick('3')">3</button>
      <button type="button" value="0" class="btn btn-light waves-effect" (click)="calcButtonclick('0')">0</button>
      <button type="button" class="decimal function btn btn-secondary" value="." (click)="calcButtonclick('.')">.</button>
      <button type="button" class="all-clear function btn btn-success btn-sm" value="enter" (click)="removeNumber()"><img src="./assets/images/BackSpace.png" style="width: 75px;"></button>
      <button type="button" class="all-clear function btn btn-success btn-sm" (click)="ok()">{{OK}}</button>
      <button type="button" style="width: 202%;" class="all-clear function btn btn-danger btn-sm" (click)="close()"> {{Close}}</button>

    </div>

    <!--<div class="calculator-keys" style="padding-top: 10px;">
      <button type="button" value="7" class="btn btn-light waves-effect" (click)="calcButtonclick('7')">7</button>
      <button type="button" value="8" class="btn btn-light waves-effect" (click)="calcButtonclick('8')">8</button>
      <button type="button" value="9" class="btn btn-light waves-effect" (click)="calcButtonclick('9')">9</button>
      <button type="button" value="4" class="btn btn-light waves-effect" (click)="calcButtonclick('4')">4</button>
      <button type="button" value="5" class="btn btn-light waves-effect" (click)="calcButtonclick('5')">5</button>
      <button type="button" value="6" class="btn btn-light waves-effect" (click)="calcButtonclick('6')">6</button>
      <button type="button" value="1" class="btn btn-light waves-effect" (click)="calcButtonclick('1')">1</button>
      <button type="button" value="2" class="btn btn-light waves-effect" (click)="calcButtonclick('2')">2</button>
      <button type="button" value="3" class="btn btn-light waves-effect" (click)="calcButtonclick('3')">3</button>
      <button type="button" value="0" [disabled]="disableZero" class="btn btn-light waves-effect" (click)="calcButtonclick('0')">0</button>

      <button type="button" class="all-clear function btn btn-success btn-sm" value="enter" (click)="removeNumber()"><i class="fal fa-backspace" style="font-size: 45px;"></i></button>

      <button type="button" class="all-clear function btn btn-success btn-sm" (click)="ok()">{{OK}}</button>
      <button type="button" style="width: 310%;" class="all-clear function btn btn-danger btn-sm" (click)="close()"> {{Close}}</button>

    </div>-->

  </div>
    </div>
