import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import {
  NbAuthComponent,
  // NbLoginComponent,
  // NbLogoutComponent,
  // NbRegisterComponent,
  // NbRequestPasswordComponent,
  // NbResetPasswordComponent,
} from '@nebular/auth';
import { LoginComponent } from '../app/admin/login/login.component';
import { PosRetailComponent } from '../app/admin/pos/pos-retail.component';
import { RetailPOSComponent } from '../app/admin/retailpos/retailpos.component';
import { CateringbookingComponent} from '../app/admin/cateringbooking/cateringbooking.component';
import { ReceiptComponent } from '../app/admin/receipt/receipt.component';
import { dayendreceiptComponent } from '../app/admin/dayendreceipt/dayendreceipt.component';
import { ArabicreceiptComponent } from '../app/admin/arabicreceipt/arabicreceipt.component';
import { SaleReturnArabicreceiptComponent} from '../app/admin/salereturnarabicreceipt/salereturnarabicreceipt.component';
import { PosloginComponent } from "../app/admin/poslogin/poslogin.component";
import { shiftendreceiptComponent } from '../app/admin/shiftendreceipt/shiftendreceipt.component';
import { DigiposloginComponent } from '../app/admin/digiposlogin/digiposlogin.component';
import { A4sizereceiptComponent } from './admin/a4sizereceipt/a4sizereceipt.component';
import { KitchenreceiptComponent } from './admin/kitchenreceipt/kitchenreceipt.component';
import { A4sizereceiptArabicformatComponent } from './admin/a4sizereceipt-arabicformat/a4sizereceipt-arabicformat.component';
import { RestaurantposComponent } from './admin/restaurantpos/restaurantpos.component';
import { A4sizeinvoiceComponent } from './pages/reports/a4sizeinvoice/a4sizeinvoice.component';


let companyName = window.location.href.includes('BackOffice')  ? 
window.location.href.includes('receipt') || window.location.href.includes('arabicreceipt') || window.location.href.includes('dayendreceipt') ||
    window.location.href.includes('posretail') || window.location.href.includes('restaurantpos') || window.location.href.includes('retailpos') || window.location.href.includes('cateringbooking') || window.location.href.includes('shiftendreceipt') || window.location.href.includes('a4sizereceipt') || window.location.href.includes('a4Invoice')|| window.location.href.includes('a4arabicformat') || window.location.href.includes('kitchenreceipt') || window.location.href.includes('salereturnarabicreceipt')
?
    (window.location.href.includes('receipt' || 'arabicreceipt' || 'dayendreceipt' || 'shiftendreceipt' ||'a4arabicformat' || 'a4sizereceipt' || 'a4Invoice' || 'kitchenreceipt' || 'salereturnarabicreceipt') ? localStorage.getItem('receiptCompnayName'): window.location.href.split('/')[6].toLowerCase())
:
window.location.href.split('/')[4].toLowerCase() 
: 
window.location.href.includes('receipt') || window.location.href.includes('arabicreceipt') || window.location.href.includes('dayendreceipt') ||
    window.location.href.includes('posretail') || window.location.href.includes('restaurantpos') || window.location.href.includes('retailpos') || window.location.href.includes('cateringbooking') || window.location.href.includes('shiftendreceipt') || window.location.href.includes('a4sizereceipt') || window.location.href.includes('a4Invoice') || window.location.href.includes('a4arabicformat') ||  window.location.href.includes('kitchenreceipt') || window.location.href.includes('salereturnarabicreceipt')
                  ?
    (window.location.href.includes('receipt' || 'arabicreceipt' || 'dayendreceipt' || 'shiftendreceipt' ||'a4arabicformat' || 'a4sizereceipt' || 'a4Invoice' || 'kitchenreceipt' || 'salereturnarabicreceipt') ? localStorage.getItem('receiptCompnayName'): window.location.href.split('/')[5].toLowerCase())
                  :
                  window.location.href.split('/')[3].toLowerCase();

let lstUser = JSON.parse(localStorage.getItem('sessionObject' + companyName)) != null ? JSON.parse(localStorage.getItem('sessionObject' + companyName))[0] : [];

let compPath = '';
if (lstUser != null && lstUser.length > 0) {
  compPath = lstUser[0].CompanyName;
}
else {
  compPath = '';
}
export const routes: Routes = [
  {
    path: compPath,
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
  },
  {
    path: 'auth',
    component: NbAuthComponent,
    children: [
      {
        path: '',
        component: LoginComponent, /*DigiposloginComponent*/
      },
      {
        path: 'login',
        component: LoginComponent, /*LoginComponent*/
      },
      {
        path: 'posretail/:CompanyName',
        component: PosRetailComponent,
      },
       {
         path: 'restaurantpos/:CompanyName',
         component: RestaurantposComponent,
      },
      {
        path: 'retailpos/:CompanyName',
        component: RetailPOSComponent,
      },
      {
        path: 'cateringbooking/:CompanyName',
        component: CateringbookingComponent,
      },     
      {
        path: 'receipt/:CompanyName/:OrderId/:OutletId/:AppStatus/:IsFoodCatering',
        component: ReceiptComponent,
      },
      {
        path: 'dayendreceipt/:CompanyName/:OrderId/:OutletId/:DayId',
        component: dayendreceiptComponent,
      },
      {
        path: 'arabicreceipt/:CompanyName/:OrderId/:OutletId/:AppStatus/:IsFoodCatering',
        component: ArabicreceiptComponent,
      },
      {
        path: 'salereturnarabicreceipt/:CompanyName/:ReturnID/:OutletId/:AppStatus/:IsFoodCatering',
        component: SaleReturnArabicreceiptComponent,
      },
      {
        path: 'a4sizereceipt/:CompanyName/:OrderId/:IsFoodCatering',
        component: A4sizereceiptComponent,
      },
      {
        path: 'a4Invoice/:CompanyName/:id/:invType',
        component: A4sizeinvoiceComponent,
      },
      {
        path: 'a4arabicformat/:CompanyName/:OrderId/:IsFoodCatering',
        component: A4sizereceiptArabicformatComponent,
      },
      {
        path: 'kitchenreceipt/:CompanyName/:OrderId',
        component: KitchenreceiptComponent,
      },
      {
        path: 'poslogin/:CompanyName/:CounterId',
        component: PosloginComponent,
      },
      {
        path: 'shiftendreceipt/:CompanyId/:ShiftId/:daydate',
        component: shiftendreceiptComponent,
      },
      {
        path: 'digiposlogin',
        component: DigiposloginComponent,
      },

      // {
      //   path: 'logout',
      //   component: LogoutComponent,
      // },
      // {
      //   path: 'aboutus',
      //   component: AboutUsComponent,
      // },
      // {
      //   path: 'register',
      //   component: NbRegisterComponent,
      // },
      // {
      //   path: 'request-password',
      //   component: NbRequestPasswordComponent,
      // },
      // {
      //   path: 'reset-password',
      //   component: NbResetPasswordComponent,
      // },
    ],
  },

  { path: '', redirectTo: 'auth/login', pathMatch: 'full' },
  { path: '**', redirectTo: 'auth/login' },
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})

export class AppRoutingModule {

}
