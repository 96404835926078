  <!--<div ngxSlickItem *ngFor="let row of lstCategories; let i = index">-->
    <!-- <figure *ngFor="let row of lstCategories; let i = index" [ngClass]="[row.Code == productstr ? 'cardCategory card-product-grid card-lg cardtypeselected menucalss' : 'cardCategory card-product-grid card-lg menucalss']">
      <a (click)="getProduct(row.Code)" class="img-wrap">
        <img src={{row.ImageName}}>
      </a>
      <a *ngIf="cultures != 'ar'" title="{{row.Name}}" [ngClass]="[row.Code == productstr ? 'selectedtitle title' : 'title']" style="text-align:center;font-size: 16px;padding-left: 8px;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;"> {{row.Name}}</a>
      <a *ngIf="cultures == 'ar'" title="{{row.AName}}" [ngClass]="[row.Code == productstr ? 'selectedtitle title' : 'title']" style="text-align:center;font-size: 16px;padding-left: 8px;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;"> {{row.AName}}</a>
      <a title="{{row.Description}}" (click)="getProduct(row.Code)" [ngClass]="[row.Code == productstr ? 'selectedtitle title' : 'title']" style="text-align:center;font-size: 12px;padding-left: 8px;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;"> {{row.Description}}</a>
    </figure> -->
  <!--</div>-->

<div *ngIf="!IsSalesTypePopup">
  <div class="inline" *ngFor="let row of lstCategories; let i = index" >
    <div class="wrap" (click)="getProduct(row.Code)" >
      <p *ngIf="culture != 'ar'" title="{{row.Name}}" [ngClass]="[row.Code == productstr ? 'selectedtitle title' : 'title']"> {{row.Name}}</p>
      <p *ngIf="culture == 'ar'" title="{{row.AName}}" [ngClass]="[row.Code == productstr ? 'selectedtitle title' : 'title']"> {{row.AName}}</p>
    </div>
  </div>
</div>

<div *ngIf="IsSalesTypePopup">
  <div  class="inline" *ngFor="let row of lstSalesType; let i = index" >
    <div class="wrap" (click)="getSelectedTypeData(row.ID,row.Type)" >
      <p *ngIf="culture != 'ar'" title="{{row.Type}}" [ngClass]="[row.ID == productstr ? 'selectedtitle title' : 'title']"> {{row.Type}}</p>
      <p *ngIf="culture == 'ar'" title="{{row.TypeArabic}}" [ngClass]="[row.ID == productstr ? 'selectedtitle title' : 'title']"> {{row.TypeArabic}}</p>
    </div>
  </div>
</div>