
import { Component, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService, NbLayoutDirection, NbLayoutDirectionService } from '@nebular/theme';
import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
import { Router } from '@angular/router';
import { UtilitiesService } from '../../../shared/utilities.service';

@Component({
  selector: 'ngx-one-column-layout',
  styleUrls: ['./one-column.layout.scss'],
  template: `
    <nb-layout windowMode>


      <nb-sidebar *ngIf="showMenu"[ngClass]="sidebar_class" tag="menu-sidebar" responsive   
      style="background-image: url('../../../../assets/images/left-sidebar-bg.jpg') !important;">
        <div>  
        <img  src="{{ImagePath}}" style="width: 100%;margin-left: 6px; margin-top: -20px;" />
        <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline" style="color:#05a9e6! important"></nb-icon>
    </a>
       </div>
        <ng-content select="nb-menu"></ng-content>
      </nb-sidebar>

      <nb-layout-column [ngStyle]="{'background-color': (showMenu == false) ? '#dde1e9' : '#dde1e9'}">
        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>


    </nb-layout>
  `,
})
export class OneColumnLayoutComponent implements OnInit  {
  constructor(private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private userService: UserData,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    public router: Router,
    private  directionService: NbLayoutDirectionService,
    private _utility: UtilitiesService,
  ) {
  }
  showMenu: boolean = true;
  hideMenuOnClick: boolean = false;




  layout_direction: NbLayoutDirection = this.directionService.getDirection();
  sidebar_class: string = 'menu-sidebar';
  ImagePath: any;

  ngOnInit() {

    // debugger
    let  test ;
    if (localStorage.getItem('LanguageCulture' + this._utility.getURLCompanyName()) != null) {
      if (localStorage.getItem('LanguageCulture' + this._utility.getURLCompanyName()) == 'en') {

        test = NbLayoutDirection.LTR;
        this.layout_direction = NbLayoutDirection.LTR;
      } else {
        test = NbLayoutDirection.RTL;
        this.layout_direction = NbLayoutDirection.RTL;
      }
    }
    if ( this.layout_direction === test) {
      this.sidebar_class = 'menu-sidebar-rtl';
    }

    if (this.router.url.includes('maindashboard'))
    this.showMenu = false;
    this.menuService.onItemClick().subscribe(() => {
      if (this.hideMenuOnClick) {
        this.sidebarService.collapse('menu-sidebar');
      }
    });
    if (this.router.url.includes('kdsdashboard'))
    this.showMenu = false;
    this.menuService.onItemClick().subscribe(() => {
      if (this.hideMenuOnClick) {
        this.sidebarService.collapse('menu-sidebar');
      }
    });
    if (this.router.url.includes('queuedashboard'))
    this.showMenu = false;
    this.menuService.onItemClick().subscribe(() => {
      if (this.hideMenuOnClick) {
        this.sidebarService.collapse('menu-sidebar');
      }
    });
// debugger
    const IsDigiPosLogin = JSON.parse(localStorage.getItem('sessionObject' + this._utility.getURLCompanyName()))[0][0].ApplicationCode;
    if (IsDigiPosLogin == 'POS')
      this.ImagePath = 'assets/images/logo2.png';
      else
      this.ImagePath = 'assets/images/logo1.png';
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }
}
