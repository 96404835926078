import { NgModule } from '@angular/core';
import { NbMenuModule } from '@nebular/theme';


import { ThemeModule } from '../../@theme/theme.module';
import { PagesRoutingModule } from '../../pages/pages-routing.module';


import { GetDateDifference } from './datediff.pipe';
import { GetMinutesDifference } from './minutesdiff';
import { SafeUrlPipe } from './safeUrl.pipe';
import { TranslatePipe } from './translate.pipe';
import { SelectboxPipe } from './selectbox.pipe';

@NgModule({
  imports: [
    PagesRoutingModule,
    ThemeModule,
    NbMenuModule,
  ],
  declarations: [
    GetDateDifference,
    GetMinutesDifference,
    SafeUrlPipe,
    TranslatePipe,
    SelectboxPipe
    
  ],
  exports: [
    GetDateDifference,
    GetMinutesDifference,
    SafeUrlPipe,
    TranslatePipe,
    SelectboxPipe
    
  ],
  providers: [GetDateDifference, GetMinutesDifference, TranslatePipe],
})
export class PipesModule { }
