import { Component, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {TranslatePipe} from '../../../shared/pipe/translate.pipe';

@Component({
  selector: 'ngx-closependingorderfirstpopup',
  templateUrl: './closependingorderfirstpopup.component.html',
  styleUrls: ['./closependingorderfirstpopup.component.scss'],
})
export class ClosependingorderfirstpopupComponent {
  PleaseClosePendingOrdersFirstthenclosetheshift = this.translate.transform('PleaseClosePendingOrdersFirstthenclosetheshift');
  ProceedAnyway = this.translate.transform('ProceedAnyway');

  constructor(private translate: TranslatePipe, public dialogRef: MatDialogRef<ClosependingorderfirstpopupComponent>, @Inject(MAT_DIALOG_DATA) public parentData: any) { }


  ngOnInit(): void {
    this.parentData.message == 'PleaseClosePendingOrdersFirstthenclosetheshift';
  }

  btnclose(): void {
    this.dialogRef.close();
  }
  loading: boolean = false;
  ProceedAnywayClick() {
    this.loading = true;

    this.dialogRef.close(2);
  }
}
