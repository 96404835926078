import { DecimalPipe } from '@angular/common';
import { Component, OnInit, Inject } from '@angular/core';
import { DataserviseService } from '../../shared/dataservice';
import { UtilitiesService } from '../../shared/utilities.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { TranslatePipe } from '../../shared/pipe/translate.pipe';

@Component({
  selector: 'ngx-quickreport-popup',
  templateUrl: './quickreport-popup.component.html',
  styleUrls: ['./quickreport-popup.component.scss']
})
export class QuickreportPopupComponent implements OnInit {
  ObjectQuickReport: quickReport = new quickReport();
  ObjOutletInfo: OutletInfo = new OutletInfo();
  NumOfDecimal: any;
  SaleSummary: any = this.translate.transform('SaleSummary');
  lstReportSummary = [];
  Culture: any;
  GrandTotal: any;

  PayMethod: any = this.translate.transform('PayMethod');
  Amount: any = this.translate.transform('Amount');

  DiscountAmountTotal: any = 0;
  TransactionTotal: any = 0;
  TaxAmountTotal: any = 0;
  NetAmountTotal: any = 0;
  TotalAmountNoTax: any = 0;
  RefundAmount: any = 0.00;
  ExtraChargeAmount: any = 0;
  ExtraChargeDescription: any;
  LoyaltyAmount: any = 0;
  TaxRate: any = 0.00;
  CurrencyCode: any = '';

  constructor(private dataservice: DataserviseService, private Utilities: UtilitiesService, private _decimalPipe: DecimalPipe, public dialogRef: MatDialogRef<QuickreportPopupComponent>, @Inject(MAT_DIALOG_DATA) public parentdata: any, private translate: TranslatePipe) { }

  ngOnInit(): void {
    debugger
    this.NumOfDecimal = this.parentdata.NoOfDecimal;
    this.Culture = this.parentdata.Culture;
    let ShiftID = this.parentdata.ShiftID;
    let OutletID = this.parentdata.OutletID;
    let currDate = this.Utilities.GetCurrentDate() + "";
    this.ObjOutletInfo = JSON.parse(sessionStorage.getItem('OutletInfo'));
    let company = JSON.parse(sessionStorage.getItem('ObjCompany'));
    this.CurrencyCode = company[0].CurrencyCode;
    this.NumOfDecimal = company[0].NumOfDecimal;
    this.TaxRate = this.ObjOutletInfo[0].TaxRate;

    this.dataservice.get('pos/GetQuickReportData/' + this.Utilities.getSubscriptionID() + '/' + currDate + '/' + this.Culture + '/' + ShiftID + '/' + OutletID)
      .subscribe(data => {
        debugger
        if (this.isArrayHasValue(data[0]['ReportData'])) {
          this.lstReportSummary = data[0]['ReportData'];
          this.GrandTotal = data[0]['GrandTotal'][0].GrandTotal;
        }
        if (this.isArrayHasValue(data[0]['DayDetail'])) {
          this.NetAmountTotal = data[0]['DayDetail'][0].NetAmount;
          this.TaxAmountTotal = data[0]['DayDetail'][0].TotalTaxAmount;
          this.TotalAmountNoTax = data[0]['DayDetail'][0].TotalAmountNoTax;
          this.TransactionTotal = data[0]['DayDetail'][0].NoOfTrans;
          this.ExtraChargeDescription = data[0]['DayDetail'][0].ExtraChargeDescription;
          this.ExtraChargeAmount = data[0]['DayDetail'][0].ExtraChargeAmount;
          this.LoyaltyAmount = data[0]['DayDetail'][0].LoyaltyAmount;
          this.DiscountAmountTotal =  data[0]['DayDetail'][0].LoyaltyAmount;
        }
        if (this.isArrayHasValue(data[0]['DiscountDetail'])) {
          this.DiscountAmountTotal += data[0]['DiscountDetail'][0].PromoAmount;
          this.TotalAmountNoTax = data[0]['DayDetail'][0].TotalAmountNoTax ;
        }
        if (this.isArrayHasValue(data[0]['RefundAmountDetail'])) {
          this.RefundAmount = data[0]['RefundAmountDetail'][0].RefundAmount;
        }
       
      });
  }

  isArrayHasValue(obj: any[]): boolean {
    let isArrayWithLength = Boolean(Array.isArray(obj) && obj.length);
    return isArrayWithLength;
  }
  btnclose() {
    debugger
    this.dialogRef.close();
  }
}

export class quickReport {
  CashSale: any = 0;
  CardSale: any = 0;
  CreditCustomerSale: any = 0;
  SubTotal: any = 0;
  TotalDiscountAmount: any = 0;
  TotalVATAmount: any = 0;
  GrandTotalIncludeVAT: any = 0;
}
export class OutletInfo {
  BranchID: any = 0;
  BranchName: any = '';
  CityId: any = 0;
  ImageName: any = '';
  TaxRate: any = 0;
  TaxID: any = 0;
  IsTaxInclusive: boolean = false;
  IsTaxOnDiscountAmount: boolean = false;
  IsZatcaInvoiceOn: boolean;
}
