import { Component, NgModule, Pipe, PipeTransform } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'

@Pipe({
    name: 'selectbox'
})

export class SelectboxPipe implements PipeTransform {
  transform(lst: any[], index: any): any {
      debugger
     // console.log(lst);
      return lst[index];
    }
}
