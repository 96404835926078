import { Pipe, PipeTransform } from '@angular/core';
import {TranslatePipe} from './translate.pipe';

@Pipe({name: 'datediff'})

export class GetDateDifference implements PipeTransform {


  constructor(public translatePipe: TranslatePipe) {
  }

  transform(CreatedDate: any, args?: any): any {
    let OrderPlacedTime;

    var CurrentDate:any = new Date();

    //CreatedDate = new Date('2021-02-04 08:00:00.000');
    CreatedDate = new Date(CreatedDate);

    let diffMs = CurrentDate - CreatedDate; // milliseconds
    let diffDays = Math.floor(diffMs / 86400000); // days
    let diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    let diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes

    //console.log('CurrentDate: ' + CurrentDate);
    //console.log('CreatedDate: ' + CreatedDate);
    //console.log('diffMs: ' + diffMs);
    //console.log('MINS: '+diffMins);
    //console.log('diffHrs: ' +diffHrs);
    //console.log('diffDays: ' + diffDays);



    if (diffMins <= 60 && diffDays == 0 && diffHrs == 0) {

      OrderPlacedTime = diffMins.toString() + ' Minutes (s) ago';
    }
    else if (diffHrs > 0 && diffDays == 0 ) {
      OrderPlacedTime = diffHrs.toString() + ' hour(s) ago';
    }
    else if (diffDays >= 1) {
      OrderPlacedTime = diffDays.toString() +  this.translatePipe.transform('daysago');//' day(s) ago';
    }
  

    return OrderPlacedTime;
  }

}
