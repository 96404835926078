<nb-card>

  <nb-card-body>
    <div class="child1" style="max-width: 100% !important ;">{{SplitBills}}
      <img src="./assets/images/lookupclose.png" (click)="btnclose()" style="float: right; width: 25px;cursor: pointer;">
    </div>
    <hr style="margin-top: 10px;"/>

    <div class="row">
      <div class="col-md-3 col-sm-6">
        <div class="form-group">
          <label class="label total">{{OrderNo}} : {{ObjMasterData1.OrderInvoiceNo}}</label>
        </div>
      </div>


      <div *ngIf="ObjMasterData1.TableNumber" class="col-md-3 col-sm-6">
        <div class="form-group">
          <label class="label total">{{TableNo}} : {{ObjMasterData1.TableNumber}}</label>
        </div>
      </div>


    </div>

    <div class="row">
      <!--<div cdkDropListGroup>
      <div class="example-container">
        <h2>To do</h2>

        <div cdkDropList
             [cdkDropListData]="todo"
             class="example-list"
             (cdkDropListDropped)="drop($event)">
          <div class="example-box" *ngFor="let item of todo" cdkDrag>{{item}}</div>
        </div>
      </div>

      <div class="example-container">
        <h2>Done</h2>

        <div cdkDropList
             [cdkDropListData]="done"
             class="example-list"
             (cdkDropListDropped)="drop($event)">
          <div class="example-box" *ngFor="let item of done" cdkDrag>{{item}}</div>
        </div>
      </div>
    </div>-->


      <div class="col-md-6">
        <label class="label">{{FirstBill}}</label>
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th class="col-md-1 gridheader">#</th>
                <th class="col-md-4 gridheader">{{Description}}</th>
                <th class="col-md-1 gridheader">{{Qty}}</th>
                <th class="col-md-1 gridheader">{{Price}}</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let data1 of lst1;let i = index">
                <tr class="handicursor" (click)="lst1Click(data1, $event, i)" [ngClass]="{'selected1': i === HighlightRow}">
                  <td class="col-md-1 griddata">{{i+1}}</td>
                  <td class="col-md-4 griddata">{{Culture == 'ar' ? data1.AProductName : data1.ProductName}} {{data1.ProductSizeName != null && data1.ProductSizeName != undefined ? '(' +data1.ProductSizeName +')' : ''}}</td>
                  <td class="col-md-1 griddata">{{data1.Quantity}}</td>
                  <td class="col-md-2 griddata">{{ data1.Quantity * data1.Price |number:'1.'+NoOfDecimal+'-5'}}</td>
                </tr>

                <ng-container *ngFor="let m of lstmodifir1;let ii = index">
                  <tr *ngIf="m.IsSelected == true && m.ProductID == data1.ProductID && data1.rowid == m.rowid">
                    <td class="col-md-1 griddata" style="padding:0px!important;border:0"></td>
                    <td class="col-md-4 griddata" colspan="10" style="text-indent: 20px;padding:0px!important;border:0">
                      {{Culture == 'ar' ?  m.AName:  m.Modifires}}
                      <span *ngIf="m.Price != undefined" class="griddata">({{CurrencyCode}}&nbsp;{{m.PriceStatic|number:'1.'+NoOfDecimal+'-5'}})</span>
                    </td>
                  </tr>
                </ng-container>

                <ng-container *ngFor="let cmb of lstCombo1;let iii = index">
                  <tr *ngIf="cmb.MainProductID == data1.ProductID && data1.rowid == cmb.rowid">
                    <td class="col-md-1 griddata" style="padding:0px!important;border:0"></td>
                    <td class="col-md-4 griddata" colspan="10" style="text-indent: 20px;padding:0px!important;border:0">
                      {{Culture == 'ar' ?  cmb.AName:  cmb.ProductName}}
                      <span *ngIf="cmb.Price != undefined" class="griddata">({{CurrencyCode}}&nbsp;{{cmb.Price|number:'1.'+NoOfDecimal+'-5'}})</span>
                    </td>
                  </tr>
                </ng-container>

                <tr *ngIf="lst1.length < 1">
                  <td colspan="10" style="text-align:center;font-size:12px;">No data</td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
      <div>
        <div class="vline"></div>
      </div>
      <div class="col-md-6">
        <label class="label">{{SecondBill}}</label>
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th class="col-md-1 gridheader">#</th>
                <th class="col-md-4 gridheader">{{Description}}</th>
                <th class="col-md-1 gridheader">{{Qty}}</th>
                <th class="col-md-1 gridheader">{{Price}}</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let data2 of lst2;let i = index">
                <tr class="handicursor" (click)="lst2Click(data2, $event, i)" [ngClass]="{'selected1': i === HighlightRow}">
                  <td class="col-md-1 griddata">{{i+1}}</td>
                  <td class="col-md-4 griddata">{{Culture == 'ar' ? data2.AProductName : data2.ProductName}} {{data2.ProductSizeName != null && data2.ProductSizeName != undefined ? '(' +data2.ProductSizeName +')' : ''}}</td>
                  <td class="col-md-1 griddata">{{data2.Quantity}}</td>
                  <td class="col-md-1 griddata">{{data2.Quantity * data2.Price |number:'1.'+NoOfDecimal+'-5'}}</td>
                </tr>

                <ng-container *ngFor="let m2 of lstmodifir2;let ii = index">
                  <tr *ngIf="m2.IsSelected == true && m2.ProductID == data2.ProductID && m2.rowid == data2.rowid">
                    <td class="col-md-1 griddata" style="padding:0px!important;border:0"></td>
                    <td class="col-md-4 griddata" colspan="10" style="text-indent: 20px;padding:0px!important;border:0">
                      {{Culture == 'ar' ? m2.AName : m2.Modifires}}
                      <span *ngIf="m2.Price != undefined" class="griddata">({{CurrencyCode}}&nbsp;{{m2.PriceStatic|number:'1.'+NoOfDecimal+'-5'}})</span>
                    </td>
                  </tr>
                </ng-container>

                <ng-container *ngFor="let cmb of lstCombo2;let iii = index">
                  <tr *ngIf="cmb.MainProductID == data2.ProductID && data2.rowid == cmb.rowid">
                    <td class="col-md-1 griddata" style="padding:0px!important;border:0"></td>
                    <td class="col-md-4 griddata" colspan="10" style="text-indent: 20px;padding:0px!important;border:0">
                      {{Culture == 'ar' ?  cmb.AName:  cmb.ProductName}}
                      <span *ngIf="cmb.Price != undefined" class="griddata">({{CurrencyCode}}&nbsp;{{cmb.Price|number:'1.'+NoOfDecimal+'-5'}})</span>
                    </td>
                  </tr>
                </ng-container>

                <tr *ngIf="lst2.length < 1">
                  <td colspan="10" style="text-align:center;font-size:12px;">No data</td>
                </tr>
              </ng-container>
            </tbody>
          </table>

        </div>



      </div>
    </div>

  


    <div class="row">
      <div class="col-md-6" id="data1">
        <hr style="margin-bottom: 8px;">
        <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">

          <div class="row" style="margin-bottom: 2px;">
            <div class="cls1 col-lg-6 col-md-5 col-xs-5 col-sm-5">
            </div>
            <div class="cls2 col-lg-4 col-md-4 col-xs-4 col-sm-4">
              <label class="label total">{{SubTotal}}:</label>
            </div>
            <div class="cls3 col-lg-2 col-md-2 col-xs-2 col-sm-2">
              <label class="label total">{{CurrencyCode}}&nbsp;{{ObjMasterData1.TotalAmount == undefined ? 0 : ObjMasterData1.TotalAmount |number:'1.'+NoOfDecimal+'-5'}}</label>
            </div>

          </div>

          <div class="row" style="margin-bottom: 2px;" *ngIf="IsExtraCharge">
            <div class="cls1 col-lg-6 col-md-5 col-xs-5 col-sm-5">
            </div>
            <div class="cls2 col-lg-4 col-md-4 col-xs-4 col-sm-4">
              <label class="label total">{{ExtraChargeDescription}}:</label>
            </div>
            <div class="cls3 col-lg-2 col-md-2 col-xs-2 col-sm-2">
              <label class="label total">{{CurrencyCode}}&nbsp;{{ObjMasterData1.ExtraChargeAmount == undefined ? 0 : ObjMasterData1.ExtraChargeAmount |number:'1.'+NoOfDecimal+'-5'}}</label>
            </div>

          </div>

          <div class="row" style="margin-bottom: 2px;display:none">
            <div class="cls1 col-lg-6 col-md-5 col-xs-5 col-sm-5">
            </div>
            <div class="cls2 col-lg-4 col-md-4 col-xs-4 col-sm-4">
              <label class="label total">{{Discount}}:</label>
            </div>
            <div class="cls3 col-lg-2 col-md-2 col-xs-2 col-sm-2">
              <label class="label total">{{CurrencyCode}}&nbsp;-{{ObjMasterData1.TotalDiscount == undefined ? 0 : ObjMasterData1.TotalDiscount |number:'1.'+NoOfDecimal+'-5'}}</label>
            </div>

          </div>

          <div class="row" style="margin-bottom: 2px;">
            <div class="cls1 col-lg-6 col-md-5 col-xs-5 col-sm-5">
            </div>
            <div class="cls2 col-lg-4 col-md-4 col-xs-4 col-sm-4">
              <label class="label total">{{Tax}}:</label>
            </div>
            <div class="cls3 col-lg-2 col-md-2 col-xs-2 col-sm-2">
              <label class="label total">{{CurrencyCode}}&nbsp;{{ObjMasterData1.TotalTaxAmount == undefined ? 0 : ObjMasterData1.TotalTaxAmount |number:'1.'+NoOfDecimal+'-5'}}</label>
            </div>
          </div>

          <div class="row" style="margin-bottom: 2px;">
            <div class="cls1 col-lg-6 col-md-5 col-xs-5 col-sm-5">
            </div>
            <div class="cls2 col-lg-4 col-md-4 col-xs-4 col-sm-4">
              <label class="label total">{{NetAmount}}:</label>
            </div>
            <div class="cls3 col-lg-2 col-md-2 col-xs-2 col-sm-2">
              <label class="label total">{{CurrencyCode}}&nbsp;{{ObjMasterData1.NetAmount == undefined ? 0 : ObjMasterData1.NetAmount |number:'1.'+NoOfDecimal+'-5'}}</label>
            </div>

          </div>

        </div>




      </div>

      <div class="col-md-6" id="data2">
        <hr style="margin-bottom: 8px;">
        <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">

          <div class="row" style="margin-bottom: 2px;">
            <div class="cls1 col-lg-6 col-md-6 col-xs-6 col-sm-6">
            </div>
            <div class="cls2 col-lg-4 col-md-4 col-xs-4 col-sm-4">
              <label class="label total">{{SubTotal}}:</label>
            </div>
            <div class="cls3 col-lg-2 col-md-2 col-xs-2 col-sm-2">
              <label class="label total">{{CurrencyCode}}&nbsp;{{ObjMasterData2.TotalAmount == undefined ? 0 : ObjMasterData2.TotalAmount |number:'1.'+NoOfDecimal+'-5'}}</label>
            </div>

          </div>

          <div class="row" style="margin-bottom: 2px;" *ngIf="IsExtraCharge">
            <div class="cls1 col-lg-6 col-md-6 col-xs-6 col-sm-6">
            </div>
            <div class="cls2 col-lg-4 col-md-4 col-xs-4 col-sm-4">
              <label class="label total">{{ExtraChargeDescription}}:</label>
            </div>
            <div class="cls3 col-lg-2 col-md-2 col-xs-2 col-sm-2">
              <label class="label total">{{CurrencyCode}}&nbsp;{{ObjMasterData2.ExtraChargeAmount == undefined ? 0 : ObjMasterData2.ExtraChargeAmount |number:'1.'+NoOfDecimal+'-5'}}</label>
            </div>

          </div>

          <div class="row" style="margin-bottom: 2px;display:none">
            <div class="cls1 col-lg-6 col-md-6 col-xs-6 col-sm-6">
            </div>
            <div class="cls2 col-lg-4 col-md-4 col-xs-4 col-sm-4">
              <label class="label total">{{Discount}}:</label>
            </div>
            <div class="cls3 col-lg-2 col-md-2 col-xs-2 col-sm-2">
              <label class="label total">{{CurrencyCode}}&nbsp;{{ObjMasterData2.TotalDiscount == undefined ? 0 : ObjMasterData2.TotalDiscount |number:'1.'+NoOfDecimal+'-5'}}</label>
            </div>
          </div>

          <div class="row" style="margin-bottom: 2px;">
            <div class="cls1 col-lg-6 col-md-6 col-xs-6 col-sm-6">
            </div>
            <div class="cls2 col-lg-4 col-md-4 col-xs-4 col-sm-4">
              <label class="label total">{{Tax}}:</label>
            </div>
            <div class="cls3 col-lg-2 col-md-2 col-xs-2 col-sm-2">
              <label class="label total">{{CurrencyCode}}&nbsp;{{ObjMasterData2.TotalTaxAmount == undefined ? 0 : ObjMasterData2.TotalTaxAmount |number:'1.'+NoOfDecimal+'-5'}}</label>
            </div>
          </div>

          <div class="row" style="margin-bottom: 2px;">
            <div class="cls1 col-lg-6 col-md-6 col-xs-6 col-sm-6">
            </div>
            <div class="cls2 col-lg-4 col-md-4 col-xs-4 col-sm-4">
              <label class="label total">{{NetAmount}}:</label>
            </div>
            <div class="cls3 col-lg-2 col-md-2 col-xs-2 col-sm-2">
              <label class="label total">{{CurrencyCode}}&nbsp;{{ObjMasterData2.NetAmount == undefined ? 0 : ObjMasterData2.NetAmount |number:'1.'+NoOfDecimal+'-5'}}</label>
            </div>

          </div>

        </div>
        <br/>
        <div class="row">
          <div class="col-md-12" style="text-align: right">
            <button class="btn btnfinish" [disabled]="btnDisabled" (click)="SplitBill()">{{SplitBills}}</button>
          </div>
        </div>
      </div>
    </div>

  
  </nb-card-body>

</nb-card>
