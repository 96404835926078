<link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous" />
<div class="doNotPrint">
  <div class="row mainDiv">

    <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-xs-1" style="background-color:#2b436e;padding: 0px;height: 100vh;overflow-y: scroll;">
      <div class="menusection">
        <div (click)="menuClick('home')" class="menuparentdiv background">
          <div (click)="homeClick()" [ngClass]="[tab == 'home' ? 'activemenu menuclass' : 'bottomborder menuclass']">
            
            <img src="assets/images/resturantMenu/home.svg" class="menusvg" />
            <p class="menuIconText">{{Home}}</p>
          </div>
        </div>

        <div (click)="menuClick(menu.EntityCode)" [ngClass]="[tab == menu.EntityCode ? 'background' : 'menuparentdiv']" *ngFor="let menu of lstPosMenu">
          <div [ngClass]="[tab == menu.EntityCode ? 'activemenu menuclass' : 'menuclass']">
            <img src="assets/images/resturantMenu/{{menu.Icon}}" class="menusvg" />
            <p class="menuIconText">{{menu.EntityName}}</p>
          </div>
        </div>

        <div (click)="menuClick('logout')" [ngClass]="[tab == 'logout' ? 'background' : 'menuparentdiv']">
          <div [ngClass]="[tab == 'logout' ? 'activemenu menuclass' : 'menuclass']">
            <img src="assets/images/resturantMenu/printreceipt.svg" class="menusvg" /><p class="menuIconText">{{Logout}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-8 col-lg-8 col-md-7 col-sm-7 col-xs-7" style="background-color: #f6f6f6;padding-top: 5px;">

      <div class="parent1 paddBotton">


      </div>
      <div class="row">

        <div class="col-md-6 col-sm-12">
          <div class="form-group">
            <label for="bBarcode" class="label">{{BarcodeProduct}}</label>
            <input nbInput fullWidth name="bBarcode" id="txtboxAmount" placeholder="Item Barcode" [(ngModel)]="SearchBarcode" (change)="BarcodeChange(SearchBarcode)" style="width:89%" />&nbsp;&nbsp;
            <i class="fa fa-search" (click)="ItemLookup()"></i>
            <div *ngIf="IsBarcodeFound" style="background: white;color: red;background: white;text-align: center; padding: 10px;border-radius: 10px;width:89%">{{ProductNotFound}}</div>
          </div>
        </div>
        <div class="col-md-3 col-sm-12">
          <div class="form-group">
            <label for="lblQuantity" class="label">{{Qty}}</label>
            <input nbInput name="lblQuantity" placeholder="Item Barcode" [(ngModel)]="Quantity" (keypress)="isNumberKey($event)" (input)="onQtyChange()" style="width:89%" />&nbsp;&nbsp;
          </div>
        </div>
      </div>

      <div class="row">
        <hr style="width:100%;" />
        <div class="col-md-11 col-xs-11 col-xs-11 col-sm-11">
          <div class="table-responsive" style="width: 100%; height: 62vh">

            <div class="table-wrap">
              <table class="table">
                <thead class="thead-primary" style="background: #3a527d!important">
                  <tr>
                    <th>#</th>
                    <th style="width:12%">{{Barcode}}</th>
                    <th style="width:25%">{{ProductName}}</th>
                    <th>Unit</th>
                    <th>{{Qty}}</th>
                    <th>{{UnitPrice}}</th>
                    <!--<th>Tax Amount</th>-->
                    <th>{{lblTotalAmount}}</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container class="alert" *ngIf="lstProduct.length > 0">
                    <tr *ngFor="let row of lstProduct;let i = index" (click)="clickEvent(i)" [ngClass]="{'highlight': row.IsSelected == true}">

                      <td class="tdval">{{i+1}}</td>
                      <!--<td>
                        <div class="img" [style.background-image]="'url(\'' + row.ProductImage +'\')'"></div>
                      </td>-->
                      <td class="tdval">{{row.Barcode}}</td>
                      <td class="tdval">
                        {{row.ProductName}}
                      </td>
                      <td class="tdval">{{row.UnitName}}</td>
                      <td class="quantity tdval">{{row.Quantity}}</td>
                      <td class="tdval">{{row.UnitCost| number:'1.'+NumOfDecimal}}</td>
                      <!--<td class="tdval">{{row.TaxAmount| number:'1.'+NumOfDecimal}}</td>-->
                      <td class="tdval">{{row.TotalAmount| number:'1.'+NumOfDecimal}}</td>

                    </tr>
                  </ng-container>
                  <ng-container *ngIf="lstProduct.length == 0">
                    <tr style="height:40px" *ngFor="let i of numbers">
                      <td class="tdstyle"></td>
                      <td class="tdstyle"></td>
                      <td class="tdstyle"></td>
                      <td class="tdstyle"></td>
                      <td class="tdstyle"></td>
                      <td class="tdstyle"></td>
                      <td class="tdstyle"></td>
                    </tr>
            
                  </ng-container>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="col-md-1 col-xs-1 col-xs-1 col-sm-1" *ngIf="lstProduct.length > 0">
          <div class="row">
            <div class="pdicon">
              <div class="icon icon-shape icon-shape-secondary icondiv">
                <img class="iconimg" src="assets/images/retailpos/up-arrow.png" (click)="UpItem()" />
              </div>
            </div>

            <div class="pdicon">
              <div class="icon icon-shape icon-shape-secondary icondiv">
                <img class="iconimg" src="assets/images/retailpos/down-arrow.png" (click)="DownItem()" />
              </div>
            </div>

            <div class="pdicon">
              <div class="icon icon-shape icon-shape-secondary icondiv">
                <img class="iconimg" src="assets/images/retailpos/delete-button.png" (click)="DeletedItem()" />
              </div>
            </div>

            <div class="pdicon">
              <div class="icon icon-shape icon-shape-secondary icondiv">
                <img class="iconimg" src="assets/images/retailpos/plus.png" (click)="PlusQty()" />
              </div>
            </div>

            <div class="pdicon">
              <div class="icon icon-shape icon-shape-secondary icondiv">
                <img class="iconimg" src="assets/images/retailpos/minus-button.png" (click)="MinusQty()" />
              </div>
            </div>

            <div class="pdicon">
              <div class="icon icon-shape icon-shape-secondary icondiv" style="cursor:pointer" (click)="QtyPriceChangePopup('Qty')">
                <span class="iconimg">{{Qty}}</span>
              </div>
            </div>

            <div class="pdicon">
              <div class="icon icon-shape icon-shape-secondary icondiv" style="cursor:pointer" (click)="QtyPriceChangePopup('Price')">
                <span class="iconimg">{{Price}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>    

      <div class="footer-642559391">
        <div class="container">
          <div class="row">

            <div class="col-md-2 col-sm-12">
              <div class="form-group">
                <label for="lblQuantity" class="label">{{lblTotalDiscount}} <span style="color: red; font-weight: 600;" class="ng-star-inserted" (click)="RemoveDiscount()" title="{{ObjdiscountVoucher.VoucherDesc}}" *ngIf="IsDiscountApplied"> [X]</span></label>
                <input nbInput name="lblQuantity" placeholder="Total Discount" [ngModel]="TotalDiscount| number:'1.'+NumOfDecimal" style="width:89%" readonly />&nbsp;&nbsp;
              </div>
            </div>

            <div class="col-md-2 col-sm-12">
              <div class="form-group">
                <label for="lblQuantity" class="label">{{lblSubTotal}}</label>
                <input nbInput name="lblQuantity" placeholder="Sub Total" [ngModel]="TotalAmount| number:'1.'+NumOfDecimal" style="width:89%" readonly />&nbsp;&nbsp;
              </div>
            </div>

            <div class="col-md-2 col-sm-12" *ngIf="IsExtraCharge">
              <div class="form-group">
                <label for="lblExtraCharge" class="label">{{Culture == 'ar' ? ExtraChargeDescriptionArabic : ExtraChargeDescription}}</label>
                <input nbInput name="lblExtraCharge" placeholder="Extra Charge" [ngModel]="ExtraChargeAmount2| number:'1.'+NumOfDecimal" style="width:89%" readonly />&nbsp;&nbsp;
              </div>
            </div>

            <div class="col-md-2 col-sm-12">
              <div class="form-group">
                <label for="lblQuantity" class="label">{{lblTotalVAT}}</label>
                <input nbInput name="lblQuantity" placeholder="Total VAT" [ngModel]="TotalVAT| number:'1.'+NumOfDecimal" style="width:89%" readonly />&nbsp;&nbsp;
              </div>
            </div>
            <div class="col-md-2 col-sm-12">
              <div class="form-group">
                <label for="lblQuantity" class="label">{{lblTotalQuantity}}</label>
                <input nbInput name="lblQuantity" placeholder="Total Quantity" [ngModel]="TotalQty| number:'1.'+NumOfDecimal" style="width:89%" readonly />&nbsp;&nbsp;
              </div>
            </div>

            <div class="col-md-2 col-sm-12">
              <div class="form-group">
                <label for="lblQuantity" class="label">{{lblNetAmount}}</label>
                <input nbInput name="lblQuantity" placeholder="Net Amount" [ngModel]="NetAmount| number:'1.'+NumOfDecimal" style="width:89%" readonly />&nbsp;&nbsp;
              </div>
            </div>
          </div>
        </div>
      </div>




      <div class="footer-59391">
        <div class="container" style="padding: 0px;">
          <div class="row align-items-center">
            <div class="col-lg-12 order-2 order-lg-1 mb-3 mb-lg-0" style="padding: 0px;">
              <ul class="list-unstyled nav-links m-0 nav-left">
                <li class="col-lg-3 col-sm-6 hidepoweredbytext" style="padding: 0px;text-align: center;vertical-align: top;"><a>Powered by <b>Global Informix,</b> 2022</a></li>
                <li class="col-lg-3 col-md-3 col-sm-3 footertext"><a>{{Branch}}: {{ObjOutletInfo.BranchName}}</a></li>
                <li class="col-lg-2 col-md-3 col-sm-3 footertext"><a>{{User}}: {{userName}}</a></li>
                <li class="col-lg-2 col-md-3 col-sm-3 footertext"><a>{{Counter}}:{{counterName}}</a></li>
                <li class="col-lg-2 col-md-3 col-sm-3 footertext"><a>{{StartDate}} : {{DayStartDate}}</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-xs-4 custCol margTop" style="height: 100vh;overflow-y: scroll;">
      <div class="row" style="padding-top: 5px;">
        <div style="width:100%">
          <label for="bBarcode" class="label" style="margin: 0px;">{{Customer}}</label>
          <input name="Customer" nbInput [(ngModel)]="custName" style="width:92%" />
          <!-- <button class="btn btn-secondary" type="button" style="margin-top:-5px;" (click)="openDialogCustomerLookUp()"> -->
          &nbsp;<i class="fas fa-search fontsize" (click)="openDialogCustomerLookUp()"></i>
          <!-- </button> -->
        </div>
     
      </div>

     <br />
      <div id="billDiv" class="billdiv">
 
        <div>
          <div class="calculator card">

            <div class="calculator-keys">
              <button type="button" value="7" style="background:#dfdfdf" class="btn btn-light waves-effect" (click)="calcButtonclick('7')">7</button>
              <button type="button" value="8" style="background:#dfdfdf" class="btn btn-light waves-effect" (click)="calcButtonclick('8')">8</button>
              <button type="button" value="9" style="background:#dfdfdf" class="btn btn-light waves-effect" (click)="calcButtonclick('9')">9</button>
              <button type="button" value="4" style="background:#dfdfdf" class="btn btn-light waves-effect" (click)="calcButtonclick('4')">4</button>
              <button type="button" value="5" style="background:#dfdfdf" class="btn btn-light waves-effect" (click)="calcButtonclick('5')">5</button>
              <button type="button" value="6" style="background:#dfdfdf" class="btn btn-light waves-effect" (click)="calcButtonclick('6')">6</button>
              <button type="button" value="1" style="background:#dfdfdf" class="btn btn-light waves-effect" (click)="calcButtonclick('1')">1</button>
              <button type="button" value="2" style="background:#dfdfdf" class="btn btn-light waves-effect" (click)="calcButtonclick('2')">2</button>
              <button type="button" value="3" style="background:#dfdfdf" class="btn btn-light waves-effect" (click)="calcButtonclick('3')">3</button>
              <button type="button" value="0" style="background:#dfdfdf" class="btn btn-light waves-effect" (click)="calcButtonclick('0')">0</button>
              <button type="button" class="decimal function btn-secondary" value="." (click)="ClickDecimal()">.</button>
              <button type="button" class="all-clear function btn-success btn-sm" value="enter" (click)="removeNumber()"><i class="fal fa-backspace" style="font-size: 45px;"></i></button>



            </div>
            <div class="row col-md-12">
              <button type="button" class="all-clear function btn-success btn-sm" style="width:100%;margin-top:-14px" (click)="ok()">{{Enter}}</button>
            </div>

            <!-- <p class="caption status-danger" *ngIf="enterAmount" style="padding-left: 22px;margin-top: -10px;">please enter Amount!</p> -->
          </div>
        </div>

    </div>
      <hr style="margin-top: 0px;margin-bottom: 5px;">
      <div class="footbtns">

        <!-- Footer Links -->

        <hr style="margin: 5px;">
        <div class="row">

          <div class="divclassforvisibitly col-sm-6 col-md-6 col-lg-6">
            <button class="buttoncss btn btnfinish" (click)="clearclick()">{{CLEAR}}</button>
          </div>

          <div [ngClass]="[IsPaybtnActive == true ? 'divclassforvisibitly col-sm col-md col-lg' : 'displaynonebutton divclassforvisibitly col-sm col-md col-lg']">
            <button [ngClass]="[IsPaybtnActive == true ? 'buttoncss btn btnfinish' : 'displaynonebutton']" [disabled]="loading" (click)="openfinishPopup()">{{PAY}}</button>
          </div>


          <div [ngClass]="[IsDiscountbtnActive == true ? 'divclassforvisibitly col-sm col-md col-lg' : 'displaynonebutton divclassforvisibitly col-sm col-md col-lg']">
            <button [ngClass]="[IsDiscountbtnActive == true ? 'buttoncss btn btnfinish' : 'displaynonebutton']" (click)="VoucherPopup()">{{Discount}}</button>
          </div>
          <div [ngClass]="[IsHoldbtnActive == true ? 'divclassforvisibitly col-sm col-md col-lg' : 'displaynonebutton divclassforvisibitly col-sm col-md col-lg']">
            <button [ngClass]="[IsHoldbtnActive == true ? 'buttoncss btn btnfinish' : 'displaynonebutton']" (click)="OpenHoldOrderConfirmPopup()">{{Hold}}</button>
          </div>
          <div [ngClass]="[IsRecallbtnActive == true ? 'divclassforvisibitly col-sm col-md col-lg' : 'displaynonebutton divclassforvisibitly col-sm col-md col-lg']">
            <button [ngClass]="[IsRecallbtnActive == true ? 'buttoncss btn btnfinish' : 'displaynonebutton']" (click)="OpenHoldOrderPopup()">{{Recall}}</button>
          </div>
        </div>

          <!--<div class="cover-image cover-image--mask"style="background-image: url(assets/images/globalPoslogo.png);">
          </div>-->
</div>

    </div>
    <iframe name="printf" #printfarme id="printf" [src]="ReceiptURL | Urlsafe" (load)="checkIframeLoaded()" width="0" height="0" frameborder="0"></iframe>
  </div>

</div>

