import { Component, Inject, OnInit } from '@angular/core';
import { DataserviseService } from '../../shared/dataservice';
import { TranslatePipe } from '../../shared/pipe/translate.pipe';
import { PopupService } from '../../shared/popup';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UtilitiesService } from '../../shared/utilities.service';
@Component({
  selector: 'ngx-splitcheckspopup',
  templateUrl: './splitcheckspopup.component.html',
  styleUrls: ['./splitcheckspopup.component.scss']
})
export class SplitCheckspopupComponent implements OnInit {
  constructor(private translate: TranslatePipe, private popup: PopupService, private dataservice: DataserviseService, private UtilitiesService :  UtilitiesService, public dialog: MatDialog, public dialogRef: MatDialogRef<SplitCheckspopupComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  SplitChecks: any = this.translate.transform('SplitChecks');
  lstarrayDetails : any = [];
  lstarrayMaster:any = [];
  SelectedOutletID:any;
  OrderID:any;
  TableID:any;
  TableNumber:any;
  ShiftID:any;
  DayID:any;
  POSID:any;
  OutletID:any;
  IsDineTable : boolean = false;

  // currentdatetime:string = new Date().toISOString();

  ngOnInit(): void {debugger
    this.OrderID = this.data.OrderID;
    this.TableID = this.data.TableID;
    this.TableNumber = this.data.TableNumber;
    this.DayID = this.data.DayID;
    this.ShiftID = this.data.ShiftID;
    this.POSID = this.data.POSID;
    this.OutletID = this.data.OutletID;
    this.IsDineTable = this.data.IsDineTable;
    this.getorderdetails_splitChecks();
  }

  btnclose(): void {
    debugger
    this.dialogRef.close();
  }


  LoadGrid() {debugger;
    // this.lstarrayMaster = [];
    // if(localStorage.getItem('counterID&CompanyID'+this.UtilitiesService.getURLCompanyName())){
   
    //   var body = [this.UtilitiesService.getSubscriptionID(), this.SelectedOutletID,this.currentdatetime];
    //   this.dataservice.post('KDSdashboard/GetPendingOrderCategoryWise', body).subscribe(data => {debugger;

    //     if (this.isArrayHasValue(data[0]["lstarrayMaster"]))
    //     {
    //       this.lstarrayMaster = data[0]["lstarrayMaster"];            
    //     }
    //     if (this.isArrayHasValue(data[0]["lstarrayDetails"]))
    //     {
    //       this.lstarrayDetails = data[0]["lstarrayDetails"];                  
    //     }

    //   });    
      
    // }
    // else
    // {
    //   alert('Error')
    // }
    
  }

  isArrayHasValue(obj: any[]): boolean {
    let isArrayWithLength = Boolean(Array.isArray(obj) && obj.length);
    return isArrayWithLength;
  }


  getorderdetails_splitChecks() {debugger
    this.dataservice.get('pos/GetOrdersdetails_SplitChecks/' + this.TableID +'/'+this.UtilitiesService.getSubscriptionID()+'/'+this.DayID+'/'+this.OutletID+'/'+this.POSID).subscribe(data => {debugger
      if (this.isArrayHasValue(data[0]['OrderMaster'])) {
        this.lstarrayMaster = data[0]['OrderMaster']
      }
      if (this.isArrayHasValue(data[0]['OrderDetails'])) {
       this.lstarrayDetails = data[0]['OrderDetails'];
      }
      // if (this.isArrayHasValue(data[0]['OrderMasterforCombineChecks'])) {
      //   this.lstarrayMasterforCombineChecks = data[0]['OrderMasterforCombineChecks'];
      //  }
      //  if (this.isArrayHasValue(data[0]['OrderDetailsforCombineChecks'])) {
      //   this.lstarrayDetailsforCombineChecks = data[0]['OrderDetailsforCombineChecks'];
      //  }
  
    });
  }

  IsNewChecks: boolean = false;
  tablenumber_NewChecks:any;
  AddNewChecks(){debugger
    let no_NewChecks = this.lstarrayMaster.length +1
    this.tablenumber_NewChecks = '-' + no_NewChecks;
     this.tablenumber_NewChecks = this.lstarrayMaster[0].TableNumber.split('-',1)[0] + this.tablenumber_NewChecks;
    this.IsNewChecks = true;
    // this.lstarrayMaster.push({DayID:550, Name:"Cashier 1", OrderDate:"14/11/2022", OrderID: 33734, OrderInvoiceNo : "ORD01000507", OrderNo:55, OrderPayStatus:"Pending", OrderType:"Dine In", OutletID :56, RejectReasonID: 0, ShiftID: 605, TableNumber : "01"});
  }

  
  SelectOrder(item){debugger
    let body = [{OrderID : item.OrderID,ID : this.TableID,TableName:this.TableNumber}];
   this.dialogRef.close(body);
 }

  SelectOrder_NewChecks(){
     let body = [{TableNumber : this.tablenumber_NewChecks}];
    this.dialogRef.close(body);
  }


  IsCombineAllChecks:boolean=true;
  lstarrayMasterforCombineChecks=[];
  lstarrayDetailsforCombineChecks=[];
  CombineAllChecks(){debugger
      if( this.lstarrayMaster.length>1) {
        this.dataservice.get('pos/GetOrdersdetails_CombineChecks/' + this.TableID +'/'+this.UtilitiesService.getSubscriptionID()+'/'+this.ShiftID+'/'+this.OutletID).subscribe(data => {debugger
          if (this.isArrayHasValue(data[0]['OrderMasterforCombineChecks'])) {
            this.lstarrayMasterforCombineChecks = data[0]['OrderMasterforCombineChecks'];
           }
           if (this.isArrayHasValue(data[0]['OrderDetailsforCombineChecks'])) {
            this.lstarrayDetailsforCombineChecks = data[0]['OrderDetailsforCombineChecks'];
           }
            // if (window.confirm('Are you sure you want to Combine All Checks')) {
          let OrderID_Parent = this.lstarrayMaster[0].OrderID;
          let OrderIds_Child = this.lstarrayMaster.map(a => a.OrderID);
          OrderIds_Child = OrderIds_Child.slice(1)+'';
          var body = [this.IsCombineAllChecks,OrderID_Parent,OrderIds_Child,this.lstarrayMasterforCombineChecks,this.lstarrayDetailsforCombineChecks];
          this.dialogRef.close(body);
        // } 
        // else { }
      
        });
       
      }
      else
      {
        // this.selecttable = true;
      }
    } 
  
}
