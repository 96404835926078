<div style="display: block;padding-top: 0px;">
  <div class="child1" style="max-width: none;">
    {{SaleSummary}}
    <img src="./assets/images/lookupclose.png" class="cross-icon" style="float: right;cursor: pointer;margin-top: -5px;" (click)="btnclose()" />
  </div>
</div>
<!--<hr style="margin-top: 10px;" />-->
<!-- <div class="col-md-7"> -->
<div class="table-responsive ">
  <table class="table table-hover">




    <tr>
      <td><b>Sales Net</b></td>
      <td style="text-align:end"><b>{{CurrencyCode}}&nbsp;{{TotalAmountNoTax | number:'1.'+NumOfDecimal+'-'+NumOfDecimal+''}}</b></td>
    </tr>
    <tr *ngIf="ExtraChargeAmount > 0">
      <td><b>{{ExtraChargeDescription}}</b></td>
      <td style="text-align:end"><b>{{CurrencyCode}}&nbsp;{{ExtraChargeAmount | number:'1.'+NumOfDecimal+'-'+NumOfDecimal+''}}</b></td>
    </tr>
    <tr>
      <td><b>VAT </b></td>
      <td style="text-align: end;"> <b>{{CurrencyCode}}&nbsp;{{TaxAmountTotal | number:'1.'+NumOfDecimal+'-'+NumOfDecimal+''}}    </b>      </td>
    </tr>


    <tr>
      <td style="color:red"><b>Sales Total</b></td>
      <td style="text-align:end;color:red"><b>{{CurrencyCode}}&nbsp;{{NetAmountTotal | number:'1.'+NumOfDecimal+'-'+NumOfDecimal+''}}</b></td>
    </tr>
    <br />
    <tr>

      <td><b>Coupon Discount</b></td>
      <td style="text-align:end"><b>{{CurrencyCode}}&nbsp;{{DiscountAmountTotal | number:'1.'+NumOfDecimal+'-'+NumOfDecimal+''}}</b></td>
    </tr>
    <tr>

      <td><b>Refund Amount</b></td>
      <td style="text-align:end"><b>{{CurrencyCode}}&nbsp;{{RefundAmount | number:'1.'+NumOfDecimal+'-'+NumOfDecimal+''}}</b></td>
    </tr>
    <!--<tr>
    <td><b>Loyalty Reward Points</b></td>
    <td style="text-align:end"><b>{{LoyaltyAmount | number:'1.'+NumOfDecimal+'-'+NumOfDecimal+''}}</b></td>
  </tr>-->
    <tr>
      <td><b>No. of Transactions</b></td>
      <td style="text-align:end"><b>{{TransactionTotal}}</b></td>
    </tr>
    <tr>
      <td><b>No. of Customers</b></td>
      <td style="text-align:end"><b>{{NoOfCust}}</b></td>
    </tr>
    <!-- <thead>-->
    <tr>
      <th class="label" style="white-space: nowrap;border-top: none;color: black !important;"><b>{{PayMethod}}</b></th>
      <th class="label" style="border-top: none;text-align: end;color: black !important;"><b>{{Amount}}</b></th>
    </tr>
    <!--</thead>
  <tbody>-->
    <tr *ngFor="let row of lstReportSummary; let i = index;" [attr.data-index]="i" style="font-size: 12px;">
      <td>
        {{row.PaymentMode}}
      </td>

      <td style="text-align:end">
        {{row.Amount | number:'1.'+NumOfDecimal+'-'+NumOfDecimal+''}}
      </td>

    </tr>
    <!-- </tbody>-->
  </table>
</div>
<!-- </div> -->
<!-- <div class="container text-center text-md-left">
    <div class="row">
        <div class="col-md-4 col-sm-4 col-xs-4">
        <ul class="list-unstyled" style="margin-bottom: 0px;">
          <li>
            <a style="color: #2b436e;font-size: 13px"><b>Cash Sale (Exc VAT) :</b></a>
          </li>
          <li style="display: none;">
            <a style="color: #2b436e;font-size: 13px"><b>Credit/Debit Card Sale (Exc VAT) :</b></a>
          </li>
          <li>
            <a style="color: #2b436e;font-size: 13px;cursor: pointer;"><b>Credit Customer Sale (Exc VAT) :</b></a>
          </li>
          <li>
            <a style="color: #2b436e;font-size: 13px;cursor: pointer;"><b>SubTotal :</b></a>
          </li>
          <li>
            <a style="color: #870f1e;font-size: 13px"><b>Total Discount Amount :</b></a>
          </li>
          <li>
            <a style="color: #25932f;font-size: 13px"><b>Total VAT Amount :</b></a>
          </li>
          <li>
            <a style="color: #fd0700;font-size: 13px"><b>Grand Total (Include VAT) :</b></a>
          </li>
        </ul>
      </div>
      <div class="col-md-6 col-sm-6 col-xs-6">
        <ul class="list-unstyled" style="margin-bottom: 0px;">
          <li>
            <a style="color: #2b436e;font-size: 13px"><b>{{ObjectQuickReport.CashSale | number:'1.'+NumOfDecimal}}</b></a>
          </li>
          <li style="display: none;">
            <a style="color: #2b436e;font-size: 13px"><b>{{ObjectQuickReport.CardSale | number:'1.'+NumOfDecimal}}</b></a>
          </li>
          <li>
            <a style="color: #2b436e;font-size: 13px"><b>{{ObjectQuickReport.CreditCustomerSale | number:'1.'+NumOfDecimal}}</b></a>
          </li>
          <li>
            <a style="color: #2b436e;font-size: 13px"><b>{{ObjectQuickReport.SubTotal | number:'1.'+NumOfDecimal}}</b></a>
          </li>
          <li>
            <a style="color: #870f1e;font-size: 13px"><b>{{ObjectQuickReport.TotalDiscountAmount | number:'1.'+NumOfDecimal}}</b></a>
          </li>
          <li>
            <a style="color: #25932f;font-size: 13px"><b>{{ObjectQuickReport.TotalVATAmount | number:'1.'+NumOfDecimal}}</b></a>
          </li>
          <li>
            <a style="color: #fd0700;font-size: 13px"><b>{{ObjectQuickReport.GrandTotalIncludeVAT | number:'1.'+NumOfDecimal}}</b></a>
          </li>
        </ul>
      </div>
    </div>
  </div> -->
