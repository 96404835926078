import { Component, OnInit ,Inject,EventEmitter} from '@angular/core';
import { UtilitiesService } from '../../shared/utilities.service';
import { DataserviseService } from '../../shared/dataservice';
import { MatDialogRef, MAT_DIALOG_DATA,MatDialog } from '@angular/material/dialog';
import { NoofcustomersPopupComponent } from '../../admin/pos_popup/noofcustomers-popup/noofcustomers-popup.component';
import { SpinnerService } from '../../shared/spinner';
import { TranslatePipe } from '../../shared/pipe/translate.pipe';
import { SplitCheckspopupComponent } from '../splitcheckspopup/splitcheckspopup.component';

// enum OrderPerpStatus {
//   All = 'All',
//   New = 'New',
//   InKitchen = 'InKitchen',
//   InRoute = 'InRoute',
//   Rejected = 'REJECTED',
//   Delivered = 'Delivered',
//   Cancel = 'Cancel',
// }

// enum TabTitle {
//   All = 'All',
//   New = 'New',
//   InKitchen = 'In Kitchen',
//   InRoute = 'In Route/Ready',
// }

@Component({
  selector: 'ngx-dinetable-popup',
  templateUrl: './dinetable-popup.component.html',
  styleUrls: ['./dinetable-popup.component.scss']
})
export class DinetablePopupComponent implements OnInit {


  lstDineinTableInfo: any = [];
  // popupdata : any;
  noOfCustomers:any;
  onOk = new EventEmitter();
  SelectTables:any = this.translate.transform('SelectTables');
  Amount:any = this.translate.transform('Amount');
  cultures:any;
  SectionID:any;
  outletID:any;
  IsMergeTable: boolean = false;
  IsDineTable: boolean = false;
  IsTransferTable: boolean = false;
  TableName:any;
  NumOfDecimal: number = 0;
  SalesTypeID:any;
  SalesTypeName:any;
  DayID:any;
  POSID:any;

  constructor(private Utilities: UtilitiesService, private dataservice: DataserviseService,public dialog: MatDialog, public dialogRef: MatDialogRef<DinetablePopupComponent>, @Inject(MAT_DIALOG_DATA) public data: any,private _spinnerService: SpinnerService, private translate : TranslatePipe) 
  {
    debugger
    this.cultures = this.data.culture;
    this.outletID = this.data.outletID;
    this.TableName = this.data.TableNumber;
    this.NumOfDecimal = this.data.NumOfDecimal;
    this.SalesTypeID= this.data.SalesTypeID;
    this.SalesTypeName= this.data.SalesTypeName;
    this.DayID= this.data.DayID;
    this.POSID = this.data.POSID;

    if(this.data.MergeTable != undefined)
    this.IsMergeTable = this.data.MergeTable;

    if(this.data.IsDineTable != undefined)
    this.IsDineTable = this.data.IsDineTable;

    if(this.data.IsTransferTable != undefined)
    this.IsTransferTable = this.data.IsTransferTable;
  }

  ngOnInit(): void {
    this.GetGroupTableInfo();
    
  }

  ngAfterViewInit(){debugger
  }
  DineChangeRights : any = [];
  chkDinerights : boolean = false;

  GetDineinTableInfoSectionWise(sectionid): void {debugger
    this.lstDineinTableInfo = [];
    this.DineChangeRights = [];
    if(this.TableName == 'N/A')
    this.TableName = null;
    this.dataservice.get('pos/GetDineinTableInfo/' + this.Utilities.getSubscriptionID()+'/'+sectionid +'/'+this.IsMergeTable+'/'+this.TableName+'/'+this.IsTransferTable + '/'+ this.Utilities.getUserId()+ '/'+ this.SalesTypeID+'/'+this.DayID+'/'+this.outletID)
      .subscribe(data => {
        debugger;
        if (this.isArrayHasValue(data[0]['DineinTableInfo'])) 
        {
          this.lstDineinTableInfo = data[0]['DineinTableInfo'];
        }
        if (this.isArrayHasValue(data[0]['DineChangeRights'])) 
        {
          this.DineChangeRights = data[0]['DineChangeRights'];
          this.chkDinerights = this.DineChangeRights[0]['Allow'];
        }

      }, error => alert('error'));
  }
  lstTableGroupInfo=[];
  GetGroupTableInfo(): void 
  {

    this.lstDineinTableInfo = [];
    this.dataservice.get('pos/GetGroupTableInfo/' + this.Utilities.getSubscriptionID()+'/'+this.outletID+'/'+this.SalesTypeID)
      .subscribe(data => {
        debugger;
        if (this.isArrayHasValue(data[0]['GroupTableInfo'])) {
          this.lstTableGroupInfo = data[0]['GroupTableInfo'];
          this.SectionID = this.lstTableGroupInfo[0]["ID"];
          this.GetDineinTableInfoSectionWise(this.SectionID);

        }

      }, error => alert('error'));
  }
 
  isArrayHasValue(obj: any[]): boolean {
    let isArrayWithLength = Boolean(Array.isArray(obj) && obj.length);
    return isArrayWithLength;
  }
  btnclose() : void
  {
    debugger
    this.dialogRef.close();
  }

  event:any;
  ClickTable(event,status : any) : void{
    
    debugger
    this.event = event;

    if(this.event.UserId == null || this.event.UserId == undefined || this.event.UserId == this.Utilities.getUserId())
    {
        if(status == 0 && this.IsDineTable && this.event.OrderID == null){
          this.noOfCustomersPopup(this.event);
        }
        else if(this.event.OrderID && status == 1){
          this.dialogRef.close(this.event);
        }
        else if(status > 1){
        this.getOrderSplitChecks(this.event.ID);
        }

        else{
          this.dialogRef.close(this.event);
        }
    }
    else if(this.chkDinerights)
    {
      if(status == 0 && this.IsDineTable && this.event.OrderID == null){
        this.noOfCustomersPopup(this.event);
      }
      else if(this.event.OrderID && status == 1){
        this.dialogRef.close(this.event);
      }
      else if(status > 1){
      this.getOrderSplitChecks(this.event.ID);
      }

      else{
        this.dialogRef.close(this.event);
      }
    }
    else 
    {
      alert('You can not update this record,please contact your admin.')
    }
    // else
    // {
    //   alert('Table is already in use.')
    // }

  }

  noOfCustomersPopup(event) {
    const dialogRef = this.dialog.open(NoofcustomersPopupComponent, {
      width: '400px',
      height: '430px',
      data: {event},
      panelClass: 'my-custom-dialog-class'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        debugger
        this.event = result;
        this.dialogRef.close(this.event);
      }

    });
  }

  okClick(){
    this.dialogRef.close(this.event);
  }
  numberOnly(event, value): boolean {
    //debugger
    const charCode = (event.which) ? event.which : event.keyCode;
    var eventKey = event.key;
    if (event.which == 46 && value.indexOf('.') != -1) {
      event.preventDefault();
    } // prevent if already dot

    else if (charCode == 46) {
      return true;
    }

    else if ((charCode < 48 || charCode > 57)) {
      return false;
    }

    else { return true; }

  }

  onChangeTab(event): void {debugger
  // if(this.lstDineinTableInfo.length>0){
   if(this.cultures == 'ar')
   this.SectionID  = +this.lstTableGroupInfo.filter(x => x.ArabicSection == event.tabTitle)[0].ID;
   else{
    this.SectionID  = +this.lstTableGroupInfo.filter(x => x.Section == event.tabTitle)[0].ID;
   }
   this.GetDineinTableInfoSectionWise(this.SectionID);
  // }
  }

  // lst_OrderSplitChecks=[];
  lstarrayMaster=[];
  // lstarrayDetails=[];
  OrderInvoiceNo_Parent:any;
  getOrderSplitChecks(TableID){debugger
    this.dataservice.get('pos/GetOrdersdetails_SplitChecks/' + TableID +'/'+this.Utilities.getSubscriptionID()+'/'+this.DayID+'/'+this.outletID+'/'+this.POSID).subscribe(data => 
      {debugger 
      if (this.isArrayHasValue(data[0]['OrderMaster'])) {debugger
        this.lstarrayMaster = data[0]['OrderMaster'];
        this.OrderInvoiceNo_Parent = this.lstarrayMaster[0].OrderInvoiceNo;
      }

    if(this.event.OrderID && this.lstarrayMaster.length == 0){
      this.dialogRef.close(this.event);
    }
    else if(this.IsDineTable && this.event.OrderID == null){
        this.noOfCustomersPopup(this.event);
    }
    else if(this.lstarrayMaster.length>1){
      this.openSplitCheckPopup();
    }
    else{
      this.event.OrderInvoiceNo_Parent= this.OrderInvoiceNo_Parent;
      this.dialogRef.close(this.event);
    }
    
      
    });
  }

  openSplitCheckPopup(){debugger
    if (this.event.OrderID != undefined && this.event.OrderID != null && this.event.OrderID != '' && this.event.OrderID != 0) {debugger
    const dialogRef = this.dialog.open(SplitCheckspopupComponent, {
      width: '1100px',
      height: '600px',
      data: { OrderID: this.event.OrderID, TableID:this.event.ID, DayID:this.DayID,OutletID:this.outletID,IsDineTable:this.IsDineTable,TableNumber: this.event.TableNumber,POSID:this.POSID}
      
    });
    
    dialogRef.afterClosed().subscribe(result => {debugger
      this.dialogRef.close(result[0]);
    });
    
  }
  else{
    alert(this.translate.transform('Pleaseselectorderfirst'));
  }
  }
}
