import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NbDateService } from '@nebular/theme';
import { SpinnerService } from '../../../shared/spinner';
@Component({
  selector: 'button-view',
  template: `
  <button style="background-color: #00bfe5;border: none; border-radius: 6px;  outline: none;    height: 30px;    width: 60px;color: white; text-align: center;text-decoration: none;display: inline-block;  font-size: 12px;     margin-right: 5px !important; "  (click)="onClick()">Select</button>`,

})

export class SelectButtonComponent implements ViewCell, OnInit {

  renderValue: string;

  @Input() value: string | number;
  @Input() rowData: any;

  @Output() save: EventEmitter<any> = new EventEmitter();

  ngOnInit() {
    
  }

  onClick() {
    debugger
    this._spinnerService.show();
    this.rowData;
    this.save.emit(this.rowData);
    this._spinnerService.hide();

  }




  ngondestroy() {
    debugger
    this.save.emit(this.rowData);
  }
  constructor(private _spinnerService: SpinnerService,  protected dateService: NbDateService<Date>, public dialog: MatDialog) { }

  isArrayHasValue(obj: any[]): boolean {
    let isArrayWithLength = Boolean(Array.isArray(obj) && obj.length);
    return isArrayWithLength;
  }




}
