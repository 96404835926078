import { Component, ChangeDetectorRef } from '@angular/core';
import { DataserviseService } from '../../shared/dataservice';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilitiesService } from '../../shared/utilities.service';

@Component({
  selector: 'ngx-arabicreceipt',
  templateUrl: './arabicreceipt.component.html',
  styleUrls: ['./arabicreceipt.component.scss'],
})

export class ArabicreceiptComponent {
  ObjCompany: Company = new Company();
  CompanyName: string = '';
  OrderId: string = '';
  InvalidCompanyName: boolean = false;
  ObjOrderMaster: OrderMaster = new OrderMaster();
  ArrOrderDetail: OrderDetail[] = [];
  ArrVoucherData: VoucherData[] = [];
  ObjCurrency: Country = new Country();
  ObjCompanyInfo: CompanyInfo = new CompanyInfo();
  _PaymentDetails: PaymentDetails[] = [];
  CurrencyCode: any = '';
  QRUrl: any = '';


  constructor(private router: Router, private route: ActivatedRoute, private dataservice: DataserviseService, private activatedRoute: ActivatedRoute, private Utilities: UtilitiesService, private cdr: ChangeDetectorRef) {

  }


  title = 'app';
  elementType = 'img';
  elementType1 = 'url';
  value = 'Tehsen Ahmed';
  logo: any = '../../../assets/images/globalinformix.png';
  lstUser = [];
  IsFoodCatering: boolean = false;
  IsFoodCateringCopy: any;
  printTriggered = false;

  ngOnInit(): void {

    debugger


    this.InvalidCompanyName = false;
    debugger
    this.activatedRoute.paramMap.subscribe(x => {
      this.CompanyName = x.get('CompanyName');
      this.OrderId = x.get('OrderId');
      this.IsFoodCateringCopy = x.get('IsFoodCatering');
      this.IsFoodCatering = this.IsFoodCateringCopy == null ? false : this.IsFoodCateringCopy;

      // this.getCompanyData();


      this.getMasterDetailData(this.Utilities.getDecrypt(this.OrderId));
      if (this.CompanyName == null) {
        this.InvalidCompanyName = true;
        return;
      }
    });



    debugger
    this.lstUser = JSON.parse(localStorage.getItem('sessionObject' + this.Utilities.getURLCompanyName()));


    // this.QRUrl = this.Utilities.getQRURL(this.CompanyName, this.OrderId, this.lstUser[0][0]['CountryCode']);

    // console.log(this.QRUrl);

  }

  printComponent() {
    //debugger
    //let myContainer = document.getElementById('invoice-POS') as HTMLInputElement;

    //var docprint = window.open('', 'PRINT', '');
    //docprint.document.write(myContainer.innerHTML);
    //docprint.document.close();
    //docprint.print();
    //docprint.close();

    window.print();
  }
  LoyaltyPoints: any = 0;
  ReturnAmount: any;
  ActivateTipAmount: any;
  getCompanyData() {
    const body = [this.Utilities.getDecrypt(this.CompanyName)];
    this.dataservice.post('OrderOnline/GetCompanyData/', body)
      .subscribe(data => {

        if (this.isArrayHasValue(data[0]['CompanyData'])) {
          this.InvalidCompanyName = false;
          this.ObjCompany.CompanyID = data[0]['CompanyData'][0].CompanyID;
          this.ObjCompany.CompanyName = data[0]['CompanyData'][0].CompanyName;
          this.ObjCompany.outletsCount = data[0]['CompanyData'][0].outletsCount;
          this.ObjCompany.CountryID = data[0]['CompanyData'][0].CountryID;
          this.ObjCompany.hearbout = data[0]['CompanyData'][0].hearbout;
          this.ObjCompany.Mobile = data[0]['CompanyData'][0].Mobile;
          this.ObjCompany.IsOTPRequired = data[0]['CompanyData'][0].IsOTPRequired;
          this.ObjCompany.CurrencyCode = data[0]['CompanyData'][0].CurrencyCode;
          this.ObjCompany.Rate = data[0]['CompanyData'][0].Rate;
          this.ObjCompany.NumOfDecimal = data[0]['CompanyData'][0].NumOfDecimal;
          this.ObjCompany.CountryCode = data[0]['CompanyData'][0].CountryCode;
          this.getMasterDetailData(this.Utilities.getDecrypt(this.OrderId));
        }
        else {
          this.InvalidCompanyName = true;
          this.QRUrl = 'Invalid company name!';
          alert('Invalid company name!');
        }
      }, error => alert(this.Utilities.getconnectionmsg()));

  }
  async getMasterDetailData(OrderID) {
    this.ObjOrderMaster = new OrderMaster();
    this.ArrOrderDetail = [];
    this._PaymentDetails = [];

    await this.dataservice.get('Receipt/GetOrderDataWithCompanyName/' + OrderID + '/' + this.Utilities.getDecrypt(this.CompanyName) + '/' + this.IsFoodCatering)
      .subscribe(data => {
        debugger
        if (this.isArrayHasValue(data[0]['CompanyData'])) {
          this.InvalidCompanyName = false;
          this.ObjCompany.CompanyID = data[0]['CompanyData'][0].CompanyID;
          this.ObjCompany.CompanyName = data[0]['CompanyData'][0].CompanyName;
          this.ObjCompany.outletsCount = data[0]['CompanyData'][0].outletsCount;
          this.ObjCompany.CountryID = data[0]['CompanyData'][0].CountryID;
          this.ObjCompany.hearbout = data[0]['CompanyData'][0].hearbout;
          this.ObjCompany.Mobile = data[0]['CompanyData'][0].Mobile;
          this.ObjCompany.IsOTPRequired = data[0]['CompanyData'][0].IsOTPRequired;
          this.ObjCompany.CurrencyCode = data[0]['CompanyData'][0].CurrencyCode;
          this.ObjCompany.Rate = data[0]['CompanyData'][0].Rate;
          this.ObjCompany.NumOfDecimal = data[0]['CompanyData'][0].NumOfDecimal;
          this.ObjCompany.CountryCode = data[0]['CompanyData'][0].CountryCode;

          //  this.getMasterDetailData(this.Utilities.getDecrypt(this.OrderId));
        }
        else {
          this.InvalidCompanyName = true;
          this.QRUrl = 'Invalid company name!';
          alert('Invalid company name!');
        }
        if (data[0]['MasterData']) {
          this.ObjOrderMaster = data[0]['MasterData'][0];

        }
        if (this.isArrayHasValue(data[0]['DetailData'])) {
          this.ArrOrderDetail = data[0]['DetailData'];
        }
        else {
          this.ArrOrderDetail = [];
        }

        if (this.isArrayHasValue(data[0]['VoucherData'])) {

          this.ArrVoucherData = data[0]['VoucherData'];
        }
        else {
          this.ArrVoucherData = [];
        }

        if (data[0]['Currency']) {
          this.ObjCurrency = data[0]['Currency'][0];
          this.CurrencyCode = this.ObjCurrency.CurrencyCode;
        }

        if (data[0]['CompanyInfo']) {
          this.ObjCompanyInfo = data[0]['CompanyInfo'][0];
        }
        if (data[0]['ConsumedPoints'][0] && data[0]['ConsumedPoints'][0].ConsumedPoints != null && data[0]['ConsumedPoints'][0].ConsumedPoints != undefined) {
          this.LoyaltyPoints = data[0]['ConsumedPoints'][0].ConsumedPoints;
        }
        else {
          this.LoyaltyPoints = 0;
        }

        if (data[0]['QRcodeBase64']) {
          this.QRUrl = data[0]['QRcodeBase64'][0].Base64;
          console.log(this.QRUrl);

        }
        debugger

        if (this.isArrayHasValue(data[0]['PaymentDetails'])) {
          this._PaymentDetails = data[0]['PaymentDetails'];
        }
        if (this.isArrayHasValue(data[0]['ReturnAmount'])) {
          debugger
          this.ReturnAmount = data[0].ReturnAmount[0].CashReturned;
          this.ActivateTipAmount = data[0].ReturnAmount[0].ActivateTipAmount;
        }
      this.cdr.detectChanges();
      setTimeout(() => {
        window.print();
      }, 10);

      }, error => alert(this.Utilities.getconnectionmsg()));
  }


  isArrayHasValue(obj: any[]): boolean {
    let isArrayWithLength = Boolean(Array.isArray(obj) && obj.length);
    return isArrayWithLength;
  }
}

class Company {
  CompanyID: any;
  CompanyName: any;
  outletsCount: any;
  CountryID: any;
  hearbout: any;
  Mobile: any;
  CurrencyCode: any;
  Rate: any;
  NumOfDecimal: any;
  CountryCode: any;
  IsOTPRequired: boolean;
}

class OrderMaster {
  OrderID: any;
  OrderInvoiceNo: any = '';
  PrepIn: any = '';
  PrepInOutlet: any = '';
  OrderPerpStatus: any = 'ALL';
  Brand: any = '';
  OutletID: any = '';
  OutletId: any = '';
  OutletName: any = '';
  AreaName: any = '';
  outletAddress: any = '';
  OrderType: any = '';
  Address: any = '';
  CustomerID: any = '';
  CustomerName: any = '';
  CustomerEmail: any = '';
  CountryCode: any = '';
  PhoneNo: any = '';
  PayType: any = '';
  Channel: any = '';
  PickupFrom: any = '';
  Remarks: any = '';
  SubTotal: any = '';
  TotalTaxAmount: any = '';
  TotalAmount: any = '';
  DeliveryFee: any = '';
  NetAmount: any = '';
  TotalDiscount: any = '';
  TaxID: any = '';
  TaxDescription: any = '';
  TaxPercent: any = '';
  OrderDate: any;
  OrderDateReal: any;
  OrderTime: any;
  CreatedOn: any;
  CreatedBy: any;
  ModifyOn: any;
  ModifyBy: any;
  RejectReasonID: any;
  RejectReason: any;
  RiderID: any;
  CompanyId: any;
  RejectReasonDetail: any = '';
  AddressPK: any = '';
  Cashier: any = '';
  OutletPhone: any = '';
  TableNumber: any;
  AdditionalDirection: any = '';
  PromoCode: any;
  PromoAmount: any;
  LoyaltyAmount: any;
  ArabicOutletName: any;
  OrderNo: any;
  IsExtraCharge: any;
  ExtraChargeAmount: any;
  OrderPayStatus: any;
  IsQRVisible: boolean = true;
  DeliverectChannelOrderDisplayId: any;
  DeliverectChannel: any;
  TIPAmount: any;

  IsMobNoVisibleReceipt: boolean;
  ReceiptMsg: any;
  ImagePath: any;
  NoOfCustomers: any;
}

class OrderDetail {
  OrderDetailID: any;
  OrderID: any;
  ProductId: any;
  ProductName: any = '';
  Quantity: any;
  UnitID: any;
  AddOnID: any;
  AddOnName: any;
  Amount: any;
  ProductDetailID: any;
  ProductSizeName: any = '';
  Description: any = '';
  OrignalAmount: any;
  AProductName: any;
}

class Country {
  CurrencyCode: any = '';
  Rate: any = '';
  NumOfDecimal: any = '';
  CountryName: any = '';
  CountryCode: any = '';
}

class CompanyInfo {
  ID: any;
  TextColor: any = '';
  BaseColor: any = '';
  Title: any = '';
  Message: any = '';
  MainImagePath: any = '';
  MainImageName: any = '';
  IconImagePath: any = '';
  IconImageName: any = '';
  LogoImagePath: any = '';
  LogoImageName: any = '';
  BrandName: any = '';
  VATNo: any = '';
  ReceiptMsg: any = '';
}
class VoucherData {
  RowID: any;
  OrderId: any;
  VoucherCode: any;
  VoucherId: any;
  IsPercent: any;
  VoucherAmount: any;
  CompanyId: any;
}

class PaymentDetails {
  Description: any;
  NetAmount: any;
}
