

  <link href="//stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet">

<nb-card>
 
  
  <nb-card-body >


    <div class="child1" style="max-width: 100% !important ;">
      {{HoldOrders}}
      <img src="./assets/images/lookupclose.png" (click)="btnclose()" style="float: right; width: 25px;cursor: pointer;">
    </div>
    
    <hr style="margin-top: 10px;"/>

    <div class="row col-lg-12 col-md-12 col-xs-12 col-sm-12">

      <div class="col-lg-4 col-md-4 col-xs-4 col-sm-4 HideOnMobile" style="background-color: #EFF4F9;min-height: 30px ; border-right:1px solid lightgray; ">
        <div class="row">
          <input type="text" nbInput fullWidth status="basic" [(ngModel)]="SearchInList"  fieldSize="medium" name="search" style=" border-radius: unset;background-color: #EFF4F9" placeholder="Search...">

        </div>
        <div class="row" style="overflow-x: auto; max-height: 430px;">
          <div id="orders" style="background-color: white;width:100%">

            <div *ngFor="let data of lstarray | filter:SearchInList;let i = index" class="Order-list" [ngClass]="{'selected': i === HighlightRow}">
              <div class="row form-group col-lg-12 col-md-12 col-xs-12 col-sm-12" (click)="getMasterDetailData(data.OrderID,i)" style="cursor: pointer" >
                <div class="form-group col-lg-12 col-md-12 col-xs-12 col-sm-12">

                  <div class="row">
                    <div class="col1">
                      <p class="numberorderNo mb-1" style="color: #292929"><strong>{{data.ReferenceNo}}</strong></p>
                      <small *ngIf ="data.OtherChannel != null" style="font-size: 14px; font-weight:700;float: right;" class="Takeawaycolor">{{data.OtherChannel}}</small>
                      <small style="display:block!important;font-weight:500;font-size:58%!important"><span style="font-size: 15px;font-weight: 700;" [ngClass]="{'Deliverycolor' : data.OrderTypeID == '3','Takeawaycolor' : data.OrderTypeID == '2','NAcolor' : data.OrderTypeID == 'N/A','DineColor' : data.OrderTypeID == '1'}" >{{data.OrderType}} <span *ngIf="data.TableNumber">({{data.TableNumber}})</span></span></small>
                      <small *ngIf ="data.OtherChannel != null " style="font-weight:700;font-size:10px !important;float: right;">{{data.DeliverectIsAlreadyPaid && data.DeliverectIsOrderCancel ? 'Cancel Order' : data.DeliverectIsAlreadyPaid ? 'Paid Order' : 'Unpaid Order' }}</small>
                      <small style="display:block!important;font-weight:500;font-size:58%!important">{{data.OrderDate }}</small>

                      <small style="display:block!important;font-weight:500;font-size:58%!important">{{data.Status}}</small>
                      <!-- <small style="display:block!important;font-weight:600;font-size:90%!important">{{data.OrderType}}</small> -->
                      <small style="display:block!important;font-weight:600;font-size:70%!important">Total: {{ObjCompany[0]["CurrencyCode"]}} {{data.NetAmount}}</small>
                      <small style="display:block!important;font-weight:600;font-size:65%!important" *ngIf = "data.PhoneNo" >Phone No.: {{data.PhoneNo}}</small>
                    </div>
                    <div class="col2" *ngIf="ActiveTabInKitchen">
                      <div class="labelPrep">Prep In</div>
                      <div class="blink_me numberCircle">
                        <span class="numberPrep">{{ data.PrepIn | mindiff: data.ModifyOn : data.PrepIn }}</span>
                        <span class="Prep">MIN</span>
                      </div>

                    </div>
                   
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>
 
      </div>

      <div class="col-lg-8 col-md-8 col-xs-8 col-sm-8">
        <div class="HRVisible col-lg-12 col-md-12 col-xs-12 col-sm-12">
          <div class="row">
            <div class="orderCard__header px-3 col-lg-12 col-md-12 col-xs-12 col-sm-12" style="margin-top:-5px;">



              <div class="row ">
                <div class="form-group col-lg-3 col-md-3 col-xs-3 col-sm-3 ">
                  <button *ngIf="!ObjOrderMaster.DeliverectIsOrderCancel" nbButton fullWidth status="danger" class="float-right" (click)="SelectOrder()">{{lblSelectOrder}}</button>
                </div>


                <div class="col-md-3">
                  <span style=" display: inline-block !important;text-align: center !important; white-space: nowrap !important; vertical-align: baseline !important; border-radius: 0.25rem !important; background-color: #355692; color: #fff;height: 38px; padding-top: 9px !important; border: 0;  cursor: pointer; width: 135px;" *ngIf="lstarray?.length > 0" (click)="DeleteOrders(ObjOrderMaster.OrderID, true)">Void Order <i class="fa fa-trash-o"></i></span>
                </div>
                <div class="col-md-3">
                  <span style=" display: inline-block !important;text-align: center !important; white-space: nowrap !important; vertical-align: baseline !important; border-radius: 0.25rem !important; background-color: #355692; color: #fff;height: 38px; padding-top: 9px !important; border: 0;  cursor: pointer; width: 135px;" *ngIf="lstarray?.length > 0" (click)="DeleteOrders(lstOrderIds, false)">Void All <i class="fa fa-trash-o"></i></span>
                </div>

              </div>
        
            
            </div>

          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
          <div class="row">
            
           
            <div class="clsM2 form-group col-lg-4 col-md-4 col-xs-4 col-sm-4">
              <div class="algStyle">
                <label class="label">{{ReferenceNo}}</label>

              </div>
              <div class="algStyle">
                <label class="label"><strong>{{ObjOrderMaster.ReferenceNo}}</strong></label>
              </div>
            </div>
            <div class="clsM2 form-group col-lg-4 col-md-4 col-xs-4 col-sm-4">
              <div class="algStyle">
                <label class="label">{{OrderDate}}</label>

              </div>
              <div class="algStyle">
                <label class="label"><strong>{{ObjOrderMaster.OrderDate }}</strong></label>
              </div>
            </div>

            <div class="clsM1 form-group col-lg-4 col-md-4 col-xs-4 col-sm-4">
              <div class="">
                <label class="label">{{OrderType}}</label>
              </div>
              <div class="">
                <label class="label"><strong>{{ObjOrderMaster.OrderType}}</strong></label>
              </div>
            </div>
            <div class="clsM2 form-group col-lg-4 col-md-4 col-xs-4 col-sm-4">
              <div class="algStyle">
                <label class="label">{{TableNo}}</label>

              </div>
              <div class="algStyle">
                <label class="label"><strong>{{ObjOrderMaster.TableNumber}}</strong></label>
              </div>
            </div>

            <div class="clsM1 form-group col-lg-4 col-md-4 col-xs-4 col-sm-4">
              <div class="">
                <label class="label">{{Customer}}</label>
              </div>
              <div class="">
                <label class="label"><strong>{{ObjOrderMaster.CustomerName}}</strong></label>
              </div>
            </div>
            <div class="clsM2 form-group col-lg-4 col-md-4 col-xs-4 col-sm-4">
              <div class="algStyle">
                <label class="label">{{PhoneNo}}</label>

              </div>
              <div>
                <label class="label"><strong>{{ObjOrderMaster.PhoneNo}}</strong></label>
              </div>
            </div>
            <div *ngIf="ObjOrderMaster.Remarks" class="clsM1 form-group col-lg-4 col-md-4 col-xs-4 col-sm-4">
              <div>
                <label class="label">{{Remarks}}</label>
              </div>
              <div class="">
                <label class="label"><strong>{{ObjOrderMaster.Remarks}}</strong></label>
              </div>
            </div>

          </div>
        </div>
        <div class="form-group col-lg-12 col-md-12 col-xs-12 col-sm-12">
          <div class="row">
            <div class="cls1 col-lg-1 col-md-1 col-xs-1 col-sm-1">
              <label class="label">{{Qty}}</label>
            </div>
            <div class="cls2 col-lg-3 col-md-3 col-xs-3 col-sm-3">
              <label class="label">{{Description}}</label>
            </div>
            <div class="cls2 col-lg-5 col-md-5 col-xs-5 col-sm-5">
              <label class="label">{{SpecialInstruction}}</label>
            </div>
            <div class="cls3 col-lg-3 col-md-3 col-xs-3 col-sm-3">
              <label class="label">{{Amount}}</label>
            </div>

          </div>
          <hr class="hrdivider" />
          <div *ngFor="let dod of ArrOrderDetail"  [ngClass]="[dod.DelFlag == 1 ? 'padd_btm_background row' : 'row']">
            <div class="cls1 col-lg-1 col-md-1 col-xs-1 col-sm-1">
              <label class="label" *ngIf="dod.AddOnID == 0">{{dod.Quantity}}</label>
            </div>
            <div class="cls2 col-lg-3 col-md-3 col-xs-3 col-sm-3" *ngIf="dod.AddOnID == 0">
              <label class="label" [ngClass]="[dod.ComboProductID != null ? 'ComboColor' : '#434444']" >{{cultures == 'en' ? dod.ProductName : dod.AProductName}} <span *ngIf="dod.ProductSizeName || dod.AProductSizeName">({{cultures == 'en' ? dod.ProductSizeName : dod.AProductSizeName}})</span></label>
            </div>
            <div class="cls2 col-lg-3 col-md-3 col-xs-3 col-sm-3" *ngIf="dod.AddOnID != 0">
              <label class="label" style="color:#797979!important">{{cultures == 'en' ? dod.AddOnName : dod.ArabicAddOnName }}</label>
            </div>
            <div class="cls2 col-lg-5 col-md-5 col-xs-5 col-sm-5" *ngIf="dod.AddOnID == 0">
              <label class="label">{{dod.SpecialRequest}}</label>
            </div>
            <div class="cls3 col-lg-3 col-md-3 col-xs-3 col-sm-3">

              <label class="label" style="text-align: left;" *ngIf="dod.Amount != undefined && dod.Amount != ''">{{ObjCompany[0]["CurrencyCode"]}}</label>
                <label class="label" style="text-align: right !important;width: 66%;" >{{dod.Amount|number:'1.3-3'}}</label>
         
            </div>
          </div>
        </div>
        <div class="form-group">
          <hr class="hrdivider" />
        </div>
        <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">

          <div class="row">
            <div class="cls1 col-lg-6 col-md-6 col-xs-6 col-sm-6">
              
            </div>
            <div class="cls2 col-lg-3 col-md-3 col-xs-3 col-sm-3">
              <label class="label">{{SubTotal}}:</label>
            </div>
            <div class="cls3 col-lg-3 col-md-3 col-xs-3 col-sm-3">

              <label class="label" style="text-align: left;" >{{ObjCompany[0]["CurrencyCode"]}}</label>
                <label class="label" style="text-align: right !important;width: 66%;" >{{ObjOrderMaster.TotalAmount|number:'1.3-3'}}</label>

              <!-- <label class="label">{{ObjCompany[0]["CurrencyCode"]}}&nbsp;{{ObjOrderMaster.TotalAmount|number:'1.3-3'}}</label> -->
            </div>

          </div>
          <div *ngIf="ObjOrderMaster.IsExtraCharge == 1">
            <div class="row">
              <div class="cls1 col-lg-6 col-md-6 col-xs-6 col-sm-6">
              </div>
              <div class="cls2 col-lg-3 col-md-3 col-xs-3 col-sm-3">
                <label class="label">{{ObjOrderMaster.ExtraChargeDescription}}:</label>
              </div>
              <div class="cls3 col-lg-3 col-md-3 col-xs-3 col-sm-3">
                <label class="label" style="text-align: left;" >{{ObjCompany[0]["CurrencyCode"]}}</label>
              <label class="label" style="text-align: right !important;width: 66%;" >{{ObjOrderMaster.ExtraChargeAmount|number:'1.3-3'}}</label>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="cls1 col-lg-6 col-md-6 col-xs-6 col-sm-6">
            </div>
            <div class="cls2 col-lg-3 col-md-3 col-xs-3 col-sm-3">
              <label class="label">{{Discount}}:</label>
            </div>
            <div class="cls3 col-lg-3 col-md-3 col-xs-3 col-sm-3">


              <label class="label" style="text-align: left;" >{{ObjCompany[0]["CurrencyCode"]}}</label>
              <label class="label" style="text-align: right !important;width: 66%;" >{{ObjOrderMaster.TotalDiscount|number:'1.3-3'}}</label>
              <!-- <label class="label">{{ObjCompany[0]["CurrencyCode"]}}&nbsp;{{ObjOrderMaster.TotalDiscount|number:'1.3-3'}}</label> -->
            </div>

          </div>

          <div class="row">
            <div class="cls1 col-lg-6 col-md-6 col-xs-6 col-sm-6">
            </div>
            <div class="cls2 col-lg-3 col-md-3 col-xs-3 col-sm-3">
              <label class="label">{{Tax}}:</label>
            </div>
            <div class="cls3 col-lg-3 col-md-3 col-xs-3 col-sm-3">

              <label class="label" style="text-align: left;" >{{ObjCompany[0]["CurrencyCode"]}}</label>
              <label class="label" style="text-align: right !important;width: 66%;" >{{ObjOrderMaster.TotalTaxAmount|number:'1.3-3'}}</label>

              <!-- <label class="label">{{ObjCompany[0]["CurrencyCode"]}}&nbsp;{{ObjOrderMaster.TotalTaxAmount|number:'1.3-3'}}</label> -->
            </div>
          </div>
          <div class="row" *ngIf="ObjOrderMaster.OrderType == 'Delivery'">
            <div class="cls1 col-lg-6 col-md-6 col-xs-6 col-sm-6">
            </div>
            <div class="cls2 col-lg-3 col-md-3 col-xs-3 col-sm-3">
              <label class="label">{{DeliveryFee}}:</label>
            </div>
            <div class="cls3 col-lg-3 col-md-3 col-xs-3 col-sm-3">

              <label class="label" style="text-align: left;" >{{ObjCompany[0]["CurrencyCode"]}}</label>
              <label class="label" style="text-align: right !important;width: 66%;" >{{ObjOrderMaster.DeliveryFee|number:'1.3-3'}}</label>

              <!-- <label class="label">{{ObjCompany[0]["CurrencyCode"]}}&nbsp;{{ObjOrderMaster.DeliveryFee|number:'1.3-3'}}</label> -->
            </div>
          </div>
          <div class="row">
            <div class="cls1 col-lg-6 col-md-6 col-xs-6 col-sm-6">
            </div>
            <div class="cls2 col-lg-3 col-md-3 col-xs-3 col-sm-3">
              <label class="label"><strong>{{NetAmount}}:</strong></label>
            </div>
            <div class="cls3 col-lg-3 col-md-3 col-xs-3 col-sm-3">

              <label class="label" style="text-align: left;" >{{ObjCompany[0]["CurrencyCode"]}}</label>
              <label class="label" style="text-align: right !important;width: 66%;" >{{ObjOrderMaster.NetAmount|number:'1.3-3'}}</label>

            </div>

          </div>

        </div>




   

      </div>
    </div>



  </nb-card-body>
</nb-card>

