

<nb-card>

  <nb-card-body>
    <div class="row parent1">
      <div class="col-md-2 ">
        <div class="child1">
          {{Items}}
        </div>
      </div>
      <div class="col-md-8"></div>
      <div class="col-md-2">
        <img src="./assets/images/lookupclose.png" (click)="dialogRef.close()" style="float: right; width: 25px;cursor: pointer;">

      </div>
    </div>
    <hr />

    <div class="row">
      <div class="col-md-12 col-sm-12">
        <div class="form-group">
          <div class="table-responsive" style="width:95%;max-height:475px">

            <!--<ng2-smart-table [settings]="settings" (userRowSelect)="onUserRowSelect($event)" [source]="lstDetail"></ng2-smart-table>-->
            <div class="form-group col-lg-10 col-md-10 col-xs-10 col-sm-10">
              <div class="row">
                <div class="cls2 col-lg-1 col-md-1 col-xs-1 col-sm-1">
                  <label class="label">{{Action}}</label>
                </div>
                <div class="cls2 col-lg-4 col-md-4 col-xs-4 col-sm-4">
                  <label class="label">{{ItemDescription}}</label>
                </div>
                <div class="cls1 col-lg-1 col-md-1 col-xs-1 col-sm-1">
                  <label class="label">{{Qty}}</label>
                </div>

                <div class="cls3 col-lg-2 col-md-2 col-xs-2 col-sm-2">
                  <label class="label">{{Price}}</label>
                </div>
                <div class="cls3 col-lg-2 col-md-2 col-xs-2 col-sm-2">
                  <label class="label">{{Amount}}</label>
                </div>

              </div>
              <hr class="hrdivider" />
              <div *ngFor="let dod of lstDetail;let i = index;" [ngClass]="[dod.DelFlag == 1 ? 'padd_btm_background row' : 'row']">
                <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12" *ngIf="i>0 && dod.AddOnID == 0">
                  <hr class="hrdivider" />
                </div>
                <div class="cls2 col-lg-1 col-md-1 col-xs-1 col-sm-1">
                  <label class="label" *ngIf="dod.AddOnID == 0" (click)="onUserRowSelect(dod)" style="cursor: pointer; text-decoration: underline;">{{Select}}</label>
                </div>

                <div class="cls2 col-lg-4 col-md-4 col-xs-4 col-sm-4" *ngIf="dod.AddOnID == 0">
                  <label class="label">{{ cultre == 'ar' ? dod.AProductName :  dod.ProductName}} {{dod.ProductSizeName == 'N/A' ? '' :  cultre == 'ar' ? '('+ dod.ArabicProductSizeName+')' : '('+dod.ProductSizeName +')'}}</label>
                </div>
                <div class="cls2 col-lg-4 col-md-4 col-xs-4 col-sm-4" *ngIf="dod.AddOnID != 0">
                  <label class="label" style="color:#7d7b7b!important">{{cultre == 'ar' ? dod.ArabicAddOnName : dod.AddOnName}}</label>
                </div>
                <div class="cls1 col-lg-1 col-md-1 col-xs-1 col-sm-1">
                  <label class="label" *ngIf="dod.AddOnID == 0">{{dod.Quantity}}</label>
                </div>


                <div class="cls3 col-lg-2 col-md-2 col-xs-2 col-sm-2">
                  <label class="label" [ngStyle]="{'color':dod.AddOnID != 0 ? '#7d7b7b' : '#444' }">{{CurrencyCode}}&nbsp;{{dod.Price == null ? 0.00 : dod.Price | number:'1.'+NumOfDecimal+'-'+NumOfDecimal+''}}</label>
                </div>
                <div class="cls3 col-lg-2 col-md-2 col-xs-2 col-sm-2">
                  <label class="label" [ngStyle]="{'color':dod.AddOnID != 0 ? '#7d7b7b' : '#444' }">{{CurrencyCode}}&nbsp;{{dod.TotalAmount == null ? 0.00 :dod.TotalAmount  | number:'1.'+NumOfDecimal+'-'+NumOfDecimal+''}}</label>
                </div>
                <hr class="hrdivider" *ngIf="dod.AddOnID != 0" />
              </div>

              </div>
          </div>
          <br />
        </div>

      </div>

    </div>
  </nb-card-body>

</nb-card>
