import { Component, OnInit } from '@angular/core';
import { DataserviseService } from '../../shared/dataservice';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '../../shared/translate.service';
import {  NbLayoutDirection, NbLayoutDirectionService} from '@nebular/theme';
import { UtilitiesService } from 'app/shared/utilities.service';
import { AboutUsComponent } from '../../about-us/about-us.component';
@Component({
  selector: 'ngx-poslogin',
  templateUrl: './poslogin.component.html',
  styleUrls: ['./poslogin.component.scss']
})
export class PosloginComponent implements OnInit {
  UserActiveInNoofBranch = [];
  counterName:any;
  showlblInvalidCounter:boolean = false;
  LogoImage:any;
  myImgUrl:string='~/assets/images/digiPOS-Logo.png ';
  lstCompanyInfo = [];
  VersionNo: any = '';
  constructor(public dialog: MatDialog, private translate: TranslateService,private directionService : NbLayoutDirectionService,private dataservice: DataserviseService, public router: Router, private _utility : UtilitiesService)
  {
    localStorage.setItem('LanguageCulture'+this._utility.getURLCompanyName(), 'en');
  }

  ngOnInit(): void {
    debugger
    this.VersionNo = this._utility.GetVersionNo();
    let val = localStorage.getItem('PosloginCheckCulture'+this._utility.getURLCompanyName()); 
    if(val == undefined || val == '0')
    {
      localStorage.setItem('PosloginCheckCulture'+this._utility.getURLCompanyName(), '1'); 
      window.location.reload();
    }

    let url  = this.router.url;
    localStorage.setItem('LanguageCulture'+this._utility.getURLCompanyName(), 'en');
    this.counterName = url.split("/").pop();
    this.counterName = this.counterName.charAt(0).toUpperCase() + this.counterName.slice(1);
    this.objSD.CounterName = this.counterName;
    var result = url.substring(0, url.lastIndexOf("/") + 0)
    this.objSD.Name = result.split("/").pop();
    this.checkCounterIsValid();
  }

  DefaultLanguage: any = 'en';
  changeLocation(event): void {
    debugger
    this.DefaultLanguage = event;
    localStorage.setItem('LanguageCulture'+this._utility.getURLCompanyName(), this.DefaultLanguage);
    this.translate.useLogin(this.DefaultLanguage);
  }

  CountryCode : any;
  MachineType:any;
    checkCounterIsValid(): void {
      this.dataservice.get('Users/checkCounterIsValid/' + this.objSD.Name + '/' + this.counterName).subscribe(data => {
        debugger
        if (data["Table"][0].Column1 == 'Invalid Company') {
          this.DisplayPopup('InvalidCompany');
            this.showlblInvalidComp =true;
          }
        else if (data["Table"][0].Column1 == 'Invalid Counter') {
          this.DisplayPopup('InvalidCounter')
             this.showlblInvalidCounter = true;
          }
          else if (data["Table"].length > 0){
            this.objSD.CompanyId = data["Table"][0].CompanyID;
            this.objSD.OutletIds = data["Table"][0].BranchID;
            //this.objSD.IsAccessCashierScreen = data["NewTable"][0].IsAccessCashierScreen;
            this.MachineType = data["Table"][0].MachineType;
            this.LogoImage = data["Table2"][0].Column1;

            this.CountryCode = data["Table1"][0].CountryCode;
            let body = [{ 'CounterID': data["Table"][0].Code, 'CompanyID': data["Table"][0].CompanyID, 'CounterName': this.counterName, 'CompanyName': this.objSD.Name, 'LogoImageComp': this.LogoImage, 'CountryCode': this.CountryCode, 'MachineType': this.MachineType, 'ISCalculateTaxProductWise': data["Table"][0].ISCalculateTaxProductWise,'DefaultSalesTypeID':data["Table"][0].DefaultSalesTypeID,'OrderType':data["Table"][0].OrderType,'TypeArabic':data["Table"][0].TypeArabic}]
            localStorage.setItem('counterID&CompanyID'+this._utility.getURLCompanyName(),JSON.stringify(body))
          }
 
        }, error => alert('error'));
    }
  

  objSD : User = new User();
  lstUser = [];
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  Click1() : void
  {
    this.objSD.PosPin = this.objSD.PosPin + '1'; 
    this.objSD.PosPin = this.objSD.PosPin.substring(0,20);
  }
  Click2() : void
  {
    this.objSD.PosPin = this.objSD.PosPin + '2'; 
    this.objSD.PosPin = this.objSD.PosPin.substring(0,20);
  }
  Click3() : void
  {
    this.objSD.PosPin = this.objSD.PosPin + '3';
    this.objSD.PosPin = this.objSD.PosPin.substring(0,20);
  }
  Click4() : void
  {
    this.objSD.PosPin = this.objSD.PosPin + '4'; 
    this.objSD.PosPin = this.objSD.PosPin.substring(0,20);
  }
  Click5() : void
  {
    this.objSD.PosPin = this.objSD.PosPin + '5'; 
    this.objSD.PosPin = this.objSD.PosPin.substring(0,20);
  }
  Click6() : void
  {
    this.objSD.PosPin = this.objSD.PosPin + '6'; 
    this.objSD.PosPin = this.objSD.PosPin.substring(0,20);
  }
  Click7() : void
  {
    this.objSD.PosPin = this.objSD.PosPin + '7';
    this.objSD.PosPin = this.objSD.PosPin.substring(0,20);
  }
  Click8() : void
  {
    this.objSD.PosPin = this.objSD.PosPin + '8'; 
    this.objSD.PosPin = this.objSD.PosPin.substring(0,20);
  }
  Click9() : void
  {
    this.objSD.PosPin = this.objSD.PosPin + '9'; 
    this.objSD.PosPin = this.objSD.PosPin.substring(0,20);
  }
  Click0() : void
  {
    this.objSD.PosPin = this.objSD.PosPin + '0'; 
    this.objSD.PosPin = this.objSD.PosPin.substring(0,20);
  }
  ClickAC() : void
  {
    
    this.objSD.PosPin = ''; 
  }
  ClickDecimal() : void
  {
    
    this.Login();
  
  }

  DisplayPopup(value): void {
    let message = value;
    let height = '200px';
    if (value == 'IsAccessCashierScreen')
      height = '300px';

    let dialogRef = this.dialog.open(AboutUsComponent,
      {
        width: '500px',
        height: height,

        data: { message },
      });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {


      }

    });
  }

  showlblPin:boolean = false;
  showlblInvalidComp:boolean = false;
  showlblPosPin: boolean = false;
  showlblSubsEndDate: boolean = false;
  showlblSubsActive: boolean = false;
  Login() {
    if(this.showlblInvalidCounter || this.showlblInvalidComp ){
      return
    }
    if(this.objSD.PosPin =="" || this.objSD.PosPin ==undefined ||this.objSD.PosPin == null){
      this.showlblPosPin = true;
      this.DisplayPopup('EnterPOSPin');
      return
    }
    else{
      this.showlblPosPin = false;

    }
  
    this.dataservice.post('Users/POSlogin', this.objSD).subscribe(data => {debugger
      if(data["Table"][0].IsValidCompany == 'Invalid Company'){
        this.showlblInvalidComp = true;
        this.DisplayPopup('InvalidCompany');
        return
      }
      else{
        this.showlblInvalidComp = false;
      }
      if(data["Table1"][0].IsValidPin == 'Invalid Pin'){
        this.showlblPin = true;
        this.DisplayPopup('InvalidPIN');
        return
      }
      else{
        this.showlblPin = false;
      }
      if (data["Table1"][0].IsAccessCashierScreen == false) {
        this.DisplayPopup('IsAccessCashierScreen');
        return;
      }

      let d = new Date();
      const sysDate = new Date((d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate()));
      const dbDate = new Date(data["Table1"][0].SubEndDate);
      if (sysDate > dbDate) {
        this.showlblSubsEndDate = true;
        return;
      }
      else {
        this.showlblSubsEndDate = false;
      }
      if(data["Table1"][0].IsActive == 0){
        this.showlblSubsActive = true;
        return
      }
      else{
        this.showlblSubsActive = false;
      }
      
debugger
      
      var test = 'en';
      if (localStorage.getItem('LanguageCulture'+data['Table1'][0].CompanyNameLC) != null)
      { 
        if (localStorage.getItem('LanguageCulture'+data['Table1'][0].CompanyNameLC) == 'en') 
        {
          test = 'en'
        }
        else 
        {
          test = 'ar';
        }
      }
          const oppositeDirection = test == 'en' ? NbLayoutDirection.LTR : NbLayoutDirection.RTL;
          this.directionService.setDirection(oppositeDirection);

      this.UserActiveInNoofBranch = data["Table2"];
      localStorage.setItem('UserActiveinNoofBranch'+data['Table1'][0].CompanyNameLC, JSON.stringify(this.UserActiveInNoofBranch) );
      this.lstUser = data["Table1"];
      debugger
      var DefaultOutlet = {'ID' : this.lstUser[0].OutletID, 'OutletName' : this.lstUser[0].OutletName, 'IsZatcaInvoiceOn' : this.lstUser[0].IsZatcaInvoiceOn };
      localStorage.setItem('SelectedBranchRow'+data['Table1'][0].CompanyNameLC,JSON.stringify(DefaultOutlet));

      localStorage.setItem('ActiveUser'+data['Table1'][0].CompanyNameLC, JSON.stringify(this.lstUser));
      debugger
      if(this.lstUser.length>0 ){
        this.lstCompanyInfo.push(this.lstUser);
      localStorage.setItem('sessionObject'+data['Table1'][0].CompanyNameLC, JSON.stringify(this.lstCompanyInfo));

       var companyPath = this.lstUser[0].CompanyName;
      this.router.config.splice(0, 0, {
        path: companyPath,
        loadChildren: () => import('../../pages/pages.module').then(m => m.PagesModule),
      });
          // this.router.navigate(['/dashboardpos/maindashboard']);
          if(this.MachineType == 'KDS')
           this.router.navigate([this.lstUser[0].CompanyName  + '/dashboardpos/kdsdashboard']);
          else if(this.MachineType == 'QMS')
           this.router.navigate([this.lstUser[0].CompanyName  + '/dashboardpos/queuedashboard']);
           else
           this.router.navigate([this.lstUser[0].CompanyName  + '/dashboardpos/maindashboard']);
          // window.location.href = 'http://localhost:4200/Papis-Pizza/dashboardpos/maindashboard';
    }
     
    })

  }

}

class User
{

  PosPin : any = '';
  CompanyId : any;
  Name:any;
  OutletIds:any;
  CounterName: any;
  IsAccessCashierScreen: any;
 }
