import { Component } from '@angular/core';
import { DataserviseService } from '../../shared/dataservice';
import { PopupService } from '../../shared/popup';
import { ActivatedRoute } from '@angular/router';
import { formatDate } from '@angular/common';
import { UtilitiesService } from '../../shared/utilities.service';
import { MatDialog } from '@angular/material/dialog';
import { DeliveryPopupComponent } from '../delivery-popup/delivery-popup.component';
import { RetailPaidOrdersPopupComponent } from '../retailpos-popup/retailpaidorderspopup/retailpaidorderspopup.component';
import { RetailSaleReturnPopupComponent } from '../retailpos-popup/creditnote/retailsalereturnpopup/retailsalereturnpopup.component';

import { Router } from '@angular/router';
import { DecimalPipe } from '@angular/common';
import { TranslatePipe } from '../../shared/pipe/translate.pipe';

import { delay } from "rxjs/operators";
import { RetailQuickreportPopupComponent } from '../../admin/retailpos-popup/retailquickreport-popup/retailquickreport-popup.component';
import { RetailItempoppupComponent } from '../retailpos-popup/retailitempoppup/retailitempoppup.component';
import { HoldOrderPopupComponent } from '../retailpos-popup/holdorderpopup/holdorderpopup.component';
import { HoldOrderConfirmPopupComponent } from '../retailpos-popup/holdorderconfirmpopup/holdorderconfirmpopup.component';
import { HotKeysPopupComponent } from '../retailpos-popup/hotkeyspopup/hotkeyspopup.component';
import { VoucherPopupComponent } from '../../../app/admin/pos_popup/voucher-popup/voucher-popup.component'
import { identifierModuleUrl } from '@angular/compiler';

import { QtyboardPopupComponent } from '../../../app/admin/pos_popup/qtyboard-popup/qtyboard-popup.component'
import { RetailCalculatorPopupComponent } from '../../admin/retailpos-popup/retailcalculator-popup/retailcalculator-popup.component';
import { AlertPopupComponent } from '../../../app/admin/alert-popup/alert-popup.component'
import { AboutUsComponent } from '../../about-us/about-us.component';
import { updateDecorator } from 'typescript';

@Component({
  selector: 'ngx-retail-pos',
  templateUrl: './retailpos.component.html',
  styleUrls: ['./retailpos.component.scss']
})
export class RetailPOSComponent {

  ObjCompany: Company = new Company();

  day: any;
  month: any;
  currentdate: any;

  CurrencyCode: any = '';

  NumOfDecimal: number = 0;
  TaxRate: number = 0;
  IsMaxPolicyOn: any;
  MaxReceiptPrintOut: any;
  MaxKitchenReceiptPrintOut: any;
  DayID: any;
  ShiftID: any;
  AddnDeleteItems: boolean = false;


  ExtraChargeDescription: any;
  ExtraChargeDescriptionArabic: any;

  ObjResturentInfo: ResturentInfo = new ResturentInfo();
  ObjOutletInfo: OutletInfo = new OutletInfo();
  ObjdiscountVoucher: discountVoucher = new discountVoucher();

  MobileNo: any;
 
  CustomerId: any;
  SelectedBrancRow: any;

  IsDirectPrintReceipt: any;

  ServerLink: any;
  SelectedOrderInvoice: any;


  ReceiptURL: any = '';
  logo: any = '../../../assets/images/globalinformix.png';


  SearchBarcode: any = '';
  IsBarcodeFound: boolean = false;

  lstPosMenu = [];



  ObjProduct: Product = new Product();
  lstProduct: any = [];

  lblSubTotal: any = this.translate.transform('NetTotal');
  lblTotalAmount: any = this.translate.transform('TotalAmount');
  lblTotalQuantity: any = this.translate.transform('TotalQuantity');
  lblTotalVAT: any = this.translate.transform('TotalVAT');
  lblTotalDiscount: any = this.translate.transform('TotalDiscount');
  lblNetAmount: any = this.translate.transform('lblFinalTotal');

  PAY: any = this.translate.transform('Pay');
  Hold: any = this.translate.transform('Hold');

  Recall: any = this.translate.transform('Recall');
  Enter: any = this.translate.transform('Enter');
  Price: any = this.translate.transform('Price');
  Qty: any = this.translate.transform('Qty');
  UnitPrice: any = this.translate.transform('UnitPrice');
  ProductName: any = this.translate.transform('ProductName');
  Barcode: any = this.translate.transform('Barcode');
  BarcodeProduct: any = this.translate.transform('BarcodeProduct');


  Discount: any = this.translate.transform('Discount');
  Customer: any = this.translate.transform('Customer');

  CLEAR: any = this.translate.transform('Clear');
  Home: any = this.translate.transform('Home');
  
  Print: any = this.translate.transform('Print');

  Branch: any = this.translate.transform('Branch');
  User: any = this.translate.transform('Users');
  Counter: any = this.translate.transform('Counter');
  StartDate: any = this.translate.transform('StartDate');
  Logout: any = this.translate.transform('Logout');
  

  ProductNotFound: any = this.translate.transform('ProductNotFound');
  SessionClosed: any = this.translate.transform('SessionClosed');

  numbers: any = [];

  constructor(private translate: TranslatePipe, private route: ActivatedRoute, private dataservice: DataserviseService, private popup: PopupService,
    public dialog: MatDialog, private Utilities: UtilitiesService, public router: Router, private _decimalPipe: DecimalPipe)
  {
    let No: number = 11;
    this.numbers = Array(No).fill(0, 0, No).map((x, i) => i);
  }


  counterName: any;
  counterID: any;
  companyID: any;
  companyName: any;
  userName: any;
  DayStartDate: any;
  CountryCode: any;

 
  AppStatus: any = 'true';
  Culture: any = 'en';
  Quantity: any;

  loading: boolean = false;
  ngOnInit(): void {

    debugger
    this.companyName = this.Utilities.getURLCompanyName();

    if (sessionStorage.getItem('DayID&ShiftID&DayStartDate') != null) {
      this.DayID = JSON.parse(sessionStorage.getItem('DayID&ShiftID&DayStartDate'))[0];
      this.ShiftID = JSON.parse(sessionStorage.getItem('DayID&ShiftID&DayStartDate'))[1];
    }

    if (localStorage.getItem('counterID&CompanyID' + this.Utilities.getURLCompanyName()) != null) {
      debugger
      this.companyName = JSON.parse(localStorage.getItem('counterID&CompanyID' + this.Utilities.getURLCompanyName()))[0].CompanyName;
      this.counterName = JSON.parse(localStorage.getItem('counterID&CompanyID' + this.companyName))[0].CounterName;
      this.counterID = JSON.parse(localStorage.getItem('counterID&CompanyID' + this.companyName))[0].CounterID;
      this.companyID = JSON.parse(localStorage.getItem('counterID&CompanyID' + this.companyName))[0].CompanyID;

      this.userName = JSON.parse(localStorage.getItem('ActiveUser' + this.companyName))[0].Name;
      this.DayStartDate = JSON.parse(localStorage.getItem('ActiveUser' + this.companyName))[0].DayDate;
      this.CountryCode = JSON.parse(localStorage.getItem('counterID&CompanyID' + this.companyName))[0].CountryCode;
      this.ISCalculateTaxProductWise = JSON.parse(localStorage.getItem('counterID&CompanyID' + this.companyName))[0].ISCalculateTaxProductWise;
    }

    if (localStorage.getItem('SelectedBranchRow' + this.companyName) != null && localStorage.getItem('SelectedBranchRow' + this.companyName) != undefined) {

      this.SelectedBrancRow = JSON.parse(localStorage.getItem('SelectedBranchRow' + this.companyName));

      this.ObjRetailMaster.OutletName = this.SelectedBrancRow.OutletName;
      this.ObjRetailMaster.OutletID = this.SelectedBrancRow.ID;
      this.ObjOutletInfo.BranchID = this.SelectedBrancRow.ID;
    }

    let culture;
    if (localStorage.getItem('LanguageCulture' + this.companyName)) {
      culture = localStorage.getItem('LanguageCulture' + this.companyName);
      this.Culture = culture;
    }

    else {
      culture = 'en';
      this.Culture = culture;
    }

    this.dataservice.get('pos/GetCompanyData/' + this.Utilities.getSubscriptionID() + '/' + this.ObjRetailMaster.OutletID + '/' + this.Culture + '/' + this.counterID)
      .subscribe(data => {


        if (this.isArrayHasValue(data[0]['CompanyData'])) {
          sessionStorage.setItem('ObjCompany', JSON.stringify(data[0]['CompanyData']));
          this.ObjCompany = data[0]['CompanyData'];
          this.CurrencyCode = data[0]['CompanyData'][0].CurrencyCode;
          this.NumOfDecimal = data[0]['CompanyData'][0].NumOfDecimal;
        }
        if (this.isArrayHasValue(data[0]['ResturentInfo'])) {
          sessionStorage.setItem('ResturentInfo', JSON.stringify(data[0]['ResturentInfo']));
          this.ObjResturentInfo = data[0]['ResturentInfo'];
        
        }
        debugger
        if (this.isArrayHasValue(data[0]['OutletInfo'])) {
          sessionStorage.setItem('OutletInfo', JSON.stringify(data[0]['OutletInfo']));
          this.ObjOutletInfo = data[0]['OutletInfo'][0];
          this.TaxRate = data[0]['OutletInfo'][0].TaxRate;
          this.IsMaxPolicyOn = data[0]['OutletInfo'][0].IsMaxPolicyOn;
          this.MaxReceiptPrintOut = data[0]['OutletInfo'][0].MaxReceiptPrintOut;
          this.MaxKitchenReceiptPrintOut = data[0]['OutletInfo'][0].MaxKitchenReceiptPrintOut;

          debugger
          this.IsExtraCharge = data[0]['OutletInfo'][0].IsExtraCharge;
          this.ExtraChargeDescription = data[0]['OutletInfo'][0].ExtraChargeDescription;
          this.ExtraChargeDescriptionArabic = data[0]['OutletInfo'][0].ExtraChargeDescriptionArabic;


        }


        if (this.isArrayHasValue(data[0]['POSInfo'])) {
          sessionStorage.setItem('POSInfo', JSON.stringify(data[0]['POSInfo']));
          this.IsDirectPrintReceipt = data[0]['POSInfo'][0].IsReceiptDirectPrint;
          this.ServerLink = data[0]['POSInfo'][0].ServerLink;
        }
   
      }, error => alert(this.translate.transform('error')));



    var d = new Date();
    this.currentdate = new Date();
    this.day = formatDate(new Date(), 'dd', 'en');
    this.month = d.toLocaleString('default', { month: 'short' })


    if (localStorage.getItem('LanguageCulture' + this.companyName)) {
      this.Culture = localStorage.getItem('LanguageCulture' + this.companyName);
    }
    this.LoadSetup();
  }

  lstProductAll: any = [];

  ModuleCode = 'Retail POS Menu';

  IsRightsEmployeeWise: boolean = false;
  lstPosButtonDeviceWise: any = [];
  arr = [];

  IsPaybtnActive: boolean = false;
  IsDiscountbtnActive: boolean = false;
  IsHoldbtnActive: boolean = false;
  IsRecallbtnActive: boolean = false;
  IsVoidbtnActive: boolean = false;

  LoadSetup() {
    debugger
    let culture;

    if (localStorage.getItem('LanguageCulture' + this.companyName)) {
      culture = localStorage.getItem('LanguageCulture' + this.companyName);
    }
    else {
      culture = 'en';
    }

    this.lstProductAll = [];

    this.dataservice.get('retailpos/GetPOSSetup/' + this.Utilities.getSubscriptionID() + '/' + this.ObjOutletInfo.BranchID + '/' + this.Utilities.getUserId() + '/' + culture + '/' + this.counterID + '/' + this.ModuleCode).subscribe(data => {
      debugger
      if (this.isArrayHasValue(data[0].Items)) {
        debugger
        this.lstProductAll = data[0].Items;

        if (this.lstProductAll.length != 0) {
          this.lstProductAll.forEach(x => {
            x.IsSelected = true;
          });
        }
      }
      if (this.isArrayHasValue(data[0].PosMenu)) {
        debugger
        this.lstPosMenu = data[0].PosMenu;

      }
      debugger
      if (this.isArrayHasValue(data[0].IsRightsEmployeeWise)) {

          this.IsRightsEmployeeWise = data[0]['IsRightsEmployeeWise'][0].CheckRightsEmployeeWise;
        }
        if (this.isArrayHasValue(data[0]['PosButtonDeviceWise'])) {
          this.lstPosButtonDeviceWise = data[0].PosButtonDeviceWise;
        }
        if (this.isArrayHasValue(data[0]['PosButtonEmployeeWise'])) {
          this.arr = data[0].PosButtonEmployeeWise;
        }


        if (!this.IsRightsEmployeeWise) {

          if (this.lstPosButtonDeviceWise.some(x => x.EntityCode == "Retailpaybutton")) {
            this.IsPaybtnActive = true;
          }

          if (this.lstPosButtonDeviceWise.some(y => y.EntityCode == "Retaildiscountbutton")) {
            this.IsDiscountbtnActive = true;
          }

          if (this.lstPosButtonDeviceWise.some(x => x.EntityCode == "Retailholdorderbutton")) {
            this.IsHoldbtnActive = true;
          }
    
          if (this.lstPosButtonDeviceWise.some(x => x.EntityCode == "Retailrecallbutton")) {
            this.IsRecallbtnActive = true;
          }
          if (this.lstPosButtonDeviceWise.some(x => x.EntityCode == "Retailvoidbutton")) {
            this.IsVoidbtnActive = true;
          }
        }

        else {

          if (this.arr.some(y => y.EntityCode == "Retailpaybutton")) {
            this.IsPaybtnActive = true;
          }

          if (this.arr.some(y => y.EntityCode == "Retaildiscountbutton")) {
            this.IsDiscountbtnActive = true;
          }
          if (this.arr.some(y => y.EntityCode == "Retailholdorderbutton")) {
            this.IsHoldbtnActive = true;
          }
          if (this.arr.some(y => y.EntityCode == "Retailrecallbutton")) {
            this.IsRecallbtnActive = true;
          }

          if (this.arr.some(y => y.EntityCode == "Retailvoidbutton")) {
            this.IsVoidbtnActive = true;
          }
        }

    })
  }


  isArrayHasValue(obj: any[]): boolean {
    let isArrayWithLength = Boolean(Array.isArray(obj) && obj.length);
    return isArrayWithLength;
  }
  ProductDuplicate: boolean = false;
  formatAMPM() {
    let hours = this.currentdate.getHours();
    let minutes = this.currentdate.getMinutes();
    let second = this.currentdate.getSeconds();
    let ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    second = second < 10 ? '0' + second : second;
    let strTime = hours + ':' + minutes + ':' + second + ' ' + ampm;
    return strTime;
  }

  openPaidOrdersPopup() {

    const dialogRef = this.dialog.open(RetailPaidOrdersPopupComponent, {
      width: '1150px',
      height: '600px',
      data: { data: true, OutletId: this.SelectedBrancRow.ID }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
     
      }

    });
  }



  custName: any;
  SelectedCutomer: any = [];
  LoyaltyPointsInfo: any;
  lstLoyaltyPointsData: any;
  CustomerAddressID: any;
  openDialogCustomerLookUp() {

    let dialogRef = this.dialog.open(DeliveryPopupComponent, {
      width: '1150px',
      height: '600px',
      data: { outletID: this.ObjRetailMaster.OutletID }
    });


    dialogRef.afterClosed().subscribe(result => {
      debugger
      if (result != undefined && result[0] != undefined) {

        this.SelectedCutomer = result[0];
        this.CustomerId = this.SelectedCutomer["custID"];
        this.custName = this.SelectedCutomer["custName"];

        this.CustomerAddressID = result[0].CustAddressID;
        this.MobileNo = result[0].PhoneNo;
        
      }


    });
  }


  replaceAstrophe(val): any {
    return val.replaceAll("'", "''");
  }



  IsPrintButtonDisplay: boolean = false;

  GetCurrentSelectedIndex() {
    return this.lstProduct.findIndex(x => x.IsSelected == true);
  }

  QtyPriceChangePopup(param) {
    if (this.lstProduct < 1) {
      return;
    }

    const dialogRef = this.dialog.open(QtyboardPopupComponent, {
      width: '400px',
      height: '430px',
      data: {IsQtyInDecimal:true,IsQtyPopUp:true},
      panelClass: 'my-custom-dialog-class'
    });

    dialogRef.afterClosed().subscribe(result => {
    debugger
      if (result) {
        let val = parseFloat(result);

        if (!isNaN(val)) {
     

        let index = this.GetCurrentSelectedIndex();

          if (param == 'Qty') {
            this.Quantity = parseFloat(result);
            this.lstProduct[index].Quantity = parseFloat(result);
          this.lstProduct[index].TotalAmount = parseFloat(result) * this.lstProduct[index].UnitCost;
        }
        else if (param == 'Price') {
            this.lstProduct[index].UnitCost = parseFloat(result);
            this.lstProduct[index].Price = parseFloat(result);
            this.lstProduct[index].TotalAmount = this.lstProduct[index].Quantity * parseFloat(result);
        }
      

          this.setTotalValue();
        }
      }

    });
  }

  ItemLookup(): void {
    debugger

    //string CompanyId, string OutletId, string UserId, string Culture, string CounterId, string ModuleCode
      const dialogRef = this.dialog.open(RetailItempoppupComponent, {
        width: '1150px',
        height: '600px',
        data: { OutletId: this.ObjOutletInfo.BranchID, UserId: this.Utilities.getUserId(), Culture: this.Culture, CounterId: this.counterID, ModuleCode: this.ModuleCode, NumOfDecimal: this.NumOfDecimal }
      });

      dialogRef.afterClosed().subscribe(result => {
        debugger
        if (result != undefined) {
          this.lstProduct.forEach(x => {
            x.IsSelected = false;
          });

          this.lstProduct.push(result);
          this.Quantity = 1;
          this.setTotalValue();
        }
   
      });

  }



  DeletedItem() {
    debugger
    let index = this.lstProduct.findIndex(x => x.IsSelected == true);

    if (index != -1) {
      if (window.confirm('Are you sure you want to delete?')) {
        this.lstProduct.splice(index, 1);

        if (this.lstProduct.length >= 1) {
          this.lstProduct[this.lstProduct.length - 1].IsSelected = true;
        }

        this.SetQty_txt();
        this.setTotalValue();
      }
    }
  }

  UpItem() {
    debugger
    let index = this.lstProduct.findIndex(x => x.IsSelected == true);
    if (index == 0)
      return;

    let NewIndex = index - 1;

    if (index != -1) {
      this.lstProduct.forEach(x => {
        x.IsSelected = false;
      });

      //this.lstProduct.splice(NewIndex, 0, this.lstProduct.splice(index, 1)[0]);
      this.lstProduct[NewIndex].IsSelected = true;
      this.SetQty_txt();
    }
  }

  DownItem() {
    debugger
    let index = this.lstProduct.findIndex(x => x.IsSelected == true);
    if (index == this.lstProduct.length-1)
      return;

    let NewIndex = index + 1;

    if (index != -1) {
      this.lstProduct.forEach(x => {
        x.IsSelected = false;
      });

      //this.lstProduct.splice(NewIndex, 0, this.lstProduct.splice(index, 1)[0]);
      this.lstProduct[NewIndex].IsSelected = true;
      this.SetQty_txt();
    }
  }

  PlusQty() {
    let index = this.lstProduct.findIndex(x => x.IsSelected == true);
    if (index != -1) {
      let qty = this.lstProduct[index].Quantity;

      this.lstProduct[index].Quantity = parseFloat(qty) + 1;
      this.SetQty_txt();
      this.setTotalValue();
    }
  }
  MinusQty() {
    let index = this.lstProduct.findIndex(x => x.IsSelected == true);
    if (index != -1) {
      let qty = this.lstProduct[index].Quantity;
      if (parseFloat(qty) == 1)
        return;

      this.lstProduct[index].Quantity = parseFloat(qty) - 1;
      this.SetQty_txt();
      this.setTotalValue();
    }

  }

  onQtyChange() {
    if (parseFloat(this.Quantity) < 1)
      return;

    debugger
    let index = this.lstProduct.findIndex(x => x.IsSelected == true);
    if (index != -1) {
      this.lstProduct[index].Quantity = this.Quantity;

      let unitcost = this.lstProduct[index].UnitCost;

      if (unitcost != undefined && unitcost != null) {
        this.lstProduct[index].TotalAmount = parseFloat(unitcost) * parseFloat(this.Quantity);
      }

      this.setTotalValue();
    }
  }
  clickEvent(index) {
    debugger
    this.lstProduct.forEach(x => {
      x.IsSelected = false;
    });
    this.lstProduct[index].IsSelected = true;
    this.SetQty_txt();
  }

  SetQty_txt() {
    debugger
    let index = this.lstProduct.findIndex(x => x.IsSelected == true);
    if (index != -1) {
      let qty = this.lstProduct[index].Quantity;
      let unitcost = this.lstProduct[index].UnitCost;

      if (unitcost != undefined && unitcost != null) {
        this.lstProduct[index].TotalAmount = parseFloat(unitcost) * parseFloat(qty);
      }

      this.Quantity = qty;
    }
  }

  TotalAmount: any;
  TotalVAT: any;
  TotalQty: any;
  TotalDiscount: any;
  NetAmount: any;

  setTotalValue() {
    this.TotalAmount = 0;
    this.TotalVAT = 0;
    this.TotalQty = 0;
    this.TotalDiscount = 0;
    this.NetAmount = 0;
    debugger

    this.TotalQty = this.lstProduct.reduce((a, b) => a + parseFloat(b['Quantity']), 0);

    let obj = this.StartCalculation2(this.lstProduct, [], [], this.ObjOutletInfo.IsTaxInclusive, this.ObjOutletInfo.IsTaxOnDiscountAmount, this.ISCalculateTaxProductWise, this.ObjOutletInfo.TaxRate, this.IsExtraCharge, this.ObjdiscountVoucher.IsPercent)

    this.TotalAmount = obj[0].SubTotal;
    this.TotalDiscount = obj[0].PromoAmount;
    this.TotalVAT = obj[0].VAT;
    this.NetAmount = obj[0].NetAmount;
    this.ExtraChargeAmount2 = obj[0].ExtraChargeAmount;
    //this.TotalQty = this.lstProduct.reduce((a, b) => a + parseFloat(b['Qty']), 0);
    //this.TotalAmount = parseFloat(this.lstProduct.reduce((a, b) => a + b['TotalAmount'], 0));

    //for (var i = 0; i < this.lstProduct.length; i++) {
    //  this.lstProduct[i].TaxAmount = this.getTax(this.lstProduct[i].TotalAmount, this.ObjOutletInfo.IsTaxInclusive, this.ObjOutletInfo.IsTaxOnDiscountAmount, this.lstProduct[i].TotalAmount, this.lstProduct[i].TaxRateActual, this.TotalDiscount, i);

    //}
    //this.TotalVAT = this.lstProduct.reduce((a, b) => a + parseFloat(b['TaxAmount']), 0);

    //if (this.ObjOutletInfo.IsTaxInclusive) {
    //  this.TotalAmount = this.TotalAmount - this.TotalVAT;
    //}

    //if (this.IsDiscountApplied == true) {
    //  if (this.ObjdiscountVoucher.IsPercent) {
    //    this.TotalDiscount = ((this.TotalAmount) * this.ObjdiscountVoucher.Amount) / 100;
       
    //  }
    //  else {
    //    this.TotalDiscount = this.ObjdiscountVoucher.Amount;
    //  }
    //}

    //this.NetAmount = (this.TotalAmount - this.TotalDiscount) + this.TotalVAT;
  }


  isNumberKey(evt): boolean {
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57))
      return false;

    return true;
  }

  


  BarcodeChange(barcode) {
    debugger
    let obj;
    debugger



    if (barcode) {
      obj = this.lstProductAll.filter(PA => PA.Barcode == barcode)[0];
      if (obj) {
        this.IsBarcodeFound = false;
        obj.RowNo += 1;
      }
      else {
        this.IsBarcodeFound = true;
      }
    }


    if (obj) {
      this.SearchBarcode = '';


      if (this.lstProduct.length != 0) {
        this.lstProduct.forEach(x => {
          x.IsSelected = false;
        });
      }

      this.lstProduct.push(Object.assign({}, obj));

      this.Quantity = 1;
      this.setTotalValue();
    }

  }

  getTax(BasketTotal: number, IsTaxInclusive: boolean, IsTaxOnDiscountAmount: boolean, BasketAmountforTax: number, TaxRate: any, TotalDiscountedAmount: any, index): any {
    let TaxAmount: number = 0;
    debugger
    if (TaxRate > 0) {
      if (IsTaxInclusive == false) {
        BasketTotal = BasketTotal //basketTotal
        if (IsTaxOnDiscountAmount) {
          TaxAmount = (BasketAmountforTax * TaxRate) / 100;
        } else {
          TaxAmount = (BasketTotal * TaxRate) / 100;
        }


      }
      else {

        let A
        if (IsTaxInclusive && IsTaxOnDiscountAmount) {
          A = (BasketAmountforTax * 100) / (100 + TaxRate);
          TaxAmount = BasketAmountforTax - A;
          A += TotalDiscountedAmount;
        }
        else if (IsTaxInclusive && !IsTaxOnDiscountAmount) {
          A = (BasketTotal * 100) / (100 + TaxRate);
          TaxAmount = BasketTotal - A;
        }
        else {
          A = (BasketTotal * 100) / (100 + TaxRate);
          TaxAmount = BasketTotal - A;
        }
        BasketTotal = A;

      }
    }
 
      return TaxAmount;

  }

  compName: any;
  homeClick() {

    let values = this.Utilities.getCompanyName();
    this.compName = values;
    var local = window.location.origin;
    console.log(local);
    if (window.location.href.includes('BackOffice'))
      // this.router.navigateByUrl('/dashboardpos/maindashboard');
      window.location.href = local + '/BackOffice/' + this.compName + '/dashboardpos/maindashboard'
    else
      // this.router.navigateByUrl('/dashboardpos/maindashboard');
      window.location.href = local + '/' + this.compName + '/dashboardpos/maindashboard'
  }

  tab: any = 'home';

  menuClick(val) {

    debugger
    if (val == 'home') {

      this.tab = 'home';
    }
 
    else if (val == 'PrintReceipt') {
      this.tab = 'PrintReceipt';
      //this.prntrec();
    }
    else if (val == 'RetailPaidOrders') {
      this.tab = 'RetailPaidOrders';
      this.openPaidOrdersPopup();
    }

    else if (val == 'RetailCreditNote') {
      this.tab = 'RetailCreditNote';
      this.SaleRetun();
    }

    else if (val == 'RetailQuickReport') {
      this.tab = 'RetailQuickReport';
      this.RetailQuickReportPopup();
    }
    else if (val == 'RetailHotKeys') {
      this.tab = 'RetailHotKeys';
      this.OpenHotKeysPopup();
    }
    else if (val == 'logout') {
      //
      this.tab = 'logout';
      sessionStorage.clear();
      localStorage.removeItem('poslogin_storage');
      localStorage.removeItem('sessionObject' + this.companyName);
      localStorage.removeItem('LanguageCulture' + this.companyName);
      localStorage.removeItem('SelectedBranchRow' + this.companyName);

      localStorage.setItem('PosloginCheckCulture' + this.companyName, '0');
      //this.router.navigateByUrl(['login']);
      window.location.href = 'auth/poslogin/' + this.companyName + '/' + this.counterName;
      // window.location.href = '~/login';
    }

  }

  RetailQuickReportPopup(): void {
    debugger

    const dialogRef = this.dialog.open(RetailQuickreportPopupComponent, {
      width: '550px',
      height: '600px',
      data: { NoOfDecimal: this.NumOfDecimal, ShiftID: this.ShiftID, OutletID: this.ObjOutletInfo.BranchID, Culture:this.Culture }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
   
      }
    });
  }

  ObjRetailMaster: RetailMaster = new RetailMaster();
  ArrRetailDetail: RetailDetail[] = [];
  ObjRetail: RetailDetail = new RetailDetail();

  PrepareData() {

    if (this.lstProduct.length < 1) {
      const dialogRef = this.dialog.open(AlertPopupComponent, {
        width: '300px',
        height: '200px',
      });

      return false;
    }


    this.ObjRetailMaster = new RetailMaster();
    this.ArrRetailDetail = [];
    //this.ObjRetailMaster.OrderID = this.HoldOrderID;
    this.ObjRetailMaster.OrderDate = this.Utilities.GetCurrentDateTime();
    this.ObjRetailMaster.PayType = 'Cash';
    this.ObjRetailMaster.Channel = 'POS';
    this.ObjRetailMaster.Remarks = '';
    this.ObjRetailMaster.IsTaxInclusive = this.ObjOutletInfo.IsTaxInclusive;
    this.ObjRetailMaster.IsTaxOnDiscountAmount = this.ObjOutletInfo.IsTaxOnDiscountAmount;
    this.ObjRetailMaster.CustAddressID = this.CustomerAddressID;
    this.ObjRetailMaster.CustomerID = this.CustomerId;
    this.ObjRetailMaster.OutletID = this.ObjOutletInfo.BranchID;
    this.ObjRetailMaster.OutletName = this.ObjOutletInfo.BranchName;
    this.ObjRetailMaster.CompanyId = this.Utilities.getSubscriptionID();
    this.ObjRetailMaster.TotalAmount = this.TotalAmount;
    this.ObjRetailMaster.TotalTaxAmount = this.TotalVAT;
    this.ObjRetailMaster.TotalDiscount = this.TotalDiscount;
    this.ObjRetailMaster.NetAmount = this.NetAmount;
    this.ObjRetailMaster.CreatedBy = this.Utilities.getUserId();
    this.ObjRetailMaster.CreatedOn = this.Utilities.GetCurrentDateTime();
    this.ObjRetailMaster.OrderPayStatus = 'Paid';
    this.ObjRetailMaster.POSID = this.counterID;
    this.ObjRetailMaster.PromoAmount = this.TotalDiscount;
    this.ObjRetailMaster.PromoCode = this.ObjdiscountVoucher.VoucherCode;
    this.ObjRetailMaster.IsExtraCharge = this.IsExtraCharge;
    this.ObjRetailMaster.ExtraChargeAmount = this.ExtraChargeAmount2;
    this.ObjRetailMaster.ExtraChargeDescription = this.ObjOutletInfo.ExtraChargeDescription;
    this.ObjRetailMaster.ExtraChargeDescriptionArabic = this.ExtraChargeDescriptionArabic;
    debugger
    for (var i = 0; i < this.lstProduct.length; i++) {
      this.ObjRetail = new RetailDetail();

      this.ObjRetail.ProductID = this.lstProduct[i].ProductID;
      this.ObjRetail.ProductCategoryID = this.lstProduct[i].CatagoryID;
      this.ObjRetail.UnitID = this.lstProduct[i].UnitID;
      this.ObjRetail.Quantity = this.lstProduct[i].Quantity;
      this.ObjRetail.Price = this.lstProduct[i].UnitCost;
      this.ObjRetail.TaxPercent = this.lstProduct[i].TaxRateActual;
      this.ObjRetail.TaxAmount = this.lstProduct[i].TaxAmount;
      this.ObjRetail.TotalAmount = this.lstProduct[i].TotalAmount;
      this.ObjRetail.CompanyId = this.lstProduct[i].CompanyId;
      this.ObjRetail.ExtraChargePercentage = this.lstProduct[i].ExtraChargePercentage;
      this.ArrRetailDetail.push(this.ObjRetail);
    }
    return true;
  }
  openfinishPopup() {
    debugger
    if (this.PrepareData() == false)
      return;

      debugger
      const dialogRef = this.dialog.open(RetailCalculatorPopupComponent, {
        // width: '850px',
        width: '1150px',
        height: '600px',

        data: { ObjRetailMaster: this.ObjRetailMaster, ArrRetailDetail: this.ArrRetailDetail, NumOfDecimal: this.NumOfDecimal, objoutletInfo: this.ObjOutletInfo, CurrencyCode: this.CurrencyCode, HoldOrderID:this.HoldOrderID  }
      });

      dialogRef.afterClosed().subscribe(result => {
   
        if (result == undefined) {
          return;
        } else {
          debugger
          this.clearclick();
          this.afterClosedMethod(result);
          this.AddnDeleteItems = false;
        }

      });
    

  }

  win;
  async afterClosedMethod(result) {
    debugger
    if (result[1][0].IsReceiptDirectPrint == 4) {

      const url = this.Utilities.geta4SizeReport(this.Utilities.getEncrypt(this.Utilities.getCompanyName()), this.Utilities.getEncrypt(sessionStorage.getItem('orderid')));
      this.ReceiptURL = url;

      // this.checkIframeLoaded();
    }
    if (result[1][0].IsReceiptDirectPrint == 3) {
      // this.ReceiptURL = this.Utilities.getReceiptURL(this.Utilities.getEncrypt(this.Utilities.getCompanyName()), this.Utilities.getEncrypt(sessionStorage.getItem('orderid')), this.CountryCode);
      this.ReceiptURL = this.Utilities.getDigiPosReceiptURL(this.Utilities.getEncrypt(this.Utilities.getCompanyName()), this.Utilities.getEncrypt(sessionStorage.getItem('orderid')), this.CountryCode);
    }
    else if (result[1][0].IsReceiptDirectPrint == 2) {
      if (this.IsMaxPolicyOn) {
        
          let link = result[1][0].ServerLink + '/Default?O=' + result[1][0].OrderID + '&C=' + result[1][0].CompanyId + '&type=RP' + '&OutletID=' + this.ObjRetailMaster.OutletID + '&ShiftID=' + this.ShiftID + '&AddnDeleteItems=' + this.AddnDeleteItems + '&POSID=' + this.counterID;
          this.win = await window.open(link, '_blank', 'width=300,height=200');
         
      
      }
      else {
        let link = result[1][0].ServerLink + '/Default?O=' + result[1][0].OrderID + '&C=' + result[1][0].CompanyId + '&type=RP' + '&OutletID=' + this.ObjRetailMaster.OutletID + '&ShiftID=' + this.ShiftID + '&AddnDeleteItems=' + this.AddnDeleteItems + '&POSID=' + this.counterID;
        this.win = await window.open(link, '_blank', 'width=300,height=200');
      }

    }
    this.ObjRetailMaster = new RetailMaster();
    this.ArrRetailDetail = [];
    
    this.custName = '';
    this.CustomerId = 0;
   this.SelectedOrderInvoice = '';
     this.SearchBarcode = '';
    this.IsBarcodeFound = false;
   this.MobileNo = '';
    this.IsPrintButtonDisplay = false
  }
  //onLoad() {
  //  if (this.ReceiptURL != '') {
  //    setTimeout(() => {
  //      window.frames["printf"].focus();
  //      window.frames["printf"].print();
  //    }, 1800);

  //  }
  //}

  async checkIframeLoaded() {

    //// Get a handle to the iframe element
    //var iframe = document.getElementById('printf') as HTMLIFrameElement;
    //var iframeDoc = iframe.contentDocument || iframe.contentWindow.document;

    //// Check if loading is complete
    //if (iframeDoc.readyState == 'complete') {
    //  //iframe.contentWindow.alert("Hello");
    //  //await iframe.contentWindow.print();
    //  // The loading is complete, call the function we want executed once the iframe is loaded
    //  await this.onLoad();
    //  return false;
    //}

    //// If we are here, it is not loaded. Set things up so we check   the status again in 100 milliseconds
    ////window.setTimeout(this.checkIframeLoaded, 100);
  }

  clearclick() {

    this.MobileNo;
    this.Quantity = 0;
    this.ReferenceNo = '';
    this.TotalAmount = 0;
    this.TotalVAT = 0;
    this.TotalDiscount = 0;
    this.NetAmount = 0;
    this.TotalQty = 0;
    this.CustomerId = 0;
    this.custName = '';
    this.SelectedOrderInvoice = '';
    this.IsBarcodeFound = false;
    this.SearchBarcode = '';

    this.lstProduct = [];
    this.IsPrintButtonDisplay = false;
  

    this.TotalAmount = 0;
    this.TotalVAT = 0;
    this.TotalDiscount = 0;
    this.NetAmount = 0;
    this.IsDiscountApplied = false;
    this.ExtraChargeAmount2 = 0;
  }


  numberOnly(event, value): boolean {
    debugger
    const charCode = (event.which) ? event.which : event.keyCode;
    var eventKey = event.key;
    if (event.which == 46 && value.indexOf('.') != -1) {
      event.preventDefault();
    } // prevent if already dot

    else if (charCode == 46) {
      return true;
    }

    else if ((charCode < 48 || charCode > 57)) {
      return false;
    }

    else { return true; }

  }

  calcButtonclick(value) {
    debugger
    document.getElementById('txtboxAmount').focus();
    if (value == 'AC' || value == "") {
      this.SearchBarcode = '';
      return
    }
    else {
      this.SearchBarcode = this.SearchBarcode + value;
      //this.SearchBarcode = this.SearchBarcode.substring(0, 5);
    }
  }
  ClickDecimal(): void {
    debugger
    var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

    if (!format.test(this.SearchBarcode)) {
      this.SearchBarcode = this.SearchBarcode + '.';
    }

  }

  removeNumber() {
    this.SearchBarcode = this.SearchBarcode.slice(0, -1);
  }


  inputValueChange(value) {
    debugger
    // value = parseFloat(value)
    if (value == 'AC' || value == "") {
      this.SearchBarcode = '';
      // this.arrTempAmount = '';
      return
    }
    else {
      // this.arrTempAmount =''
      // this.arrTempAmount = this.arrTempAmount + value;  
      // this.txtboxAmount = this.arrTempAmount;
      // this.arrTempAmount = this._decimalPipe.transform(this.arrTempAmount, '1.'+this.NumOfDecimal+'-'+this.NumOfDecimal)

    }
  }

  ok() {
    this.BarcodeChange(this.SearchBarcode);

  }

  OpenHoldOrderPopup(): void {
    debugger

    const dialogRef = this.dialog.open(HoldOrderPopupComponent, {
      width: '1150px',
      height: '600px',
      data: { OutletId: this.ObjOutletInfo.BranchID, POSID: this.counterID, ShiftID: this.ShiftID, DayID: this.DayID, NumOfDecimal: this.NumOfDecimal, IsVoidbtnActive:this.IsVoidbtnActive }
    });

    dialogRef.afterClosed().subscribe(row => {
      debugger
      if (row != undefined)
        this.GetHoldOrderMasterDetail(this.companyID, row.OrderID);

    });
  }

  OpenHoldOrderConfirmPopup(): void {
    debugger
    if (this.PrepareData() == false)
      return;

    let lstPaymentDetail = [];
    let lstVoucherPaymentDetail = [];

    this.ObjRetailMaster.OrderPayStatus = null;
    var CurrentDate = this.Utilities.GetCurrentDate();
    this.ObjRetailMaster.ReferenceNo = this.ReferenceNo;

    let body = [this.ObjRetailMaster, this.ArrRetailDetail, lstPaymentDetail, lstVoucherPaymentDetail, this.Utilities.getUserId(), this.Utilities.getSubscriptionID(), this.ObjRetailMaster.OutletID, this.ObjRetailMaster.POSID, CurrentDate, this.HoldOrderID]

    const dialogRef = this.dialog.open(HoldOrderConfirmPopupComponent, {
      width: '600px',
      height: '400px',
      data: { body: body, OrderFrom: 'retail', ReferenceNo: this.ReferenceNo}
    });

    dialogRef.afterClosed().subscribe(row => {
      debugger

      if (row != undefined)
        this.clearclick();

      //this.GetHoldOrderMasterDetail(this.companyID, row.OrderID);

    });
  }

  OpenHotKeysPopup(): void {
    debugger

    const dialogRef = this.dialog.open(HotKeysPopupComponent, {
      width: '900px',
      height: '600px',
      data: { OutletId: this.ObjOutletInfo.BranchID, UserId: this.Utilities.getUserId(), Culture: this.Culture, CounterId: this.counterID, ModuleCode: this.ModuleCode, CurrencyCode: this.CurrencyCode, NumOfDecimal: this.NumOfDecimal }
    });

    dialogRef.afterClosed().subscribe(result => {
      debugger
      if (result != undefined) {
        this.lstProduct.forEach(x => {
          x.IsSelected = false;
        });

        this.lstProduct.push(result);
        this.Quantity = 1;
        this.setTotalValue();
      }

    });
  }
  ReferenceNo: any;
  HoldOrderID: any = 0;
  GetHoldOrderMasterDetail(companyid, orderid) {
   
    this.dataservice.get('retailpos/GetHoldOrderMasterDetail/' + companyid + '/' + orderid).subscribe(data => {
      debugger
      if (this.isArrayHasValue(data)) {
       
        let lst: any = [];
        let Obj;

        Obj = data[0].Master[0];
        lst = data[0].Detail;

        this.clearclick();
        this.ReferenceNo = Obj.ReferenceNo;
        this.HoldOrderID = orderid;
        this.ObjRetailMaster.Remarks = Obj.Remarks;
        this.ObjOutletInfo.IsTaxInclusive = Obj.IsTaxInclusive;
        this.ObjOutletInfo.IsTaxOnDiscountAmount = Obj.IsTaxOnDiscountAmount;
        this.CustomerAddressID = Obj.CustomerAddressID;
        this.CustomerId = Obj.CustomerId;
        this.custName = Obj.custName;

        this.ObjOutletInfo.BranchID = Obj.OutletID;
        this.ObjRetailMaster.OutletName = this.SelectedBrancRow.OutletName;
        this.TotalAmount = Obj.TotalAmount;
        this.TotalVAT = Obj.TotalTaxAmount;
        this.ExtraChargeAmount2 = Obj.ExtraChargeAmount;
        this.NetAmount = Obj.NetAmount;

        this.counterID = Obj.POSID;
        debugger
        this.TotalDiscount = Obj.PromoAmount;
        this.ObjRetailMaster.PromoCode = Obj.PromoCode;
        this.ObjdiscountVoucher.DiscVoucherID = Obj.DiscVoucherID;
        this.ObjdiscountVoucher.VoucherDesc = Obj.VoucherDesc;
        this.ObjdiscountVoucher.IsPercent = Obj.IsPercent;
        this.ObjdiscountVoucher.Amount = Obj.DisVoucherValue;


        this.SetPromoAmount(this.ObjdiscountVoucher.Amount);

        if (Obj.PromoCode)
          this.IsDiscountApplied = true;
        else
          this.IsDiscountApplied = false;

        let length = this.lstProduct.length;

        for (var i = 0; i < lst.length; i++) {
          this.lstProduct.push(lst[i]);
        }


        if (length === 0) {
          this.lstProduct.forEach(x => {
            x.IsSelected = false;
          });

          this.lstProduct[this.lstProduct.length - 1].IsSelected = true;
          this.Quantity = this.lstProduct[this.lstProduct.length - 1].Quantity;
        }
        this.TotalQty = this.lstProduct.reduce((a, b) => a + parseFloat(b['Quantity']), 0);
      //this.setTotalValue();

      }
    })
  }

  SetPromoAmount(val) {
    this.PromoAmount2 = 0;
    if (val != undefined && val != '')
      this.PromoAmount2 = this.ObjdiscountVoucher.Amount;

  }
  IsCancelButtonPaidOrderActive: boolean = false;
  SaleRetun(): void {
    const dialogRef = this.dialog.open(RetailSaleReturnPopupComponent, {
      width: '1350px',
      height: '800px',
      data: { data: this.IsCancelButtonPaidOrderActive, OutletId: this.ObjOutletInfo.BranchID, ObjOutletInfo: this.ObjOutletInfo, NumOfDecimal: this.NumOfDecimal, CurrencyCode: this.CurrencyCode, DayID: this.DayID, ShiftID: this.ShiftID, POSID: this.counterID }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        this.AddnDeleteItems = false;
      }

    });
  }


  IsDiscountApplied: boolean = false;
  VoucherPopup() {

    if (this.lstProduct.length > 0) {

      const dialogRef = this.dialog.open(VoucherPopupComponent, {
        width: '400px',
        height: '430px',
        data: { 'Subtoal': this.TotalAmount, 'OutletID': this.ObjOutletInfo.BranchID }
      });

      dialogRef.afterClosed().subscribe(result => {
        debugger 
        if (result != undefined) {
          //this.PromoCode = result.VoucherCode;
          //this.PromoAmount = result.Amount;
          //this.IsPercentPromoDiscount = result.IsPercent;

          this.IsDiscountApplied = true;
          this.ObjdiscountVoucher.DiscVoucherID = result.DiscVoucherID;
          this.ObjdiscountVoucher.VoucherCode = result.VoucherCode;
          this.ObjdiscountVoucher.VoucherDesc = result.VoucherDesc;
          this.ObjdiscountVoucher.IsPercent = result.IsPercent;
          this.ObjdiscountVoucher.Amount = result.Amount;
          this.ObjdiscountVoucher.MinOrderValue = result.MinOrderValue;
          this.ObjdiscountVoucher.UsagePerUser = result.UsagePerUser;
          this.ObjdiscountVoucher.UsagePerVoucher = result.UsagePerVoucher;
          this.ObjdiscountVoucher.VoucherExpiryDate = result.VoucherExpiryDate;
          this.ObjdiscountVoucher.OutletIds = result.OutletIds;
          this.ObjdiscountVoucher.Status = result.Status;
          this.ObjdiscountVoucher.CompanyId = result.CompanyId;

          this.SetPromoAmount(this.ObjdiscountVoucher.Amount);
       
       

          this.setTotalValue();
        }
        else {
         
          // this.PromoAmount = 0;
          // this.PromoCode = '';
        }

      });
    }
    else {
      const dialogRef = this.dialog.open(AlertPopupComponent, {
        width: '300px',
        height: '200px',
      });
    }
  }
  RemoveDiscount() {

    this.ObjdiscountVoucher = new discountVoucher();
    this.SetPromoAmount(0);

    this.IsDiscountApplied = false;
    this.TotalDiscount = 0;
    this.setTotalValue();
  }

  TotalAmountforDiscount2: number = 0;
  TotalVatAmount2: number = 0;
  SubTotalAmount2: number = 0;
  PromoAmount2: any = 0;

  /*Product Wies Variables*/
  ProductVat2: number = 0;
  productDiscount2: number = 0;
  TotalDiscountAmount2: number = 0;
  ProdcutTotalAmount2: number = 0;
  productmodifiresum2: number = 0;
  productsubtotal2: number = 0;
  productTax2: number;

  /*Product Wies Variables*/
  ExtraChargeProductVat2: number = 0;
  ExtraChargeproductDiscount2: number = 0;
  ExtraChargeproductmodifiresum2: number = 0;
  ExtraChargeproductsubtotal2: number = 0;
  ExtraChargeproductTax2: number;
  /*Discount Variables */
  VATOnDiscountAmount2: number = 0;
  DiscountAmountWithOutVAT2: number = 0;
  PromoAmountforVisibility2: any;

  Subtoal2: any;
  VAT2: any;
  ExtraChargeAmount2: any;
  Total2: any;

  lst2: any = [];
  lstmodifir2: any = [];
  lstCombo2: any = [];
  ISCalculateTaxProductWise: boolean;
  OutletTaxRate: any;
  IsExtraCharge: boolean;
  IsPercentPromoDiscount: boolean;
  IsTaxOnDiscountAmount: boolean;
  StartCalculation2(lstBasket, lstmodifir, lstCombo, IsTaxInclusive, IsTaxOnDiscountAmount, ISCalculateTaxProductWise, OutletTaxRate, IsExtraCharge, IsPercentPromoDiscount): any {
    debugger
    let arr: any = [];

    this.lst2 = lstBasket;
    this.lstmodifir2 = lstmodifir;
    this.lstCombo2 = lstCombo;
    this.ISCalculateTaxProductWise = ISCalculateTaxProductWise;
    this.OutletTaxRate = OutletTaxRate;
    this.IsExtraCharge = IsExtraCharge;
    this.IsPercentPromoDiscount = IsPercentPromoDiscount;
    this.IsTaxOnDiscountAmount = IsTaxOnDiscountAmount;

    this.Subtoal2 = 0;
    this.PromoAmountforVisibility2 = 0;
    this.VAT2 = 0;
    this.TotalDiscountAmount2 = 0;
    this.ProdcutTotalAmount2 = 0;
    this.Total2 = 0;
    this.DiscountAmountWithOutVAT2 = 0;
    this.VATOnDiscountAmount2 = 0;
    this.productDiscount2 = 0;
    this.ExtraChargeAmount2 = 0;
    this.ExtraChargeProductVat2 = 0;
    this.ExtraChargeproductDiscount2 = 0;
    this.ExtraChargeproductmodifiresum2 = 0;

    this.ExtraChargeproductTax2 = 0;
    if (this.lst2.length > 0) {
      for (var i = 0; i < this.lst2.length; i++) {
        let ProdcutPrice: any;
        let ModifirePrice: any;
        let BogoAmount: any;
        let ComboProductAmount: any;

        this.ExtraChargeproductsubtotal2 = 0;
        if (this.lst2[i].DelFlag == null || this.lst2[i].DelFlag == false) {
          ProdcutPrice = this.lst2[i].Price * parseFloat(this.lst2[i].Quantity);
          for (var j = 0; j < this.lstmodifir2.length; j++) {

            if (this.lstmodifir2[j].ProductID == this.lst2[i].ProductID) {
              if (this.lst2[i].rowid == this.lstmodifir2[j].rowid && this.lstmodifir2[j].Price > 0) {
                ModifirePrice = (this.lstmodifir2[j].Price * parseFloat(this.lst2[i].Quantity));
              }
              if (this.lstmodifir2[j].IsBogo == true) {
                BogoAmount = this.lst2[i].Price * parseFloat(this.lst2[i].Quantity);
              }
            }

          }

          if (this.lstCombo2.filter(t => t.rowid == this.lst2[i].rowid && (this.lst2[i].DelFlag == null || this.lst2[i].DelFlag == false) && t.Price > 0).length > 0)
            ComboProductAmount = this.lstCombo2.filter(t => t.rowid == this.lst2[i].rowid && this.lst2[i].DelFlag == null || this.lst2[i].DelFlag == false).map(t => t.Price * parseFloat(this.lst2[i].Quantity)).reduce((a, b) => a + b);
          else
            ComboProductAmount = 0;

          ModifirePrice = (ModifirePrice == undefined || ModifirePrice == null) ? 0 : ModifirePrice;

          this.CalculateDiscountAmount2(ProdcutPrice + ModifirePrice + ComboProductAmount);
          this.CalculateTotalDiscountAmount2();
          if (IsTaxInclusive == true) {
            this.CalculateTaxInclusive2(ProdcutPrice, ModifirePrice, ComboProductAmount, BogoAmount, this.lst2[i].TaxRateActual, this.lst2[i].ExtraChargePercentage)
          }
          else
            this.CalculateTaxExclusive2(ProdcutPrice, ModifirePrice, ComboProductAmount, BogoAmount, this.lst2[i].TaxRateActual, this.lst2[i].ExtraChargePercentage)
        }
      }

      if (IsTaxInclusive == true) {
        this.FinalDiscountCalculationInclusive2(IsTaxOnDiscountAmount);
      }
      else {
        this.FinalDiscountCalculationExclusive2(IsTaxOnDiscountAmount);
      }

      this.TotalAmountforDiscount2 = 0;
      this.TotalVatAmount2 = 0;
      this.SubTotalAmount2 = 0;
    }

    let body = [{ 'SubTotal': this.Subtoal2, 'VAT': this.VAT2, 'PromoAmount': this.PromoAmountforVisibility2, 'ExtraChargeAmount': this.ExtraChargeAmount2, 'NetAmount': this.Total2 }];
    //console.log(body);
    return body;
  }
  CalculateTaxExclusive2(ProdcutPrice, ModifirePrice, ComboProductAmount, BogoAmount, ProductPercentage, ExtraChargePercentage) {
    debugger
    let taxpercent = this.ISCalculateTaxProductWise == false ? this.OutletTaxRate : ProductPercentage;
    BogoAmount = BogoAmount == undefined ? 0 : BogoAmount;
    this.productmodifiresum2 = (ProdcutPrice + ModifirePrice + ComboProductAmount);
    let extrachrgepercentamount = 0;
    let ProductPriceAfterDiscount = 0;
    if (this.IsExtraCharge == true && ExtraChargePercentage > 0) {

      this.CalculateProductPriceAfterWithTotalDiscountAmount2(taxpercent);
      ProductPriceAfterDiscount = this.ItemWiseAmountAfterDisc2 > 0 ? this.ItemWiseAmountAfterDisc2 : this.productmodifiresum2;
      extrachrgepercentamount = (ProductPriceAfterDiscount * ExtraChargePercentage) / 100;
      this.ExtraChargeproductsubtotal2 = extrachrgepercentamount;
      this.productsubtotal2 = this.productmodifiresum2;

      this.ProductVat2 = ((this.productmodifiresum2 - this.ItemWiseDiscountAmount2 + this.ExtraChargeproductsubtotal2 + BogoAmount) * taxpercent) / 100;

    }
    else {
      this.CalculateProductPriceAfterWithTotalDiscountAmount2(taxpercent);
      this.productsubtotal2 = this.ItemWiseAmountAfterDisc2;
      this.ProductVat2 = ((this.ItemWiseAmountAfterDisc2 + BogoAmount) * taxpercent) / 100;
    }

    this.Subtoal2 += this.productsubtotal2;
    this.PromoAmountforVisibility2 = this.TotalDiscountAmount2;
    this.VAT2 += this.ProductVat2;
    this.ExtraChargeAmount2 += this.ExtraChargeproductsubtotal2;
  }

  CalculateTaxInclusive2(ProdcutPrice, ModifirePrice, ComboProductAmount, BogoAmount, ProductPercentage, ExtraChargePercentage) {

    this.productmodifiresum2 = (ProdcutPrice + ModifirePrice + ComboProductAmount);
    let taxpercent = this.ISCalculateTaxProductWise == false ? this.OutletTaxRate : ProductPercentage;
    BogoAmount = BogoAmount == undefined ? 0 : BogoAmount;

    let extrachrgepercentamount = 0;
    let ProductPriceAfterDiscount = 0;
    if (this.IsExtraCharge == true && ExtraChargePercentage > 0) {
      this.CalculateProductPriceAfterWithTotalDiscountAmount2(taxpercent);
      ProductPriceAfterDiscount = this.ItemWiseAmountAfterDisc2 > 0 ? this.ItemWiseAmountAfterDisc2 : this.productmodifiresum2;
      extrachrgepercentamount = (ProductPriceAfterDiscount * ExtraChargePercentage) / 100;
      this.ExtraChargeproductsubtotal2 = ((extrachrgepercentamount) * 100) / (100 + taxpercent);

      this.productsubtotal2 = ((this.ItemWiseAmountAfterDisc2 - extrachrgepercentamount) * 100) / (100 + taxpercent);
      this.ProductVat2 = ((this.ItemWiseAmountAfterDisc2) * taxpercent) / (100 + taxpercent);
    }
    else {
      this.CalculateProductPriceAfterWithTotalDiscountAmount2(taxpercent);

      this.productsubtotal2 = ((this.ItemWiseAmountAfterDisc2) * 100) / (100 + taxpercent);
      this.ProductVat2 = ((this.ItemWiseAmountAfterDisc2) * taxpercent) / (100 + taxpercent);
    }
    this.Subtoal2 += this.productsubtotal2;
    this.PromoAmountforVisibility2 = this.TotalDiscountAmount2;
    this.VAT2 += this.ProductVat2;
    this.ExtraChargeAmount2 += this.ExtraChargeproductsubtotal2;

  }
  CalculateDiscountAmount2(PriceWithOutVAT) {
    if (this.IsPercentPromoDiscount) {
      this.productDiscount2 += (parseFloat(PriceWithOutVAT) * this.PromoAmount2) / 100;
    }
    else {
      this.productDiscount2 = this.PromoAmount2;
    }
  }
  CalculateTotalDiscountAmount2() {

    let modifireamount = 0;
    let ComboProductAmount = 0;
    debugger
    for (var i = 0; i < this.lst2.length; i++) {
      let Quant = this.lst2[i].Quantity;
      if (this.lstmodifir2.filter(t => t.rowid == this.lst2[i].rowid && (this.lst2[i].DelFlag == null || this.lst2[i].DelFlag == false) && t.Price > 0).length > 0)
        modifireamount += this.lstmodifir2.filter(t => t.rowid == this.lst2[i].rowid && this.lst2[i].DelFlag == null || this.lst2[i].DelFlag == false).map(t => t.Price * Quant).reduce((a, b) => a + b);
      else
        modifireamount += 0;

      if (this.lstCombo2.filter(t => t.rowid == this.lst2[i].rowid && (this.lst2[i].DelFlag == null || this.lst2[i].DelFlag == false) && t.Price > 0).length > 0)
        ComboProductAmount = this.lstCombo2.filter(t => t.rowid == this.lst2[i].rowid && this.lst2[i].DelFlag == null || this.lst2[i].DelFlag == false).map(t => t.Price * parseFloat(this.lst2[i].Quantity)).reduce((a, b) => a + b);
      else
        ComboProductAmount = 0;
    }

    let sum = 0;
    if (this.lst2.length > 0) {
      if (this.lst2.filter(x => x.DelFlag == null || x.DelFlag == false).length > 0) {
        sum = this.lst2.filter(x => x.DelFlag == null || x.DelFlag == false).map(x => x.Price * x.Quantity).reduce((a, b) => a + b);
      }
    }
    else {
      sum = 0;
    }
    this.ProdcutTotalAmount2 = (sum + modifireamount + ComboProductAmount);
    if (this.IsPercentPromoDiscount) {
      this.TotalDiscountAmount2 = ((sum + modifireamount + ComboProductAmount) * this.PromoAmount2) / 100;
    }
    else {
      this.TotalDiscountAmount2 = +this.PromoAmount2;
    }

  }

  CalculateVATOnDiscountAmountExclusive2(taxpercent) {
    if (this.IsTaxOnDiscountAmount == true && this.productDiscount2 > 0) {
      this.VATOnDiscountAmount2 = (this.productDiscount2 * taxpercent) / 100;
      this.DiscountAmountWithOutVAT2 = this.productDiscount2 - this.VATOnDiscountAmount2;
    }
    else {
      this.VATOnDiscountAmount2 = 0;
      this.DiscountAmountWithOutVAT2 = 0;
    }
  }
  FinalDiscountCalculationInclusive2(discountwitout) {
    debugger;
    if (this.TotalDiscountAmount2 == this.ProdcutTotalAmount2) {
      this.Subtoal2 = 0;
      this.Total2 = 0;
      this.VAT2 = 0;
    }
    else {
      if (discountwitout == false)
        this.Subtoal2 = this.Subtoal2 - this.PromoAmountforVisibility2;
      this.Total2 = this.Subtoal2 + this.VAT2 + this.ExtraChargeAmount2;

    }
  }
  FinalDiscountCalculationExclusive2(discountwitout) {
    debugger;
    if (this.TotalDiscountAmount2 == this.ProdcutTotalAmount2) {
      this.VAT2 = 0;
      this.ExtraChargeAmount2 = 0;
      this.Subtoal2 = 0;
      this.Total2 = this.Subtoal2 + this.VAT2 + this.ExtraChargeAmount2;
    }
    else {
      if (discountwitout == false)
        this.Subtoal2 = this.Subtoal2 - this.PromoAmountforVisibility2;
      this.Total2 = this.Subtoal2 + this.VAT2 + this.ExtraChargeAmount2;
    }
  }
  ItemWiseAmountAfterDisc2: number = 0;
  ItemWiseVat2: number = 0;
  ItemWiseDiscountAmount2: number = 0;
  CalculateProductPriceAfterDiscount2(taxpercent) {
    debugger
    this.ItemWiseAmountAfterDisc2 = 0;
    this.ItemWiseVat2 = 0;
    this.ItemWiseDiscountAmount2 = 0;
    this.ItemWiseDiscountAmount2 = this.productDiscount2 / this.TotalAmountforDiscount2 * this.productmodifiresum2;
    this.ItemWiseAmountAfterDisc2 = this.productmodifiresum2 - this.ItemWiseDiscountAmount2;
    this.ItemWiseVat2 = this.ItemWiseAmountAfterDisc2 * taxpercent / (100 + taxpercent);
    return this.ItemWiseAmountAfterDisc2;
  }
  CalculateProductPriceAfterWithTotalDiscountAmount2(taxpercent) {
    debugger
    this.ItemWiseAmountAfterDisc2 = 0;
    this.ItemWiseVat2 = 0;
    this.ItemWiseDiscountAmount2 = 0;
    if (this.TotalDiscountAmount2 > 0 && this.IsTaxOnDiscountAmount == true) {
      this.ItemWiseDiscountAmount2 = this.TotalDiscountAmount2 / this.ProdcutTotalAmount2 * this.productmodifiresum2;
      this.ItemWiseAmountAfterDisc2 = this.productmodifiresum2 - this.ItemWiseDiscountAmount2;
      this.ItemWiseVat2 = this.ItemWiseAmountAfterDisc2 * taxpercent / (100 + taxpercent);
    } else {
      this.ItemWiseAmountAfterDisc2 = this.productmodifiresum2;
      this.ItemWiseVat2 = 0;
    }

  }

  OK: any = this.translate.transform('OK');
  Close: any = this.translate.transform('Close');
}

export class ResturentInfo {
  ID: any;
  TextColor: any = '';
  BaseColor: any = '';
  Title: any = '';
  Message: any = '';
  MainImagePath: any = '';
  MainImageName: any = '';
  IconImagePath: any = '';
  IconImageName: any = '';
  RestaurantName: any = '';
  RestaurantNameDisplay: any = '';
}
export class Company {
  CompanyID: any;
  CompanyName: any;
  outletsCount: any;
  CountryID: any;
  hearbout: any;
  Mobile: any;
  CurrencyCode: any;
  Rate: any;
  NumOfDecimal: any;
  CountryCode: any;
  IsOTPRequired: boolean;
}
export class OutletInfo {
  BranchID: any = 0;
  BranchName: any = '';
  CityId: any = 0;
  ImageName: any = '';
  TaxRate: any = 0;
  TaxID: any = 0;
  IsTaxInclusive: boolean = false;
  IsTaxOnDiscountAmount: boolean = false;
  IsZatcaInvoiceOn: boolean;
  IsExtraCharge: boolean;
  ExtraChargeDescription: any;
  ExtraChargeDescriptionArabic: any;
}

export class discountVoucher {
  DiscVoucherID: any;
  VoucherCode: any;
  VoucherDesc: any;
  IsPercent: any;
  Amount: any;
  MinOrderValue: any;
  UsagePerUser: any;
  UsagePerVoucher: any;
  VoucherExpiryDate: any;
  OutletIds: any;
  Status: any;
  CompanyId: any;
}


class Product {
  UnitName: any;
  CategoryName: any;
  IsSelected: boolean;
  TotalAmount: any;
  ProductID: any;
  ProductCode: any;
  ProductName: any;
  AProductName: any;
  Description: any;
  ProductType: any;
  SubCatagoryID: any;
  UnitID: any;
  BrandID: any;
  SizeID: any;
  Barcode: any;
  SKUNumber: any;
  Factor: any;
  ExpiryRange: any;
  MinQtyLimit: any;
  CostPrice: any;
  TaxPercent: any;
  TaxApply: any;
  ProductImage: any;
  SupplierID: any;
  Active: any;
  LowestTemp: any;
  HighestTemp: any;
  FoodLocation: any;
  InventoryItem: any;
  ProductionDate: any;
  ExpiryDate: any;
  BranchIds: any;
  ArabicDescription: any;
  CountryID: any;
  CompanyId: any;
  SequenceNo: any;
  CatagoryID: any;
  ReferenceCode: any;
  MaintainStock: any;
  PrinterIds: any;
  Type: any;
  GroupID: any;
  PartNumber: any;
  Make: any;
  SalePrice: any;
  TaxRateActual: any;
  SupplyID: any;
  Specification: any;
  LocationId: any;
  SKU_No: any;
  StockDepletionId: any;
  Yield: any;
  InventoryInHand: any;
  UnitCost: any;
  TaxabilityID: any;
}
export class RetailMaster {
  OrderID: any;
  OrderDate: any;
  OrderInvoiceNo: any;
  PayType: any;
  Channel: any;
  Remarks: any;
  IsTaxInclusive: boolean;
  IsTaxOnDiscountAmount: boolean;
  CustAddressID: any;
  CustomerID: any;
  CustomerName: any;
  OutletID: any;
  OutletName: any;
  CompanyId: any;
  CounterId: any;
  TotalAmount: any;
  TotalTaxAmount: any;
  TotalDiscount: any;
  NetAmount: any;
  CreatedBy: any;
  CreatedOn: any;
  OrderPerpStatus: any = 'Delivered';
  OrderPayStatus: any;
  POSID: any;
  ReferenceNo: any;
  PromoCode: any;
  PromoAmount: any;

  IsExtraCharge: boolean;
  ExtraChargeAmount: any;
  ExtraChargeDescription: any;
  ExtraChargeDescriptionArabic: any;
}
export class RetailDetail {
  ProductID: any;
  ProductCategoryID: any;
  OrderID: any;
  UnitID: any;
  Quantity: any;
  Price: any;
  Amount: any;
  TaxPercent: any;
  TaxAmount: any;
  TotalAmount: any;
  CompanyId: any;
  ExtraChargePercentage: any;
}
