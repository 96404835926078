<link href="//stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet">

<nb-card>
  <nb-card-header>
    <div class="form-group col-md-12">
      <div class="row">
        <div class="col-md-10 cls2">
          {{'AcceptOrder' | translate}}

        </div>

        <div class="col-md-2 cls2">
          {{ObjOrderMaster.OrderInvoiceNo}}
        </div>
      </div>
      </div>
      
  </nb-card-header>

  <nb-card-body>
    <div class="form-group col-md-12">
      <div class="row">
        <div class="col-md-4">
        </div>
        <div class="col-md-8">
          <label class="label clsc"> {{'PrepTime' | translate}}</label>
        </div>
      </div>

      <div class="row">
        <div class="col-md-2">
        </div>
        <div class="">
          <button nbButton hero status="warning" (click)="Minus()">-</button>

          <input type="text" nbInput hero status="primary" fieldSize="medium" [(ngModel)]="PrepTime" class="clsM1" style="text-align:center" name="AddSub">
          <button nbButton hero status="primary" (click)="Plus()">+</button>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4">
        </div>
        <div class="col-md-8">
          <label class="label clsc">{{'Minutes' | translate}}</label>
        </div>
      </div>

    </div>

    <div class="form-group col-md-12">

      <div class="row">
        <div class="col-md-10">
        </div>

        <div class="col-md-2">
        </div>

      </div>
    </div>

    <div class="form-group col-md-12 col-sm-12">

      <div class="row">
        <div class="col-md-10 col-sm-6 cls2">
          <button nbButton status="basic" (click)="ClosePopup()">{{'Close' | translate}}</button>
        </div>

        <div class="col-md-2 col-sm-6 cls2">
          <button nbButton status="info" (click)="AcceptOrder()">{{'Accept' | translate}}</button>
        </div>

      </div>
    </div>
  </nb-card-body>
</nb-card>
