<nb-card style="border: none;">
    <nb-card-header style="padding: 0px;padding-bottom: 5px;">
      <div class="parent1"  style="display: block;padding-top: 0px; border-bottom: 1px solid #edf1f7 !important;">
        <div class="child1" style="max-width: none;">{{lblHotKeys}}<img src="./assets/images/lookupclose.png" (click)="btnclose()" class="cross-icon" style="float: right;cursor: pointer;margin-top: -5px;" /></div>
      </div>
    </nb-card-header>
      <nb-card-body >
        
        <div class="form-group">
          <div class="row">
            <br />
            <div class="col-md-6 col-sm-12">
              <div class="form-group">
                <label for="bBarcode" class="label">{{BarcodeProduct}}</label>
                <input nbInput fullWidth name="bBarcode" id="BarcodeProduct" placeholder="Item Barcode" [(ngModel)]="SearchBarcode" />

                <div *ngIf="IsItemFound" style="background: white;color: red;background: white;text-align: center; padding: 10px;border-radius: 10px;width:89%">{{ProductNotFound}}</div>

              </div>
            </div>
            <div class="col-md-3 col-sm-12">
              <div class="form-group">
                <br />
                <button class="buttoncss btn btnfinish" (click)="SearchProduct()">Search</button>
              </div>
            </div>
          </div>
          <hr />
          <br />

          <div class="col-md-12 col-xs-12 col-xs-12 col-sm-12">
            <div class="row">

              <div class="col-md-4 col-sm-4 col-lg-2 text-center pdicon" *ngFor="let r of lstarray;let i = index">
                <div class="icon icon-shape icon-shape-secondary" style="cursor:pointer" (click)="ItemSelcted(r)">
                  <div>
                    {{r.ProductName}}
                    <br *ngIf="r.UnitCost != undefined" />
                    {{CurrencyCode}} {{r.UnitCost| number:'1.'+NumOfDecimal}}
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>
      </nb-card-body>
  
    </nb-card>
