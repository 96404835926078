import { Component, OnInit, ElementRef, ViewChild, AfterViewInit, Output, EventEmitter, QueryList, ViewChildren, Renderer2 } from '@angular/core';
import { DataserviseService } from '../../shared/dataservice';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '../../shared/translate.service';
import {  NbLayoutDirection, NbLayoutDirectionService} from '@nebular/theme';
import { BehaviorSubject } from 'rxjs';
import { UtilitiesService } from 'app/shared/utilities.service';

@Component({
  selector: 'ngx-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit  {
  @ViewChild('childComponent', { static: false }) childComponentRef: ElementRef;

  // loginForm: FormGroup;
   loading = false;

  ShowlblUserName: boolean = false;
  ShowlblPassword: boolean = false;
  showlblInvalid: boolean = false;
  showlblSubsEndDate: boolean = false;
  // returnUrl: string;
  objUser: User = new User();
  lstUser = [];
  lstCompanyInfo = [];
  companyPath: any;
  hashkey: any;
  lstloginUserHashkey = [];
  textColor:string ='';
  baseColor: string = '';
  DefaultLanguage: any = 'en';
  VersionNo: any = '';
  private currentUserSubject = new BehaviorSubject<any>(null)
  @ViewChild('loginID', { static: false }) inputEl: ElementRef;

  constructor(private directionService : NbLayoutDirectionService,private translate: TranslateService,private dataservice: DataserviseService, public router: Router, private _Activatedroute: ActivatedRoute,private _utility: UtilitiesService, private renderer: Renderer2) {

  }

  ngAfterViewInit() {
    if (this.childComponentRef) {
      const scrollableElement = this.childComponentRef.nativeElement.querySelector('.scrollable-container');
      if (scrollableElement) {
        this.renderer.removeClass(scrollableElement, 'scrollable-container');
      }
    }}

 
  ngOnInit() {
    debugger
    /*  this.companyName = window.location.href.split('/')[3]*/
    this.VersionNo = this._utility.GetVersionNo();
    if (this.lstUser == null || this.lstUser.length == 0) {
    this.router.navigate(['login']);
    }
    // if (sessionStorage.getItem('hashKey')) {debugger
    //   let result = sessionStorage.getItem('hashKey')
    //       let rights = result.split('&').pop();
    //          let d = result.substring(4);
    //         let key = d.split('&')[0];
    //   this.dataservice.get('Users/LoginHashkey/' + key).subscribe(data => {
    //     this.lstloginUserHashkey = data;
    //     if (this.lstloginUserHashkey) {debugger
    //       if (rights == 'true') {
    //         this.router.navigate([this.lstloginUserHashkey[0]["CompanyName"]+'/dashboardpos/dashboard']);
    //       }
    //       else {
    //         this.router.navigate([this.lstloginUserHashkey[0]["CompanyName"]+'/welcome/welcometodigidiner']);
    //       }
    //       this.companyPath = this.lstloginUserHashkey[0]["CompanyName"];
    //       this.router.config.splice(0, 0, {
    //         path: this.companyPath,
    //         loadChildren: () => import('../../pages/pages.module').then(m => m.PagesModule),
    //       });
    //     }
    //   })

    if (localStorage.getItem('hashKey') !==null) {
      let result = localStorage.getItem('hashKey')
      var res = result.split('&');
      let key = res[0];
      var rights = res[1];
      var Active = res[2];
      this.dataservice.get('Users/LoginHashkey/' + key+'/'+ Active).subscribe(data => {
        this.lstloginUserHashkey = data;
        if (this.lstloginUserHashkey) {
          localStorage.setItem('sessionObject', JSON.stringify(data));
          let navRoute = this.lstloginUserHashkey[0]["CompanyName"]
          this.router.navigate([navRoute]);

          this.companyPath = this.lstloginUserHashkey[0]["CompanyName"];
          this.router.config.splice(0, 0, {
            path: this.companyPath,
            loadChildren: () => import('../../pages/pages.module').then(m => m.PagesModule),
          });
        }

        if (rights == 'true') {
          this.router.navigate([this.lstloginUserHashkey[0]["CompanyName"] + '/dashboardpos/dashboard']);
        }
        else {
          this.router.navigate([this.lstloginUserHashkey[0]["CompanyName"] + '/welcome/welcometodigidiner']);
        }

      })
    }
  }

  loginfocus() {
    debugger
    this.inputEl.nativeElement.focus()
  }
  
  
  Login() {debugger
    this.showlblInvalid = false;
    this.showlblSubsEndDate = false;
    if (this.objUser.Name == undefined || this.objUser.Name == "") {
      this.ShowlblUserName = true;
    }
    else {
      this.ShowlblUserName = false;
    }
    if (this.objUser.Password == undefined || this.objUser.Password == "") {
      this.ShowlblPassword = true;
    }
    else {
      this.ShowlblPassword = false;
    }

    if (this.ShowlblUserName || this.ShowlblPassword) {
      return
    }
    
    // this.loading = true;
    this.dataservice.post('Users/LoginClick', this.objUser).subscribe(data => {
      debugger
      this.lstUser = data;
      if (this.lstUser != null && this.lstUser.length > 0) {
        // this.loading = false;
        let d = new Date();
        const sysDate = new Date((d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate()));
        const dbDate = new Date(this.lstUser[0].SubEndDate);
        if (sysDate > dbDate) {
          this.showlblSubsEndDate = true;
          return;
        }
        else {
          this.showlblSubsEndDate = false;
        }
        var test = 'en';
        var DefaultOutlet = {'ID' : this.lstUser[0].OutletID, 'OutletName' : this.lstUser[0].OutletName };
        localStorage.setItem('LanguageCulture' + this.lstUser[0].CompanyNameLC, this.DefaultLanguage);
        debugger
        localStorage.setItem('SelectedBranchRow' + this.lstUser[0].CompanyNameLC, JSON.stringify(DefaultOutlet));
        
    if (localStorage.getItem('LanguageCulture'+this.lstUser[0].CompanyNameLC) != null)
    {  
      if (localStorage.getItem('LanguageCulture'+this.lstUser[0].CompanyNameLC) == 'en') 
      {
        test = 'en'
      }
      else 
      {
        test = 'ar';
      }
    }
        const oppositeDirection = test == 'en' ? NbLayoutDirection.LTR : NbLayoutDirection.RTL;
        this.directionService.setDirection(oppositeDirection);
debugger
        this.lstCompanyInfo.push(this.lstUser);
        localStorage.setItem('sessionObject' + this.lstUser[0].CompanyNameLC, JSON.stringify(this.lstCompanyInfo));
        // this.currentUserSubject.next(this.lstCompanyInfo);
        // this._utility.SetUserInfo('shaniii');
        debugger
        this.translate.useComp(this.DefaultLanguage,this.lstUser[0].CompanyNameLC);
             // if (this.lstUser[0].dashboardRights == 1 && this.lstUser[0].retailPOSRights == 1) {
        //   var objcolor={
        //     "textColor":this.lstUser[0].TextColor,
        //     "baseColor":this.lstUser[0].BaseColor,
        //     "compName":this.lstUser[0].CompanyName
        //   }
        //   sessionStorage.setItem('color', JSON.stringify(objcolor));
        //   this.router.navigate([this.lstUser[0].CompanyName + '/dashboardpos/maindashboard']);
        // }
         if (this.lstUser[0].dashboardRights == 1 ) {
           this.router.navigate([this.lstUser[0].CompanyName + '/dashboardpos/dashboard']);
           
        }
        //redirect to tehseen form
        // else if (this.lstUser[0].dashboardRights == 0 && this.lstUser[0].retailPOSRights == 0) {
        //   this.router.navigate([this.lstUser[0].CompanyName + '/dashboardpos/dashboard']);
        // }
        else {
           this.router.navigate([this.lstUser[0].CompanyName + '/welcome/welcometodigidiner']);
  
        }
       



        this.companyPath = this.lstUser[0].CompanyName;
        this.router.config.splice(0, 0, {
          path: this.companyPath,
          loadChildren: () => import('../../pages/pages.module').then(m => m.PagesModule),
        });
        // console.log(this.router.config)
      }

      else if (this.lstUser == null) {
        this.showlblInvalid = true;
        // this.loading = false;
      }
      else {
        this.showlblInvalid = false;
      }
    })

  }


  changeLocation(event): void {
    debugger
    this.DefaultLanguage = event;
    this.translate.useLogin(this.DefaultLanguage);
    // localStorage.setItem('LanguageCulture'+ this.lstUser[0].CompanyName, 'en');
    // this.translate.useComp(this.DefaultLanguage,this.lstUser[0].CompanyName);
  }

  keyDownFunction() {
    this.Login();
  }


}
class User {
  Name: any;
  Password: any;
  ApplicationCode:any = 'OrderOnline';
}

