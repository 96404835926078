import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { UtilitiesService } from './utilities.service';



@Injectable()
export class TranslateService {

  data: any = {};

  constructor(private http: HttpClient, private Utilities: UtilitiesService) {

    this.data = {};

   }

  use(lang: string): Promise <{}> {
    if (localStorage.getItem('LanguageCulture' + this.Utilities.getURLCompanyName()) == 'en')
    lang = 'en';
    else
    lang = 'ar';

    return new Promise<{}>((resolve, reject) => {

      const langPath = `assets/languageFiles/${lang || 'en'}.json`;

      this.http.get<{}>(langPath).subscribe(

      translation => {this.data = Object.assign({}, translation || {});

      resolve(this.data);

      },

     error => {
       this.data = {};
       resolve(this.data);
     },

      );
    });

  }

  useLogin(lang: string): Promise <{}> {
    // if(localStorage.getItem('LanguageCulture'+this.Utilities.getURLCompanyName()) == 'en')
    // lang = 'en';
    // else
    // lang = 'ar';

    return new Promise<{}>((resolve, reject) => {

      const langPath = `assets/languageFiles/${lang || 'en'}.json`;

      this.http.get<{}>(langPath).subscribe(

      translation => {this.data = Object.assign({}, translation || {});

      resolve(this.data);

      },

     error => {
       this.data = {};
       resolve(this.data);
     },

      );
    });

  }

  useComp(lang: string, CompanyName: string): Promise <{}> {
      if (localStorage.getItem('LanguageCulture' + CompanyName) == 'en')
    lang = 'en';
    else
    lang = 'ar';

    return new Promise<{}>((resolve, reject) => {

      const langPath = `assets/languageFiles/${lang || 'en'}.json`;

      this.http.get<{}>(langPath).subscribe(

      translation => {this.data = Object.assign({}, translation || {});

      resolve(this.data);

      },

     error => {
       this.data = {};
       resolve(this.data);
     },

      );
    });
  }

}
