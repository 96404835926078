import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { PagesComponent } from './pages.component';
// import { AboutUsComponent } from '../about-us/about-us.component';
// import { DashboardComponent } from './dashboard/dashboard.component';
// import { ECommerceComponent } from './e-commerce/e-commerce.component';
// import { NotFoundComponent } from './miscellaneous/not-found/not-found.component';

const routes: Routes = [{
  path: '',
  component: PagesComponent,
  children: [
    // {
    //   path: 'aboutus',
    //   component: AboutUsComponent,
    // },
    // {
    //   path: 'dashboard',
    //   component: ECommerceComponent,
    // },
    // {
    //   path: 'iot-dashboard',
    //   component: DashboardComponent,
    // },
    // {
    //   path: 'layout',
    //   loadChildren: () => import('./layout/layout.module')
    //     .then(m => m.LayoutModule),
    // },
    // {
    //   path: 'forms',
    //   loadChildren: () => import('./forms/forms.module')
    //     .then(m => m.FormsModule),
    // },
    // {
    //   path: 'ui-features',
    //   loadChildren: () => import('./ui-features/ui-features.module')
    //     .then(m => m.UiFeaturesModule),
    // },
    // {
    //   path: 'modal-overlays',
    //   loadChildren: () => import('./modal-overlays/modal-overlays.module')
    //     .then(m => m.ModalOverlaysModule),
    // },
    {
      path: 'dashboardpos',
      loadChildren: () => import('./dashboardpos/dashboardpos.module')
        .then(m => m.DashboardPosModule),
    },
    {
      path: 'productmanagement',
      loadChildren: () => import('./productmanagement/productmanagement.module')
        .then(m => m.ProductManagementModule),
    },
    {
      path: 'menu',
      loadChildren: () => import('./menu/menu.module')
        .then(m => m.MenuManagementsModule),
    },
    {
      path: 'usermanagement',
      loadChildren: () => import('./usermanagement/usermanagement.module')
        .then(m => m.UserManagementModule),
    },
    {
      path: 'live',
      loadChildren: () => import('./live/live.module')
        .then(m => m.LiveModule),
    },
    {
      path: 'ordermanagements',
      loadChildren: () => import('./ordermanagements/ordermanagements.module')
        .then(m => m.OrderManagementsModule),
    },
    {
      path: 'companymanagement',
      loadChildren: () => import('./companymanagement/companymanagement.module')
        .then(m => m.CompanyManagementModule),
    },
    {
      path: 'customermanagement',
      loadChildren: () => import('./customermanagement/customermanagement.module')
        .then(m => m.CustomerManagementModule),
    },
    {
      path: 'purchasemanagement',
      loadChildren: () => import('./purchasemanagement/purchasemanagement.module')
        .then(m => m.PurchaseManagementModule),
    },
    {
      path: 'employeemanagement',
      loadChildren: () => import('./employeemanagement/employeemanagement.module')
        .then(m => m.EmployeeManagementModule),
    },
    {
      path: 'ecrintegration',
      loadChildren: () => import('./ecrintegration/ecrintegration.module')
        .then(m => m.ECRIntegrationModule),
    },
    {
      path: 'pos',
      loadChildren: () => import('./pos/pos.module')
        .then(m => m.PosModule),
    },
   
    {
      path: 'outletsmanagement',
      loadChildren: () => import('./outletsmanagement/outletsmanagement.module')
        .then(m => m.OutletsManagementModule),
    },
    {
      path: 'reports',
      loadChildren: () => import('./reports/Reports.module')
        .then(m => m.ReportsModule),
    },
    {
      path: 'welcome',
      loadChildren: () => import('./welcome/welcome.module')
        .then(m => m.WelcomeModule),
    },
    {
      path: 'inventory',
      loadChildren: () => import('./inventorymanagement/inventorymanagement.module')
        .then(m => m.InventoryModule),
    },
    // {
    //   path: 'extra-components',
    //   loadChildren: () => import('./extra-components/extra-components.module')
    //     .then(m => m.ExtraComponentsModule),
    // },
    // {
    //   path: 'maps',
    //   loadChildren: () => import('./maps/maps.module')
    //     .then(m => m.MapsModule),
    // },
    // {
    //   path: 'charts',
    //   loadChildren: () => import('./charts/charts.module')
    //     .then(m => m.ChartsModule),
    // },
    // {
    //   path: 'editors',
    //   loadChildren: () => import('./editors/editors.module')
    //     .then(m => m.EditorsModule),
    // },
    // {
    //   path: 'tables',
    //   loadChildren: () => import('./tables/tables.module')
    //     .then(m => m.TablesModule),
    // },
    // {
    //   path: 'miscellaneous',
    //   loadChildren: () => import('./miscellaneous/miscellaneous.module')
    //     .then(m => m.MiscellaneousModule),
    // },
    {
      path: '',
      redirectTo: 'dashboard',
      pathMatch: 'full',
    },
    // {
    //   path: '**',
    //   component: NotFoundComponent,
    // },
  ],
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule {
}
