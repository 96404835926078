<link href="//stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet">

<nb-card style="margin-bottom:0px!important">
  <nb-card-header>
    <div class="form-group col-md-12">
      <div class="row">
        <div class="col-md-10 cls2">
          {{btnText}} {{'Order' | translate}}
        </div>

        <div class="col-md-2 cls2">
          {{ObjOrderMaster.OrderInvoiceNo}}
        </div>
      </div>
      </div>
      
  </nb-card-header>

  <nb-card-body>
    <div class="form-group col-md-12">
      <div class="row">
        <div class="col-md-3">
        </div>
        <div class="">
          <label class="label clsf">{{'Pleaseselectareason' | translate}}</label>
        </div>
      </div>

      <div class="row">
        <div class="form-group" style="width:100%;text-align:center">
          <nb-select id="IdRejectDD" [(selected)]="ObjOrderMaster.RejectReasonID" style="width:100%" (selectedChange)="OnRejectedReasonChange($event)">
            <nb-option *ngFor="let _data of ddlRejectReasons" [value]="_data.RejectReasonID">{{_data.RejectReason}}</nb-option>
          </nb-select>

        </div>
      </div>

      <div class="row">
        <div class="col-md-3">
        </div>
        <div class="">
          <label class="label clsf">{{'Description' | translate}}</label>
        </div>
      </div>

      <div class="row">
        <div class="form-group" style="width:100%;text-align:center">
          <textarea nbInput style="width:100%" [(ngModel)]="ObjOrderMaster.RejectReasonDetail" [maxlength]="maxChars"></textarea>
          <br />
          <label style="font-size:12px"> {{'CharactersRemaining' | translate}} {{ObjOrderMaster.RejectReasonDetail.length}}/{{maxChars}}</label>
        </div>
      </div>

   

    </div>

    <div class="form-group col-md-12">

      <div class="row">
        <div class="col-md-10">
        </div>

        <div class="col-md-2">
        </div>

      </div>
    </div>

    <div class="form-group col-md-12 col-sm-12">

      <div class="row">
        <div class="col-md-10 col-sm-6 cls2">
          <button nbButton status="basic" (click)="ClosePopup()">{{'Close' | translate}}</button>
        </div>

        <div class="col-md-2 col-sm-6 cls2">
          <button nbButton status="danger" (click)="RejectOrder()">{{btnText}}</button>
        </div>

      </div>
    </div>
  </nb-card-body>
</nb-card>
