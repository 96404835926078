<link href="//stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet">

<nb-card>  
  <nb-card-body >

    <div class="child1" style="max-width: 100% !important ;">{{CustomerReceiptVoucher}}
      <img src="./assets/images/lookupclose.png" (click)="btnclose()" style="float: right; width: 25px;cursor: pointer;">
    </div>
    
    <hr style="margin-top: 10px;"/>

    <div class="row">
        <div class="col-md-6">
        <div class="col-md-12 col-sm-12">
          <div class="form-group">
            <label for="bref" class="label">{{ReceiptNo}}</label>
            <input nbInput fullWidth name="bref" [(ngModel)]="ObjCustomerReceiptVoucher.ReceiptNo" placeholder="Reference" style="width: 91%;border-top-right-radius: 0px;border-bottom-right-radius: 0px;border-right: none;" readonly>           
            <i class="fa fa-search" style="padding: 13px;cursor: pointer;background: lightgray;border-left: none;border-top-right-radius: 5px;border-bottom-right-radius: 5px;" (click)="openDialogReceiptPopUp()"></i>
          </div>
        </div>
        <div class="col-md-12 col-sm-12">
            <div class="form-group">
              <label for="bref" class="label">{{CustomerName}}</label>
              <input nbInput fullWidth name="bref" [(ngModel)]="ObjCustomerReceiptVoucher.CustomerName" placeholder="Reference" style="width: 91%;border-top-right-radius: 0px;border-bottom-right-radius: 0px;border-right: none;" readonly>           
              <i class="fa fa-search" style="padding: 13px;cursor: pointer;background: lightgray;border-left: none;border-top-right-radius: 5px;border-bottom-right-radius: 5px;" (click)="openDialogCustomerPopUp()"></i>
              <p class="caption status-danger" *ngIf="showlblCustomerMandatory">Please select Customer</p>
            </div>
          </div>
          <div class="col-md-12 col-sm-12"> 
            <div class="form-group">
              <label for="bLocation" class="label">{{ReceiptNote}}</label>  
              <textarea nbInput fullWidth name="ReceiptNote" [(ngModel)]="ObjCustomerReceiptVoucher.Note" cols="40" rows="3"></textarea> 
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="col-md-12 col-sm-12"> 
            <div class="form-group">
              <label for="bLocation" class="label">{{ReceiptDate}}</label>   
              <input nbInput fullWidth name="bSupplier" [(ngModel)]="ObjCustomerReceiptVoucher.ReceiptDate"  >
            </div>
          </div>
          <div class="col-md-12 col-sm-12"> 
            <div class="form-group">
              <label for="bLocation" class="label">{{TotalCreditSales}}</label>   
              <input nbInput fullWidth name="bSupplier"  [(ngModel)]="ObjCustomerReceiptVoucher.TotalCreditSales"  readonly>
            </div>
          </div>
          <div class="col-md-12 col-sm-12"> 
            <div class="form-group">
              <label for="bLocation" class="label">{{PaidAmount}}</label>   
              <input nbInput fullWidth name="bSupplier"  [(ngModel)]="ObjCustomerReceiptVoucher.PaidAmount" readonly>
            </div>
          </div>
          <div class="col-md-12 col-sm-12"> 
            <div class="form-group">
              <label for="bLocation" class="label">{{OutstandingBalance}}</label>   
              <input nbInput fullWidth name="bSupplier" [(ngModel)]="ObjCustomerReceiptVoucher.Balance" readonly >
            </div>
          </div>
        </div>
      </div>


      <div id="sizeId2" class="panel2" style="line-height: 40px;font-weight: 700;">{{AddPaymentDetail}}
        <br/>
        <div class="row">
          <div class="col-md-3" style="line-height: 20px;">
            <label class="label">{{ReceiptPayType}}</label>
            <nb-select id="ReceiptPayType" fullWidth placeholder="Please select" [(selected)]="ReceiptPayType_selected" [(ngModel)]="ReceiptPayType_selected" >
                <nb-option [value]="1">Receipt Payment</nb-option>
                <nb-option [value]="2">Advance Payment</nb-option>
                <!-- <nb-option *ngFor="let option of listSize" [value]="option.value">{{option.title}}</nb-option> -->
            </nb-select>
            <p class="caption status-danger" *ngIf="showlblReceiptPayTypeMandatory" style="margin-bottom: 0px;">Please select Pay Type</p>
            <p class="caption status-danger" *ngIf="showlblInsertPayment" style="margin-bottom: 0px;">Please Insert Payment</p>
     
          </div>
          <div class="col-md-3" style="line-height: 20px;">
            <label class="label">{{PaymentMethod}}</label>
            <nb-select id="PaymentMethod" fullWidth placeholder="Please select" [(selected)]="PaymentMethod_selected" [(ngModel)]="PaymentMethod_selected" >
                <nb-option *ngFor="let option of lstPaymentMode" [value]="option.Code">{{option.PaymentName}}</nb-option>
            </nb-select>
            <p class="caption status-danger" *ngIf="showlblPaymentMethodMandatory" style="margin-bottom: 0px;">Please select Payment Method</p>

          <!-- <label *ngIf="showlblSalesTypeName_MultiplePrice" class="caption status-danger" >{{'PleaseenterSalesType' | translate}}</label>
          <label *ngIf="showlblDataAlreadyExists" class="caption status-danger" >This SalesType Is Already Set with Selected Outlet</label> -->
          </div>
          <!-- <div class="col-md-2" style="line-height: 20px;">
            <label class="label">{{'Size'}}</label>
          <nb-select id="idSize_AddMultiplePrice" fullWidth placeholder="Please select" [(selected)]="SizeMultiplePrice" [(ngModel)]="SizeMultiplePrice" >
            <nb-option *ngFor="let option of listSize" [value]="option.value">{{option.title}}</nb-option>
          </nb-select>
             <label *ngIf="showlblSizeAlreadyExists_MultiplePrice" class="caption status-danger" >{{'SizeAlreadyExists' | translate}}</label>
          </div> -->
          <div class="col-md-3" style="line-height: 20px;">
            <label class="label">{{PaymentAmount}}</label>
            <input type="text" name="inputField" maxlength="8" (keypress)="numberOnly($event,PaymentAmount_selected)" nbInput fullWidth [(ngModel)]="PaymentAmount_selected" width="100%" id="Price2"  onpaste="return false" ondrop="return false;" >
            <!-- <span style="position: absolute; margin-left: -50px;font-weight: 600;margin-top: 10px;">{{Currency}}</span>
            <label *ngIf="showlblPrice_MultiplePrice" class="caption status-danger" >{{'PleaseenterPrice' | translate}}</label> -->
            <p class="caption status-danger" *ngIf="showlblPaymentAmountMandatory" style="margin-bottom: 0px;">Please enter Amount</p>

          </div>
          <div class="col-md-3" style="line-height: 20px;">
            <label class="label">{{Reference}}</label>
            <input type="text" name="inputField" nbInput fullWidth [(ngModel)]="Reference_selected" width="100%" id="Reference"  onpaste="return false" ondrop="return false;" >
            <!-- <span style="position: absolute; margin-left: -50px;font-weight: 600;margin-top: 10px;">{{Currency}}</span>
            <label *ngIf="showlblPrice_MultiplePrice" class="caption status-danger" >{{'PleaseenterPrice' | translate}}</label> -->
          </div>
          <div class="col-md-12" style="text-align: right;padding-top: 10px;padding-bottom: 10px;">
            <button type="submit" status="success" nbButton class="btn" (click)="btnAddPaymentsClick()">{{btnAddPayments}}</button> 
          </div>

        </div>
    
      <ng2-smart-table [settings]="AddPaymentsSettings" [source]="source1" (edit)="rowEdit($event)" (createConfirm)="onCreateConfirm($event)" (editConfirm)="onEditConfirm($event)" (delete)="DeleteAddMultiplePrice($event)"> </ng2-smart-table>
      <!-- <label *ngIf="showlbladdlist" class="caption status-danger" >{{'PleaseenteratleastoneItemSize' | translate}}</label> -->
    </div>

    <div class="col-md-12" style="text-align: right;padding-right: 0px;">
        <button type="submit" status="success" nbButton class="btn" (click)="btnSaveClick()">{{Save}}</button> 
      </div>
  </nb-card-body>
</nb-card>