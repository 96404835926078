import { Component, ɵangular_packages_core_core_bp, ChangeDetectorRef } from '@angular/core';
import { DataserviseService } from '../../shared/dataservice';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { UtilitiesService } from '../../shared/utilities.service';

@Component({
  selector: 'ngx-receipt',
  templateUrl: './receipt.component.html',
  styleUrls: ['./receipt.component.scss'],
})

export class ReceiptComponent {
  ObjCompany: Company = new Company();
  CompanyName: string = '';
  OrderId: string = '';
  InvalidCompanyName: boolean = false;
  ObjOrderMaster: OrderMaster = new OrderMaster();
  ArrOrderDetail: OrderDetail[] = [];
  ArrVoucherData: VoucherData[] = [];
  ObjCurrency: Country = new Country();
  ObjCompanyInfo: CompanyInfo = new CompanyInfo();
  _PaymentDetails: PaymentDetails[] = [];
  CurrencyCode: any = '';
  QRUrl: any = '';
  ReturnAmount: any;
  ActivateTipAmount: any;


  constructor(private router: Router, private route: ActivatedRoute, private dataservice: DataserviseService, private activatedRoute: ActivatedRoute, private Utilities: UtilitiesService, private cdr: ChangeDetectorRef)
  {

  }


  title = 'app';
  elementType = 'img';
  elementType1 = 'url';
  logo: any = '../../../assets/images/globalinformix.png';
  lstUser = [];
  OutletId : any;
  AppStatus : any;
  IsFoodCatering : boolean=false;
  IsFoodCateringCopy : any;

  QRRedirection  : any = 1;
  QRRedirectionURL : any = '';


  ngOnInit(): void {
    this.InvalidCompanyName = false;
    debugger
    this.activatedRoute.paramMap.subscribe(x => {
      this.CompanyName = x.get('CompanyName');
      this.OrderId = x.get('OrderId');
      this.OutletId = x.get('OutletId');
      this.AppStatus = x.get('AppStatus');
      this.IsFoodCateringCopy = x.get('IsFoodCatering');
      this.IsFoodCatering = this.IsFoodCateringCopy == null ? false : this.IsFoodCateringCopy;

      if (this.CompanyName == null) {
        this.InvalidCompanyName = true;
        return;
      }
    });


    const body = [this.Utilities.getDecrypt(this.CompanyName)];
    debugger
    this.dataservice.post('OrderOnline/GetCompanyData/', body)
      .subscribe(data => {

        if (this.isArrayHasValue(data[0]['CompanyData'])) {
          this.InvalidCompanyName = false;
          this.ObjCompany.CompanyID = data[0]['CompanyData'][0].CompanyID;
          this.ObjCompany.CompanyName = data[0]['CompanyData'][0].CompanyName;
          this.ObjCompany.outletsCount = data[0]['CompanyData'][0].outletsCount;
          this.ObjCompany.CountryID = data[0]['CompanyData'][0].CountryID;
          this.ObjCompany.hearbout = data[0]['CompanyData'][0].hearbout;
          this.ObjCompany.Mobile = data[0]['CompanyData'][0].Mobile;
          this.ObjCompany.IsOTPRequired = data[0]['CompanyData'][0].IsOTPRequired;
          this.ObjCompany.CurrencyCode = data[0]['CompanyData'][0].CurrencyCode;
          this.ObjCompany.Rate = data[0]['CompanyData'][0].Rate;
          this.ObjCompany.NumOfDecimal = data[0]['CompanyData'][0].NumOfDecimal;
          this.ObjCompany.CountryCode = data[0]['CompanyData'][0].CountryCode;

          this.getMasterDetailData(this.Utilities.getDecrypt(this.OrderId),this.OutletId,this.AppStatus);
        }
        else {
          this.InvalidCompanyName = true;
          this.QRUrl = 'Invalid company name!';
          alert('Invalid company name!');
        }
      }, error => alert(this.Utilities.getconnectionmsg()));

    
     

  }

  printComponent() {
    //debugger
    //let myContainer = document.getElementById('invoice-POS') as HTMLInputElement;
  
    //var docprint = window.open('', 'PRINT', '');
    //docprint.document.write(myContainer.innerHTML);
    //docprint.document.close();
    //docprint.print();
    //docprint.close();

    window.print();
  }
  LoyaltyPoints: any = 0;
  isvisibleQRCode : boolean = true;
  async getMasterDetailData(OrderID,OutletID,AppStatus) {
    this.ObjOrderMaster = new OrderMaster();
    this.ArrOrderDetail = [];
    this._PaymentDetails = [];

    await this.dataservice.get('Receipt/GetOrderData/' + OrderID + '/' + this.ObjCompany.CompanyID + '/'+OutletID + '/'+ AppStatus+'/'+this.IsFoodCatering)
      .subscribe(data => {
        debugger
        if (data[0]['MasterData']) {
          this.ObjOrderMaster = data[0]['MasterData'][0];
          
        }
        if (this.isArrayHasValue(data[0]['DetailData'])) {
          this.ArrOrderDetail = data[0]['DetailData'];
        }
        else {
          this.ArrOrderDetail = [];
        }

        if (this.isArrayHasValue(data[0]['VoucherData'])) {
          this.ArrVoucherData = data[0]['VoucherData'];
        }
        else {
          this.ArrVoucherData = [];
        }

        if (data[0]['Currency']) {
          this.ObjCurrency = data[0]['Currency'][0];
          this.CurrencyCode = this.ObjCurrency.CurrencyCode;
        }

        if (data[0]['CompanyInfo']) {
          this.ObjCompanyInfo = data[0]['CompanyInfo'][0];
        }
        if (data[0]['ConsumedPoints'][0] && data[0]['ConsumedPoints'][0].ConsumedPoints != null && data[0]['ConsumedPoints'][0].ConsumedPoints != undefined) {
          this.LoyaltyPoints = data[0]['ConsumedPoints'][0].ConsumedPoints;
        }
        else {
          this.LoyaltyPoints = 0;
        }
        if (this.isArrayHasValue(data[0]['PaymentDetails'])) {
          this._PaymentDetails = data[0]['PaymentDetails'];
        }
        if (this.isArrayHasValue(data[0]['ReturnAmount'])) {
          debugger
          this.ReturnAmount = data[0].ReturnAmount[0].CashReturned;
          this.ActivateTipAmount = data[0].ReturnAmount[0].ActivateTipAmount;
        }
        this.lstUser = JSON.parse(localStorage.getItem('sessionObject'+this.Utilities.getURLCompanyName()));
        if(this.ObjOrderMaster.QRRedirection == 1)
        {
          this.QRUrl = this.Utilities.getQRURL(this.CompanyName, this.OrderId, this.lstUser[0]['CountryCode']);
        }
        else
        {
          this.QRUrl = this.ObjOrderMaster.QRRedirectionURL;
        }
        this.cdr.detectChanges();
        setTimeout(() => {window.print();}, 10); // 1 second delay

      }, error => alert(this.Utilities.getconnectionmsg()));
  }

  printhtml() {
    debugger
    var currDateTime = new Date().toLocaleString();
    var docprint = window.open('', 'PRINT', 'height=400,width=600');

    docprint.document.write('<body style="margin: 0px;">');
    docprint.document.write('<div id="invoice-POS"><div id="top"><div class="logo"></div><div class="info"></div></div>');
    docprint.document.write('<div id="mid">');

    if (this.ObjOrderMaster.OutletName != 'N/A') {
      docprint.document.write('<div class="info">');
      docprint.document.write('<div style="color:red !important;font-size:25px">' + this.ObjOrderMaster.OutletName + '</div>');
      docprint.document.write('<div class="mdatal">' + this.ObjOrderMaster.outletAddress + '</div>');
      docprint.document.write('<div class="mdatal">' + this.ObjOrderMaster.AreaName + '</div>');
      docprint.document.write('</div>');
    }

    if (this.ObjOrderMaster.CustomerName != 'N/A') {
      docprint.document.write('<div class="info" style="border-top: 2px dotted #eee;">');
      docprint.document.write('<div class="mdatal">' + this.ObjOrderMaster.CustomerName + '</div>');
      docprint.document.write('<div class="mdatal">' + this.ObjOrderMaster.PhoneNo + '</div>');
      if (this.ObjCurrency.CountryName == 'Pakistan') {
        docprint.document.write('<div style="border-bottom: 2px dotted #eee;" class="mdatal">' + this.ObjOrderMaster.AddressPK + '</div>');        
      }
      else {
        docprint.document.write('<div class="mdatal">' + this.ObjOrderMaster.Address + '</div>');
      }
      
      docprint.document.write('</div>');
    }
    
    docprint.document.write('<div style="display:flex"> <br />');
    docprint.document.write('<div class="mdatal">Order No. :&nbsp;</div>');
    docprint.document.write('<div class="mdatad"> ' + this.ObjOrderMaster.OrderInvoiceNo + '</div>');
    docprint.document.write('<div class="mrgn mdatal">Date :&nbsp;</div>');
    docprint.document.write('<div class="mdatad"> ' + this.ObjOrderMaster.OrderDate + '</div>');
    docprint.document.write('</div>');


    docprint.document.write('</div>');

    docprint.document.write('<div id="bot">');
    docprint.document.write('<div id="table">');
    docprint.document.write('<table>');
    docprint.document.write('<thead>');
    docprint.document.write('<tr>');
    docprint.document.write('<th>Description</th>');


    docprint.document.write('<div id="bot">');
    docprint.document.write('<th class="qty">Qty</th>');
    docprint.document.write('<th>Amount</th>');
    docprint.document.write('</tr>');
    docprint.document.write('</thead>');
    docprint.document.write('<tbody>');
    docprint.document.write('<tr>');
    for (var i = 0; i < this.ArrOrderDetail.length; i++) {
      if (this.ArrOrderDetail[i].AddOnID == '0') {
        docprint.document.write('<td>' + this.ArrOrderDetail[i].ProductName +'</td>');
      } else {
        docprint.document.write('<td style="text-indent: 10px">' + this.ArrOrderDetail[i].AddOnName + '</td>');
      }
      
      docprint.document.write('<td class="qty">' + this.ArrOrderDetail[i].Quantity + ' </td>');
      docprint.document.write('<td>' + this.ArrOrderDetail[i].Amount + ' </td>');
    }
    docprint.document.write('</tr>');
    docprint.document.write('</tbody>');
    docprint.document.write('<tfoot>');
    docprint.document.write('<tr>');
    docprint.document.write('<td colspan="1">Sub Total</td>');
    docprint.document.write('<td>' + this.CurrencyCode + ' </td>');
    docprint.document.write('<td>' + this.ObjOrderMaster.TotalAmount + ' </td>');
    docprint.document.write('</tr>');

    docprint.document.write('<tr>');
    docprint.document.write('<td colspan="1">Discount</td>');
    docprint.document.write('<td>' + this.CurrencyCode + ' </td>');
    docprint.document.write('<td>' + this.ObjOrderMaster.TotalDiscount + ' </td>');
    docprint.document.write('</tr>');

    docprint.document.write('<tr>');
    docprint.document.write('<td colspan="1">Tax</td>');
    docprint.document.write('<td>' + this.CurrencyCode + ' </td>');
    docprint.document.write('<td>' + this.ObjOrderMaster.TotalTaxAmount + ' </td>');
    docprint.document.write('</tr>');

    if (this.ObjOrderMaster.OrderType == 'Delivery') {
      docprint.document.write('<tr>');
      docprint.document.write('<td colspan="1">Delivery Fee</td>');
      docprint.document.write('<td>' + this.CurrencyCode + ' </td>');
      docprint.document.write('<td>' + this.ObjOrderMaster.DeliveryFee + ' </td>');
      docprint.document.write('</tr>');
    }


    docprint.document.write('<tr>');
    docprint.document.write('<td colspan="1">Net Amount</td>');
    docprint.document.write('<td>' + this.CurrencyCode + ' </td>');
    docprint.document.write('<td>' + this.ObjOrderMaster.NetAmount + ' </td>');
    docprint.document.write('</tr>');

    docprint.document.write(' </tfoot>');
    docprint.document.write('</table>');
    docprint.document.write('</div>');
    docprint.document.write('<div id="legalcopy"><p class="legal"><strong>Thank you for shopping! come again</strong><br /></p>');
    docprint.document.write('</div>');
    docprint.document.write('</div>');
    docprint.document.write('</div>');












    docprint.document.write('<p style="text-align: center;font-size: 10px;">Powered by Global Informix</p>');
    docprint.document.write('</div>');
    docprint.document.write('</body>');
    docprint.document.close();
    docprint.print();
    docprint.close();

    return true;
  }



  isArrayHasValue(obj: any[]): boolean {
    let isArrayWithLength = Boolean(Array.isArray(obj) && obj.length);
    return isArrayWithLength;
  }

}

class Company {
  CompanyID: any;
  CompanyName: any;
  outletsCount: any;
  CountryID: any;
  hearbout: any;
  Mobile: any;
  CurrencyCode: any;
  Rate: any;
  NumOfDecimal: any;
  CountryCode: any;
  IsOTPRequired: boolean;
}

class OrderMaster {
  OrderID: any;
  OrderInvoiceNo: any = '';
  PrepIn: any = '';
  PrepInOutlet: any = '';
  OrderPerpStatus: any = 'ALL';
  Brand: any = '';
  OutletID: any = '';
  OutletId: any = '';
  OutletName: any = '';
  AreaName: any = '';
  outletAddress: any = '';
  OrderType: any = '';
  Address: any = '';
  CustomerID: any = '';
  CustomerName: any = '';
  CustomerEmail: any = '';
  CountryCode: any = '';
  PhoneNo: any = '';
  PayType: any = '';
  Channel: any = '';
  PickupFrom: any = '';
  Remarks: any = '';
  SubTotal: any = '';
  TotalTaxAmount: any = '';
  TotalAmount: any = '';
  DeliveryFee: any = '';
  NetAmount: any = '';
  TotalDiscount: any = '';
  TaxID: any = '';
  TaxDescription: any = '';
  TaxPercent: any = '';
  OrderDate: any;
  OrderDateReal: any;
  OrderTime: any;
  CreatedOn: any;
  CreatedBy: any;
  ModifyOn: any;
  ModifyBy: any;
  RejectReasonID: any;
  RejectReason: any;
  RiderID: any;
  CompanyId: any;
  RejectReasonDetail: any = '';
  AddressPK: any = '';
  Cashier: any = '';
  OutletPhone: any = '';
  TableNumber: any;
  AdditionalDirection: any = '';
  PromoCode: any;
  PromoAmount: any;
  LoyaltyAmount: any;
  OrderNo : any;
  IsExtraCharge: any;
  ExtraChargeAmount: any;
  IsQRVisible : boolean = true;
  QRRedirection  : any;
  QRRedirectionURL: any;
  TIPAmount: any;
  OrderPayStatus: any;
  IsMobNoVisibleReceipt: boolean;
  ReceiptMsg: any;
  ImagePath: any;
  NoOfCustomers: any;
}

class OrderDetail {
  OrderDetailID: any;
  OrderID: any;
  ProductId: any;
  ProductName: any = '';
  Quantity: any;
  UnitID: any;
  AddOnID: any;
  AddOnName: any;
  Amount: any;
  ProductDetailID: any;
  ProductSizeName: any = '';
  Description: any = '';
  OrignalAmount: any;
}

class Country {
  CurrencyCode: any = '';
  Rate: any = '';
  NumOfDecimal: any = '';
  CountryName: any = '';
  CountryCode: any = '';
}

class CompanyInfo {
  ID: any;
  TextColor: any = '';
  BaseColor: any = '';
  Title: any = '';
  Message: any = '';
  MainImagePath: any = '';
  MainImageName: any = '';
  IconImagePath: any = '';
  IconImageName: any = '';
  LogoImagePath: any = '';
  LogoImageName: any = '';
  BrandName: any = '';
  VATNo: any = '';
  ReceiptMsg: any = '';
}
class VoucherData {
  RowID: any;
  OrderId: any;
  VoucherCode: any;
  VoucherId: any;
  IsPercent: any;
  VoucherAmount: any;
  CompanyId: any;
}

class PaymentDetails {
  Description: any;
  NetAmount: any;
}
