<!--<mat-horizontal-stepper linear #stepper>
    <mat-step [stepControl]="firstFormGroup" [editable]="isEditable">
      <form [formGroup]="firstFormGroup">
        <ng-template matStepLabel>Fill out your name</ng-template>
        <mat-form-field>
          <mat-label>Name</mat-label>
          <input matInput formControlName="firstCtrl" placeholder="Last name, First name" required>
        </mat-form-field>
        <div>
          <button mat-button matStepperNext>Next</button>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="secondFormGroup" [editable]="isEditable">
      <form [formGroup]="secondFormGroup">
        <ng-template matStepLabel>Fill out your address</ng-template>
        <mat-form-field>
          <mat-label>Address</mat-label>
          <input matInput formControlName="secondCtrl" placeholder="Ex. 1 Main St, New York, NY"
                 required>
        </mat-form-field>
        <div>
          <button mat-button matStepperPrevious>Back</button>
          <button mat-button matStepperNext>Next</button>
        </div>
      </form>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>Done</ng-template>
      <p>You are now done.</p>
      <div>
        <button mat-button matStepperPrevious>Back</button>
        <button mat-button (click)="stepper.reset()">Reset</button>
      </div>
    </mat-step>
  </mat-horizontal-stepper>-->

  
    <div class="row" style="float: right;margin-top: -12px;margin-right: -12px;" (click)="btnclose()">
      <img src="./assets/images/lookupclose.png" style="cursor: pointer;">
    </div>
    <!-- <hr style="margin-top: 10px;"/> -->

<div *ngIf="showPopupIsDayShiftClosed" >
  <!-- <h1  style="text-align: center;font-size: 30px;">{{ showPopupIsDayShiftOpen ? PleaseOpenshiftforselectedBranch  : 'Kitchen Display Under Process.' }}</h1> -->
  <h1 style="text-align: center;font-size: 30px;">{{PleaseOpenshiftforselectedBranch}}</h1>
</div>

<div *ngIf="showPopupKitchenDisplayUnderProcess" >
  <h1 style="text-align: center;font-size: 30px;">Kitchen Display Under Process.</h1>
</div>

<div *ngIf="showPopupIsSessionClosed" >
  <h1 style="text-align: center;font-size: 30px;">{{SessionClosed}}</h1>
</div>

<div *ngIf="showPopupHoldOrder">
  <h1 style="text-align: center;font-size: 30px;">{{OrderHold}}</h1>
</div>
<div *ngIf="IsAccessCashierScreen">
  <h1 style="text-align: center;font-size: 30px;">{{IsAccessCashierScreenmsg}}</h1>
</div>

<div *ngIf="InvalidCompany">
  <h1 style="text-align: center;font-size: 30px;">{{msgInvalidCompany}}</h1>
</div>
<div *ngIf="InvalidPIN">
  <h1 style="text-align: center;font-size: 30px;">{{msgInvalidPIN}}</h1>
</div>
<div *ngIf="InvalidCounter">
  <h1 style="text-align: center;font-size: 30px;">{{msgInvalidCounter}}</h1>
</div>
<div *ngIf="EnterPOSPin">
  <h1 style="text-align: center;font-size: 30px;">{{msgPOSPin}}</h1>
</div>
<div *ngIf="showECRPaymentPopup">

  <div *ngIf="showsuccessimg !== true" class="center-container">
    <img src="./assets/images/error-img.gif" alt="GIF Image" width="60%" height="60%">
  </div>
  <div *ngIf="showsuccessimg" class="center-container">
    <img src="./assets/images/success.gif" alt="GIF Image" width="60%" height="60%" />
  </div>
  <h1 style="text-align: center;font-size: 30px;">{{status}}</h1>
  </div>
