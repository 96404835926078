import { Component, OnInit, ViewChild, Input, Output, EventEmitter, Inject } from '@angular/core';
//import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { MatDialog } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Observable } from 'rxjs/Rx';
import { isNullOrUndefined, isNumber } from 'util';
import { IUser } from './EmployeeFields';
import { DataserviseService } from '../dataservice';
@Component({
    templateUrl: '../Lookup/lookupinformation.component.html',
    styleUrls: ['./lookupinformation.component.css'],
})

export class ManageLookup implements OnInit {
    simpleItems: any[] = [5, 10, 20, 50, 100, 500];
    rt: any = this.simpleItems[2];
    selectedDdlValue: any;
    selectedRow: Number; p: number = 1;
    selectedHead: Number;
    filter: any;
    isREADONLY: boolean = false; headingName: boolean = false; defaultHeadingName: boolean = true;
    pdata: any[]; dateFilter: any[]; auditFilter: any[];
    listFilter: string;
    highlight: string;
    filtervalue: any;
    result: any;
    users: IUser[];
   // Customizelabels: Customizelabel[];
    ishide: boolean = false;
    sorting: any = {
        column: 'NameOnly',
        descending: false,
    };

    objectKeys(obj) {
        return Object.keys(obj);
    }
    generateArray(obj) {
        return Object.keys(obj).map((key) => { return obj[key] });
    }
    constructor(private _userService: DataserviseService, public dialogRef: MatDialogRef<ManageLookup>, @Inject(MAT_DIALOG_DATA) public data: any) {
   
        this.pdata = [];
        this.users = [];
        if (this.selectedDdlValue == undefined) {
            this.selectedDdlValue = 0;
        }
        if (this.listFilter == undefined) {
            this.listFilter = "";
        }

        this.LoadUsers();
        //this.loadLabels();
        if (data.headingName != undefined && data.headingName != '' && !isNullOrUndefined(data.headingName)) {
            this.headingName = true;
            this.defaultHeadingName = false;
        }
        else {
            this.headingName = false;
            this.defaultHeadingName = true;
        }
    }
  ngOnInit(): void {
    }
    //HideColumns(columnName) {
    //    if (this.data.hideColumns) {
    //        return this.data.hideColumns.includes(columnName);
    //    } else {
    //        return false;
    //    }
    //}
    HideColumns(columnName) {
      if (this.data.hideColumns) {
        let HideColumns = this.data.hideColumns.toLowerCase().trim().split(',');
        if (HideColumns.includes(columnName.toLowerCase().trim()))
          return true;
        else
          false;
      }
      else {
        return false;
      }
    }

    auditField(columnName) {
        if (this.data.AuditField) {
            this.auditFilter = this.objectKeys(this.data.AuditField);
            var result = this.auditFilter.some(item => {
                return item == columnName;
            })
            if (result) {
                return true;
            }
        } else {
            return false;
        }
    }
    //checkStatus() {
    //  debugger;
    //  if (this.data.Status) {
    //    if (this.data.Status == "0" || this.data.Status == "2")
    //      return true;
    //    else
    //      return false;
    //  }
    //  return false;
    //}
    dateTimeField(columnName) {
        if (this.data.DateTimeField) {
            this.dateFilter = this.objectKeys(this.data.DateTimeField);
            var result = this.dateFilter.some(item => {
                return item == columnName;
            })
            if (result) {
                // this.pdata = this.generateArray(this.pdata);
                return true;
            }
        }
        else {
            return false;
        }
    }

    LoadUsers(): void {

      this._userService.post(this.data.url, this.data.whereClause)
        .subscribe(users => {
          this.pdata = users.Table;
          this.users = users.Table;
          this.sorting.column = this.data.sortingColumn;
          this.sorting.descending = false;
        }, error => alert('error')
            );
    }
    eventHandler(event) 
    {
        debugger
        if (event.keyCode == 13)
            this.SearchUsers(event);
    }
    objParam: any = {
        search: this.listFilter,
        fieldName: this.selectedDdlValue,
        whereClauseSearchBar: this.data.whereClause,
        hideColumn: this.data.hideColumns
    }
    SearchUsers(e: any) {

        debugger
        let symbol = this.listFilter.includes('%');
        if (symbol == true) {
            this.listFilter = "";
            return;
        }
        this.listFilter = this.listFilter.trim();
        this.objParam.search = this.listFilter;
        this.objParam.fieldName = this.selectedDdlValue;
        this.objParam.hideColumn = this.data.hideColumns;

      this._userService.post(this.data.urlSearch, this.objParam)
        .subscribe(users => {
          this.pdata = users.Table;
          this.users = users.Table;
        }, error => alert('error')
            );
    }

    //loadLabels(): void {
    //    this._userService.get('api/LabelsFetching/LabelsFetching?CompanyId=&Culture=en-GB&code=&formid=Lookup')
    //        .subscribe(labelsFetch => {
    //            this.Customizelabels = labelsFetch;
                
    //        }, error => alert('error')
    //        );
    //}

    selectedClass(columnName: string): any {
        return columnName == this.sorting.column ? 'sorting-' + this.sorting.descending : false;
    }


    isDateColumn(colName): boolean {
        let match = false;
        for (var i in this.data.DateTimeField) {
            if (i == colName) {
                match = true;
                break;
            }
        }
        return match;
    }


    click(btn: any, row: any, index: any): void {
        // console.log(index);
        if (row != null) {
            this.selectedRow = index;
            this.dialogRef.close(row)
        }
    }
}
