import { Injectable } from '@angular/core';

@Injectable()
export class LocalStorageService {
  constructor() { }

  set(key: string, data: any): void {
    try {
      sessionStorage.setItem(key, JSON.stringify(data));
    } catch (e) {
      console.error('Error saving to sessionStorage', e);
    }
  }

  get(key: string) {
    try {
      return JSON.parse(sessionStorage.getItem(key));
    } catch (e) {
      console.error('Error getting data from sessionStorage', e);
      return null;
    }
  }

  delete(key: string) {
    try {
      sessionStorage.removeItem(key);
    } catch (e) {
      console.error('Error deleteing data from sessionStorage', e);
      return null;
    }
  }

  cleareAllLocalStorage() {
    try {
      sessionStorage.clear(); 
    } catch (e) {
      console.error('Error clearing data from sessionStorage', e);
      return null;
    }
  }
}
