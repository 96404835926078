<p class="font-semibold" style="font-family: inherit;text-align: center;border-bottom: 1px solid lightgray;
background: lightgray;">{{lstSelectedProduct.ProductName}}</p>

<div>
<div class="pt-4 pb-2 border-b-10 border-black-100" style="padding-top: 0px !important;" *ngIf="ArrProductSize?.length > 0 && ArrProductSize[0].RowsCount > 1">
  <div class="flex px-4">
    <div class="w-full pb-2">
      <p class="font-semibold" style="font-family: inherit">Item Sizes</p>
      <!-- <small class="text-black-500" >
        Choose 1
      </small> -->
    </div>
  </div>
  <div  class="row">
    <div *ngFor="let psize of ArrProductSize; let iii = index" class="radio-selector col-md-4">
      <input id="psize{{iii}}" [checked]="(lstSelectedProduct.ProductDetailID === psize.ProductDetailID)" (change)="rdProductSizeSelected(psize, $event)" type="radio" name="rd77{{psize.ProductID}}" class="radio-selector__input" [value]="psize.ProductDetailID">
      <label for="psize{{iii}}" class="radio-selector__box">
        <div class="w-10">
          <span class="radio-selector__radio" [ngStyle]="{'border-color': ObjResturentInfo[0].BaseColor}"> </span>
        </div>
        <div class="radio-selector__modifier">
          <div class="radio-selector__modifier-title" style="width:55%">
            {{psize.Description}}
          </div>
          <small class="radio-selector__price" [ngClass]="(psize.DiscountPrice  > 0 && psize.DiscountPrice != undefined) ? 'lblDiscount' : 'lblOriginal'">{{this.ObjCompany[0].CurrencyCode}}&nbsp;{{psize.Price| number:'1.'+ObjCompany[0].NumOfDecimal +'-'+ObjCompany[0].NumOfDecimal+''}}</small>
          <small class="lblOriginal" *ngIf="lstSelectedProduct.DiscountPrice > 0">{{this.ObjCompany[0].CurrencyCode}}&nbsp;{{psize.DiscountPrice| number:'1.'+ObjCompany[0].NumOfDecimal +'-'+ObjCompany[0].NumOfDecimal+''}}</small>
        </div>
      </label>
    </div>
  </div>
</div>
<div class="pt-4 pb-2 border-b-10 border-black-100" *ngFor="let name of lstData; let i = index">
  <div class="flex px-4">
    <div class="w-full pb-2" [id]="name.ListId">
      <p id="category-252426345471152128" class="font-semibold" style="font-family: inherit">
        {{name.ListName}}
      </p>
      <small class="text-black-500" *ngIf="name.InputType == 'rd'">
        Choose 1
      </small>
    </div>
    <div class="pl-3" *ngIf="name.InputType == 'rd'">
      <span class="required-label" id="dv{{name.MasterId}}" [class]="name.class">
        Required
      </span>
    </div>
  </div>

  <div class="row">
  <div class="col-md-4"  *ngFor="let lst of lstModifier; let ii = index" [ngStyle]="{'display': (lst.MasterId == name.MasterId) ? 'block' : 'none'}">
    <div *ngIf="lst.MasterId == name.MasterId" >
      <div class="radio-selector" *ngIf="lst.MaxModifiers == 1">
        <input id="rd{{ii}}" (change)="rdModifierSelected(lst, $event)" type="radio" name="rd{{name.MasterId}}" class="radio-selector__input" [value]="lst.MasterId">
        <label for="rd{{ii}}" class="radio-selector__box" style="margin-bottom: 0px;">
          <div class="w-10">
            <span class="radio-selector__radio" [ngStyle]="{'border-color': ObjResturentInfo[0].BaseColor}">
            </span>
          </div>
          <div>
            <div class="radio-selector__modifier-title" style="font-size: 0.8rem !important;">
              {{lst.Modifires}} {{lst.AName}}
            </div>
            <small class="radio-selector__price" *ngIf="lst.Price > 0">+&nbsp;{{this.ObjCompany[0].CurrencyCode}}&nbsp;{{lst.Price| number:'1.'+ObjCompany[0].NumOfDecimal}}</small>
          </div>
        </label>
      </div>

      <div class="checkbox-selector" *ngIf="lst.MaxModifiers > 1" >


        <input id="chk{{ii}}" (change)="chkModifierSelected(lst, $event)" type="checkbox" name="chk{{name.MasterId}}" class="checkbox-selector__input" [value]="lst.MasterId">
        <label for="chk{{ii}}" class="checkbox-selector__box">
          <div class="w-10">
            <span class="checkbox-selector__check" [ngStyle]="{'border-color': ObjResturentInfo[0].BaseColor}">
            </span>
          </div>
          <div class="checkbox-selector__modifier" style="font-size: 0.8rem !important;">
            <div class="checkbox-selector__modifier-title">
              <span *ngIf="cultures != 'ar'">{{lst.Modifires}}</span> 
              <span *ngIf="cultures == 'ar'">{{lst.AName}}</span> 
            </div>
            <small *ngIf="lst.Price" class="checkbox-selector__price">+&nbsp;{{this.ObjCompany[0].CurrencyCode}}&nbsp;{{lst.Price| number:'1.'+ObjCompany[0].NumOfDecimal}}</small>
          </div>
        </label>
      </div>

    </div>
  </div>
</div>

</div>
</div>

<div class="page-basket__ctas" style="cursor:pointer">
  <div class="link-button" [ngStyle]="{'background-color': ObjResturentInfo[0].BaseColor,'color':ObjResturentInfo[0].TextColor}">
    <a class="link-button__el link-button__el--rg" (click)="AddToBasket(lstSelectedProduct)" [ngStyle]="{'background-color': ObjResturentInfo[0].BaseColor,'color':ObjResturentInfo[0].TextColor}">
      {{AddtoBasket}}
      <small class="absolute inset-y-0 right-0 flex items-center pr-4 text-xs leading-normal font-semibold">{{this.ObjCompany[0].CurrencyCode}}&nbsp; {{this.data.lstSelectedProduct.Price| number:'1.'+ObjCompany[0].NumOfDecimal}}</small>
    </a>
  </div>
</div>
