import { Component, OnInit, Inject} from '@angular/core';
import { DataserviseService } from '../../../shared/dataservice';
import { PopupService } from '../../../shared/popup';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NbToastrService } from '@nebular/theme';
import { SpinnerService } from '../../../shared/spinner';
import { TranslatePipe } from '../../../shared/pipe/translate.pipe';
import { UtilitiesService } from '../../../shared/utilities.service';
import { MatDialog } from '@angular/material/dialog';
import { CashDiscountPopupComponent } from '../cashdiscount-popup/cashdiscount-popup.component';

@Component({
  selector: 'ngx-voiditem-popup',
  templateUrl: './voiditem-popup.component.html',
  styleUrls: ['./voiditem-popup.component.scss']
})
export class VoiditemPopupComponent implements OnInit {
  // btnText: any = '';
  RejectReason:any='';

  Pleaseselectareason : any = this.translatePipe.transform('Pleaseselectareason');
  Description : any = this.translatePipe.transform('Description');
  Close : any = this.translatePipe.transform('Close');
  Void : any = this.translatePipe.transform('Void');
  CharachtersRemaining: any = this.translatePipe.transform('CharachtersRemaining');
  lblOrignalQty: any = this.translatePipe.transform('OrignalQty');
  lblVoidQuantity: any = this.translatePipe.transform('VoidQuantity');
  lblVoidItem: any = this.translatePipe.transform('VoidItem');
  VoidQtyshouldbeless: any = this.translatePipe.transform('VoidQtyshouldbeless');
  OrignalQty: any = 0;
  VoidQty: any;
  ItemName: any;
  ItemIndex: any;

  constructor(public dialog: MatDialog, private Utilities: UtilitiesService, private dataservice: DataserviseService, private popup: PopupService, private toastrService: NbToastrService, public dialogRef: MatDialogRef<VoiditemPopupComponent>, @Inject(MAT_DIALOG_DATA) public data: any,private _spinnerService: SpinnerService,private translatePipe : TranslatePipe) {
    this.OrignalQty = this.data.Oqty;
    this.VoidQty = this.OrignalQty;
    this.ItemName = this.data.sizeProductName;
    this.ItemIndex = this.data.ItemIndex
  }
  ngOnInit(): void {debugger
    this.GetDropdownSetups();

    // if (this.ObjOrderMaster.OrderPerpStatus == 'REJECTED') {
    //   this.btnText = this.translatePipe.transform('Reject');
    // }
    // else{
    //   this.btnText = this.translatePipe.transform('Cancel');
    // }
    // this.ObjOrderMaster.RejectReasonDetail = '';

  }
  ClosePopup(): void {
    this.dialogRef.close(undefined);
  }

  ddlRejectReasons: any = [];
  RejectReasonID: any;

  GetDropdownSetups(): void {

    this.dataservice.get('LiveOrder/GetSetupData/' + 'G')
      .subscribe(data => {

        if (this.isArrayHasValue(data[0]['RejectReasons'])) {
          this.ddlRejectReasons = data[0]['RejectReasons'];
          this.RejectReasonID = this.ddlRejectReasons[0].RejectReasonID;
        }
      }, error => alert('error'));

  }

  // setAuditFields(): void {
  //   this.ObjOrderMaster.CreatedBy = this.Utilities.getUserId();
  //   this.ObjOrderMaster.CreatedOn = this.Utilities.GetCurrentDateTime();
  //   this.ObjOrderMaster.ModifyBy = this.Utilities.getUserId();
  //   this.ObjOrderMaster.ModifyOn = this.Utilities.GetCurrentDateTime();
  //   this.ObjOrderMaster.CompanyId = this.Utilities.getSubscriptionID();
  // }

  maxChars: number = 500;

  isArrayHasValue(obj: any[]): boolean {
    let isArrayWithLength = Boolean(Array.isArray(obj) && obj.length);
    return isArrayWithLength;
  }
 
  VoidItem() {
    let isvalid: boolean;

    if (this.OrignalQty != this.VoidQty)
      isvalid = true;
    else
      isvalid = false;

    let childData = [{ 'RejectReasonID': this.RejectReasonID, 'RejectReason': this.RejectReason, 'VoidQty': this.VoidQty, IsValid: isvalid, 'ItemIndex': this.ItemIndex}]
    this.dialogRef.close(childData);

  }

  qtyValidation: boolean = false;

  CalcPopup() {
    if (this.VoidQty == 1)
      return;

    this.qtyValidation = false;

    const dialogRef = this.dialog.open(CashDiscountPopupComponent, {
      width: '430px',
      height: '450px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      debugger


      if (result != undefined) {
        let qty = parseInt(result);

        if (qty > this.OrignalQty) {
          this.qtyValidation = true;
        } else {
          this.VoidQty = qty;
        }
      }
   

    });
  }
}
