

<nb-card>

  <nb-card-body>
    <div class="row parent1">
      <div class="col-md-2 ">
        <div class="child1">
          {{PaidOrders}}
        </div>
      </div>
      <div class="col-md-8"></div>
      <div class="col-md-2">
        <img src="./assets/images/lookupclose.png" (click)="dialogRef.close()" style="float: right; width: 25px;cursor: pointer;">

      </div>
    </div>
    <hr />
    <div class="row">
      <div class="col-md-2 col-sm-6">
        <div class="form-group">
          <label class="label">{{OrderNo}}</label>
          <input nbInput fullWidth name="Id" [(ngModel)]="ObjSearchFilters.Id">

        </div>
      </div>
      <div class="col-md-2 col-sm-6">
        <div class="form-group">
          <label class="label">{{PhoneNo}}</label>
          <input nbInput fullWidth name="Id" [(ngModel)]="ObjSearchFilters.PhoneNo">

        </div>
      </div>

      <div class="col-md-2 col-sm-6">
        <div class="form-group">
          <label class="label">{{FromDate}}</label>
          <input nbInput fullWidth [(ngModel)]="_DateFrom" [nbDatepicker]="fromDate" name="ifromDate" autocomplete="off">
          <nb-datepicker #fromDate></nb-datepicker>
          <p class="caption status-danger" *ngIf="InvalidDateFrom">{{Invaliddate}}!</p>
        </div>
      </div>
      <div class="col-md-2 col-sm-6">
        <div class="form-group">
          <label class="label">{{ToDate1}}</label>
          <input nbInput fullWidth [(ngModel)]="_DateTo" [nbDatepicker]="ToDate" name="iToDate" autocomplete="off">
          <nb-datepicker #ToDate></nb-datepicker>
          <p class="caption status-danger" *ngIf="InvalidDateTo">{{Invaliddate}}!</p>
        </div>
      </div>

      <div class="col-md-2 col-sm-6">
        <div class="form-group">
          <label class="label">&nbsp;</label>
          <button class="btn btnfinish" (click)="getGridData()">{{Search}}</button>
        </div>
      </div>

    </div>
    <div class="row">
      <div class="col-md-12 col-sm-12">
        <div class="form-group">
          <div class="child1">{{PaidOrderslist}}</div>
          <div class="table-responsive" style="width:95%;max-height:475px">

            <ng2-smart-table [settings]="settings" (userRowSelect)="onUserRowSelect($event)" [source]="ArrPaidOrders"></ng2-smart-table>

          </div>
          <br />
        </div>
      </div>
    </div>
  </nb-card-body>
</nb-card>
