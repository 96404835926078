<!-- <div class="row">
  <div class="col-md-8 col-sm-8 col-xs-6 text-right"> -->
    <img src="./assets/images/lookupclose.png" (click)="dialogRef.close()" class="cross-icon" style="float: right; margin-top: 16px;margin-right: 11px;" />
  <!-- </div>
</div> -->
<div class="Lookup">
  
  <div class="row">
    <div class="col-lg-12">
      <div *ngIf='defaultHeadingName' class="col-md-4 col-sm-4 col-xs-6">Lookup</div>
      <div *ngIf='headingName' class="col-md-4 col-sm-4 col-xs-6 " style="color: #05a9e6; font-weight: 500;">{{data.headingName}}</div>
     
    </div>
  </div>
</div>
<div class="popupContainer">

  <div class="row">

    <div class="col-md-3 lookupMobileView">
      <label class="label" >Search</label>
      <nb-select  fullWidth class="DropdownClassic" [(ngModel)]="selectedDdlValue"  [(selected)]="selectedDdlValue" tabindex="-1"> 
        <nb-option *ngFor="let _ddlValue of data.columnName | keys" [value]="_ddlValue == 'Id' ? 0 : _ddlValue">{{ (_ddlValue == 'Id') ? 'All' : data.columnName[_ddlValue]}}</nb-option>
      </nb-select>
    </div> 

    <div class="col-md-4">

      <label class="lookupMobileView label" >Search for</label>
      <div>

        <input nbInput fullWidth type="search" style=" height: 40px;" (keypress)="eventHandler($event)" class="form-controlSearch txt-lookup" tabindex="0" placeholder="Enter Search Text" [(ngModel)]="listFilter" />
      </div>

    </div>

    <div class="col-md-1 btn-lookup-search-txt"><button  nbButton (click)="SearchUsers($event)" class="btnSearch lookupMobileView">Search</button></div>
    <div class="col-md-4 text-right pull-right lookupMobileView" style="width: 22%;">
      <div style="width: 100px; float: right">
        <label class="label">RecordPerPage</label>
        <span class="custom-dropdown small" style="width: 91px;">
          <nb-select  fullWidth [(ngModel)]="rt" [(selected)]="rt">
            <nb-option *ngFor="let i of simpleItems" [value]="i">{{i | json}}</nb-option>
          </nb-select>
        </span>
      </div>
    </div>



  </div>
  <br />

  <div class="row">
    <div class="col-md-12 lookup-panel">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 look-height scrollbarLookup" id="lookuppanel">

        <table class="LookupGrid table table-responsive" *ngIf='users' cellspacing="0">
          <thead>
            <tr>

              <th style="border-right-color: transparent; border-bottom: 1px solid #D9E0F2;"></th>

              <th *ngFor="let column of data.columnName | keys" style="cursor:pointer;border-bottom: 1px solid #D9E0F2;"
                  [ngClass]="{'auditDetail' : auditField(column) == true}"
                  [hidden]="HideColumns(column)">
                {{ data.columnName[column]}}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="rows" *ngFor="let row of pdata;let i = index" (click)="click(btn,row,i)" [class.active]="i == selectedRow" >
            
              <td style="border-right-color: transparent;">
                <div class="roundcheckbox" style="margin-top: 2px;">
                  <input type="checkbox" [id]="'checkbox'+i" />
                  <label [for]="'checkbox'+i" style="background-color:#ccc"></label>
                </div>
              </td>
              <td *ngFor="let key of row | keys" [highlight]="selectedDdlValue == 0 ? listFilter : undefined" style="cursor:pointer;" [hidden]="HideColumns(key)"
                  >
                {{row[key]}}
              </td>
          

            </tr>


          </tbody>


        </table>

        <div *ngIf='users && users.length==0' style="text-align:center; color:#6C82C6;font-size:12px;font-family: Barlow !important;">No data</div>
      </div>

    </div>
  </div>

  <!--PAGINATION-->
  <div class="row" align="center">

    <!--<pagination-controls (pageChange)="p = $event"></pagination-controls>-->
  </div>


</div>

