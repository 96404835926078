

<nb-card>

  <nb-card-body>
    <div class="row parent1">
      <div class="col-md-6 ">
        <div class="child1">
         {{PaidOrders}}
        </div>
      </div>
      <div class="col-md-4"></div>
      <div class="col-md-2">
        <img src="./assets/images/lookupclose.png" (click)="dialogRef.close()" style="float: right; width: 25px;cursor: pointer;">
       
      </div>
    </div>
    <hr />
    <div class="row">
      <div class="col-md-2 col-sm-6">
        <div class="form-group">
          <label class="label">{{OrderNo}}</label>
          <input nbInput fullWidth name="Id" [(ngModel)]="ObjSearchFilters.Id">

        </div>
      </div>
      <div class="col-md-2 col-sm-6">
        <div class="form-group">
          <label class="label">{{PhoneNo}}</label>
          <input nbInput fullWidth name="Id" [(ngModel)]="ObjSearchFilters.PhoneNo">

        </div>
      </div>

      <div class="col-md-2 col-sm-6">
        <div class="form-group">
          <label class="label">{{FromDate}}</label>
          <input nbInput fullWidth [(ngModel)]="_DateFrom" [nbDatepicker]="fromDate" name="ifromDate" autocomplete="off">
          <nb-datepicker #fromDate></nb-datepicker>
          <p class="caption status-danger" *ngIf="InvalidDateFrom">Invalid date!</p>
        </div>
      </div>
      <div class="col-md-2 col-sm-6">
        <div class="form-group">
          <label class="label">{{ToDate1}}</label>
          <input nbInput fullWidth [(ngModel)]="_DateTo" [nbDatepicker]="ToDate" name="iToDate" autocomplete="off">
          <nb-datepicker #ToDate></nb-datepicker>
          <p class="caption status-danger" *ngIf="InvalidDateTo">Invalid date!</p>
        </div>
      </div>
      <div class="col-md-2 col-sm-6">
        <div class="form-group">
          <label class="label">{{OrderStatus}}</label>
        <nb-select [(selected)]="ObjSearchFilters.status" style="max-width: 100%;width: 100%;" [(ngModel)]="ObjSearchFilters.status">
          <nb-option value="0">All</nb-option>
          <nb-option value="Paid">Delivered</nb-option>
          <nb-option value="Cancel">Cancel</nb-option>
        </nb-select>
    </div>
  </div>
      <div class="col-md-2 col-sm-6">
        <div class="form-group">
          <label class="label">&nbsp;</label>
          <button class="btn btnfinish" (click)="getGridData()">{{Search}}</button>
        </div>
      </div>


    </div>
    <div class="row">
      <!--<div class="col-md-6 col-sm-6">
        <div class="form-group">
            <h4>Order Detail</h4>
            <div class="table-responsive" style="height:556px;overflow:auto;">
                <table class="table table-bordered table-striped">
                    <thead>
                        <tr>
                            <th class="th">Qty</th>
                            <th class="th">Product Name</th>
                            <th class="th">Total</th>
                        </tr>
                    </thead>
                    <tbody>

                        <tr *ngFor="let data of ArrPaidOrdersDetail">
                            <td class="td">{{data.Quantity}}</td>
                            <td class="td" *ngIf="data.AddOnID == '0'">{{data.ProductName}}</td>
                            <td class="td" style="text-indent: 10px;color:#969696" *ngIf="data.AddOnID != '0'">{{data.AddOnName}}</td>
                            <td class="td">{{data.Amount|number:'1.'+ObjCountry.NumOfDecimal+'-5'}}</td>

                        </tr>
                        <tr *ngIf="ArrPaidOrdersDetail?.length < 1">
                            <td colspan="10" style="text-align:center;font-size:12px;">No data</td>
                        </tr>
                    </tbody>
                </table>

            </div>
            <br />
        </div>

    </div>-->



      <div class="col-md-12 col-sm-12">
        <div class="form-group">
          <div class="child1">{{PaidOrderslist}}</div>
          <div class="table-responsive" style="width:100%;max-height:475px">
            
            <ng2-smart-table [settings]="settings" (userRowSelect)="onUserRowSelect($event)" [source]="ArrPaidOrders"></ng2-smart-table>

          </div>
          <br />
        </div>

      </div>




    </div>



  </nb-card-body>

</nb-card>
