import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA,MatDialog } from '@angular/material/dialog';
import { TranslatePipe } from '../../../shared/pipe/translate.pipe';

@Component({
  selector: 'ngx-noofcustomers-popup',
  templateUrl: './noofcustomers-popup.component.html',
  styleUrls: ['./noofcustomers-popup.component.scss']
})
export class NoofcustomersPopupComponent implements OnInit {
  txtbox_NoOfCustomers:any;
  TableName:any;
  eventValue:any;
  enterValue:boolean = false;
  OK : any = this.translate.transform('OK');
  Close : any = this.translate.transform('Close');
  Noofcustomers : any = this.translate.transform('Noofcustomers');

  constructor(public dialog: MatDialog, public dialogRef: MatDialogRef<NoofcustomersPopupComponent>, @Inject(MAT_DIALOG_DATA) public parentdata: any,private translate:TranslatePipe) 
{}

  ngOnInit(): void {debugger
    this.eventValue = this.parentdata.event;
    this.TableName = this.parentdata.event.TableName;
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  // numberOnly(event, value): boolean {debugger
  //   const charCode = (event.which) ? event.which : event.keyCode;
  //   var eventKey = event.key;
  //   if (event.which == 46 && value.indexOf('.') != -1) {
  //     event.preventDefault();
  //   } // prevent if already dot

  //   else if (charCode == 46) {
  //     return true;
  //   }

  //   else if ((charCode < 48 || charCode > 57)) {
  //     return false;
  //   }

  //   else { return true; }

  // }
  disableZero: boolean = true;

  calcButtonclick(value){debugger
    if(value == 'AC' || value == ""){
    this.txtbox_NoOfCustomers = 0;
    return
    }
    else {
      

      this.txtbox_NoOfCustomers = this.txtbox_NoOfCustomers == undefined || this.txtbox_NoOfCustomers == '0' ? value : this.txtbox_NoOfCustomers + value;
        this.txtbox_NoOfCustomers = this.txtbox_NoOfCustomers.substring(0, 5);
      this.disableZero = false;
       
    }
  }
  ClickDecimal(): void {debugger
    var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

    if (!format.test(this.txtbox_NoOfCustomers)) {
      this.txtbox_NoOfCustomers = this.txtbox_NoOfCustomers + '.';
    }

  }

  removeNumber() {
    debugger
    this.txtbox_NoOfCustomers = this.txtbox_NoOfCustomers.slice(0, -1);
    if (this.txtbox_NoOfCustomers == '')
      this.disableZero = true;
  }
  inputValueChange(value){
    debugger
    // value = parseFloat(value)
    if(value == 'AC' || value == ""){
    this.txtbox_NoOfCustomers = 0;
    return
    }
    else{
      this.disableZero = false;
    }

    if (this.txtbox_NoOfCustomers == '' && this.txtbox_NoOfCustomers == '0')
      return false;
  }

  close(){
    this.dialogRef.close();
  }
  ok(){debugger
    // if(this.txtbox_NoOfCustomers){
    // this.enterValue = false;
    this.eventValue.noOfCustomers = this.txtbox_NoOfCustomers;
    this.dialogRef.close(this.eventValue);
    // }
    // else{
      // this.enterValue = true;
      // return
    // }
  }

}
