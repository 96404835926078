import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataserviseService } from '../../shared/dataservice';
import { UtilitiesService } from '../../shared/utilities.service';
import { PopupService } from '../../shared/popup';
import { TranslatePipe } from '../../shared/pipe/translate.pipe';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LocalDataSource } from 'ng2-smart-table';
import { DeliveryPopupComponent } from '../delivery-popup/delivery-popup.component';
import {  ReceiptpopupComponent } from '../receiptpopup/receiptpopup.component';
import { SpinnerService } from '../../shared/spinner';


@Component({
  selector: 'ngx-customerreceiptvoucher',
  templateUrl: './customerreceiptvoucher.component.html',
  styleUrls: ['./customerreceiptvoucher.component.scss']
})
export class CustomerreceiptvoucherComponent implements OnInit {

  constructor(private translate: TranslatePipe, private UtilitiesService: UtilitiesService, private popup: PopupService, private dataservice: DataserviseService, public dialog: MatDialog, public dialogRef: MatDialogRef<CustomerreceiptvoucherComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public http: HttpClient,public _spinnerService:SpinnerService) { }

  CustomerReceiptVoucher: any = this.translate.transform('CustomerReceiptVoucher');
  ReceiptNo: any = this.translate.transform('ReceiptNo');
  CustomerName: any = this.translate.transform('CustomerName');
  ReceiptNote: any = this.translate.transform('ReceiptNote');
  ReceiptDate: any = this.translate.transform('ReceiptDate');
  TotalCreditSales: any = this.translate.transform('TotalCreditSales');
  PaidAmount: any = this.translate.transform('PaidAmount');
  OutstandingBalance: any = this.translate.transform('OutstandingBalance');
  AddPaymentDetail: any = this.translate.transform('AddPaymentDetail');
  ReceiptPayType: any = this.translate.transform('ReceiptPayType');
  PaymentMethod: any = this.translate.transform('PaymentMethod');
  PaymentAmount: any = this.translate.transform('PaymentAmount');
  Reference: any = this.translate.transform('Reference');
  btnAddPayments: any = this.translate.transform('btnAddPayments');
  Save: any = this.translate.transform('Save');

  gridData_AddPayments=[]
  source1: LocalDataSource;
  lstPaymentMode=[];

  PaymentMethod_selected:any;
  ReceiptPayType_selected:any;
  PaymentAmount_selected:any;
  Reference_selected:any;
  
  editRowIndex:any;

  ObjCustomerReceiptVoucher: CustomerReceiptVoucher = new CustomerReceiptVoucher();
  // ObjCustomerReceiptInfo: CustomerReceiptInfo = new CustomerReceiptInfo();

  ngOnInit(): void {debugger
    this.ObjCustomerReceiptVoucher.OutletID = this.data.OutletID;
    // if (sessionStorage.getItem('ObjCompany') != null && sessionStorage.getItem('ObjCompany') != undefined) {
    //   this.ObjCompany = JSON.parse(sessionStorage.getItem('ObjCompany'));
    // }
    // if (localStorage.getItem('SelectedBranchRow' + this.UtilitiesService.getURLCompanyName()) != null && localStorage.getItem('SelectedBranchRow' + this.UtilitiesService.getURLCompanyName()) != undefined) {
    //   this.SelectedBrancRow = JSON.parse(localStorage.getItem('SelectedBranchRow' + this.UtilitiesService.getURLCompanyName()));
    //   this.SelectedOutletID = this.SelectedBrancRow.ID;
    // }

    // if (localStorage.getItem('sessionObject' + this.UtilitiesService.getURLCompanyName()) != null && localStorage.getItem('sessionObject' + this.UtilitiesService.getURLCompanyName()) != undefined) {
    //   this.lstUser = JSON.parse(localStorage.getItem('sessionObject' + this.UtilitiesService.getURLCompanyName()));
    // }
    // this.cultures = this.data["culture"];
    // this.IsCancelOrderButtonActive = this.data["data"];

    // this.DayID = this.data["DayID"];

    this.get_paymentmodes();
    this.LoadGrid();
  }

  get_paymentmodes() {debugger
    this.dataservice.get('pos/GetPaymentModes/' + this.UtilitiesService.getSubscriptionID() + '/' + localStorage.getItem('LanguageCulture' + this.UtilitiesService.getURLCompanyName())).subscribe(data => {
      if (this.isArrayHasValue(data)) {
        this.lstPaymentMode = data;
        //  this.PaymentMethod_selected = this.lstPaymentMode[0].Code;
        // this.rdPaymentMethod = this.lstPaymentMode[0].PaymentName;
      }
    })
  }

  btnclose(): void {
    debugger
    this.dialogRef.close();
  }
  LoadGrid() {
    debugger;
    // this.lstarray = [];

    // if (this.SelectedOutletID != undefined && this.SelectedOutletID != null) {
    //   this.dataservice.get('pos/GetPendingOrders/' + this.UtilitiesService.getSubscriptionID() + '/' + this.SelectedOutletID + '/' + localStorage.getItem('LanguageCulture' + this.UtilitiesService.getURLCompanyName()) + '/' + this.DayID).subscribe(data => {

    //     debugger;
    //     if (this.isArrayHasValue(data[0]["Pendingprder"])) {
    //       this.lstarray = data[0]["Pendingprder"];
    //       console.log(this.lstarray);
    //       this.getMasterDetailData(data[0]['Pendingprder'][0]["OrderID"], 0);
    //     }
    //     if (this.isArrayHasValue(data[0]["printerserversetting"])) {
    //       this.lstPrinterserversetting = data[0]["printerserversetting"];
    //     }
    //   });
    // }
    // else {
    //   alert('Please Select Outlet first.');
    // }
  }

  isArrayHasValue(obj: any[]): boolean {
    let isArrayWithLength = Boolean(Array.isArray(obj) && obj.length);
    return isArrayWithLength;
  }

  SelectedReceiptId:any=0;
  openDialogReceiptPopUp() {debugger 
    let dialogRef = this.dialog.open(ReceiptpopupComponent, {
      width: '1150px',
      height: '600px',
      data: { outletID: this.data.OutletID}
    });

    dialogRef.afterClosed().subscribe(result => {debugger
      if (result != undefined && result[0] != undefined) {
        this.SelectedReceiptId = result[0]["ReceiptID"];
        this.SelectedCustId = 0;
        this.CustomerReceiptsInfo();
      }
  })
}

showlblReceiptPayTypeMandatory:boolean = false;
showlblPaymentMethodMandatory:boolean = false;
showlblPaymentAmountMandatory:boolean = false;
  btnAddPaymentsClick() {
    debugger
    if (this.ReceiptPayType_selected == undefined || this.ReceiptPayType_selected == null) {
      this.showlblReceiptPayTypeMandatory = true;
    }
    else {
      this.showlblReceiptPayTypeMandatory = false;
    }
    if (this.PaymentMethod_selected == undefined || this.PaymentMethod_selected == null ) {
      this.showlblPaymentMethodMandatory = true;
    }
    else {
      this.showlblPaymentMethodMandatory = false;
    }
    if (this.PaymentAmount_selected == undefined || this.PaymentAmount_selected == null || this.PaymentAmount_selected == '') {
      this.showlblPaymentAmountMandatory = true;
    }
    else {
      this.showlblPaymentAmountMandatory = false;
    }

    if (this.showlblPaymentMethodMandatory || this.showlblReceiptPayTypeMandatory || this.showlblPaymentAmountMandatory) {
      return
    }
    this.showlblInsertPayment = false;
    
    if(this.ReceiptPayType_selected !=undefined)
    var PayTypeName_selected = document.getElementById('ReceiptPayType').innerText;

    if(this.PaymentMethod_selected !=undefined)
    var PaymentMethodName_selected = document.getElementById('PaymentMethod').innerText;

    if(this.PaymentAmount_selected)
    this.PaymentAmount_selected = parseFloat(this.PaymentAmount_selected.replaceAll(/,/g, ''));
  
    if (this.editRowIndex >= 0) {
      // var index = this.gridData_AddPayments.findIndex(x => x.ProductSizeID == 2);  

      // if (this.SizeExistsInOutlet && this.SizeExistsInSalesType ) {
      //   this.showlblSizeAlreadyExists = true;
      //   return;
      // }
      
      // else {
        // this.showlblSizeAlreadyExists = false;
        this.gridData_AddPayments.splice(this.editRowIndex, 1, {
        "PayTypeId": this.ReceiptPayType_selected,
        "PayType": PayTypeName_selected,
        "PaymentCode": this.PaymentMethod_selected == '' ? 0 : this.PaymentMethod_selected,
        "PaymentMode": PaymentMethodName_selected,
        "CustomerPaid": +this.PaymentAmount_selected,
        "PaymentRef": this.Reference_selected,
        "IsUpdateRow": 1
      });
        this.editRowIndex = undefined;
        this.source1 = new LocalDataSource(this.gridData_AddPayments);
        this.ReceiptPayType_selected = undefined;
        this.PaymentMethod_selected = undefined;
        this.Reference_selected = undefined;
        this.PaymentAmount_selected = '';
        return
      // }
    }
    else {debugger   
      // if (this.SizeExistsInOutlet && this.SizeExistsInSalesType && this.ObjmodifierSetupMain.IsPriceOnSelection) {
      //   this.showlblSizeAlreadyExists = true;
      //   return;
      // }

      // else {
        // this.showlblSizeAlreadyExists = false;
        this.gridData_AddPayments.push({
          "PayTypeId": this.ReceiptPayType_selected,
          "PayType": PayTypeName_selected,
          "PaymentCode": this.PaymentMethod_selected == '' ? 0 : this.PaymentMethod_selected,
          "PaymentMode": PaymentMethodName_selected,
          "CustomerPaid": +this.PaymentAmount_selected,
          "PaymentRef": this.Reference_selected,
          "IsNewRow": 1
        });
        this.source1 = new LocalDataSource(this.gridData_AddPayments);
        this.ReceiptPayType_selected = undefined;
        this.PaymentMethod_selected = undefined;
        this.Reference_selected = undefined;
        this.PaymentAmount_selected = '';
      }
      // this.PaymentMethod_selected=''
    }


  // }

  AddPaymentsSettings = {
    mode: 'external',
    actions: {
      columnTitle: this.translate.transform('Actions'),
      add: false
    },
    add: {
      addButtonContent: '<i class="nb-plus"></i>',
      createButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
      confirmCreate: true,
    },
    edit: {
      editButtonContent: '<i class="nb-edit"></i>',
      saveButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
      confirmSave: true
    },

    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
      confirmDelete: true
    },
    columns: {
      PayType: {
        title: this.translate.transform('ReceiptPayType'),
        filter: false,
      },
      PaymentMode: {
        title: this.translate.transform('PaymentMethod'),
        filter: false,
      },
      CustomerPaid: {
        title: this.translate.transform('PaymentAmount'),
        filter: false,
      },

      // SalePrice: {
      //   title: this.translate.transform('Price'),
      //   editable: true,
      //   filter: false,
      //   valuePrepareFunction: cell => {
      //     return this._decimalPipe.transform(cell, '1.3-3');
      //   }
      // },
    },
    attr: {
      class: 'table table-bordered',
    },
    hideSubHeader: true,
  };

  rowEdit(event) {debugger
    // this.btnSizesAddUpdate = this.translatePipe.transform('UPDATESIZE');
    this.editRowIndex = event.index;
    this.ReceiptPayType_selected = event.data.PayTypeId;
    // this.Reference_selected = event.data.PayTypeName;
    this.PaymentAmount_selected = event.data.CustomerPaid.toLocaleString();
    this.PaymentMethod_selected = event.data.PaymentCode;
    // this.Reference_selected = event.data.PaymentMode;
    this.Reference_selected = event.data.PaymentRef;  
    this.showlblReceiptPayTypeMandatory=false;
    this.showlblPaymentMethodMandatory=false;
    this.showlblPaymentAmountMandatory=false;
  }


  DeleteAddMultiplePrice(event) {debugger
    this.gridData_AddPayments.splice(event.index, 1, {
      "ReceiptID": this.ObjCustomerReceiptVoucher.ReceiptId,
      "IsDeletedRow": 1
    });
    // this.gridData_AddPayments.splice(event.index, 1);
    this.source1 = new LocalDataSource(this.gridData_AddPayments);
  }
  onEditConfirm(event){
    debugger
  }
  onCreateConfirm(event){
    debugger
  }

  numberOnly(event, value): boolean {
    debugger
    const charCode = (event.which) ? event.which : event.keyCode;
    var eventKey = event.key;
    if (event.which == 46 && value.indexOf('.') != -1) {
      event.preventDefault();
    } // prevent if already dot

    else if (charCode == 46) {
      return true;
    }

    else if ((charCode < 48 || charCode > 57)) {
      return false;
    }

    else { return true; }

  }

  
  SelectedCustId: any=0;
  SelectedCustName: any;
  // LoyaltyPointsInfo: any;
  // lstLoyaltyPointsData: any;
  // CustomerAddressID: any;
  openDialogCustomerPopUp(){debugger
      let dialogRef = this.dialog.open(DeliveryPopupComponent, {
        width: '1150px',
        height: '600px',
        data: { outletID: this.data.OutletID , IsCustomerReceiptVoucherForm:true}
      });
  
      dialogRef.afterClosed().subscribe(result => {
        debugger
        if (result != undefined && result[0] != undefined) {
          this.SelectedCustId = result[0]["custID"];
          this.SelectedCustName = result[0]["custName"];
          this.SelectedReceiptId = 0;
          this.showlblCustomerMandatory =false;
          this.showlblReceiptPayTypeMandatory=false;
          this.showlblPaymentMethodMandatory=false;
          this.showlblPaymentAmountMandatory=false;
          this.showlblInsertPayment=false;
          this.CustomerReceiptsInfo();
        }
 
  
      });
    
  }

  CustomerReceiptsInfo(){debugger
    this._spinnerService.show();
    this.dataservice.get('CustomerReceipt/GetCustomerReceiptsData/'+ this.UtilitiesService.getSubscriptionID() +'/'+this.SelectedCustId+'/'+this.SelectedReceiptId)
      .subscribe(data => {debugger
        if (this.isArrayHasValue(data[0]['ReceiptData'])) {
          this.ObjCustomerReceiptVoucher = data[0]['ReceiptData'][0];
          if(this.SelectedCustId !=0){
          this.ObjCustomerReceiptVoucher.CustomerID = this.SelectedCustId;
          this.ObjCustomerReceiptVoucher.CustomerName = this.SelectedCustName;
          }
        } 
        if (this.isArrayHasValue(data[0]['ReceiptDetailData'])) {debugger
          this.gridData_AddPayments = data[0]['ReceiptDetailData'];
        this.source1 = new LocalDataSource(this.gridData_AddPayments);

        } 
      else{
        this.gridData_AddPayments = [];
      }
        this._spinnerService.hide();
      }, error => alert('error'));
  }

  showlblCustomerMandatory: boolean =false;
  showlblInsertPayment: boolean =false;
  btnSaveClick(){debugger
    let flag = false;
    if (this.ObjCustomerReceiptVoucher.CustomerID == undefined || this.ObjCustomerReceiptVoucher.CustomerID == '' || this.ObjCustomerReceiptVoucher.CustomerID == 0) 
    {
      this.showlblCustomerMandatory = true;
      flag = true;
    }
    else{
      this.showlblCustomerMandatory = false
    }
    let paymentGrid = this.gridData_AddPayments.filter(element => element.IsNewRow == 1 || element.IsUpdateRow == 1 || element.IsDeletedRow == 1);
    if(paymentGrid.length == 0){
      this.showlblInsertPayment =true;
      flag = true;
    }
    else{
     this.showlblInsertPayment =false;
    }
    if (flag)
      return;

    var currDate = new Date();
    let PayDate = currDate.getFullYear() + '/' + (currDate.getMonth() + 1) + '/' + currDate.getDate();
    let PayTime = new Date().toLocaleTimeString([], { hour: '2-digit', minute: "2-digit" });
    let CreatedBy = this.UtilitiesService.getUserId();
    let CreatedOn = this.UtilitiesService.GetCurrentDateTime();
    this.ObjCustomerReceiptVoucher.OutletID = this.data.OutletID;
    this.ObjCustomerReceiptVoucher.DayID = this.data.DayId;
    this.ObjCustomerReceiptVoucher.ShiftID = this.data.ShiftId;

    var body = [this.ObjCustomerReceiptVoucher,this.gridData_AddPayments,PayDate,PayTime,this.UtilitiesService.getSubscriptionID(),CreatedBy,CreatedOn,this.data.OutletID,this.ObjCustomerReceiptVoucher.ReceiptId];

    this._spinnerService.show();
    this.dataservice.post('CustomerReceipt/SaveCustomerReceiptVoucher', body).subscribe(data => {debugger
      if (data == "Insert") {
        this.popup.ShowPopup('Insert');
        this._spinnerService.hide();
        this.ObjCustomerReceiptVoucher = new CustomerReceiptVoucher();
        this.gridData_AddPayments= [];
        this.source1 = new LocalDataSource(this.gridData_AddPayments);
        // this.showlblDuplicateName = false;

      }
      else if (data == "Update") {      
        this._spinnerService.hide();
        this.popup.ShowPopup('Update');
      }
   
      
   
      else {
        setTimeout(() => { this._spinnerService.hide() }, 3000);
      }

    })

  }

}

class CustomerReceiptVoucher {
  ReceiptId: any=0;
  ReceiptNo: any;
  ReceiptDate: any;
  Note: any;
  TotalCreditSales: any;
  PaidAmount: any;
  Balance: any;
  CustomerID:any;
  CustomerName:any;
  DayID:any
  ShiftID:any;
  OutletID:any;
  // CompanyId:any;
  CreatedBy:any;
  CreatedOn:any;
  // NetAmount:any
  // CustomerPaid:any
  // OutstandingBalance:any
}

