import { Component, OnInit, Inject } from '@angular/core';
import { DataserviseService } from '../../shared/dataservice';
import { PopupService } from '../../shared/popup';
import { UtilitiesService } from '../../shared/utilities.service';
import { SpinnerService } from '../../shared/spinner';
import { MatDialogRef, MAT_DIALOG_DATA,MatDialog } from '@angular/material/dialog';
import {NumberPopupComponent} from '../pos_popup/number-popup/number-popup.component'
import {TranslatePipe} from '../../shared/pipe/translate.pipe';
import { SelectButtonComponent } from './selectbutton/selectbutton.component';
@Component({
  selector: 'ngx-delivery-popup',
  templateUrl: './delivery-popup.component.html',
  styleUrls: ['./delivery-popup.component.scss']
})

export class DeliveryPopupComponent implements OnInit {
  showPerPage = 5;

  settings = {
    actions: {
      columnTitle: '',
      add: false,
      edit: true,
      delete:false
    },
    mode:'external',   
    pager:{
      display: false,
      perPage: this.showPerPage,
    },
    edit:{
      editButtonContent: '<i class="nb-edit"></i>',
    },
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',  
      confirmDelete: true,
    },
    columns: {
      button: {
        title: '',
        type: 'custom',
        renderComponent: SelectButtonComponent,
        onComponentInitFunction: (instance: any) => {
          instance.save.subscribe(row => {
            debugger
            if (row != undefined) { this.onUserRowSelect(row); }
          });
        }
      },

      UserFullName: {
        title: this.translate.transform('Name'),
      },
      PhoneNo: {
        title: this.translate.transform('MobileNo.'),
      },
      Deliveryat: {
        title: this.translate.transform('Delivery'),
      },
      Address: {
        title: this.translate.transform('Address'),
      },
      RemainingPoints: {
        title: this.translate.transform('LoyaltyPoints'),
      },

    },
    attr: {
      class: 'table table-bordered',
    },
    hideSubHeader: true,
  };
  
  CompanyId: any;
  CreatedBy: any = '';
  CreatedOn: any = '';
  lstCustomerData: any = [];
  // objCustomerMaster: CustomerMaster = new CustomerMaster();
  // objCustomerAddressDetail: CustomerAddressDetail = new CustomerAddressDetail();
  ObjRegister: Register = new Register();
  ObjRegisterDetail: RegisterDetail = new RegisterDetail();
  ObjGridFilterData: filterdata = new filterdata();
  buttonSave:string = this.translate.transform('Save') // 'Save';
  AddUpdateCustomer:string = this.translate.transform('AddNewCustomer') //'Add New Customer';

  AddressVisible: boolean = false;
  FirstNameMandatory: boolean = false;
  // LastNameMandatory: boolean = false;
  MobileMandatory: boolean = false;
  // MobileValid: boolean = false;
  Area: boolean = false;
  Flat: boolean = false;
  Road: boolean = false;
  Block: boolean = false;
  BuildingNo: boolean = false;
  Address: boolean = false;
  CountryCode: any;
  showvalidationEmail: boolean = true;
  showlabelEmail: boolean = false;
  filterName:any;
  filterMobileNo:any;

  SearchCustomer : any = this.translate.transform('SearchCustomer');
  Name : any = this.translate.transform('Name');
  MobileNo : any = this.translate.transform('MobileNo.');
  Search : any = this.translate.transform('Search');
  FirstName : any = this.translate.transform('FirstName');
  LastName : any = this.translate.transform('LastName');
  DeliveryAt : any = this.translate.transform('Deliveryat');
  Home : any = this.translate.transform('Home');
  Office : any = this.translate.transform('Office');
  Others : any = this.translate.transform('Others');
  lblArea : any = this.translate.transform('Area');
  Floor : any = this.translate.transform('Floor');
  Building  : any = this.translate.transform('BuildingNo');
  lblRoad  : any = this.translate.transform('RoadNo');
  lblBlock  : any = this.translate.transform('BlockNo');
  BuildingName : any = this.translate.transform('BuildingName');
  AdditionalDirectionsOptional : any = this.translate.transform('AdditionalDirection');
  DeliveryAddress : any = this.translate.transform('DeliveryAddress');
  Cancel : any = this.translate.transform('Cancel');
  Pleaseenterfirstname : any = this.translate.transform('Pleaseenterfirstname');
  VATNo : any = this.translate.transform('VATNo');
 Companyname : any = this.translate.transform('Companyname');
 LoyaltyPoints : any = this.translate.transform('LoyaltyPoints');
  MaxCreditLimit: any = this.translate.transform('MaxCreditLimit');



  constructor( private translate : TranslatePipe,private _spinnerService: SpinnerService, private Utilities: UtilitiesService, private dataservice: DataserviseService, private popup: PopupService, public dialog: MatDialog,public dialogRef: MatDialogRef<DeliveryPopupComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {debugger
    this.ObjGridFilterData.branchId = this.data.outletID;
    this.CompanyId = this.Utilities.getSubscriptionID();
    this.ObjRegisterDetail.CreatedBy = this.Utilities.getUserId();
    this.ObjRegisterDetail.CreatedOn = this.Utilities.GetCurrentDateTime();
    this.ObjRegister.RegisterDate = this.Utilities.GetCurrentDateTime();
    this.CountryCode = JSON.parse(localStorage.getItem('counterID&CompanyID'+this.Utilities.getURLCompanyName()))[0].CountryCode;
    this.ObjRegister.CountryCode = this.CountryCode;
    // this.dataservice.checkFormRights('addAreaviaexcel', 'Allow');
      
    if (this.CountryCode == 'PK') {
      this.AddressVisible = false;
    }
    else {
      this.AddressVisible = true;
    }

    // if (this.Storage.geten('OrderType' + this.CompanyIdL) != null)
    // this.OrderType = this.Storage.geten('OrderType' + this.CompanyIdL);
  this.GetDropdownSetups();
  }

  LoyaltyPointsInfo: any = [];
  lstLoyaltyPointsData: any = [];
  getGridData(): void {debugger
    if ((this.ObjGridFilterData.Name == undefined || this.ObjGridFilterData.Name == '') && (this.ObjGridFilterData.PhoneNo == undefined || this.ObjGridFilterData.PhoneNo == '') && (this.ObjGridFilterData.Company == undefined || this.ObjGridFilterData.Company == ''))
    return
    this.ObjGridFilterData.CompanyId = this.Utilities.getSubscriptionID();
    this._spinnerService.show();
    this.dataservice.post('Customer/GetFilterCustomerGridDataPOSCahsierScreen/', this.ObjGridFilterData)
      .subscribe(data => {debugger
        if (this.isArrayHasValue(data[0]['lstCustomer'])) {
          this.lstCustomerData = data[0]['lstCustomer'];
        } 
        else {
          this.lstCustomerData = [];
        }

        //if (this.isArrayHasValue(data[0]['LoyaltyPointsInfo'])) {
        //  this.LoyaltyPointsInfo = data[0]['LoyaltyPointsInfo'];
        //}
        //else{
        //  this.LoyaltyPointsInfo = [];
        //}
        //if (this.isArrayHasValue(data[0]['lstLoyaltyPointsData'])) {
        //  this.lstLoyaltyPointsData = data[0]['lstLoyaltyPointsData'];
        //}
        //else{
        //  this.lstLoyaltyPointsData = [];
        //}
        this._spinnerService.hide();
      }, error => alert('error'));

  }
  NumberKeypadPopup(){

    const dialogRef = this.dialog.open(NumberPopupComponent, {
      width: '400px',
      height: '430px',
      data: {},
      panelClass: 'my-custom-dialog-class'
    });

    dialogRef.afterClosed().subscribe(result => {
        debugger
      if (result) {
        debugger
        this.ObjGridFilterData.PhoneNo = result;
        this.getGridData();
      }

    });
  }

  isArrayHasValue(obj: any[]): boolean {
    let isArrayWithLength = Boolean(Array.isArray(obj) && obj.length);
    return isArrayWithLength;
  }
  replaceAstrophe(val): any {
    if (val != null)
      return val.replaceAll("'", "''");
  }

  // val_lblCustName:boolean = false;
  // val_lblNumber:boolean = false;
  // serverMsg: any = [];
  val_lblDuplicateName;
  // val_lblEmail;
  ArrRegisterDetail: any = [];
  
  btnSave(){debugger
    if (this.ValidateForm())
    return;
    this.ObjRegister.LastName = this.ObjRegister.LastName == undefined ? '' : this.ObjRegister.LastName;
     this.ObjRegister.UserFullName = this.ObjRegister.FirstName + " " + this.ObjRegister.LastName;
     this.ObjRegister.CreatedBy = this.Utilities.getUserId();
     this.ObjRegister.CreatedOn = this.Utilities.GetCurrentDateTime();
     if(this.ObjRegister.VATNo == ""){
        this.ObjRegister.VATNo = null 
     }
     let rowid = Math.floor(100000 + Math.random() * 900000);

    this.ArrRegisterDetail.push({
      'CustAddressID': this.ObjRegisterDetail.CustAddressID,
      'CustomerID': this.ObjRegister.CustomerID,
      'Area': this.ObjRegisterDetail.Area,
      'Flat': this.ObjRegisterDetail.Flat,
      'Floor': this.ObjRegisterDetail.Floor,
      'Road': this.ObjRegisterDetail.Road,
      'Block': this.ObjRegisterDetail.Block,
      'BuildingNo': this.ObjRegisterDetail.BuildingNo,
      'BuildingName': this.replaceAstrophe(this.ObjRegisterDetail.BuildingName),
      'AdditionalDirection': this.replaceAstrophe(this.ObjRegisterDetail.AdditionalDirection),
      'Deliveryat': this.ObjRegisterDetail.Deliveryat,
      'Address': this.replaceAstrophe(this.ObjRegisterDetail.Address),
      'isActive': true,
      'DeleteFlag': false,
      'CompanyId': this.CompanyId,
      'rowid': rowid,
      'CreatedBy': this.ObjRegisterDetail.CreatedBy,
      'CreatedOn': this.ObjRegisterDetail.CreatedOn,
      'ModifyBy': this.ObjRegisterDetail.ModifyBy,
      'ModifyOn': this.ObjRegisterDetail.ModifyOn,
    });

this._spinnerService.show();
let body = [this.ObjRegister,this.ArrRegisterDetail,this.ObjRegister.CustomerID]
this.dataservice.post('Customer/SavePOSCustomer/',body )
  .subscribe(result => {
    debugger
    if (result) {
      debugger
      if (result[0].SavedData[0].Column1 == 'Insert') {
        this.popup.ShowPopup('Insert');
        // this.clearControls();
        // this.getGridData();
        var customerRow = {
          custID:  result[0].SavedData[0].CustomerID,
          custName: this.ObjRegister.UserFullName,
          CustAddressID: result[0].SavedData[0].CustomerAddressID
        }
        this.dialogRef.close(customerRow);
      }
      else if (result[0].SavedData[0].Column1 == 'Update') {debugger
        this.popup.ShowPopup('Update');
        // this.clearControls();
        // this.getGridData();
        this.val_lblDuplicateName = false;
        var customerRow = {
          custID: this.ObjRegister.CustomerID,
          custName: this.ObjRegister.UserFullName,
          CustAddressID:this.ObjRegisterDetail.CustAddressID
        }
        this.dialogRef.close(customerRow);
      }

      else if (result[0].SavedData[0].Column1 == 'Duplicate') {
        this.val_lblDuplicateName = true;
      }
      else {
        alert('error');
      }
    }
    this._spinnerService.hide();
  }, error => alert('error'));

  }

  ddlArea: any = [];

  GetDropdownSetups(): void {
    this.ObjRegister.CompanyId = this.Utilities.getSubscriptionID();
    debugger;
    this.dataservice.get('Web_Customer/GetSetupData/' + this.ObjRegister.CompanyId)
      .subscribe(data => {
        debugger;
        if (this.isArrayHasValue(data[0]['Area'])) {
          this.ddlArea = data[0]['Area'];
          this.ObjRegisterDetail.Area = this.ddlArea[0].AreaId;
          // if (this.OrderType && this.OrderType == 'Delivery') {
         
          //   this.ObjRegisterDetail.Area = parseInt(this.sessionStorage.geten('AreaId' + this.ObjRegister.CompanyId));
          // }
          // else {
          //   this.ObjRegisterDetail.Area = this.ddlArea[0].AreaId;
          // }
        }

      });
  }

  public onUserRowSelect(event) {
    debugger
 
    var customerRow = {
      custID: event.CustomerID,
      custName: event.UserFullName,
      CustAddressID:event.CustAddressID,
      PhoneNo: event.PhoneNo,
      MaxCreditLimit:event.MaxCreditLimit

    }
    let body = [customerRow,this.LoyaltyPointsInfo,this.lstLoyaltyPointsData]
    this.dialogRef.close(body);
  }

  btnclose(){debugger
    this.dialogRef.close();
  }

  onDelete(event) {
    if (window.confirm('Are you sure you want to delete?')) {
      this.DeleteData(event.data.CustomerID);
    } else {
      event.confirm.reject();
    }
  }
  DeleteData(Id): void {
    this.dataservice.post('Customer/deleteCustomer/', Id)
      .subscribe(result => {
        if (result) {
          if (result) {
            this.popup.ShowPopup('Delete');
            this.getGridData();
          }
          else {
            alert('error');
          }
        }
      }, error => alert('error'));
  }

  disableDelivertButton:boolean = false;
  onEditRowSelect(event) {
    debugger
    this.buttonSave = this.translate.transform('Update'); //'Update';
    this.AddUpdateCustomer = this.translate.transform('UpdateCustomer');// 'Update Customer';
    this.disableDelivertButton = true;
    this.ObjRegister.CustomerID = event.data.CustomerID;
    this.ObjRegisterDetail.CustAddressID = event.data.CustAddressID;
    this.ObjRegister.FirstName = event.data.FirstName;
    this.ObjRegister.LastName = event.data.LastName;
    this.ObjRegister.PhoneNo = event.data.PhoneNo;
    this.ObjRegister.Email = event.data.Email;
    this.ObjRegister.VATNo = event.data.VATNo;
    this.ObjRegister.Company = event.data.Company;
    this.ObjRegister.MaxCreditLimit = event.data.MaxCreditLimit;
    this.ObjRegister.ModifyBy = this.Utilities.getUserId();
    this.ObjRegister.ModifyOn = this.Utilities.GetCurrentDateTime();
    this.ObjRegisterDetail.Deliveryat = event.data.Deliveryat;
    this.ObjRegisterDetail.Area = +event.data.Area;
    this.ObjRegisterDetail.Flat = event.data.Flat;
    this.ObjRegisterDetail.Floor = event.data.Floor;
    this.ObjRegisterDetail.BuildingNo = event.data.BuildingNo;
    this.ObjRegisterDetail.Road = event.data.Road;
    this.ObjRegisterDetail.Block = event.data.Block;
    this.ObjRegisterDetail.BuildingName = event.data.BuildingName;
    this.ObjRegisterDetail.Address = event.data.Address;
    this.ObjRegisterDetail.AdditionalDirection = event.data.AdditionalDirection;
    this.ObjRegisterDetail.ModifyBy = this.Utilities.getUserId();
    this.ObjRegisterDetail.ModifyOn = this.Utilities.GetCurrentDateTime();
  }

  flatApartment: any = this.translate.transform('FlatAppartment'); // 'Flat / Appartment #';
  PleasenterFlat: any = this.translate.transform('PleasenterFlat');

  PleaseenterRoad: any = this.translate.transform('PleaseenterRoad');
  PleaseenterBlock: any = this.translate.transform('PleaseenterBlock');
  PleaseenterBuildingNo: any = this.translate.transform('PleaseenterBuildingNo');
  // PleasenterFlat: any = this.translate.transform('PleasenterFlat');
  // PleasenterFlat: any = this.translate.transform('PleasenterFlat');


  rdChange(val): void {
    if (val == 'Home' || val == 'Others') {
      this.flatApartment = this.translate.transform('FlatAppartment') //'Flat / Appartment #';
    }
    else {
      this.flatApartment = this.translate.transform('Office') + '#'; //'Office #';
    }
  }

  firstchar:any;
  ValidateForm(): boolean {
    this.MobileMandatory = false;
    this.FirstNameMandatory = false;
    // this.LastNameMandatory = false;
    // this.MobileValid = false;
    this.Area = false;
    this.Road = false;
    this.Block = false;
    this.BuildingNo = false;
    this.Flat = false;
    this.Address = false;
// if(this.ObjRegister.PhoneNo){
//   this.firstchar = this.ObjRegister.PhoneNo.charAt(0)
// }
    
    let res = false;
    if (!this.ObjRegister.FirstName || this.ObjRegister.FirstName.trim() == '') {
      this.FirstNameMandatory = true;
      res = true;
    }
    // if (!this.ObjRegister.LastName || this.ObjRegister.LastName.trim() == '') {
    //   this.LastNameMandatory = true;
    //   res = true;
    // }

    if (!this.ObjRegister.PhoneNo || this.ObjRegister.PhoneNo == '' || this.ObjRegister.PhoneNo == undefined) {
      this.MobileMandatory = true;
      res = true;
    }
    //if (!this.ObjRegister.Email || this.ObjRegister.Email.trim() == '') {
    //  this.showlabelEmail = true;
    //  res = true;
    //}
    //if(this.showvalidationEmail == false){
    //    res = true;
    //}
    //if(this.showlabelEmail == true ){
    //  this.showvalidationEmail = true;
    //}

    // else if (this.firstchar != '+') {
    //   this.MobileValid = true;
    //   res = true;
    // }
    debugger
    if (this.CountryCode == 'PK') {
      if (!this.ObjRegisterDetail.Address || this.ObjRegisterDetail.Address.trim() == '') {
        this.Address = true;
        res = true;
      }
    }
    else {

      if (!this.ObjRegisterDetail.Area || this.ObjRegisterDetail.Area == '') {
        this.Area = true;
        res = true;
      }
      //if (!this.ObjRegisterDetail.Flat || this.ObjRegisterDetail.Flat.trim() == '') {
      //  this.Flat = true;
      //  res = true;
      //}

      //if (!this.ObjRegisterDetail.Road || this.ObjRegisterDetail.Road.trim() == '') {
      //  this.Road = true;
      //  res = true;
      //}
      //if (!this.ObjRegisterDetail.Block || this.ObjRegisterDetail.Block.trim() == '') {
      //  this.Block = true;
      //  res = true;
      //}

      //if (!this.ObjRegisterDetail.BuildingNo || this.ObjRegisterDetail.BuildingNo.trim() == '') {
      //  this.BuildingNo = true;
      //  res = true;
      //}
    }

    return res;
  }
 
  isValidEmail(search: string): boolean {
    debugger
    this.showlabelEmail = false;
    if (search != "") {
      var regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
      this.showvalidationEmail = regexp.test(search);
      return this.showvalidationEmail;
    }
  }

  clearControls(){
    this.ObjRegister = new Register();
    this.ObjRegisterDetail = new RegisterDetail();
    this.ArrRegisterDetail = [];
    this.buttonSave = this.translate.transform('Save'); //'Save';
    this.AddUpdateCustomer = this.translate.transform('AddNewCustomer')// 'Add New Customer';
    this.disableDelivertButton = false;
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}

class Register {
  CustomerID:any = 0;
  FirstName: any;
  LastName: any;
  UserFullName: any;
  Email: any ;
  PhoneNo: any ;
  CompanyId: any;
  Address:any;
  CreatedBy: any;
  CreatedOn: any;
  ModifyBy:any;
  ModifyOn:any;
  RegisterDate:any;
  CountryCode:any;
  CustomerType:any = 'POS';
  VATNo:any;
  Company: any;
  MaxCreditLimit: any;
}

class RegisterDetail {
  CustAddressID: any=0;
  CustomerID: any;
  Area: any;
  Flat: any;
  Floor: any;
  Road: any;
  Block: any;
  BuildingNo: any;
  BuildingName: any;
  AdditionalDirection: any;
  Deliveryat: any = 'Home';
  Address: any;
  isActive: boolean = true;
  DeleteFlag: boolean;
  CompanyId: any;
  CreatedBy: any;
  CreatedOn: any;
  ModifyBy:any;
  ModifyOn:any;
}

class filterdata{
  Name:any;
  PhoneNo:any;
  CompanyId:any;
  branchId: any;
  Company: any;
}
