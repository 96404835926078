import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataserviseService } from '../../shared/dataservice';
import { UtilitiesService } from '../../shared/utilities.service';
import { PopupService } from '../../shared/popup';
import { RejectPopupComponent } from '../../pages/live/liveorder/rejectpopup/rejectpopup.component';
import { TranslatePipe } from '../../shared/pipe/translate.pipe';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'ngx-pendingorders',
  templateUrl: './pendingorders.component.html',
  styleUrls: ['./pendingorders.component.scss']
})
export class PendingordersComponent implements OnInit {


  constructor(private _popup: PopupService, private translate: TranslatePipe, private UtilitiesService: UtilitiesService, private popup: PopupService, private dataservice: DataserviseService, public dialog: MatDialog, public dialogRef: MatDialogRef<PendingordersComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public http: HttpClient) { }

  cultures: any;
  PendingOrders: any = this.translate.transform('PendingOrders');
  lblCancelOrder: any = this.translate.transform('CancelOrder');
  lblSelectOrder: any = this.translate.transform('SelectOrder');
  // lblAcceptOrder: any = this.translate.transform('AcceptOrder');
  PrintOrder: any = this.translate.transform('PrintOrder');
  RePrintOrder: any = this.translate.transform('RePrintOrder');
  OrderNo: any = this.translate.transform('OrderNo.');
  OrderDate: any = this.translate.transform('OrderDate');
  OrderType: any = this.translate.transform('OrderType');
  TableNo: any = this.translate.transform('TableNo.');
  Customer: any = this.translate.transform('Customer');
  PhoneNo: any = this.translate.transform('PhoneNo');
  Remarks: any = this.translate.transform('Remarks');
  Qty: any = this.translate.transform('Qty');
  Description: any = this.translate.transform('Description');
  Amount: any = this.translate.transform('Amount');
  SubTotal: any = this.translate.transform('NetTotal');
  Discount: any = this.translate.transform('Discount');
  Tax: any = this.translate.transform('Tax');
  DeliveryFee: any = this.translate.transform('Delivery Fee');
  NetAmount: any = this.translate.transform('lblFinalTotal');
  SpecialInstruction: any = this.translate.transform('SpecialInstruction');
  YoucannotprinttheReceiptmorethen: any = this.translate.transform('YoucannotprinttheReceiptmorethen');
  times: any = this.translate.transform('times');
  DayID: any;
  CounterID: any;
  IsCancelOrderButtonActive: boolean = false;
  IsPendingOrderPrintReciept: boolean = false;
  IsPendingOrderKitchenPrint: boolean = false;

  MaxReceiptPrintOut: any;
  MaxKitchenReceiptPrintOut: any;
  ReceiptPrintOutCount: any;
  KitchenReceiptPrintOutCount: any;
  CounterName: any;
  lstSalesType = [];

  ngOnInit(): void {
    debugger

    if (sessionStorage.getItem('ObjCompany') != null && sessionStorage.getItem('ObjCompany') != undefined) {
      this.ObjCompany = JSON.parse(sessionStorage.getItem('ObjCompany'));
    }
    if (localStorage.getItem('SelectedBranchRow' + this.UtilitiesService.getURLCompanyName()) != null && localStorage.getItem('SelectedBranchRow' + this.UtilitiesService.getURLCompanyName()) != undefined) {
      this.SelectedBrancRow = JSON.parse(localStorage.getItem('SelectedBranchRow' + this.UtilitiesService.getURLCompanyName()));
      this.SelectedOutletID = this.SelectedBrancRow.ID;
    }

    if (localStorage.getItem('sessionObject' + this.UtilitiesService.getURLCompanyName()) != null && localStorage.getItem('sessionObject' + this.UtilitiesService.getURLCompanyName()) != undefined) {
      this.lstUser = JSON.parse(localStorage.getItem('sessionObject' + this.UtilitiesService.getURLCompanyName()));
    }
    this.cultures = this.data["culture"];
    this.IsCancelOrderButtonActive = this.data["data"];
    this.IsPendingOrderPrintReciept = this.data["IsPendingOrderPrintReciept"];
    this.IsPendingOrderKitchenPrint = this.data["IsPendingOrderKitchenPrint"];
    // console.log(this.IsPendingOrderPrintReciept,this.IsPendingOrderKitchenPrint)
    this.DayID = this.data["DayID"];
    this.CounterID = this.data["CounterID"];
    this.CounterName = this.data["CounterName"];
    this.lstSalesType = this.data["listSalesType"];

    this.LoadGrid();
  }

  ObjCompany: Company = new Company();
  ObjOrderMaster: OrderMaster = new OrderMaster();
  ArrOrderDetail: OrderDetail[] = [];
  SearchInList: any = '';
  SearchInListSalesType: any = '0';
  HighlightRow: any;
  CurrencyCode: any = '';
  SelectedBrancRow: any;
  SelectedOutletID: any;

  lstarray = [];
  lstarray_Duplicate = [];
  lstPrinterserversetting = [];
  lstBasket: any = [];
  lstSelectModifier: any = [];
  OrderID: any;
  barertoken: any;
  btnclose(): void {
    debugger
    this.dialogRef.close();
  }
  LoadGrid() {
    debugger;
    this.lstarray = [];
    this.lstarray_Duplicate = [];
    this.validationPrintReceipt = false;

    if (this.SelectedOutletID != undefined && this.SelectedOutletID != null) {
      this.dataservice.get('pos/GetPendingOrders/' + this.UtilitiesService.getSubscriptionID() + '/' + this.SelectedOutletID + '/' + localStorage.getItem('LanguageCulture' + this.UtilitiesService.getURLCompanyName()) + '/' + this.DayID + '/' + this.CounterID).subscribe(data => {

        debugger;
        if (this.isArrayHasValue(data[0]["Pendingprder"])) {
          this.lstarray = data[0]["Pendingprder"];
          this.lstarray_Duplicate = this.lstarray;
          this.getMasterDetailData(data[0]['Pendingprder'][0]["OrderID"], 0);
        }

        //if (this.isArrayHasValue(data[0]["printerserversetting"])) {
        //  this.lstPrinterserversetting = data[0]["printerserversetting"];

        //}

      });




    }
    else {
      alert('Please Select Outlet first.');
    }
  }
  win;
  KitchenRePrintClick() {
    debugger
    this.validationPrintReceipt = false;
    // if(this.ObjOrderMaster.KitchenReceiptPrintOutCount < this.ObjOrderMaster.MaxKitchenReceiptPrintOut)
    // {
    if (this.lstPrinterserversetting.length > 0) {
      if (this.lstPrinterserversetting[0]['IsKOTDirectPrint'] == 2) {
        if (this.ObjOrderMaster.IsMaxPolicyOn) {
          if (this.ObjOrderMaster.MaxKitchenReceiptPrintOut > this.ObjOrderMaster.KitchenReceiptPrintOutCount) {
            let link = this.lstPrinterserversetting[0]['ServerLink'] + '/Default?O=' + this.OrderID + '&C=' + this.UtilitiesService.getSubscriptionID() + '&type=KRePrint' + '&OutletID=' + this.ObjOrderMaster.OutletId + '&ShiftID=' + this.ObjOrderMaster.ShiftID;
            this.win = window.open(link, '_blank', 'width=300,height=200');
            this.ObjOrderMaster.KitchenReceiptPrintOutCount = parseInt(this.ObjOrderMaster.KitchenReceiptPrintOutCount) + 1;
          }
          else {
            this.validationPrintReceipt = true;
          }
        }
        else {
          let link = this.lstPrinterserversetting[0]['ServerLink'] + '/Default?O=' + this.OrderID + '&C=' + this.UtilitiesService.getSubscriptionID() + '&type=KRePrint' + '&OutletID=' + this.ObjOrderMaster.OutletId + '&ShiftID=' + this.ObjOrderMaster.ShiftID;
          this.win = window.open(link, '_blank', 'width=300,height=200');
        }
      }
      if (this.lstPrinterserversetting[0]['IsKOTDirectPrint'] == 3) {
        // this.ReceiptURL = this.UtilitiesService.getReceiptURL(this.UtilitiesService.getEncrypt(this.UtilitiesService.getCompanyName()), this.UtilitiesService.getEncrypt(this.OrderID), this.lstUser[0]['CountryCode']);
        this.ReceiptURL = this.UtilitiesService.getKitchenReceiptURL(this.UtilitiesService.getEncrypt(this.UtilitiesService.getCompanyName()), this.UtilitiesService.getEncrypt(this.OrderID), this.lstUser[0][0]['CountryCode']);
        this.checkIframeLoaded();
      }
    }
    else {
      // this.ReceiptURL = this.UtilitiesService.getReceiptURL(this.UtilitiesService.getEncrypt(this.UtilitiesService.getCompanyName()), this.UtilitiesService.getEncrypt(this.OrderID), this.lstUser[0]['CountryCode']);
      this.ReceiptURL = this.UtilitiesService.getKitchenReceiptURL(this.UtilitiesService.getEncrypt(this.UtilitiesService.getCompanyName()), this.UtilitiesService.getEncrypt(this.OrderID), this.lstUser[0][0]['CountryCode']);
      this.checkIframeLoaded();
    }
    // }
    // else
    // {
    //   this.CanPrintReceipt = true;
    // }
  }
  validationPrintReceipt: boolean = false;
  ReceiptRePrintClick() {
    debugger
    this.validationPrintReceipt = false;
    if (this.lstPrinterserversetting.length > 0) {
      if (this.lstPrinterserversetting[0]['IsReceiptDirectPrint'] == 2) {

        if (this.ObjOrderMaster.IsMaxPolicyOn) {
          if (this.ObjOrderMaster.MaxReceiptPrintOut > this.ObjOrderMaster.ReceiptPrintOutCount) {
            let link = this.lstPrinterserversetting[0]['ServerLink'] + '/Default?O=' + this.OrderID + '&C=' + this.UtilitiesService.getSubscriptionID() + '&type=ReceiptREPRINT' + '&OutletID=' + this.ObjOrderMaster.OutletId + '&ShiftID=' + this.ObjOrderMaster.ShiftID;
            this.win = window.open(link, '_blank', 'width=300,height=200');
            this.ObjOrderMaster.ReceiptPrintOutCount = parseInt(this.ObjOrderMaster.ReceiptPrintOutCount) + 1;

          }
          else {
            this.validationPrintReceipt = true;
          }
        }
        else {
          let link = this.lstPrinterserversetting[0]['ServerLink'] + '/Default?O=' + this.OrderID + '&C=' + this.UtilitiesService.getSubscriptionID() + '&type=ReceiptREPRINT' + '&OutletID=' + this.ObjOrderMaster.OutletId + '&ShiftID=' + this.ObjOrderMaster.ShiftID;
          this.win = window.open(link, '_blank', 'width=300,height=200');
        }


      }
      if (this.lstPrinterserversetting[0]['IsReceiptDirectPrint'] == 3) {
        // this.ReceiptURL = this.UtilitiesService.getReceiptURL(this.UtilitiesService.getEncrypt(this.UtilitiesService.getCompanyName()), this.UtilitiesService.getEncrypt(this.OrderID), this.lstUser[0][0]['CountryCode']);
        this.ReceiptURL = this.UtilitiesService.getDigiPosReceiptURL(this.UtilitiesService.getEncrypt(this.UtilitiesService.getCompanyName()), this.UtilitiesService.getEncrypt(this.OrderID), this.lstUser[0][0]['CountryCode']);
        this.checkIframeLoaded();
      }
      if (this.lstPrinterserversetting[0]['IsReceiptDirectPrint'] == 4) {

        const url = this.UtilitiesService.geta4SizeReport(this.UtilitiesService.getEncrypt(this.UtilitiesService.getCompanyName()), this.UtilitiesService.getEncrypt(this.OrderID));
        this.ReceiptURL = url;
        this.checkIframeLoaded();


      }


    } else {
      // this.ReceiptURL = this.UtilitiesService.getReceiptURL(this.UtilitiesService.getEncrypt(this.UtilitiesService.getCompanyName()), this.UtilitiesService.getEncrypt(this.OrderID), this.lstUser[0][0]['CountryCode']);
      this.ReceiptURL = this.UtilitiesService.getDigiPosReceiptURL(this.UtilitiesService.getEncrypt(this.UtilitiesService.getCompanyName()), this.UtilitiesService.getEncrypt(this.OrderID), this.lstUser[0][0]['CountryCode']);
      this.checkIframeLoaded();
    }


  }

  async afterClosedMethod(result) {
    debugger
    if (result[0].IsReceiptDirectPrint == 4) {

      const url = this.UtilitiesService.geta4SizeReport(this.UtilitiesService.getEncrypt(this.UtilitiesService.getCompanyName()), this.UtilitiesService.getEncrypt(result[0].OrderID));
      this.ReceiptURL = url;
      this.checkIframeLoaded();
      // this.checkIframeLoaded();
    }
    if (result[0].IsReceiptDirectPrint == 3) {
      if (result[0].IsKOTDirectPrint == 2 && this.IsDirectPrint == true) {
        let link = result[0].ServerLink + '/Default?O=' + result[0].OrderID + '&C=' + result[0].CompanyId + '&type=CANCELORDERPRINT' + '&OutletID=' + this.ObjOrderMaster.OutletId + '&ShiftID=' + this.ObjOrderMaster.ShiftID + '&POSID=' + this.lstUser[0][0]['CountryCode'];
        this.win = window.open(link, '_blank', 'width=300,height=200');
        this.ReceiptURL = this.UtilitiesService.getDigiPosReceiptURL(this.UtilitiesService.getEncrypt(this.UtilitiesService.getCompanyName()), this.UtilitiesService.getEncrypt(this.OrderID), this.lstUser[0][0]['CountryCode']);
      }
      // this.ReceiptURL = this.Utilities.getReceiptURL(this.Utilities.getEncrypt(this.Utilities.getCompanyName()), this.Utilities.getEncrypt(sessionStorage.getItem('orderid')), this.CountryCode);
      this.ReceiptURL = this.UtilitiesService.getDigiPosReceiptURL(this.UtilitiesService.getEncrypt(this.UtilitiesService.getCompanyName()), this.UtilitiesService.getEncrypt(this.OrderID), this.lstUser[0][0]['CountryCode']);
      this.checkIframeLoaded();
    }
    else if (result[0].IsReceiptDirectPrint == 2) {
      if (this.ObjOrderMaster.IsMaxPolicyOn) {
        if (this.ObjOrderMaster.ReceiptPrintOutCount == undefined || this.ObjOrderMaster.ReceiptPrintOutCount == null) { this.ObjOrderMaster.ReceiptPrintOutCount = 0; }
        if (this.ObjOrderMaster.MaxReceiptPrintOut > this.ObjOrderMaster.ReceiptPrintOutCount) {
          let link = result[0].ServerLink + '/Default?O=' + result[0].OrderID + '&C=' + result[0].CompanyId + '&type=RP' + '&OutletID=' + this.ObjOrderMaster.OutletId + '&ShiftID=' + this.ObjOrderMaster.ShiftID + '&AddnDeleteItems=' + false + '&POSID=' + this.CounterID;
          this.win = await window.open(link, '_blank', 'width=300,height=200');
          this.ReceiptPrintOutCount = this.ReceiptPrintOutCount + 1;
        }
        else {
          this.validationPrintReceipt = true;
        }
      }
      else {
        let link = result[0].ServerLink + '/Default?O=' + result[0].OrderID + '&C=' + result[0].CompanyId + '&type=RP' + '&OutletID=' + this.ObjOrderMaster.OutletId + '&ShiftID=' + this.ObjOrderMaster.ShiftID + '&AddnDeleteItems=' + false + '&POSID=' + this.CounterID;
        this.win = await window.open(link, '_blank', 'width=300,height=200');
      }

    }
  

  }

  CancelOrderClickPrint() {
    debugger
    this.validationPrintReceipt = false;
    if (this.lstPrinterserversetting.length > 0) {
      if (this.lstPrinterserversetting[0]['IsReceiptDirectPrint'] == 2) {

        if (this.ObjOrderMaster.IsMaxPolicyOn) {
          if (this.ObjOrderMaster.MaxReceiptPrintOut > this.ObjOrderMaster.ReceiptPrintOutCount) {
            let link = this.lstPrinterserversetting[0]['ServerLink'] + '/Default?O=' + this.OrderID + '&C=' + this.UtilitiesService.getSubscriptionID() + '&type=ReceiptREPRINT' + '&OutletID=' + this.ObjOrderMaster.OutletId + '&ShiftID=' + this.ObjOrderMaster.ShiftID;
            this.win = window.open(link, '_blank', 'width=300,height=200');
            this.ObjOrderMaster.ReceiptPrintOutCount = parseInt(this.ObjOrderMaster.ReceiptPrintOutCount) + 1;

          }
          else {
            this.validationPrintReceipt = true;
          }
        }
        else {
          let link = this.lstPrinterserversetting[0]['ServerLink'] + '/Default?O=' + this.OrderID + '&C=' + this.UtilitiesService.getSubscriptionID() + '&type=ReceiptREPRINT' + '&OutletID=' + this.ObjOrderMaster.OutletId + '&ShiftID=' + this.ObjOrderMaster.ShiftID;
          this.win = window.open(link, '_blank', 'width=300,height=200');
        }


      }
      if (this.lstPrinterserversetting[0]['IsReceiptDirectPrint'] == 3) {
        // this.ReceiptURL = this.UtilitiesService.getReceiptURL(this.UtilitiesService.getEncrypt(this.UtilitiesService.getCompanyName()), this.UtilitiesService.getEncrypt(this.OrderID), this.lstUser[0][0]['CountryCode']);
        this.ReceiptURL = this.UtilitiesService.getDigiPosReceiptURL(this.UtilitiesService.getEncrypt(this.UtilitiesService.getCompanyName()), this.UtilitiesService.getEncrypt(this.OrderID), this.lstUser[0][0]['CountryCode']);
        this.checkIframeLoaded();
      }
      if (this.lstPrinterserversetting[0]['IsReceiptDirectPrint'] == 4) {

        const url = this.UtilitiesService.geta4SizeReport(this.UtilitiesService.getEncrypt(this.UtilitiesService.getCompanyName()), this.UtilitiesService.getEncrypt(this.OrderID));
        this.ReceiptURL = url;
        this.checkIframeLoaded();


      }


    } else {
      // this.ReceiptURL = this.UtilitiesService.getReceiptURL(this.UtilitiesService.getEncrypt(this.UtilitiesService.getCompanyName()), this.UtilitiesService.getEncrypt(this.OrderID), this.lstUser[0][0]['CountryCode']);
      this.ReceiptURL = this.UtilitiesService.getDigiPosReceiptURL(this.UtilitiesService.getEncrypt(this.UtilitiesService.getCompanyName()), this.UtilitiesService.getEncrypt(this.OrderID), this.lstUser[0][0]['CountryCode']);
      this.checkIframeLoaded();
    }
  }
  async checkIframeLoaded() {
    //// Get a handle to the iframe element
    //var iframe = document.getElementById('orderreceipt') as HTMLIFrameElement;

    //if (iframe) {
    //  try {
    //    var iframeDoc = iframe.contentDocument || iframe.contentWindow.document;

    //    // Check if loading is complete
    //    if (iframeDoc.readyState === 'complete' || iframeDoc.readyState === 'interactive') {
    //      // The loading is complete, call the function we want executed once the iframe is loaded
    //      await this.onLoad();
    //      return false;
    //    } else {
    //      // If not loaded, set up an event listener for the 'load' event
    //      iframe.addEventListener('load', async () => {
    //        await this.onLoad();
    //      });
    //    }
    //  } catch (error) {
    //    console.error("Error accessing iframe content:", error);
    //  }
    //} else {
    //  console.error("Iframe with id 'printf' not found.");
    //}

    //// If we are here, it is not loaded. Set things up so we check the status again in 100 milliseconds
    //// You might want to uncomment the following line if you want to keep checking
    //// window.setTimeout(() => this.checkIframeLoaded(), 100);
  }
  //async checkIframeLoaded() {
  //  debugger
  //  // Get a handle to the iframe element
  //  var iframe = document.getElementById('orderreceipt') as HTMLIFrameElement;
  //  var iframeDoc = iframe.contentDocument || iframe.contentWindow.document;

  //  // Check if loading is complete
  //  if (iframeDoc.readyState == 'complete') {
  //    //iframe.contentWindow.alert("Hello");
  //    //await iframe.contentWindow.print();
  //    // The loading is complete, call the function we want executed once the iframe is loaded
  //    await this.onLoad();
  //    return false;
  //  }

  //  // If we are here, it is not loaded. Set things up so we check   the status again in 100 milliseconds
  //  //window.setTimeout(this.checkIframeLoaded, 100);
  //}
  onLoad() {
    let timeout = this.UtilitiesService.GetTimeOut();
    if (this.ReceiptURL != '') {
      setTimeout(() => {
      window.frames["orderreceipt"].focus();
      window.frames["orderreceipt"].print();
       }, timeout);

    }
  }
  ReviewClick(event) {
    debugger

    this.OrderID = event.OrderID;
    this.getorderdetails(event.OrderID)
    // this.dialogRef.close(event.OrderID);



  }
  SelectOrder(): void {
    debugger
    // if( this.ObjOrderMaster.OrderType == 'Dine In' || this.ObjOrderMaster.UserId == this.UtilitiesService.getUserId())
    if (this.ObjOrderMaster.OrderType == 'Dine In' && this.chkAllow || this.ObjOrderMaster.UserId == this.UtilitiesService.getUserId()) {
      if (this.OrderID != undefined && this.OrderID != null && this.OrderID != '') {
        if (this.ObjOrderMaster.OtherChannel == 'Deliverect' && this.ObjOrderMaster.DeliverectIsOrderAccepted != 1)
          this.UpdateDeliverectOrderAcceptedStatus();
        this.dialogRef.close(this.OrderID);
      }
      else {
        alert('Please select Order first.');
      }
    }
    else if (this.ObjOrderMaster.OrderType != 'Dine In') {
      if (this.OrderID != undefined && this.OrderID != null && this.OrderID != '') {
        if (this.ObjOrderMaster.OtherChannel == 'Deliverect' && this.ObjOrderMaster.DeliverectIsOrderAccepted != 1)
          this.UpdateDeliverectOrderAcceptedStatus();
        this.dialogRef.close(this.OrderID);
      }
      else {
        alert('Please select Order first.');
      }
    }
    else {
      alert('You can not update this order, please contact your admin.')
    }

  }

  UpdateDeliverectOrderAcceptedStatus() {
    this.dataservice.get('pos/UpdateDeliverectOrderAcceptedColumn/' + this.OrderID + '/' + this.ObjOrderMaster.DeliverectOrderId + '/' + this.ObjOrderMaster.OrderInvoiceNo).subscribe(data => {
      debugger;
      if (data == "SUCCESS") {
        debugger
      }
    });
  }

  CancelOrder(): void {
    if (this.OrderID != undefined && this.OrderID != null && this.OrderID != '') {
      // var body = [this.UtilitiesService.getSubscriptionID(), this.OrderID];


      //  this.dataservice.post('pos/CancelOrder/', body).subscribe(data => 
      // {

      //   if(data == 'Update')
      //   {
      //     this.OrderID = '';
      //     this.lstBasket = [];
      //     this.lstSelectModifier = [];
      //     this.popup.ShowPopup('Update')
      //     this.LoadGrid();
      //   }
      //   else if(data == 'Error')
      //   {

      //   }

      // });
      this.ObjOrderMaster.OrderPerpStatus = 'Cancel';
      this.ObjOrderMaster.Channel = 'POSRestaurants';
      debugger
      let data = this.ObjOrderMaster;

      let dialogpopup = this.dialog.open(RejectPopupComponent, {
        width: '700px',

        data: { data },
      })

      dialogpopup.afterClosed().subscribe(result => {
        debugger

        if (result != undefined && result != 'SUCCESS') {
          this.popup.ShowPopup('Update')
          this.LoadGrid();
        }
        if (result == 'SUCCESS') {
          //this.dialogRef.close(result);
          //this.CancelOrderClickPrint();
          this.afterClosedMethod(this.lstPrinterserversetting);
          this.LoadGrid();
        }
      });

    }
    else {
      alert('Please select order first.');
    }
  }
  async GetAccessToken() {
    const headers = { 'accept': 'application/json', 'content-type': 'application/json' };
    const body = `{\"audience\": \"https://api.staging.deliverect.com\",\"grant_type\": \"token\",\"client_id\": \"5aaCR7XQeWX1Oejy\",\"client_secret\": \"wZVaEJ9dKPi0A7TmKUQjorAwHCJEBR9I\"}`;
    await this.http.post<any>('https://api.staging.deliverect.com/oauth/token', body, { headers }).subscribe(data => {
      debugger
      this.barertoken = data["access_token"];
      //this.UpdateOrderStatus();
    });

  }
  async UpdateOrderStatus() {
    /*POST REQUEST*/
    const headers = { 'accept': 'application/json', 'content-type': 'application/json', 'authorization': 'Bearer ' + this.barertoken };
    debugger
    const body = "{\"orderId\":\"" + this.ObjOrderMaster.DeliverectOrderId + "\",\"status\":110,\"reason\":\"cancellation\",\"timeStamp\":\"2019-09-05 07:44:15.000Z\",\"receiptId\":0}";
    await this.http.post<any>('https://api.staging.deliverect.com/orderStatus/orderId', body, { headers }).subscribe(data => {
      console.log('updated data in delivercet' + data);
    });
  }
  //CancelOrder(): void {
  //  debugger
  //  this.dataservice.get('OrderList/CheckShiftStatus/' + this.ObjOrderMaster.ShiftID + '/' + this.UtilitiesService.getSubscriptionID())
  //    .subscribe(result => {
  //      debugger

  //      if (result[0].ShiftStatus[0].Column1 == 1) {
  //        alert(this.translate.transform('OrderCannotbeCanceledDuetoShiftAlreadyClosed'))
  //      }
  //      else {
  //        if (this.ObjOrderMaster.OrderID != undefined && this.ObjOrderMaster.OrderID != null && this.ObjOrderMaster.OrderID != '') {
  //          this.ObjOrderMaster.OrderPerpStatus = 'Cancel';
  //          this.ObjOrderMaster.Channel = 'POSRestaurants';

  //          let data = this.ObjOrderMaster;

  //          let dialogpopup = this.dialog.open(RejectPopupComponent, {
  //            width: '700px',

  //            data: { data },
  //          })

  //          dialogpopup.afterClosed().subscribe(result => {

  //            if (result != undefined) {
  //              this._popup.ShowPopup('Update')
  //              this.CancelOrderClickPrint();
  //              //  this.LoadGrid();
  //            }
  //          });

  //        }
  //        else {
  //          alert('Please select order first.');
  //        }
  //      }


  //    }
  //    )

  //}

  getorderdetails(orderid: any) {
    debugger

    this.dataservice.get('pos/GetOrdersdetails/' + this.UtilitiesService.getSubscriptionID() + '/' + orderid).subscribe(data => {
      debugger
      if (this.isArrayHasValue(data[0]['Ordersdetails'])) {
        this.lstBasket = data[0]['Ordersdetails'];
      }
      if (this.isArrayHasValue(data[0]['OrdersModifiers'])) {

        this.lstSelectModifier = [];

        let lst = data[0]['OrdersModifiers'];
        for (let i = 0; i < this.lstBasket.length; i++) {
          let row = lst.filter(t => t.ProductID == this.lstBasket[i].ProductID);
          this.lstSelectModifier.push(row);
        }
      }

    });
  }

  RefreshOrders(): void {

    this.LoadGrid();
  }

  OrderIDMaster: any;
  OrderIDIndex: any;
  chkDinerights: any = [];
  chkAllow: boolean = false;

  IsDirectPrint: boolean = true;

  getMasterDetailData(OrderID, index): void {

    this.OrderIDMaster = OrderID;
    this.OrderIDIndex = index;
    this.validationPrintReceipt = false;
    this.HighlightRow = index;
    this.ObjOrderMaster = new OrderMaster();
    this.OrderID = OrderID;
    this.lstPrinterserversetting = [];
    this.IsDirectPrint = true;

    this.dataservice.get('pos/GetPendingOrdersdetails/' + this.UtilitiesService.getSubscriptionID() + '/' + OrderID + '/' + localStorage.getItem('LanguageCulture' + this.UtilitiesService.getURLCompanyName()) + '/' + this.UtilitiesService.getUserId())
      .subscribe(data => {

        debugger
        if (data[0]['Master']) {
          this.ObjOrderMaster = data[0]['Master'][0];
          if (this.ObjOrderMaster.OtherChannel == 'Deliverect' && this.ObjOrderMaster.DeliverectIsOrderAccepted != 1)
            this.lblSelectOrder = this.translate.transform('AcceptOrder');
          else
            this.lblSelectOrder = this.translate.transform('SelectOrder');
        }

        if (this.isArrayHasValue(data[0]['Details'])) {
          this.ArrOrderDetail = data[0]['Details'];
          console.log(this.ArrOrderDetail)
        }
        else {
          this.ArrOrderDetail = [];
        }
        if (this.isArrayHasValue(data[0]['chkDinerights'])) {
          this.chkDinerights = data[0]['chkDinerights'];
          this.chkAllow = this.chkDinerights[0]['Allow'];
        }
        debugger
        if (this.isArrayHasValue(data[0]['PrintSetting'])) {
          this.lstPrinterserversetting = data[0]['PrintSetting'];
        }

        if (this.isArrayHasValue(data[0]['IsDirectPrint'])) {
          this.IsDirectPrint = data[0]['IsDirectPrint'][0].IsDirectPrint;
        }

        // this.getReceipt();

      }, error => alert('error'));
  }
  ReceiptURL: any = '';
  lstUser = [];
  getReceipt(): void {


    const CompanyName = this.UtilitiesService.getCompanyName();
    // const url = this.UtilitiesService.getReceiptURL(this.UtilitiesService.getEncrypt(CompanyName), this.UtilitiesService.getEncrypt(this.ObjOrderMaster.OrderID), this.lstUser[0][0]['CountryCode']);
    const url = this.UtilitiesService.getDigiPosReceiptURL(this.UtilitiesService.getEncrypt(this.UtilitiesService.getCompanyName()), this.UtilitiesService.getEncrypt(this.OrderID), this.lstUser[0][0]['CountryCode']);
    this.ReceiptURL = url;
  }


  isArrayHasValue(obj: any[]): boolean {
    let isArrayWithLength = Boolean(Array.isArray(obj) && obj.length);
    return isArrayWithLength;
  }

  OnSalesTypeChange() {
    debugger
    if (this.SearchInListSalesType == 0) {
      this.lstarray = this.lstarray_Duplicate;
    } else {
      this.lstarray = this.lstarray_Duplicate.filter((option) => {
        return option.OrderType.toLowerCase().includes(this.SearchInListSalesType.toLowerCase());
      });
    }
  }

}
class OrderMaster {
  OrderID: any;
  TableNumber: any;
  OrderInvoiceNo: any = '';
  PrepIn: any = '';
  PrepInOutlet: any = '';
  OrderPerpStatus: any = 'ALL';
  Brand: any = '';
  OutletID: any = '';
  OutletId: any = '';
  OutletName: any = '';
  OrderType: any = '';
  Address: any = '';
  CustomerID: any = '';
  CustomerName: any = '';
  CustomerEmail: any = '';
  CountryCode: any = '';
  PhoneNo: any = '';
  PayType: any = '';
  Channel: any = '';
  PickupFrom: any = '';
  Remarks: any = '';
  SubTotal: any = '';
  TotalTaxAmount: any = '';
  TotalAmount: any = '';
  DeliveryFee: any = '';
  NetAmount: any = '';
  TotalDiscount: any = '';
  TaxID: any = '';
  TaxDescription: any = '';
  TaxPercent: any = '';
  OrderDate: any;
  OrderDateReal: any;
  CreatedOn: any;
  CreatedBy: any;
  ModifyOn: any;
  ModifyBy: any;
  RejectReasonID: any;
  RejectReason: any;
  RiderID: any;
  CompanyId: any;
  RejectReasonDetail: any = '';
  AddressPK: any = '';
  ShiftID: any;
  IsExtraCharge: any;

  MaxReceiptPrintOut: any;
  MaxKitchenReceiptPrintOut: any;
  ReceiptPrintOutCount: any;
  KitchenReceiptPrintOutCount: any;
  IsMaxPolicyOn: boolean;
  DeliverectOrderId: any;
  OtherChannel: any;
  UserId: any;
  DeliverectIsOrderAccepted: any;
  DeliverectIsOrderCancel: any;

}
class OrderDetail {
  OrderDetailID: any;
  OrderID: any;
  ProductId: any;
  ProductName: any = '';
  Quantity: any;
  UnitID: any;
  AddOnID: any;
  AddOnName: any;
}

class Company {
  CompanyID: any;
  CompanyName: any;
  outletsCount: any;
  CountryID: any;
  hearbout: any;
  Mobile: any;
  CurrencyCode: any;
  Rate: any;
  NumOfDecimal: any;
  CountryCode: any;
  IsOTPRequired: boolean;
}
