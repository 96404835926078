import { Injectable} from '@angular/core';
import { Component } from '@angular/core';
import {
    NbComponentStatus,
    NbGlobalLogicalPosition,
    NbGlobalPhysicalPosition,
    NbGlobalPosition,
    NbToastrService,
    NbToastrConfig,
  } from '@nebular/theme';

  @Injectable()
  export class PopupService {
    constructor(private toastrService: NbToastrService  ) { }

    config: NbToastrConfig;

  index = 1;
  destroyByClick = true;
  duration = 2000;
  hasIcon = true;
  position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
  preventDuplicates = false;
  status: NbComponentStatus = 'success';

  title = 'Record Created Successfully!';
  content = ``;

  types: NbComponentStatus[] = [
    'primary',
    'success',
    'info',
    'warning',
    'danger',
  ];
  positions: string[] = [
    NbGlobalPhysicalPosition.TOP_RIGHT,
    NbGlobalPhysicalPosition.TOP_LEFT,
    NbGlobalPhysicalPosition.BOTTOM_LEFT,
    NbGlobalPhysicalPosition.BOTTOM_RIGHT,
    NbGlobalLogicalPosition.TOP_END,
    NbGlobalLogicalPosition.TOP_START,
    NbGlobalLogicalPosition.BOTTOM_END,
    NbGlobalLogicalPosition.BOTTOM_START,
  ];


  ShowPopup(msgType: any) {
    let msg = '';
    let Status = '';

    if (msgType == 'Insert') {
        msg = 'Record Created Successfully!';
        Status = 'success';
    }
    if (msgType == 'Update') {
        msg = 'Record Updated Successfully!';
        Status = 'success';
    }
    if (msgType == 'Delete') {
        msg = 'Record Deleted Successfully!';
        Status = 'success';
    }
    if (msgType == 'Duplicate') {
      msg = 'Record Already exists!';
      Status = 'danger';
    }
    if (msgType == 'Error') {
      msg = 'Error | Record not updated!';
      Status = 'danger';
    }
    if (msgType == 'Copy') {
      msg = 'Code copied!';
      Status = 'success';
    }
    if (msgType == 'Email') {
      msg = 'Email sent successfully!';
      Status = 'success';
    }
    this.showToast(Status, msg, '');
  }

    ShowPopupCustom(Status: any, msg: any) {
      this.showToast(Status, msg, '');
    }
  private showToast(type: any, title: string, body: string) {
    const config = {
      status: type,
      destroyByClick: this.destroyByClick,
      duration: this.duration,
      hasIcon: this.hasIcon,
      position: this.position,
      preventDuplicates: this.preventDuplicates,
    };
    const titleContent = title ? ` ${title}` : '';

    this.index += 1;
    this.toastrService.show(
      body,
      `${titleContent}`,
      config);
  }
  }
