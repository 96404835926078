import { Component } from '@angular/core';
import { NbMenuService, NbMenuItem } from '@nebular/theme';
import { DataserviseService } from '../shared/dataservice';
import { NbLayoutDirection, NbLayoutDirectionService } from '@nebular/theme';
import { UtilitiesService } from 'app/shared/utilities.service';
// import { MENU_ITEMS } from './pages-menu';

@Component({
  selector: 'ngx-pages',
  styleUrls: ['pages.component.scss'],
  template: `
    <ngx-one-column-layout>
      <nb-menu [items]="menuItemsArr"></nb-menu>
      <router-outlet></router-outlet>
    </ngx-one-column-layout>
  `,
})
export class PagesComponent {
  lstMenu = [];
  lstModules = [];
  UserActiveinNoofBranch = [];
  // menu = MENU_ITEMS;
  // menu = [];
  // PosMenu  = [];
  newMenuItem: NbMenuItem[] = [];
  menuItemsArr = [];
  constructor(private directionService: NbLayoutDirectionService, private _userService: DataserviseService, private _utility: UtilitiesService) { }
  lstUser = [];
  ngOnInit(): void {
 /*   debugger;*/
    if (JSON.parse(localStorage.getItem('sessionObject' + this._utility.getURLCompanyName())) != null) {
      this.lstUser = JSON.parse(localStorage.getItem('sessionObject' + this._utility.getURLCompanyName()))[0];

    const dummyArr = this.lstUser;
    const code = dummyArr[0].Code;
    let lang;


    if (localStorage.getItem('LanguageCulture' + this._utility.getURLCompanyName()) == 'en')
    lang = 'en';
    else
    lang = 'ar';

    const oppositeDirection = lang == 'en' ? NbLayoutDirection.LTR : NbLayoutDirection.RTL;
    this.directionService.setDirection(oppositeDirection);

        this._userService.get('Users/CheckMenuRights/' + code + '/' + lang).subscribe(data => {
          this.newMenuItem = [];
          this.lstMenu = data[0].Menus;
          sessionStorage.setItem('FormRights', JSON.stringify(data[0].Menus));
          this.lstModules = data[0].Modules;
          const res =  this.lstModules.find(obj => obj.ModuleCode === 'POS');
          if ( res != undefined && res != null &&  res.ModuleCode == 'POS') {
            sessionStorage.setItem('POSRights', JSON.stringify('True') );
          } else {
            sessionStorage.setItem('POSRights', JSON.stringify('False') );
          }
          this.UserActiveinNoofBranch = data[0].UserActiveinNoofBranch;
          localStorage.setItem('UserActiveinNoofBranch' + this._utility.getURLCompanyName(), JSON.stringify(this.UserActiveinNoofBranch) );
          this.newMenuItem = this.lstModules.map(topik => {
            return {
              title: topik.EntityName,
              icon: topik.Icon,
              link: topik.Path,

              children: this.lstMenu.filter(lesson => lesson.ModuleCode == topik.ModuleCode && lesson.IsParent == 0 ).map( lesson =>  {
                  return {
                    title: lesson.EntityName ,
                    link: lesson.EntityCode == 'posRetailPOS' ? '/' + lesson.Path : '/' + dummyArr[0].CompanyName + lesson.Path,
                  };
              }),
            };
          });

          for (let i = 0; i < this.newMenuItem.length; i++) {
            if (this.newMenuItem[i].children.length > 0) {
              this.menuItemsArr[i] = {title: this.newMenuItem[i].title, icon: this.newMenuItem[i].icon,
                link: this.newMenuItem[i].link, pathMatch: 'full', children: this.newMenuItem[i].children};
            } else {
              this.menuItemsArr[i] = {title: this.newMenuItem[i].title,
                link: '/' + dummyArr[0].CompanyName + this.newMenuItem[i].link, icon: this.newMenuItem[i].icon};
            }

          }
          // console.log(this.menuItemsArr)
          // this.nbMenuService.addItems(this.menuItemsArr);
        });


      }
}
}
