<!-- <div class="child1" style="position: absolute;">Search Customer</div>
<div class="child1" style="margin-left: 52%;">
  <img src="./assets/images/lookupclose.png" (click)="btnclose()" style="float: right; width: 25px;cursor: pointer;">
</div> -->
<!-- <div class="child1" >Search Customer</div> -->
<div style="display: block;padding-top: 0px;">
  <div class="child1" style="max-width: none;">{{CateringFoodManagement}} <img src="./assets/images/lookupclose.png" class="cross-icon" style="float: right;cursor: pointer;margin-top: -5px;" (click)="btnclose()" /></div>
</div>
<hr style="margin-top: 10px;" />
<!-- <div class="row"> -->
<div class="row">

</div>
<div class="row">
  <div class="col-md-12">
    <div class="row" style="padding-bottom:5px">
      <div class="col-md-3 col-sm-6">
        <label class="label">{{Name}}</label>
        <input nbInput fullWidth [(ngModel)]="ObjGridFilterData.Name">
      </div>
      <div class="col-md-3 col-sm-6">
        <label class="label">Mobile Number</label>
        <div>
          <input nbInput fullWidth [(ngModel)]="ObjGridFilterData.PhoneNo">
          <i class="far fa-keyboard" (click)="NumberKeypadPopup()" style="cursor: pointer;cursor: pointer;padding-right: 10px;position: relative;float: right;margin-top: -30px;font-size: 22px;"></i>
        </div>
      </div>
      <div class="col-md-3 col-sm-6">
        <label class="label">&nbsp;</label>
        <button class="btn btnfinish" (click)="getGridData()">{{Search}}</button>
      </div>
    </div>
    <div class="table-responsive" style="max-height: 42vh;">
      <ng2-smart-table [settings]="settings" [source]="lstCustomerData" (delete)="onDelete($event)" (edit)="onEditRowSelect($event)" (userRowSelect)="onUserRowSelect($event)">
      </ng2-smart-table>
    </div>
  </div>
 
</div>
<!-- <div  style="padding-left: 0px;padding-right: 0px;border: 1px solid  #e5e5e5;;"></div> -->
<br />
<div class="row">
  <div class="col-md-12">
    <div class="child1">{{AddUpdateBooking}}</div>
    <hr style="margin-top: 5px;" />
 
    <form class="flex-grow flex flex-col">
      <div class="row">
        <div class="col-md-3 col-sm-12 ">
          <div class="form-group">
            <label class="label">{{CustomerName}}</label>
            <input type="text" fullWidth nbInput [(ngModel)]="Objclscateringfood.CustomerName" name="CustomerName"/>
            <p class="caption status-danger" *ngIf="valcustid">{{Pleaseentercustomername}}</p>
          </div>
        </div>
        <div class="col-md-3 col-sm-12 ">
          <div class="form-group">
            <label class="label">{{EventName}}</label>
            <input type="text" fullWidth nbInput name="LName" [(ngModel)]="Objclscateringfood.EventName" />
            <p class="caption status-danger" *ngIf="valeventname">{{Pleaseentereventname}}</p>

          </div>
        </div>
        <div class="col-md-3 col-sm-12 ">
          <div class="form-group">
            <label class="label">{{EventLocation}}</label>
            <input type="text" maxlength="15" fullWidth nbInput name="MNo" [(ngModel)]="Objclscateringfood.EventLocation" />
            <p class="caption status-danger" *ngIf="valeventlocation">{{pleaseentereventlocation}}</p>
          </div>
        </div>
        <div class="col-md-3 col-sm-12 ">
          <div class="form-group">
            <label class="label">{{EventMap}}</label>
            <input type="text" maxlength="15" fullWidth nbInput name="MNo" [(ngModel)]="Objclscateringfood.EventMap" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3 col-sm-12 ">
          <div class="form-group">
            <label class="label">{{BookingDate}}</label>
            <input type="text" fullWidth nbInput [(ngModel)]="Objclscateringfood.BookingDate" name="FName" />
            <p class="caption status-danger" *ngIf="valBookingDate">{{pleaseenterbookingdate}}</p>
          </div>
        </div>
        <div class="col-md-3 col-sm-12 ">
          <div class="form-group">
            <label class="label">{{DeliveryDate}}</label>
            <input type="text" fullWidth nbInput name="LName" [(ngModel)]="Objclscateringfood.DeliveryDate" />
            <p class="caption status-danger" *ngIf="valDeliveryDate">{{pleaseenterdeliverydate}}</p>

          </div>
        </div>
        <div class="col-md-3 col-sm-12 ">
          <div class="form-group">
            <label class="label">{{DeliveryTime}}</label>
            <input type="text" maxlength="15" fullWidth nbInput name="DeliveryTime" [(ngModel)]="Objclscateringfood.DeliveryTime"  />
          </div>
        </div>
        <div class="col-md-3 col-sm-12 ">
          <div class="form-group">
            <label class="label">{{ExpectedPeople}}</label>
            <input type="text" maxlength="15" fullWidth nbInput name="expectedPeople" [(ngModel)]="Objclscateringfood.ExpectedPeople" (keypress)="numberOnly($event)" onpaste="return false" ondrop="return false;" />
          </div>
        </div>
      </div>


      <div class="row">
        <div class="col-md-3 col-sm-12 ">
          <div class="form-group">
            <label class="label">{{EventDiscription}}</label>
        <input type="text" fullWidth nbInput [(ngModel)]="Objclscateringfood.EventDiscription" name="Description"/>
      </div>
        </div>
        <div class="col-md-3 col-sm-12 ">
          <div class="form-group">
            <label class="label">{{BugetAmount}}</label>
            <input type="text" fullWidth nbInput name="Company" [(ngModel)]="Objclscateringfood.BugetAmount" (keypress)="numberOnly($event)" onpaste="return false" ondrop="return false;" />
            <p class="caption status-danger" *ngIf="valBugetAmount">{{pleaseenterbudgetamount}}</p>
          </div>
        </div>

        <div class="col-md-3 col-sm-12 ">
       
        </div>
        <div class="col-md-3 col-sm-12 " style="text-align: right;padding-top: 15px;">
         
        </div>

      </div>
      <div class="row">
        <div class="col-md-3 col-sm-12 ">
         
        </div>
        <div class="col-md-3 col-sm-12 ">
        
        </div>

        <div class="col-md-3 col-sm-12 ">
        
        </div>
        <div class="col-md-3 col-sm-12 " style="text-align: right;padding-top: 15px;">
          <button class="btnfinish" (click)="btnSave()">{{buttonSave}}</button>&nbsp;&nbsp;
          <button class="btnfinish" (click)="btnclose()">{{Cancel}}</button>
        </div>

      </div>



    </form>



  </div>

</div>



