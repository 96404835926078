
<div id="invoice-POS" class="invoice-POS">

  <div id="top">
    <div class="logo" [ngStyle]="{'background': 'url(' + this.ObjCompanyInfo.LogoImageName + ')','height':'140px'}"></div>

  </div>

  <div id="mid">
    <div class="info" *ngIf="ObjOrderMaster.OutletName != 'N/A'">
      <div class="hd">{{ObjOrderMaster.OutletName}}</div>
      <div class="hd" style="text-align: center;">{{ObjOrderMaster.ArabicOutletName}}</div>
      <div class="mdatal">{{ObjOrderMaster.outletAddress}}</div>
      <div class="mdatal"></div>
      <div class="mdatal">{{ObjOrderMaster.AreaName}}</div>
      <div class="mdatal" *ngIf="ObjCompanyInfo.VATNo != 'N/A'">الرقم الضريبي / VAT No. : {{ObjCompanyInfo.VATNo}}</div>
      <div class="mdatal" *ngIf="ObjOrderMaster.OutletPhone != 'N/A'">Tel : {{ObjOrderMaster.OutletPhone}}</div>
    </div>


    <div class="info" style="border-top: 2px dotted #0e0d0d;border-bottom: 2px dotted #0e0d0d">
      <div class="dlvy">إشعار دائن</div>
      <div class="dlvy">Credit Note</div>

    </div>
    <!--<div class="info" style="border-top: 2px dotted #0e0d0d;border-bottom: 2px dotted #0e0d0d">

      <div class="dlvy" *ngIf="ObjOrderMaster.OrderNo" > Order No.: {{ObjOrderMaster.OrderNo}}   رقم الطلب</div>
    </div>-->

    <div class="info" style="border-top: 2px dotted #0e0d0d;border-bottom: 2px dotted #0e0d0d">
      <!--<div class="dlvy" *ngIf="ObjOrderMaster.OrderType == 'Delivery'">Delivery</div>
  <div class="dlvy" *ngIf="ObjOrderMaster.OrderType == 'Takeaway' || ObjOrderMaster.OrderType == 'Pickup'">Take Away - Pickup</div>-->
      <!--<div class="dlvy" *ngIf="ObjOrderMaster.OrderType != 'Dine In'">{{ObjOrderMaster.OrderType}}</div>

      <div *ngIf="ObjOrderMaster.CustomerName != 'N/A' && ObjOrderMaster.OrderType != 'Dine In'">
        <div class="mdatal" style="font-weight: 700">{{ObjOrderMaster.CustomerName}}
      </div>
        <div class="mdatal">{{ObjOrderMaster.PhoneNo}}</div>

        <div class="mdatal" *ngIf="ObjCurrency.CountryName != 'Pakistan'">Address: {{ObjOrderMaster.Address}} {{ObjOrderMaster.AdditionalDirection == 'N/A' || ObjOrderMaster.AdditionalDirection == '' ? '' : ' , ' +ObjOrderMaster.AdditionalDirection}}</div>
        <div style="border-bottom: 2px dotted #0e0d0d;" class="mdatal" *ngIf="ObjCurrency.CountryName == 'Pakistan'">Address: {{ObjOrderMaster.AddressPK}}</div>
      </div>-->

      <!--<div class="info" *ngIf="ObjOrderMaster.OrderType == 'Dine In' && ObjOrderMaster.TableNumber != 0">
        <div class="dlvy">Dine In</div>
        <div class="dlvy">Table No.</div>
        <div class="mdatal" style="font-weight: 700">{{ObjOrderMaster.TableNumber}}</div>


      </div>-->

    </div>



    <div style="border-bottom: 2px dotted #0e0d0d">
      <!--<div class="mdatal" *ngIf="ObjOrderMaster.DeliverectChannelOrderDisplayId != 'N/A' && ObjOrderMaster.DeliverectChannelOrderDisplayId != ''">Channel Order Id :&nbsp; {{ObjOrderMaster.DeliverectChannelOrderDisplayId}}</div>
  <div class="mdatal" *ngIf="ObjOrderMaster.DeliverectChannelOrderDisplayId != 'N/A' && ObjOrderMaster.DeliverectChannelOrderDisplayId != ''">Channel :&nbsp; {{ObjOrderMaster.DeliverectChannel}}</div>-->
      <div class="mdatal">Credit Note No. / رقم إشعار  الدائن &nbsp;  &nbsp;&nbsp; <!--<span *ngIf="ObjOrderMaster.OrderType == 'Dine In' && ObjOrderMaster.TableNumber != 0">Cust# :&nbsp; 001</span>--></div>
      <div class="mdatal" style="text-indent: 10px;">{{ObjOrderMaster.ReturnInvoice}}</div>
      <div class="mdatal">Credit Note Date / تاريخ إشعار دائن &nbsp; </div>
      <div class="mdatal" style="text-indent: 10px;">{{ObjOrderMaster.OrderDate}} , {{ObjOrderMaster.OrderTime}}</div>
      <!--<div class="mdatal">Payment :&nbsp; {{ObjOrderMaster.PayType}}  &nbsp;&nbsp; &nbsp;&nbsp; <span *ngIf="ObjOrderMaster.OrderType == 'Dine In' && ObjOrderMaster.TableNumber != 0"> Serv# :&nbsp; W001 </span></div>-->

      <div class="mdatal" *ngIf="ObjOrderMaster.Cashier != 'N/A'">Cashier / أمين الصندوق &nbsp;</div>
      <div class="mdatal" style="text-indent: 10px;" *ngIf="ObjOrderMaster.Cashier != 'N/A'">{{ObjOrderMaster.Cashier}}</div>
      <div class="mdatal">Invoice Ref No. / رقم الفاتورة &nbsp;</div>
      <div class="mdatal" style="text-indent: 10px;">{{ObjOrderMaster.OrderInvoiceNo}}</div>
    </div>



  </div>

  <div id="bot">


    <div id="table">
      <table>
        <thead>
          <tr>
            <th>Description</th>
            <th>Qty</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of ArrOrderDetail; let i = index" [ngClass]="row.AddOnID != '0' ? 'borderDoted' : 'borderDoted'">
            <td *ngIf="row.AddOnID == '0'">{{row.ProductName}} {{r(row.ProductSizeName === null || row.ProductSizeName.trim() === '' || row.ProductSizeName === 'N/A') ? '' : '(' + row.ProductSizeName +')'}}  <br /> <span style="text-indent: 10px">{{row.AProductName}} {{row.ArabicProductSizeName == 'N/A' ? '' : '(' + row.ArabicProductSizeName +')'}}</span> <br /> <span *ngIf="row.SpecialRequest" style="text-indent: 10px"><b>Note: </b>{{row.SpecialRequest}}</span></td>
            <!-- <td style="text-indent: 10px">arabic</td> -->
            <td *ngIf="row.AddOnID != '0'" style="text-indent: 10px">{{row.AddOnName}}</td>
            <td *ngIf="row.AddOnID != '0'" ></td>
            <td *ngIf="row.AddOnID == '0'">{{row.Quantity}}</td>
            <td class="amountcls">{{row.OrignalAmount|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</td>
          </tr>

        </tbody>
        <tfoot>
          <tr>
            <td colspan="1">Sub Total <br><span>المجموع الفرعي</span></td>
            <td style="text-align:right !important">{{CurrencyCode}}</td>
            <td style="text-align:right !important">{{ObjOrderMaster.TotalAmount|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</td>
          </tr>
          <tr *ngIf="ObjOrderMaster.IsExtraCharge == 1">
            <td colspan="1">{{ObjOrderMaster.ExtraChargeDescription}}<br><span>{{ObjOrderMaster.ExtraChargeDescriptionArabic}}</span></td>
            <td style="text-align:right !important">{{CurrencyCode}}</td>
            <td style="text-align:right !important">{{ObjOrderMaster.ExtraChargeAmount|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</td>
          </tr>
          <ng-container *ngIf="ArrVoucherData?.length > 0">
            <tr *ngFor="let d of ArrVoucherData;">
              <td colspan="1">
                Discount /تخفيض : <!--Voucher [{{d.VoucherCode}}]-->
               <!-- <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="promo-code__icon" style="width: 13px; height: 18px;"><path d="M497.941 225.941L286.059 14.059A48 48 0 00252.118 0H48C21.49 0 0 21.49 0 48v204.118a48 48 0 0014.059 33.941l211.882 211.882c18.745 18.745 49.137 18.746 67.882 0l204.118-204.118c18.745-18.745 18.745-49.137 0-67.882zm-22.627 45.255L271.196 475.314c-6.243 6.243-16.375 6.253-22.627 0L36.686 263.431A15.895 15.895 0 0132 252.117V48c0-8.822 7.178-16 16-16h204.118c4.274 0 8.292 1.664 11.314 4.686l211.882 211.882c6.238 6.239 6.238 16.39 0 22.628zM144 124c11.028 0 20 8.972 20 20s-8.972 20-20 20-20-8.970-20 8.970 20-20m0-28c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48z"></path></svg>-->
              </td>
              <td style="text-align:right !important">{{CurrencyCode}}</td>
              <td style="text-align:right !important">(-{{d.VoucherAmount|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}})</td>
            </tr>
          </ng-container>
          <!--<tr *ngIf="ObjOrderMaster.TotalDiscount">
            <td colspan="1">Discount <br> <span>تخفيض</span></td>
            <td style="text-align:right !important">{{CurrencyCode}}</td>
            <td style="text-align:right !important">(-{{ObjOrderMaster.TotalDiscount|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}})</td>
          </tr>-->
          <!-- <tr>
            <td colspan="1">Discount</td>
            <td style="text-align:right !important">{{CurrencyCode}}</td>
            <td style="text-align:right !important">(-{{ObjOrderMaster.TotalDiscount|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}})</td>
          </tr> -->

          <tr *ngIf="ObjOrderMaster.LoyaltyAmount > 0">
            <td colspan="1">Discount (Reward Points)</td>
            <td style="text-align:right !important">{{CurrencyCode}}</td>
            <td style="text-align:right !important">(-{{ObjOrderMaster.LoyaltyAmount|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}})</td>
          </tr>

          <tr>
            <td colspan="1">VAT 15% <br> <span>الضريبة المضافة</span></td>
            <td style="text-align:right !important">{{CurrencyCode}}</td>
            <td style="text-align:right !important">{{ObjOrderMaster.TotalTaxAmount|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</td>
          </tr>
          <tr *ngIf="ObjOrderMaster.OrderType == 'Delivery' && ObjOrderMaster.DeliveryFee > 0">
            <td colspan="1">Delivery Fee</td>
            <td style="text-align:right !important">{{CurrencyCode}}</td>
            <td style="text-align:right !important">{{ObjOrderMaster.DeliveryFee|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</td>
          </tr>
      
          <tr>
            <!-- <td colspan="1">Amount</td> -->
            <td colspan="1">Net Amount <br><span>صافي المبلغ</span></td>
            <td style="text-align:right !important">{{CurrencyCode}}</td>
            <td style="text-align:right !important">{{ObjOrderMaster.NetAmount|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</td>
          </tr>

        </tfoot>
      </table>
      <div *ngIf="ObjOrderMaster.Remarks != 'N/A' && ObjOrderMaster.Remarks != ''">
        <br />
        <div style="text-decoration:underline"><strong>Special Instructions</strong> </div>
        <div style="text-indent:5px">{{ObjOrderMaster.Remarks}}</div>
      </div>
      <!--paymentdetails-->
      <br />
      <div *ngIf="ObjOrderMaster.OrderPayStatus != 'Pending'">
        <table style="display:none!important">
          <tr>
            <td style="width: 10% !important;">طريقةالدفع</td>
            <td style="width: 65% !important;"></td>
            <td style="width: 25% !important;">Paid By</td>
          </tr>
        </table>

        <table style=" border: 1px solid black; border-bottom:2px solid !important">

          <tr>
            <td><strong>{{ObjOrderMaster.PaidBy}}</strong></td>
            <td style="text-align:right !important">{{CurrencyCode}}</td>
            <td>{{ObjOrderMaster.NetAmount|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</td>
          </tr>
        </table>
        <table *ngIf="ReturnAmount > 0">
          <tr>
            <td style="width: 30% !important;">المبلغ المسترد</td>
            <td style="width: 20% !important;"></td>
            <td style="width: 30% !important;">Cash returned</td>
          </tr>
        </table>
        <table *ngIf="ReturnAmount  > 0" style=" border: 1px solid black; border-bottom:2px solid !important">
          <tr>
            <td><strong>Return Amount</strong></td>
            <td style="text-align:right !important">{{CurrencyCode}}</td>
            <td>{{ReturnAmount|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</td>
          </tr>
        </table>
        <!--Tip Info-->
        <table *ngIf="ObjOrderMaster.TIPAmount >0  && ActivateTipAmount == 1">
          <tr>
            <td style="width: 30% !important;">المبلغ الإكرامية</td>
            <td style="width: 20% !important;"></td>
            <td style="width: 30% !important;">TIP Amount</td>
          </tr>
        </table>
        <table *ngIf="ObjOrderMaster.TIPAmount >0 && ActivateTipAmount == 1" style=" border: 1px solid black; border-bottom:2px solid !important">
          <tr>
            <td><strong>TIP Amount</strong></td>
            <td style="text-align:right !important">{{CurrencyCode}}</td>
            <td>{{ObjOrderMaster.TIPAmount|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</td>
          </tr>
        </table>
      </div>
    </div>
    <div id="legalcopy">
      <p class="legal" style="text-align:center;font-size:20px" *ngIf="ObjCompanyInfo.ReceiptMsg != 'N/A' && ObjCompanyInfo.ReceiptMsg != ''">
        <strong class="">{{ObjCompanyInfo.ReceiptMsg}}</strong><br />
      </p>

      
    </div>
    <div class="info" *ngIf="ObjOrderMaster.IsQRVisible">
      <ngx-qrcode [elementType]="elementType"
                  [value]="QRUrl"
                  cssClass="aclass"
                  errorCorrectionLevel="L">
      </ngx-qrcode>
    </div>
<br />
    <div id="NOtpaid" *ngIf="ObjOrderMaster.OrderPayStatus == 'Pending'">
      <p class="legal" style="text-align:center;font-size:28px" >
        <strong class="">**** Not Paid / لم تدفع  ****</strong><br />
      </p>

    </div>
    <div id="legalcopy">

      <button class="label clsB1 doNotPrint" style="border:0" (click)="printComponent()">Print order</button>
    </div>

  </div>
</div>
