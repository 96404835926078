<!--<div class="header-container">
  <div class="logo-container" style="width:unset!important">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo HideOnMobile" href="#" (click)="navigateHome()">digidiner - <span>Back Office</span></a>
  </div>

</div>-->
<div class="box fullSize">
  <div class="boxHeader">
    <div class="trial-banner trial-banner__exp_free" *ngIf="IsTrailExpired">
      <div class="trial-banner__days-left">
       <span >Your subscription will be expired on {{this.ObjSubscriptionPaymentDetail.EndDate}}, kindly contact the support team!</span>
      </div>

    </div>
  </div>
  <div class="boxContent">
    <div class="box fullSize">
      <div class="boxHeader" style="display:flex">

        <div class="header-container" style="width:86%">
          <div class="mobileShow">
            <a (click)="toggleSidebar()" href="#" class="sidebar-toggle" style="padding-right: 0.25rem!important;">
              <nb-icon icon="menu-2-outline" style="color:#05a9e6! important"></nb-icon>
            </a>
          </div>
          <div class="logo-container" style="width:unset!important;">
            <div style="padding-left:5px;color:#2C4370; background-color: #F9ECE3; font-size: 12px; border-radius: 5px; padding: 5px;">
              {{Welcome}}, {{sessionObject?.Name}} <span *ngIf="DisplaySelectedBranchName" (click)="clickBrnachName()"> | {{SelectedBranchName}}</span>
            </div>
          </div>

        </div>

        <div class="header-container" style="display: grid !important;">


          <nb-actions size="small">

            <!--<nb-action class="control-item">
              <nb-search type="rotate-layout"></nb-search>
            </nb-action>
            <nb-action class="control-item" icon="email-outline"></nb-action>
            <nb-action class="control-item" icon="bell-outline"></nb-action>-->


            <nb-action class="user-action" *nbIsGranted="['view', 'sessionObject']">
              <nb-user [nbContextMenu]="userMenu"
                       [onlyPicture]="userPictureOnly"
                       [name]="sessionObject?.Code"
                       [picture]="user?.picture">
              </nb-user>
            </nb-action>

          </nb-actions>
        </div>

      </div>
    </div>
  </div>
</div>


