import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA,MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'ngx-number-popup',
  templateUrl: './number-popup.component.html',
  styleUrls: ['./number-popup.component.scss']
})
export class NumberPopupComponent implements OnInit {
  txtbox_NoOfCustomers:any = '';
  TableName:any;
  eventValue:any;
  enterValue:boolean = false;
  constructor(public dialog: MatDialog, public dialogRef: MatDialogRef<NumberPopupComponent>, @Inject(MAT_DIALOG_DATA) public parentdata: any) 
{}

  ngOnInit(): void {
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  calcButtonclick(value){debugger
    if(value == 'AC' || value == ""){
    this.txtbox_NoOfCustomers = '';
    return
    }
    else{
        this.txtbox_NoOfCustomers = this.txtbox_NoOfCustomers + value;  
        this.txtbox_NoOfCustomers = this.txtbox_NoOfCustomers.substring(0,25);
    }
  }
  ClickDecimal(): void {debugger
    var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

    if (!format.test(this.txtbox_NoOfCustomers)) {
      this.txtbox_NoOfCustomers = this.txtbox_NoOfCustomers + '.';
    }

  }

  removeNumber(){
    this.txtbox_NoOfCustomers = this.txtbox_NoOfCustomers.slice(0, -1);
  }
  inputValueChange(value){
    debugger
    // value = parseFloat(value)
    if(value == 'AC' || value == ""){
    this.txtbox_NoOfCustomers = '';
    return
    }
    else{
    
    }
  }

  close(){
    this.dialogRef.close();
  }
  ok(){debugger
    if(this.txtbox_NoOfCustomers){
      this.enterValue = false;
    this.dialogRef.close(this.txtbox_NoOfCustomers);
    }
    else{
      this.enterValue = true;
      return
    }
  }

}
