import { Component, OnInit,Inject } from '@angular/core';
import { DataserviseService } from '../../shared/dataservice';
import { PopupService } from '../../shared/popup';
import { UtilitiesService } from '../../shared/utilities.service';
import { SpinnerService } from '../../shared/spinner';
import { OrderListPopupComponent } from '../../pages/ordermanagements/orderlistpopup/orderlistpopup.component';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {TranslatePipe} from '../../shared/pipe/translate.pipe';
import { DecimalPipe } from '@angular/common';
import { SaleReturnOrderComponent } from '../salereturnorderpopuop/salereturnorderpopuop.component';
import { SaleReturnItemComponent } from '../salereturnitempopup/salereturnitempopup.component';
import { SaleReturnOrderReturnComponent } from '../salereturnorderreturnpopuop/salereturnorderreturnpopuop.component';
import { LocalDataSource } from 'ng2-smart-table';
import { escapeLeadingUnderscores } from 'typescript';
import { DeliveryPopupComponent } from '../delivery-popup/delivery-popup.component';
import { UrlSafePipe } from '../../admin/pipe/urlsafe';
import { AlertPopupComponent } from '../../../app/admin/alert-popup/alert-popup.component'
import { HttpClient } from '@angular/common/http';
import { DinetablePopupComponent } from '../dinetable-popup/dinetable-popup.component';
import { NumberPopupComponent } from '../pos_popup/number-popup/number-popup.component';


@Component({
  selector: 'ngx-updateorderpopup',
  templateUrl: './updateorderpopup.component.html',
  styleUrls: ['./updateorderpopup.component.scss']
})
export class UpdateorderpopupComponent implements OnInit {

  SaleReturn: any = this.translate.transform('SaleReturn');
  ReturnInvoicNo: any = this.translate.transform('CreditNoteNo');
  ReturnDate: any = this.translate.transform('CreditNoteDate');
  PleaseSelectDate: any = this.translate.transform('PleaseSelectDate');
  OrderNo: any = this.translate.transform('OrderNo');
  Selectorderno: any = this.translate.transform('Selectorderno');
  SelectCustomer: any = this.translate.transform('Pleaseselectcustomer');
  OrderDateTime: any = this.translate.transform('OrderDateTime');
  OrderType: any = this.translate.transform('OrderType');
  PayType: any = this.translate.transform('PayType');
  OrderAmount: any = this.translate.transform('OrderAmount');
  Customer: any = this.translate.transform('Customer');
  PhoneNo: any = this.translate.transform('PhoneNo');
  Cashier: any = this.translate.transform('Cashier');
  ReturnReason: any = this.translate.transform('ReturnReason');
  Description: any = this.translate.transform('Description');
  Items: any = this.translate.transform('Items');
  Action: any = this.translate.transform('Action');
  ItemDescription: any = this.translate.transform('ItemDescription');
  RefundPaymentType: any = this.translate.transform('RefundPaymentType');
  ReturnQty: any = this.translate.transform('ReturnQty');
  Price: any = this.translate.transform('Price');
  Amount: any = this.translate.transform('Amount');
  ReferenceCode: any = this.translate.transform('ReferenceCode');
  SubTotal: any = this.translate.transform('SubTotal');
  Creditissuenote: any = this.translate.transform('Creditissuenote');
  Selectatleastoneitem: any = this.translate.transform('Selectatleastoneitem');
  OrderQty: any = this.translate.transform('OrderQty');
  Vat: any = this.translate.transform('VAT');
  NetAmount: any = this.translate.transform('NetAmount');
  Save: any = this.translate.transform('Save');
  Cancel: any = this.translate.transform('Cancel');

  NoOfCustomer: any = this.translate.transform('Noofcustomers');
  SalesType: any = this.translate.transform('SalesType');
  PayMethod: any = this.translate.transform('PayMethod');
  Update: any = this.translate.transform('Update');
  Status: any = this.translate.transform('Status');
  
  source: LocalDataSource;

  ObjSaleReturnMaster: SaleReturnMaster = new SaleReturnMaster();
  ObjSaleReturnDetail: SaleReturnDetail = new SaleReturnDetail();

  ArrSaleReturnDetail: SaleReturnDetail[] = [];
  ArrSaleReturnDetailSaving: SaleReturnDetail[] = [];

  ObjCountry: Country = new Country();
  ObjSearchFilters: SearchFilters = new SearchFilters();

  RetDate_Mandatory: boolean = false;
  OrderId_Mandatory: boolean = false;
 Customer_Mandatory: boolean = false;
 OrderType_Mandatory: boolean = false;
  // Items_Mandatory: boolean = false;
  CurrencyCode: any;

  DayID: any;
  ShiftID: any;

  showPerPage = 5;
  settings = {
    actions: {
      columnTitle: '',
      add: false,
      edit: false,
    },
    pager: {
      display: true,
      perPage: this.showPerPage,
    },
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
      confirmDelete: true
    },
    filtering: { filterString: '' },
    columns: {

      ProductName: {
        title: this.translate.transform('ProductName'),
      },
      Quantity: {
        title: this.translate.transform('Quantity'),
      },
      ReturnQty: {
        title: this.translate.transform('ReturnQty'),
      },
      Price: {
        title: this.translate.transform('Price'),
      },
      TotalAmount: {
        title: this.translate.transform('TotalAmount'),
      },
      //TotalAmount: {
      //  title: this.translate.transform('TotalAmount'),
      //  type: 'html',
      //  valuePrepareFunction: (value, row, cell) => {
      //    return this._DecimalPipe.transform(cell.row.data.TotalAmount, '1.' + this.ObjCountry.NumOfDecimal + '-' + this.ObjCountry.NumOfDecimal);
      //  }, 
      //},
    
    },
    attr: {
      class: 'table table-bordered handicursor',
    },
    hideSubHeader: false,
  };

  CompanyId: any;
  CreatedBy: any = '';
  CreatedOn: any = '';
  lstDetail: any = [];

  companyName:any;
  CountryCode: any;
  POSID: any;
  listOrderTypes=[];
  culture:any;
  selectedOutletID:any;
  lstPaymentDetail=[];

  constructor( private translate : TranslatePipe,public dialogRef: MatDialogRef<OrderListPopupComponent>  , @Inject(MAT_DIALOG_DATA) public parentData: any, public dialog: MatDialog, private _spinnerService: SpinnerService, private Utilities: UtilitiesService, private dataservice: DataserviseService, private popup: PopupService,private _DecimalPipe:DecimalPipe) { }

  ngOnInit(): void {
    debugger
    this.ReceiptURL= '';
    this.CompanyId = this.Utilities.getSubscriptionID();
    this.CreatedBy = this.Utilities.getUserId();
    this.CreatedOn = this.Utilities.GetCurrentDateTime();
    this.ObjSearchFilters.OutletId = this.parentData.OutletId;
    this.ObjOutletInfo = this.parentData.ObjOutletInfo;
    this.NumOfDecimal = this.parentData.NumOfDecimal;
    this.CurrencyCode = this.parentData.CurrencyCode;
    this.listOrderTypes = this.parentData.listOrderType;
    this.culture = this.parentData.culture;
    this.selectedOutletID = this.parentData.outletID;
    this.companyName = JSON.parse(localStorage.getItem('counterID&CompanyID' + this.Utilities.getURLCompanyName()))[0].CompanyName;
    this.CountryCode = JSON.parse(localStorage.getItem('counterID&CompanyID' + this.companyName))[0].CountryCode;
    this.POSID = JSON.parse(localStorage.getItem('counterID&CompanyID' + this.Utilities.getURLCompanyName()))[0].CounterID

    this.DayID = this.parentData.DayID;
    this.ShiftID = this.parentData.ShiftID;
    //this.getGridData();
    this.GetNewTransactionNo();
    this.get_paymentmodes();
    this.GetDropdownSetups();
    this.ObjSaleReturnMaster.ReturnID = 0;
    // this.ObjSaleReturnMaster.ReturnDate = this.Utilities.GetCurrentDateTime();
  }


  ddlRejectReasons: any = [];

  GetDropdownSetups(): void {

    this.dataservice.get('LiveOrder/GetSetupData/' + 'G')
      .subscribe(data => {

        if (this.isArrayHasValue(data[0]['RejectReasons'])) {
          this.ddlRejectReasons = data[0]['RejectReasons'];
          this.ObjSaleReturnMaster.Reason = this.ddlRejectReasons[0].RejectReasonID;
        }
      }, error => alert('error'));

  }
  Pram_PaymentMode_Refund: boolean = true;
  Pram_PaymentMode_CIssNote: boolean = false;
  Changetg1(value) {
    debugger;
    if (value)
      this.ObjSaleReturnMaster.PaymentMode = 1;
    else
      this.ObjSaleReturnMaster.PaymentMode = 2;

    //this.ObjSaleReturnMaster.PaymentMode = 1;
    this.Pram_PaymentMode_Refund = value;
    this.Pram_PaymentMode_CIssNote = !value;
    this.vPaymentCode = this.lstPaymentMode[0].Code;
  }
  Changetg2(value) {
    debugger;
    if (value)
      this.ObjSaleReturnMaster.PaymentMode = 2;
    else
      this.ObjSaleReturnMaster.PaymentMode = 1;

    //this.ObjSaleReturnMaster.PaymentMode = 2;
    this.Pram_PaymentMode_CIssNote = value;
    this.Pram_PaymentMode_Refund = !value;
    this.vPaymentCode = undefined;
  }
  deleteDetailData(x): void {


    if (window.confirm('Are you sure you want to delete?')) {

      debugger
      let OrderDetailID;
      let ProductID;

      OrderDetailID = this.ArrSaleReturnDetailSaving[x].OrderDetailID;
      ProductID = this.ArrSaleReturnDetailSaving[x].ProductID;

      //for (var i = 0; i <= this.ArrSaleReturnDetailSaving.length; i++) {
      //  if (this.ArrSaleReturnDetailSaving[i].OrderDetailID === OrderDetailID) {
      //    this.ArrSaleReturnDetailSaving.splice(i, 1);
      //  } else if (this.ArrSaleReturnDetailSaving[i].AddOnID != 0 && this.ArrSaleReturnDetailSaving[i].ProductID == ProductID) {
      //    this.ArrSaleReturnDetailSaving.splice(i, 1);
      //  }
   
      //}

      this.ArrSaleReturnDetailSaving.filter(x => x.Row == OrderDetailID).forEach(x => this.ArrSaleReturnDetailSaving.splice(this.ArrSaleReturnDetailSaving.indexOf(x), 1));
      
    }

   

    this.SetSummary();
  }
  GetNewTransactionNo(): void {
    this.dataservice.get('pos/GenerateSaleRetunNo/' + this.ObjOutletInfo.BranchID + '/' + this.CompanyId)
      .subscribe(data => {
        if (data) {
          // this.trxNumber = data;
        }
      }, error => alert('error'));

  }

  openDialogCustomer(): void {
    this.Customer_Mandatory = false;
    let dialogRef = this.dialog.open(DeliveryPopupComponent, {
      width: '1150px',
      height: '600px',
      data: { outletID: this.ObjOutletInfo.BranchID }
    });

   
    dialogRef.afterClosed().subscribe(result => {
      debugger
      if (result != undefined) {
if(result[0]){
        let SelectedCutomer = result[0];
        this.ObjSaleReturnMaster.CustomerID = SelectedCutomer["custID"];
        this.ObjSaleReturnMaster.Customer = SelectedCutomer["custName"];
        this.ObjSaleReturnMaster.PhoneNo = SelectedCutomer["PhoneNo"];
}else{  
  this.ObjSaleReturnMaster.CustomerID = result["custID"];
        this.ObjSaleReturnMaster.Customer = result["custName"];
}
      }
    
      

    });
  }
  getdecimalAmount(val): any {
    let b: any = val;

    if (val!=undefined) {
      b = this._DecimalPipe.transform(parseFloat(val), '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal).replace(',', '');
    }
    return b;
   
  }
  // openDialogSaleReturn(): void {
    
  //   const dialogRef = this.dialog.open(SaleReturnOrderReturnComponent, {
  //     width: '1100px',
  //     height: '600px',
  //     data: { OutletId: this.ObjOutletInfo.BranchID, NumOfDecimal: this.NumOfDecimal }
  //   });

  //   dialogRef.afterClosed().subscribe(result => {
  //     if (result != undefined) {
 
  //         let dt = result.data;
  //         this.ObjSaleReturnMaster = new SaleReturnMaster();
  //       this.ObjSaleReturnMaster.ReturnID = dt.ReturnID;
  //       this.trxNumber = dt.ReturnInvoice;
  //         this.ObjSaleReturnMaster.CompanyId = dt.CompanyId;
  //         this.ObjSaleReturnMaster.Customer = dt.Customer;
  //         this.ObjSaleReturnMaster.CustomerID = dt.CustomerID;
  //         this.ObjSaleReturnMaster.OrderID = dt.OrderID;
  //         this.ObjSaleReturnMaster.PayType = dt.PayType;

        
  //       this.ObjSaleReturnMaster.PaymentMode = dt.PaymentMode;

        
  //       debugger

  //       if (this.ObjSaleReturnMaster.PaymentMode == 1) {
  //         let obj = this.lstPaymentMode.filter(x => x.Code == dt.PaymentCode);
  //         this.rdPaymentMethod = obj[0].PaymentName;
  //         this.vPaymentCode = dt.PaymentCode;
  //         this.ObjSaleReturnMaster.PaymentCode = dt.PaymentCode;

  //         this.Pram_PaymentMode_Refund = true;
  //         this.Pram_PaymentMode_CIssNote = false;
  //       }
  //       else if (this.ObjSaleReturnMaster.PaymentMode == 2) {
  //         this.Pram_PaymentMode_Refund = false;
  //         this.Pram_PaymentMode_CIssNote = true;
  //       }
    


          
  //         this.ObjSaleReturnMaster.PaymentReference = dt.PaymentReference;
  //         this.ObjSaleReturnMaster.PhoneNo = dt.PhoneNo;

  //         this.ObjSaleReturnMaster.Reason = parseInt( dt.Reason);
  //         this.ObjSaleReturnMaster.Remarks = dt.Remarks;
  //         this.ObjSaleReturnMaster.ReturnDate = dt.ReturnDate;
  //         this.ObjSaleReturnMaster.ReturnInvoice = dt.ReturnInvoice;
  //         this.ObjSaleReturnMaster.ReturnStatus = dt.ReturnStatus;

  //         this.ObjSaleReturnMaster.ShiftID = dt.ShiftID;
  //         this.ObjSaleReturnMaster.TaxID = dt.TaxID;
  //         this.ObjSaleReturnMaster.TotalAmount = dt.TotalAmount;
  //         this.ObjSaleReturnMaster.TotalTaxAmount = dt.TotalTaxAmount;
  //       this.ObjSaleReturnMaster.NetAmount = this.getdecimalAmount(dt.NetAmount);
  //       debugger
  //       this.ObjSaleReturnMaster.OrderDate = dt.OrderDate;
  //       this.ObjSaleReturnMaster.OrderAmount = this.getdecimalAmount(dt.OrderAmount);
  //       this.ObjSaleReturnMaster.OrderNo = dt.OrderInvoiceNo;
  //       this.ObjSaleReturnMaster.OrderType = dt.OrderType;
  //       this.ObjSaleReturnMaster.Cashier = dt.Cashier;

  //       this.getSaleReturnOrder();
  //     }

  //   });
  // }

  getSaleReturnOrder(): void {

    this.ArrSaleReturnDetailSaving = [];
    this.dataservice.get('pos/GetSaleReturnDetail/' + this.ObjSaleReturnMaster.ReturnID)
      .subscribe(Rdata => {

        debugger
        if (this.isArrayHasValue(Rdata[0]['SaleDetail'])) {
          this.ArrSaleReturnDetailSaving = Rdata[0]['SaleDetail'];
          //console.log(this.ArrSaleReturnDetailSaving)
        }
      }, error => alert('error'));

  }

  openDialogOrderkUp(): void {
    this.OrderId_Mandatory = false;
    const dialogRef = this.dialog.open(SaleReturnOrderComponent, {
      width: '1300px',
      height: '700px',
      data: { data: 0, OutletId: this.ObjOutletInfo.BranchID }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        debugger
        this.getOrder(result.data)
      }

    });
  }
  numberOnly(event, value): boolean {
    debugger
    const charCode = (event.which) ? event.which : event.keyCode;
    var eventKey = event.key;
    if (event.which == 46 && value.indexOf('.') != -1) {
      event.preventDefault();
    } // prevent if already dot
    else if (charCode == 46) {
      return true;
    }
    else if ((charCode < 48 || charCode > 57)) {
      return false;
    }
    else { return true; }
  }
  ArrayChange(index, row): void {
    debugger

    let res: any = 0;
    let resm: any = 0;

    if (row.ReturnQty == '') {
      return;
    }
    if (row.Quantity == '' || parseFloat(row.ReturnQty) == 0 || parseFloat(row.ReturnQty)>row.Quantity) {

      this.ArrSaleReturnDetailSaving[index].ReturnQty = parseFloat(row.Quantity);
      this.ArrSaleReturnDetailSaving[index] = Object.assign({}, row);
      return;
    }

    for (var i = 0; i < this.ArrSaleReturnDetailSaving.length; i++) {
      if (this.ArrSaleReturnDetailSaving[i].ProductID == row.ProductID && this.ArrSaleReturnDetailSaving[i].ProductSizeID == row.ProductSizeID) {
        res = parseFloat(this.ArrSaleReturnDetailSaving[index].Price) * parseFloat(this.ArrSaleReturnDetailSaving[index].ReturnQty);
        this.ArrSaleReturnDetailSaving[index].ReturnQty = row.ReturnQty;

        if (this.ArrSaleReturnDetailSaving[i].AddOnID != 0) {
          resm = parseFloat(this.returnzero(this.ArrSaleReturnDetailSaving[i].Price)) * parseFloat(row.ReturnQty);
          this.ArrSaleReturnDetailSaving[i].TotalAmount = resm;
        }
      } 
    }

    this.ArrSaleReturnDetailSaving[index].TotalAmount = res;
   

    this.SetSummary();
  }

  returnzero(val): any {
    let r: any = 0;
    if (val !=null && val != undefined && val != '') {
      r = val;
    }
    return r;
  }

  SetSummary(): void {
    debugger
    let SubTotal: any = 0;
    let TotalTax: any = 0;
    let NetAmount: any = 0;

    this.ObjSaleReturnMaster.TotalAmount = 0;
    this.ObjSaleReturnMaster.TotalTaxAmount = 0;
    this.ObjSaleReturnMaster.NetAmount = 0;

    for (var i = 0; i < this.ArrSaleReturnDetailSaving.length; i++) {
      SubTotal += parseFloat(this.returnzero(this.ArrSaleReturnDetailSaving[i].TotalAmount));
    }
    if (SubTotal)
      this.ObjSaleReturnMaster.TotalAmount = SubTotal;

    if (TotalTax)
      this.ObjSaleReturnMaster.TotalTaxAmount = TotalTax;

    NetAmount = SubTotal + TotalTax;

    if (NetAmount)
      this.ObjSaleReturnMaster.NetAmount = NetAmount;

    this.CalculateTax()
  }

  lstPaymentMode = [];
  ActivateTipAmount: any;
  rdPaymentMethod: any = 'Cash';
  moneyImg: string = './assets/images/money.PNG';

  vPaymentCode: any;
  get_paymentmodes() {
    this.dataservice.get('pos/GetPaymentModes/' + this.CompanyId + '/' + localStorage.getItem('LanguageCulture' + this.Utilities.getURLCompanyName()) + '/' + this.POSID + '/' + this.ObjOutletInfo.BranchID ).subscribe(data => {
      if (this.isArrayHasValue(data)) {
     
        this.lstPaymentMode = data;
        this.vPaymentCode = this.lstPaymentMode[0].Code;
        this.rdPaymentMethod = this.lstPaymentMode[0].PaymentName;
        this.ActivateTipAmount = this.lstPaymentMode[0].ActivateTipAmount;

      }
    })
  }
  PaymentTypeChangeFn(value) {
    debugger
    this.vPaymentCode = value.Code;
    this.rdPaymentMethod = value.PaymentName;
    this.ActivateTipAmount = value.ActivateTipAmount;
    //this.returnValue();
  }

  // openDialogItem(): void {
  //   if (this.ObjSaleReturnMaster.OrderID == undefined || this.ObjSaleReturnMaster.OrderID == '') {
  //     const dialogRef = this.dialog.open(AlertPopupComponent, {
  //       width: '300px',
  //       height: '200px',
  //     });
  //     return;
  //   }
  //   this.Items_Mandatory = false;
  //   const dialogRef = this.dialog.open(SaleReturnItemComponent, {
  //     width: '700px',
  //     height: '600px',
  //     data: { data: this.ObjSaleReturnMaster.OrderID, NumOfDecimal: this.NumOfDecimal, CurrencyCode: this.CurrencyCode }
  //   });

  //   dialogRef.afterClosed().subscribe(lst => {
  //     if (lst != undefined) {
  //       debugger

  //       let orderdetailid;

  //       for (var i = 0; i < lst.length; i++) {

  //         orderdetailid = lst[i].OrderDetailID;
  //         debugger
  //         if (this.ArrSaleReturnDetailSaving.some(({ OrderDetailID }) => OrderDetailID == orderdetailid) == false) { // check duplicate item
  //           this.ArrSaleReturnDetailSaving.push(lst[i]);            
  //             } else {
  //           alert('Item already exists.')
  //           break;
  //             }
  //       }
  //       this.SetSummary();
  //     }

  //   });
  // }

  SaveData(): void {
debugger
    this.RetDate_Mandatory = false;
    this.OrderId_Mandatory = false;
    // this.Items_Mandatory = false;
    this.Customer_Mandatory = false;
    this.OrderType_Mandatory = false;
    let flag = false;

    // if (this.ObjSaleReturnMaster.ReturnDate == undefined || this.ObjSaleReturnMaster.ReturnDate == '') {
    //   this.RetDate_Mandatory = true;
    //   flag = true;
    // }

    if (this.ObjSaleReturnMaster.OrderID == undefined || this.ObjSaleReturnMaster.OrderID == '') {
      this.OrderId_Mandatory = true;
      flag = true;
    }
    if (this.ObjSaleReturnMaster.OrderTypeID == undefined || this.ObjSaleReturnMaster.OrderTypeID == '' || this.ObjSaleReturnMaster.OrderTypeID == 0) {
      this.OrderType_Mandatory = true;
      flag = true;
    }
    // if (this.ArrSaleReturnDetailSaving.length < 1) {
    //   this.Items_Mandatory = true;
    //   flag = true;
    // }

    if (flag)
      return;

    // if (this.ArrSaleReturnDetailSaving.length > 0) {

    //   this.ObjSaleReturnMaster.CreatedBy = this.CreatedBy;
    //   this.ObjSaleReturnMaster.TaxID = this.ObjOutletInfo.TaxID;
    //   this.ObjSaleReturnMaster.DayID = this.DayID;
    //   this.ObjSaleReturnMaster.ShiftID = this.ShiftID;
    //   this.ObjSaleReturnMaster.PaymentCode = this.vPaymentCode;
    //   this.ObjSaleReturnMaster.CompanyId = this.CompanyId;
    //   this.ObjSaleReturnMaster.OutletId = this.ObjOutletInfo.BranchID;

    
    //  this.ObjSaleReturnMaster.ReturnDate = this.Utilities.GetCurrentDateTime();

      // var body = [this.ObjSaleReturnMaster.ReturnID, this.ObjSaleReturnMaster.OrderID, this.ObjSaleReturnMaster, this.ArrSaleReturnDetailSaving];
debugger
this.ObjSaleReturnMaster.CreatedBy = this.CreatedBy;
this.ObjSaleReturnMaster.CreatedOn = this.CreatedOn;
      this.dataservice.post('pos/UpdateOrderInfo/', this.ObjSaleReturnMaster)
        .subscribe(result => {
          if (result) {
            debugger
            
            if (result == 'SUCCESS') {
              this.popup.ShowPopup('Insert');
              this._spinnerService.hide();
              this.clear();
            }
            else {
              this._spinnerService.hide();
              alert('error');
            }
          }
          this._spinnerService.hide();
        }, error => alert('error'));
    
  }

  ReceiptURL: any = '';
  print(ReturnID) {
    this.ReceiptURL =this.Utilities.getSaleReturnReceiptURL(this.Utilities.getEncrypt(this.Utilities.getCompanyName()), this.Utilities.getEncrypt(ReturnID), this.CountryCode);
    console.log(this.ReceiptURL)
   
    this.checkIframeLoaded();

  }
  async checkIframeLoaded() {
    debugger
    // Get a handle to the iframe element
    //alert('1' + this.ReceiptURL);
    var iframe = document.getElementById('onpaysalereturn') as HTMLIFrameElement;
    var iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
  
    // Check if loading is complete
    if (iframeDoc.readyState == 'complete') {
     
      //iframe.contentWindow.alert("Hello");
      //await iframe.contentWindow.print();
      // The loading is complete, call the function we want executed once the iframe is loaded
      await this.onLoad();

      return false;
    }

    // If we are here, it is not loaded. Set things up so we check   the status again in 100 milliseconds
    window.setTimeout(this.checkIframeLoaded, 1000);
  }
  onLoad() {
    
    if (this.ReceiptURL!='') {
     // alert(this.ReceiptURL);
      setTimeout(() => {
        window.frames["onpaysalereturn"].focus();
        window.frames["onpaysalereturn"].print();
      }, 1800);

    }
  }
  InvalidDateFrom: boolean = false;
  InvalidDateTo: boolean = false;

  _DateFrom: Date = new Date();
  _DateTo: Date = new Date();

  getGridData(): void {
    this._spinnerService.show();

    this.ObjSearchFilters.CompanyId = this.Utilities.getSubscriptionID();
    this.ObjSearchFilters.Channel = 'POS';

    this.ObjSearchFilters.DateFrom = this.Utilities.getCalenderDate(this._DateFrom);
    this.ObjSearchFilters.DateTo = this.Utilities.getCalenderDate(this._DateTo);
    this.ObjSearchFilters.culture = sessionStorage.getItem('LanguageCulture');
    this.dataservice.post('pos/GetPaidOrders/', this.ObjSearchFilters )
      .subscribe(data => {debugger
        if (this.isArrayHasValue(data[0]['PaidOrders'])) {
          //this.ArrPaidOrders = data[0]['PaidOrders'];
        } else {
          //this.ArrPaidOrders = [];
        }
        this.ObjCountry = data[0]['Currency'][0];

        this._spinnerService.hide();
      }, error => alert('error'));

  }

  // trxNumber: any;
  getOrder(dt): void {
   
    debugger
    let reason = this.ObjSaleReturnMaster.Reason;
    this.ObjSaleReturnMaster = new SaleReturnMaster();
    // this.ArrSaleReturnDetail = [];
    this.ArrSaleReturnDetailSaving = [];
    // this.ObjSaleReturnMaster.ReturnInvoice = this.trxNumber;
    this.ObjSaleReturnMaster.Reason = reason;
    debugger
    this.ObjSaleReturnMaster.OrderID = dt.OrderID;
    this.dataservice.get('pos/getUpdateOrderInfo/' + this.ObjSaleReturnMaster.OrderID)
      .subscribe(Rdata => {
        if (this.isArrayHasValue(Rdata[0]['OrderMaster'])) {
          let data = Rdata[0]['OrderMaster'];
          debugger
          this.ObjSaleReturnMaster.OrderNo = data[0].OrderInvoiceNo;
          this.ObjSaleReturnMaster.OrderDate = data[0].OrderDate;
          this.ObjSaleReturnMaster.OrderTypeID = data[0].OrderTypeID;
          this.ObjSaleReturnMaster.OrderType = data[0].OrderType;
          this.ObjSaleReturnMaster.PayType = dt.PayType;
          this.ObjSaleReturnMaster.OrderPerpStatus = dt.OrderPerpStatus;
          this.ObjSaleReturnMaster.OrderAmount = data[0].NetAmount;
          this.ObjSaleReturnMaster.CustomerID = data[0].CustomerID;
          this.ObjSaleReturnMaster.Customer = data[0].Customer;
          this.ObjSaleReturnMaster.Cashier = data[0].Cashier;
          this.ObjSaleReturnMaster.PhoneNo = data[0].PhoneNo;
          this.ObjSaleReturnMaster.noOfCustomers = data[0].NoOfCustomers;
        }
        if (this.isArrayHasValue(Rdata[0]['PaymentDetail'])) {
          this.lstPaymentDetail = Rdata[0]['PaymentDetail'];
        }
      }, error => alert('error'));

  }

  ObjOutletInfo: any;
  NumOfDecimal: any;
  CalculateTax() {
    debugger 
    let A: number = 0;

    let amt: any = this.ObjSaleReturnMaster.TotalAmount;

   
    if (this.ArrSaleReturnDetailSaving.length > 0) {
      let Productssum;

      if (this.ObjOutletInfo.IsTaxInclusive == true) {

        if (this.ObjOutletInfo.IsTaxOnDiscountAmount == true) {
          A = (((this.ObjSaleReturnMaster.TotalAmount)) * 100) / (100 + this.ObjOutletInfo.TaxRate);
          this.ObjSaleReturnMaster.TotalTaxAmount = this._DecimalPipe.transform(((this.ObjSaleReturnMaster.TotalAmount) - A), '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal).replace(',', '');
          this.ObjSaleReturnMaster.TotalAmount = this._DecimalPipe.transform((A), '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal).replace(',', '');
          this.ObjSaleReturnMaster.NetAmount = this._DecimalPipe.transform(parseFloat(this.ObjSaleReturnMaster.TotalTaxAmount) + parseFloat(this.ObjSaleReturnMaster.TotalAmount), '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal).replace(',', '');
        }
        else {
          A = ((this.ObjSaleReturnMaster.TotalAmount) * 100) / (100 + this.ObjOutletInfo.TaxRate);
          this.ObjSaleReturnMaster.TotalTaxAmount = this._DecimalPipe.transform(((amt) - A), '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal).replace(',', '');
          this.ObjSaleReturnMaster.TotalAmount = this._DecimalPipe.transform((A), '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal).replace(',', '');
          this.ObjSaleReturnMaster.NetAmount = this._DecimalPipe.transform(parseFloat(this.ObjSaleReturnMaster.TotalTaxAmount) + parseFloat(this.ObjSaleReturnMaster.TotalAmount), '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal).replace(',', '');
          //this.ObjSaleReturnMaster.NetAmount = (this.ObjSaleReturnMaster.TotalTaxAmount + this.ObjSaleReturnMaster.TotalAmount);
        }

      }
      else {

        this.ObjSaleReturnMaster.TotalAmount = parseFloat(amt);

       
        this.ObjSaleReturnMaster.NetAmount = this._DecimalPipe.transform((this.ObjSaleReturnMaster.TotalAmount + this.ObjSaleReturnMaster.TotalTaxAmount), '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal).replace(',', '');
      }

      //this._decimalPipe.transform(this.Subtoal, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal).replace(',', '');

    }


  }


  clear(): void {
    this.ObjSaleReturnMaster = new SaleReturnMaster();
    this.ArrSaleReturnDetail = [];
    this.ArrSaleReturnDetailSaving = [];
    this.GetNewTransactionNo();

    //this.rdPaymentMethod = 'Cash';
    //this.ObjSaleReturnMaster.PaymentMode = 1;
    this.Pram_PaymentMode_Refund = true;
    this.Pram_PaymentMode_CIssNote = false;

    this.vPaymentCode = this.lstPaymentMode[0].Code;
    this.rdPaymentMethod = this.lstPaymentMode[0].PaymentName;
    this.ActivateTipAmount = this.lstPaymentMode[0].ActivateTipAmount;

    this.ObjSaleReturnMaster.Reason = this.ddlRejectReasons[0].RejectReasonID;
    // this.ObjSaleReturnMaster.ReturnDate = this.Utilities.GetCurrentDateTime();
  }

  isArrayHasValue(obj: any[]): boolean {
    let isArrayWithLength = Boolean(Array.isArray(obj) && obj.length);
    return isArrayWithLength;
  }

  onChangeType(event){debugger
    if (event == '1') {
      // this.ClearControlsAgain = true;
      this.openDineInTablePopup(event, 'Dine In');
    }
    else if (event == '3') {
      //this.OrderType = 'Delivery';
      this.openDialogCustomerLookUp();
      // if (this.ClearControlsAgain) {
        // this.clearclick();
      // }
      // this.ClearControlsAgain = !this.ClearControlsAgain;
    }
    else
    this.ObjSaleReturnMaster.OrderTypeID = event;
    this.ObjSaleReturnMaster.noOfCustomers = '';
        }
        onChangePaymentType(){

        }

        openDineInTablePopup(salesTypeId, SalesTypeName) {          debugger
          const dialogRef = this.dialog.open(DinetablePopupComponent, {
            width: '1300px',
            height: '600px',
      data: { culture: this.culture, outletID: this.selectedOutletID, IsDineTable: true, NumOfDecimal: this.NumOfDecimal, SalesTypeID: salesTypeId, SalesTypeName: SalesTypeName, DayID: this.DayID, POSID: this.POSID,IsTransferTable:true }
          });
      
      
          dialogRef.afterClosed().subscribe(result => {
            debugger;
            if (result != undefined) {
                // this.TableID = this.Dinedata["ID"]
                // this.TableName = this.Dinedata["TableName"]
                // this.noOfCustomers = this.result["noOfCustomers"];
                // this.OrderTypeID = 1;
                this.ObjSaleReturnMaster.OrderTypeID = salesTypeId;
                this.ObjSaleReturnMaster.noOfCustomers = result.noOfCustomers;

            }
            else {
              // this.OrderTypeID = 1;
              // this.OrderType = "Dine In";
            }
      
          });
        }
        openDialogCustomerLookUp() {

          let dialogRef = this.dialog.open(DeliveryPopupComponent, {
            width: '1150px',
            height: '650px',
            data: { outletID: this.ObjOutletInfo.BranchID }
          });
      
      
          dialogRef.afterClosed().subscribe(result => {
            debugger
            if (result != undefined && result[0] != undefined) {
              // this.custId = result[0].custID;
              // this.custName = result[0].custName;
              // this.CustomerAddressID = result[0].CustAddressID;
              // this.MobileNo = result[0].PhoneNo;
              // this.OrderType = 'Delivery';
            }
          });
        }

        CalcTextboxClick(Index, Amount, Calculated, OverShort, PaymentMode, PaymentCode) {
          debugger
          // this.selectedIndex = Index;
          //this.lstPaymentDetail[Index] = { Amount: Amount, Calculated: Calculated, OverShort: OverShort, PaymentMode: PaymentMode, PaymentCode: PaymentCode };
        }
        trackByFn(index, item) {
          return index;
        }
        removeitem(){

        }
        NumberKeypadPopup(){

          const dialogRef = this.dialog.open(NumberPopupComponent, {
            width: '400px',
            height: '430px',
            data: {},
            panelClass: 'my-custom-dialog-class'
          });
      
          dialogRef.afterClosed().subscribe(result => {
              debugger
            if (result) {
              debugger
              this.ObjSaleReturnMaster.noOfCustomers = result;
            }
      
          });
        }
}

class SaleReturnMaster {
  ReturnID: any;

  // ReturnInvoice: any;
  SaleID: any = 0;
  // ReturnDate: any;
  CustomerID: any;
  Customer: any;
  TaxID: any;
  TotalAmount: any = 0.00;
  TotalDiscount: any = 0;
  TotalTaxAmount: any = 0.00;
  NetAmount: any = 0.00;
  PaymentMode: any = 1;
  PaymentCode: any;
  PaymentReference: any;
  ShiftID: any;
  DayID: any;
  Remarks: any;
  Reason: any;
  ReturnStatus: any;
  Barcode: any;
  CreatedBy: any;
  CreatedOn: any;
  OutletId: any;
  CompanyId: any;

  OrderID: any;
  OrderNo: any;
  OrderTypeID: any;
  OrderType: any;
  PayType: any;
  OrderAmount: any;
  PhoneNo: any;
  Cashier: any;
  OrderDate: any;
  noOfCustomers: any;
  OrderPerpStatus:any;
}

class SaleReturnDetail {
  ReturnDetailID: any;
  OrderID: any;
  OrderDetailID: any;
  ReturnID: any;
  ProductID: any;
  UnitID: any;
  Quantity: any;
  ReturnQty: any;
  Price: any;
  TotalAmount: any;
  Barcode: any;
  Remarks: any;
  AddOnID: any;
  ProductSizeID: any;
  CompanyId: any;
  AddOnName: any;
  ArabicAddOnName: any;
  ProductSizeName: any;
  ArabicProductSizeName: any;
  Row: any;
}

class Country {
  CurrencyCode: any;
  Rate: any = '';
  NumOfDecimal: any = '';
  CountryName: any = '';
  CountryCode: any = '';
}

class SearchFilters {
  CompanyId: any;
  Channel: any = '';
  Id: any = '';
  PhoneNo: any = '';
  DateFrom: any = new Date();
  DateTo: any = new Date();
  culture : any;
  OutletId: any;
}

