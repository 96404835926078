<div class="container" style="overflow: hidden !important;">
  <!-- <img src="'../../../assets/images/globalinformix.png'" style="width: 100%; padding-bottom: 40px;"> -->
  <div style="text-align: center;">
    <img src="assets/images/globalPoslogo.png" style="width: 375px;margin-top: -25px;">
  </div>


  <!-- <h1 class="title">Login</h1> -->
  <!-- <div class="form-control-group">
      <label class="label" for="input-email">User ID</label>
      <input nbInput
             fullWidth
             name="email"
             id="input-email"
             pattern=".+@.+\..+"
             placeholder="Email address"
             fieldSize="large"
             autofocus>
    </div> -->
  <div style="margin-top: -30px;">
    <label for="bLocation" class="label">Language</label>
    <nb-select fullWidth (selectedChange)="changeLocation($event)" [(ngModel)]="DefaultLanguage" name="bLocation" style="width: 100%;">
      <nb-option value="ar">Arabic عربي</nb-option>
      <nb-option value="en">English</nb-option>
    </nb-select>
  </div>
  <br />
  <div>
    <label for="username" class="label">User Name</label>
    <input type="text" [(ngModel)]="objUser.Name" placeholder="User ID" (keydown.enter)="Login()" class="form-control" />
    <p class="caption status-danger" *ngIf="ShowlblUserName">
      User Name is required!
    </p>
    <!-- <p class="caption status-danger" >
      Email should be the real one!
    </p> -->
  </div>
  <br />
  <div>
    <label for="password" class="label">Password</label>
    <input type="password" [(ngModel)]="objUser.Password" (keydown.enter)="Login()" placeholder="Password" class="form-control" />
    <p class="caption status-danger" *ngIf="ShowlblPassword">
      Password is required!
    </p>
    <!-- <img style="margin-top: 5%;margin-left: 48%;" *ngIf="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" /> -->
    <p class="caption status-danger" *ngIf="showlblSubsActive"> Your service is Inactive please contact the support team! </p>
  </div>

  <p class="caption status-danger" *ngIf="showlblInvalid">
    User Name or Password is Invalid!
  </p>
  <br />
  <p class="caption status-danger" *ngIf="showlblSubsEndDate">
    Your subscription has expired, kindly contact the support team!
  </p>
  <br />

  <div>
    <button #loginID type="button" class="btnlogin" autofocus (click)="Login()">Login</button>
  </div><br />
  <p style=" text-align: center; font-weight: bold;">
    {{VersionNo}}
  </p>

</div>
