<nb-card>
    <nb-card-body >
      <div class="child1" style="max-width: 100% !important ;">  {{!IsDineTable ? 'Split Checks' : 'Pending Split Checks'}}
        <img src="./assets/images/lookupclose.png" (click)="btnclose()" style="float: right; width: 25px;cursor: pointer;">
      </div>
      
      <hr style="margin-top: 10px;"/>
      <!-- <div style="text-align: center;">
        <button nbButton status="success" (click)="AddNewChecks()" >Add New Checks</button>
      </div> -->

      <div class="row" *ngIf="!IsDineTable">
        <div class="col-lg-4 col-md-6 col-xs-4 col-sm-4">
          <button nbButton fullWidth status="success" (click)="CombineAllChecks()">Combine All Checks</button>
        </div>
        <!-- <div class="col-md-4">
          <button nbButton fullWidth status="success">Print All Receipts</button>
        </div> -->
        <div class="col-md-4">
         <button nbButton fullWidth status="success"  (click)="AddNewChecks()">Add New Checks</button>
       </div>
      </div>
      <div class="row col-lg-12 col-md-12 col-xs-12 col-sm-12" style="margin:0px ;padding:0px">
                
                <div  style="padding-right: 25px;padding-top: 25px;width: 327px;float: left;" *ngFor="let item of lstarrayMaster;">
                    <div class="card">
                        <div class="Headerbackgroung" style="min-height: 125px;">    
                            <div class="Card" style="background-color: #cef7ac;  height: 36px;">
                                <button style="background-color: #606467; color:white; width: 100%;height: 36px; outline: none; border-style: none;font-size: 20px;">
                                    <label style="margin:0px ;">Table #<span>{{item.TableNumber}}</span> </label>
                                    </button>
                             </div>   
                            <div style="font-size: 14px; font-weight: 600; color: white;"><label *ngIf="item.OrderNo" style="width: 60%; text-align: start; margin-left: 5px;">Order No. {{item.OrderNo}}</label><label *ngIf="item.OrderDifference" style="width: 38%;  ">{{item.OrderDifference}} ago</label>  </div>         
                            <div style="font-size: 14px; font-weight: 600; color: white;"><label *ngIf="item.OrderInvoiceNo" style="width: 60%; text-align: start; margin-left: 5px;">#{{item.OrderInvoiceNo}}</label><label style="width: 38%;  ">{{item.OrderDate}}</label>  </div>
                            <div style="font-size: 14px; font-weight: 600; color: white;"><label style="width: 60%;vertical-align: top;margin-left: 5px;">{{item.OrderType}}</label> <label style="width: 38%;  white-space: nowrap;overflow: hidden; text-overflow: ellipsis;  ">{{item.Name}}</label>  </div>
                        </div>
                        <div style="height:16rem; overflow-y: scroll;">
                            <div *ngFor="let itemdtl of lstarrayDetails">
                                <div *ngIf="item.OrderID == itemdtl.OrderID" style="border-bottom: 1px solid #e7e0e0;">
                                    <ul class="list-group list-group-flush" >
                                    <li  [ngClass]="[itemdtl.DelFlag == 1 ? 'padd_btm_background list-group-item' : 'list-group-item']" style="font-size: 13px; color: #434444; padding-top: 0px; padding-bottom: 0px;padding-left: 10px;padding-right: 5px;height: 28px;line-height: 28px;" *ngIf="itemdtl.AddOnID == 0" >
                                        <label>
                                            <span>{{itemdtl.Quantity}}x</span> &nbsp;&nbsp; 
                                            <span >{{itemdtl.ProductName}}</span> &nbsp;&nbsp; 
                                            <!-- <span  *ngIf="itemdtl.DelFlag" style="color: red;">(Deleted)</span> -->
                                        </label>
                                        <label *ngIf="itemdtl.SpecialRequest" style="display: flex; padding-left: 30px; " >
                                            Note : {{itemdtl.SpecialRequest}}
                                        </label>
                                    </li>
                                </ul>
                                    <ul class="list-group list-group-flush" *ngIf="itemdtl.AddOnID > 0" >
                                        <li class="list-group-item" style="text-indent: 30px; font-size: 13px; padding-top: 0px; padding-bottom: 0px;height: 28px;line-height: 28px;">{{itemdtl.AddOnName}}</li>
                                    </ul> 
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="Card" style="background-color: #cef7ac;  height: 36px;">
                        <!-- <button *ngIf="item.RejectReasonID != 0" style="background-color: #c50d0d; color:white; width: 130px;height: 36px; outline: none; border-style: none;" (click)="CancelClick(item)">Cancel</button>
                        <button *ngIf="item.RejectReasonID == 0" style="background-color: #99b880; color:white; width: 130px;height: 36px; outline: none; border-style: none;" (click)="ItemButtonClick(item)">Complete</button>&nbsp;&nbsp;&nbsp;&nbsp; -->
                        <button style="background-color: #bababa; color:white; width: 100%;height: 36px; outline: none; border-style: none;" (click)="SelectOrder(item)">Select Order</button>
                     </div>
                    </div>    
          

           
                 <div *ngIf="IsNewChecks" style="padding-right: 25px;padding-top: 25px; width: 327px;float: left;" >
                    <div class="card">
                        <div class="Headerbackgroung" style="min-height: 125px;">    
                            <div class="Card" style="background-color: #cef7ac;  height: 36px;">
                                <button style="background-color: #606467; color:white; width: 100%;height: 36px; outline: none; border-style: none;font-size: 20px;">
                                    <label style="margin:0px ;">Table #<span>{{tablenumber_NewChecks}}</span> </label>
                                    </button>
                             </div>   
                            <!-- <div style="font-size: 14px; font-weight: 600; color: white;"><label style="width: 60%; text-align: start; margin-left: 5px;">Order No. {{item.OrderNo}}</label><label style="width: 38%;  ">{{item.OrderDifference}} ago</label>  </div>         
                            <div style="font-size: 14px; font-weight: 600; color: white;"><label style="width: 60%; text-align: start; margin-left: 5px;">#{{item.OrderInvoiceNo}}</label><label style="width: 38%;  ">{{item.OrderDate}}</label>  </div>
                            <div style="font-size: 14px; font-weight: 600; color: white;"><label style="width: 31%; text-align: start; margin-left: 5px;vertical-align: top;">Table# <span>{{item.TableNumber}}</span> </label><label style="width: 29%;vertical-align: top;  ">{{item.OrderType}}</label> <label style="width: 38%;  white-space: nowrap;overflow: hidden; text-overflow: ellipsis;  ">{{item.Name}}</label>  </div> -->
                        </div>
                        <div style="height:16rem; overflow-y: scroll;">
                            <!-- <div *ngFor="let itemdtl of lstarrayDetails">
                                <div style="border-bottom: 1px solid #e7e0e0;">
                                    <ul class="list-group list-group-flush" >
                                    <li  class="list-group-item" style="font-size: 13px; color: #434444; padding-top: 5px; padding-bottom: 2px;" *ngIf="itemdtl.AddOnID == 0" >
                                        <label>
                                            <span>{{itemdtl.Quantity}}X</span> &nbsp;&nbsp; 
                                            <span >{{itemdtl.ProductName}}</span> &nbsp;&nbsp; 
                                        </label>
                                        <label *ngIf="itemdtl.SpecialRequest" style="display: flex; padding-left: 30px; " >
                                            Note : {{itemdtl.SpecialRequest}}
                                        </label>
                                    </li>
                                </ul>
                                    <ul class="list-group list-group-flush" *ngIf="itemdtl.AddOnID > 0" >
                                        <li class="list-group-item" style="text-indent: 18px; font-size: 13px; padding-top: 5px; padding-bottom: 2px;">{{itemdtl.AddOnName}}</li>
                                    </ul> 
                                </div>
                            </div> -->
                        </div>
                    </div>
                    <div class="Card" style="background-color: #cef7ac;  height: 36px;">
                        <button style="background-color: #bababa; color:white; width: 100%;height: 36px; outline: none; border-style: none;" (click)="SelectOrder_NewChecks()">Select Order</button>
                     </div>
                    </div>   
       

      </div>   
    
    </nb-card-body>

  </nb-card>
  
  