  <div id="invoice-POS" class="invoice-POS">
    <div id="top">
      <div *ngIf="ObjOrderMaster.Channel == 'Website'" class="logo" [ngStyle]="{'background': 'url(' + this.ObjCompanyInfo.LogoImageName + ')','height':'140px'}"></div>
      <div *ngIf="ObjOrderMaster.Channel == 'POS'" class="logo" [ngStyle]="{'background': 'url(' + this.ObjOrderMaster.ImagePath + ')','height':'140px'}"></div>
    </div>

    <div id="mid">
      <div class="info" *ngIf="ObjOrderMaster.OutletName != 'N/A'">
        <div class="hd">{{ObjOrderMaster.OutletName}}</div>
        <div class="mdatal">{{ObjOrderMaster.outletAddress}}</div>
        <div class="mdatal">{{ObjOrderMaster.AreaName}}</div>
        <div class="mdatal" *ngIf="ObjCompanyInfo.VATNo != 'N/A'">VAT No. : {{ObjCompanyInfo.VATNo}}</div>
        <div class="mdatal" *ngIf="ObjOrderMaster.OutletPhone != 'N/A' && ObjOrderMaster.IsMobNoVisibleReceipt && ObjOrderMaster.Channel == 'POS'">Tel : {{ObjOrderMaster.OutletPhone}}</div>
        <div class="mdatal" *ngIf="ObjOrderMaster.OutletPhone != 'N/A' && ObjOrderMaster.Channel == 'Website'">Tel : {{ObjOrderMaster.OutletPhone}}</div>
      </div>
      <div class="info" *ngIf="ObjCurrency.CountryName == 'Bahrain'" style="border-top: 2px dotted #0e0d0d;border-bottom: 2px dotted #0e0d0d">
        <div class="dlvy">Tax Invoice</div>

      </div>
      <div class="info" *ngIf="ObjCurrency.CountryName == 'Pakistan'" style="border-top: 2px dotted #0e0d0d;border-bottom: 2px dotted #0e0d0d">
        <div class="dlvy">Invoice</div>

      </div>

      <div class="info" style="border-top: 2px dotted #0e0d0d;border-bottom: 2px dotted #0e0d0d">
        <div class="dlvy" *ngIf="ObjOrderMaster.OrderType == 'Delivery'">Delivery</div>
        <div class="dlvy" *ngIf="ObjOrderMaster.OrderType == 'Takeaway' || ObjOrderMaster.OrderType == 'Pickup'">Take Away - Pickup</div>
        <div class="dlvy" *ngIf="ObjOrderMaster.OrderNo">Order No. : {{ObjOrderMaster.OrderNo}}</div>

        <div *ngIf="ObjOrderMaster.CustomerName != 'N/A' && ObjOrderMaster.OrderType != 'Dine In'">
          <div class="mdatal" style="font-weight: 700">{{ObjOrderMaster.CustomerName}}</div>
          <div class="mdatal">{{ObjOrderMaster.PhoneNo}}</div>

          <div class="mdatal" *ngIf="ObjCurrency.CountryName != 'Pakistan'">Address: {{ObjOrderMaster.Address}} {{ObjOrderMaster.AdditionalDirection == 'N/A' || ObjOrderMaster.AdditionalDirection == '' ? '' : ' , ' +ObjOrderMaster.AdditionalDirection}}</div>
          <div style="border-bottom: 2px dotted #0e0d0d;" class="mdatal" *ngIf="ObjCurrency.CountryName == 'Pakistan'">Address: {{ObjOrderMaster.AddressPK}}</div>
        </div>

        <!--<div class="info" *ngIf="ObjOrderMaster.OrderType == 'Dine In' && ObjOrderMaster.TableNumber != 0">
      <div class="dlvy">Dine In</div>
      <div class="dlvy">Table No.</div>
      <div class="mdatal" style="font-weight: 700">{{ObjOrderMaster.TableNumber}}</div>
    </div>-->

      </div>

      <div style="border-bottom: 2px dotted #0e0d0d">
        <div class="mdatal">
          Order Type :&nbsp; {{ObjOrderMaster.OrderType}} <span *ngIf="ObjOrderMaster.OrderType == 'Dine In' && ObjOrderMaster.TableNumber != 0" class="mdatal" style="font-weight: 700">Table No.:{{ObjOrderMaster.TableNumber}}</span>

        </div>
      </div>
      <!-- <div style="border-bottom: 2px dotted #0e0d0d" *ngIf="ObjOrderMaster.OrderType == 'Dine In' && ObjOrderMaster.TableNumber != 0">
    <div class="mdatal">Order Type :&nbsp;Dine In Table No.:<span class="mdatal" style="font-weight: 700">{{ObjOrderMaster.TableNumber}}</span></div>
  </div> -->


      <div style="border-bottom: 2px dotted #0e0d0d">
        <div class="mdatal" *ngIf="ObjOrderMaster.OrderType == 'Dine In' && ObjOrderMaster.TableNumber != 0 && ObjOrderMaster.NoOfCustomers > 0">Cust# :&nbsp; {{ObjOrderMaster.NoOfCustomers}}</div>

        <div class="mdatal">Tran# :&nbsp; {{ObjOrderMaster.OrderInvoiceNo}}</div>

        <div class="mdatal">Order Date :&nbsp; {{ObjOrderMaster.OrderDate}} , {{ObjOrderMaster.OrderTime}}</div>

        <!--<div class="mdatal">Payment :&nbsp; {{ObjOrderMaster.PayType}}</div>-->

        <div class="mdatal" *ngIf="ObjOrderMaster.Cashier != 'N/A'">Employee :&nbsp; {{ObjOrderMaster.Cashier}}</div>
      </div>



    </div>

    <div id="bot">


      <div id="table">
        <table>

          <tr>
            <th>Description</th>
            <th>Qty</th>
            <th>Amount</th>
          </tr>

          <tbody>
            <tr *ngFor="let row of ArrOrderDetail; let i = index">
              <td *ngIf="row.AddOnID == '0' && row.ComboProductID == null">{{row.ProductName}} {{(row.ProductSizeName === null || row.ProductSizeName.trim() === '' || row.ProductSizeName === 'N/A') ? '' : '(' + row.ProductSizeName +')'}} <br /> <span *ngIf="row.SpecialRequest" style="text-indent: 10px"><b>Note: </b>{{row.SpecialRequest}}</span> </td>
              <td *ngIf="row.AddOnID != '0'" style="text-indent: 10px">{{row.AddOnName}}</td>
              <!-- <td *ngIf="row.ComboProductID != null" style="text-indent: 10px">{{row.ProductName}}  {{(row.ProductSizeName === null || row.ProductSizeName.trim() === '' || row.ProductSizeName === 'N/A') ? '' : '(' + row.ProductSizeName +')'}}  </td>-->
              <td>{{row.Quantity}}</td>
              <td class="amountcls">{{row.OrignalAmount|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</td>

            </tr>
            <!-- <tr > <td colspan="2"><b>Note:</b>{{row.SpecialRequest}}</td></tr> -->
          </tbody>


          <ng-container *ngIf="ArrVoucherData?.length > 0">
            <tr class="tfoot" *ngFor="let d of ArrVoucherData;">
              <td class="tfoot" colspan="1">
                Voucher [{{d.VoucherCode}}]
                <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="promo-code__icon" style="width: 13px; height: 18px;"><path d="M497.941 225.941L286.059 14.059A48 48 0 00252.118 0H48C21.49 0 0 21.49 0 48v204.118a48 48 0 0014.059 33.941l211.882 211.882c18.745 18.745 49.137 18.746 67.882 0l204.118-204.118c18.745-18.745 18.745-49.137 0-67.882zm-22.627 45.255L271.196 475.314c-6.243 6.243-16.375 6.253-22.627 0L36.686 263.431A15.895 15.895 0 0132 252.117V48c0-8.822 7.178-16 16-16h204.118c4.274 0 8.292 1.664 11.314 4.686l211.882 211.882c6.238 6.239 6.238 16.39 0 22.628zM144 124c11.028 0 20 8.972 20 20s-8.972 20-20 20-20-8.970-20 8.970 20-20m0-28c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48z"></path></svg>
              </td>
              <td class="tfoot" style="text-align:right !important">{{CurrencyCode}}</td>
              <td class="tfoot" style="text-align:right !important">(-{{d.VoucherAmount|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}})</td>
            </tr>
          </ng-container>
          <!--<tr class="tfoot" *ngIf="ObjOrderMaster.PromoAmount">
        <td class="tfoot" colspan="1">Coupon Disc</td>
        <td class="tfoot" style="text-align:right !important">{{CurrencyCode}}</td>
        <td class="tfoot" style="text-align:right !important">(-{{ObjOrderMaster.PromoAmount|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}})</td>
      </tr>-->
          <tr class="tfoot" *ngIf="ObjOrderMaster.TotalDiscount && ObjOrderMaster.TotalDiscount > 0">
            <td class="tfoot" colspan="1">Discount</td>
            <td class="tfoot" style="text-align:right !important">{{CurrencyCode}}</td>
            <td class="tfoot" style="text-align:right !important">(-{{ObjOrderMaster.TotalDiscount|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}})</td>
          </tr>

          <tr class="tfoot" *ngIf="ObjOrderMaster.LoyaltyAmount > 0">
            <td class="tfoot" colspan="1">Discount (Reward Points)</td>
            <td class="tfoot" style="text-align:right !important">{{CurrencyCode}}</td>
            <td class="tfoot" style="text-align:right !important">(-{{ObjOrderMaster.LoyaltyAmount|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}})</td>
          </tr>
          <tr class="tfoot">
            <td class="tfoot" colspan="1">Net Total</td>
            <td class="tfoot" style="text-align:right !important">{{CurrencyCode}}</td>
            <td class="tfoot" style="text-align:right !important">{{ObjOrderMaster.TotalAmount|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</td>
          </tr>
          <tr class="tfoot" *ngIf="ObjOrderMaster.IsExtraCharge == 1 && ObjOrderMaster.ExtraChargeAmount > 0">
            <td class="tfoot" colspan="1">{{ObjOrderMaster.ExtraChargeDescription}}</td>
            <td class="tfoot" style="text-align:right !important">{{CurrencyCode}}</td>
            <td class="tfoot" style="text-align:right !important">{{ObjOrderMaster.ExtraChargeAmount|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</td>
          </tr>
          <tr class="tfoot" *ngIf="ObjOrderMaster.TotalTaxAmount > 0">
            <td class="tfoot" colspan="1">Tax</td>
            <td class="tfoot" style="text-align:right !important">{{CurrencyCode}}</td>
            <td class="tfoot" style="text-align:right !important">{{ObjOrderMaster.TotalTaxAmount|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</td>
          </tr>
          <tr class="tfoot" *ngIf="ObjOrderMaster.OrderType == 'Delivery'">
            <td class="tfoot" colspan="1">Delivery Fee</td>
            <td class="tfoot" style="text-align:right !important">{{CurrencyCode}}</td>
            <td class="tfoot" style="text-align:right !important">{{ObjOrderMaster.DeliveryFee|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</td>
          </tr>

          <tr class="tfoot">
            <td class="tfoot" colspan="1">Final Total</td>
            <td class="tfoot" style="text-align:right !important">{{CurrencyCode}}</td>
            <td class="tfoot" style="text-align:right !important">{{ObjOrderMaster.NetAmount|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</td>
          </tr>


        </table>
        <div *ngIf="ObjOrderMaster.Remarks != 'N/A' && ObjOrderMaster.Remarks != ''">
          <br />
          <div style="text-decoration:underline"><strong>Special Instructions</strong></div>
          <div style="text-indent:5px">{{ObjOrderMaster.Remarks}}</div>
        </div>
        <!--paymentdetails-->
        <br />

        <table style=" border: 1px solid black; border-bottom:2px solid !important">
          <tr *ngFor="let row of _PaymentDetails; let i = index">
            <td><strong>{{row.Description}}</strong></td>
            <td style="text-align:right !important">{{CurrencyCode}}</td>
            <td>{{row.NetAmount|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</td>
          </tr>
        </table>
        <table *ngIf="ReturnAmount > 0">
          <tr>
            <td style="width: 30% !important;">المبلغ المسترد</td>
            <td style="width: 20% !important;"></td>
            <td style="width: 30% !important;">Cash returned</td>
          </tr>
        </table>
        <table *ngIf="ReturnAmount  > 0" style=" border: 1px solid black; border-bottom:2px solid !important">
          <tr>
            <td><strong>Return Amount</strong></td>
            <td style="text-align:right !important">{{CurrencyCode}}</td>
            <td>{{ReturnAmount|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</td>
          </tr>
        </table>
        <!--Tip Info-->
        <table *ngIf="ObjOrderMaster.TIPAmount >0  && ActivateTipAmount == 1">
          <tr>
            <td style="width: 30% !important;">المبلغ الإكرامية</td>
            <td style="width: 20% !important;"></td>
            <td style="width: 30% !important;">TIP Amount</td>
          </tr>
        </table>
        <table *ngIf="ObjOrderMaster.TIPAmount >0 && ActivateTipAmount == 1" style=" border: 1px solid black; border-bottom:2px solid !important">
          <tr>
            <td><strong>TIP Amount</strong></td>
            <td style="text-align:right !important">{{CurrencyCode}}</td>
            <td>{{ObjOrderMaster.TIPAmount|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</td>
          </tr>
        </table>
      </div>

      <div class="info" *ngIf="ObjOrderMaster.IsQRVisible">
        <ngx-qrcode [elementType]="elementType"
                    [value]="QRUrl"
                    cssClass="aclass"
                    errorCorrectionLevel="L">
        </ngx-qrcode>
      </div>
      <div id="legalcopy">
        <p class="legal" style="text-align:center;font-size:15px" *ngIf="ObjOrderMaster.ReceiptMsg != '' || ObjCompanyInfo.ReceiptMsg != ''">

          <strong class="" *ngIf="ObjOrderMaster.Channel == 'POS'">{{ObjOrderMaster.ReceiptMsg}}</strong>
          <strong class="" *ngIf="ObjOrderMaster.Channel == 'Website'">{{ObjCompanyInfo.ReceiptMsg}}</strong>
          <br />
        </p>
        <!--<button class="label clsB1 doNotPrint" style="border:0" (click)="printComponent()">Print order</button>-->
      </div>
      <br />
      <div id="NOtpaid" *ngIf="ObjOrderMaster.OrderPayStatus == 'Pending' && ObjOrderMaster.OrderPerpStatus !='Cancel'">
        <p class="legal" style="text-align:center;font-size:28px">
          <strong class="">**** Not Paid ****</strong><br />
        </p>

      </div>
      <div id="NOtpaid" *ngIf="ObjOrderMaster.OrderPerpStatus =='Cancel'">
        <p class="legal" style="text-align:center;font-size:28px">
          <strong class="">**** Canceled ****</strong><br />
        </p>

      </div>
      <div id="legalcopy">

        <button class="label clsB1 doNotPrint" style="border:0" (click)="printComponent()">Print order</button>
      </div>
    </div>
  </div>
