

<div class="parent1" style="display: block;padding-top: 0px;">
  <div class="child1" style="max-width: none;">Select Outlet  
    <img src="./assets/images/lookupclose.png" (click)="btnclose()" class="cross-icon" style="float: right;cursor: pointer;margin-top: -5px;" /></div>
</div>
  <hr style="margin-top: 5px;"/>
  <div class="row">
<div class="container" *ngFor="let row of lstOrderType; let i = index">
      <div (click)="figureClick(row)"  [ngClass]="[row.ID == str ? 'cardtype card-product-grid card-lg parentdiv cardtypeselected' : 'cardtype card-product-grid card-lg parentdiv']">
        <a [ngClass]="[row.ID == str ? 'fontcolorselected' : 'fontcolorunselected']" style="text-align:center;width: 100%;" >{{row.OutletName}}</a> 
      </div>
  </div>
</div>


<!-- <div style="position: fixed; width: 310px;">
  <div class="child1" style="max-width: 100% !important ;">Select Outlets
    <img src="./assets/images/lookupclose.png" (click)="btnclose()" style="float: right; width: 25px;cursor: pointer;">
  </div>
  <hr style="margin-top: 10px;"/>
</div>
<div class="container">
  <div class="row" style="margin-bottom: 100px; ">
    <div class="col-md-2"></div>
    <div class="col-md-8" style=" padding-top: 20px;">
      <div *ngFor="let row of lstOrderType; let i = index" style="text-align:center; width: 100%;" [ngClass]="[row.ID == str ? 'cardtype card-product-grid card-lg cardtypeselected' : 'cardtype card-product-grid card-lg']">
        <a [ngClass]="[row.ID == str ? 'fontcolorselected' : 'fontcolorunselected']" style="text-align:center;width: 100%;" (click)="figureClick(row)" >{{row.OutletName}}</a> 
      </div>
    </div>
    <div class="col-md-2"></div>
  </div>
</div> -->


