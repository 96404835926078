<link href="//stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet">

<nb-card>
  <nb-card-header>
    <div class="form-group col-md-12 ">
      <div class="row">
        <div class="col-md-10 cls2">
          {{'OrderNo' | translate}}
        </div>

        <div class="col-md-2 cls2">
            {{ObjOrderMaster.OrderInvoiceNo}}
        </div>
      </div>
      </div>
      
  </nb-card-header>

  <nb-card-body>
    <div class="form-group col-md-12">
      <div class="row">
        <div class="col-md-4">
        </div>
        <div class="">
          <label class="label clsf">{{'Pleaseselectarider' | translate}}</label>
        </div>
      </div>

      <div class="row">
        <div class="form-group" style="width:100%;text-align:center">
          <nb-select [(selected)]="ObjOrderMaster.RiderID"  style="width:100%">
            <nb-option *ngFor="let _data of ddlRiders" [value]="_data.RiderID" >{{_data.RiderName}}</nb-option>
         </nb-select>

        </div>
      </div>


    </div>

    <div class="form-group col-md-12">

      <div class="row">
        <div class="col-md-10">
        </div>

        <div class="col-md-2">
        </div>

      </div>
    </div>

    <div class="form-group col-md-12 col-sm-12">

      <div class="row">
        <div class="col-md-10 col-sm-6 cls2">
          <button nbButton status="basic" (click)="ClosePopup()" >    {{'Close' | translate}}</button>
        </div>

        <div class="col-md-2 col-sm-6 cls2">
          <button nbButton status="danger" (click)="SelectedRider()">{{'S' | translate}}</button>
        </div>

      </div>
    </div>
  </nb-card-body>
</nb-card>
