
  <div id="invoice-POS" class="invoice-POS">

    <!-- <div id="top">
      <div class="logo" [ngStyle]="{'background': 'url(' + this.ObjCompanyInfo.LogoImageName + ')','height':'140px'}"></div>
 
    </div> -->

    <div id="mid">
      <!-- <div class="info" *ngIf="ObjOrderMaster.OutletName != 'N/A'">
        <div class="hd">{{ObjOrderMaster.OutletName}}</div>
        <div class="hd" style="text-align: center;">{{ObjOrderMaster.ArabicOutletName}}</div>
        <div class="mdatal">{{ObjOrderMaster.outletAddress}}</div>
        <div class="mdatal"></div>
        <div class="mdatal">{{ObjOrderMaster.AreaName}}</div>   
        <div class="mdatal" *ngIf="ObjCompanyInfo.VATNo != 'N/A'">VAT No. : {{ObjCompanyInfo.VATNo}}</div>
        <div class="mdatal" *ngIf="ObjOrderMaster.OutletPhone != 'N/A'">Tel : {{ObjOrderMaster.OutletPhone}}</div>
      </div> -->

      <div class="info" >
   
        <div class="dlvy" >{{ObjCompany.CompanyName}}</div>

      </div>
      <div class="info" >
   
        <div class="dlvy" >== {{EndOfDaySummary}} ==</div>

      </div>
          <div >

        <div class="mdatal">{{PrintDate}}:&nbsp; <br /> <span> {{printdate}} </span></div>   
        <div class="mdatal">{{DayStartDate}}:&nbsp;<br /> <span> {{ObjSalessummary.DayDate}}</span>  </div>
        <div class="mdatal">{{DayEndDate}}:&nbsp; <br /> <span> {{ObjSalessummary.EndTime}}</span>  </div>     
        
      </div>
    <div >

      <div class="mdatal">{{HoursWorked}}:&nbsp; {{WorkedHour}} </div>

      <!-- <div class="mdatal">Hours Scheduled:&nbsp; {{ObjDayInfo.StartTime}}</div>        -->
      
    </div>
    <div>

      <div class="mdatal">{{EmployeeNamelabel}}:&nbsp; {{ObjCompany.EmployeeName}}</div>     
      
    </div>

  </div>

    <div id="bot">


      <div id="table1" style="border-top: 2px dotted #0e0d0d;border-top: 2px dotted #0e0d0d">

        <div class="productheading">
          {{Products}}:
        </div>

        <!-- <div class="row" *ngFor="let row of ArrSalesDetail; let i = index">
          <div class="col-md-3">test</div>
          <div class="col-md-3"></div>
          <div class="col-md-3"></div>
          <div class="col-md-3">123</div>

        </div> -->
        <table>        
          <tbody>
            <tr style="line-height: 0.5rem !important;">
              <th>{{Item}}</th>
              <th *ngIf="IsRcptProWise">{{Qty}}</th>
              <th></th>
              <th>{{Amount}}</th>
            </tr>
          </tbody>
          </table>
        <table *ngFor="let row of ArrSalesDetail; let i = index">        
          <tbody>
            
            <tr *ngIf="!IsRcptProWise"> 
              <td style="font-weight: 600;" >{{row.ProductCategory}} </td>
             
              <td ></td>
              <td></td>
              <td class="amountcls" style="font-weight: 600;">{{row.NetAmount |number:'1.'+ObjCompany.NumOfDecimal+'-'+ObjCompany.NumOfDecimal+''}}</td>
            </tr>
            <ng-container *ngFor="let rowdtl of ArrProductDetail; let i = index">
            <tr *ngIf="IsRcptProWise && row.Code == rowdtl.CategoryId" >
              <td style="text-indent: 10px; font-size: 16px !important;"><label >{{rowdtl.ProductName}}</label>  </td>
             
              <td style="font-size: 16px !important; text-align: center;">{{rowdtl.Quantity}}</td>
              <td></td>
              <td class="amountcls" style="font-size: 16px !important;">{{rowdtl.productTotal |number:'1.'+ObjCompany.NumOfDecimal+'-'+ObjCompany.NumOfDecimal+''}}</td>
            </tr>
            </ng-container>
            <!-- <tr>
              <td  style="font-weight: 600;" >{{ProductTotal}} </td>
             
              <td ></td>
              <td></td>
              <td  style="font-weight: 600;">{{TotalSale |number:'1.'+ObjCompany.NumOfDecimal+'-'+ObjCompany.NumOfDecimal+''}}</td>
            </tr> -->

          </tbody>
    
        </table>

        <table>
  <tbody>
             <tr>
              <td  style="font-weight: 600;" >{{ProductTotal}} </td>
             
              <td ></td>
              <td></td>
              <td  style="font-weight: 600;">{{TotalSale |number:'1.'+ObjCompany.NumOfDecimal+'-'+ObjCompany.NumOfDecimal+''}}</td>
            </tr>

          </tbody>
    
        </table>

      </div>
      
      <div id="table2" >

        <div class="productheading">
          {{Coupons}}:
        </div>
        <table>        
          <tbody  *ngIf="ArrCoupansDetail.length > 0">
     
            <tr  *ngFor="let row of ArrCoupansDetail; let i = index">
              <td> {{row.VoucherCode}}</td>
             
              <td ></td>
              <td></td>
              <td class="amountcls">{{row.PromoAmount |number:'1.'+ObjCompany.NumOfDecimal+'-'+ObjCompany.NumOfDecimal+''}}</td>
            </tr>
            

          </tbody>
  
    
        </table>
        <table>
          <tbody>
            <tr>
              <td  style="font-weight: 600;" >{{CouponsTotal}} </td>
             
              <td ></td>
              <td></td>
              <td  style="font-weight: 600;">{{TotalCoupans |number:'1.'+ObjCompany.NumOfDecimal+'-'+ObjCompany.NumOfDecimal+''}}</td>
            </tr>
  
          </tbody>
        </table>
        
      </div>
      
      <div id="table9" >             
        <table style="border-top: 2px dotted #0e0d0d">
          <tbody>
            <tr>
              <td style="font-weight: 600;">{{CancelVoidAmount}} </td>

              <td></td>
              <td></td>
              <td style="font-weight: 600;">{{ObjSalessummary.CancelVoidAmount |number:'1.'+ObjCompany.NumOfDecimal+'-'+ObjCompany.NumOfDecimal+''}}</td>
            </tr>
            <tr>
              <td style="font-weight: 600;">{{TIPAmount}} </td>

              <td></td>
              <td></td>
              <td style="font-weight: 600;">{{ObjSalessummary.TIPAmount |number:'1.'+ObjCompany.NumOfDecimal+'-'+ObjCompany.NumOfDecimal+''}}</td>
            </tr>
            <tr>
              <td style="font-weight: 600;">{{SalesReturnAmount}} </td>

              <td></td>
              <td></td>
              <td style="font-weight: 600;">{{ObjSalessummary.SalesReturnAmount |number:'1.'+ObjCompany.NumOfDecimal+'-'+ObjCompany.NumOfDecimal+''}}</td>
            </tr>
           
          </tbody>
        </table>

      </div>
           
      <div id="table3" style="border-top: 2px dotted #0e0d0d;border-top: 2px dotted #0e0d0d;border-bottom: 2px dotted #0e0d0d;margin-bottom: 5px;">

      
        <table>        
          <tbody>
     
            <tr>
              <td>{{Noofcustomers}}: </td>
             
              <td ></td>
              <td></td>
              <td class="amountcls">{{ObjSalessummary.NoOfCust |number:'1.'+ObjCompany.NumOfDecimal+'-'+ObjCompany.NumOfDecimal+''}}</td>
            </tr>
            <tr>
              <td>{{NumberofTransactions}}: </td>
             
              <td ></td>
              <td></td>
              <td class="amountcls">{{ObjSalessummary.NoOfTrans |number:'1.'+ObjCompany.NumOfDecimal+'-'+ObjCompany.NumOfDecimal+''}}</td>
            </tr>
            <tr>
              <td>{{TotalSubtotalExclVAT}}:</td>
             
              <td ></td>
              <td></td>
              <td class="amountcls">{{ObjSalessummary.TotalAmountNoTax |number:'1.'+ObjCompany.NumOfDecimal+'-'+ObjCompany.NumOfDecimal+''}}</td>
            </tr>
            <tr *ngIf="ObjSalessummary.ExtraChargeAmount">
              <td>{{ObjSalessummary.ExtraChargeDescription}}:</td>
             
              <td ></td>
              <td></td>
              <td class="amountcls">{{ObjSalessummary.ExtraChargeAmount |number:'1.'+ObjCompany.NumOfDecimal+'-'+ObjCompany.NumOfDecimal+''}}</td>
            </tr>
            <!--<tr>
              <td>{{TotalDiscountCoupons}}:</td>
              <td ></td>
              <td></td>
              <td class="amountcls">{{TotalCoupans |number:'1.'+ObjCompany.NumOfDecimal+'-'+ObjCompany.NumOfDecimal+''}}</td>
            </tr>-->
            <tr *ngIf="TotalLoyaltyAmount > 0">
              <td>{{LoyaltyTotal}}:</td>
              <td ></td>
              <td></td>
              <td class="amountcls">{{TotalLoyaltyAmount |number:'1.'+ObjCompany.NumOfDecimal+'-'+ObjCompany.NumOfDecimal+''}}</td>
            </tr>
            <tr>
              <td>{{TotalTaxAmount}}:</td>
             
              <td ></td>
              <td></td>
              <td class="amountcls">{{ObjSalessummary.TotalTaxAmount |number:'1.'+ObjCompany.NumOfDecimal+'-'+ObjCompany.NumOfDecimal+''}}</td>
            </tr>
            <tr>
              <td>{{TotalNetAmountInclVAT}}:</td>
             
              <td ></td>
              <td></td>
              <td class="amountcls">{{ObjSalessummary.NetAmount |number:'1.'+ObjCompany.NumOfDecimal+'-'+ObjCompany.NumOfDecimal+''}}</td>
            </tr>
            <tr>
              <td>{{AverageNumberofCustomers}}: </td>
             
              <td ></td>
              <td></td>
              <td class="amountcls">{{ObjSalessummary.AvgCust |number:'1.'+ObjCompany.NumOfDecimal+'-'+ObjCompany.NumOfDecimal+''}}</td>
            </tr>
            <tr>
              <td>{{AverageTransactionsValue}}: </td>
             
              <td ></td>
              <td></td>
              <td class="amountcls">{{ObjSalessummary.AvgTrans |number:'1.'+ObjCompany.NumOfDecimal+'-'+ObjCompany.NumOfDecimal+''}}</td>
            </tr>
            <tr>
              <td>{{AveragevaluePerCustomers}}: </td>
             
              <td ></td>
              <td></td>
              <td class="amountcls">{{ObjSalessummary.AvgPerValueCust |number:'1.'+ObjCompany.NumOfDecimal+'-'+ObjCompany.NumOfDecimal+''}}</td>
            </tr>
            <tr>
              <td>{{AvgSaleperEmployeeHour}}: </td>
             
              <td ></td>
              <td></td>
              <td class="amountcls">{{AvgSalePerEmployee |number:'1.'+ObjCompany.NumOfDecimal+'-'+ObjCompany.NumOfDecimal+''}}</td>
            </tr>
          
          </tbody>
    
        </table>

      </div>
      <div id="table3" style="border-top: 2px dotted #0e0d0d;border-top: 2px dotted #0e0d0d;border-bottom: 2px dotted #0e0d0d;margin-bottom: 5px;">      
        <table>
          <tbody>
            <tr>
              <td  style="font-weight: 600;" >{{CashInAmount}}:</td>
             
              <td ></td>
              <td></td>
              <td  style="font-weight: 600;">{{ObjSalessummary.CashInAmount |number:'1.'+ObjCompany.NumOfDecimal+'-'+ObjCompany.NumOfDecimal+''}}</td>
            </tr>
  
          </tbody>
        </table>
      </div>

      <div id="table2" *ngIf="ArrPOSTenders.length > 0" style="border-top: 2px dotted #0e0d0d;border-top: 2px dotted #0e0d0d;border-bottom: 2px dotted #0e0d0d;margin-bottom: 5px;">

        <div class="info" style="font-size: 21px; font-weight: 600; ">
        <label style="border-bottom: 1px dotted black ;">{{POSTendered}}</label>
        </div>

        <div class="row" *ngFor="let row of ArrPOSTenders; let i = index" style="border-bottom: 1px solid #dbd8d8;">

          <div class="col-md-6" style="font-size: 21px; font-weight: 549; font-family: Calibri !important;">{{row.PaymentType}}</div>
          <!-- <div class="col-md-4"style="font-size: 15px; " >{{Sales}}:</div> -->
          <!-- <div class="col-md-4"></div> -->
          <div class="col-md-6" style="text-align: right;font-size: 21px;font-weight: 549;font-family: Calibri !important;">{{row.SaleAmount |number:'1.'+ObjCompany.NumOfDecimal+'-'+ObjCompany.NumOfDecimal+''}}</div>       
        </div>

      
      </div>


      <div id="table2" *ngIf="ArrPOSTenders.length > 0"  style="border-top: 2px dotted #0e0d0d;">

        <div class="info" style="font-size: 21px; font-weight: 600; ">
        <label style="border-bottom: 1px dotted black ;">{{POSCashOut}}</label>
        </div>

        <div class="row" *ngFor="let row of ArrPOSTenders; let i = index" style="border-bottom: 1px solid #dbd8d8;">

          <div class="col-md-6" style="font-size: 21px; font-weight: 549; font-family: Calibri !important;">{{row.PaymentType}} </div>
          <!-- <div class="col-md-4"style="font-size: 15px; " >{{POSTendered}} </div> -->
          <!-- <div class="col-md-4"></div> -->
          <div class="col-md-6" style="text-align: right;font-size: 21px;font-weight: 549;font-family: Calibri !important;">{{row.CashOutAmount |number:'1.'+ObjCompany.NumOfDecimal+'-'+ObjCompany.NumOfDecimal+''}}</div>
    

        </div>

      
      </div>
  

      <div id="table2" *ngIf="ArrOvershort.length > 0"  style="border-top: 2px dotted #0e0d0d;">

        <div class="info" style="font-size: 21px; font-weight: 600; ">
        <label style="border-bottom: 1px dotted black ;">{{OverShortBaseCurrency}}</label>
        </div>

        <div class="row" *ngFor="let row of ArrOvershort; let i = index" style="border-bottom: 1px solid #dbd8d8;">

          <div class="col-md-6" style="font-size: 21px; font-weight: 549; font-family: Calibri !important;">{{row.PaymentName}} </div>
 
          <div class="col-md-6" style="text-align: right;font-size: 21px;font-weight: 549;font-family: Calibri !important;">{{row.Overshort |number:'1.'+ObjCompany.NumOfDecimal+'-'+ObjCompany.NumOfDecimal+''}}</div>
    

        </div>

      
      </div>

      <div id="table2"   style="border-top: 2px dotted #0e0d0d; margin-top: 5px;">

   

        <div class="row" style="border-bottom: 1px solid #dbd8d8;">

          <div class="col-md-12" style="font-size: 21px; font-weight: 549; font-family: Calibri !important;"></div>
          <div class="col-md-8"style="font-size: 15px; " >{{CashPurchaseTotallabel}} </div>
          <!-- <div class="col-md-2"></div> -->
          <div class="col-md-4" style="text-align: right;font-size: 21px;font-weight: 549;font-family: Calibri !important;">{{CashPurchaseTotal}}</div>
    

        </div>

      
      </div>

      <!-- <div id="table2"   style="border-top: 2px dotted #0e0d0d; margin-top: 5px;">

   

        <div class="row" style="border-bottom: 1px solid #dbd8d8;">

          <div class="col-md-12" style="font-size: 21px; font-weight: 549; font-family: Calibri !important;"></div>
          <div class="col-md-8"style="font-size: 15px; " >{{OverShortBaseCurrency}} </div>
   
          <div class="col-md-4" style="text-align: right;">{{Overshort}}</div>
    

        </div>

      
      </div> -->
      <!-- <div id="table">
        <table>
          <thead>
            <tr>
              <th>Description</th>
              <th>Qty</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let row of ArrOrderDetail; let i = index" [ngClass]="row.AddOnID != '0' ? 'borderDoted' : 'borderDoted'" >
              <td *ngIf="row.AddOnID == '0'" >{{row.ProductName}} {{row.ProductSizeName == 'N/A' ? '' : '(' + row.ProductSizeName +')'}}  <br /> <span style="text-indent: 10px">{{row.AProductName}}</span> </td>
             
              <td *ngIf="row.AddOnID != '0'" style="text-indent: 10px">{{row.AddOnName}}</td>
              <td>{{row.Quantity}}</td>
              <td class="amountcls">{{row.OrignalAmount|number:'1.'+ObjCompany.NumOfDecimal+'-'+ObjCompany.NumOfDecimal+''}}</td>
            </tr>

          </tbody>
          <tfoot>
            <tr>
              <td colspan="1">Net Total</td>
              <td style="text-align:right !important">{{CurrencyCode}}</td>
              <td style="text-align:right !important">{{ObjOrderMaster.TotalAmount|number:'1.'+ObjCompany.NumOfDecimal+'-'+ObjCompany.NumOfDecimal+''}}</td>
            </tr>
            <ng-container *ngIf="ArrVoucherData?.length > 0">
              <tr *ngFor="let d of ArrVoucherData;">
                <td colspan="1">
                  Voucher [{{d.VoucherCode}}]
                  <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="promo-code__icon" style="width: 13px; height: 18px;"><path d="M497.941 225.941L286.059 14.059A48 48 0 00252.118 0H48C21.49 0 0 21.49 0 48v204.118a48 48 0 0014.059 33.941l211.882 211.882c18.745 18.745 49.137 18.746 67.882 0l204.118-204.118c18.745-18.745 18.745-49.137 0-67.882zm-22.627 45.255L271.196 475.314c-6.243 6.243-16.375 6.253-22.627 0L36.686 263.431A15.895 15.895 0 0132 252.117V48c0-8.822 7.178-16 16-16h204.118c4.274 0 8.292 1.664 11.314 4.686l211.882 211.882c6.238 6.239 6.238 16.39 0 22.628zM144 124c11.028 0 20 8.972 20 20s-8.972 20-20 20-20-8.970-20 8.970 20-20m0-28c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48z"></path></svg>
                </td>
                <td style="text-align:right !important">{{CurrencyCode}}</td>
                <td style="text-align:right !important">(-{{d.VoucherAmount|number:'1.'+ObjCompany.NumOfDecimal+'-'+ObjCompany.NumOfDecimal+''}})</td>
              </tr>
            </ng-container>

            <tr>
              <td colspan="1">Discount</td>
              <td style="text-align:right !important">{{CurrencyCode}}</td>
              <td style="text-align:right !important">(-{{ObjOrderMaster.TotalDiscount|number:'1.'+ObjCompany.NumOfDecimal+'-'+ObjCompany.NumOfDecimal+''}})</td>
            </tr>

            <tr *ngIf="ObjOrderMaster.LoyaltyAmount > 0">
              <td colspan="1">Discount (Reward Points)</td>
              <td style="text-align:right !important">{{CurrencyCode}}</td>
              <td style="text-align:right !important">(-{{ObjOrderMaster.LoyaltyAmount|number:'1.'+ObjCompany.NumOfDecimal+'-'+ObjCompany.NumOfDecimal+''}})</td>
            </tr>

            <tr>
              <td colspan="1">Tax</td>
              <td style="text-align:right !important">{{CurrencyCode}}</td>
              <td style="text-align:right !important">{{ObjOrderMaster.TotalTaxAmount|number:'1.'+ObjCompany.NumOfDecimal+'-'+ObjCompany.NumOfDecimal+''}}</td>
            </tr>
            <tr *ngIf="ObjOrderMaster.OrderType == 'Delivery'">
              <td colspan="1">Delivery Fee</td>
              <td style="text-align:right !important">{{CurrencyCode}}</td>
              <td style="text-align:right !important">{{ObjOrderMaster.DeliveryFee|number:'1.'+ObjCompany.NumOfDecimal+'-'+ObjCompany.NumOfDecimal+''}}</td>
            </tr>

            <tr>
              <td colspan="1">Amount</td>
              <td style="text-align:right !important">{{CurrencyCode}}</td>
              <td style="text-align:right !important">{{ObjOrderMaster.NetAmount|number:'1.'+ObjCompany.NumOfDecimal+'-'+ObjCompany.NumOfDecimal+''}}</td>
            </tr>
        
          </tfoot>
        </table>
        <div *ngIf="ObjOrderMaster.Remarks != 'N/A' && ObjOrderMaster.Remarks != ''"><br />
          <div style="text-decoration:underline"><strong>Special Instructions</strong></div>
          <div style="text-indent:5px">{{ObjOrderMaster.Remarks}}</div>
        </div>
      </div>
      <div id="legalcopy">
        <p class="legal" style="text-align:center;font-size:20px"  *ngIf="ObjCompanyInfo.ReceiptMsg != 'N/A' && ObjCompanyInfo.ReceiptMsg != ''">
          <strong>{{ObjCompanyInfo.ReceiptMsg}}</strong><br />         
        </p>
       
      </div>
      <div class="info">
        <ngx-qrcode [elementType]="elementType"
                    [value]="QRUrl"
                    cssClass="aclass"
                    errorCorrectionLevel="L">
        </ngx-qrcode>
      </div> -->

      
      <div id="legalcopy">
   
        <button class="label clsB1 doNotPrint" style="border:0" (click)="printComponent()">Print order</button>
      </div>

    </div>
  </div>
