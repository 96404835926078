import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CoreModule } from './@core/core.module';
import { ThemeModule } from './@theme/theme.module';
import { AppComponent } from './app.component'; 
import { AppRoutingModule } from './app-routing.module';
import { NavBarComponent } from '../app/pages/live/navbar/navbar.component';
import { SpinnerService } from '../app/shared/spinner';
import { DecimalPipe } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
// import {
//   NbChatModule,
//   NbDatepickerModule,
//   NbDialogModule,
//   NbMenuModule,
//   NbSidebarModule,
//   NbToastrModule,
//   NbTimepickerModule,
//   NbWindowModule, ,NbSpinnerModule,
// } from '@nebular/theme';
import {
  NbButtonModule,
  NbCardModule,
  NbCheckboxModule,
  NbToggleModule,
  NbDialogModule,
  NbInputModule,
  NbPopoverModule,
  NbSelectModule,
  NbTabsetModule,
  NbTooltipModule,
  NbWindowModule,
  NbRadioModule,
  NbDatepickerModule,
  NbTreeGridModule,
  NbTableModule,
  NbStepperModule,
  NbSidebarModule,
  NbToastrModule,
  NbTimepickerModule,
  NbChatModule,
  NbSpinnerModule,
  NbMenuModule
} from '@nebular/theme';
import { DataserviseService } from './shared/dataservice';
import { PopupService } from './shared/popup';
import { ManageLookup } from './shared/lookup/lookupinformation.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';


import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
const routes: Routes = [
  { path: '', component: NavBarComponent }];
import { PipesModule } from './shared/pipe/pipe.module';
import { LocalStorageService } from './shared/localstorage.service';
import { AuthGuard } from './shared/authguard_service';
import { UtilitiesService } from './shared/utilities.service';
import { ModifierPopupComponent } from './admin/modifier-popup/modifier-popup.component';
import { CalculatorPopupComponent } from './admin/calculator-popup/calculator-popup.component';
import { RetailCalculatorPopupComponent } from './admin/retailpos-popup/retailcalculator-popup/retailcalculator-popup.component';
import { FormsModule } from '@angular/forms';
import { DinetablePopupComponent } from './admin/dinetable-popup/dinetable-popup.component';
import { DeliveryPopupComponent } from './admin/delivery-popup/delivery-popup.component';
import { PaidOrdersPopupComponent } from './admin/paidorderspopup/paidorderspopup.component';

import { RetailItempoppupComponent } from './admin/retailpos-popup/retailitempoppup/retailitempoppup.component';
import { RetailPaidOrdersPopupComponent } from './admin/retailpos-popup/retailpaidorderspopup/retailpaidorderspopup.component';
import { RetailQuickreportPopupComponent } from './admin/retailpos-popup/retailquickreport-popup/retailquickreport-popup.component';
import { HoldOrderPopupComponent } from './admin/retailpos-popup/holdorderpopup/holdorderpopup.component';
import { HoldOrderConfirmPopupComponent } from './admin/retailpos-popup/holdorderconfirmpopup/holdorderconfirmpopup.component';
import { HotKeysPopupComponent } from './admin/retailpos-popup/hotkeyspopup/hotkeyspopup.component';
import { SaleReturnOrderComponent } from './admin/salereturnorderpopuop/salereturnorderpopuop.component';
import { SaleReturnItemComponent } from './admin/salereturnitempopup/salereturnitempopup.component';

import { SplitBillPopupComponent } from './admin/splitbillpopup/splitbillpopup.component';
import { SplitBillNumpadPopupComponent } from './admin/splitbillnumpad-popup/splitbillnumpad-popup.component';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { AlertPopupComponent } from './admin/alert-popup/alert-popup.component';
import { SelectbranchComponent } from './admin/selectbranch/selectbranch.component';
import { PosloginComponent } from './admin/poslogin/poslogin.component';
import { TranslateService } from '../app/shared/translate.service';

import { AppSettings } from '../AppSettings';
import { VoucherPopupComponent } from './admin/pos_popup/voucher-popup/voucher-popup.component';
import { QtyboardPopupComponent } from './admin/pos_popup/qtyboard-popup/qtyboard-popup.component';
import { SpecialinstructionPopupComponent } from './admin/pos_popup/specialinstruction-popup/specialinstruction-popup.component';
import { DigiposloginComponent } from './admin/digiposlogin/digiposlogin.component';
import { NoofcustomersPopupComponent } from './admin/pos_popup/noofcustomers-popup/noofcustomers-popup.component';
import { CashDiscountPopupComponent } from './admin/pos_popup/cashdiscount-popup/cashdiscount-popup.component';
import { NumberPopupComponent } from './admin/pos_popup/number-popup/number-popup.component';
import { VoiditemPopupComponent } from './admin/pos_popup/voiditem-popup/voiditem-popup.component';
import { QuickreportPopupComponent } from './admin/quickreport-popup/quickreport-popup.component';
import { OpencashdrawerComponent } from './admin/opencashdrawer/opencashdrawer.component';
import { KdspoppupComponent } from './admin/kdspoppup/kdspoppup.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { KitchenreceiptComponent } from './admin/kitchenreceipt/kitchenreceipt.component';
import { LoyalityPopupComponent } from './admin/pos_popup/loyality-popup/loyality-popup.component';
import { ClosependingorderfirstpopupComponent } from './admin/pos_popup/closependingorderfirstpopup/closependingorderfirstpopup.component';
import { SplitCheckspopupComponent } from './admin/splitcheckspopup/splitcheckspopup.component';
import { AppConfigService } from './shared/app-config.service';
import { CustomerreceiptvoucherComponent } from './admin/customerreceiptvoucher/customerreceiptvoucher.component';
import { CategorymenupopupComponent } from './admin/categorymenupopup/categorymenupopup.component';
import { CateringfoodempinfoComponent } from './admin/cateringfoodempinfo/cateringfoodempinfo.component';
import { ReceiptpopupComponent } from './admin/receiptpopup/receiptpopup.component';
import { SalestypepopupComponent } from './admin/salestypepopup/salestypepopup.component';

import { SaleReturnPopupComponent } from './admin/salereturnpopup/salereturnpopup.component';
import { SaleReturnOrderReturnComponent } from './admin/salereturnorderreturnpopuop/salereturnorderreturnpopuop.component';
//import { CateringbookingComponent } from './admin/cateringbooking/cateringbooking.component';
import { salereturnurlsafe } from './admin/pipe/salereturnurlsafe';
import { A4sizeinvoiceComponent } from '../app/pages/reports/a4sizeinvoice/a4sizeinvoice.component';

import { RetailSaleReturnPopupComponent } from './admin/retailpos-popup/creditnote/retailsalereturnpopup/retailsalereturnpopup.component';
import { RetailSaleReturnOrderReturnComponent } from './admin/retailpos-popup/creditnote/retailsalereturnorderreturnpopuop/retailsalereturnorderreturnpopuop.component';
import { RetailSaleReturnOrderComponent } from './admin/retailpos-popup/creditnote/retailsalereturnorderpopuop/retailsalereturnorderpopuop.component';
import { RetailSaleReturnItemComponent } from './admin/retailpos-popup/creditnote/retailsalereturnitempopup/retailsalereturnitempopup.component';
import { A4sizereceiptArabicformatComponent } from './admin/a4sizereceipt-arabicformat/a4sizereceipt-arabicformat.component';
import { UpdateorderpopupComponent } from './admin/updateorderpopup/updateorderpopup.component';

const Config = (appConfig: AppConfigService) => {
  return () => {
    return appConfig.loadAppConfig();
  }
};
export function setupTranslateFactory(service: TranslateService):
  Function {
  return () => service.use(AppSettings.language)
}


@NgModule({
  declarations: [A4sizeinvoiceComponent, AppComponent, NavBarComponent, ModifierPopupComponent, CalculatorPopupComponent, RetailSaleReturnPopupComponent, RetailSaleReturnOrderReturnComponent, RetailSaleReturnOrderComponent, RetailSaleReturnItemComponent, RetailCalculatorPopupComponent, DinetablePopupComponent, DeliveryPopupComponent, PaidOrdersPopupComponent, RetailItempoppupComponent, RetailPaidOrdersPopupComponent, RetailQuickreportPopupComponent, HoldOrderPopupComponent, HoldOrderConfirmPopupComponent, HotKeysPopupComponent, SaleReturnOrderComponent, SaleReturnItemComponent, SplitBillPopupComponent, AlertPopupComponent, SelectbranchComponent, PosloginComponent, VoucherPopupComponent, QtyboardPopupComponent, SpecialinstructionPopupComponent, DigiposloginComponent, NoofcustomersPopupComponent, CashDiscountPopupComponent, NumberPopupComponent, VoiditemPopupComponent, QuickreportPopupComponent, KdspoppupComponent, AboutUsComponent, KitchenreceiptComponent, LoyalityPopupComponent, ClosependingorderfirstpopupComponent, SplitCheckspopupComponent, OpencashdrawerComponent, CategorymenupopupComponent, CateringfoodempinfoComponent, CustomerreceiptvoucherComponent, ReceiptpopupComponent, SalestypepopupComponent, SaleReturnPopupComponent, SaleReturnOrderReturnComponent, salereturnurlsafe, A4sizereceiptArabicformatComponent, SplitBillNumpadPopupComponent, UpdateorderpopupComponent],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    MatDialogModule,   
    NbCardModule,
    NbButtonModule,
    MatPaginatorModule,
    NbTabsetModule,
    MatSelectModule,

    NbInputModule,

    NbTreeGridModule,
    NbTableModule,
    NbTooltipModule,
    NbRadioModule,
    NbCheckboxModule,
    NbToggleModule,
    NbPopoverModule,
    NbSelectModule,

    NbStepperModule,
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbTimepickerModule.forRoot(),
    NbDialogModule.forRoot(),
    NbWindowModule.forRoot(),
    NbToastrModule.forRoot(),
    NbSpinnerModule,
    FormsModule,
    Ng2SmartTableModule,
    Ng2SearchPipeModule,
    NbChatModule.forRoot({
      messageGoogleMapKey: 'AIzaSyA_wNuCzia92MAmdLRzmqitRGvCF7wCZPY',
    }),
    CoreModule.forRoot(),
    ThemeModule.forRoot(),
    RouterModule.forRoot(routes),
    DragDropModule,
  ],
  providers: [AppConfigService,DataserviseService, PopupService, ManageLookup, LocalStorageService, AuthGuard, UtilitiesService, SpinnerService, PipesModule, DecimalPipe, TranslateService,
    {
      provide: APP_INITIALIZER,
      useFactory: Config,
      deps: [AppConfigService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: setupTranslateFactory,
      deps: [TranslateService],
      multi: true,
    }
  ],
  bootstrap: [AppComponent],
  exports: [
    PipesModule, salereturnurlsafe
  ]
  
})
export class AppModule {
}
