import { Injectable, ApplicationRef , Inject } from '@angular/core';


@Injectable()
export class SpinnerService {

  constructor(private ref: ApplicationRef ) { }


  public Isshow: boolean = false;
  private count = 0;

  show() {
    if (this.count == 0) {
      this.Isshow = true;
    }
    this.count++;
  }

  hide() {
    this.count--;
    if (this.count == -1)
      this.count = 0;

    if (this.count == 0) {
      this.Isshow = false;
    }
  }


//   ngAfterViewInit(){
//     this.ref.tick();
//  }

}
