
  <div id="invoice-POS" class="invoice-POS">

    <!-- <div id="top">
      <div class="logo" [ngStyle]="{'background': 'url(' + this.ObjCompanyInfo.LogoImageName + ')','height':'140px'}"></div>
 
    </div> -->

    <div id="mid">
      <!-- <div class="info" *ngIf="ObjOrderMaster.OutletName != 'N/A'">
        <div class="hd">{{ObjOrderMaster.OutletName}}</div>
        <div class="hd" style="text-align: center;">{{ObjOrderMaster.ArabicOutletName}}</div>
        <div class="mdatal">{{ObjOrderMaster.outletAddress}}</div>
        <div class="mdatal"></div>
        <div class="mdatal">{{ObjOrderMaster.AreaName}}</div>   
        <div class="mdatal" *ngIf="ObjCompanyInfo.VATNo != 'N/A'">VAT No. : {{ObjCompanyInfo.VATNo}}</div>
        <div class="mdatal" *ngIf="ObjOrderMaster.OutletPhone != 'N/A'">Tel : {{ObjOrderMaster.OutletPhone}}</div>
      </div> -->

      <div class="info" >
   
        <div class="dlvy" >{{ObjCompany.CompanyName}}</div>

      </div>
      <div class="info" >
   
        <div class="dlvy" >== {{ShiftEndReport}} ==</div>

      </div>
      <div>       
        <div class="mdatal">{{PrintDate}}:&nbsp; <br /> <span> {{printdate}}</span></div>       
        <div class="mdatal">{{ShiftStartDate}}:&nbsp; <br /> <span>{{ObjSalessummary.ShiftDate}}</span> </div>
        <div class="mdatal">{{ShiftEndDate}}:&nbsp; <br /> <span>{{ObjSalessummary.ShiftEndDate}}</span></div>        
      </div>
    <div >

      <div class="mdatal">{{HoursWorked}}:&nbsp; {{WorkedHour}}</div>

      <!-- <div class="mdatal">Hours Scheduled:&nbsp; {{ObjDayInfo.StartTime}}</div>     -->
      
    </div>
    <div>

      <div class="mdatal">{{EmployeeNamelabel}}:&nbsp; {{EmployeeName}} &nbsp;&nbsp; </div>     
      
    </div>

  </div>

    <div id="bot">


      <div id="table1" style="border-top: 2px dotted #0e0d0d;border-top: 2px dotted #0e0d0d">

        <div class="productheading">
          {{Products}}:
        </div>
        <!-- <table>        
          <tbody>
     
            <tr *ngFor="let row of ArrSalesDetail; let i = index" >
              <td>{{row.ProductCategory}} </td>
             
              <td ></td>
              <td></td>
              <td class="amountcls">{{row.NetAmount |number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</td>
            </tr>
            <tr>
              <td  style="font-weight: 600;" >{{ProductTotal}} </td>
             
              <td ></td>
              <td></td>
              <td  style="font-weight: 600;">{{TotalSale |number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</td>
            </tr>

          </tbody>
    
        </table> -->


        <table>        
          <tbody>
            <tr style="line-height: 0.5rem !important;">
              <th>{{Item}}</th>
              <th *ngIf="IsRcptProWise" >{{Qty}}</th>
              <th></th>
              <th>{{Amount}}</th>
            </tr>
          </tbody>
          </table>
        <table *ngFor="let row of ArrSalesDetail; let i = index">        
          <tbody>
            
            <tr *ngIf="!IsRcptProWise">
              <td style="font-weight: 600;" >{{row.ProductCategory}} </td>
             
              <td ></td>
              <td></td>
              <td class="amountcls" style="font-weight: 600;">{{row.NetAmount |number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</td>
            </tr>
            <ng-container *ngFor="let rowdtl of ArrProductDetail; let i = index" >
            <tr *ngIf="IsRcptProWise && row.Code == rowdtl.CategoryId" >
              <td style="text-indent: 4px; font-size: 16px !important;"><label >{{rowdtl.ProductName}}</label>  </td>
             
              <td style="font-size: 16px !important; text-align: center;">{{rowdtl.Quantity}}</td>
              <td></td>
              <td class="amountcls" style="font-size: 16px !important;">{{rowdtl.productTotal |number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</td>
            </tr>
            </ng-container>
          
          </tbody>
    
        </table>

        <table>
  <tbody>
             <tr>
              <td  style="font-weight: 600;" >{{ProductTotal}} </td>
             
              <td ></td>
              <td></td>
              <td  style="font-weight: 600;">{{TotalSale |number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</td>
            </tr>

          </tbody>
    
        </table>

      </div>
      
      <div id="table2" >

        <div class="productheading">
          {{Coupons}}:
        </div>
        <table>        
          <tbody  *ngIf="ArrCoupansDetail.length > 0">
     
            <tr  *ngFor="let row of ArrCoupansDetail; let i = index">
              <td> {{row.VoucherCode}}</td>
             
              <td ></td>
              <td></td>
              <td class="amountcls">{{row.PromoAmount |number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</td>
            </tr>
            

          </tbody>  
    
        </table>
        <table>
          <tbody>
            <tr>
              <td  style="font-weight: 600;" >{{CouponsTotal}} </td>
             
              <td ></td>
              <td></td>
              <td  style="font-weight: 600;">{{TotalCoupans |number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</td>
            </tr>
  
          </tbody>
        </table>

      </div>
      
      <div id="table9" >             
        <table style="border-top: 2px dotted #0e0d0d">
          <tbody>
            <tr>
              <td style="font-weight: 600;">{{CancelVoidAmount}} </td>

              <td></td>
              <td></td>
              <td style="font-weight: 600;">{{ObjSalessummary.CancelVoidAmount |number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</td>
            </tr>
            <tr>
              <td style="font-weight: 600;">{{TIPAmount}} </td>

              <td></td>
              <td></td>
              <td style="font-weight: 600;">{{ObjSalessummary.TIPAmount |number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</td>
            </tr>
            <tr>
              <td style="font-weight: 600;">{{SalesReturnAmount}} </td>

              <td></td>
              <td></td>
              <td style="font-weight: 600;">{{ObjSalessummary.SalesReturnAmount |number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</td>
            </tr>
          </tbody>
        </table>

      </div>
           
      <div id="table3" style="border-top: 2px dotted #0e0d0d;border-top: 2px dotted #0e0d0d;border-bottom: 2px dotted #0e0d0d;margin-bottom: 5px;">

      
        <table>        
          <tbody>
     
            <tr>
              <td>{{Noofcustomers}}: </td>
             
              <td ></td>
              <td></td>
              <td class="amountcls">{{ObjSalessummary.NoOfCust |number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</td>
            </tr>
            <tr>
              <td>{{NumberofTransactions}}: </td>
             
              <td ></td>
              <td></td>
              <td class="amountcls">{{ObjSalessummary.NoOfTrans |number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</td>
            </tr>
            <tr>
              <td>{{TotalSubtotalExclVAT}}:</td>
             
              <td ></td>
              <td></td>
              <td class="amountcls">{{ObjSalessummary.TotalAmountNoTax |number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</td>
            </tr>
            <tr *ngIf="ObjSalessummary.ExtraChargeAmount">
              <td>{{ObjSalessummary.ExtraChargeDescription}}:</td>
             
              <td ></td>
              <td></td>
              <td class="amountcls">{{ObjSalessummary.ExtraChargeAmount |number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</td>
            </tr>
            <!--<tr>
              <td>{{TotalDiscountCoupons}}:</td>
             
              <td ></td>
              <td></td>
              <td class="amountcls">{{TotalCoupans |number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</td>
            </tr>-->
            <tr *ngIf="TotalLoyaltyAmount > 0">
              <td>{{LoyaltyTotal}}:</td>
              <td ></td>
              <td></td>
              <td class="amountcls">{{TotalLoyaltyAmount |number:'1.'+ObjCompany.NumOfDecimal+'-'+ObjCompany.NumOfDecimal+''}}</td>
            </tr>
            <tr>
              <td>{{TotalTaxAmount}}:</td>
              
              <td ></td>
              <td></td>
              <td class="amountcls">{{ObjSalessummary.TotalTaxAmount |number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</td>
            </tr>
            <tr>
              <td>{{TotalNetAmountInclVAT}}:</td>
              
              <td ></td>
              <td></td>
              <td class="amountcls">{{ObjSalessummary.NetAmount |number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</td>
            </tr>
            <tr>
              <td>{{AverageNumberofCustomers}}: </td>
             
              <td ></td>
              <td></td>
              <td class="amountcls">{{ObjSalessummary.AvgCust |number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</td>
            </tr>
            <tr>
              <td>{{AverageTransactionsValue}}: </td>
             
              <td ></td>
              <td></td>
              <td class="amountcls">{{ObjSalessummary.AvgTrans |number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</td>
            </tr>
            <tr>
              <td>{{AveragevaluePerCustomers}}: </td>
             
              <td ></td>
              <td></td>
              <td class="amountcls">{{ObjSalessummary.AvgPerValueCust |number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</td>
            </tr>
            <!-- <tr>
              <td>Avg Sale per Employee Hour: </td>
             
              <td ></td>
              <td></td>
              <td class="amountcls">{{AvgSalePerEmployee |number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</td>
            </tr> -->
          
          </tbody>
    
        </table>

      </div>
      
      <div id="table3" style="border-top: 2px dotted #0e0d0d;border-top: 2px dotted #0e0d0d;border-bottom: 2px dotted #0e0d0d;margin-bottom: 5px;">      
        <table>
          <tbody>
            <tr>
              <td  style="font-weight: 600;" >{{CashInAmount}}:</td>
             
              <td ></td>
              <td></td>
              <td  style="font-weight: 600;">{{ObjSalessummary.CashInAmount |number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</td>
            </tr>
  
          </tbody>
        </table>
      </div>

      <div id="table2" *ngIf="ArrPOSTenders.length > 0" style="border-top: 2px dotted #0e0d0d;border-top: 2px dotted #0e0d0d;border-bottom: 2px dotted #0e0d0d;margin-bottom: 5px;">

        <div class="info" style="font-size: 21px; font-weight: 600; ">
        <label style="border-bottom: 1px dotted black ;">{{POSTendered}}</label>
        </div>

        <div class="row" *ngFor="let row of ArrPOSTenders; let i = index" style="border-bottom: 1px solid #dbd8d8;">

          <div class="col-md-6" style="font-size: 21px; font-weight: 549; font-family: Calibri !important;">{{row.PaymentType}}</div>
          <!-- <div class="col-md-4"style="font-size: 15px; " >{{Sales}}:</div>
          <div class="col-md-4"></div> -->
          <div class="col-md-6" style="text-align: right;font-size: 21px; font-weight: 549;font-family: Calibri !important;">{{row.SaleAmount |number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</div>       
        </div>

      
      </div>
      <div id="table2" *ngIf="ArrCashOutData.length > 0"  style="border-top: 2px dotted #0e0d0d; border-bottom: 2px dotted #0e0d0d;">

        <div class="info" style="font-size: 21px; font-weight: 600; ">
        <label style="border-bottom: 1px dotted black ;">{{POSCashOut}}</label>
        </div>

        <div class="row" *ngFor="let row of ArrCashOutData; let i = index" style="border-bottom: 1px solid #dbd8d8;">

          <div class="col-md-6" style="font-size: 21px; font-weight: 549; font-family: Calibri !important;">{{row.PaymentType}} </div>
          <!-- <div class="col-md-4"style="font-size: 15px; " >{{POSTendered}} </div>
          <div class="col-md-4"></div> -->
          <div class="col-md-6" style="text-align: right;;font-size: 21px; font-weight: 549;font-family: Calibri !important;">{{row.CashOutAmount |number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</div>
    

        </div>

      
      </div>

      <div id="table2" *ngIf="ArrOvershort.length > 0"  style="border-top: 2px dotted #0e0d0d; border-bottom: 2px dotted #0e0d0d;">

        <div class="info" style="font-size: 21px; font-weight: 600; ">
        <label style="border-bottom: 1px dotted black ;">{{OverShortBaseCurrency}}</label>
        </div>

        <div class="row" *ngFor="let row of ArrOvershort; let i = index" style="border-bottom: 1px solid #dbd8d8;">

          <div class="col-md-6" style="font-size: 21px; font-weight: 549; font-family: Calibri !important;">{{row.PaymentName}} </div>
          <div class="col-md-6" style="text-align: right;font-size: 21px; font-weight: 549;font-family: Calibri !important;">{{row.Overshort |number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</div>
    

        </div>

      
      </div>
      <!-- <div id="table2"  style="border-top: 2px dotted #0e0d0d; margin-top: 5px;">

   

        <div class="row" style="border-bottom: 1px solid #dbd8d8;">

          <div class="col-md-12" style="font-size: 21px; font-weight: 549; font-family: Calibri !important;"></div>
          <div class="col-md-8"style="font-size: 15px; " >{{OverShortBaseCurrency}}</div>
          
          <div class="col-md-4" style="text-align: right;">{{Overshort}}</div>
    

        </div>

      
      </div> -->

      
      <div id="legalcopy">
   
        <button class="label clsB1 doNotPrint" style="border:0" (click)="printComponent()">{{Printorder}}</button>
      </div>

    </div>
  </div>
