      <div style="display: block;padding-top: 0px;">
        <div class="child1" style="max-width: none;">{{SearchReceipt}} <img src="./assets/images/lookupclose.png" class="cross-icon" style="float: right;cursor: pointer;margin-top: -5px;" (click)="btnclose()"/></div>
      </div>
      <hr style="margin-top: 10px;"/>

    <div class="row">
    
    </div>
    <div class="row">
    <div class="col-md-12">
      <div class="row" style="padding-bottom:5px">
      <div class="col-md-3 col-sm-6">
          <label class="label">{{ReceiptNo}}</label>
          <input nbInput fullWidth [(ngModel)]="ObjGridFilterData.ReceiptNo">
      </div>
      <!-- <div class="col-md-3 col-sm-6">
          <label class="label">{{MobileNo}}</label>
          <div>
            <input nbInput fullWidth [(ngModel)]="ObjGridFilterData.PhoneNo">
            <i class="far fa-keyboard" (click)="NumberKeypadPopup()" style="cursor: pointer;cursor: pointer;padding-right: 10px;position: relative;float: right;margin-top: -30px;font-size: 22px;"></i>
          </div>
      </div> -->
      <div class="col-md-3 col-sm-6">
          <label class="label">&nbsp;</label>
          <button class="btn btnfinish" (click)="getGridData()">{{Search}}</button>
        </div>
      </div>
      <div class="table-responsive" style="max-height: 42vh;" >
        <ng2-smart-table [settings]="settings" [source]="lstReceiptData" (userRowSelect)="onUserRowSelect($event)">
        </ng2-smart-table>
      </div>
    </div>
   
  </div>



    

