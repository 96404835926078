<div class="row" style="float: right;margin-top: -12px;margin-right: -12px;" (click)="btnclose()">
    <img src="./assets/images/lookupclose.png" style="cursor: pointer;">
  </div>

<div>
    <h1 style="text-align: center;font-size: 30px;">{{PleaseClosePendingOrdersFirstthenclosetheshift}}</h1>
  </div>


  <div class="row" style="padding-top: 5px;padding-bottom: 10px;">
    <div class="col-md-12" style=" text-align: right;">
     <button type="submit" nbButton status="success" (click)="ProceedAnywayClick()" [disabled]="loading">Proceed Anyway</button>   
    </div>
    </div>
