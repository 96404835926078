import { Component, OnInit, Inject } from '@angular/core';
import { DataserviseService } from '../../../../shared/dataservice';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrderMaster } from './../liveorder.component';
import { UtilitiesService } from '../../../../shared/utilities.service';
import { SpinnerService } from '../../../../shared/spinner';

@Component({
  selector: 'ngx-riderpopup',
  templateUrl: './riderpopup.component.html',
  styleUrls: ['./riderpopup.component.scss']
})
export class RiderpopupComponent implements OnInit {

  ddlRiders: any = [];
  ObjOrderMaster: OrderMaster = new OrderMaster();
  constructor(private Utilities: UtilitiesService, private dataservice : DataserviseService,  public dialogRef: MatDialogRef<RiderpopupComponent>, @Inject(MAT_DIALOG_DATA) public data: any,private _spinnerService: SpinnerService) { 
    
    this.ObjOrderMaster = this.data.data;
    this.ObjOrderMaster.CreatedBy = this.Utilities.getUserId();
    this.ObjOrderMaster.ModifyBy = this.Utilities.getUserId();
  }

  ngOnInit(): void {
    this.GetDropdownSetups();
  }
  GetDropdownSetups(): void {
    this.dataservice.get('LiveOrder/GetRiderName/' + this.ObjOrderMaster.OutletId)
      .subscribe(data => {


          this.ddlRiders = data[0]['Riders'];
         
        
      }, error => alert('error'));

  }

 SelectedRider(): void {

    // alert(this.ObjOrderMaster.RiderID);

    // if (this.ObjOrderMaster.RiderID == undefined || this.ObjOrderMaster.RiderID == '') {
    //   alert('Please select rider!');
    //   return;
    // }
   if (this.ObjOrderMaster.RiderID != undefined && this.ObjOrderMaster.RiderID != null) {
     this.dialogRef.close(this.ObjOrderMaster.RiderID);
   } else {
     this.dialogRef.close('0');
   }
    
  }
  ClosePopup(): void {
    this.dialogRef.close(undefined);
  }
 
}
