<link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous" />

<div id="maindiv">
  <div style="display: inline-block;">
    <div class="backcircle" style="display: inline-block;" (click)="backClick()"> <i class="fa fa-chevron-left backIcon" aria-hidden="true"></i></div>
    <div style="display:inline-block;font-size: 15px;color:#2b436e;padding-left: 8px;"><b>{{Back}}</b></div>
    <div *ngIf="ShowCustomerLookup" style="display: inline-block; font-size: 15px; color: #2b436e; padding-left: 300px;">
      <label for="bBarcode" class="label" style="margin: 0px; font-size: 15px !important;color: #2b436e !important; ">{{Customer}}</label>
      <input name="Customer" [(ngModel)]="custName" class="custInput" />
      <i class="fas fa-search fontsize" (click)="openDialogCustomerLookUp()" style="cursor: pointer;"></i>
    </div>

  </div>
  <!-- <table width="85%" style="float: right;">
    <tr>
      <td><hr style="border-top: 2px solid #e7e7e7;" /></td>
      <td style="width:1px; padding: 0 10px; white-space: nowrap;color:#2b436e ;"><b>Checkout Order # 000000</b></td>
      <td><hr style="border-top: 2px solid #e7e7e7;"/></td>
    </tr>
  </table> -->



  <div id="totalpayment" class="totalpayment">
    {{lblTotalPayment}} <div style="float: right;padding-left: 30px;">
      <b>
        {{CurrencyCode}} {{TotalAmount | number:'1.'+NumOfDecimal+'-'+NumOfDecimal}}
      </b>
    </div>
  </div>

  <table width="100%" style="margin-top: -5px;margin-bottom: 10px;">
    <tr>
      <td style="width:1px; padding: 0 10px; white-space: nowrap;color:#2b436e ;"><b>{{PaymentMethods}}</b></td>
      <td><hr style="border-top: 2px solid #e7e7e7;" /></td>
    </tr>
  </table>

  <div class="col-md-12">
    <div id="rowdiv" class="row">
      <div class="col-md-7 col-sm-12" style="padding-right: 0px;height: 185px;overflow: auto;">
        <div id="rowdiv" >
          <div *ngFor="let objPayModes of lstPaymentMode" [ngClass]="[rdPaymentMethod == objPayModes.PaymentName ? 'innerbox_selected' : 'inner_box']" (click)="PaymentTypeChangeFn(objPayModes)">
            <img class="icon_style" loading="lazy" style="width: 58%;height: 50%;" [src]="objPayModes.Image!= null ? objPayModes.Image : moneyImg" />
            <!-- <i [ngClass]="[rdPaymentMethod == objPayModes.PaymentName ? 'fal fa-money-bill-alt iconstyle_selected' : 'fal fa-money-bill-alt icon_style']"></i> -->
            <p [ngClass]="[rdPaymentMethod == objPayModes.PaymentName ? 'iconheading_selected' : 'icon_heading']">{{objPayModes.PaymentName}}</p>
          </div>
          <!-- <div [ngClass]="[rdPaymentMethod == 'Card' ? 'innerbox_selected' : 'inner_box']" (click)="PaymentTypeChangeFn('Card')"><i [ngClass]="[rdPaymentMethod == 'Card' ? 'fal fa-credit-card iconstyle_selected' : 'fal fa-credit-card icon_style']"></i><p [ngClass]="[rdPaymentMethod == 'Card' ? 'iconheading_selected' : 'icon_heading']">Card</p></div>
          <div [ngClass]="[rdPaymentMethod == 'eWallet' ? 'innerbox_selected' : 'inner_box']" (click)="PaymentTypeChangeFn('eWallet')"><i [ngClass]="[rdPaymentMethod == 'eWallet' ? 'fal fa-wallet iconstyle_selected' : 'fal fa-wallet icon_style']"></i><p [ngClass]="[rdPaymentMethod == 'eWallet' ? 'iconheading_selected' : 'icon_heading']">eWallet</p></div>
          <div class="inner_box"><b><h3 class="icon_heading">More Options</h3></b></div> -->
        </div>
        <!-- <br/>
        <br/>
        <br/>
        <br/> -->



      </div>

      <div class="col-md-5 col-sm-12">

        <div class="calculator card">
          <input type="text" maxlength="15" class="calculator-screen" [(ngModel)]="cashPaidAmount" (change)="changeEvent()" (ngModelChange)="inputValueChange($event)" onpaste="return false" ondrop="return false;" (keypress)="numberOnly($event,cashPaidAmount)" />
          <div class="calculator-keys">
            <button type="button" value="7" class="btn btn-light waves-effect" (click)="calcButtonclick('7')">7</button>
            <button type="button" value="8" class="btn btn-light waves-effect" (click)="calcButtonclick('8')">8</button>
            <button type="button" value="9" class="btn btn-light waves-effect" (click)="calcButtonclick('9')">9</button>
            <button type="button" value="4" class="btn btn-light waves-effect" (click)="calcButtonclick('4')">4</button>
            <button type="button" value="5" class="btn btn-light waves-effect" (click)="calcButtonclick('5')">5</button>
            <button type="button" value="6" class="btn btn-light waves-effect" (click)="calcButtonclick('6')">6</button>
            <button type="button" value="1" class="btn btn-light waves-effect" (click)="calcButtonclick('1')">1</button>
            <button type="button" value="2" class="btn btn-light waves-effect" (click)="calcButtonclick('2')">2</button>
            <button type="button" value="3" class="btn btn-light waves-effect" (click)="calcButtonclick('3')">3</button>
            <button type="button" value="0" class="btn btn-light waves-effect" (click)="calcButtonclick('0')">0</button>
            <button type="button" class="decimal function btn btn-secondary" value="." (click)="calcButtonclick('.')">.</button>
            <button type="button" class="all-clear function btn btn-success btn-sm" value="enter" (click)="removeNumber()"><img src="./assets/images/BackSpace.png" style="width: 75px;"></button>
           
            <button type="button" class="all-clear function btn btn-danger btn-sm" value="all-clear" (click)="calcButtonclick('AC')">{{CLEAR}}</button>
            <!-- <button type="button" class="all-clear function btn btn-success btn-sm" value="enter" (click)="clickAll()">PAY</button> -->
            <button type="button" class="all-clear function btn btn-success btn-sm" value="enter" (click)="clickEnter()" style="width: 203%;">{{PAY}}</button>

          </div>

          <p class="caption status-danger" *ngIf="enterAmount" style="padding-left: 22px;margin-top: -10px;">{{pleaseenterAmount}}</p>
          <p class="caption status-danger" *ngIf="SelectCustomer" style="padding-left: 22px;margin-top: -10px;">{{pleaseselectCustomer}}</p>
          <p class="caption status-danger" *ngIf="maxlimit" style="padding-left: 22px;margin-top: -10px;">{{pleaseselectCustomer}}</p>
          <p class="caption status-danger" *ngIf="ercAmountlessthentotalAmount" style="padding-left: 22px;margin-top: -10px;">Enter complete remaining amount for ECR Payment.</p>
        </div>

      </div>
      <div class="payButtonDiv">
        <label for="one" style="display: block;color:#2b436e ;">{{ReturnedCash}}({{CurrencyCode}})</label>
        <input class="calculator-screen returnAmtTextbox" type="text" [(ngModel)]="ReturnAmount" readonly />
        <!-- <button class="paybtn" (click)="paybill()" [disabled] = "btnDisabled"> Pay</button> -->
        <label for="one" style="display: block;color:#2b436e ;">{{Reference}}</label>
        <input class="calculator-screen returnAmtTextbox" style="background: white;color: black;border: 1px solid lightgray;width:45% !important" type="text" [(ngModel)]="Remarks" />
        <button *ngIf="ShowERCPayButton" type="button" class="all-clear function btn btn-success btn-sm" value="enter" (click)="ClickECRPaybutton()" style="font-size: 1rem !important;height:30px !important">ECR Payment</button>
      </div>
    </div>

    <!-- <div class="menubottom">
      <div class="icon_text"><i class="fa fa-gift " style="font-size: 40px;color: #05a9e6;"></i><p class="icon_heading">Gift Vouchers</p></div>
      <div class="icon_text"><i class="fa fa-envelope " style="font-size: 40px;color: #05a9e6;"></i><p class="icon_heading">Email</p></div>
      <div class="icon_text"><i class="fa fa-print " style="font-size: 40px;color: #05a9e6;"></i><p class="icon_heading">Reprint</p></div>
      <div class="icon_text"><i class="fa fa-redo " style="font-size: 40px;color: #05a9e6;"></i><p class="icon_heading">Refund</p></div>
      <div class="icon_text"><i class="fa fa-award " style="font-size: 40px;color: #05a9e6;"></i><p class="icon_heading">Redeem</p></div>
    </div> -->
  </div>
  <div class="table-responsive" style="margin-top: 10px;margin-bottom: 0px;">
    <ng2-smart-table [settings]="settings" [source]="source1" (delete)="onDelete($event)"></ng2-smart-table>
  </div>
</div>
<div id="invoice-POS" class="invoice-POS" style="display:none">

  <div id="top">
    <div class="logo"></div>
    <!--<div class="info">
      <ngx-qrcode [elementType]="elementType"
                  [value]="QRUrl"
                  cssClass="aclass"
                  errorCorrectionLevel="L">
      </ngx-qrcode>
    </div>-->
  </div>

  <div id="mid">
    <div class="info" *ngIf="ObjOrderMaster.OutletName != 'N/A'">
      <div class="hd">{{ObjOrderMaster.OutletName}}</div>
      <div class="mdatal">{{ObjOrderMaster.outletAddress}}</div>
      <div class="mdatal">{{ObjOrderMaster.AreaName}}</div>
    </div>


    <div class="info" style="border-top: 2px dotted #eee;" *ngIf="ObjOrderMaster.CustomerName != 'N/A'">
      <div class="mdatal" style="font-weight: 700">{{ObjOrderMaster.CustomerName}}</div>
      <div class="mdatal">{{ObjOrderMaster.PhoneNo}}</div>

      <div class="mdatal" *ngIf="ObjCurrency.CountryName != 'Pakistan'">{{ObjOrderMaster.Address}}</div>
      <div style="border-bottom: 2px dotted #eee;" class="mdatal" *ngIf="ObjCurrency.CountryName == 'Pakistan'">{{ObjOrderMaster.AddressPK}}</div>
    </div>


    <div style="display:flex">
      <br />
      <div class="mdatal">Order No. :&nbsp;</div>
      <div class="mdatad"> {{ObjOrderMaster.OrderInvoiceNo}}</div>
      <div class="mrgn mdatal">Date :&nbsp;</div>
      <div class="mdatad"> {{ObjOrderMaster.OrderDate}}</div>
    </div>
  </div>

  <div id="bot">


    <div id="table">
      <table>
        <thead>
          <tr>
            <th>Description</th>
            <th class="qty">Qty</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of ArrOrderDetail; let i = index">
            <td *ngIf="row.AddOnID == '0'">{{row.ProductName}}</td>
            <td *ngIf="row.AddOnID != '0'" style="text-indent: 10px">{{row.AddOnName}}</td>
            <td class="qty">{{row.Quantity}}</td>
            <td>{{row.Amount|number:'1.'+ObjCurrency.NumOfDecimal+'-5'}}</td>
          </tr>

        </tbody>
        <tfoot>
          <tr>
            <td colspan="1">Sub Total</td>
            <td>{{CurrencyCode}}</td>
            <td>{{ObjOrderMaster.TotalAmount|number:'1.'+ObjCurrency.NumOfDecimal+'-5'}}</td>
          </tr>
          <tr>
            <td colspan="1">Discount</td>
            <td>{{CurrencyCode}}</td>
            <td>{{ObjOrderMaster.TotalDiscount|number:'1.'+ObjCurrency.NumOfDecimal+'-5'}}</td>
          </tr>
          <tr>
            <td colspan="1">Tax</td>
            <td>{{CurrencyCode}}</td>
            <td>{{ObjOrderMaster.TotalTaxAmount|number:'1.'+ObjCurrency.NumOfDecimal+'-5'}}</td>
          </tr>
          <tr *ngIf="ObjOrderMaster.OrderType == 'Delivery'">
            <td colspan="1">Delivery Fee</td>
            <td>{{CurrencyCode}}</td>
            <td>{{ObjOrderMaster.DeliveryFee|number:'1.'+ObjCurrency.NumOfDecimal+'-5'}}</td>
          </tr>
          <tr>
            <td colspan="1">Net Amount</td>
            <td>{{CurrencyCode}}</td>
            <td>{{ObjOrderMaster.NetAmount|number:'1.'+ObjCurrency.NumOfDecimal+'-5'}}</td>
          </tr>
        </tfoot>
      </table>
    </div>

    <div id="legalcopy">
      <p class="legal">
        <strong>Thank you for shopping! come again</strong><br />

      </p>
    </div>

  </div>
</div>
