import { Component, OnInit,Input, Inject,EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA,MatDialog } from '@angular/material/dialog';
import { DataserviseService } from '../../shared/dataservice'; 
import { UtilitiesService } from '../../shared/utilities.service';
import { DatePipe } from '@angular/common';
import { NbLayoutDirection, NbLayoutDirectionService} from '@nebular/theme';

@Component({
  selector: 'ngx-kdspoppup',
  templateUrl: './kdspoppup.component.html',
  styleUrls: ['./kdspoppup.component.scss']
})
export class KdspoppupComponent implements OnInit {

  ItemBumping:any='';

  constructor(private UtilitiesService :  UtilitiesService,private datePipe: DatePipe,private dataservice : DataserviseService,public dialogRef: MatDialogRef<KdspoppupComponent>, @Inject(MAT_DIALOG_DATA) public data: any) 
  { 
    debugger
    this.OrderID = this.data.OrderID;
    this.CategoryID = this.data.CategoryID;
    this.objKds.OrderId =this.OrderID;
    this.ITemClickEvent = this.data.ITemClickEvent;
    if(this.ITemClickEvent)
    this.ItemBumping ='INDIVIDUAL ITEM BUMPING';
    else
    this.ItemBumping ='COMPLETE ITEM BUMPING';

  }

  OrderID : any;
  CategoryID : any;
  lstarrayMaster : any = [];  
  lstarrayDetails : any = [];
  companyLogo:string;
  currDate:any = new Date();
  ITemClickEvent : boolean;
  objKds : Kdspoppup  = new Kdspoppup();

  ngOnInit(): void 
  {
    debugger
  
    this.currDate = this.datePipe.transform(this.currDate, 'EEEE, MMMM d, y, h:mm:ss a zzzz');
    this.currDate = this.currDate.toString().substring(0, this.currDate.indexOf('GMT'))
    debugger
    this.LoadGrid();
  
  }

  
  LoadGrid() {
    debugger;

    this.lstarrayMaster = [];
   
      this.dataservice.get('KDSdashboard/GetOrderDetails/'+ this.OrderID + '/' + this.UtilitiesService.getSubscriptionID()+ '/' + this.CategoryID).subscribe(data => 
        {
        debugger;
        // if (this.isArrayHasValue(data[0]["lstarrayMaster"]))
        // {
        //   this.lstarrayMaster = data[0]["lstarrayMaster"];                  
        // }
        if (this.isArrayHasValue(data[0]["lstarrayDetails"]))
        {
          this.lstarrayDetails = data[0]["lstarrayDetails"];                  
        }

      });  
  }
  Close() : void
  {
this.dialogRef.close(undefined);
  }
  BumpClick() : void
  {
    debugger
    this.objKds.ItemClickevent = this.ITemClickEvent;
    if(this.ITemClickEvent && this.objKds.OrderDetailID)
    {this.UpdateProduct();}
    else if(!this.ITemClickEvent)
    {
      // if (window.confirm('Are you sure you want BUMP all Items')) 
      // {
        this.objKds.OrderDetailID = 0;
        this.UpdateProduct();
      // }
    }
    else
    {
      alert('Please select some Items')
    }
    
  }


  UpdateProduct() {
    debugger;

    this.lstarrayMaster = [];
    this.objKds.CompanyId = this.UtilitiesService.getSubscriptionID()
    this.objKds.BumpBy = this.UtilitiesService.getLoginUserId();
    this.objKds.BumpOn = this.UtilitiesService.GetCurrentDateTime();
   
      this.dataservice.post('KDSdashboard/UpdateProduct', this.objKds).subscribe(data => 
        {
        debugger;
 
        if(data == 'Update')
        {
          this.dialogRef.close(data)
        }
        else
        {
          alert('error')
        }
       

      });  
  }
  
  isArrayHasValue(obj: any[]): boolean {
    let isArrayWithLength = Boolean(Array.isArray(obj) && obj.length);
    return isArrayWithLength;
  }

  selectedproduct : any;
  tempArr = [];
  checkValue(row,event): void
  {
    debugger;
    if (event.target.checked) 
    {
      // this.tempArr = this.tempArr.filter(item => item !== BranchID);
      this.tempArr.push(row.OrderDetailID);
      this.objKds.OrderDetailID = this.tempArr.toString();
    }
    else 
    {
      this.tempArr = this.tempArr.filter(item => item !== row.ProductId);
      this.objKds.OrderDetailID = this.tempArr.toString();     
 
    }
  }



}


class Kdspoppup
{
  OrderId : any;
  ProductId : any;
  OrderDetailID : any;
  CompanyId : any;
  ItemClickevent : boolean;
  BumpBy : any;
  BumpOn : any;
}
