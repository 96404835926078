import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { AppConfigService } from './app-config.service';
// import { BehaviorSubject, Subject } from 'rxjs';
// import { Observable } from 'rxjs/Observable';

// import { TranslateService } from './translate.service';

@Injectable()
export class UtilitiesService {

  private ObjUser: any = [];
  receiptName: any = 'receipt';
  ApplicationCode: any;
  lstUser = [];
  companyName: any;
  counterName: any;
  // private currentUserSubject = new BehaviorSubject<any>(null)

  // public subject = new Subject<string>();
  constructor(private router: Router, private datepipe: DatePipe, private _config: AppConfigService) {
    debugger;
    if (window.location.href.includes('BackOffice')) {

      if (window.location.href.split('/').length > 5 && window.location.href.split('/')[5].toLowerCase() == 'poslogin') {
        this.companyName = window.location.href.split('/')[6].toLowerCase();
        this.counterName = window.location.href.split('/')[7].toLowerCase();
      }
    } else if (window.location.href.split('/').length > 4 && window.location.href.split('/')[4].toLowerCase() == 'poslogin') {
      debugger;
      this.companyName = window.location.href.split('/')[5].toLowerCase();
      this.counterName = window.location.href.split('/')[6].toLowerCase();
    }

    if (localStorage.getItem('sessionObject' + this.getURLCompanyName()) == null) {
      debugger;
      if (window.location.href.includes('digiposlogin')) {
        this.ApplicationCode = 'POS';
        this.router.navigate(['auth/digiposlogin']);
      } else if (window.location.href.includes('poslogin')) {
        this.router.navigate(['auth/poslogin/' + this.companyName + '/' + this.counterName]);
      } else {
        this.ApplicationCode = 'OrderOnline';
        this.router.navigate(['login']);
      }
    } else {
      this.lstUser = JSON.parse(localStorage.getItem('sessionObject' + this.getURLCompanyName()))[0];
      this.ApplicationCode = JSON.parse(localStorage.getItem('sessionObject' + this.getURLCompanyName()))[0][0].ApplicationCode;
    }

  }

  langCulture: any;
  SetLanguageCulture() {
    try {
      if (localStorage.getItem('LanguageCulture' + this.getURLCompanyName()) != null) {
        this.langCulture = localStorage.getItem('LanguageCulture' + this.getURLCompanyName());
        return this.langCulture;
        //  this.Translate.use(this.langCulture);
      }

    } catch (e) {
      console.error('Error getting Login User Id from Storage', e);
      return null;
    }
  }

  getLoginUserId() {
    try {
      this.ObjUser = JSON.parse(localStorage.getItem('sessionObject' + this.getURLCompanyName()))[0];
      return this.ObjUser[0].Code;
    } catch (e) {
      console.error('Error getting Login User Id from Storage', e);
      return null;
    }
  }

  getLoginUserName() {
    try {
      this.ObjUser = JSON.parse(localStorage.getItem('sessionObject' + this.getURLCompanyName()))[0];
      return this.ObjUser[0].Name;
    } catch (e) {
      console.error('Error getting Login User Id from Storage', e);
      return null;
    }
  }

  getUserId() {
    try {
      this.ObjUser = JSON.parse(localStorage.getItem('sessionObject' + this.getURLCompanyName()))[0];
      return this.ObjUser[0].UserId;
    } catch (e) {
      console.error('Error getting User Id from Storage', e);
      return null;
    }
  }

  getSubscriptionID() {
    try {
      this.ObjUser = JSON.parse(localStorage.getItem('sessionObject' + this.getURLCompanyName()))[0];
      return this.ObjUser[0].CompanyID;
    } catch (e) {
      console.error('Error getting Subscription ID from Storage', e);
      return null;
    }
  }
  getCompanyName() {
    try {
      this.ObjUser = JSON.parse(localStorage.getItem('sessionObject' + this.getURLCompanyName()))[0];
      return this.ObjUser[0].CompanyName;
    } catch (e) {
      console.error('Error getting Subscription ID from Storage', e);
      return null;
    }
  }
  getUserCountryCode() {
    try {
      this.ObjUser = JSON.parse(localStorage.getItem('sessionObject' + this.getURLCompanyName()))[0];
      return this.ObjUser[0].CountryCode;
    } catch (e) {
      console.error('Error getting Subscription ID from Storage', e);
      return null;
    }
  }
  getBrandName() {
    try {
      this.ObjUser = JSON.parse(localStorage.getItem('sessionObject' + this.getURLCompanyName()))[0];
      return this.ObjUser[0].BrandName;
    } catch (e) {
      console.error('Error getting Subscription ID from Storage', e);
      return null;
    }
  }
  getBranchId() {
    try {
      const name = this.getURLCompanyName();
     debugger;
      const obj = JSON.parse(localStorage.getItem('SelectedBranchRow' + name));
      return obj.ID;
    } catch (e) {
      console.error('Error getting default branch id from utilities.', e);
      return null;
    }
  }

  GetCurrentDateTime(): any {

    const date = new Date();

    const aaaa = date.getFullYear();
    let gg: any = date.getDate();
    let mm: any = (date.getMonth() + 1);

    if (gg < 10)
      gg = '0' + gg;

    if (mm < 10)
      mm = '0' + mm;

    const cur_day = aaaa + '-' + mm + '-' + gg;

    let hours: any = date.getHours();
    let minutes: any = date.getMinutes();
    let seconds: any = date.getSeconds();

    if (hours < 10)
      hours = '0' + hours;

    if (minutes < 10)
      minutes = '0' + minutes;

    if (seconds < 10)
      seconds = '0' + seconds;

    return cur_day + ' ' + hours + ':' + minutes + ':' + seconds;

  }
  GetCurrentDate(): any {

    const date = new Date();

    const aaaa = date.getFullYear();
    let gg: any = date.getDate();
    let mm: any = (date.getMonth() + 1);

    if (gg < 10)
      gg = '0' + gg;

    if (mm < 10)
      mm = '0' + mm;

    const cur_day = aaaa + '-' + mm + '-' + gg;

    let hours: any = date.getHours();
    let minutes: any = date.getMinutes();
    let seconds: any = date.getSeconds();

    if (hours < 10)
      hours = '0' + hours;

    if (minutes < 10)
      minutes = '0' + minutes;

    if (seconds < 10)
      seconds = '0' + seconds;

    return cur_day;

  }

  CurrentTimeAMPM() {

    const date = new Date();
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';

    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const Min = minutes < 10 ? '0' + minutes : minutes;
    const strTime = hours + ':' + Min + ' ' + ampm;
    return strTime;
  }

  getCalenderDate(date): any {
    return this.datepipe.transform(date, 'yyyy-MM-dd');
  }

  getCalenderDateTime(date123): any {

    const date = new Date();
    let hours: any = date.getHours();
    let minutes: any = date.getMinutes();
    let seconds: any = date.getSeconds();

    if (hours < 10)
      hours = '0' + hours;

    if (minutes < 10)
      minutes = '0' + minutes;

    if (seconds < 10)
      seconds = '0' + seconds;
    date123 = this.datepipe.transform(date123, 'yyyy-MM-dd');
    return date123 + ' ' + hours + ':' + minutes + ':' + seconds;
    // return this.datepipe.transform(date, 'yyyy-MM-dd HH:mm', 'en-US');
  }

  AppStatus: any;
  SelectedBrancRow: any;
   SelectedBranchName: any;
   SelectedBranchID: any;
   getReceiptURL(companyName, orderId, CountryCode, OutletID, AppStatus, IsFoodCatering): any {
     try {
       debugger;

       if (CountryCode == 'SA') {
         this.receiptName = 'arabicreceipt';
       } else {
         this.receiptName = 'receipt';
       }

       const parsedUrl = new URL(window.location.href);
       const baseUrl = parsedUrl.origin;
       localStorage.setItem('receiptCompnayName', this.getURLCompanyName());

       if ( this.receiptName == 'receipt') {
       if (window.location.href.includes('BackOffice'))
         return baseUrl + '/BackOffice/auth/' + this.receiptName + '/' + companyName + '/' + orderId + '/' + OutletID + '/' + AppStatus + '/' + IsFoodCatering ;
       else
         return baseUrl + '/auth/' + this.receiptName + '/' + companyName + '/' + orderId + '/' + OutletID + '/' + AppStatus + '/' + IsFoodCatering ;
       } else {
         if (window.location.href.includes('BackOffice'))
         return baseUrl + '/BackOffice/auth/' + this.receiptName + '/' + companyName + '/' + orderId + '/' + OutletID + '/' + AppStatus + '/' + IsFoodCatering ;
       else
         return baseUrl + '/auth/' + this.receiptName + '/' + companyName + '/' + orderId + '/' + OutletID + '/' + AppStatus + '/' + IsFoodCatering ;
       }
     } catch (e) {
       console.error('Error getting receipt url', e);
       return null;
     }
   }


   getDigiPosReceiptURL(companyName, orderId, CountryCode): any {
     try {
       debugger;
       this.AppStatus = this._config.environment.IsApplicationOnline;
       // this.AppStatus =   localStorage.getItem('AppStatus' + this.getURLCompanyName()).toString();

       if (localStorage.getItem('SelectedBranchRow' + this.getURLCompanyName()) != null && localStorage.getItem('SelectedBranchRow' + this.getURLCompanyName()) != undefined) {
         this.SelectedBrancRow = JSON.parse(localStorage.getItem('SelectedBranchRow' + this.getURLCompanyName()));
         this.SelectedBranchID = this.SelectedBrancRow.ID;
       }

       if (CountryCode == 'SA') {
         this.receiptName = 'arabicreceipt';
       } else {
         this.receiptName = 'receipt';
       }

       const parsedUrl = new URL(window.location.href);
       const baseUrl = parsedUrl.origin;
       localStorage.setItem('receiptCompnayName', this.getURLCompanyName());

      //  if( this.receiptName == 'receipt'  )
      //  {
      debugger;
      if (window.location.href.includes('BackOffice'))
        return baseUrl + '/BackOffice/auth/' + this.receiptName + '/' + companyName + '/' + orderId + '/' + this.SelectedBranchID + '/' + this.AppStatus + '/' + this.IsCateringFood;
      else
        return baseUrl + '/auth/' + this.receiptName + '/' + companyName + '/' + orderId + '/' + this.SelectedBranchID + '/' + this.AppStatus + '/' + this.IsCateringFood;
      //  }
      //  else
      //  {
      //    if (window.location.href.includes('BackOffice'))
      //    return baseUrl + '/BackOffice/auth/' + this.receiptName + '/' + companyName + '/' + orderId ;
      //  else
      //    return baseUrl + '/auth/' + this.receiptName + '/' + companyName + '/' + orderId  ;
      //  }
    } catch (e) {
      console.error('Error getting receipt url', e);
      return null;
    }
  }

  getSaleReturnReceiptURL(companyName, ReturnId, CountryCode): any {
    try {
      debugger;
      this.AppStatus = this._config.environment.IsApplicationOnline;
      // this.AppStatus =   localStorage.getItem('AppStatus' + this.getURLCompanyName()).toString();

      if (localStorage.getItem('SelectedBranchRow' + this.getURLCompanyName()) != null && localStorage.getItem('SelectedBranchRow' + this.getURLCompanyName()) != undefined) {
        this.SelectedBrancRow = JSON.parse(localStorage.getItem('SelectedBranchRow' + this.getURLCompanyName()));
        this.SelectedBranchID = this.SelectedBrancRow.ID;
      }

      if (CountryCode == 'SA') {
        this.receiptName = 'salereturnarabicreceipt';
      } else {
        this.receiptName = 'salereturnarabicreceipt';
      }

      const parsedUrl = new URL(window.location.href);
      const baseUrl = parsedUrl.origin;
      localStorage.setItem('receiptCompnayName', this.getURLCompanyName());

      //  if( this.receiptName == 'receipt'  )
      //  {
      debugger;
      if (window.location.href.includes('BackOffice'))
        return baseUrl + '/BackOffice/auth/' + this.receiptName + '/' + companyName + '/' + ReturnId + '/' + this.SelectedBranchID + '/' + this.AppStatus + '/' + this.IsCateringFood;
      else
        return baseUrl + '/auth/' + this.receiptName + '/' + companyName + '/' + ReturnId + '/' + this.SelectedBranchID + '/' + this.AppStatus + '/' + this.IsCateringFood;
      //  }
      //  else
      //  {
      //    if (window.location.href.includes('BackOffice'))
      //    return baseUrl + '/BackOffice/auth/' + this.receiptName + '/' + companyName + '/' + orderId ;
      //  else
      //    return baseUrl + '/auth/' + this.receiptName + '/' + companyName + '/' + orderId  ;
      //  }
    } catch (e) {
      console.error('Error getting receipt url', e);
      return null;
    }
  }


  getKitchenReceiptURL(companyName, orderId, CountryCode): any {
    try {
      debugger;

      this.receiptName = 'kitchenreceipt';

      const parsedUrl = new URL(window.location.href);
      const baseUrl = parsedUrl.origin;
      localStorage.setItem('receiptCompnayName', this.getURLCompanyName());
      if (window.location.href.includes('BackOffice'))
        return baseUrl + '/BackOffice/auth/' + this.receiptName + '/' + companyName + '/' + orderId;
      else
        return baseUrl + '/auth/' + this.receiptName + '/' + companyName + '/' + orderId;

    } catch (e) {
      console.error('Error getting receipt url', e);
      return null;
    }
  }

  checkscondition: any;
  getQRURL(companyName, orderId, CountryCode): any {
    try {

      this.AppStatus = this._config.environment.IsApplicationOnline;
      if (localStorage.getItem('SelectedBranchRow' + this.getURLCompanyName()) != null && localStorage.getItem('SelectedBranchRow' + this.getURLCompanyName()) != undefined) {
        this.SelectedBrancRow = JSON.parse(localStorage.getItem('SelectedBranchRow' + this.getURLCompanyName()));
        this.SelectedBranchID = this.SelectedBrancRow.ID;
      }

      if (CountryCode == 'SA') {
        this.receiptName = 'arabicreceipt';
      } else {
        this.receiptName = 'receipt';
      }

      this.AppStatus = this._config.environment.IsApplicationOnline;

      if (window.location.href.includes('BackOffice')) {
        this.checkscondition = false;
      } else {
        this.checkscondition = true;
      }
      if (this.receiptName == 'receipt') {
        return 'https://order.globalinformix.io/' + this.receiptName + '/' + companyName + '/' + orderId + '/' + this.SelectedBranchID + '/' + this.checkscondition;
      } else {
        return 'https://order.globalinformix.io/' + this.receiptName + '/' + companyName + '/' + orderId;
      }

      //  return 'http://localhost:4200/'+this.receiptName+'/' + companyName + '/' + orderId;
      // const parsedUrl = new URL(window.location.href);
      // const baseUrl = parsedUrl.origin;
      // localStorage.setItem('receiptCompnayName', this.getURLCompanyName())
      // if (window.location.href.includes('BackOffice'))
      //  return baseUrl + '/BackOffice/auth/' + this.receiptName + '/' + companyName + '/' + orderId;
      // else
      //  return baseUrl + '/auth/' + this.receiptName + '/' + companyName + '/' + orderId;

    } catch (e) {
      console.error('Error getting receipt url', e);
      return null;
    }
  }

  getdayEndReport(CompanyID, daydate, outletID, DayId): any {
    try {
      //     const parsedUrl = new URL(window.location.href);
      //     const baseUrl = parsedUrl.origin;
      // if (baseUrl.includes('87.236.51.123:8081'))
      //     return baseUrl + '/BackOffice/auth/dayendreceipt/' + companyName + '/' + orderId;
      // else
      //   return  baseUrl + '/auth/dayendreceipt/' + companyName + '/' + orderId;
      localStorage.setItem('receiptCompnayName', this.getURLCompanyName());
      const local = window.location.origin;

      // if (local.includes('87.236.51.123:8081'))
      if (window.location.href.includes('BackOffice'))
        return local + '/BackOffice/auth/dayendreceipt/' + CompanyID + '/' + daydate + '/' + outletID + '/' + DayId;
      else
        return local + '/auth/dayendreceipt/' + CompanyID + '/' + daydate + '/' + outletID + '/' + DayId;

    } catch (e) {
      console.error('Error getting receipt url', e);
      return null;
    }
  }

  geta4SizeArabicFormat(CompanyID, orderID): any {
    try {
      localStorage.setItem('receiptCompnayName', this.getURLCompanyName());
      const local = window.location.origin;

      if (window.location.href.includes('BackOffice'))
        return local + '/BackOffice/auth/a4arabicformat/' + CompanyID + '/' + orderID + '/' + this.IsCateringFood;
      else
        return local + '/auth/a4arabicformat/' + CompanyID + '/' + orderID + '/' + this.IsCateringFood;

    } catch (e) {
      console.error('Error getting receipt url', e);
      return null;
    }
  }
  geta4SizeReport(CompanyID, orderID): any {
    try {
      localStorage.setItem('receiptCompnayName', this.getURLCompanyName());
      const local = window.location.origin;

      if (window.location.href.includes('BackOffice'))
        return local + '/BackOffice/auth/a4sizereceipt/' + CompanyID + '/' + orderID + '/' + this.IsCateringFood;
      else
        return local + '/auth/a4sizereceipt/' + CompanyID + '/' + orderID + '/' + this.IsCateringFood;

    } catch (e) {
      console.error('Error getting receipt url', e);
      return null;
    }
  }
  geta4SizeInvoice(CompanyID, InvoiceID, Type): any {
    try {
       localStorage.setItem('receiptCompnayName', this.getURLCompanyName());
      const local = window.location.origin;

      if (window.location.href.includes('BackOffice'))
      return local + '/BackOffice/auth/a4Invoice/' + CompanyID + '/' + InvoiceID + '/' + Type;
    else
      return local + '/auth/a4Invoice/' + CompanyID + '/' + InvoiceID + '/' + Type;

      // if (window.location.href.includes('BackOffice'))
      //   return local + '/BackOffice/'+this.getCompanyName()+'/reports/a4invoice/' +  InvoiceID + '/' + Type;
      // else
      //   return local + '/'+this.getCompanyName()+'/reports/a4invoice/' + InvoiceID + '/' + Type;

    } catch (e) {
      console.error('Error getting receipt url', e);
      return null;
    }
  }


  getshiftEndReport(CompanyID, shiftId, daydate): any {
    try {
      debugger;



      const local = window.location.origin;
      localStorage.setItem('receiptCompnayName', this.getURLCompanyName());
      // if (local.includes('87.236.51.123:8081'))
      if (window.location.href.includes('BackOffice'))
        return local + '/BackOffice/auth/shiftendreceipt/' + CompanyID + '/' + shiftId + '/' + daydate;
      else
        return local + '/auth/shiftendreceipt/' + CompanyID + '/' + shiftId + '/' + daydate;

    } catch (e) {
      console.error('Error getting receipt url', e);
      return null;
    }
  }

  getEncrypt(key) {
    const dt = window.btoa(key).split('=').join('');
    return dt + this.getrandomkey();
  }
  getDecrypt(key) {
    const dt = this.removeChars(key);
    return window.atob(dt);
  }

  private getrandomkey() {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < 5; i++) {
      result += characters.charAt(Math.floor(Math.random() *
        charactersLength));
    }
    return result;
  }

  GetTimeOut(): any {
    try {
        return 3500;
    } catch (e) {
      console.error('Error getting receipt url', e);
      return null;
    }
  }
  private removeChars(str) {
    str = str.substring(0, str.length - 5);
    return str;
  }
  public GetVersionNo() {
    return 'Version : v2024.0513.02';
  }
  getconnectionmsg(): any {
    debugger;
    return 'Connection Lost. Please check your Internet connection';
  }
  getURLCompanyName() {
    try {
      let comp = '';
      if (window.location.href.includes('BackOffice')) {
        if (window.location.href.includes('poslogin') || window.location.href.includes('posretail') || window.location.href.includes('restaurantpos') || window.location.href.includes('retailpos') || window.location.href.includes('cateringbooking'))
          comp = window.location.href.split('/')[6];
        else if (window.location.href.includes('receipt') || window.location.href.includes('arabicreceipt') || window.location.href.includes('dayendreceipt') || window.location.href.includes('shiftendreceipt') || window.location.href.includes('a4sizereceipt') || window.location.href.includes('a4Invoice') || window.location.href.includes('a4arabicformat') || window.location.href.includes('salereturnarabicreceipt'))
          comp = localStorage.getItem('receiptCompnayName');
        else
          comp = window.location.href.split('/')[4].toLowerCase();
        return comp;
      } else {
        if (window.location.href.includes('poslogin') || window.location.href.includes('posretail') || window.location.href.includes('restaurantpos') || window.location.href.includes('retailpos') || window.location.href.includes('cateringbooking'))
          comp = window.location.href.split('/')[5];
        else if (window.location.href.includes('receipt') || window.location.href.includes('arabicreceipt') || window.location.href.includes('dayendreceipt') || window.location.href.includes('shiftendreceipt') || window.location.href.includes('a4sizereceipt') || window.location.href.includes('a4Invoice')  || window.location.href.includes('a4arabicformat') || window.location.href.includes('salereturnarabicreceipt'))
          comp = localStorage.getItem('receiptCompnayName');
        else
          comp = window.location.href.split('/')[3].toLowerCase();
        return comp;
      }

    } catch (e) {
      console.error('Error getting Subscription ID from Storage', e);
      return null;
    }
  }

  IsCateringFood: boolean = false;
  setIsCateringFood(value) {
    this.IsCateringFood = value;
  }

  getNoOfDecimals_Stock(): number {
    return 2;
  }
  ngOnDestroy() {
  }


  // SetUserInfo(lstCompanyInfo){
  //   this.subject.next('sumaniii');
  // }

  // eventing:any;
  // items() {
  //   // return this.subject.subscribe(event => this.event = event);
  //     this.subject.subscribe(data => {
  //       this.eventing = data;
  //     console.log("Subscriber got data >>>>> "+ data);
  //   });
  // }

  // items() {
  //    return this.currentUserSubject.value;
  // }

}


