import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA,MatDialog } from '@angular/material/dialog';
import { TranslatePipe } from '../../shared/pipe/translate.pipe';

@Component({
  selector: 'ngx-splitbillnumpad-popup',
  templateUrl: './splitbillnumpad-popup.component.html',
  styleUrls: ['./splitbillnumpad-popup.component.scss']
})
export class SplitBillNumpadPopupComponent implements OnInit {
  txtQty:any;

  OK : any = this.translate.transform('OK');
  Close : any = this.translate.transform('Close');

  qty: any;

  constructor(public dialog: MatDialog, public dialogRef: MatDialogRef<SplitBillNumpadPopupComponent>, @Inject(MAT_DIALOG_DATA) public parentdata: any,private translate:TranslatePipe) 
{}

  ngOnInit(): void {
    debugger
    this.qty = this.parentdata.qty;

  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }


  calcButtonclick(value){debugger
    if(value == 'AC' || value == ""){
      this.txtQty = 0;
    return
    }
    else {
      

      this.txtQty = this.txtQty == undefined ? value : this.txtQty + value;
      this.txtQty = this.txtQty.substring(0, 5);
  
      if (this.txtQty == '.')
        this.txtQty = '';
    }
  }
  ClickDecimal(): void {debugger
    var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

    if (!format.test(this.txtQty)) {
      this.txtQty = this.txtQty + '.';
    }

  }

  removeNumber() {
    debugger
    this.txtQty = this.txtQty.slice(0, -1);
 
  }
  inputValueChange(value){
    debugger
    // value = parseFloat(value)
    if(value == 'AC' || value == ""){
      this.txtQty = 0;
    return
    }
    else{
   
    }

    if (this.txtQty == '' && this.txtQty == '0')
      return false;
  }

  close(){
    this.dialogRef.close();
  }
  ok(){debugger
    if (this.txtQty > this.qty) {
      alert('Split qty should be less than or equal to item qty.');
      return;
    }
    if (this.txtQty == 0 || this.txtQty == '0') {
      alert('zero not allowed.');
      return;
    }
     

    this.dialogRef.close(this.txtQty);

  }

}
