import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA,MatDialog } from '@angular/material/dialog';
import { TranslatePipe } from '../../../shared/pipe/translate.pipe';

@Component({
  selector: 'ngx-cashdiscount-popup',
  templateUrl: './cashdiscount-popup.component.html',
  styleUrls: ['./cashdiscount-popup.component.scss']
})
export class CashDiscountPopupComponent implements OnInit {
  txtAmount:any;

  OK : any = this.translate.transform('OK');
  Close : any = this.translate.transform('Close');


  constructor(public dialog: MatDialog, public dialogRef: MatDialogRef<CashDiscountPopupComponent>, @Inject(MAT_DIALOG_DATA) public parentdata: any,private translate:TranslatePipe) 
{}

  ngOnInit(): void {debugger

  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }


  calcButtonclick(value){debugger
    if(value == 'AC' || value == ""){
      this.txtAmount = 0;
    return
    }
    else {
      

      this.txtAmount = this.txtAmount == undefined ? value : this.txtAmount + value;
      this.txtAmount = this.txtAmount.substring(0, 5);
  
      //if (this.txtAmount == '.')
      //  this.txtAmount = '';
    }
  }
  ClickDecimal(): void {debugger
    var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

    if (!format.test(this.txtAmount)) {
      this.txtAmount = this.txtAmount + '.';
    }

  }

  removeNumber() {
    debugger
    this.txtAmount = this.txtAmount.slice(0, -1);
 
  }
  inputValueChange(value){
    debugger
    // value = parseFloat(value)
    if(value == 'AC' || value == ""){
      this.txtAmount = 0;
    return
    }
    else{
   
    }

    if (this.txtAmount == '' && this.txtAmount == '0')
      return false;
  }

  close(){
    this.dialogRef.close();
  }
  ok() {
    debugger

    if (this.txtAmount == undefined || this.txtAmount == '') {
      this.dialogRef.close(0);
      return;
    }
    const decimalPattern: RegExp = /^(?:\d+)?(?:\.\d+)?$/;

    if (!decimalPattern.test(this.txtAmount)) {
      alert('Please enter valid value.')
    } else {
      this.dialogRef.close(this.txtAmount);
    }

  
    //.dialogRef.close(this.txtAmount);
    
  }

}
