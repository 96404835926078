import { Component, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';

import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { UtilitiesService } from '../../../shared/utilities.service';
import { DataserviseService } from '../../../shared/dataservice';
import { SelectbranchComponent } from '../../../admin/selectbranch/selectbranch.component';
import { MatDialog } from '@angular/material/dialog';
import { NbLayoutDirection, NbLayoutDirectionService } from '@nebular/theme';
import { TranslatePipe } from '../../../shared/pipe/translate.pipe';
import { TranslateService } from 'app/shared/translate.service';


@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;
  retrievedObject: any = [];
  sessionObject: any = [];
  ObjBillingData: BillingData = new BillingData();
  ObjSubscriptionPaymentDetail: SubscriptionPaymentDetail = new SubscriptionPaymentDetail();
  SelectedBranchName: any = '';
  DisplaySelectedBranchName: boolean = false;
  SelectedBrancRow: any;

  Welcome: any = this.Translate.transform('Welcome');
  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';

  userMenu = [{ title: this.Translate.transform('Profile') }, { title: this.Translate.transform('Logout') }];

  ObjloginData: any;
  IsTrailExpired: boolean = false;

  hideMenuOnClick: boolean = false;
  constructor(private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private userService: UserData,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    public router: Router,
    private Utilities: UtilitiesService,
    private dataservice: DataserviseService,
    public dialog: MatDialog,
    private Translate: TranslatePipe,
    private cdr: ChangeDetectorRef,
    private directionService: NbLayoutDirectionService,
    private _utility: UtilitiesService,
    private translateservice: TranslateService,
  ) {
  }
  lstUser = [];
  ngOnInit() {
    debugger;
    this.lstUser = JSON.parse(localStorage.getItem('sessionObject' + this._utility.getURLCompanyName()))[0];
    // if( localStorage.getItem('sessionObject') == null ||  localStorage.getItem('sessionObject') == '' ||  localStorage.getItem('sessionObject') == undefined){
    // if( localStorage.getItem('sessionObject') == null ||  localStorage.getItem('sessionObject') == '' ||  localStorage.getItem('sessionObject') == undefined){
    if (this.lstUser == null || this.lstUser.length == 0) {
      this.router.navigate(['login']);
    }
    this.getBillingDataData();
    const oppositeDirection = this.isRtl ? NbLayoutDirection.RTL : NbLayoutDirection.LTR;
    this.directionService.setDirection(oppositeDirection);



    this.menuService.onItemClick().subscribe((event) => {
      debugger;
      if (event.item.title === this.Translate.transform('Logout')) {
        this.logout();
      } else if (event.item.title === this.Translate.transform('Profile')) {
        this.Profile();
      }
    });

    this.currentTheme = this.themeService.currentTheme;

    this.userService.getUsers()
      .pipe(takeUntil(this.destroy$))
      .subscribe((users: any) => this.user = users.nick);

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);
    debugger;
    this.retrievedObject = localStorage.getItem('sessionObject' + this._utility.getURLCompanyName());
    this.retrievedObject = JSON.parse(this.retrievedObject)[0];
    if (this.retrievedObject !== null)
      this.sessionObject = this.retrievedObject[0];


    const { is } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint),
        takeUntil(this.destroy$),
      )
      .subscribe(currentBreakpoint => {
        this.userPictureOnly = currentBreakpoint.width < xl;
        this.hideMenuOnClick = currentBreakpoint.width <= is;
      });

    this.menuService.onItemClick().subscribe(() => {
      if (this.hideMenuOnClick) {
        this.sidebarService.collapse('menu-sidebar');
      }
    });

    if (localStorage.getItem('SelectedBranchRow' + this._utility.getURLCompanyName()) != null && localStorage.getItem('SelectedBranchRow' + this._utility.getURLCompanyName()) != undefined) {
      this.SelectedBrancRow = JSON.parse(localStorage.getItem('SelectedBranchRow' + this._utility.getURLCompanyName()));
      this.SelectedBranchName = this.SelectedBrancRow.OutletName;
      this.strname = this.SelectedBrancRow.OutletName;

      if (this.SelectedBranchName.length > 0) {
        this.DisplaySelectedBranchName = true;
      }

      // this.SelectedBranchName = this.SelectedBranchName.substring(0,) + ' ...';

    }

  }
  get isRtl() {
    return this.directionService.isRtl();
  }
  strname: any = '';
  ngAfterViewChecked(): void {

    // console.log(localStorage.getItem('SelectedBranchRow'));



    if (localStorage.getItem('SelectedBranchRow' + this._utility.getURLCompanyName()) != 'undefined' && localStorage.getItem('SelectedBranchRow' + this._utility.getURLCompanyName()) != undefined && localStorage.getItem('SelectedBranchRow' + this._utility.getURLCompanyName()) != null) {
      this.SelectedBrancRow = JSON.parse(localStorage.getItem('SelectedBranchRow' + this._utility.getURLCompanyName()));

      if (this.SelectedBrancRow.length > 0) {
        this.SelectedBranchName = this.SelectedBrancRow.OutletName;
        this.strname = this.SelectedBrancRow.OutletName;

        if (this.SelectedBranchName.length > 0) {
          this.DisplaySelectedBranchName = true;
        }
      }



      // this.SelectedBranchName = this.SelectedBranchName.substring(0,) + ' ...';

    }

    this.cdr.detectChanges();
  }

  getBillingDataData(): void {

    this.dataservice.get('subescription/GetBillingData/' + this.Utilities.getSubscriptionID())
      .subscribe(data => {
        debugger;
        if (this.isArrayHasValue(data[0]['BillingData'])) {
          this.ObjSubscriptionPaymentDetail = data[0]['BillingData'][0];

          const CurrentDate = new Date(this.Utilities.GetCurrentDateTime());

          if (this.ObjSubscriptionPaymentDetail.SubtractEndDate != null
            && this.ObjSubscriptionPaymentDetail.SubtractEndDate != undefined) {
            const BillingEndDate = new Date(this.ObjSubscriptionPaymentDetail.SubtractEndDate);
            if (CurrentDate.setHours(0, 0, 0, 0) >= BillingEndDate.setHours(0, 0, 0, 0)) {
              this.IsTrailExpired = true;
            }
          }

        } else {
          this.ObjBillingData = new BillingData();
        }

      }, error => alert('error'));
  }

  isArrayHasValue(obj: any[]): boolean {
    const isArrayWithLength = Boolean(Array.isArray(obj) && obj.length);
    return isArrayWithLength;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  websiteUser: any;
  posLogin: boolean = false;
  logout() {
    debugger;
    const oppositeDirection = NbLayoutDirection.LTR;
    this.directionService.setDirection(oppositeDirection);
    this.translateservice.useLogin('en');
    if (JSON.parse(localStorage.getItem('sessionObject' + this._utility.getURLCompanyName())))
      {
      const IsDigiPosLogin = JSON.parse(localStorage.getItem('sessionObject' + this._utility.getURLCompanyName()))[0][0].ApplicationCode;
    debugger;
    if (IsDigiPosLogin == 'POS') {
      this.posLogin = true;
      localStorage.clear();
      this.router.navigate(['auth/digiposlogin']);
      return;
    } else {
      sessionStorage.clear();
      localStorage.removeItem('poslogin_storage');
      localStorage.removeItem('receiptCompnayName');
      localStorage.removeItem('sessionObject' + this._utility.getURLCompanyName());
      localStorage.removeItem('LanguageCulture' + this._utility.getURLCompanyName());
      localStorage.removeItem('SelectedBranchRow' + this._utility.getURLCompanyName());
      this.router.navigate(['login']);
    }
  }
    // if(localStorage.getItem('hashKey') !=null && localStorage.getItem('hashKey') !=undefined && localStorage.getItem('hashKey') !=''){
    //   var storageValue  = localStorage.getItem('hashKey');
    //   var res = storageValue.split('&');
    //   this.websiteUser = res[2];
    //   if(this.websiteUser){
    //   if(this.websiteUser.indexOf('=') !== -1)
    //   this.websiteUser = this.websiteUser.replace('=', '');
    //   }
    //   let posObjUser = JSON.parse(localStorage.getItem('sessionObject'));
    //   let posUser = posObjUser[0].Code;
    //   if(this.websiteUser == posUser)
    //  localStorage.removeItem('hashKey');
    //   window.location.href = ' https://globalinformix.com//login';

    // }
    // else
    // localStorage.removeItem('sessionObject');
    // localStorage.removeItem('FormRights');
    // this.router.navigate(['login']);

  }
  Profile() {
    debugger;
    if (this.lstUser[0].UserId == undefined || this.lstUser[0].UserId == null)
      return;

    localStorage.setItem('profileclick' + this._utility.getURLCompanyName(), this.lstUser[0].UserId);
    const compName = this._utility.getCompanyName();

    // this.router.navigate([compName + '/usermanagement/adduser'])
    //  .then(() => {
    //    window.location.reload();
    //  });

    this.router.navigateByUrl(compName + '/usermanagement/adduser');
  }
  clickBrnachName() {
    const dummyArr = JSON.parse(localStorage.getItem('UserActiveinNoofBranch' + this.Utilities.getURLCompanyName()));
    if (dummyArr.length > 1) {
      this.SelectbranchPopup(dummyArr);
    }
  }

  SelectbranchPopup(dummyArr): void {
    const body = [dummyArr];
    const dialogRef = this.dialog.open(SelectbranchComponent, {
      width: '820px',
      height: '550px',
      data: { body },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {

      }

    });
  }


}
class BillingData {
  ID: any;
  CompanyId: any;
  BillingStartDate: any;
  BillingEndDate: any;
  Amount: any;
  RemainingAmount: any;
  CreatedBy: any;
  CreatedOn: any;
  ModifyBy: any;
  ModifyOn: any;
}
class SubscriptionPaymentDetail {
  SubPaymentDetailID: any;
  CompanyID: any;
  OutletID: any;
  StartDate: any;
  EndDate: any;
  SubtractEndDate: any;
  Fee: any;
  CreatedBy: any;
  CreatedOn: any;
  ModifyBy: any;
  ModifyOn: any;
}
