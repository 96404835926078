
<div id="invoice-POS" class="invoice-POS">
 
    <div id="mid">
      <!-- <div class="info" *ngIf="ObjOrderMaster.OutletName != 'N/A'">
        <div class="hd">{{ObjOrderMaster.OutletName}}</div>
        <div class="hd" style="text-align: center;">{{ObjOrderMaster.ArabicOutletName}}</div>
        <div class="mdatal">{{ObjOrderMaster.outletAddress}}</div>
        <div class="mdatal"></div>
        <div class="mdatal">{{ObjOrderMaster.AreaName}}</div>
        <div class="mdatal" *ngIf="ObjCompanyInfo.VATNo != 'N/A'">رقم الرضيبة / VAT No. : {{ObjCompanyInfo.VATNo}}</div>
        <div class="mdatal" *ngIf="ObjOrderMaster.OutletPhone != 'N/A'">Tel : {{ObjOrderMaster.OutletPhone}}</div>
      </div> -->
  
  
      <!-- <div class="info" style="border-top: 2px dotted #0e0d0d;border-bottom: 2px dotted #0e0d0d">
        <div class="dlvy">فاتورة ضريبية مبسطة</div>
        <div class="dlvy">Simplified Tax Invoice</div>
      </div> -->
      <div class="info" style="border-bottom: 2px dotted #0e0d0d">
  
        <div class="dlvy" *ngIf="ObjOrderMaster.OrderNo" > Order No.: {{ObjOrderMaster.OrderNo}}   رقم الطلب</div>
      </div>
  
      <div class="info" style="border-top: 2px dotted #0e0d0d;border-bottom: 2px dotted #0e0d0d">
        <div class="dlvy" *ngIf="ObjOrderMaster.OrderType != 'Dine In'">{{ObjOrderMaster.OrderType}}</div>
  
        <!-- <div *ngIf="ObjOrderMaster.CustomerName != 'N/A' && ObjOrderMaster.OrderType != 'Dine In'">
          <div class="mdatal" style="font-weight: 700">{{ObjOrderMaster.CustomerName
          }}
        </div>
          <div class="mdatal">{{ObjOrderMaster.PhoneNo}}</div>
  
          <div class="mdatal" *ngIf="ObjCurrency.CountryName != 'Pakistan'">Address: {{ObjOrderMaster.Address}} {{ObjOrderMaster.AdditionalDirection == 'N/A' || ObjOrderMaster.AdditionalDirection == '' ? '' : ' , ' +ObjOrderMaster.AdditionalDirection}}</div>
          <div style="border-bottom: 2px dotted #0e0d0d;" class="mdatal" *ngIf="ObjCurrency.CountryName == 'Pakistan'">Address: {{ObjOrderMaster.AddressPK}}</div>
        </div> -->
  
        <div class="info" *ngIf="ObjOrderMaster.OrderType == 'Dine In' && ObjOrderMaster.TableNumber != 0">
          <div class="dlvy">Dine In</div>
          <div class="dlvy">Table No.</div>
          <div class="mdatal" style="font-weight: 700">{{ObjOrderMaster.TableNumber}}</div>
  
  
        </div>
  
      </div>
  
  
  
      <div style="border-bottom: 2px dotted #0e0d0d">
  
        <div class="mdatal">Tran# / رقم الفاتورة :&nbsp; {{ObjOrderMaster.OrderInvoiceNo}} &nbsp;&nbsp; <span *ngIf="ObjOrderMaster.OrderType == 'Dine In' && ObjOrderMaster.TableNumber != 0">Cust# :&nbsp; 001</span></div>
  
        <div class="mdatal">Order Date :&nbsp; {{ObjOrderMaster.OrderDate}} , {{ObjOrderMaster.OrderTime}}</div>
    
        <div class="mdatal" *ngIf="ObjOrderMaster.Cashier != 'N/A'">Cashier :&nbsp; {{ObjOrderMaster.Cashier}}</div>
      </div>
  
  
  
    </div>
  
    <div id="bot">
  
  
      <div id="table">
        <table>
          <thead>
            <tr>
              <th>Description</th>
              <th>Qty</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let row of ArrOrderDetail; let i = index" [ngClass]="row.AddOnID != '0' ? 'borderDoted' : 'borderDoted'">
              <td *ngIf="row.AddOnID == '0'">{{row.ProductName}} {{(row.ProductSizeName === null || row.ProductSizeName.trim() === '' || row.ProductSizeName === 'N/A') ? '' : '(' + row.ProductSizeName +')'}}  <br /> <span style="text-indent: 10px">{{row.AProductName}}</span> <br /> <span *ngIf="row.SpecialRequest" style="text-indent: 10px"><b>Note: </b>{{row.SpecialRequest}}</span></td>
              <td *ngIf="row.AddOnID != '0'" style="text-indent: 10px">{{row.AddOnName}}</td>
              <td>{{row.Quantity}}</td>
              <!-- <td class="amountcls">{{row.OrignalAmount|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</td> -->
            </tr>
  
          </tbody>
       
        </table>
            

      </div>
   
  
    </div>
  </div>
  