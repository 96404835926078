import { Component, ElementRef, ViewChild } from '@angular/core';
import { DataserviseService } from '../../shared/dataservice';
import { PopupService } from '../../shared/popup';
import { ActivatedRoute } from '@angular/router';
import { formatDate } from '@angular/common';
import { UtilitiesService } from '../../shared/utilities.service';
import { MatDialog } from '@angular/material/dialog';
import { ModifierPopupComponent } from '../modifier-popup/modifier-popup.component';
import { CalculatorPopupComponent } from '../calculator-popup/calculator-popup.component';
import { DinetablePopupComponent } from '../dinetable-popup/dinetable-popup.component';
import { DeliveryPopupComponent } from '../delivery-popup/delivery-popup.component';
import { CategorymenupopupComponent } from '../../../app/admin/categorymenupopup/categorymenupopup.component'
import { PendingordersComponent } from '../pendingorders/pendingorders.component';
import { RecallRestaurantOrdersComponent } from '../recallrestaurantorders/recallrestaurantorders.component';
import { PaidOrdersPopupComponent } from '../paidorderspopup/paidorderspopup.component';
import { SaleReturnPopupComponent } from '../salereturnpopup/salereturnpopup.component';
import { TransfertableComponent } from '../transfertable/transfertable.component';
import { SplitBillPopupComponent } from '../splitbillpopup/splitbillpopup.component';
import { AlertPopupComponent } from '../../../app/admin/alert-popup/alert-popup.component'
import { VoucherPopupComponent } from '../../../app/admin/pos_popup/voucher-popup/voucher-popup.component'
import { QtyboardPopupComponent } from '../../../app/admin/pos_popup/qtyboard-popup/qtyboard-popup.component'
import { SpecialinstructionPopupComponent } from '../../../app/admin/pos_popup/specialinstruction-popup/specialinstruction-popup.component'
import { Router } from '@angular/router';
import { DecimalPipe } from '@angular/common';
import { TranslatePipe } from '../../shared/pipe/translate.pipe';
import { VoiditemPopupComponent } from '../../admin/pos_popup/voiditem-popup/voiditem-popup.component';
import { delay } from "rxjs/operators";
import { QuickreportPopupComponent } from '../../admin/quickreport-popup/quickreport-popup.component';
import { OpencashdrawerComponent } from '../../admin/opencashdrawer/opencashdrawer.component';
import { AboutUsComponent } from '../../about-us/about-us.component';
import { LoyalityPopupComponent } from '../pos_popup/loyality-popup/loyality-popup.component';
import { TransferorderpopupComponent } from '../transferorderpopup/transferorderpopup.component';
import { SplitCheckspopupComponent } from '../splitcheckspopup/splitcheckspopup.component';
import { CustomerreceiptvoucherComponent } from '../customerreceiptvoucher/customerreceiptvoucher.component';
import { SalestypepopupComponent } from '../salestypepopup/salestypepopup.component';
import { HoldOrderConfirmPopupComponent } from '../retailpos-popup/holdorderconfirmpopup/holdorderconfirmpopup.component';

@Component({
  selector: 'ngx-pos-retail',
  templateUrl: './pos-retail.component.html',
  styleUrls: ['./pos-retail.component.scss']
})
export class PosRetailComponent {

  ObjMasterData: PurchaseOrder = new PurchaseOrder();
  ObjDetailData: DetailData = new DetailData();
  ObjCompany: Company = new Company();
  ObjVFI_GetAuth: VFI_GetAuth = new VFI_GetAuth();


  arrayModifier: Modifierclass[] = [];
  //ArrDetailData: DetailData[] = [];
  btnSave: any = 'Save';
  SupplierDate: boolean = false;
  OrderDate: boolean = false;
  ItemMandatory: boolean = false;
  lstProductDetail = [];
  PONoMandatory: boolean = false;
  day: any;
  month: any;
  currentdate: any;
  Subtoal: number = 0;
  SubtoalForVAT: number = 0;
  DiscountAmont: number = 0;
  PromoAmount: number = 0;
  VAT: number = 0;
  Total: number = 0;
  CurrencyCode: any = '';
  BaseColor: any = '';
  NumOfDecimal: number = 0;
  TaxRate: number = 0;
  IsExtraCharge: boolean = false;
  IsActiveMinOrderValue: boolean = false;
  ExtraChargeDescription: any = '';
  ExtraChargeDescriptionArabic: any = '';
  ExtraChargePercentage: number = 0;
  ExtraChargeAmount: number = 0;
  ModifiresAmount: number = 0;
  DeliveryFee: number = 0;
  autoincreament: number = 0;

  lstCategories: any = [];
  lstMinimumOrderValue: any[];
  lstOrderType: any = [];
  lstBasket: any = [];
  lstSelectModifier: any = [];
  OrderTypeID: any = 0;
  isCategorySelected: boolean = false;
  CategoryId: any;
  ObjResturentInfo: ResturentInfo = new ResturentInfo();
  ObjOutletInfo: OutletInfo = new OutletInfo();
  lstProduct: any = [];
  lstSelectedProduct: any = [];
  lstData: any = [];
  lstModifier: any = [];
  lstProductSize: any = [];
  productstr: any = '';
  // Customer: any = '';

  ArrMasterData: any = [];
  ArrSelectedDetailData: any = []
  lstbasketdata = [];
  GuestCutomer = [];

  rdPaymentMethod: any = 'Cash';
  objoutletInfo: OutletInfo = new OutletInfo();
  remarks: any = '';
  OrderType: any = 'N/A';
  OrderPerpStatus: any = 'New';
  DiscountCode: any;
  TimeStampSelected: any = '30';
  TaxId: number = 0;
  lstSelectedModifrs: any = [];
  UserName: any;
  MobileNo: any;
  MaxCreditLimit: any;
  ReturnAmount: number = 0;
  chkKOT: boolean = false;
  KotOrderID: any;
  CustomerId: any;
  SelectedBrancRow: any;
  SelectedBranchName: any;
  SelectedBranchID: any;
  IsDirectPrintReceipt: any;
  IsDirectPrintKOT: any;
  ServerLink: any;
  SelectedOrderInvoice: any;
  lstUser = [];

  PromoCode: any = '';
  ReceiptURL: any = '';
  logo: any = '../../../assets/images/globalinformix.png';

  ShiftID: any;
  DayID: any;
  SearchBarcode: any = '';
  IsBarcodeFound: boolean = false;
  lstProductAll = [];
  lstPosMenu = [];
  ObjdiscountVoucher: discountVoucher = new discountVoucher();
  lstVoucherPaymentDetail: any = [];
  loyaltypoints: any = 0.0;
  AvailingAmount: any = 0.0;
  PriceWithOutVAT: any = 0.0;
  showStock: boolean = true;

  Home: any = this.translate.transform('Home');
  CategoryMenu: any = this.translate.transform('CategoryMenu');
  lblSearchBarcode: any = this.translate.transform('SearchBarcode');
  Customer: any = this.translate.transform('Customer');
  Table: any = this.translate.transform('Table');
  Bills: any = this.translate.transform('Bills');
  Subtotal: any = this.translate.transform('SubTotal');
  Discount: any = this.translate.transform('Discount');
  CouponDiscount: any = this.translate.transform('CouponDiscount');
  lblVAT: any = this.translate.transform('VAT');
  lblTotal: any = this.translate.transform('Total');
  CLEAR: any = this.translate.transform('Clear');
  PAY: any = this.translate.transform('Pay');
  Loyality: any = this.translate.transform('Loyality');
  Print: any = this.translate.transform('Print');
  Signature: any = this.translate.transform('Signature');
  Branch: any = this.translate.transform('Branch');
  User: any = this.translate.transform('Users');
  Counter: any = this.translate.transform('Counter');
  StartDate: any = this.translate.transform('StartDate');
  Logout: any = this.translate.transform('Logout');
  KOT: any = this.translate.transform('KOT');
  OrderNo: any = this.translate.transform('OrderNo');
  ProductNotFound: any = this.translate.transform('ProductNotFound');
  SessionClosed: any = this.translate.transform('SessionClosed');
  PrintButton: any = this.translate.transform('Print');
  LoyaltyButton: any = this.translate.transform('Loyalty');
  DiscountButton: any = this.translate.transform('Di');
  YoucannotprinttheReceiptmorethen: any = this.translate.transform('YoucannotprinttheReceiptmorethen');
  times: any = this.translate.transform('times');
  lblHoldOrder: any = this.translate.transform('Hold');
  lblRecall: any = this.translate.transform('Recall');
  loading = false;


  constructor(private translate: TranslatePipe, private route: ActivatedRoute, private dataservice: DataserviseService, private popup: PopupService,
    public dialog: MatDialog, private Utilities: UtilitiesService, public router: Router, private _decimalPipe: DecimalPipe) { }

  // carousal work
  brandslideConfig = {
    "slidesToScroll": 1, "autoplay": false, "autoplaySpeed": 1500, "arrows": true, "dots": false, "infinite": false, 'responsive': [
      // {
      //   breakpoint: 768,
      //   settings: {
      //     slidesToShow: 3,
      //     slidesToScroll: 1,
      //     infinite: false,
      //     dots: false
      //   }
      // },
      {
        //for tab screen
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: false
        }
      },
      {
        //for tab screen
        breakpoint: 1220,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: false,
          dots: false
        }
      },
      {
        //for medium screen
        breakpoint: 1440,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
          infinite: false,
          dots: false
        }
      },
      {
        //for medium screen
        breakpoint: 1800,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
          infinite: false,
          dots: false
        }
      },
      {
        //for large screen
        breakpoint: 2560,
        settings: {
          slidesToShow: 8,
          slidesToScroll: 1,
          infinite: false,
          dots: false
        }
      },
      {
        //for large screen
        breakpoint: 3200,
        settings: {
          slidesToShow: 10,
          slidesToScroll: 1,
          infinite: false,
          dots: false
        }
      },
      {
        //for large screen
        breakpoint: 3800,
        settings: {
          slidesToShow: 12,
          slidesToScroll: 1,
          infinite: false,
          dots: false
        }
      },
      {
        //for large screen
        breakpoint: 4400,
        settings: {
          slidesToShow: 14,
          slidesToScroll: 1,
          infinite: false,
          dots: false
        }
      },
    ],
  };

  counterName: any;
  counterID: any;
  companyID: any;
  companyName: any;
  userName: any;
  DayStartDate: any;
  CountryCode: any;
  cultures: any;
  MaxReceiptPrintOut: any;
  IsMaxPolicyOn: any;
  MaxKitchenReceiptPrintOut: any;
  IsCalculateTIPAmount: any;
  AddnDeleteItems: boolean = false;
  AppStatus: any = 'true';

  IsInventoryActivate: boolean = false;
  MinimumBalanceQty: any;

  ngOnInit(): void {
    this.AddnDeleteItems = false;
    // if(sessionStorage.getItem('DayID&ShiftID&DayStartDate') != null){
    //   this.DayID = JSON.parse(sessionStorage.getItem('DayID&ShiftID&DayStartDate'))[0];
    //   this.ShiftID = JSON.parse(sessionStorage.getItem('DayID&ShiftID&DayStartDate'))[1];
    if (sessionStorage.getItem('DayID&ShiftID&DayStartDate') != null) {
      this.DayID = JSON.parse(sessionStorage.getItem('DayID&ShiftID&DayStartDate'))[0];
      this.ShiftID = JSON.parse(sessionStorage.getItem('DayID&ShiftID&DayStartDate'))[1];
    }
    debugger

    // this.route.queryParams.subscribe(params => {      
    //   if (params['dayid'] && params['shiftid']) {
    //     let body = [params['dayid'], params['shiftid']]
    //     this.DayID = params['dayid'];
    //     this.ShiftID = params['shiftid'];
    //     sessionStorage.setItem('DayID&ShiftID', JSON.stringify(body));
    //   }
    //   else {
    //     this.DayID = JSON.parse(sessionStorage.getItem('DayID&ShiftID'))[0];
    //     this.ShiftID = JSON.parse(sessionStorage.getItem('DayID&ShiftID'))[1];
    //   }
    // });


    if (localStorage.getItem('counterID&CompanyID' + this.Utilities.getURLCompanyName()) != null) {
      debugger
      this.companyName = JSON.parse(localStorage.getItem('counterID&CompanyID' + this.Utilities.getURLCompanyName()))[0].CompanyName;
      this.counterName = JSON.parse(localStorage.getItem('counterID&CompanyID' + this.companyName))[0].CounterName;
      this.counterID = JSON.parse(localStorage.getItem('counterID&CompanyID' + this.companyName))[0].CounterID;
      this.companyID = JSON.parse(localStorage.getItem('counterID&CompanyID' + this.companyName))[0].CompanyID;

      this.userName = JSON.parse(localStorage.getItem('ActiveUser' + this.companyName))[0].Name;
      this.DayStartDate = JSON.parse(localStorage.getItem('ActiveUser' + this.companyName))[0].DayDate;
      this.CountryCode = JSON.parse(localStorage.getItem('counterID&CompanyID' + this.companyName))[0].CountryCode;
    }
    //
    if (localStorage.getItem('SelectedBranchRow' + this.companyName) != null && localStorage.getItem('SelectedBranchRow' + this.companyName) != undefined) {
      //
      this.SelectedBrancRow = JSON.parse(localStorage.getItem('SelectedBranchRow' + this.companyName));
      this.SelectedBranchName = this.SelectedBrancRow.OutletName;
      this.SelectedBranchID = this.SelectedBrancRow.ID;
      this.ObjOutletInfo.BranchID = this.SelectedBrancRow.ID;
    }

    let culture;
    if (localStorage.getItem('LanguageCulture' + this.companyName)) {
      culture = localStorage.getItem('LanguageCulture' + this.companyName);
      this.cultures = culture;
    }

    else {
      culture = 'en';
      this.cultures = culture;
    }
    if (this.cultures == 'ar')
      this.OrderType = 'طلب خارجي';
    else
      this.OrderType = 'Takeaway';
    this.OrderTypeID = 2;

    this.dataservice.get('pos/GetCompanyData/' + this.Utilities.getSubscriptionID() + '/' + this.SelectedBranchID + '/' + culture + '/' + this.counterID)
      .subscribe(data => {


        if (this.isArrayHasValue(data[0]['CompanyData'])) {
          sessionStorage.setItem('ObjCompany', JSON.stringify(data[0]['CompanyData']));
          this.ObjCompany = data[0]['CompanyData'];
          this.CurrencyCode = data[0]['CompanyData'][0].CurrencyCode;
          this.NumOfDecimal = data[0]['CompanyData'][0].NumOfDecimal;
        }
        if (this.isArrayHasValue(data[0]['ResturentInfo'])) {
          sessionStorage.setItem('ResturentInfo', JSON.stringify(data[0]['ResturentInfo']));
          this.ObjResturentInfo = data[0]['ResturentInfo'];
          this.BaseColor = data[0]['ResturentInfo'][0].BaseColor;
        }
        debugger
        if (this.isArrayHasValue(data[0]['OutletInfo'])) {
          sessionStorage.setItem('OutletInfo', JSON.stringify(data[0]['OutletInfo']));
          this.ObjOutletInfo = data[0]['OutletInfo'][0];
          this.TaxRate = data[0]['OutletInfo'][0].TaxRate;
          this.IsExtraCharge = data[0]['OutletInfo'][0].IsExtraCharge;
          this.ExtraChargeDescription = data[0]['OutletInfo'][0].ExtraChargeDescription;
          this.ExtraChargePercentage = data[0]['OutletInfo'][0].ExtraChargePercentage;
          this.ExtraChargeDescriptionArabic = data[0]['OutletInfo'][0].ExtraChargeDescriptionArabic;
          this.MaxReceiptPrintOut = data[0]['OutletInfo'][0].MaxReceiptPrintOut;
          this.MaxKitchenReceiptPrintOut = data[0]['OutletInfo'][0].MaxKitchenReceiptPrintOut;
          this.IsMaxPolicyOn = data[0]['OutletInfo'][0].IsMaxPolicyOn;
          this.IsCalculateTIPAmount = data[0]['OutletInfo'][0].IsCalculateTIPAmount;
          this.IsActiveMinOrderValue = data[0]['OutletInfo'][0].IsActiveMinOrderValue;
          this.IsInventoryActivate = data[0]['OutletInfo'][0].IsInventoryActivate;
          this.MinimumBalanceQty = data[0]['OutletInfo'][0].MinimumBalanceQty;
          // this.AppStatus = data[0]['OutletInfo'][0].IsDigiPosOutletOffline;
          // localStorage.setItem('AppStatus' + this.companyName , data[0]['OutletInfo'][0].IsDigiPosOutletOffline);
        }

        if (this.isArrayHasValue(data[0]['OrderTypeInfo'])) {
          this.lstOrderType = data[0]['OrderTypeInfo'];
          this.OrderTypeID = data[0]['OrderTypeInfo'][1]["ID"];
        }

        if (this.isArrayHasValue(data[0]['POSInfo'])) {
          sessionStorage.setItem('POSInfo', JSON.stringify(data[0]['POSInfo']));
          this.IsDirectPrintReceipt = data[0]['POSInfo'][0].IsReceiptDirectPrint;
          this.IsDirectPrintKOT = data[0]['POSInfo'][0].IsKOTDirectPrint;
          this.ServerLink = data[0]['POSInfo'][0].ServerLink;
        }
        if (this.isArrayHasValue(data[0]['MinimumOrderValue'])) {
          this.lstMinimumOrderValue = data[0]['MinimumOrderValue'];

        }
      }, error => alert(this.translate.transform('error')));

    var d = new Date();
    this.currentdate = new Date();
    this.day = formatDate(new Date(), 'dd', 'en');
    this.month = d.toLocaleString('default', { month: 'short' })
    this.getProductAllBarcode();
    this.GetCategories();


  }

  indexID: any;
  hoverEvent(i, IsMouseEnter) {
    
    this.indexID = i;
    if (IsMouseEnter == true)
      this.showStock = true;
    else
      this.showStock = false;


  }

  GetCategories(): void {
    this.lstCategories = [];
    this.dataservice.get('pos/GetCategoryData/' + this.Utilities.getSubscriptionID() + '/' + this.SelectedBranchID + '/' + this.counterID)
      .subscribe(data => {
        if (this.isArrayHasValue(data[0]['CategoryData'])) {
          this.lstCategories = data[0]['CategoryData'];
          this.getProduct(data[0]['CategoryData'][0]["Code"]);
        }

      }, error => alert(this.translate.transform('error')));
  }
  ProductPrice: any;
  SaleRetun(): void {
    const dialogRef = this.dialog.open(SaleReturnPopupComponent, {
      width: '1350px',
      height: '800px',
      data: { data: this.IsCancelButtonPaidOrderActive, OutletId: this.SelectedBranchID, ObjOutletInfo: this.ObjOutletInfo, NumOfDecimal: this.NumOfDecimal, CurrencyCode: this.CurrencyCode, DayID: this.DayID, ShiftID: this.ShiftID }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        this.AddnDeleteItems = false;
      }

    });
  }
  getProduct(categoryid): void {
    debugger
    this.SearchBarcode = '';
    this.IsBarcodeFound = false;
    this.lstProduct = [];
    this.productstr = categoryid;
    categoryid = categoryid == "" ? 0 : categoryid;
    this.dataservice.get('pos/GetProductData/' + this.Utilities.getSubscriptionID() + '/' + categoryid + '/' + this.Utilities.GetCurrentDate() + '/' + this.OrderType + '/' + this.SelectedBranchID)
      .subscribe(data => {
        if (this.isArrayHasValue(data[0]['ProductData'])) {

          this.lstProduct = data[0]['ProductData'];
        }
      }, error => alert(this.translate.transform('error')));
  }

  IsKotButtonActive: boolean = false;
  IsCancelOrderButtonActive: boolean = false;
  IsCancelButtonPaidOrderActive: boolean = false;
  IsOpenCheckButtonPaidOrderActive: boolean = false;
  IsPayButtonActive: boolean = false;
  IsVoidButtonActive: boolean = true;
  IsPriceChangeActive: boolean = false;
  IsDisCountButtonActive: boolean = false;
  IsLoyalityButtonActive: boolean = false;
  IsPrintButtonActive: boolean = false;
  IsPendingOrderPrintReciept: boolean = false;
  IsPendingOrderKitchenPrint: boolean = false;

  IsHoldOrderBtnActive: boolean = false;
  IsCustomerBtnActive: boolean = false;
  IsRecallBtnActive: boolean = false;

  IsSalesTypeButton: boolean = false;

  buttonCount: number = 1;
  arr = [];
  IsRightsEmployeeWise: boolean = true;
  lstPosButtonDeviceWise = [];
  getProductAllBarcode(): void {
    //;
    let culture;
    let ModuleCode = 'POS Menu';
    if (localStorage.getItem('LanguageCulture' + this.companyName)) {
      culture = localStorage.getItem('LanguageCulture' + this.companyName);
    }
    else {
      culture = 'en';
    }
    this.dataservice.get('pos/GetAllProductData/' + this.Utilities.getSubscriptionID() + '/' + this.Utilities.GetCurrentDate() + '/' + this.OrderType + '/' + this.SelectedBranchID + '/' + this.Utilities.getUserId() + '/' + culture + '/' + this.counterID + '/' + ModuleCode)
      .subscribe(data => {
        debugger
       
        if (this.isArrayHasValue(data[0]['PosMenu'])) {
          this.lstPosMenu = data[0]['PosMenu'];
        }
        if (this.isArrayHasValue(data[0]['ProductDataAll'])) {
          this.lstProductAll = data[0]['ProductDataAll'];
        }
        if (this.isArrayHasValue(data[0]['IsRightsEmployeeWise'])) {

          this.IsRightsEmployeeWise = data[0]['IsRightsEmployeeWise'][0].CheckRightsEmployeeWise;
        }
        if (this.isArrayHasValue(data[0]['PosButtonDeviceWise'])) {
          this.lstPosButtonDeviceWise = data[0]['PosButtonDeviceWise'];
        }
        if (this.isArrayHasValue(data[0]['PosButtonEmployeeWise'])) {
          this.arr = data[0]['PosButtonEmployeeWise']
        }

        if (!this.IsRightsEmployeeWise) {
          /* this.buttonCount = this.lstPosButtonDeviceWise.length;*/
          if (this.lstPosButtonDeviceWise.some(x => x.EntityCode == "kotbutton")) {
            this.IsKotButtonActive = true;
            this.buttonCount += 1;
          }
          if (this.lstPosButtonDeviceWise.some(y => y.EntityCode == "cancelOrderbutton")) {
            this.IsCancelOrderButtonActive = true;
          }
          if (this.lstPosButtonDeviceWise.some(y => y.EntityCode == "cancelButtonPaidOrder")) {
            this.IsCancelButtonPaidOrderActive = true;
          }
          if (this.lstPosButtonDeviceWise.some(y => y.EntityCode == "paybutton")) {
            this.IsPayButtonActive = true;
            this.buttonCount += 1;
          }
          if (this.lstPosButtonDeviceWise.some(y => y.EntityCode == "pricechange")) {
            this.IsPriceChangeActive = true;
          }
          if (this.lstPosButtonDeviceWise.some(x => x.EntityCode == "DisCountButton")) {
            this.IsDisCountButtonActive = true;
            this.buttonCount += 1;
          }
          if (this.lstPosButtonDeviceWise.some(x => x.EntityCode == "holdorderbutton")) {
            this.IsHoldOrderBtnActive = true;
            this.buttonCount += 1;
          }
          if (this.lstPosButtonDeviceWise.some(x => x.EntityCode == "customerbutton")) {
            this.IsCustomerBtnActive = true;
            this.buttonCount += 1;
          }
          if (this.lstPosButtonDeviceWise.some(x => x.EntityCode == "recallbutton")) {
            this.IsRecallBtnActive = true;
            this.buttonCount += 1;
          }
          if (this.lstPosButtonDeviceWise.some(x => x.EntityCode == "LoyalityButton")) {
            this.IsLoyalityButtonActive = true;
            this.buttonCount += 1;
          }
          if (this.lstPosButtonDeviceWise.some(x => x.EntityCode == "PrintButton")) {
            this.IsPrintButtonActive = true;
            this.buttonCount += 1;
          }
          if (this.lstPosButtonDeviceWise.some(x => x.EntityCode == "PendingOrderPrintReciept")) {
            this.IsPendingOrderPrintReciept = true;
            // this.buttonCount += 1;
          }
          if (this.lstPosButtonDeviceWise.some(x => x.EntityCode == "PendingOrderKitchenPrint")) {
            this.IsPendingOrderKitchenPrint = true;
            // this.buttonCount += 1;
          }
          if (this.lstPosButtonDeviceWise.some(y => y.EntityCode == "OpenCheckButtonPaidOrder")) {
            this.IsOpenCheckButtonPaidOrderActive = true;
          }
          if (this.lstPosButtonDeviceWise.some(y => y.EntityCode == "IsSalesTypeButton")) {
            this.IsSalesTypeButton = true;
          }
        }

        else {
         
          //this.buttonCount = this.arr.length;
          if (this.arr.some(x => x.EntityCode == "kotbutton")) {
            this.IsKotButtonActive = true;
            this.buttonCount += 1;
          }
          if (this.arr.some(y => y.EntityCode == "cancelOrderbutton")) {
            this.IsCancelOrderButtonActive = true;
          }
          if (this.arr.some(y => y.EntityCode == "cancelButtonPaidOrder")) {
            this.IsCancelButtonPaidOrderActive = true;
          }
          if (this.arr.some(y => y.EntityCode == "paybutton")) {
            this.IsPayButtonActive = true;
            this.buttonCount += 1;
          }
          if (this.arr.some(y => y.EntityCode == "pricechange")) {
            this.IsPriceChangeActive = true;
          }
          if (this.arr.some(y => y.EntityCode == "DisCountButton")) {
            this.IsDisCountButtonActive = true;
            this.buttonCount += 1;
          }
          if (this.arr.some(y => y.EntityCode == "holdorderbutton")) {
            this.IsHoldOrderBtnActive = true;
            this.buttonCount += 1;
          }
          if (this.arr.some(y => y.EntityCode == "customerbutton")) {
            this.IsCustomerBtnActive = true;
            this.buttonCount += 1;
          }
          if (this.arr.some(y => y.EntityCode == "recallbutton")) {
            this.IsRecallBtnActive = true;
            this.buttonCount += 1;
          }
          if (this.arr.some(y => y.EntityCode == "LoyalityButton")) {
            this.IsLoyalityButtonActive = true;
            this.buttonCount += 1;
          }
          if (this.arr.some(y => y.EntityCode == "PrintButton")) {
            this.IsPrintButtonActive = true;
            this.buttonCount += 1;
          }
          if (this.arr.some(y => y.EntityCode == "PendingOrderPrintReciept")) {
            this.IsPendingOrderPrintReciept = true;
            // this.buttonCount += 1;
          }
          if (this.arr.some(y => y.EntityCode == "PendingOrderKitchenPrint")) {
            this.IsPendingOrderKitchenPrint = true;
            // this.buttonCount += 1;
          }
          if (this.arr.some(y => y.EntityCode == "OpenCheckButtonPaidOrder")) {
            this.IsOpenCheckButtonPaidOrderActive = true;
          }
          if (this.arr.some(y => y.EntityCode == "IsSalesTypeButton")) {
            this.IsSalesTypeButton = true;
          }
        }
      });
  }
  PushintoBakset(row): void {


    debugger
    this.KitchenReceiptPrintOutCount = 0;
    this.ReceiptPrintOutCount = 0;
    this.printvalidation = false;
    this.GetModifierData(row);
    // this.ProductVATCalculation(row);
    // //debugger
    var objDiv = document.getElementById("billDiv");
    objDiv.scrollTop = objDiv.scrollHeight - objDiv.clientHeight;

  }

  lstComboProducts = [];
  lstComboProducts_Copy = [];
  GetModifierData(row): void {

    this.ModifiresAmount = 0;
    this.dataservice.get('pos/GetModifierData/' + row.ProductID + '/' + this.Utilities.getSubscriptionID() + '/' + this.OrderType + '/' + this.Utilities.GetCurrentDate() + '/' + this.SelectedBranchID)
      .subscribe(data => {
        debugger
        this.lstData = [];

        if (this.lstBasket.length == 0)
          this.autoincreament += 1;
        else
          this.autoincreament = this.lstBasket[this.lstBasket.length - 1].rowid + 1;

        if (this.isArrayHasValue(data[0]['ListData'])) {
          this.lstData = data[0]['ListData'];
        }
        else {
          this.lstModifier = [];
        }

        if (this.isArrayHasValue(data[0]['ModifierData'])) {
          this.lstModifier = data[0]['ModifierData'];
        }
        else {
          this.lstModifier = [];
        }
        if (this.isArrayHasValue(data[0]['ProductSize'])) {
          this.lstProductSize = data[0]['ProductSize'];
        }
        else {
          this.lstProductSize = [];
        }
        debugger
        if (this.isArrayHasValue(data[0]['ProductComboData'])) {
          // this.autoincreamentCombo = this.autoincreamentCombo + 1;
          this.lstComboProducts_Copy = data[0]['ProductComboData'];
          const filterArray_comboProduct = this.lstComboProducts_Copy.filter((obj) => obj.MainProductID === row.ProductID)

          // if(filterArray_Product.length > 0){
          //   const nonMatchingData =  this.lstComboProducts.filter(x => !filterArray_Product.includes(x.ProductId));

          //   if(nonMatchingData.length > 0){
          //     this.lstComboProducts.push(...nonMatchingData);
          //   }
          //   else{
          debugger
          if (filterArray_comboProduct.length > 0) {
            for (const [i, comboProduct] of filterArray_comboProduct.entries()) {
              comboProduct['rowid'] = this.autoincreament;
              comboProduct['IsComboItem'] = 1;
              // if(!this.lstBasket.find(t => t.rowid === this.lstComboProducts[i].rowid && t.MainProductID === row.ProductID))
              this.lstComboProducts.push(comboProduct);
            }

          }

        }

        //Modifire Popup Open
        if (!this.ObjVoidItem && (this.isArrayHasValue(data[0]['ListData']) && this.isArrayHasValue(data[0]['ModifierData'])) || this.lstProductSize.length > 1 && !this.ObjVoidItem) {
          //;
          this.lstSelectedProduct = row;
          this.openPopup(row);

        }
        // Adding Simple Products Into Lst Basket
        else {
          debugger
          // this.lstSelectedProduct = row;

          this.Amount = row.Price;
          // this.ProductVATCalculation(row);

          //POS Work 19-12-23 @Tehseen

          let index = this.lstBasket.findIndex(x => x.ProductID == row.ProductID && x.IsSelected == 0);
          if (index != -1) {
            this.plusqunty(row, index);
            return;
          } else {
            this.lstBasket.push({ ProductSizeID: row.ProductSizeID, ProductSizeName: row.ProductSizeName, ComboProductID: row.ComboProductID, BranchIds: row.BranchIds, CatagoryID: row.CatagoryID, Description: row.Description, DiscountPrice: row.DiscountPrice, FinalQuantity: row.FinalQuantity, ImageName: row.ImageName, IsSelected: row.IsSelected, OrderTotal: row.OrderTotal, Price: row.Price, ProductID: row.ProductID, ProductName: row.ProductName, Quantity: 1, rowid: this.autoincreament, Amount: this.Amount, sizeProductName: row.ProductName, ProductDetailID: row.ProductDetailID, ProductCategoryID: row.CatagoryID, PName: row.PName, AProductName: row.AProductName, IsNewItemPunch: 1, TaxAmount: this.ProductVAT, TaxPercent: this.ObjOutletInfo.TaxRate, ProductDiscount: 0, PriceWithOutVAT: this.PriceWithOutVAT, DiffPriceWithOutVAT: 0, DiffProductDiscount: 0, DiffTaxAmount: 0  });

            if (this.ObjVoidItem) {
              //console.log('@@@@@@@@@@')
              //console.log(this.lstSelectModifier[this.ObjVoidItem.ItemIndex]);
              //console.log(this.lstSelectModifier);
              debugger
              if (this.lstSelectModifier[this.ObjVoidItem.ItemIndex].length > 0) {
                let newAr: any = [];
                for (var i = 0; i < this.lstSelectModifier[this.ObjVoidItem.ItemIndex].length; i++) {
                  if (this.lstSelectModifier[this.ObjVoidItem.ItemIndex][i].rowid == row.rowid && this.lstSelectModifier[this.ObjVoidItem.ItemIndex][i].ProductID == row.ProductID) {
                    newAr.push({
                      AName: this.lstSelectModifier[this.ObjVoidItem.ItemIndex][i].AName,
                      IsBogo: this.lstSelectModifier[this.ObjVoidItem.ItemIndex][i].IsBogo,
                      IsSelected: this.lstSelectModifier[this.ObjVoidItem.ItemIndex][i].IsSelected,
                      ModifierId: this.lstSelectModifier[this.ObjVoidItem.ItemIndex][i].ModifierId,
                      Modifires: this.lstSelectModifier[this.ObjVoidItem.ItemIndex][i].Modifires,
                      Price: this.lstSelectModifier[this.ObjVoidItem.ItemIndex][i].Price,
                      PriceStatic: this.lstSelectModifier[this.ObjVoidItem.ItemIndex][i].PriceStatic,
                      ProductID: this.lstSelectModifier[this.ObjVoidItem.ItemIndex][i].ProductID,
                      rowid: this.autoincreament
                    });
                  }
              
                }
                this.lstSelectModifier.push(newAr);
              }
           
              //console.log('||||||||||||')
              //console.log(this.lstSelectModifier);
            }

            
            
          }


          if (this.ObjVoidItem && this.ObjVoidItem.IsValid) {
            let newindex = this.lstBasket.length-1;

            this.lstBasket[newindex]['DelFlag'] = 1;
            this.lstBasket[newindex]['RejectReasonID'] = this.ObjVoidItem.RejectReasonID;
            this.lstBasket[newindex]['RejectedReason'] = this.ObjVoidItem.RejectReason;
            this.lstBasket[newindex]['DeletedBy'] = this.Utilities.getUserId();
            this.lstBasket[newindex]['DeletedOn'] = this.Utilities.GetCurrentDateTime();
            this.lstBasket[newindex]['Quantity'] = this.ObjVoidItem.VoidQty;
            this.lstBasket[newindex]['Amount'] = this.lstBasket[newindex]['Price'] * this.lstBasket[newindex]['Quantity'];
            
            this.lstBasket[this.ObjVoidItem.ItemIndex]['Quantity'] = this.lstBasket[this.ObjVoidItem.ItemIndex]['Quantity'] - this.ObjVoidItem.VoidQty;
            this.lstBasket[this.ObjVoidItem.ItemIndex]['Amount'] = this.lstBasket[this.ObjVoidItem.ItemIndex]['Price'] * this.lstBasket[this.ObjVoidItem.ItemIndex]['Quantity'];
          } else {
            this.lstSelectModifier.push([{ AllowMultipleSeletion: null, DetailId: null, IsSelected: null, ListName: null, MasterId: null, MaxModifiers: null, MinModifiers: null, ModifierId: null, Modifires: null, Price: null, PriceStatic: null, ProductID: null, rowid: null, Quantity: 1, IsBogo: null }]);
            //console.log(this.lstSelectModifier);
          }
          this.ObjVoidItem = undefined;

          if (this.chkKOT)
            this.AddnDeleteItems = false;
          //this.CalculateTaxPlusEvent(row);
          this.ProductVAT = 0;
          this.ProSubtoal = 0;
          this.ProSubtoalForVAT = 0;
          this.ProductDiscount = 0;
          this.CalculateProductWisePriceTAXDiscountAmount();

          this.CalculateTotalTaxDiscountExtraCharge();
          //this.CalculateExtraCharge();
          // this.CalculatePromoDiscountEvent();

          if (this.lstLoyaltyPointsData) {
            for (var i = 0; i < this.lstBasket.length; i++) {

              for (var j = 0; j < this.lstLoyaltyPointsData.length; j++) {
                if (this.lstLoyaltyPointsData[j].ProductID == this.lstBasket[i].ProductID && this.lstLoyaltyPointsData[j].ProductDetailID == this.lstBasket[i].ProductDetailID) {
                  this.lstBasket[i].LoyalityID = this.lstLoyaltyPointsData[j].LoyalityID;
                  this.lstBasket[i].LoyaltyDetailId = this.lstLoyaltyPointsData[j].LoyaltyDetailId;
                  this.lstBasket[i].Points = this.lstLoyaltyPointsData[j].Points;
                  this.lstBasket[i].ValuePoints = this.lstLoyaltyPointsData[j].ValuePoints;
                  this.lstBasket[i].AvailingAmount = this.lstLoyaltyPointsData[j].AvailingAmount;
                }
              }
            }
          }
          this.setloyalty(0, 0);

        }


      }, error => alert(this.translate.transform('error')));
  }


  // async getComboProduct(ProductID): Promise<any> {
  //   try {
  //     const response = await this.dataservice.get('pos/GetComboProduct/' + this.Utilities.getSubscriptionID() + '/' + ProductID).toPromise();
  //     return response; // Return the response data
  //   } catch (error) {
  //     console.error('Error:', error);
  //     throw error; // Rethrow the error so it can be handled elsewhere
  //   }
  // }

  Amount: any;
  plusqunty(row, index) {

    //this.lstBasket.find(t => t.ProductID == row.ProductID).Quantity += 1;
    this.lstBasket[index].Quantity += 1;
    this.Amount = this.lstBasket[index].Quantity * row.Price;
    this.lstBasket[index].Amount = this.Amount;
    let lst = this.lstSelectModifier[index];
    //let Quant = this.lstBasket.find(t => t.ProductID == row.ProductID).Quantity;
    this.ModifiresAmount = this.CalculateModifireAmount(lst);
    this.ModifiresAmount = this.ModifiresAmount;

    let ProVATAmount: number = parseFloat(this.lstBasket[index].TaxAmount);
    // this.ProductVATCalculation(row)
    ProVATAmount = ProVATAmount + parseFloat(this.ProductVAT);
    this.lstBasket[index].TaxAmount = ProVATAmount;
    this.CalculateTotalTaxDiscountExtraCharge();
    this.CalculateProductWisePriceTAXDiscountAmount();
    //this.CalculateTaxPlusEvent(row);
    this.setloyalty(0, 0);
  }


  minuqunty(row, index) {
    //;
    // this.lstBasket.find(t => t.ProductID == row.ProductID).Quantity -= 1;
    if (this.lstBasket[index].Quantity == 1) {
      return;
    }
    //
    this.lstBasket[index].Quantity -= 1;
    this.Amount = this.lstBasket[index].Quantity * row.Price;
    this.lstBasket[index].Amount = this.Amount;
    let lst = this.lstSelectModifier[index];
    //let Quant = this.lstBasket.find(t => t.ProductID == row.ProductID).Quantity;
    this.ModifiresAmount = this.CalculateModifireAmount(lst);
    this.ModifiresAmount = this.ModifiresAmount;

    let ProVATAmount: number = parseFloat(this.lstBasket[index].TaxAmount);
    // this.ProductVATCalculation(row)
    ProVATAmount = ProVATAmount - parseFloat(this.ProductVAT);
    this.lstBasket[index].TaxAmount = ProVATAmount;
    this.CalculateTotalTaxDiscountExtraCharge();
    this.CalculateProductWisePriceTAXDiscountAmount();
    //this.CalculateTaxMinusEvent(row, index);
    this.setloyalty(0, 0);

  }

  openPopup(row: any): void {
    const dialogRef = this.dialog.open(ModifierPopupComponent, {
      width: '800px',
      height: '500px',
      data: { lstData: this.lstData, lstModifier: this.lstModifier, lstSelectedProduct: this.lstSelectedProduct, lstProductSize: this.lstProductSize, culture: this.cultures, lstBasket: this.lstBasket }
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result != undefined) {
        let lstSelectedprodct = result["selectedproduct"];
        // let SpecialInstruction = result["SpecialInstruction"];
        if (this.lstSelectedProduct.sizeProductName)
          this.lstSelectedProduct.sizeProductName = this.lstSelectedProduct.sizeProductName.replace('(null)', '')

        let lstSelectedModifireforbilling = result["selectedmodifiers"];

        this.ModifiresAmount = 0;
        this.ModifiresAmount = this.CalculateModifireAmount(lstSelectedModifireforbilling);

        //POS Work 19-12-23 @Tehseen

        //let isModifierExists: any;


        //  isModifierExists = this.IsModifierExists(result["selectedmodifiers"]);

        //  if (isModifierExists != -1) {
        //    let index = this.lstBasket.findIndex(x => x.rowid == isModifierExists);
        //    if (index != -1) {
        //      this.plusqunty(row, index);
        //      return;
        //    }
        //  }
        //  else {
        //    this.lstBasket.push({ BranchIds: lstSelectedprodct.BranchIds, CatagoryID: lstSelectedprodct.CatagoryID, Description: lstSelectedprodct.Description, DiscountPrice: lstSelectedprodct.DiscountPrice, FinalQuantity: lstSelectedprodct.FinalQuantity, ImageName: lstSelectedprodct.ImageName, IsSelected: lstSelectedprodct.IsSelected, OrderTotal: lstSelectedprodct.OrderTotal, Price: lstSelectedprodct.Price, ProductID: lstSelectedprodct.ProductID, ProductName: lstSelectedprodct.ProductName, Quantity: 1, rowid: rowid, Amount: lstSelectedprodct.Price, ProductDetailID: lstSelectedprodct.ProductDetailID, sizeProductID: lstSelectedprodct.ProductSizeID, sizeProductName: lstSelectedprodct.sizeProductName, ProductCategoryID: lstSelectedprodct.CatagoryID, PName: lstSelectedprodct.PName, AProductName: lstSelectedprodct.AProductName, ProductSizeName: lstSelectedprodct.ProductSizeName, IsNewItemPunch: 1, TaxAmount: this.ProductVAT, TaxPercent: this.ObjOutletInfo.TaxRate, ProductDiscount: 0, PriceWithOutVAT: this.PriceWithOutVAT, DiffPriceWithOutVAT: 0, DiffProductDiscount: 0, DiffTaxAmount: 0 });
        //  }

        //if (isModifierExists == -1) {
        //  let index = this.lstBasket.findIndex(x => x.ProductID == row.ProductID && x.sizeProductID == row.ProductSizeID && x.IsSelected == 0);
        //  if (index != -1) {
        //    this.plusqunty(row, index);
        //    return;
        //  } else {
        //    this.lstBasket.push({ BranchIds: lstSelectedprodct.BranchIds, CatagoryID: lstSelectedprodct.CatagoryID, Description: lstSelectedprodct.Description, DiscountPrice: lstSelectedprodct.DiscountPrice, FinalQuantity: lstSelectedprodct.FinalQuantity, ImageName: lstSelectedprodct.ImageName, IsSelected: lstSelectedprodct.IsSelected, OrderTotal: lstSelectedprodct.OrderTotal, Price: lstSelectedprodct.Price, ProductID: lstSelectedprodct.ProductID, ProductName: lstSelectedprodct.ProductName, Quantity: 1, rowid: rowid, Amount: lstSelectedprodct.Price, ProductDetailID: lstSelectedprodct.ProductDetailID, sizeProductID: lstSelectedprodct.ProductSizeID, sizeProductName: lstSelectedprodct.sizeProductName, ProductCategoryID: lstSelectedprodct.CatagoryID, PName: lstSelectedprodct.PName, AProductName: lstSelectedprodct.AProductName, ProductSizeName: lstSelectedprodct.ProductSizeName, IsNewItemPunch: 1, TaxAmount: this.ProductVAT, TaxPercent: this.ObjOutletInfo.TaxRate, ProductDiscount: 0, PriceWithOutVAT: this.PriceWithOutVAT, DiffPriceWithOutVAT: 0, DiffProductDiscount: 0, DiffTaxAmount: 0 });
        //  }
        //}


        let index = this.lstBasket.findIndex(x => x.ProductID == row.ProductID && x.sizeProductID == row.ProductSizeID && x.IsSelected == 0);
        let val = [];
        debugger;
        if (index != -1) {
          let a_rowid = this.lstBasket[index].rowid;
          for (var i = 0; i < this.lstSelectModifier.length; i++) {

            for (var j = 0; j < this.lstSelectModifier[i].length; j++) {
              if (this.lstSelectModifier[i][j].rowid == a_rowid)
                val.push(this.lstSelectModifier[i][j]);
            }
          }

          //console.log(val)
        }

        debugger;
        //&& val.length == 0
        if (index != -1 && result["selectedmodifiers"].length == 0 && val.length == 0) {
          this.plusqunty(row, index);
          return;
        } else {
          this.lstSelectModifier.push(result["selectedmodifiers"]);
          let rowid = result["rowid"];
          this.lstBasket.push({ BranchIds: lstSelectedprodct.BranchIds, CatagoryID: lstSelectedprodct.CatagoryID, Description: lstSelectedprodct.Description, DiscountPrice: lstSelectedprodct.DiscountPrice, FinalQuantity: lstSelectedprodct.FinalQuantity, ImageName: lstSelectedprodct.ImageName, IsSelected: lstSelectedprodct.IsSelected, OrderTotal: lstSelectedprodct.OrderTotal, Price: lstSelectedprodct.Price, ProductID: lstSelectedprodct.ProductID, ProductName: lstSelectedprodct.ProductName, Quantity: 1, rowid: rowid, Amount: lstSelectedprodct.Price, ProductDetailID: lstSelectedprodct.ProductDetailID, sizeProductID: lstSelectedprodct.ProductSizeID, sizeProductName: lstSelectedprodct.sizeProductName, ProductCategoryID: lstSelectedprodct.CatagoryID, PName: lstSelectedprodct.PName, AProductName: lstSelectedprodct.AProductName, ProductSizeName: lstSelectedprodct.ProductSizeName, IsNewItemPunch: 1, TaxAmount: this.ProductVAT, TaxPercent: this.ObjOutletInfo.TaxRate, ProductDiscount: 0, PriceWithOutVAT: this.PriceWithOutVAT, DiffPriceWithOutVAT: 0, DiffProductDiscount: 0, DiffTaxAmount: 0 });
        }
        //@Tehseen Work END

        // this.ProductVATCalculation(row);

        if (this.chkKOT)
          this.AddnDeleteItems = false;
        //this.CalculateTaxPlusEvent(lstSelectedprodct);
        this.CalculateTotalTaxDiscountExtraCharge();
        this.ProductVAT = 0;
        this.ProSubtoal = 0;
        this.ProSubtoalForVAT = 0;
        this.ProductDiscount = 0;
        this.CalculateProductWisePriceTAXDiscountAmount();

        if (this.lstLoyaltyPointsData) {
          for (var i = 0; i < this.lstBasket.length; i++) {

            for (var j = 0; j < this.lstLoyaltyPointsData.length; j++) {
              if (this.lstLoyaltyPointsData[j].ProductID == this.lstBasket[i].ProductID && this.lstLoyaltyPointsData[j].ProductDetailID == this.lstBasket[i].ProductDetailID) {
                this.lstBasket[i].LoyalityID = this.lstLoyaltyPointsData[j].LoyalityID;
                this.lstBasket[i].LoyaltyDetailId = this.lstLoyaltyPointsData[j].LoyaltyDetailId;
                this.lstBasket[i].Points = this.lstLoyaltyPointsData[j].Points;
                this.lstBasket[i].ValuePoints = this.lstLoyaltyPointsData[j].ValuePoints;
                this.lstBasket[i].AvailingAmount = this.lstLoyaltyPointsData[j].AvailingAmount;
              }
            }
          }
        }
        this.setloyalty(0, 0);
        //this.CalculateExtraCharge();
      }
    });
  }

  IsModifierExists(NewArray): any {
    debugger


    let Arr = [];
    let NewArrayLength: number;

    NewArrayLength = NewArray.length;

    for (var i = 0; i < NewArray.length; i++) {
      Arr.push({
        DetailId: NewArray[i].DetailId,
        MasterId: NewArray[i].MasterId,
        ModifierId: NewArray[i].ModifierId,
        ProductID: NewArray[i].ProductID
      });

    }
    let sortNewArr = [];
    sortNewArr = Arr.sort();

    let NewJson = JSON.stringify(sortNewArr);

    let OldDataPairJson;
    let OldDataLength: any;
    for (var i = 0; i < this.lstSelectModifier.length; i++) {
      OldDataLength = this.lstSelectModifier[i].length;

      if (NewArrayLength === OldDataLength) {
        let OldData = this.lstSelectModifier[i].filter((elt, eltIndex) => this.lstSelectModifier[i].some((sameNameElt, sameNameEltIndex) => sameNameElt.rowid === elt.rowid && sameNameElt.ProductID == elt.ProductID && sameNameEltIndex !== eltIndex));


        let OldDataPair = [];
        for (var i = 0; i < OldData.length; i++) {
          OldDataPair.push({
            DetailId: OldData[i].DetailId,
            MasterId: OldData[i].MasterId,
            ModifierId: OldData[i].ModifierId,
            ProductID: OldData[i].ProductID
          });

        }
        let sortOldArr = [];
        sortOldArr = OldDataPair.sort();
        OldDataPairJson = JSON.stringify(sortOldArr);
        debugger
        if (NewJson === OldDataPairJson)
          return OldData[0].rowid;
      }


    }
    this.lstSelectModifier.push(NewArray);
    return -1;
  }
  ObjVoidItem: any;

  // SelectedOrderID:any;
  removeitem(index, lstBasket): void {
    debugger
    ;
    let parentdata = this.KotOrderID
    if (this.chkKOT && this.lstBasket[index].IsNewItemPunch != true) {
      let dialogpopup = this.dialog.open(VoiditemPopupComponent, {
        width: '700px',
        data: { parentdata, Oqty: this.lstBasket[index].Quantity, sizeProductName: this.lstBasket[index].sizeProductName, ItemIndex:index }
      })
      dialogpopup.afterClosed().subscribe(result => {
        debugger
        if (result != undefined) {
          var priceTotal = this.lstBasket.reduce(function (res, item) { return res + (item.DelFlag != 1 ? item.Price * item.Quantity : 0) }, 0);
          this.SubtoalForVAT = priceTotal + this.ModifiresAmount;
    
         
          if (result[0].IsValid == true) { // This block execute when void qty change or delete 2 of 5 items.
           
            this.ObjVoidItem = result[0];
            this.GetModifierData(this.lstBasket[index]);
            debugger
        

          } else { // this block execute as before
      
            this.lstBasket[index]['DelFlag'] = 1;
            this.lstBasket[index]['RejectReasonID'] = result[0].RejectReasonID;
            this.lstBasket[index]['RejectedReason'] = result[0].RejectReason;
            this.lstBasket[index]['DeletedBy'] = this.Utilities.getUserId();
            this.lstBasket[index]['DeletedOn'] = this.Utilities.GetCurrentDateTime();
            this.lstBasket[index]['Quantity'] = this.lstBasket[index]['Quantity'];
          }

          

          debugger
          this.AddnDeleteItems = false; //forcedfully false by me
          // this.SubtoalForVAT += parseFloat(lstBasket.DiscountPrice && this.ObjOutletInfo.IsTaxOnDiscountAmount == true ? (lstBasket.DiscountPrice > 0 ? lstBasket.DiscountPrice + this.ModifiresAmount : lstBasket.Price + this.ModifiresAmount) : lstBasket.Price + this.ModifiresAmount);
          // let lstBasketIsDelFlag = this.lstBasket.filter(t => t.DelFlag != 1);
          // let copylstBasket = this.lstBasket.filter(t => t.DelFlag != 1);
          let lst = this.lstSelectModifier[index];
          let Quant = this.lstBasket.find(t => t.ProductID == lstBasket.ProductID).Quantity;
          this.ModifiresAmount = this.CalculateModifireAmount(lst);
          this.ModifiresAmount = this.ModifiresAmount * Quant;
          //this.CalculateTaxRemoveRow(lstBasket, index);
          this.CalculateTotalTaxDiscountExtraCharge();
          this.setloyalty(0, 0);
        }
      });
    }
    else {
      let lst = this.lstSelectModifier[index];
      let Quant = this.lstBasket.find(t => t.ProductID == lstBasket.ProductID).Quantity;
      this.ModifiresAmount = this.CalculateModifireAmount(lst);
      this.ModifiresAmount = this.ModifiresAmount * Quant;
      this.lstBasket.splice(index, 1);
      this.lstSelectModifier.splice(index, 1);

      debugger
      let indexToDelete = this.lstComboProducts.findIndex(item => item.MainProductID === lstBasket.ProductID && item.rowid === lstBasket.rowid);

      while (indexToDelete !== -1) {
        debugger
        this.lstComboProducts.splice(indexToDelete, 1);

        // Find the next occurrence of the ID
        indexToDelete = this.lstComboProducts.findIndex(item => item.MainProductID === lstBasket.ProductID && item.rowid === lstBasket.rowid);
      }
      /* this.CalculateTaxRemoveRow(lstBasket, index);*/
      this.CalculateTotalTaxDiscountExtraCharge();
      this.setloyalty(0, 0);

    }
  }

  isArrayHasValue(obj: any[]): boolean {
    let isArrayWithLength = Boolean(Array.isArray(obj) && obj.length);
    return isArrayWithLength;
  }
  ProductDuplicate: boolean = false;
  formatAMPM() {
    let hours = this.currentdate.getHours();
    let minutes = this.currentdate.getMinutes();
    let second = this.currentdate.getSeconds();
    let ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    second = second < 10 ? '0' + second : second;
    let strTime = hours + ':' + minutes + ':' + second + ' ' + ampm;
    return strTime;
  }


  ProSubtoal: any = 0;
  ProSubtoalForVAT: any = 0;
  ProductVAT: any = 0;

  CalculateExtraChargeInclusive() {


    debugger
    this.Subtoal = (this.Total - this.VAT) / ((100 + this.ExtraChargePercentage) / 100)

    this.ExtraChargeAmount = (this.Subtoal * this.ExtraChargePercentage / 100)
    this.Subtoal = +this._decimalPipe.transform(this.Subtoal, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal).replace(',', '');
    this.ExtraChargeAmount = +this._decimalPipe.transform(this.ExtraChargeAmount, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal).replace(',', '');

  }
  ProductDiscount: any = 0;

  ProductVATCalculation_OLD(row) {
    debugger;

    this.ProSubtoal = 0;
    this.ProSubtoalForVAT = 0;
    this.ProductVAT = 0;
    this.PriceWithOutVAT = 0;

    if (this.ObjOutletInfo.IsTaxInclusive == false) {
      this.ProSubtoal += row.Price + this.ModifiresAmount;
      this.ProSubtoalForVAT += parseFloat(row.DiscountPrice && this.ObjOutletInfo.IsTaxOnDiscountAmount == true ? (row.DiscountPrice > 0 ? row.DiscountPrice + this.ModifiresAmount : row.Price + this.ModifiresAmount) : row.Price + this.ModifiresAmount);
      this.ProductVAT = (this.ProSubtoalForVAT * this.ObjOutletInfo.TaxRate) / 100;
      this.PriceWithOutVAT = this._decimalPipe.transform(this.ProSubtoalForVAT - this.ProductVAT, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal).replace(',', '');
    }
    else {

      let A = 0;
      let price = row.DiscountPrice > 0 ? row.DiscountPrice + this.ModifiresAmount : row.Price + this.ModifiresAmount;
      if (this.ObjOutletInfo.IsTaxOnDiscountAmount == false) {
        A = ((row.Price + this.ModifiresAmount) * 100) / (100 + this.ObjOutletInfo.TaxRate);
        this.ProductVAT = (row.Price + this.ModifiresAmount) - A;
        this.ProductVAT = +this._decimalPipe.transform(this.VAT, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal).replace(',', '');
        this.PriceWithOutVAT = this._decimalPipe.transform(price - this.ProductVAT, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal).replace(',', '');
      }
      if (this.ObjOutletInfo.IsTaxOnDiscountAmount == true) {
        A = ((price) * 100) / (100 + this.ObjOutletInfo.TaxRate);
        this.ProductVAT = (price) - A;
        this.ProductVAT = this._decimalPipe.transform(this.ProductVAT, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal).replace(',', '');
        this.PriceWithOutVAT = this._decimalPipe.transform(price - this.ProductVAT, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal).replace(',', '');
      }

    }

  }



  ProductVATPlusEventBulkQuntity(row) {
    ;
    this.ProSubtoal = 0;
    this.ProSubtoalForVAT = 0;
    this.ProductVAT = 0;

    var _Price = parseFloat(row.Price) * parseFloat(row.Quantity);
    var _DiscountPrice = parseFloat(row.DiscountPrice) * parseFloat(row.Quantity);

    if (this.ObjOutletInfo.IsTaxInclusive == false) {
      this.ProSubtoal += _Price + this.ModifiresAmount;
      this.ProSubtoalForVAT += _DiscountPrice && this.ObjOutletInfo.IsTaxOnDiscountAmount == true ? (_DiscountPrice > 0 ? _DiscountPrice + this.ModifiresAmount : _Price + this.ModifiresAmount) : _Price + this.ModifiresAmount;
      this.ProSubtoalForVAT = parseFloat(this.ProSubtoalForVAT.toFixed(3));
      this.ProductVAT = (this.ProSubtoalForVAT * this.ObjOutletInfo.TaxRate) / 100;

    }
    else {

      let A = 0;
      let price = _DiscountPrice > 0 ? _DiscountPrice + this.ModifiresAmount : _Price + this.ModifiresAmount;
      if (this.ObjOutletInfo.IsTaxOnDiscountAmount == false) {
        A = ((_Price + this.ModifiresAmount) * 100) / (100 + this.ObjOutletInfo.TaxRate);
        this.ProductVAT += (_Price + this.ModifiresAmount) - A;
        this.ProductVAT = +this._decimalPipe.transform(this.ProductVAT, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal).replace(',', '');
      }
      if (this.ObjOutletInfo.IsTaxOnDiscountAmount == true) {
        A = ((price) * 100) / (100 + this.ObjOutletInfo.TaxRate);
        this.ProductVAT += (price) - A;
        this.ProductVAT = + this._decimalPipe.transform(this.ProductVAT, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal).replace(',', '');
      }

    }

  }


  CalculateModifireAmount(row): number {
    let a = 0;
    for (var i = 0; i < row.length; i++) {

      if (row[i].Price != null && row[i].Price != undefined && row[i].IsSelected == true) {
        a += row[i].Price;
      }
    }

    return a;
  }

  CalculateTaxChangePrice(row, index) {
    ;
    let A: number = 0;
    let modifireamount = 0;

    debugger
    if (this.lstSelectModifier.length > 0) {
      for (var i = 0; i < this.lstBasket.length; i++) {
        for (var j = 0; j < this.lstSelectModifier[i].length; j++) {
          if (this.lstSelectModifier[i][j].Price != null && this.lstSelectModifier[i][j].Price != undefined && this.lstSelectModifier[i][j].Price != 0 && this.lstSelectModifier[i][j].IsSelected == true) {
            let Quant
            if (this.lstBasket.hasOwnProperty('DelFlag') == true)
              Quant = this.lstBasket.find(t => t.rowid == this.lstSelectModifier[i][j].rowid && t.DelFlag == 0).Quantity;
            else {
              Quant = this.lstBasket.find(t => t.rowid == this.lstSelectModifier[i][j].rowid).Quantity;
            }
            modifireamount += this.lstSelectModifier[i][j].Price * Quant;
          }
        }
      }
    }
    if (this.lstBasket.length > 0) {
      let sum = 0;

      if (this.lstBasket.filter(x => x.DelFlag == null || x.DelFlag == 0).length > 0) {
        sum = this.lstBasket.filter(x => x.DelFlag == null || x.DelFlag == 0).map(x => x.Price * x.Quantity).reduce((a, b) => a + b);
      }
      else {
        sum = 0;
      }
      if (this.IsPercentPromoDiscount) {
        this.PromoAmountforVisibility = ((sum + modifireamount) * this.PromoAmount) / 100;
      }
      else {
        this.PromoAmountforVisibility = +this.PromoAmount;
      }
      if (this.ObjOutletInfo.IsTaxInclusive == false) {
        if (this.ObjOutletInfo.IsTaxOnDiscountAmount == true)
          this.VAT = ((sum + modifireamount - this.PromoAmountforVisibility) * this.ObjOutletInfo.TaxRate) / 100;
        else
          this.VAT = ((sum + modifireamount) * this.ObjOutletInfo.TaxRate) / 100;
        this.Subtoal = sum;

        this.Total = (this.Subtoal + this.VAT - this.PromoAmountforVisibility);


      }
      else {
        if (this.ObjOutletInfo.IsTaxOnDiscountAmount == true) {
          A = (((sum + modifireamount) - this.PromoAmountforVisibility) * 100) / (100 + this.ObjOutletInfo.TaxRate);
          this.VAT = ((sum + modifireamount) - this.PromoAmountforVisibility) - A;
          this.Subtoal = A + this.PromoAmountforVisibility;
          this.Total = (this.Subtoal + this.VAT - this.PromoAmountforVisibility);
        }
        else {
          A = ((sum + modifireamount) * 100) / (100 + this.ObjOutletInfo.TaxRate);
          this.VAT = (sum + modifireamount) - A;
          this.Subtoal = A;
          this.Total = (this.Subtoal + this.VAT - this.PromoAmountforVisibility);
        }
      }
      this.PromoAmountforVisibility = +this._decimalPipe.transform(this.PromoAmountforVisibility, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal).replace(',', '');
      this.Total = +this._decimalPipe.transform(this.Total, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal).replace(',', '');
      this.Subtoal = +this._decimalPipe.transform(this.Subtoal, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal).replace(',', '');
      this.VAT = +this._decimalPipe.transform(this.VAT, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal).replace(',', '');
    }



  }
  // CalculateTotalTaxDiscountExtraCharge() {
  //   debugger;
  //   let A: number = 0;
  //   let modifireamount = 0;
  //   debugger
  //   if (this.lstSelectModifier.length > 0) {
  //     for (var i = 0; i < this.lstBasket.length; i++) {
  //       for (var j = 0; j < this.lstSelectModifier[i].length; j++) {
  //         if (this.lstSelectModifier[i][j].Price != null && this.lstSelectModifier[i][j].Price != undefined && this.lstSelectModifier[i][j].Price != 0 && this.lstSelectModifier[i][j].IsSelected == true) {
  //           let Quant
  //           if (this.lstBasket.hasOwnProperty('DelFlag') == true)
  //             Quant = this.lstBasket.find(t => t.rowid == this.lstSelectModifier[i][j].rowid && t.DelFlag == 0).Quantity;
  //           else {
  //             Quant = this.lstBasket.find(t => t.rowid == this.lstSelectModifier[i][j].rowid).Quantity;
  //           }
  //           modifireamount += this.lstSelectModifier[i][j].Price * Quant;
  //         }
  //       }
  //     }
  //   }
  //   if (this.lstBasket.length > 0) {
  //     const Productssum = this.lstBasket.filter(x => x.DelFlag == null || x.DelFlag == 0).map(x => x.Price * x.Quantity).reduce((a, b) => a + b);


  //     if (this.ObjOutletInfo.IsTaxInclusive == true) {
  //       if (this.IsPercentPromoDiscount) {
  //         this.PromoAmountforVisibility = ((Productssum + modifireamount) * this.PromoAmount) / 100;
  //       }
  //       else {
  //         this.PromoAmountforVisibility = +this.PromoAmount;
  //       }

  //       if (this.ObjOutletInfo.IsTaxOnDiscountAmount == true) {
  //         A = (((Productssum + modifireamount) - this.PromoAmountforVisibility) * 100) / (100 + this.ObjOutletInfo.TaxRate);
  //         this.VAT = ((Productssum + modifireamount) - this.PromoAmountforVisibility) - A;
  //         this.Subtoal = A + this.PromoAmountforVisibility;
  //         this.Total = (this.Subtoal + this.VAT - this.PromoAmountforVisibility);
  //       }
  //       else {
  //         A = ((Productssum + modifireamount) * 100) / (100 + this.ObjOutletInfo.TaxRate);
  //         this.VAT = (Productssum + modifireamount) - A;
  //         this.Subtoal = A;
  //         this.Total = (this.Subtoal + this.VAT - this.PromoAmountforVisibility);
  //       }

  //       if (this.IsExtraCharge == true) {
  //         this.CalculateExtraChargeInclusive();
  //       }
  //     }
  //     else {

  //       this.Subtoal = Productssum + modifireamount;

  //       if (this.IsExtraCharge == true) {
  //         this.ExtraChargeAmount = (this.Subtoal) * this.ExtraChargePercentage / 100;
  //       }


  //       if (this.IsPercentPromoDiscount) {
  //         this.PromoAmountforVisibility = ((Productssum + modifireamount + this.ExtraChargeAmount) * this.PromoAmount) / 100;
  //       }
  //       else {
  //         this.PromoAmountforVisibility = +this.PromoAmount;
  //       }

  //       if (this.ObjOutletInfo.IsTaxOnDiscountAmount == true)
  //         this.VAT = (((this.Subtoal + this.ExtraChargeAmount) - this.PromoAmountforVisibility) * this.ObjOutletInfo.TaxRate) / 100;
  //       else
  //         this.VAT = ((this.Subtoal + this.ExtraChargeAmount) * this.ObjOutletInfo.TaxRate) / 100;

  //       this.Total = (this.Subtoal + this.VAT + this.ExtraChargeAmount - this.PromoAmountforVisibility);
  //     }



  //     this.PromoAmountforVisibility = +this._decimalPipe.transform(this.PromoAmountforVisibility, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal).replace(',', '');
  //     this.Total = +this._decimalPipe.transform(this.Total, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal).replace(',', '');

  //     this.Subtoal = +this._decimalPipe.transform(this.Subtoal, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal).replace(',', '');
  //     this.VAT = +this._decimalPipe.transform(this.VAT, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal).replace(',', '');
  //   }
  //   if (!this.isArrayHasValue(this.lstBasket)) {
  //     this.Subtoal = 0;
  //     this.SubtoalForVAT = 0;
  //     this.Total = 0;
  //     this.VAT = 0;
  //     this.PromoAmountforVisibility = 0;
  //     this.PromoAmount = 0;
  //     this.ExtraChargeAmount = 0;
  //     this.PromoCode = '';
  //   }


  // }

  CalculateTotalTaxDiscountExtraCharge() {

    let A: number = 0;
    let modifireamount = 0;
    let bogoCount = 0;

    if (this.lstSelectModifier.length > 0) {
      for (var i = 0; i < this.lstBasket.length; i++) {
        let Quant = this.lstBasket[i].Quantity;
        if (this.lstSelectModifier[i].filter(t => t.rowid == this.lstBasket[i].rowid && (this.lstBasket[i].DelFlag == null || this.lstBasket[i].DelFlag == 0) && t.Price > 0).length > 0)
          modifireamount += this.lstSelectModifier[i].filter(t => t.rowid == this.lstBasket[i].rowid && this.lstBasket[i].DelFlag == null || this.lstBasket[i].DelFlag == 0).map(t => t.Price * Quant).reduce((a, b) => a + b);
        else
          modifireamount += 0;

      }
    }







    if (this.lstBasket.length > 0) {
      let Productssum;
      if (this.lstBasket.filter(x => x.DelFlag == null || x.DelFlag == 0).length > 0)
        Productssum = this.lstBasket.filter(x => x.DelFlag == null || x.DelFlag == 0).map(x => x.Price * x.Quantity).reduce((a, b) => a + b);
      else
        Productssum = 0;




      if (this.ObjOutletInfo.IsTaxInclusive == true) {
        if (this.IsPercentPromoDiscount) {
          this.PromoAmountforVisibility = ((Productssum + modifireamount) * this.PromoAmount) / 100;
        }
        else {
          if (this.PromoAmount > this.Total) {
            this.PromoAmountforVisibility = 0;
            this.removeCoupon();
          }
          else
            this.PromoAmountforVisibility = +this.PromoAmount;
        }
        let sumof_PromoandLoyaltyDiscountAmount = 0
        sumof_PromoandLoyaltyDiscountAmount = this.PromoAmountforVisibility + this.Consumed_LoyaltyAmount;
        if (this.ObjOutletInfo.IsTaxOnDiscountAmount == true) {
          A = (((Productssum + modifireamount) - sumof_PromoandLoyaltyDiscountAmount) * 100) / (100 + this.ObjOutletInfo.TaxRate);
          this.VAT = ((Productssum + modifireamount) - sumof_PromoandLoyaltyDiscountAmount) - A;
          this.Subtoal = A + sumof_PromoandLoyaltyDiscountAmount;
          this.Total = (this.Subtoal + this.VAT - sumof_PromoandLoyaltyDiscountAmount);
        }
        else {
          A = ((Productssum + modifireamount) * 100) / (100 + this.ObjOutletInfo.TaxRate);
          this.VAT = (Productssum + modifireamount) - A;
          this.Subtoal = A;
          this.Total = (this.Subtoal + this.VAT - sumof_PromoandLoyaltyDiscountAmount);
        }

        if (this.IsExtraCharge == true) {
          this.CalculateExtraChargeInclusive();
        }
      }
      else {



        this.Subtoal = Productssum + modifireamount;

        if (this.IsExtraCharge == true) {
          this.ExtraChargeAmount = (this.Subtoal) * this.ExtraChargePercentage / 100;
        }


        if (this.IsPercentPromoDiscount) {
          this.PromoAmountforVisibility = ((Productssum + modifireamount + this.ExtraChargeAmount) * this.PromoAmount) / 100;
        }
        else {
          if (this.PromoAmount > this.Subtoal) {

            this.PromoAmountforVisibility = 0;
            this.removeCoupon();
          }
          else
            this.PromoAmountforVisibility = +this.PromoAmount;
          //this.PromoAmountforVisibility = +this.PromoAmount;
        }

        let sumof_PromoandLoyaltyDiscountAmount = this.PromoAmountforVisibility + this.Consumed_LoyaltyAmount;
        if (this.ObjOutletInfo.IsTaxOnDiscountAmount == true) {
          this.VAT = (((this.Subtoal + this.ExtraChargeAmount) - sumof_PromoandLoyaltyDiscountAmount) * this.ObjOutletInfo.TaxRate) / 100;
        }
        else { this.VAT = ((this.Subtoal + this.ExtraChargeAmount) * this.ObjOutletInfo.TaxRate) / 100; }

        if (this.ObjOutletInfo.IsTaxInclusive == false && sumof_PromoandLoyaltyDiscountAmount == this.Subtoal) {
          this.VAT = 0;
        }

        //TEhseen Work IS Bogo
        debugger;
        let arr = [];

        for (var i = 0; i < this.lstSelectModifier.length; i++) {

          arr = this.lstSelectModifier[i];
          for (var j = 0; j < arr.length; j++) {

            for (var ii = 0; ii < this.lstBasket.length; ii++) {
              if (this.lstBasket[ii].rowid == arr[j].rowid) {
                if (arr[j].IsBogo == true) {

                  let vt = 0;
                  let price = this.lstBasket[ii].Price * parseFloat(this.lstBasket[ii].Quantity);
                  vt = ((price) * this.ObjOutletInfo.TaxRate) / 100; // this.VAT * bogoCount;
                  if (vt > 0)
                    this.VAT += vt;
                }
              }

            }

          }
        }
        //END

        this.Total = (this.Subtoal + this.VAT + this.ExtraChargeAmount - sumof_PromoandLoyaltyDiscountAmount);
        debugger





      }



      this.PromoAmountforVisibility = +this._decimalPipe.transform(this.PromoAmountforVisibility, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal).replace(',', '');
      this.Total = +this._decimalPipe.transform(this.Total, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal).replace(',', '');

      this.Subtoal = +this._decimalPipe.transform(this.Subtoal, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal).replace(',', '');
      this.VAT = +this._decimalPipe.transform(this.VAT, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal).replace(',', '');

    }
    if (!this.isArrayHasValue(this.lstBasket)) {
      this.Subtoal = 0;
      this.SubtoalForVAT = 0;
      this.Total = 0;
      this.VAT = 0;
      this.PromoAmountforVisibility = 0;
      this.PromoAmount = 0;
      this.ExtraChargeAmount = 0;
      this.PromoCode = '';
    }


  }

  //CalculateTotalTaxDiscountExtraCharge() {
  //  debugger;
  //  let A: number = 0;
  //  let modifireamount = 0;
  //  debugger
  //  if (this.lstSelectModifier.length > 0) {
  //    for (var i = 0; i < this.lstBasket.length; i++) {
  //      for (var j = 0; j < this.lstSelectModifier[i].length; j++) {
  //        if (this.lstSelectModifier[i][j].Price != null && this.lstSelectModifier[i][j].Price != undefined && this.lstSelectModifier[i][j].Price != 0 && this.lstSelectModifier[i][j].IsSelected == true) {
  //          let Quant
  //          if (this.lstBasket.hasOwnProperty('DelFlag') == true)
  //            Quant = this.lstBasket.find(t => t.rowid == this.lstSelectModifier[i][j].rowid && t.DelFlag == 0).Quantity;
  //          else {
  //            Quant = this.lstBasket.find(t => t.rowid == this.lstSelectModifier[i][j].rowid).Quantity;
  //          }
  //          modifireamount += this.lstSelectModifier[i][j].Price * Quant;
  //        }
  //      }
  //    }
  //  }
  //  if (this.lstBasket.length > 0) {
  //    const Productssum = this.lstBasket.filter(x => x.DelFlag == null || x.DelFlag == 0).map(x => x.Price * x.Quantity).reduce((a, b) => a + b);


  //    if (this.ObjOutletInfo.IsTaxInclusive == true) {
  //      if (this.IsPercentPromoDiscount) {
  //        this.PromoAmountforVisibility = ((Productssum + modifireamount) * this.PromoAmount) / 100;
  //      }
  //      else {
  //        this.PromoAmountforVisibility = +this.PromoAmount;
  //      }

  //      if (this.ObjOutletInfo.IsTaxOnDiscountAmount == true) {
  //        A = (((Productssum + modifireamount) - this.PromoAmountforVisibility) * 100) / (100 + this.ObjOutletInfo.TaxRate);
  //        this.VAT = ((Productssum + modifireamount) - this.PromoAmountforVisibility) - A;
  //        this.Subtoal = A + this.PromoAmountforVisibility;
  //        this.Total = (this.Subtoal + this.VAT - this.PromoAmountforVisibility);
  //      }
  //      else {
  //        A = ((Productssum + modifireamount) * 100) / (100 + this.ObjOutletInfo.TaxRate);
  //        this.VAT = (Productssum + modifireamount) - A;
  //        this.Subtoal = A;
  //        this.Total = (this.Subtoal + this.VAT - this.PromoAmountforVisibility);
  //      }

  //      if (this.IsExtraCharge == true) {
  //        this.CalculateExtraChargeInclusive();
  //      }
  //    }
  //    else {

  //      this.Subtoal = Productssum + modifireamount;

  //      if (this.IsExtraCharge == true) {
  //        this.ExtraChargeAmount = (this.Subtoal) * this.ExtraChargePercentage / 100;
  //      }


  //      if (this.IsPercentPromoDiscount) {
  //        this.PromoAmountforVisibility = ((Productssum + modifireamount + this.ExtraChargeAmount) * this.PromoAmount) / 100;
  //      }
  //      else {
  //        this.PromoAmountforVisibility = +this.PromoAmount;
  //      }

  //      if (this.ObjOutletInfo.IsTaxOnDiscountAmount == true)
  //        this.VAT = (((this.Subtoal + this.ExtraChargeAmount) - this.PromoAmountforVisibility) * this.ObjOutletInfo.TaxRate) / 100;
  //      else
  //        this.VAT = ((this.Subtoal + this.ExtraChargeAmount) * this.ObjOutletInfo.TaxRate) / 100;

  //      this.Total = (this.Subtoal + this.VAT + this.ExtraChargeAmount - this.PromoAmountforVisibility);
  //    }



  //    this.PromoAmountforVisibility = +this._decimalPipe.transform(this.PromoAmountforVisibility, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal).replace(',', '');
  //    this.Total = +this._decimalPipe.transform(this.Total, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal).replace(',', '');

  //    this.Subtoal = +this._decimalPipe.transform(this.Subtoal, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal).replace(',', '');
  //    this.VAT = +this._decimalPipe.transform(this.VAT, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal).replace(',', '');
  //  }
  //  if (!this.isArrayHasValue(this.lstBasket)) {
  //    this.Subtoal = 0;
  //    this.SubtoalForVAT = 0;
  //    this.Total = 0;
  //    this.VAT = 0;
  //    this.PromoAmountforVisibility = 0;
  //    this.PromoAmount = 0;
  //    this.ExtraChargeAmount = 0;
  //    this.PromoCode = '';
  //  }


  //}
  ClearControlsAgain: boolean = true;
  figureClick(selectedvar, value) {
    debugger

    this.OrderTypeID = selectedvar;
    if (selectedvar == '1') {
      this.ClearControlsAgain = true;
      this.openDineInTablePopup(selectedvar, value);
    }
    else if (selectedvar == '3') {
      //this.OrderType = 'Delivery';
      this.openDialogCustomerLookUp();
      if (this.ClearControlsAgain) {
        this.clearclick();
        // this.openDeliveryPopup();
      }
      this.ClearControlsAgain = !this.ClearControlsAgain;
    }
    else {
      this.ClearControlsAgain = true;
      this.openSalesTypePopup(selectedvar, value);
      this.clearclick();
    }


  }

  MinimumOrderValue: any;
  openfinishPopup() {
    debugger
    this.loading = true;
    let DayID;
    let ShiftID;
    debugger

    //this.addVoucherPaymentDetail();
    if (sessionStorage.getItem('DayID&ShiftID&DayStartDate') != null) {
      DayID = JSON.parse(sessionStorage.getItem('DayID&ShiftID&DayStartDate'))[0];
      ShiftID = JSON.parse(sessionStorage.getItem('DayID&ShiftID&DayStartDate'))[1];
    }

    if (DayID == undefined || ShiftID == undefined) {
      this.DayShiftOpenPopup();
      return
    }

    if (this.OrderTypeID == 1 && (this.TableName == undefined || this.TableName == '')) {
      this.loading = false;
      alert(this.translate.transform('Pleaseselecttablefirst'));
      return;
    }
    if (this.OrderTypeID == 3 && (this.CustomerId == 0 || this.CustomerId == undefined || this.CustomerId == null || this.CustomerId == '')) {
      alert(this.translate.transform('Pleaseselectcustomer'));
      return
    }
    // if (this.Subtoal <= 0) {
    //   const dialogRef = this.dialog.open(AlertPopupComponent, {
    //     width: '300px',
    //     height: '200px',
    //   });
    // }


    else if (this.isArrayHasValue(this.lstBasket)) {
      debugger
      if (this.IsActiveMinOrderValue) {
        if (this.lstMinimumOrderValue.filter(t => t.SaleTypeID == this.OrderTypeID).length > 0) {
          this.MinimumOrderValue = this.lstMinimumOrderValue.filter(t => t.SaleTypeID == this.OrderTypeID)[0]['MinimumAmount']
          if (this.MinimumOrderValue > this.Total) {
            const dialogRef = this.dialog.open(AlertPopupComponent, {
              width: '400px',
              height: '200px',
              data: { data: 'Minimun Order Value Should be grater then ' + this.CurrencyCode + ' ' + this.MinimumOrderValue }
            });
            return;
          }
        }
        else
          this.MinimumOrderValue = 0;
      }
      else {
        this.MinimumOrderValue = 0;
      }
      debugger
      const dialogRef = this.dialog.open(CalculatorPopupComponent, {
        // width: '850px',
        width: '1150px',
        height: '600px',

        data: { BillAmount: this.Total, Subtoal: this.Subtoal, DiscountAmont: this.DiscountAmont, VAT: this.VAT, lstbasket: this.lstBasket, lstselectedProduct: this.lstSelectedProduct, DeliveryFee: this.DeliveryFee, outletInfo: this.ObjOutletInfo, lstSelectedModifiers: this.lstSelectModifier, IsComingKOT: this.chkKOT, KotOrderID: this.KotOrderID, Currency: this.CurrencyCode, NumOfDecimal: this.NumOfDecimal, CustomerID: this.custId, Status: 'PAID', OrderType: this.OrderType, SelectedBranchID: this.SelectedBranchID, POSID: this.counterID, DayID: this.DayID, ShiftID: this.ShiftID, PromoCode: this.PromoCode, PromoAmount: this.PromoAmountforVisibility, OrderPerpStatus: 'Delivered', TableName: this.TableName, noOfCustomers: this.noOfCustomers, 'CustAddressID': this.CustomerAddressID, 'OrderTypeID': this.OrderTypeID, 'VoucherPaymentDetail': this.lstVoucherPaymentDetail, 'loyaltypoints': this.loyaltypoints, 'AvailingAmount': this.AvailingAmount, 'MobileNo': this.MobileNo, IsExtraCharge: this.IsExtraCharge, ExtraChargeDescription: this.ExtraChargeDescription, ExtraChargeAmount: this.ExtraChargeAmount, ExtraChargeDescriptionArabic: this.ExtraChargeDescriptionArabic, IsCalculateTIPAmount: this.IsCalculateTIPAmount, IsLoyaltySelected: this.IsLoyaltySelected, Consumed_LoyaltyPoints: this.Consumed_LoyaltyPoints, Consumed_LoyaltyAmount: this.Consumed_LoyaltyAmount, AddnDeleteItems: this.AddnDeleteItems, custName: this.custName, lstComboProducts: this.lstComboProducts, HoldOrderID: this.HoldOrderID }
      });

      dialogRef.afterClosed().subscribe(result => {

        if (result == undefined) {
          this.loading = false;
          return;
        }

        else {
          debugger
          this.HoldOrderID = 0;
          this.ReferenceNo = '';
          this.afterClosedMethod(result);
          this.AddnDeleteItems = false;
        }
      });
    }

    else {
      this.loading = false;
      const dialogRef = this.dialog.open(AlertPopupComponent, {
        width: '300px',
        height: '200px',
      });
    }


  }

  async afterClosedMethod(result) {
    debugger
    if (result[1][0].IsReceiptDirectPrint == 4) {

      const url = this.Utilities.geta4SizeReport(this.Utilities.getEncrypt(this.Utilities.getCompanyName()), this.Utilities.getEncrypt(sessionStorage.getItem('orderid')));
      this.ReceiptURL = url;

      // this.checkIframeLoaded();
    }
    if (result[1][0].IsReceiptDirectPrint == 3) {
      if (result[1][0].IsKOTDirectPrint == 2 && result[3] == true) {
        let link = result[1][0].ServerLink + '/Default?O=' + result[1][0].OrderID + '&C=' + result[1][0].CompanyId + '&type=KP' + '&OutletID=' + this.SelectedBranchID + '&ShiftID=' + this.ShiftID + '&POSID=' + this.counterID;
        this.win = window.open(link, '_blank', 'width=300,height=200');
        this.ReceiptURL = this.Utilities.getDigiPosReceiptURL(this.Utilities.getEncrypt(this.Utilities.getCompanyName()), this.Utilities.getEncrypt(sessionStorage.getItem('orderid')), this.CountryCode);
      }
      // this.ReceiptURL = this.Utilities.getReceiptURL(this.Utilities.getEncrypt(this.Utilities.getCompanyName()), this.Utilities.getEncrypt(sessionStorage.getItem('orderid')), this.CountryCode);
      this.ReceiptURL = this.Utilities.getDigiPosReceiptURL(this.Utilities.getEncrypt(this.Utilities.getCompanyName()), this.Utilities.getEncrypt(sessionStorage.getItem('orderid')), this.CountryCode);
    }
    else if (result[1][0].IsReceiptDirectPrint == 2) {
      if (this.IsMaxPolicyOn) {
        if (this.ReceiptPrintOutCount == undefined || this.ReceiptPrintOutCount == null) { this.ReceiptPrintOutCount = 0; }
        if (this.MaxReceiptPrintOut > this.ReceiptPrintOutCount) {
          let link = result[1][0].ServerLink + '/Default?O=' + result[1][0].OrderID + '&C=' + result[1][0].CompanyId + '&type=RP' + '&OutletID=' + this.SelectedBranchID + '&ShiftID=' + this.ShiftID + '&AddnDeleteItems=' + this.AddnDeleteItems + '&POSID=' + this.counterID;
          this.win = await window.open(link, '_blank', 'width=300,height=200');
          this.ReceiptPrintOutCount = this.ReceiptPrintOutCount + 1;
        }
        else {
          this.printvalidation = true;
        }
      }
      else {
        let link = result[1][0].ServerLink + '/Default?O=' + result[1][0].OrderID + '&C=' + result[1][0].CompanyId + '&type=RP' + '&OutletID=' + this.SelectedBranchID + '&ShiftID=' + this.ShiftID + '&AddnDeleteItems=' + this.AddnDeleteItems + '&POSID=' + this.counterID;
        this.win = await window.open(link, '_blank', 'width=300,height=200');
      }

    }
    this.lstBasket = [];
    this.lstComboProducts = [];
    this.lstVoucherPaymentDetail = [];
    this.lstSelectModifier = [];
    this.Total = 0;
    this.Subtoal = 0;
    this.DiscountAmont = 0;
    this.PromoAmount = 0;
    this.PromoCode = '';
    this.VAT = 0;
    this.SubtoalForVAT = 0;
    this.custName = '';
    this.CustomerId = 0;
    this.lstSelectedProduct = [];
    this.DeliveryFee = 0;
    this.SelectedOrderInvoice = '';
    this.chkKOT = false;
    this.TableName = undefined;
    this.TableID = null;
    this.noOfCustomers = null;
    this.PromoAmountforVisibility = 0;
    this.SearchBarcode = '';
    this.IsBarcodeFound = false;
    this.IsVoidButtonActive = true;
    this.DineOrderID = 0;
    this.lstVoucherPaymentDetail = [];
    this.loyaltypoints = 0;
    this.AvailingAmount = 0;
    this.IsLoyaltySelected = false;
    this.Consumed_LoyaltyPoints = 0;
    this.Consumed_LoyaltyAmount = 0;
    this.MobileNo = '';
    //this.IsExtraCharge = false;
    this.ExtraChargeAmount = 0;
    this.IsPrintButtonDisplay = false
    //this.ExtraChargeDescription = '';
    //this.ExtraChargeDescriptionArabic = '';
    //this.win.close(); 
    this.loading = false;


  }
  onLoad() {
    let timeout = this.Utilities.GetTimeOut();
    debugger;
    if (this.ReceiptURL != '') {
      setTimeout(() => {
        window.frames["printf"].focus();
        window.frames["printf"].print();
      }, 2000);

    }
  }

  async checkIframeLoaded() {

    // Get a handle to the iframe element
    var iframe = document.getElementById('printf') as HTMLIFrameElement;
    var iframeDoc = iframe.contentDocument || iframe.contentWindow.document;

    // Check if loading is complete
    if (iframeDoc.readyState == 'complete') {
      //iframe.contentWindow.alert("Hello");
      //await iframe.contentWindow.print();
      // The loading is complete, call the function we want executed once the iframe is loaded
      await this.onLoad();
      return false;
    }

    // If we are here, it is not loaded. Set things up so we check   the status again in 100 milliseconds
    //window.setTimeout(this.checkIframeLoaded, 100);
  }

  TableID: any;
  TableName: any;
  Dinedata: any = [];
  DineOrderID: any;
  noOfCustomers: any;
  TableClosedBy: any;
  TableClosedOn: any;
  DeliverectOrderId: any;
  OtherChannel: any;
  openDineInTablePopup(salesTypeId, SalesTypeName) {
    debugger

    const dialogRef = this.dialog.open(DinetablePopupComponent, {
      width: '1300px',
      height: '600px',
      data: { culture: this.cultures, outletID: this.SelectedBranchID, IsDineTable: true, NumOfDecimal: this.NumOfDecimal, SalesTypeID: salesTypeId, SalesTypeName: SalesTypeName, DayID: this.DayID, POSID: this.counterID }
    });


    dialogRef.afterClosed().subscribe(result => {

      debugger;
      if (result != undefined) {
        this.Dinedata = result;

        if (this.Dinedata["Status"] == 0) {
          // this.IsVoidButtonActive = true;
          this.TableID = this.Dinedata["ID"]
          this.TableName = this.Dinedata["TableName"]
          this.noOfCustomers = this.Dinedata["noOfCustomers"];
          this.TableClosedBy = this.Utilities.getUserId();
          this.TableClosedOn = this.Utilities.GetCurrentDateTime();
          this.lstBasket = [];
          this.lstbasketdata = [];
          this.lstComboProducts = [];
          this.lstModifier = [];
          this.lstSelectModifier = [];
          this.Subtoal = 0;
          this.Total = 0;
          this.VAT = 0;
          this.SubtoalForVAT = 0;
          this.DiscountAmont = 0;
          this.PromoAmountforVisibility = 0;
          this.PromoAmount = 0;
          this.PromoCode = '';
          this.SelectedOrderInvoice = '';
          this.DineOrderID = 0;
          this.chkKOT = false;
          /*this.IsExtraCharge = false*/;
          this.ExtraChargeAmount = 0;
          //this.ExtraChargeDescription = '';
          //this.ExtraChargeDescriptionArabic = '';
          // this.CustomerId = 0;
          // this.custId = '';
          // this.custName = '';
          // this.lstVoucherPaymentDetail = [];
          this.OrderTypeID = 1;
          this.OrderType = "Dine In";

          this.getProduct(this.productstr);
        }
        else {
          if (this.IsRightsEmployeeWise) {
            if (this.arr.some(y => y.EntityCode == "voidbutton")) {
              this.IsVoidButtonActive = true;
            }
            else
              this.IsVoidButtonActive = false;

          }
          else if (this.lstPosButtonDeviceWise) {
            if (this.lstPosButtonDeviceWise.some(x => x.EntityCode == "voidbutton")) {
              this.IsVoidButtonActive = true;
            }
            else {
              this.IsVoidButtonActive = false;
            }
          }
          this.TableID = this.Dinedata["ID"];
          this.TableName = this.Dinedata["TableName"];
          this.DineOrderID = this.Dinedata["OrderID"];
          this.getorderdetails(this.Dinedata["OrderID"]);
          this.OrderID = this.Dinedata["OrderID"];
          this.chkKOT = true;

        }
        if (this.Dinedata["OrderID"] > 0)
          this.IsPrintButtonDisplay = true
        else
          this.IsPrintButtonDisplay = false

        // this.TableName = result;
      }
      else {
        this.OrderTypeID = 1;
        this.OrderType = "Dine In";
        this.getProduct(this.productstr);
      }

    });
  }
  // CustomerAddressID: any;
  // openDeliveryPopup() {
  //   const dialogRef = this.dialog.open(DeliveryPopupComponent, {
  //     width: '1150px',
  //     height: '600px',
  //     data: { outletID: this.SelectedBranchID }

  //   });

  //   dialogRef.afterClosed().subscribe(result => {
  //     if (result != undefined) {
  //       
  //       this.SelectedCutomer = result;
  //       this.CustomerId = this.SelectedCutomer["custID"];
  //       this.custName = this.SelectedCutomer["custName"];
  //       this.custId = result.custID;
  //       this.custName = result.custName;
  //       this.CustomerAddressID = result.CustAddressID;
  //       this.MobileNo = result.PhoneNo;
  //     }

  //   });
  // }

  openPaidOrdersPopup() {

    const dialogRef = this.dialog.open(PaidOrdersPopupComponent, {
      width: '1100px',
      height: '670px',
      data: { data: this.IsCancelButtonPaidOrderActive, OutletId: this.SelectedBranchID, IsOpenCheckButtonActive: this.IsOpenCheckButtonPaidOrderActive }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        this.AddnDeleteItems = false;
      }

    });
  }
  prntrec() {
    if (this.lstBasket.length < 1) {
      alert(this.translate.transform('Pleaseselectorderoradditemsinbasket'));
      return;
    }
    window.print();
  }
  openSplitBillPopup(dt, modifier, Subtoal, DiscountAmont, VAT, Total, ObjOutletInfo, comboproduct) {

    let CurrencyCode = this.CurrencyCode;
    let NoOfDecimal = this.NumOfDecimal;

    let IsPercentPromoDiscount = this.IsPercentPromoDiscount;
    //let ISCalculateTaxProductWise = this.ISCalculateTaxProductWise;
    let ISCalculateTaxProductWise = JSON.parse(localStorage.getItem('counterID&CompanyID' + this.companyName))[0].ISCalculateTaxProductWise;

    dt = dt.filter(x => x.DelFlag != true);

    dt = JSON.stringify(dt);
    debugger
    const dialogRef = this.dialog.open(SplitBillPopupComponent, {
      width: '1150px',
      height: '600px',
      data: { dt, modifier, CurrencyCode, NoOfDecimal, Subtoal, DiscountAmont, VAT, Total, ObjOutletInfo, comboproduct, IsPercentPromoDiscount, ISCalculateTaxProductWise }
    });

    dialogRef.afterClosed().subscribe(result => {
      //
      if (result != undefined) {
        //

        this.lstBasket = [];
        this.lstComboProducts = [];
        this.lstSelectModifier = [];
        this.ArrMasterData = [];
        this.ArrSelectedDetailData = [];
        this.Total = 0;
        this.UserName = '';
        this.Subtoal = 0;
        this.DiscountAmont = 0;
        this.PromoAmount = 0;
        this.PromoCode = '';
        this.VAT = 0;
        this.SubtoalForVAT = 0;
        this.CustomerId = 0;
        this.custId = '';
        this.custName = '';
        this.SelectedOrderInvoice = '';
        this.lstVoucherPaymentDetail = [];
        /*this.IsExtraCharge = false*/;
        this.ExtraChargeAmount = 0;
        //this.ExtraChargeDescription = '';
        //this.ExtraChargeDescriptionArabic = '';
      }

    });
  }

  ItemBarcode: any = '';
  custId: any;
  custName: any;
  SelectedCutomer: any = [];
  LoyaltyPointsInfo: any;
  lstLoyaltyPointsData: any;
  CustomerAddressID: any;
  openDialogCustomerLookUp() {

    let dialogRef = this.dialog.open(DeliveryPopupComponent, {
      width: '1150px',
      height: '650px',
      data: { outletID: this.SelectedBranchID }
    });


    dialogRef.afterClosed().subscribe(result => {
      debugger
      if (result != undefined && result[0] != undefined) {
        this.SelectedCutomer = result[0];
        this.CustomerId = this.SelectedCutomer["custID"];
        this.custName = this.SelectedCutomer["custName"];
        this.custId = result[0].custID;
        this.custName = result[0].custName;
        this.CustomerAddressID = result[0].CustAddressID;
        this.MobileNo = result[0].PhoneNo;
        this.MaxCreditLimit = result[0].MaxCreditLimit;
        this.OrderType = 'Delivery';
      }
      if (result != undefined && result[1] != undefined) {
        this.LoyaltyPointsInfo = result[1];
      }
      else {
        this.lstLoyaltyPointsData = [];
      }
      if (result != undefined && result[2] != undefined) {
        this.lstLoyaltyPointsData = result[2];
        for (var i = 0; i < this.lstBasket.length; i++) {

          for (var j = 0; j < this.lstLoyaltyPointsData.length; j++) {

            if (this.lstLoyaltyPointsData[j].ProductID == this.lstBasket[i].ProductID && this.lstLoyaltyPointsData[j].ProductDetailID == this.lstBasket[i].ProductDetailID) {
              this.lstBasket[i].LoyalityID = this.lstLoyaltyPointsData[j].LoyalityID;
              this.lstBasket[i].LoyaltyDetailId = this.lstLoyaltyPointsData[j].LoyaltyDetailId;
              this.lstBasket[i].Points = this.lstLoyaltyPointsData[j].Points;
              this.lstBasket[i].ValuePoints = this.lstLoyaltyPointsData[j].ValuePoints;
              this.lstBasket[i].AvailingAmount = this.lstLoyaltyPointsData[j].AvailingAmount;
            }
          }
        }
        this.setloyalty(0, 0);
      }
      else {
        this.lstLoyaltyPointsData = [];
      }

    });
  }


  openDialogCategoryMenuPopup() {

    let dialogRef = this.dialog.open(CategorymenupopupComponent, {
      width: '1150px',
      height: '600px',
      data: { outletID: this.SelectedBranchID, culture: this.cultures, counterID: this.counterID }

    });


    dialogRef.afterClosed().subscribe(result => {
      debugger
      if (result != undefined) {
        this.getProduct(result);
      }
    });
  }
  OpenDialogSalesTypePopup() {

    let dialogRef = this.dialog.open(CategorymenupopupComponent, {
      width: '1150px',
      height: '600px',
      data: { outletID: this.SelectedBranchID, culture: this.cultures, IsSalesTypePopup: true, SalesType: this.lstOrderType }

    });


    dialogRef.afterClosed().subscribe(result => {
      debugger
      if (result != undefined) {
        this.figureClick(result[0], result[1]);
      }
    });
  }

  // lstCustomerLoyaltyPointsData=[];
  // getCustomerloyaltypoints(){
  //   this.dataservice.get('Pos/GetCustomerLoyaltyPoints/'+this.companyID+'/'+this.CustomerId+'/'+this.currentdate)
  //     .subscribe(data => {debugger
  //       if (this.isArrayHasValue(data[0]['lstCustomerLoyaltyPointsData'])) {
  //         this.lstCustomerLoyaltyPointsData = data[0]['lstCustomerLoyaltyPointsData'];
  //       }
  //       else{
  //         this.lstCustomerLoyaltyPointsData = [];
  //       }
  //     }, error => alert('error'));
  // }


  // posRetailPopup: boolean = false;
  // openAddCustomer() {
  //   let dialogRef = this.dialog.open(DeliveryPopupComponent, {
  //     width: '1150px',
  //     height: '600px',
  //     data: { outletID: this.SelectedBranchID }

  //   });

  //   dialogRef.afterClosed().subscribe(result => {
  //     if (result != undefined) {
  //       //
  //       this.SelectedCutomer = result;
  //       this.CustomerId = this.SelectedCutomer["custId"];
  //       this.custName = this.SelectedCutomer["custName"];
  //       this.custId = result.custID;
  //       this.custName = result.custName;
  //       this.CustomerAddressID = result.CustAddressID;

  //     }
  //   });
  // }

  replaceAstrophe(val): any {
    return val.replaceAll("'", "''");
  }





  getSelectedDetailData(): void {
    debugger;
    this.ArrSelectedDetailData = new Array();
    this.lstbasketdata = this.lstBasket;
    this.lstSelectedModifrs = this.lstSelectModifier;
    //simple product detail
    for (var i = 0; i < this.lstbasketdata.length; i++) {
      debugger
      //  if(!comboid){
      this.ArrSelectedDetailData.push({
        'ProductId': this.lstbasketdata[i].ProductID,
        'Quantity': this.lstbasketdata[i].Quantity,
        'AddOnID': null,
        'ProductCategoryID': this.lstbasketdata[i].CatagoryID,
        'ProductDetailID': this.lstbasketdata[i].ProductDetailID,
        'SpecialRequest': this.lstbasketdata[i].SpecialRequest,
        'UnitPrice': this.lstbasketdata[i].Price,
        'TaxAmount': this.lstbasketdata[i].TaxAmount,
        'TaxPercent': this.lstbasketdata[i].TaxPercent,
        'ProductDiscount': this.lstbasketdata[i].ProductDiscount,
        'ProductModifireReferenceKey': this.lstbasketdata[i].rowid,
        'PriceWithOutVAT': this.lstbasketdata[i].PriceWithOutVAT,
        'DiffPriceWithOutVAT': this.lstbasketdata[i].DiffPriceWithOutVAT,
        'DiffProductDiscount': this.lstbasketdata[i].DiffProductDiscount,
        'DiffTaxAmount': this.lstbasketdata[i].DiffTaxAmount,
      });
      // }
      //Modifier Detail Array
      for (var j = 0; j < this.lstSelectedModifrs[i].length; j++) {
        debugger
        if (this.lstbasketdata[i].ProductID == this.lstSelectedModifrs[i][j].ProductID && this.lstbasketdata[i].rowid == this.lstSelectedModifrs[i][j].rowid) {
          debugger
          this.ArrSelectedDetailData.push({
            'ProductId': this.lstSelectedModifrs[i][j].ProductID,
            'Quantity': this.lstbasketdata[i].Quantity,
            'AddOnID': this.lstSelectedModifrs[i][j].ModifierId,
            'ProductCategoryID': this.lstbasketdata[i].CatagoryID,
            'ProductDetailID': this.lstbasketdata[i].ProductDetailID,
            'UnitPrice': this.lstSelectedModifrs[i][j].Price,
            'TaxAmount': this.lstbasketdata[i].TaxAmount,
            'TaxPercent': this.lstbasketdata[i].TaxPercent,
            'ProductDiscount': this.lstbasketdata[i].ProductDiscount,
            'ProductModifireReferenceKey': this.lstSelectedModifrs[i][j].rowid,
            'PriceWithOutVAT': this.lstbasketdata[i].PriceWithOutVAT,
            'DiffPriceWithOutVAT': this.lstbasketdata[i].DiffPriceWithOutVAT,
            'DiffProductDiscount': this.lstbasketdata[i].DiffProductDiscount,
            'DiffTaxAmount': this.lstbasketdata[i].DiffTaxAmount
          });
        }
      }
      //ComboProduct Detail
      for (var k = 0; k < this.lstComboProducts.length; k++) {
        debugger
        if (this.lstComboProducts[k].MainProductID == this.lstbasketdata[i].ProductID && this.lstComboProducts[k].rowid == this.lstbasketdata[i].rowid && this.lstComboProducts[k].IsComboItem == 1) {
          debugger
          this.ArrSelectedDetailData.push({
            'ComboProductId': this.lstComboProducts[k].ProductId,
            'UnitPrice': this.lstComboProducts[k].Price,
            'ProductId': this.lstComboProducts[k].ProductId,
            'Quantity': this.lstbasketdata[i].Quantity,
            'AddOnID': null,
            'ProductCategoryID': this.lstbasketdata[i].CatagoryID,
            'TaxAmount': this.lstbasketdata[i].TaxAmount,
            'TaxPercent': this.lstbasketdata[i].TaxPercent,
            'ProductDetailID': this.lstbasketdata[i].ProductDetailID,
            'ProductDiscount': this.lstbasketdata[i].ProductDiscount,
            'PriceWithOutVAT': this.lstbasketdata[i].PriceWithOutVAT,
            'DiffPriceWithOutVAT': this.lstbasketdata[i].DiffPriceWithOutVAT,
            'DiffProductDiscount': this.lstbasketdata[i].DiffProductDiscount,
            'DiffTaxAmount': this.lstbasketdata[i].DiffTaxAmount,
            'ProductModifireReferenceKey': this.lstComboProducts[k].rowid,

          });
        }
      }

    }

    this.ArrMasterData.push({
      'DeliveryFee': this.DeliveryFee, 'OrderDate': this.Utilities.GetCurrentDateTime(), 'PayType': this.rdPaymentMethod, 'OrderPayStatus': 'Pending', 'OutletID': this.SelectedBranchID, 'Channel': 'POS', 'Remarks': this.replaceAstrophe(this.remarks), 'CustomerID': this.CustomerId == undefined ? 0 : this.CustomerId,
      'TotalAmount': this.Subtoal, 'NetAmount': this.Total, 'TotalDiscount': this.DiscountAmont, 'OrderType': this.OrderType, 'OrderPerpStatus': this.OrderPerpStatus, 'DiscountCode': this.DiscountCode, 'DeliveryTime': this.TimeStampSelected, 'TaxID': this.TaxId, 'TotalTaxAmount': this.VAT, 'CompanyId': this.Utilities.getSubscriptionID(), 'TableNumber': this.TableName,
      'ShiftID': this.ShiftID, 'DayID': this.DayID, 'PromoCode': this.PromoCode, 'PromoAmount': this.PromoAmountforVisibility, 'noOfCustomers': this.noOfCustomers, 'CustAddressID': this.CustomerAddressID == undefined ? 0 : this.CustomerAddressID, 'OrderTypeID': this.OrderTypeID, 'TableClosedBy': this.TableClosedBy, 'TableClosedOn': this.TableClosedOn, 'IsExtraCharge': this.IsExtraCharge, 'ExtraChargeDescription': this.ExtraChargeDescription, 'ExtraChargeAmount': this.ExtraChargeAmount, 'ExtraChargeDescriptionArabic': this.ExtraChargeDescriptionArabic, 'TableID': this.TableID, 'POSID': this.counterID
    });
  }

  getSelectedKOTDetailData(): void {
    debugger
    this.ArrSelectedDetailData = new Array();
    this.lstbasketdata = this.lstBasket;
    this.lstSelectedModifrs = this.lstSelectModifier;

    for (var i = 0; i < this.lstbasketdata.length; i++) {
      debugger
      this.ArrSelectedDetailData.push({
        'ProductID': this.lstbasketdata[i].ProductID,
        'Quantity': this.lstbasketdata[i].Quantity,
        'AddOnID': null,
        'ProductCategoryID': this.lstbasketdata[i].ProductCategoryID,
        'ProductDetailID': this.lstbasketdata[i].ProductDetailID,
        'ProductSizeID': this.lstbasketdata[i].ProductSizeID,
        'DelFlag': this.lstBasket[i].DelFlag,
        'RejectReasonID': this.lstBasket[i].RejectReasonID,
        'RejectedReason': this.lstBasket[i].RejectedReason,
        'DeletedBy': this.lstBasket[i].DeletedBy,
        'DeletedOn': this.lstBasket[i].DeletedOn,
        'Printed': this.lstBasket[i].Printed,
        'IsDeletedItemReprint': this.lstBasket[i].IsDeletedItemReprint,
        'SpecialRequest': this.lstBasket[i].SpecialRequest,
        'UnitPrice': this.lstbasketdata[i].Price,
        'IsNewItemPunch': this.lstbasketdata[i].IsNewItemPunch,
        'TaxAmount': this.lstbasketdata[i].TaxAmount,
        'TaxPercent': this.lstbasketdata[i].TaxPercent,
        'ProductDiscount': this.lstbasketdata[i].ProductDiscount,
        'PriceWithOutVAT': this.lstbasketdata[i].PriceWithOutVAT,
        'DiffPriceWithOutVAT': this.lstBasket[i].DiffPriceWithOutVAT,
        'DiffProductDiscount': this.lstBasket[i].DiffProductDiscount,
        'DiffTaxAmount': this.lstBasket[i].DiffTaxAmount,
        'ProductModifireReferenceKey': this.lstbasketdata[i].rowid,
      });

      for (var j = 0; j < this.lstSelectedModifrs[i].length; j++) {
        debugger
        if (this.lstbasketdata[i].ProductID == this.lstSelectedModifrs[i][j].ProductID && this.lstbasketdata[i].rowid == this.lstSelectedModifrs[i][j].rowid) {
          debugger
          this.ArrSelectedDetailData.push({
            'ProductID': this.lstSelectedModifrs[i][j].ProductID,
            'Quantity': this.lstbasketdata[i].Quantity,
            'AddOnID': this.lstSelectedModifrs[i][j].ModifierId,
            'ProductCategoryID': this.lstbasketdata[i].ProductCategoryID,
            'ProductDetailID': this.lstbasketdata[i].ProductDetailID,
            'ProductSizeID': this.lstbasketdata[i].ProductSizeID,
            'UnitPrice': this.lstSelectedModifrs[i][j].Price,
            'TaxAmount': this.lstbasketdata[i].TaxAmount,
            'TaxPercent': this.lstbasketdata[i].TaxPercent,
            'ProductDiscount': this.lstbasketdata[i].ProductDiscount,
            'PriceWithOutVAT': this.lstbasketdata[i].PriceWithOutVAT,
            'DiffPriceWithOutVAT': this.lstBasket[i].DiffPriceWithOutVAT,
            'DiffProductDiscount': this.lstBasket[i].DiffProductDiscount,
            'DiffTaxAmount': this.lstBasket[i].DiffTaxAmount,
            'ProductModifireReferenceKey': this.lstSelectedModifrs[i][j].rowid,
            'Printed': this.lstBasket[i].Printed,
            'DelFlag': this.lstBasket[i].DelFlag,
          });
        }
      }
      //ComboProduct Detail
      for (var k = 0; k < this.lstComboProducts.length; k++) {
        debugger
        if (this.lstComboProducts[k].MainProductID == this.lstbasketdata[i].ProductID && this.lstComboProducts[k].rowid == this.lstbasketdata[i].rowid && this.lstComboProducts[k].IsComboItem == 1) {
          debugger
          this.ArrSelectedDetailData.push({
            'ComboProductId': this.lstComboProducts[k].ProductId == undefined ? this.lstComboProducts[k].ProductID : this.lstComboProducts[k].ProductId,
            'UnitPrice': this.lstComboProducts[k].Price,
            'ProductId': this.lstComboProducts[k].ProductId == undefined ? this.lstComboProducts[k].ProductID : this.lstComboProducts[k].ProductId,
            'ProductID': this.lstComboProducts[k].ProductId == undefined ? this.lstComboProducts[k].ProductID : this.lstComboProducts[k].ProductId,
            'Quantity': this.lstbasketdata[i].Quantity,
            'AddOnID': null,
            'ProductCategoryID': this.lstbasketdata[i].CatagoryID,
            'TaxAmount': this.lstbasketdata[i].TaxAmount,
            'TaxPercent': this.lstbasketdata[i].TaxPercent,
            'ProductDetailID': this.lstbasketdata[i].ProductDetailID,
            'ProductDiscount': this.lstbasketdata[i].ProductDiscount,
            'PriceWithOutVAT': this.lstbasketdata[i].PriceWithOutVAT,
            'DiffPriceWithOutVAT': this.lstbasketdata[i].DiffPriceWithOutVAT,
            'DiffProductDiscount': this.lstbasketdata[i].DiffProductDiscount,
            'DiffTaxAmount': this.lstbasketdata[i].DiffTaxAmount,
            'ProductModifireReferenceKey': this.lstComboProducts[k].rowid,

          });
        }
      }

    }



  }

  DayShiftOpenPopup() {
    let message = 'sessionclosed';
    let dialogRef = this.dialog.open(AboutUsComponent,
      {
        width: '500px',
        height: '200px',

        data: { message },
      });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {


      }

    });
  }
  KOTclick(): void {




    debugger
    this.loading = true;
    this.KitchenReceiptPrintOutCount = 0;
    this.ReceiptPrintOutCount = 0;
    this.printvalidation = false;
    let DayID;
    let ShiftID;

    if (sessionStorage.getItem('DayID&ShiftID&DayStartDate') != null) {
      DayID = JSON.parse(sessionStorage.getItem('DayID&ShiftID&DayStartDate'))[0];
      ShiftID = JSON.parse(sessionStorage.getItem('DayID&ShiftID&DayStartDate'))[1];
    }

    if (DayID == undefined || ShiftID == undefined) {
      this.DayShiftOpenPopup();
      return
    }
    if (this.OrderTypeID == 3 && (this.CustomerId == 0 || this.CustomerId == undefined || this.CustomerId == null || this.CustomerId == '')) {
      alert(this.translate.transform('Pleaseselectcustomer'));
      return
    }


    if (this.OrderTypeID == 1 && (this.TableName == undefined || this.TableName == '')) {
      this.loading = false;
      alert(this.translate.transform('Pleaseselecttablefirst'));
      return;
    }
    if (this.isArrayHasValue(this.lstBasket)) {
      // if(this.TableName == undefined || this.TableName == null || this.TableName == '')
      // {
      //   alert('Please select table')
      // }
      // else
      // {
      if (!this.chkKOT) {
        this.paybill();
      }
      else {
        this.AddItemInKOT();
      }
      // }

    }
    else {
      this.loading = false;
      const dialogRef = this.dialog.open(AlertPopupComponent, {
        width: '300px',
        height: '200px',
      });
    }

  }



  HoldOrderClick(): void {

    debugger
    this.loading = true;
    this.KitchenReceiptPrintOutCount = 0;
    this.ReceiptPrintOutCount = 0;
    this.printvalidation = false;
    let DayID;
    let ShiftID;

    if (sessionStorage.getItem('DayID&ShiftID&DayStartDate') != null) {
      DayID = JSON.parse(sessionStorage.getItem('DayID&ShiftID&DayStartDate'))[0];
      ShiftID = JSON.parse(sessionStorage.getItem('DayID&ShiftID&DayStartDate'))[1];
    }

    if (DayID == undefined || ShiftID == undefined) {
      this.DayShiftOpenPopup();
      return
    }
    if (this.OrderTypeID == 3 && (this.CustomerId == 0 || this.CustomerId == undefined || this.CustomerId == null || this.CustomerId == '')) {
      alert(this.translate.transform('Pleaseselectcustomer'));
      return
    }


    if (this.OrderTypeID == 1 && (this.TableName == undefined || this.TableName == '')) {
      this.loading = false;
      alert(this.translate.transform('Pleaseselecttablefirst'));
      return;
    }
    if (this.isArrayHasValue(this.lstBasket)) {
      //this.AddItemInKOT();
      this.getSelectedDetailData();
      var CurrentDate = this.Utilities.GetCurrentDate();
      let body = [this.ArrMasterData, this.ArrSelectedDetailData, this.GuestCutomer, this.MobileNo, this.Utilities.getSubscriptionID(), this.Total, this.ReturnAmount, this.UserName, this.Utilities.getUserId(), CurrentDate, this.OrderType, this.SelectedBranchID, this.lstVoucherPaymentDetail, this.counterID, this.HoldOrderID];

      this.OpenHoldOrderConfirmPopup(body);
    }
    else {
      this.loading = false;
      const dialogRef = this.dialog.open(AlertPopupComponent, {
        width: '300px',
        height: '200px',
      });
    }

  }

  ReferenceNo: any = '';
  OpenHoldOrderConfirmPopup(body): void {
    debugger

    const dialogRef = this.dialog.open(HoldOrderConfirmPopupComponent, {
      width: '600px',
      height: '400px',
      data: { body: body, OrderFrom: 'restaurant', ReferenceNo: this.ReferenceNo }
    });

    dialogRef.afterClosed().subscribe(row => {
      debugger
      this.loading = false;
      if (row != undefined)

        this.UpdatePrintColumn_UpdateKOT();

    });
  }
  HoldOrderID: any = 0;
  OpenRecallPopup(): void {
    debugger

    const dialogRef = this.dialog.open(RecallRestaurantOrdersComponent, {
      width: '1000px',
      height: '800px',
      data: { culture: this.cultures, DayID: this.DayID, POSID: this.counterID }
    });

    dialogRef.afterClosed().subscribe(row => {
      debugger

      let OrderID = row;
      if (OrderID != undefined) {
        this.HoldOrderID = OrderID;
        this.getorderdetails_Hold(row);
      }

    });
  }

  getorderdetails_Hold(orderid: any) {
    //
    this.KotOrderID = orderid;
    this.dataservice.get('pos/GetHoldOrdersdetails/' + this.Utilities.getSubscriptionID() + '/' + orderid).subscribe(data => {
      debugger
      if (this.isArrayHasValue(data[0]['Ordersdetails'])) {

        this.lstBasket = data[0]['Ordersdetails'];
        // this.lstBasket.forEach(basket => basket.Amount = basket.Price);
        this.TableID = data[0]["Ordersdetails"][0]['TableID'];
        this.TableName = data[0]["Ordersdetails"][0]['TableNumber'];
        this.Subtoal = data[0]["Ordersdetails"][0]['TotalAmount'];
        this.SubtoalForVAT = this.Subtoal;
        this.Total = data[0]["Ordersdetails"][0]['NetAmount'];
        this.VAT = data[0]["Ordersdetails"][0]['TotalTaxAmount'];
        this.DiscountAmont = data[0]["Ordersdetails"][0]['TotalDiscount'];
        this.custName = data[0]["Ordersdetails"][0]['UserFullName'];
        this.CustomerId = data[0]["Ordersdetails"][0]['CustomerID'];
        this.OrderTypeID = data[0]["Ordersdetails"][0]['OrderTypeID'];
        this.OrderType = data[0]["Ordersdetails"][0]['OrderType'];
        this.SelectedOrderInvoice = data[0]["Ordersdetails"][0]['OrderInvoiceNo'];
        // this.PromoAmount = data[0]["Ordersdetails"][0]['PromoAmount'] == null ? 0 : data[0]["Ordersdetails"][0]['PromoAmount'];
        this.PromoAmountforVisibility = data[0]["Ordersdetails"][0]['PromoAmount'] == null ? 0 : data[0]["Ordersdetails"][0]['PromoAmount'];
        this.IsPercentPromoDiscount = data[0]["Ordersdetails"][0]['IsPercent'];
        this.PromoAmount = data[0]["Ordersdetails"][0]['DisVoucherValue'];
        this.PromoCode = data[0]["Ordersdetails"][0]['PromoCode'];
        this.IsExtraCharge = data[0]["Ordersdetails"][0]['IsExtraCharge'];

        this.IsExtraCharge = data[0]["Ordersdetails"][0]['IsExtraCharge'];
        this.ExtraChargeAmount = data[0]["Ordersdetails"][0]['ExtraChargeAmount'];
        this.ExtraChargeDescription = data[0]["Ordersdetails"][0]['ExtraChargeDescription'];
        this.ExtraChargeDescriptionArabic = data[0]["Ordersdetails"][0]['ExtraChargeDescriptionArabic'];
        debugger
        this.ReceiptPrintOutCount = data[0]["Ordersdetails"][0]['ReceiptPrintOutCount'];
        this.KitchenReceiptPrintOutCount = data[0]["Ordersdetails"][0]['KitchenReceiptPrintOutCount'];

        this.ReferenceNo = data[0]["Ordersdetails"][0]['ReferenceNo'];
        // this.Amount = this.lstBasket[index].Quantity * row.Price;
      }


      if (this.isArrayHasValue(data[0]['OrdersModifiers'])) {
        debugger
        this.lstSelectModifier = [];
        // var myArray = {"selectedmodifiers" :  data[0]['OrdersModifiers']}

        debugger
        let lst = data[0]['OrdersModifiers'];
        for (let i = 0; i < this.lstBasket.length; i++) {
          let row = lst.filter(t => t.ProductID == this.lstBasket[i].ProductID && t.rowid == this.lstBasket[i].rowid);
          if (row.length > 0)
            this.lstSelectModifier.push(row);
          else
            this.lstSelectModifier.push([{ AllowMultipleSeletion: null, DetailId: null, IsSelected: null, ListName: null, MasterId: null, MaxModifiers: null, MinModifiers: null, ModifierId: null, Modifires: null, Price: null, PriceStatic: null, ProductID: null, rowid: null }]);
        }
      }


      else if (this.isArrayHasValue(data[0]['DummyModifier'])) {
        this.lstSelectModifier = [];
        for (let i = 0; i < this.lstBasket.length; i++) {
          this.lstSelectModifier.push([{ AllowMultipleSeletion: null, DetailId: null, IsSelected: null, ListName: null, MasterId: null, MaxModifiers: null, MinModifiers: null, ModifierId: null, Modifires: null, Price: null, PriceStatic: null, ProductID: null, rowid: null }]);
        }
        //this.lstSelectModifier = Array.of(this.lstSelectModifier);
      }



      if (this.isArrayHasValue(data[0]['VoucherPaymentDetail'])) {
        this.ObjdiscountVoucher.DiscVoucherID = data[0]["VoucherPaymentDetail"][0].DiscVoucherID;
        this.ObjdiscountVoucher.VoucherCode = data[0]["VoucherPaymentDetail"][0].VoucherCode;
        this.ObjdiscountVoucher.VoucherDesc = data[0]["VoucherPaymentDetail"][0].VoucherDesc;
        this.ObjdiscountVoucher.IsPercent = data[0]["VoucherPaymentDetail"][0].IsPercent;
        this.ObjdiscountVoucher.Amount = data[0]["VoucherPaymentDetail"][0].Amount;
        this.ObjdiscountVoucher.MinOrderValue = data[0]["VoucherPaymentDetail"][0].MinOrderValue;
        this.ObjdiscountVoucher.UsagePerUser = data[0]["VoucherPaymentDetail"][0].UsagePerUser;
        this.ObjdiscountVoucher.UsagePerVoucher = data[0]["VoucherPaymentDetail"][0].UsagePerVoucher;
        this.ObjdiscountVoucher.VoucherExpiryDate = data[0]["VoucherPaymentDetail"][0].VoucherExpiryDate;
        this.ObjdiscountVoucher.OutletIds = data[0]["VoucherPaymentDetail"][0].OutletIds;
        this.ObjdiscountVoucher.Status = data[0]["VoucherPaymentDetail"][0].Status;
        this.ObjdiscountVoucher.CompanyId = data[0]["VoucherPaymentDetail"][0].CompanyId;
        this.addVoucherPaymentDetail();
      }
      debugger
      if (this.isArrayHasValue(data[0]['ComboItemdData'])) {

        this.lstComboProducts = [];
        // var myArray = {"selectedmodifiers" :  data[0]['OrdersModifiers']}

        debugger
        let lst = data[0]['ComboItemdData'];
        for (let i = 0; i < this.lstBasket.length; i++) {
          let row = lst.filter(t => t.rowid == this.lstBasket[i].rowid);
          if (row.length > 0)
            this.lstComboProducts.push(...row);

        }
      }

    });
  }

  IsSessionClosed: boolean = false;
  paybill() {
    debugger
    this.printvalidation = false;
    this.getSelectedDetailData();
    this.Print_Receipt_Method_InsertKOT();
  }

  async Print_Receipt_Method_InsertKOT() {
    var CurrentDate = this.Utilities.GetCurrentDate();
    // var OrderType = this.ArrMasterData[0].OrderType;
    let body = [this.ArrMasterData, this.ArrSelectedDetailData, this.GuestCutomer, this.MobileNo, this.Utilities.getSubscriptionID(), this.Total, this.ReturnAmount, this.UserName, this.Utilities.getUserId(), CurrentDate, this.OrderType, this.SelectedBranchID, this.lstVoucherPaymentDetail, this.counterID, this.HoldOrderID]
    const response = await this.dataservice.post('pos/SavePPOSOrderData/', body).toPromise().then((data: any) => {
      debugger
      debugger
      if (data['Table'][0].msg == 'sessionclosed') {
        this.IsSessionClosed = true;
        let message = 'sessionclosed';
        let dialogRef = this.dialog.open(AboutUsComponent,
          {
            width: '500px',
            height: '200px',

            data: { message },
          });

        dialogRef.afterClosed().subscribe(result => {
          if (result != undefined) {


          }

        });
        return

      }
      else if (data.Table.length > 0) {
        if (data.Table[0].IsKOTDirectPrint == 2) { //IsReceiptDirectPrint == 2 means Receipt Direct Print
          if (this.IsMaxPolicyOn) {
            if (this.MaxKitchenReceiptPrintOut > this.KitchenReceiptPrintOutCount) {
              let link = data.Table[0]['ServerLink'] + '/Default?O=' + data.Table[0]['OrderID'] + '&C=' + data.Table[0]['CompanyId'] + '&type=KP' + '&OutletID=' + this.SelectedBranchID + '&ShiftID=' + this.ShiftID + '&POSID=' + this.counterID;
              window.open(link, '_blank', 'width=300,height=200');
              this.KitchenReceiptPrintOutCount = this.KitchenReceiptPrintOutCount + 1;
            }
            else {
              this.printvalidation = true;
            }
          }
          else {
            let link = data.Table[0]['ServerLink'] + '/Default?O=' + data.Table[0]['OrderID'] + '&C=' + data.Table[0]['CompanyId'] + '&type=KP' + '&OutletID=' + this.SelectedBranchID + '&ShiftID=' + this.ShiftID + '&POSID=' + this.counterID;
            window.open(link, '_blank', 'width=300,height=200');
          }

        }
        else if (data.Table[0].IsKOTDirectPrint == 3) {          //IsReceiptDirectPrint == 3 means Popup
          // this.ReceiptURL = this.Utilities.getReceiptURL(this.Utilities.getEncrypt(this.Utilities.getCompanyName()), this.Utilities.getEncrypt(data.Table[0].OrderID), this.CountryCode);
          this.ReceiptURL = this.Utilities.getKitchenReceiptURL(this.Utilities.getEncrypt(this.Utilities.getCompanyName()), this.Utilities.getEncrypt(data.Table[0].OrderID), this.CountryCode);
        }
      }
      this.loading = false;


    });
    if (this.IsSessionClosed == false) {
      const otherResponse = await this.UpdatePrintColumn_InsertKOT();
    }
    //console.log(response, otherResponse);
  }
  async UpdatePrintColumn_InsertKOT() {

    //await this.dataservice.get('pos/UpdatePrintedColumn/'+this.OrderID_Print).subscribe(data => {
    //  
    //  if (data) {
    this.popup.ShowPopup('Insert');
    this.lstBasket = [];
    this.lstComboProducts = [];
    this.lstVoucherPaymentDetail = [];
    this.lstSelectModifier = [];
    this.ArrMasterData = [];
    this.ArrSelectedDetailData = [];
    this.GuestCutomer = []
    this.MobileNo
    this.Total = 0;
    this.ReturnAmount = 0;
    this.UserName = '';
    this.TableName = undefined;
    this.TableID = null;
    this.noOfCustomers = null;
    this.Subtoal = 0;
    this.DiscountAmont = 0;
    this.PromoAmountforVisibility = 0;
    this.PromoAmount = 0;
    this.PromoCode = '';
    this.VAT = 0;
    this.SubtoalForVAT = 0;
    this.CustomerId = 0;
    this.custId = '';
    this.custName = '';
    this.SelectedOrderInvoice = '';
    this.SearchBarcode = '';
    this.IsBarcodeFound = false;
    this.lstVoucherPaymentDetail = [];
    /*this.IsExtraCharge = false*/;
    this.ExtraChargeAmount = 0;
    this.IsPrintButtonDisplay = false;
    this.loading = false;
    //this.ExtraChargeDescription = '';
    //this.ExtraChargeDescriptionArabic = '';
    //this.win.close();
    //}

    //})
  }

  AddItemInKOT(): void {//
    this.getSelectedKOTDetailData();
    this.Print_Receipt_Method_UpdateKOT();
  }
  win;
  async Print_Receipt_Method_UpdateKOT() {
    var CurrentDate = this.Utilities.GetCurrentDate();
    // var OrderType = this.lstbasketdata[0].OrderType;
    debugger;
    let body = [this.ArrSelectedDetailData, this.KotOrderID, this.Utilities.getSubscriptionID(), this.Subtoal, this.Total, this.DiscountAmont, 'Pending', '[]', this.Utilities.getUserId(), CurrentDate, this.OrderType, this.SelectedBranchID, 'New', this.PromoCode, this.PromoAmountforVisibility, this.VAT, this.OrderTypeID, this.TableName, this.CustomerId == undefined ? 0 : this.CustomerId, this.CustomerAddressID == undefined ? 0 : this.CustomerAddressID, this.lstVoucherPaymentDetail, this.IsExtraCharge, this.ExtraChargeDescription, this.ExtraChargeAmount, this.ExtraChargeDescriptionArabic, 0, 'SAR', 0, this.counterID, this.ShiftID]
    debugger
    await this.dataservice.post('pos/SaveKOTorders/', body).pipe(delay(1000)).toPromise().then((data: any) => {
      if (data['Table4'].length > 0) {

        if (data.Table4[0].IsKOTDirectPrint == 2) {
          debugger
          if (this.IsMaxPolicyOn) {
            if (this.MaxKitchenReceiptPrintOut > this.KitchenReceiptPrintOutCount) {
              let link = data.Table4[0].ServerLink + '/Default?O=' + data.Table4[0].OrderID + '&C=' + data.Table4[0].CompanyId + '&type=KP' + '&OutletID=' + this.SelectedBranchID + '&ShiftID=' + this.ShiftID + '&POSID=' + this.counterID;
              this.win = window.open(link, '_blank', 'width=300,height=200');
              this.KitchenReceiptPrintOutCount = this.KitchenReceiptPrintOutCount + 1;
            }
            else {
              this.printvalidation = true;
            }
          }
          else {
            let link = data.Table4[0].ServerLink + '/Default?O=' + data.Table4[0].OrderID + '&C=' + data.Table4[0].CompanyId + '&type=KP' + '&OutletID=' + this.SelectedBranchID + '&ShiftID=' + this.ShiftID + '&POSID=' + this.counterID;
            this.win = window.open(link, '_blank', 'width=300,height=200');
          }

        }
        else if (data.Table4[0].IsKOTDirectPrint == 3) {
          // this.ReceiptURL = this.Utilities.getReceiptURL(this.Utilities.getEncrypt(this.Utilities.getCompanyName()), this.Utilities.getEncrypt(data.Table4[0].OrderID), this.CountryCode);
          this.ReceiptURL = this.Utilities.getKitchenReceiptURL(this.Utilities.getEncrypt(this.Utilities.getCompanyName()), this.Utilities.getEncrypt(data.Table[0].OrderID), this.CountryCode);

        }
        this.UpdatePrintColumn_UpdateKOT();
      }
      this.loading = false;
    });


  }

  UpdatePrintColumn_UpdateKOT() {
    //this.dataservice.get('pos/UpdatePrintedColumn/'+ this.OrderID_Print).subscribe(data => {
    //  if (data) {
    this.popup.ShowPopup('Insert');
    this.lstBasket = [];
    this.lstComboProducts = [];
    this.lstVoucherPaymentDetail = [];
    this.lstSelectModifier = [];
    this.ArrMasterData = [];
    this.ArrSelectedDetailData = [];
    this.GuestCutomer = []
    this.MobileNo
    this.Total = 0;
    this.ReturnAmount = 0;
    this.UserName = '';
    this.TableName = undefined;
    this.TableID = null;
    this.noOfCustomers = null;
    this.Subtoal = 0;
    this.DiscountAmont = 0;
    this.PromoAmount = 0;
    this.PromoCode = '';
    this.PromoAmountforVisibility = 0;
    this.VAT = 0;
    this.SubtoalForVAT = 0;
    this.CustomerId = 0;
    this.custId = '';
    this.custName = '';
    this.SelectedOrderInvoice = '';
    this.DineOrderID = 0;
    this.chkKOT = false;
    this.lstVoucherPaymentDetail = [];
    this.IsPrintButtonDisplay = false
    /*this.IsExtraCharge = false*/;
    this.ExtraChargeAmount = 0;
    this.loading = false;
    //this.ExtraChargeDescription = '';
    //this.ExtraChargeDescriptionArabic = '';
    //    this.win.close(); 
    //  }

    //})
  }
  IsPrintButtonDisplay: boolean = false;
  OrderID: any;
  pendingorderPopup(): void {
    debugger
    this.printvalidation = false;
    if (this.SelectedBranchID == null) {
      alert(this.translate.transform('Pleaseselectoutletsfirst'));
      return
    }

    else {
      debugger
      const dialogRef = this.dialog.open(PendingordersComponent, {
        width: '1150px',
        height: '600px',
        data: { culture: this.cultures, data: this.IsCancelOrderButtonActive, DayID: this.DayID, IsPendingOrderPrintReciept: this.IsPendingOrderPrintReciept, IsPendingOrderKitchenPrint: this.IsPendingOrderKitchenPrint, CounterID: this.counterID, CounterName: this.counterName, listSalesType: this.lstOrderType }
      });

      dialogRef.afterClosed().subscribe(result => {
        ;
        if (result != undefined && result != 'SUCCESS') {
          debugger
          this.OrderID = result;
          this.AddnDeleteItems = false;
          this.getorderdetails(result);
          this.chkKOT = true;
          this.DineOrderID = result;
          if (this.IsRightsEmployeeWise) {
            debugger
            if (this.arr.some(y => y.EntityCode == "voidbutton")) {
              this.IsVoidButtonActive = true;
            }
            else
              this.IsVoidButtonActive = false;
          }
          else if (this.lstPosButtonDeviceWise) {
            if (this.lstPosButtonDeviceWise.some(x => x.EntityCode == "voidbutton")) {
              this.IsVoidButtonActive = true;
            }
            else {
              this.IsVoidButtonActive = false;
            }
          }

          if (this.OrderID > 0)
            this.IsPrintButtonDisplay = true
          else
            this.IsPrintButtonDisplay = false
        }

        if (result == 'SUCCESS')
          this.clearclick();

      });

    }
  }

  ReceiptPrintOutCount: any;
  KitchenReceiptPrintOutCount: any;

  getorderdetails(orderid: any) {
    //
    this.KotOrderID = orderid;
    this.dataservice.get('pos/GetOrdersdetails/' + this.Utilities.getSubscriptionID() + '/' + orderid).subscribe(data => {
      debugger
      if (this.isArrayHasValue(data[0]['Ordersdetails'])) {

        this.lstBasket = data[0]['Ordersdetails'];
        // this.lstBasket.forEach(basket => basket.Amount = basket.Price);
        this.TableID = data[0]["Ordersdetails"][0]['TableID'];
        this.TableName = data[0]["Ordersdetails"][0]['TableNumber'];
        this.Subtoal = data[0]["Ordersdetails"][0]['TotalAmount'];
        this.SubtoalForVAT = this.Subtoal;
        this.Total = data[0]["Ordersdetails"][0]['NetAmount'];
        this.VAT = data[0]["Ordersdetails"][0]['TotalTaxAmount'];
        this.DiscountAmont = data[0]["Ordersdetails"][0]['TotalDiscount'];
        this.custName = data[0]["Ordersdetails"][0]['UserFullName'];
        this.CustomerId = data[0]["Ordersdetails"][0]['CustomerID'];
        this.OrderTypeID = data[0]["Ordersdetails"][0]['OrderTypeID'];
        this.OrderType = data[0]["Ordersdetails"][0]['OrderType'];
        this.SelectedOrderInvoice = data[0]["Ordersdetails"][0]['OrderInvoiceNo'];
        // this.PromoAmount = data[0]["Ordersdetails"][0]['PromoAmount'] == null ? 0 : data[0]["Ordersdetails"][0]['PromoAmount'];
        this.PromoAmountforVisibility = data[0]["Ordersdetails"][0]['PromoAmount'] == null ? 0 : data[0]["Ordersdetails"][0]['PromoAmount'];
        this.IsPercentPromoDiscount = data[0]["Ordersdetails"][0]['IsPercent'];
        this.PromoAmount = data[0]["Ordersdetails"][0]['DisVoucherValue'];
        this.PromoCode = data[0]["Ordersdetails"][0]['PromoCode'];
        this.IsExtraCharge = data[0]["Ordersdetails"][0]['IsExtraCharge'];

        this.IsExtraCharge = data[0]["Ordersdetails"][0]['IsExtraCharge'];
        this.ExtraChargeAmount = data[0]["Ordersdetails"][0]['ExtraChargeAmount'];
        this.ExtraChargeDescription = data[0]["Ordersdetails"][0]['ExtraChargeDescription'];
        this.ExtraChargeDescriptionArabic = data[0]["Ordersdetails"][0]['ExtraChargeDescriptionArabic'];
        debugger
        this.ReceiptPrintOutCount = data[0]["Ordersdetails"][0]['ReceiptPrintOutCount'];
        this.KitchenReceiptPrintOutCount = data[0]["Ordersdetails"][0]['KitchenReceiptPrintOutCount'];


        // this.Amount = this.lstBasket[index].Quantity * row.Price;
      }


      if (this.isArrayHasValue(data[0]['OrdersModifiers'])) {
        debugger
        this.lstSelectModifier = [];
        // var myArray = {"selectedmodifiers" :  data[0]['OrdersModifiers']}

        debugger
        let lst = data[0]['OrdersModifiers'];
        for (let i = 0; i < this.lstBasket.length; i++) {
          let row = lst.filter(t => t.ProductID == this.lstBasket[i].ProductID && t.rowid == this.lstBasket[i].rowid);
          if (row.length > 0)
            this.lstSelectModifier.push(row);
          else
            this.lstSelectModifier.push([{ AllowMultipleSeletion: null, DetailId: null, IsSelected: null, ListName: null, MasterId: null, MaxModifiers: null, MinModifiers: null, ModifierId: null, Modifires: null, Price: null, PriceStatic: null, ProductID: null, rowid: null }]);
        }
      }


      else if (this.isArrayHasValue(data[0]['DummyModifier'])) {
        this.lstSelectModifier = [];
        for (let i = 0; i < this.lstBasket.length; i++) {
          this.lstSelectModifier.push([{ AllowMultipleSeletion: null, DetailId: null, IsSelected: null, ListName: null, MasterId: null, MaxModifiers: null, MinModifiers: null, ModifierId: null, Modifires: null, Price: null, PriceStatic: null, ProductID: null, rowid: null }]);
        }
        //this.lstSelectModifier = Array.of(this.lstSelectModifier);
      }



      if (this.isArrayHasValue(data[0]['VoucherPaymentDetail'])) {
        this.ObjdiscountVoucher.DiscVoucherID = data[0]["VoucherPaymentDetail"][0].DiscVoucherID;
        this.ObjdiscountVoucher.VoucherCode = data[0]["VoucherPaymentDetail"][0].VoucherCode;
        this.ObjdiscountVoucher.VoucherDesc = data[0]["VoucherPaymentDetail"][0].VoucherDesc;
        this.ObjdiscountVoucher.IsPercent = data[0]["VoucherPaymentDetail"][0].IsPercent;
        this.ObjdiscountVoucher.Amount = data[0]["VoucherPaymentDetail"][0].Amount;
        this.ObjdiscountVoucher.MinOrderValue = data[0]["VoucherPaymentDetail"][0].MinOrderValue;
        this.ObjdiscountVoucher.UsagePerUser = data[0]["VoucherPaymentDetail"][0].UsagePerUser;
        this.ObjdiscountVoucher.UsagePerVoucher = data[0]["VoucherPaymentDetail"][0].UsagePerVoucher;
        this.ObjdiscountVoucher.VoucherExpiryDate = data[0]["VoucherPaymentDetail"][0].VoucherExpiryDate;
        this.ObjdiscountVoucher.OutletIds = data[0]["VoucherPaymentDetail"][0].OutletIds;
        this.ObjdiscountVoucher.Status = data[0]["VoucherPaymentDetail"][0].Status;
        this.ObjdiscountVoucher.CompanyId = data[0]["VoucherPaymentDetail"][0].CompanyId;
        this.addVoucherPaymentDetail();
      }
      debugger
      if (this.isArrayHasValue(data[0]['ComboItemdData'])) {

        this.lstComboProducts = [];
        // var myArray = {"selectedmodifiers" :  data[0]['OrdersModifiers']}

        debugger
        let lst = data[0]['ComboItemdData'];
        for (let i = 0; i < this.lstBasket.length; i++) {
          let row = lst.filter(t => t.rowid == this.lstBasket[i].rowid);
          if (row.length > 0)
            this.lstComboProducts.push(...row);

        }
      }

    });
  }


  BarcodeChange(barcode) {
    let row;
    debugger
    if (barcode) {
      row = this.lstProductAll.filter(PA => PA.Barcode == barcode);
      if (row.length > 0) {
        this.IsBarcodeFound = false;
        //this.lstProduct = [];
        //this.lstProduct = row;
      }
      else {
        //this.lstProduct = [];
        this.IsBarcodeFound = true;
      }
    }
    else {
      row = this.lstProductAll.filter(PA => PA.CatagoryID == this.productstr);
      if (row.length > 0) {
        //this.lstProduct = [];
        this.IsBarcodeFound = false;
        //this.lstProduct = row;
      }
    }

    if (row) {
      this.SearchBarcode = '';

      this.PushintoBakset(row[0]);
    }



  }
  orderTypeChangeFn(value) {
    //

  }

  compName: any;
  homeClick() {

    let values = this.Utilities.getCompanyName();
    this.compName = values;
    var local = window.location.origin;
    console.log(local);
    if (window.location.href.includes('BackOffice'))
      // this.router.navigateByUrl('/dashboardpos/maindashboard');
      window.location.href = local + '/BackOffice/' + this.compName + '/dashboardpos/maindashboard'
    else
      // this.router.navigateByUrl('/dashboardpos/maindashboard');
      window.location.href = local + '/' + this.compName + '/dashboardpos/maindashboard'
  }

  tab: any = 'home';

  menuClick(val) {

    debugger
    if (val == 'home') {

      this.tab = 'home';
    }
    else if (val == 'PendingOrders') {
      this.pendingorderPopup();
      this.tab = 'PendingOrders';
    }
    else if (val == 'PrintReceipt') {
      this.tab = 'PrintReceipt';
      this.prntrec();
    }
    else if (val == 'PaidOrders') {
      this.tab = 'PaidOrders';
      this.openPaidOrdersPopup();
    }
    else if (val == 'opencashdrawer') {
      this.tab = 'opencashdrawer';
      this.OpenCashDrawerPoppup();
    }
    else if (val == 'SaleReturn') {
      this.tab = 'SaleReturn';
      this.SaleRetun();
    }

    else if (val == 'SplitBills') {
      this.tab = 'SplitBills';
      if (this.SelectedOrderInvoice != undefined && this.SelectedOrderInvoice != '') {

        this.openSplitBillPopup(this.lstBasket, this.lstSelectModifier, this.Subtoal, this.PromoAmountforVisibility, this.VAT, this.Total, this.ObjOutletInfo, this.lstComboProducts);
      } else {
        alert(this.translate.transform('BeforesplitBillspleaseselectpendingorderfirst'));
        return;
      }

    }
    else if (val == 'logout') {
      //
      this.tab = 'logout';
      sessionStorage.clear();
      localStorage.removeItem('poslogin_storage');
      localStorage.removeItem('sessionObject' + this.companyName);
      localStorage.removeItem('LanguageCulture' + this.companyName);
      localStorage.removeItem('SelectedBranchRow' + this.companyName);

      localStorage.setItem('PosloginCheckCulture' + this.companyName, '0');
      //this.router.navigateByUrl(['login']);
      window.location.href = 'auth/poslogin/' + this.companyName + '/' + this.counterName;
      // window.location.href = '~/login';
    }
    else if (val == 'TransferTable') {
      this.tab = 'TransferTable';
      this.TransferTablePopup();
    }
    else if (val == 'QuickReport') {
      this.tab = 'QuickReport';
      this.QuickReportPopup();
    }
    else if (val == 'TransferOrder') {
      this.tab = 'TransferOrder';
      this.TransferOrderPopup();
    }
    else if (val == 'SplitChecks') {
      this.tab = 'SplitChecks';
      this.SplitChecksPopup();
    }
    else if (val == 'CustomerReceiptVoucher') {
      this.tab = 'CustomerReceiptVoucher';
      this.CustomerReceiptVoucherPopup();
    }
  }

  QuickReportPopup(): void {
    debugger

    // var data = [this.DineOrderID,this.cultures];
    const dialogRef = this.dialog.open(QuickreportPopupComponent, {
      width: '550px',
      height: '600px',
      data: { NoOfDecimal: this.NumOfDecimal, Culture: this.cultures, ShiftID: this.ShiftID, OutletID: this.SelectedBranchID }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.chkKOT = true;
      }
    });
  }

  OpenCashDrawerPoppup(): void {

    let link = this.ServerLink + '/Default?C=' + this.companyID + '&DayID=' + this.DayID + '&type=OPCD' + '&OutletID=' + this.SelectedBranchID + '&ShiftID=' + this.ShiftID + '&POSID=' + this.counterID;
    this.win = window.open(link, '_blank', 'width=300,height=200');
    //const dialogRef = this.dialog.open(OpencashdrawerComponent, {
    //  width: '700px',
    //  height: '400px',
    //  data: { NoOfDecimal: this.NumOfDecimal, Culture: this.cultures, ShiftID: this.ShiftID, OutletID: this.SelectedBranchID }
    //});

    //dialogRef.afterClosed().subscribe(result => {
    //  if (result) {
    //    this.chkKOT = true;
    //  }
    //});
  }

  IsTransferTable: boolean = false;
  IsMergeTable: boolean = false;
  OrderID_Parent: any;
  OrderID_Child: any;
  lstMaster_merged = [];
  lstOrderDetail_merged = [];
  TransferTablePopup(): void {
    debugger
    if (this.DineOrderID != undefined && this.DineOrderID != null && this.DineOrderID != '' && this.DineOrderID != 0) {
      var data = [this.DineOrderID, this.cultures, this.SelectedBranchID, this.NumOfDecimal, this.OrderTypeID, this.OrderType, this.DayID];
      const dialogRef = this.dialog.open(TransfertableComponent, {
        width: '1050px',
        height: '600px',
        data: { data }
      });

      dialogRef.afterClosed().subscribe(result => {
        debugger;
        this.IsTransferTable = result[0]
        this.IsMergeTable = result[1]
        this.OrderID_Parent = result[2]
        this.OrderID_Child = result[3]
        this.lstMaster_merged = result[4]
        this.lstOrderDetail_merged = result[5]
        this.AddnDeleteItems = false;
        if (this.IsTransferTable) {
          this.clearclick();
        }
        else if (this.IsMergeTable) {
          this.setValuesOrderData(this.lstMaster_merged, this.lstOrderDetail_merged);
          this.TableName = result[6]
          this.SelectedOrderInvoice = result[7]
          this.CalculateTotalTaxDiscountExtraCharge();
          this.mergeOrders();
          this.clearclick();
        }
      });
    }
    else {
      alert(this.translate.transform('Pleaseselectorderfirst'));
    }
  }

  TransferOrderPopup(): void {
    debugger
    const dialogRef = this.dialog.open(TransferorderpopupComponent, {
      width: '1050px',
      height: '600px',
      data: { NoOfDecimal: this.NumOfDecimal, Culture: this.cultures, ShiftID: this.ShiftID, OutletID: this.SelectedBranchID, PosID: this.counterID, DayID: this.DayID }

    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // this.chkKOT = true;
      }
    });


  }

  // IsCombineAllChecks:Boolean = false;
  SplitChecksPopup(): void {

    debugger
    if (this.OrderID) {
      this.DineOrderID = this.OrderID;
    }
    if (this.DineOrderID != undefined && this.DineOrderID != null && this.DineOrderID != '' && this.DineOrderID != 0) {
      debugger
      const dialogRef = this.dialog.open(SplitCheckspopupComponent, {
        width: '1050px',
        height: '600px',
        data: { OrderID: this.DineOrderID, TableID: this.TableID, ShiftID: this.ShiftID, DayID: this.DayID, OutletID: this.SelectedBranchID, POSID: this.counterID }

      });


      dialogRef.afterClosed().subscribe(result => {
        debugger
        if (result[0].TableNumber) {
          let table_Id = this.TableID;
          this.clearclick();
          this.TableID = table_Id;
          this.TableName = result[0].TableNumber;
          return
        }
        else if (result[0].OrderID) {
          debugger
          this.OrderID = result[0].OrderID;
          this.getorderdetails(this.OrderID);
          this.chkKOT = true;
          this.DineOrderID = this.OrderID;
          if (this.IsRightsEmployeeWise) {
            if (this.arr.some(y => y.EntityCode == "voidbutton")) {
              this.IsVoidButtonActive = true;
            }
            else
              this.IsVoidButtonActive = false;
          }
          else if (this.lstPosButtonDeviceWise) {
            if (this.lstPosButtonDeviceWise.some(x => x.EntityCode == "voidbutton")) {
              this.IsVoidButtonActive = true;
            }
            else {
              this.IsVoidButtonActive = false;
            }
          }

          if (this.OrderID > 0)
            this.IsPrintButtonDisplay = true
          else
            this.IsPrintButtonDisplay = false
        }
        else if (result[0] == true) {
          this.OrderID_Parent = result[1]
          this.OrderID_Child = result[2]
          this.lstMaster_merged = result[3]
          this.lstOrderDetail_merged = result[4]
          this.clearclick();
          this.setValuesOrderData(this.lstMaster_merged, this.lstOrderDetail_merged);
          this.CalculateTotalTaxDiscountExtraCharge();
          this.combineAllChecks();
        }
      });

    }
    else {
      alert(this.translate.transform('Pleaseselectorderfirst'));
    }


  }

  CustomerReceiptVoucherPopup(): void {
    debugger
    if (this.OrderID) {
      this.DineOrderID = this.OrderID;
    }
    // if (this.DineOrderID != undefined && this.DineOrderID != null && this.DineOrderID != '' && this.DineOrderID != 0) {
    if (1 == 1) {
      debugger
      const dialogRef = this.dialog.open(CustomerreceiptvoucherComponent, {
        width: '1050px',
        height: '600px',
        data: { OrderID: this.DineOrderID, DayId: this.DayID, ShiftId: this.ShiftID, OutletID: this.SelectedBranchID }
      });

      dialogRef.afterClosed().subscribe(result => {
        debugger
        if (result[0].TableNumber) {
          return
        }

      });

    }
    else {
      alert(this.translate.transform('Pleaseselectorderfirst'));
    }


  }

  mergeOrders() {
    // tipAmount,this.loyaltypoints,this.lstVoucherPaymentDetail
    let body = [this.OrderID_Parent, this.OrderID_Child, this.Subtoal, this.Total, this.DiscountAmont, this.PromoCode, this.PromoAmountforVisibility, this.VAT, this.ExtraChargeAmount]
    this.dataservice.post('pos/MergeTable/', body).subscribe(data => {
      debugger;
      if (data == "Update") {
        this.popup.ShowPopup('Update');
      }
    }, error => alert('error'));
  }

  combineAllChecks() {
    let body = [this.OrderID_Parent, this.OrderID_Child, this.Subtoal, this.Total, this.DiscountAmont, this.PromoCode, this.PromoAmountforVisibility, this.VAT, this.ExtraChargeAmount]
    this.dataservice.post('pos/CombineAllChecks/', body).subscribe(data => {
      debugger;
      if (data == "Update") {
        this.popup.ShowPopup('Update');
      }
    }, error => alert('error'));
  }

  clearclick() {
    this.printvalidation = false;
    this.lstBasket = [];
    this.lstComboProducts = [];
    this.lstVoucherPaymentDetail = [];
    this.lstSelectModifier = [];
    this.ArrMasterData = [];
    this.ArrSelectedDetailData = [];
    this.GuestCutomer = []
    this.MobileNo
    this.Total = 0;
    this.ReturnAmount = 0;
    this.UserName = '';
    this.TableName = undefined;
    this.TableID = null;
    this.noOfCustomers = null;
    this.Subtoal = 0;
    this.DiscountAmont = 0;
    this.PromoCode = '';
    this.PromoAmount = 0;
    this.PromoAmountforVisibility = 0;
    this.VAT = 0;
    this.SubtoalForVAT = 0;
    this.CustomerId = 0;
    this.custId = '';
    this.custName = '';
    this.SelectedOrderInvoice = '';
    this.IsBarcodeFound = false;
    this.SearchBarcode = '';
    this.chkKOT = false;
    this.IsVoidButtonActive = true;
    this.DineOrderID = 0;
    this.IsSessionClosed = false;
    /*this.IsExtraCharge = false*/;
    this.ExtraChargeAmount = 0;
    this.IsPrintButtonDisplay = false;
    this.OrderID = 0;
    this.AddnDeleteItems = false;
    this.IsPendingOrderKitchenPrint = false;
    this.IsPendingOrderPrintReciept = false;
    this.loading = false;
    //this.ExtraChargeDescription = '';
    //this.ExtraChargeDescriptionArabic = '';
  }

  bsktTotalAmount: any = 0;
  copysubTotal: any;
  PromoAmountforVisibility: any = 0;
  IsPercentPromoDiscount: boolean = false;
  VoucherPopup() {
    //
    // this.lstBasket.forEach(lb => this.bsktTotalAmount += lb.Amount);
    if (this.isArrayHasValue(this.lstBasket)) {
      this.copysubTotal = this.Subtoal;
      const dialogRef = this.dialog.open(VoucherPopupComponent, {
        width: '400px',
        height: '430px',
        data: { 'Subtoal': this.Subtoal, 'OutletID': this.SelectedBranchID, ObjOutletInfo: this.ObjOutletInfo, 'Total': this.Total }
      });

      dialogRef.afterClosed().subscribe(result => {

        if (result != undefined) {
          this.PromoCode = result.VoucherCode;
          this.PromoAmount = result.Amount;
          this.IsPercentPromoDiscount = result.IsPercent;

          this.ObjdiscountVoucher.DiscVoucherID = result.DiscVoucherID;
          this.ObjdiscountVoucher.VoucherCode = result.VoucherCode;
          this.ObjdiscountVoucher.VoucherDesc = result.VoucherDesc;
          this.ObjdiscountVoucher.IsPercent = result.IsPercent;
          this.ObjdiscountVoucher.Amount = result.Amount;
          this.ObjdiscountVoucher.MinOrderValue = result.MinOrderValue;
          this.ObjdiscountVoucher.UsagePerUser = result.UsagePerUser;
          this.ObjdiscountVoucher.UsagePerVoucher = result.UsagePerVoucher;
          this.ObjdiscountVoucher.VoucherExpiryDate = result.VoucherExpiryDate;
          this.ObjdiscountVoucher.OutletIds = result.OutletIds;
          this.ObjdiscountVoucher.Status = result.Status;
          this.ObjdiscountVoucher.CompanyId = result.CompanyId;

          //this.CalculatePromoDiscountEvent();
          this.CalculateTotalTaxDiscountExtraCharge();
          this.ProductVAT = 0;
          this.ProSubtoal = 0;
          this.ProSubtoalForVAT = 0;
          this.ProductDiscount = 0;
          this.CalculateProductWisePriceTAXDiscountAmount();
          this.addVoucherPaymentDetail();
          // this.addVoucherPaymentDetail();
        }
        else {
          // this.PromoAmount = 0;
          // this.PromoCode = '';
        }

      });
    }
    else {
      this.loading = false;
      const dialogRef = this.dialog.open(AlertPopupComponent, {
        width: '300px',
        height: '200px',
      });
    }
  }

  LoyaltyAmountConsume: any;
  IsLoyaltySelected: boolean = false;
  Consumed_LoyaltyAmount: any = 0.0;
  Consumed_LoyaltyPoints: any = 0;
  LoyalityPopup() {

    // if (this.isArrayHasValue(this.lstBasket)) {
    //   this.copysubTotal = this.Subtoal;
    const dialogRef = this.dialog.open(LoyalityPopupComponent, {
      width: '500px',
      height: '320px',
      data: { 'CurrencyCode': this.CurrencyCode, 'NumOfDecimal': this.NumOfDecimal, 'CustomerID': this.CustomerId, 'currentdate': this.currentdate, 'LoyaltyPointsInfo': this.LoyaltyPointsInfo, 'loyaltypoints': this.loyaltypoints, 'AvailingAmount': this.AvailingAmount, 'Total': this.Total }
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result != undefined) {
        this.IsLoyaltySelected = result[0].isLoyaltySelected;
        this.Consumed_LoyaltyPoints = result[0].LoyaltyRemainingPoints;
        this.Consumed_LoyaltyAmount = result[0].LoyaltyRemainingAmount;
        this.CalculateTotalTaxDiscountExtraCharge();
        this.setloyaltyAfterConsumed(0, 0);

      }
      else {
      }

    });
    //  }
    // else {
    //   const dialogRef = this.dialog.open(AlertPopupComponent, {
    //     width: '300px',
    //     height: '200px',
    //   });
    // }
  }
  removeCoupon() {
    debugger
    this.PromoAmount = 0;
    this.PromoCode = '';
    let modifireamount = 0;
    this.CalculateTotalTaxDiscountExtraCharge();

    this.PromoAmountforVisibility = 0;
    this.lstVoucherPaymentDetail = [];
  }
  
  removeLoyalty() {
    debugger
    this.IsLoyaltySelected = false;
    this.Consumed_LoyaltyPoints = 0;
    this.Consumed_LoyaltyAmount = 0;
    this.CalculateTotalTaxDiscountExtraCharge();
  }

  QtyChangePopup(row, index) {

    const dialogRef = this.dialog.open(QtyboardPopupComponent, {
      width: '400px',
      height: '430px',
      data: {IsQtyInDecimal:row.IsQtyInDecimal,IsQtyPopUp:true},
      panelClass: 'my-custom-dialog-class'
    });

    dialogRef.afterClosed().subscribe(result => {
      //
      if (result) {

        //this.CalculateTaxRemoveRow(row, index);
        var newqty = +result;
        this.lstBasket[index].Quantity = newqty;
        this.Amount = this.lstBasket[index].Quantity * row.Price;
        this.lstBasket[index].Amount = this.Amount;
        let lst = this.lstSelectModifier[index];
        this.ModifiresAmount = this.CalculateModifireAmount(lst);
        this.ModifiresAmount = this.ModifiresAmount;

        this.ProductVATPlusEventBulkQuntity(row)
        this.lstBasket[index].TaxAmount = this.ProductVAT;
        this.CalculateTotalTaxDiscountExtraCharge();
        this.CalculateProductWisePriceTAXDiscountAmount();
        //this.CalculateTaxPlusEventBulkQuntity(row);
        this.setloyalty(0, 0);
      }

    });
  }

  PriceChangePopup(row, index) {

    if (!this.IsPriceChangeActive) {
      return
    }
    else {

      const dialogRef = this.dialog.open(QtyboardPopupComponent, {
        width: '400px',
        height: '430px',
        data: {IsQtyInDecimal:false,IsQtyPopUp:false},
        panelClass: 'my-custom-dialog-class'
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {

          // this.CalculateTaxRemoveRow(row, index);
          var newPrice = +result;
          this.lstBasket[index].Price = newPrice;
          this.Amount = this.lstBasket[index].Quantity * row.Price;
          this.lstBasket[index].Amount = this.Amount;
          // let lst = this.lstSelectModifier[index];
          // this.ModifiresAmount = this.CalculateModifireAmount(lst);
          // this.ModifiresAmount = this.ModifiresAmount;
          this.CalculateTaxChangePrice(row, index);
        }
      });
    }
  }

  specialInstructionPopup(row, index) {
    //
    const dialogRef = this.dialog.open(SpecialinstructionPopupComponent, {
      width: '400px',
      height: '430px',
      data: { lstbasket: this.lstBasket, Index: index }
    });
    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        var SpecialRequest = result;
        this.lstBasket[index].SpecialRequest = SpecialRequest;
      }
    });
  }


  addVoucherPaymentDetail(): void {
    this.lstVoucherPaymentDetail = [];
    this.lstVoucherPaymentDetail.push({
      'VoucherCode': this.ObjdiscountVoucher.VoucherCode, 'VoucherId': this.ObjdiscountVoucher.DiscVoucherID, 'IsPercent': this.ObjdiscountVoucher.IsPercent,
      'VoucherAmount': this.PromoAmountforVisibility, 'CompanyId': this.ObjCompany.CompanyID
    });



  }

  CalculateProductWiseDiscount_OLD() {
    debugger;
    if (this.PromoAmount > 0) {
      for (var i = 0; i < this.lstBasket.length; i++) {

        let ProductAmount: number = this.lstBasket[i].Amount;
        if (this.IsPercentPromoDiscount) {
          this.lstBasket[i].ProductDiscount = ((ProductAmount) * this.PromoAmount) / 100;

          if (this.ObjOutletInfo.IsTaxInclusive == false) {
            if (this.ObjOutletInfo.IsTaxOnDiscountAmount == true)
              this.lstBasket[i].TaxAmount = ((this.lstBasket[i].Amount - this.lstBasket[i].ProductDiscount) * this.ObjOutletInfo.TaxRate) / 100;
            else
              this.lstBasket[i].TaxAmount = (((this.lstBasket[i].Amount) * this.ObjOutletInfo.TaxRate) / 100);

          }
          else {
            let A: number = 0;
            if (this.ObjOutletInfo.IsTaxOnDiscountAmount == true) {
              A = (((this.lstBasket[i].Amount) - this.lstBasket[i].ProductDiscount) * 100) / (100 + this.ObjOutletInfo.TaxRate);
              this.lstBasket[i].TaxAmount = this._decimalPipe.transform(((this.lstBasket[i].Amount) - this.lstBasket[i].ProductDiscount) - A, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal).replace(',', '');


            }
            else {
              A = ((this.lstBasket[i].Amount) * 100) / (100 + this.ObjOutletInfo.TaxRate);
              this.lstBasket[i].TaxAmount = this._decimalPipe.transform((this.lstBasket[i].Amount) - A, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal).replace(',', '');
            }
          }

        }
        else {
          this.lstBasket[i].ProductDiscount = this.PromoAmount;
        }

      }

    }

  }
  DiffPriceWithOutVAT: any = 0;
  DiffTaxAmount: any = 0;
  DiffProductDiscount: any = 0;
  CalculateProductWisePriceTAXDiscountAmount() {
    ;
    let A: number = 0;
    let modifireamount = 0;
    // let ProductDiscount = 0;
    let Productssum = 0;

    this.DiffPriceWithOutVAT = 0;
    this.DiffTaxAmount = 0


    if (this.lstBasket.length > 0) {
      debugger
      for (var i = 0; i < this.lstBasket.length; i++) {

        this.DiffPriceWithOutVAT = 0;
        this.DiffTaxAmount = 0
        this.DiffProductDiscount = 0;
        this.ProductVAT = 0;
        this.ProductDiscount = 0;
        this.PriceWithOutVAT = 0;

        A = 0;
        modifireamount = 0;
        Productssum = 0;

        let Quant = this.lstBasket[i].Quantity;
        if (this.lstSelectModifier[i].filter(t => t.rowid == this.lstBasket[i].rowid && (this.lstBasket[i].DelFlag == null || this.lstBasket[i].DelFlag == 0) && t.Price > 0).length > 0)
          modifireamount += this.lstSelectModifier[i].filter(t => t.rowid == this.lstBasket[i].rowid && this.lstBasket[i].DelFlag == null || this.lstBasket[i].DelFlag == 0).map(t => t.Price).reduce((a, b) => a + b)
        else
          modifireamount += 0;

        Productssum = this.lstBasket[i].Price;
        if (this.IsPercentPromoDiscount) {
          this.ProductDiscount = ((Productssum + modifireamount) * this.PromoAmount) / 100;
        }
        else {
          this.ProductDiscount = +this.PromoAmount;
        }
        if (this.ObjOutletInfo.IsTaxInclusive == true) {
          if (this.IsPercentPromoDiscount) {
            this.ProductDiscount = ((Productssum + modifireamount) * this.PromoAmount) / 100;
          }
          else {
            this.ProductDiscount = +this.PromoAmount;
          }

          if (this.ObjOutletInfo.IsTaxOnDiscountAmount == true) {
            A = (((Productssum + modifireamount) - this.ProductDiscount) * 100) / (100 + this.ObjOutletInfo.TaxRate);
            this.ProductVAT = ((Productssum + modifireamount) - this.ProductDiscount) - A;
            this.PriceWithOutVAT = A + this.ProductDiscount;

          }
          else {
            A = ((Productssum + modifireamount) * 100) / (100 + this.ObjOutletInfo.TaxRate);
            this.ProductVAT = (Productssum + modifireamount) - A;
            this.PriceWithOutVAT = A;

          }

          // if (this.IsExtraCharge == true) {
          //   this.CalculateExtraChargeInclusive();
          // }
        }
        else {

          this.PriceWithOutVAT = Productssum + modifireamount;

          // if (this.IsExtraCharge == true) {
          //   this.ExtraChargeAmount = (this.PriceWithOutVAT) * this.ExtraChargePercentage / 100;
          // }


          if (this.IsPercentPromoDiscount) {
            // this.ProductDiscount = ((Productssum + modifireamount + this.ExtraChargeAmount) * this.PromoAmount) / 100;
            this.ProductDiscount = ((Productssum + modifireamount) * this.PromoAmount) / 100;
          }
          else {
            this.ProductDiscount = +this.PromoAmount;
          }
          if (this.ObjOutletInfo.IsTaxOnDiscountAmount == true)
            this.ProductVAT = (((this.PriceWithOutVAT) - this.ProductDiscount) * this.ObjOutletInfo.TaxRate) / 100;
          else
            this.ProductVAT = ((this.PriceWithOutVAT) * this.ObjOutletInfo.TaxRate) / 100;
          // if (this.ObjOutletInfo.IsTaxOnDiscountAmount == true)
          //   this.ProductVAT = (((this.PriceWithOutVAT + this.ExtraChargeAmount) - this.ProductDiscount) * this.ObjOutletInfo.TaxRate) / 100;
          // else
          //   this.ProductVAT = ((this.PriceWithOutVAT + this.ExtraChargeAmount) * this.ObjOutletInfo.TaxRate) / 100;

        }



        this.lstBasket[i].PriceWithOutVAT = this._decimalPipe.transform(this.PriceWithOutVAT, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal).replace(',', '');
        this.DiffPriceWithOutVAT = this.lstBasket[i].PriceWithOutVAT - this.PriceWithOutVAT;
        this.lstBasket[i].ProductDiscount = this._decimalPipe.transform(this.ProductDiscount, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal).replace(',', '');
        this.DiffProductDiscount = this.lstBasket[i].ProductDiscount - this.ProductDiscount;
        this.lstBasket[i].TaxAmount = this._decimalPipe.transform(this.ProductVAT, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal).replace(',', '');
        this.DiffTaxAmount = this.lstBasket[i].TaxAmount - this.ProductVAT;
        debugger
        this.lstBasket[i].DiffPriceWithOutVAT = this._decimalPipe.transform(this.DiffPriceWithOutVAT, '1.' + 5 + '-' + 5).replace(',', '');
        this.lstBasket[i].DiffProductDiscount = this._decimalPipe.transform(this.DiffProductDiscount, '1.' + 5 + '-' + 5).replace(',', '');
        this.lstBasket[i].DiffTaxAmount = this._decimalPipe.transform(this.DiffTaxAmount, '1.' + 5 + '-' + 5).replace(',', '');



      }
    }






  }


  //CalculateTaxPlusEvent(row) {
  //  ;
  //  if (this.ObjOutletInfo.IsTaxInclusive == false) {
  //    this.Subtoal += row.Price + this.ModifiresAmount;
  //    this.SubtoalForVAT += parseFloat(row.DiscountPrice && this.ObjOutletInfo.IsTaxOnDiscountAmount == true ? (row.DiscountPrice > 0 ? row.DiscountPrice + this.ModifiresAmount : row.Price + this.ModifiresAmount) : row.Price + this.ModifiresAmount);
  //    this.VAT = (this.SubtoalForVAT * this.ObjOutletInfo.TaxRate) / 100;
  //    if (row.DiscountPrice)
  //      this.DiscountAmont += (row.Price - row.DiscountPrice);
  //    this.Total = (this.Subtoal + this.VAT - this.DiscountAmont);
  //  }
  //  else {

  //    let A = 0;
  //    let price = row.DiscountPrice > 0 ? row.DiscountPrice + this.ModifiresAmount : row.Price + this.ModifiresAmount;
  //    if (this.ObjOutletInfo.IsTaxOnDiscountAmount == false) {
  //      A = ((row.Price + this.ModifiresAmount) * 100) / (100 + this.ObjOutletInfo.TaxRate);
  //      this.VAT += (row.Price + this.ModifiresAmount) - A;
  //      this.VAT = +this._decimalPipe.transform(this.VAT, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal).replace(',', '');
  //    }
  //    if (this.ObjOutletInfo.IsTaxOnDiscountAmount == true) {
  //      A = ((price) * 100) / (100 + this.ObjOutletInfo.TaxRate);
  //      this.VAT += (price) - A;
  //      this.VAT = +this._decimalPipe.transform(this.VAT, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal).replace(',', '');
  //    }
  //    if (row.DiscountPrice)
  //      this.DiscountAmont += (row.Price - row.DiscountPrice);
  //    if (row.DiscountPrice && this.ObjOutletInfo.IsTaxOnDiscountAmount == true) {
  //      this.Subtoal += A + (row.Price - row.DiscountPrice);
  //      this.Subtoal = +this._decimalPipe.transform(this.Subtoal, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal).replace(',', '');
  //    }
  //    else
  //      //
  //      this.Subtoal += A;
  //    this.Subtoal = +this._decimalPipe.transform(this.Subtoal, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal).replace(',', '');

  //    this.Total = (this.Subtoal + this.ExtraChargeAmount + this.VAT - this.DiscountAmont);
  //    this.Total = +this._decimalPipe.transform(this.Total, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal).replace(',', '');


  //  }
  //  this.CalculatePromoDiscountEvent();
  //  this.ModifiresAmount = 0;
  //}
  //CalculatePromoDiscountEvent() {

  //  

  //  let A: number = 0;
  //  let modifireamount = 0;
  //  if (this.PromoAmount > 0) {
  //    
  //    if (this.lstSelectModifier.length > 0) {
  //      for (var i = 0; i < this.lstBasket.length; i++) {
  //        for (var j = 0; j < this.lstSelectModifier[i].length; j++) {
  //          if (this.lstSelectModifier[i][j].Price != null && this.lstSelectModifier[i][j].Price != undefined && this.lstSelectModifier[i][j].Price != 0 && this.lstSelectModifier[i][j].IsSelected == true) {
  //            let Quant
  //            if(this.lstBasket.hasOwnProperty('DelFlag') == true)
  //            Quant = this.lstBasket.find(t => t.rowid == this.lstSelectModifier[i][j].rowid && t.DelFlag == 0).Quantity;
  //            else{
  //              Quant = this.lstBasket.find(t => t.rowid == this.lstSelectModifier[i][j].rowid).Quantity;
  //            }
  //            modifireamount += this.lstSelectModifier[i][j].Price * Quant;
  //          }
  //        }
  //      }
  //    }
  //    if(this.lstBasket.length > 0) 
  //    {
  //      //let sum: number = this.lstBasket.map(a => a.Price * a.Quantity).reduce(function (a, b) { return a + b; });
  //      const sum = this.lstBasket.filter(x => x.DelFlag   == null || x.DelFlag   == 0).map(x => x.Price * x.Quantity).reduce((a, b) => a + b);
  //      if (this.IsPercentPromoDiscount) {
  //        this.PromoAmountforVisibility = ((sum + modifireamount) * this.PromoAmount) / 100;
  //      }
  //      else {
  //        this.PromoAmountforVisibility = +this.PromoAmount;
  //      }
  //      if (this.ObjOutletInfo.IsTaxInclusive == false) {
  //        if (this.ObjOutletInfo.IsTaxOnDiscountAmount == true)
  //          this.VAT = ((sum + modifireamount - this.PromoAmountforVisibility) * this.ObjOutletInfo.TaxRate) / 100;
  //        else
  //          this.VAT = ((sum + modifireamount) * this.ObjOutletInfo.TaxRate) / 100;
  //        this.Total = (this.Subtoal + this.VAT - this.PromoAmountforVisibility);
  //      }
  //      else {
  //        if (this.ObjOutletInfo.IsTaxOnDiscountAmount == true) {
  //          A = (((sum + modifireamount) - this.PromoAmountforVisibility) * 100) / (100 + this.ObjOutletInfo.TaxRate);
  //          this.VAT = ((sum + modifireamount) - this.PromoAmountforVisibility) - A;
  //          this.Subtoal = A + this.PromoAmountforVisibility;
  //          this.Total = (this.Subtoal + this.VAT - this.PromoAmountforVisibility);
  //        }
  //        else {
  //          A = ((sum + modifireamount) * 100) / (100 + this.ObjOutletInfo.TaxRate);
  //          this.VAT = (sum + modifireamount) - A;
  //          this.Subtoal = A;
  //          this.Total = (this.Subtoal + this.VAT - this.PromoAmountforVisibility);
  //        }
  //      }
  //      if (this.IsExtraCharge == true) {
  //        //this.CalculateExtraCharge();
  //      }
  //      this.PromoAmountforVisibility = +this._decimalPipe.transform(this.PromoAmountforVisibility, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal).replace(',', '');
  //      this.Total = +this._decimalPipe.transform(this.Total, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal).replace(',', '');
  //      this.Subtoal = +this._decimalPipe.transform(this.Subtoal, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal).replace(',', '');
  //      this.VAT = +this._decimalPipe.transform(this.VAT, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal).replace(',', '');
  //      this.CalculateProductWiseDiscount();
  //      this.addVoucherPaymentDetail();
  //    }
  //  }
  //}

  //CalculatePromoDiscountEventOld() {
  //  //
  //  // this.copysubTotal = this.Total;
  //  if (this.PromoAmount > 0) {
  //    if (this.IsPercentPromoDiscount) {
  //      this.PromoAmountforVisibility = (this.Total * this.PromoAmount) / 100;
  //      this.PromoAmountforVisibility = +this._decimalPipe.transform(this.PromoAmountforVisibility, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal).replace(',', '');
  //      this.Total -= this.PromoAmountforVisibility;
  //      this.Total = +this._decimalPipe.transform(this.Total, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal).replace(',', '');

  //    }
  //    else {
  //      this.Total -= this.PromoAmountforVisibility;
  //      this.Total = +this._decimalPipe.transform(this.Total, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal).replace(',', '');

  //    }
  //  }
  //}
  //CalculateTaxPlusEventBulkQuntity(row) {
  //  ;
  //  var _Price = parseFloat(row.Price) * parseFloat(row.Quantity);
  //  var _DiscountPrice = parseFloat(row.DiscountPrice) * parseFloat(row.Quantity);

  //  if (this.ObjOutletInfo.IsTaxInclusive == false) {
  //    this.Subtoal += _Price + this.ModifiresAmount;
  //    this.SubtoalForVAT += _DiscountPrice && this.ObjOutletInfo.IsTaxOnDiscountAmount == true ? (_DiscountPrice > 0 ? _DiscountPrice + this.ModifiresAmount : _Price + this.ModifiresAmount) : _Price + this.ModifiresAmount;
  //    this.SubtoalForVAT = parseFloat(this.SubtoalForVAT.toFixed(3));
  //    this.VAT = (this.SubtoalForVAT * this.ObjOutletInfo.TaxRate) / 100;
  //    if (_DiscountPrice)
  //      this.DiscountAmont += (_Price - _DiscountPrice);
  //    this.Total = (this.Subtoal + this.VAT - this.DiscountAmont);
  //  }
  //  else {

  //    let A = 0;
  //    let price = _DiscountPrice > 0 ? _DiscountPrice + this.ModifiresAmount : _Price + this.ModifiresAmount;
  //    if (this.ObjOutletInfo.IsTaxOnDiscountAmount == false) {
  //      A = ((_Price + this.ModifiresAmount) * 100) / (100 + this.ObjOutletInfo.TaxRate);
  //      this.VAT += (_Price + this.ModifiresAmount) - A;
  //      this.VAT = +this._decimalPipe.transform(this.VAT, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal).replace(',', '');
  //    }
  //    if (this.ObjOutletInfo.IsTaxOnDiscountAmount == true) {
  //      A = ((price) * 100) / (100 + this.ObjOutletInfo.TaxRate);
  //      this.VAT += (price) - A;
  //      this.VAT = +this._decimalPipe.transform(this.VAT, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal).replace(',', '');
  //    }
  //    if (_DiscountPrice)
  //      this.DiscountAmont += (_Price - _DiscountPrice);
  //    if (_DiscountPrice && this.ObjOutletInfo.IsTaxOnDiscountAmount == true) {
  //      this.Subtoal += A + (_Price - _DiscountPrice);
  //      this.Subtoal = +this._decimalPipe.transform(this.Subtoal, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal).replace(',', '');
  //    }
  //    else
  //      this.Subtoal += A;
  //    this.Subtoal = +this._decimalPipe.transform(this.Subtoal, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal).replace(',', '');

  //    this.Total = (this.Subtoal + this.VAT - this.DiscountAmont);
  //    this.Total = +this._decimalPipe.transform(this.Total, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal).replace(',', '');


  //  }
  //  this.CalculatePromoDiscountEvent();
  //  this.ModifiresAmount = 0;
  //}
  //CalculateTaxMinusEvent(row, index) {
  //  ;

  //  if (this.ObjOutletInfo.IsTaxInclusive == false) {
  //    this.Subtoal = this.Subtoal - row.Price - this.ModifiresAmount;

  //    if (row.DiscountPrice && this.ObjOutletInfo.IsTaxOnDiscountAmount == true) {
  //      this.SubtoalForVAT = this.SubtoalForVAT - row.DiscountPrice - this.ModifiresAmount;
  //    } else {
  //      this.SubtoalForVAT = this.SubtoalForVAT - row.Price - this.ModifiresAmount
  //    }
  //    this.VAT = (this.SubtoalForVAT * this.ObjOutletInfo.TaxRate) / 100;
  //    if (row.DiscountPrice)
  //      this.DiscountAmont = this.DiscountAmont - (row.Price - row.DiscountPrice);
  //    this.Total = (this.Subtoal + this.VAT - this.DiscountAmont);
  //  }
  //  else {

  //    let A = 0;
  //    let price = row.DiscountPrice > 0 ? row.DiscountPrice + this.ModifiresAmount : row.Price + this.ModifiresAmount;
  //    if (this.ObjOutletInfo.IsTaxOnDiscountAmount == false) {
  //      A = ((row.Price + this.ModifiresAmount) * 100) / (100 + this.ObjOutletInfo.TaxRate);
  //      this.VAT = this.VAT - ((row.Price + this.ModifiresAmount) - A);
  //    }
  //    if (this.ObjOutletInfo.IsTaxOnDiscountAmount == true) {
  //      A = ((price) * 100) / (100 + this.ObjOutletInfo.TaxRate);
  //      this.VAT = this.VAT - ((price) - A);
  //    }
  //    if (row.DiscountPrice)
  //      this.DiscountAmont = this.DiscountAmont - (row.Price - row.DiscountPrice);
  //    if (row.DiscountPrice && this.ObjOutletInfo.IsTaxOnDiscountAmount == true)
  //      this.Subtoal = this.Subtoal - (A + (row.Price - row.DiscountPrice));
  //    else
  //      this.Subtoal = this.Subtoal - A;
  //    this.Total = (this.Subtoal + this.VAT - this.DiscountAmont);


  //  }
  //  this.ModifiresAmount = 0;
  //  this.CalculatePromoDiscountEvent();
  //  if (!this.isArrayHasValue(this.lstBasket)) {
  //    this.Subtoal = 0;
  //    this.SubtoalForVAT = 0;
  //    this.Total = 0;
  //    this.VAT = 0;
  //    this.PromoAmountforVisibility = 0;
  //  }
  //}
  //CalculateTaxRemoveRow(row, index) {
  //  ;
  //  let qty = row.Quantity;
  //  if (this.ObjOutletInfo.IsTaxInclusive == false) {
  //    this.Subtoal -= (row.Price * qty) - this.ModifiresAmount;

  //    if (row.DiscountPrice && this.ObjOutletInfo.IsTaxOnDiscountAmount == true) {
  //      this.SubtoalForVAT -= (row.DiscountPrice * qty) - this.ModifiresAmount;
  //    } else {
  //      this.SubtoalForVAT -= (row.Price * qty) - this.ModifiresAmount
  //    }
  //    this.VAT = (this.SubtoalForVAT * this.ObjOutletInfo.TaxRate) / 100;
  //    if (row.DiscountPrice)
  //      this.DiscountAmont -= ((row.Price - row.DiscountPrice) * qty);
  //    this.Total = (this.Subtoal + this.VAT - this.DiscountAmont);
  //    this.copysubTotal = this.Total;
  //  }
  //  else {

  //    let A = 0;
  //    let price = row.DiscountPrice > 0 ? row.DiscountPrice : row.Price;
  //    if (this.ObjOutletInfo.IsTaxOnDiscountAmount == false) {
  //      A = (((row.Price * qty) + this.ModifiresAmount) * 100) / (100 + this.ObjOutletInfo.TaxRate);
  //      this.VAT -= (((row.Price * qty) + this.ModifiresAmount) - A);
  //    }
  //    if (this.ObjOutletInfo.IsTaxOnDiscountAmount == true) {
  //      A = (((price * qty) + this.ModifiresAmount) * 100) / (100 + this.ObjOutletInfo.TaxRate);
  //      this.VAT -= (((price * qty) + this.ModifiresAmount) - A);
  //    }
  //    if (row.DiscountPrice)
  //      this.DiscountAmont -= ((row.Price * qty) - (row.DiscountPrice * qty));

  //    if (row.DiscountPrice && this.ObjOutletInfo.IsTaxOnDiscountAmount == true)
  //      this.Subtoal -= (A + (row.Price * qty - row.DiscountPrice * qty));
  //    else
  //      this.Subtoal = this.Subtoal - A;
  //    this.Total = (this.Subtoal + this.VAT - this.DiscountAmont);
  //    this.copysubTotal = this.Total;
  //    if (this.VAT < 0) {
  //      this.Subtoal = 0;
  //      this.SubtoalForVAT = 0;
  //      this.Total = 0;
  //      this.VAT = 0;
  //      this.PromoAmountforVisibility = 0;
  //      this.PromoAmount = 0;
  //      this.PromoCode = '';
  //    }
  //  }
  //  this.Subtoal = +this._decimalPipe.transform(this.Subtoal, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal).replace(',', '');
  //  this.Total = +this._decimalPipe.transform(this.Total, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal).replace(',', '');
  //  this.VAT = +this._decimalPipe.transform(this.VAT, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal).replace(',', '');

  //  this.CalculatePromoDiscountEvent();
  //  this.ModifiresAmount = 0;
  //  if (!this.isArrayHasValue(this.lstBasket)) {
  //    this.Subtoal = 0;
  //    this.SubtoalForVAT = 0;
  //    this.Total = 0;
  //    this.VAT = 0;
  //    this.PromoAmountforVisibility = 0;
  //    this.PromoAmount = 0;
  //    this.PromoCode = '';
  //  }

  //}

  setloyalty(Points, Quantity): void {


    if (!this.LoyaltyPointsInfo)
      return;

    this.loyaltypoints = 0;
    this.AvailingAmount = 0;

    this.lstBasket.forEach(x => {

      if (x.Price != undefined && x.Price != null && x.Price > 0 && x.DelFlag != 1) {

        if (this.LoyaltyPointsInfo[0].IsLoyaltyOnAllItems == true) {
          let price: any = 0.0;
          price = price - this.PromoAmountforVisibility - this.Consumed_LoyaltyAmount;
          debugger
          price = parseFloat(x.DiscountPrice > 0 ? x.DiscountPrice : x.Price) * x.Quantity;
          let availingamount = this.getloyaltyAvailingAmount(parseFloat(price), this.LoyaltyPointsInfo[0].Perordervalue);
          this.AvailingAmount += availingamount;
          this.loyaltypoints += this.getloyaltypoints(parseFloat(availingamount), this.LoyaltyPointsInfo[0].ValuePoint);
        }
        else {

          if (x.Points != undefined && x.Points != null)
            this.loyaltypoints += parseFloat(x.Points) * parseInt(x.Quantity);

          if (x.AvailingAmount != undefined && x.AvailingAmount != null)
            this.AvailingAmount += parseFloat(x.AvailingAmount) * parseInt(x.Quantity);

        }
      }
    })

    let modifiepoint: any = 0.0;
    let modifieavailingamount: any = 0.0;

    if (this.lstSelectModifier.length > 0) {

      for (var i = 0; i < this.lstBasket.length; i++) {
        this.lstSelectModifier.forEach(x => {
          if (this.LoyaltyPointsInfo[0].IsLoyaltyOnAllItems == true) {


            if (x[0].Price != null && x[0].Price != undefined && x[0].Price != 0 && x[0].IsSelected == true) {
              let Quant
              if (this.lstBasket.hasOwnProperty('DelFlag') == true)
                Quant = this.lstBasket.find(t => t.rowid == x[0].rowid).Quantity;

              Quant = this.lstBasket[i].Quantity;
              let _modifieavailingamount = this.getloyaltyAvailingAmount(x[0].Price * Quant, this.LoyaltyPointsInfo[0].Perordervalue);

              modifieavailingamount += this.getloyaltyAvailingAmount(x[0].Price * Quant, this.LoyaltyPointsInfo[0].Perordervalue);
              modifiepoint += this.getloyaltypoints(_modifieavailingamount, this.LoyaltyPointsInfo[0].ValuePoint);
            }
          }
          else {
            debugger
            let Quant;
            if (this.lstBasket.hasOwnProperty('DelFlag') == true)
              Quant = this.lstBasket.find(t => t.rowid == x[0].rowid).Quantity;

            if (x[0].LoyaltyPoints != undefined && x[0].LoyaltyPoints != null) {
              Quant = this.lstBasket[i].Quantity;
              this.loyaltypoints += parseFloat(x[0].LoyaltyPoints) * parseInt(Quant);

              this.AvailingAmount = (this.loyaltypoints * this.LoyaltyPointsInfo[0].ValuePoint);
            }
          }
        })
      }
    }
    this.loyaltypoints += parseFloat(modifiepoint);
    this.AvailingAmount += parseFloat(modifieavailingamount);

    // this.lstorage.seten('EarnLoyaltyPoints', this.loyaltypoints);
    // this.lstorage.seten('EarnLoyaltyAmount', this.AvailingAmount);
  }

  setloyaltyAfterConsumed(Points, Quantity): void {
    debugger
    if (!this.IsLoyaltySelected)
      return;

    this.loyaltypoints = 0;
    this.AvailingAmount = 0;
    let modifireamount = 0;
    let Productsum: number = 0;

    let modifirepoints = 0;
    let productPoints: number = 0;
    let modifireavailingmount = 0;
    let productavailingmount: number = 0;

    if (this.LoyaltyPointsInfo[0].IsLoyaltyOnAllItems == true) {
      let price: any = 0.0;
      debugger
      if (this.lstBasket.length > 0) {
        Productsum = this.lstBasket.map(a => a.DiscountPrice > 0 ? a.DiscountPrice * a.Quantity : a.Price * a.Quantity).reduce(function (a, b) {
          return a + b;
        });


        if (this.lstModifier.length > 0) {
          for (var i = 0; i < this.lstBasket.length; i++) {
            for (var j = 0; j < this.lstModifier.length; j++) {
              debugger
              if (this.lstModifier[j].Price != null && this.lstModifier[j].Price != undefined && this.lstModifier[j].IsSelected == true) {
                debugger
                let Quant = this.lstBasket.find(t => t.rowid == this.lstModifier[j].rowid).Quantity;
                modifireamount += this.lstModifier[j].Price * Quant;
              }
            }
          }
        }
      }
      price = Productsum + modifireamount - this.Consumed_LoyaltyAmount;
      let availingamount = this.getloyaltyAvailingAmount(parseFloat(price), this.LoyaltyPointsInfo[0].Perordervalue);
      this.AvailingAmount = availingamount;
      this.loyaltypoints = this.getloyaltypoints(parseFloat(availingamount), this.LoyaltyPointsInfo[0].ValuePoint);
    }

    else {
      debugger
      if (this.lstBasket.length > 0) {

        for (var i = 0; i < this.lstBasket.length; i++) {
          if (this.lstBasket[i].Points != null && this.lstBasket[i].Points != undefined) {
            let Quant = this.lstBasket[i].Quantity;
            productPoints += this.lstBasket[i].Points * Quant;
            productavailingmount += this.lstBasket[i].AvailingAmount * Quant;
          }
        }

        if (this.lstModifier.length > 0) {
          for (var i = 0; i < this.lstBasket.length; i++) {
            for (var j = 0; j < this.lstModifier.length; j++) {
              debugger
              if (this.lstModifier[i].Points != null && this.lstModifier[i].Points != undefined && this.lstModifier[i].IsSelected == true) {
                debugger
                let Quant = this.lstBasket.find(t => t.rowid == this.lstModifier[i].rowid).Quantity;
                modifirepoints = this.lstModifier[i].Points * Quant;
                modifireavailingmount = this.lstModifier[i].AvailingAmount * Quant;
              }
            }
          }
        }
      }
      this.loyaltypoints = productPoints + modifirepoints;
      this.AvailingAmount = productavailingmount + modifireavailingmount;
    }
    // this.localStorage.seten('EarnLoyaltyPoints', this.loyaltypoints);
    // this.localStorage.seten('EarnLoyaltyAmount', this.AvailingAmount);
  }

  getloyaltyAvailingAmount(price, Perordervalue): any {
    let AvailingAmount: any = 0;
    AvailingAmount = (parseFloat(price) / 100) * parseFloat(Perordervalue);
    return parseFloat(this._decimalPipe.transform(AvailingAmount, '1.3-3'));
  }
  getloyaltypoints(loyaltyamount, ValuePoints): any {
    let points: any = 0;
    points = parseFloat(loyaltyamount) * parseFloat(ValuePoints);
    return points;
  }
  printvalidation: boolean = false;
  async KOTOrderPrint() {
    ;
    if (this.IsDirectPrintReceipt == 4) {
      const url = this.Utilities.geta4SizeReport(this.Utilities.getEncrypt(this.Utilities.getCompanyName()), this.Utilities.getEncrypt(this.OrderID));
      this.ReceiptURL = url;
      // this.checkIframeLoaded();
    }
    if (this.IsDirectPrintReceipt == 3) {
      // this.ReceiptURL = this.Utilities.getReceiptURL(this.Utilities.getEncrypt(this.Utilities.getCompanyName()), this.Utilities.getEncrypt(this.OrderID), this.CountryCode);
      this.ReceiptURL = this.Utilities.getDigiPosReceiptURL(this.Utilities.getEncrypt(this.Utilities.getCompanyName()), this.Utilities.getEncrypt(this.OrderID), this.CountryCode);
    }
    else if (this.IsDirectPrintReceipt == 2) {
      if (this.IsMaxPolicyOn) {
        if (this.MaxReceiptPrintOut > this.ReceiptPrintOutCount) {
          let link = this.ServerLink + '/Default?O=' + this.OrderID + '&C=' + this.companyID + '&type=RECEIPTREPRINT' + '&OutletID=' + this.SelectedBranchID + '&ShiftID=' + this.ShiftID + '&POSID=' + this.counterID;
          this.win = await window.open(link, '_blank', 'width=300,height=200');
          this.ReceiptPrintOutCount = this.ReceiptPrintOutCount + 1;
        }
        else {
          this.printvalidation = true;
        }
      }
      else {
        let link = this.ServerLink + '/Default?O=' + this.OrderID + '&C=' + this.companyID + '&type=RECEIPTREPRINT' + '&OutletID=' + this.SelectedBranchID + '&ShiftID=' + this.ShiftID + '&POSID=' + this.counterID;
        this.win = await window.open(link, '_blank', 'width=300,height=200');
      }

    }
  }

  setValuesOrderData(lstMaster_merged, lstOrderDetail_merged) {
    debugger
    if (this.isArrayHasValue(lstMaster_merged)) {
      this.lstBasket = lstMaster_merged;
      this.TableName = lstMaster_merged[0]['TableNumber'];
      this.Subtoal = lstMaster_merged[0]['TotalAmount'];
      this.SubtoalForVAT = this.Subtoal;
      this.Total = lstMaster_merged[0]['NetAmount'];
      this.VAT = lstMaster_merged[0]['TotalTaxAmount'];
      this.DiscountAmont = lstMaster_merged[0]['TotalDiscount'];
      this.custName = lstMaster_merged[0]['UserFullName'];
      this.CustomerId = lstMaster_merged[0]['CustomerID'];
      this.OrderTypeID = lstMaster_merged[0]['OrderTypeID'];
      this.OrderType = lstMaster_merged[0]['OrderType'];
      this.SelectedOrderInvoice = lstMaster_merged[0]['OrderInvoiceNo'];
      this.PromoAmountforVisibility = lstMaster_merged[0]['PromoAmount'] == null ? 0 : lstMaster_merged[0]['PromoAmount'];
      this.IsPercentPromoDiscount = lstMaster_merged[0]['IsPercent'];
      this.PromoAmount = lstMaster_merged[0]['DisVoucherValue'];
      this.PromoCode = lstMaster_merged[0]['PromoCode'];
      this.IsExtraCharge = lstMaster_merged[0]['IsExtraCharge'];
      this.ExtraChargeAmount = lstMaster_merged[0]['ExtraChargeAmount'];
      this.ExtraChargeDescription = lstMaster_merged[0]['ExtraChargeDescription'];
      this.ExtraChargeDescriptionArabic = lstMaster_merged[0]['ExtraChargeDescriptionArabic'];
    }

    if (this.isArrayHasValue(lstOrderDetail_merged)) {
      debugger
      this.lstSelectModifier = [];
      let lst = lstOrderDetail_merged;
      for (let i = 0; i < this.lstBasket.length; i++) {
        let row = lst.filter(t => t.ProductID == this.lstBasket[i].ProductID && t.rowid == this.lstBasket[i].rowid);
        if (row.length > 0)
          this.lstSelectModifier.push(row);
        else
          this.lstSelectModifier.push([{ AllowMultipleSeletion: null, DetailId: null, IsSelected: null, ListName: null, MasterId: null, MaxModifiers: null, MinModifiers: null, ModifierId: null, Modifires: null, Price: null, PriceStatic: null, ProductID: null, rowid: null }]);
      }
    }


    // else if (this.isArrayHasValue(data[0]['DummyModifier'])) {
    //   this.lstSelectModifier = [];
    //   for (let i = 0; i < this.lstBasket.length; i++) {
    //     this.lstSelectModifier.push([{ AllowMultipleSeletion: null, DetailId: null, IsSelected: null, ListName: null, MasterId: null, MaxModifiers: null, MinModifiers: null, ModifierId: null, Modifires: null, Price: null, PriceStatic: null, ProductID: null, rowid: null }]);
    //   }
    // }
  }

  openSalesTypePopup(SalesTypeID, Section) {
    this.GetGroupTableInfo(SalesTypeID, Section);

  }

  lstDineinTableInfo = [];
  lstTableGroupInfo = [];
  SectionID: any;
  GetGroupTableInfo(SalesTypeID, Section): void {
    this.lstDineinTableInfo = [];
    this.dataservice.get('pos/GetGroupTableInfo/' + this.Utilities.getSubscriptionID() + '/' + this.SelectedBranchID + '/' + SalesTypeID)
      .subscribe(data => {
        debugger;
        if (this.isArrayHasValue(data[0]['GroupTableInfo'])) {
          this.lstTableGroupInfo = data[0]['GroupTableInfo'];
          this.SectionID = this.lstTableGroupInfo[0]["ID"];
          this.GetDineinTableInfoSectionWise(this.SectionID, SalesTypeID, Section);
        }
        else {
          debugger
          this.OrderTypeID = SalesTypeID;
          this.OrderType = Section;
          this.getProduct(this.productstr);
        }
      }, error => alert('error'));
  }

  DineChangeRights: any = [];
  chkDinerights: boolean = false;
  GetDineinTableInfoSectionWise(sectionid, SalesTypeID, Section): void {
    debugger
    this.lstDineinTableInfo = [];
    this.DineChangeRights = [];
    this.dataservice.get('pos/GetDineinTableInfo/' + this.Utilities.getSubscriptionID() + '/' + sectionid + '/' + this.IsMergeTable + '/' + this.TableName + '/' + this.IsTransferTable + '/' + this.Utilities.getUserId() + '/' + SalesTypeID)
      .subscribe(data => {
        debugger;
        if (this.isArrayHasValue(data[0]['DineinTableInfo'])) {
          this.lstDineinTableInfo = data[0]['DineinTableInfo'];
        }
        if (this.isArrayHasValue(data[0]['DineChangeRights'])) {
          this.DineChangeRights = data[0]['DineChangeRights'];
          this.chkDinerights = this.DineChangeRights[0]['Allow'];
        }

        this.OrderTypeID = SalesTypeID;
        this.OrderType = Section;
        this.getProduct(this.productstr);
        if (this.lstDineinTableInfo.length > 0) {

          const dialogRef = this.dialog.open(SalestypepopupComponent, {
            width: '1150px',
            height: '600px',
            data: { culture: this.cultures, outletID: this.SelectedBranchID, NumOfDecimal: this.NumOfDecimal, ID: SalesTypeID, Section: Section }
          });


          dialogRef.afterClosed().subscribe(result => {

            debugger;
            if (result != undefined) {
              this.Dinedata = result;

              if (this.Dinedata["Status"] == 0) {
                this.TableID = this.Dinedata["ID"]
                this.TableName = this.Dinedata["TableName"]
                this.noOfCustomers = this.Dinedata["noOfCustomers"];
                this.TableClosedBy = this.Utilities.getUserId();
                this.TableClosedOn = this.Utilities.GetCurrentDateTime();
                this.lstBasket = [];
                this.lstbasketdata = [];
                this.lstComboProducts = [];
                this.lstModifier = [];
                this.lstSelectModifier = [];
                this.Subtoal = 0;
                this.Total = 0;
                this.VAT = 0;
                this.SubtoalForVAT = 0;
                this.DiscountAmont = 0;
                this.PromoAmountforVisibility = 0;
                this.PromoAmount = 0;
                this.PromoCode = '';
                this.SelectedOrderInvoice = '';
                this.DineOrderID = 0;
                this.chkKOT = false;
                this.ExtraChargeAmount = 0;
              }
              else {
                if (this.IsRightsEmployeeWise) {
                  if (this.arr.some(y => y.EntityCode == "voidbutton")) {
                    this.IsVoidButtonActive = true;
                  }
                  else
                    this.IsVoidButtonActive = false;

                }
                else if (this.lstPosButtonDeviceWise) {
                  if (this.lstPosButtonDeviceWise.some(x => x.EntityCode == "voidbutton")) {
                    this.IsVoidButtonActive = true;
                  }
                  else {
                    this.IsVoidButtonActive = false;
                  }
                }
                this.TableID = this.Dinedata["ID"];
                this.TableName = this.Dinedata["TableName"];
                this.DineOrderID = this.Dinedata["OrderID"];
                this.getorderdetails(this.Dinedata["OrderID"]);
                this.OrderID = this.Dinedata["OrderID"];
                this.chkKOT = true;

              }
              if (this.Dinedata["OrderID"] > 0)
                this.IsPrintButtonDisplay = true
              else
                this.IsPrintButtonDisplay = false
            }

          });
        }
        else {
          debugger
          this.OrderTypeID = SalesTypeID;
          this.OrderType = Section;
          this.getProduct(this.productstr);
        }
      }, error => alert('error'));
  }

  VFI_GetAuth() {
    debugger
    this.dataservice.post('Queue/Web_VFI_GetAuth/', this.ObjVFI_GetAuth)
      .subscribe(data => {
        debugger;
        if (data) {
          debugger

        }
        else {
          debugger
        }
      }, error => alert('error'));
  }
  // CalculateTotalTaxDiscount_MergeOrder() {
  //   let A: number = 0;
  //   let modifireamount = 0;
  //   debugger
  //   if (this.lstSelectModifier.length > 0) {
  //     for (var i = 0; i < this.lstBasket.length; i++) {
  //       for (var j = 0; j < this.lstSelectModifier[i].length; j++) {
  //         if (this.lstSelectModifier[i][j].Price != null && this.lstSelectModifier[i][j].Price != undefined && this.lstSelectModifier[i][j].Price != 0 && this.lstSelectModifier[i][j].IsSelected == true) {
  //           let Quant
  //           if (this.lstBasket.hasOwnProperty('DelFlag') == true)
  //             Quant = this.lstBasket.find(t => t.rowid == this.lstSelectModifier[i][j].rowid && t.DelFlag == 0).Quantity;
  //           else {
  //             Quant = this.lstBasket.find(t => t.rowid == this.lstSelectModifier[i][j].rowid).Quantity;
  //           }
  //           modifireamount += this.lstSelectModifier[i][j].Price * Quant;
  //         }
  //       }
  //     }
  //   }
  //   if (this.lstBasket.length > 0) {
  //     const Productssum = this.lstBasket.filter(x => x.DelFlag == null || x.DelFlag == 0).map(x => x.Price * x.Quantity).reduce((a, b) => a + b);


  //     if (this.ObjOutletInfo.IsTaxInclusive == true) {
  //       if (this.IsPercentPromoDiscount) {
  //         this.PromoAmountforVisibility = ((Productssum + modifireamount) * this.PromoAmount) / 100;
  //       }
  //       else {
  //         this.PromoAmountforVisibility = +this.PromoAmount;
  //       }

  //       if (this.ObjOutletInfo.IsTaxOnDiscountAmount == true) {
  //         A = (((Productssum + modifireamount) - this.PromoAmountforVisibility) * 100) / (100 + this.ObjOutletInfo.TaxRate);
  //         this.VAT = ((Productssum + modifireamount) - this.PromoAmountforVisibility) - A;
  //         this.Subtoal = A + this.PromoAmountforVisibility;
  //         this.Total = (this.Subtoal + this.VAT - this.PromoAmountforVisibility);
  //       }
  //       else {
  //         A = ((Productssum + modifireamount) * 100) / (100 + this.ObjOutletInfo.TaxRate);
  //         this.VAT = (Productssum + modifireamount) - A;
  //         this.Subtoal = A;
  //         this.Total = (this.Subtoal + this.VAT - this.PromoAmountforVisibility);
  //       }

  //       if (this.IsExtraCharge == true) {
  //         this.CalculateExtraChargeInclusive();
  //       }
  //     }
  //     else {
  //       this.Subtoal = Productssum + modifireamount;

  //       if (this.IsExtraCharge == true) {
  //         this.ExtraChargeAmount = (this.Subtoal) * this.ExtraChargePercentage / 100;
  //       }

  //       if (this.IsPercentPromoDiscount) {
  //         this.PromoAmountforVisibility = ((Productssum + modifireamount + this.ExtraChargeAmount) * this.PromoAmount) / 100;
  //       }
  //       else {
  //         this.PromoAmountforVisibility = +this.PromoAmount;
  //       }

  //       if (this.ObjOutletInfo.IsTaxOnDiscountAmount == true)
  //         this.VAT = (((this.Subtoal + this.ExtraChargeAmount) - this.PromoAmountforVisibility) * this.ObjOutletInfo.TaxRate) / 100;
  //       else
  //         this.VAT = ((this.Subtoal + this.ExtraChargeAmount) * this.ObjOutletInfo.TaxRate) / 100;

  //       this.Total = (this.Subtoal + this.VAT + this.ExtraChargeAmount - this.PromoAmountforVisibility);
  //     }



  //     this.PromoAmountforVisibility = +this._decimalPipe.transform(this.PromoAmountforVisibility, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal).replace(',', '');
  //     this.Total = +this._decimalPipe.transform(this.Total, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal).replace(',', '');

  //     this.Subtoal = +this._decimalPipe.transform(this.Subtoal, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal).replace(',', '');
  //     this.VAT = +this._decimalPipe.transform(this.VAT, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal).replace(',', '');
  //   }
  //   if (!this.isArrayHasValue(this.lstBasket)) {
  //     this.Subtoal = 0;
  //     this.SubtoalForVAT = 0;
  //     this.Total = 0;
  //     this.VAT = 0;
  //     this.PromoAmountforVisibility = 0;
  //     this.PromoAmount = 0;
  //     this.ExtraChargeAmount = 0;
  //     this.PromoCode = '';
  //   }


  // }

}
class PurchaseOrder {
  POID: any = 0;
  POInvoiceNo: any = '';
  QuotationRef: any = '';
  OrderDate: any = '';
  DeliveryDate: any = '';
  SupplierID: any;
  BranchID: any;
  Description: any = '';
  OrderStatus: any = 'P';
  TotalAmount: any;
  InvoiceDiscount: any;
  TotalLineDiscount: any;
  NetAmount: any;
  Notes: any = '';
  Posted: boolean = false;
  CreatedBy: any = '';
  CreatedOn: any = '';
}
class DetailData {
  RowID: any;
  POID: any;
  ProductID: any;
  ProductName: any;
  Quantity: any;
  Price: any;
  TaxID: any;
  TaxAmount: any;
  LineDiscount: any;
  TotalAmount: any;
  NetAmount: any;
  // RejectReasonID:any;
  // RejectedReason:any;
  // DelFlag:any=0;
}
export class ResturentInfo {
  ID: any;
  TextColor: any = '';
  BaseColor: any = '';
  Title: any = '';
  Message: any = '';
  MainImagePath: any = '';
  MainImageName: any = '';
  IconImagePath: any = '';
  IconImageName: any = '';
  RestaurantName: any = '';
  RestaurantNameDisplay: any = '';
}
export class Company {
  CompanyID: any;
  CompanyName: any;
  outletsCount: any;
  CountryID: any;
  hearbout: any;
  Mobile: any;
  CurrencyCode: any;
  Rate: any;
  NumOfDecimal: any;
  CountryCode: any;
  IsOTPRequired: boolean;
}
export class OutletInfo {
  BranchID: any = 0;
  BranchName: any = '';
  CityId: any = 0;
  ImageName: any = '';
  TaxRate: any = 0;
  TaxID: any = 0;
  IsTaxInclusive: boolean = false;
  IsTaxOnDiscountAmount: boolean = false;
  IsZatcaInvoiceOn: boolean;
  CertificateType: any = '';
}
export class OrderTypeInfo {
  ID: any = 0;
  Type: any = '';
  CompanyId: any = 0;

}
export class Modifierclass {

  AllowMultipleSeletion: any;
  DetailId: any;
  IsSelected: any;
  ListName: any;
  MasterId: any;
  MaxModifiers: any;
  MinModifiers: any;
  ModifierId: any;
  Modifires: any;
  Price: any;
  PriceStatic: any;
  ProductID: any;
  rowid: any;

}
export class discountVoucher {
  DiscVoucherID: any;
  VoucherCode: any;
  VoucherDesc: any;
  IsPercent: any;
  Amount: any;
  MinOrderValue: any;
  UsagePerUser: any;
  UsagePerVoucher: any;
  VoucherExpiryDate: any;
  OutletIds: any;
  Status: any;
  CompanyId: any;
}


export class VFI_GetAuth {
  VFI_TransType: any = '1';
  VFI_ECRRcptNum: any = 'a123'
  VFI_Amount: any = '1000';
  VFI_CardNum: any = '123456789'
  VFI_CashAmount: any = '1000';
  VFI_AditionalInfo: any = 'hello';
  VFI_RespCode: any = '';
}
// import { take } from 'rxjs/operators';

// async ngOnInit(): Promise<any> {
//   const data = await this.service.getData().pipe(take(1)).toPromise();
//   this.data = this.modifyMyData(data);
// }

// constructor() {
//   this.service.getData().pipe(take(1)).toPromise()
//     .then((data => {;
//       this.data = this.modifyMyData(data);
//     });
// }
