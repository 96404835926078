<meta name="viewport" content="width=device-width, initial-scale=1.0">
<div *ngIf="ObjCompany.CompanyID  ==5">

  <div id="invoice-POS" class="invoice-POS">
    <!-- <div id="top" >
      <div class="logo" style="display: inline;">
        <img *ngIf="ObjOrderMaster.Channel == 'Website'" [src]="this.ObjCompanyInfo.LogoImageName" style="width: 15vh;border-radius: 5px;" />
        <img *ngIf="ObjOrderMaster.Channel == 'POS'" [src]="this.ObjOrderMaster.ImagePath" style="width: 15vh;border-radius: 5px;" />
      </div>
        <div *ngIf="ObjOrderMaster.OutletName != 'N/A'" style="float:right;font-weight:700;max-width: 40%;">
          <div style="font-size: 20px;">{{ObjOrderMaster.OutletName}}</div>
          <div style="font-size: 22px;">{{ObjOrderMaster.ArabicOutletName}}</div>
          <div>{{ObjOrderMaster.outletAddress}} {{ObjOrderMaster.AreaName}}</div>
          <div style="font-size: 14px;">{{ObjOrderMaster.ArabicArea}} {{ObjOrderMaster.ArabicAddress}}</div>
          <div *ngIf="ObjCompanyInfo.VATNo != 'N/A'">الرقم الضريبي / VAT No. : {{ObjCompanyInfo.VATNo}}</div>
          <div *ngIf="ObjOrderMaster.OutletPhone != 'N/A'">Tel : {{ObjOrderMaster.OutletPhone}}</div>
        </div>
    </div> -->
  <br/>
  <br/>
  <br/>
  <br/>
  <br/>
  <br/>
  <br/>
  <br/>
  <div class="row">
    <label style="width: 100%;font-size: 23px;font-weight: 600;text-align: center;padding: 8px;margin-bottom: 0px;">فاتورة ضريبية / Tax Invoice</label>
    <div style="padding-left: 5px;padding-top: 5px;width: 70%;display: inline-block;" *ngIf="ObjOrderMaster.OutletName != 'N/A'">
      <div style="font-weight:600;">تفاصيل الفاتورة / Invoice Detail</div>
      <div style="font-size: 14px"><span>رقم الفاتورة / Invoice No. </span> {{ObjOrderMaster.OrderInvoiceNo}}</div>
      <div style="font-size: 14px"><span>تاريخ الفاتوره / Invoice Date: </span> {{ObjOrderMaster.OrderDate}}</div>
      <div style="font-size: 14px"><span>رقم طلب الشراء / PO No: </span> {{ObjOrderMaster.PurchaseOrderNo}}</div>
   </div>
  </div>

<div id="bot">
  <div id="table">
      <div class="row mb-2 companyandcustmerdiv">

        <div class="col-md-6 col-sm-6 col-xs-6 col-lg-6" style="padding: 0px;">
          <table style="border: 1px solid black;width: 100%;table-layout: auto;">
            <tbody>
              <tr style="line-height: 10px;">
                <th style="border-right: 1px solid;border-bottom: 1px solid;text-align: center;font-size: 14px;background: #ededed;"  colspan="4">Seller Detail</th>
              </tr>
              <tr style="line-height: 10px;">
                <td style="border-right: 1px solid;border-bottom: 1px solid;font-size:14px;font-weight:600;width: 105px; ">Name:</td>
                <td style="border-right: 1px solid;border-bottom: 1px solid;font-size: 14px;">{{ObjCompanyInfo.CompanyRegName}}</td>
                <td style="border-right: 1px solid;border-bottom: 1px solid;font-size: 14px;text-align: end;">{{ObjCompanyInfo.CompanyRegNameAra}}</td>
                <td style="border-right: 1px solid;border-bottom: 1px solid;font-size:14px;font-weight:600;text-align: end;width: 70px">سم</td>
              </tr>
              <tr style="line-height: 10px;">
                <td style="border-right: 1px solid;border-bottom: 1px solid;font-size:14px;font-weight:600;width: 105px">Building No:</td>
                <td style="border-right: 1px solid;border-bottom: 1px solid;font-size: 14px;">{{ObjCompanyInfo.BuildingNo}}</td>
                <td style="border-right: 1px solid;border-bottom: 1px solid;font-size: 14px;text-align: end">{{ObjCompanyInfo.BuildingNo}}</td>
                <td style="border-right: 1px solid;border-bottom: 1px solid;font-size:14px;font-weight:600;text-align: end;width: 70px">رقم المبنى</td>
              </tr>
              <tr style="line-height: 10px;">
                <td style="border-right: 1px solid;border-bottom: 1px solid;font-size:14px;font-weight:600;width: 105px">Street Name:</td>
                <td style="border-right: 1px solid;border-bottom: 1px solid;font-size: 14px;">{{ObjCompanyInfo.StreetName}}</td>
                <td style="border-right: 1px solid;border-bottom: 1px solid;font-size: 14px;text-align: end;">{{ObjCompanyInfo.StreetNameArabic}}</td>
                <td style="border-right: 1px solid;border-bottom: 1px solid;font-size:14px;font-weight:600;text-align: end;width: 70px">اسم الشارع</td>
              </tr>
              <tr style="line-height: 10px;">
                <td style="border-right: 1px solid;border-bottom: 1px solid;font-size:14px;font-weight:600;width: 105px">District:</td>
                <td style="border-right: 1px solid;border-bottom: 1px solid;font-size: 14px;">{{ObjCompanyInfo.District}}</td>
                <td style="border-right: 1px solid;border-bottom: 1px solid;font-size: 14px;text-align: end">{{ObjCompanyInfo.DistrictArabic}}</td>
                <td style="border-right: 1px solid;border-bottom: 1px solid;font-size:14px;font-weight:600;text-align: end;width: 70px">الحي</td>
              </tr>
              <tr style="line-height: 10px;">
                <td style="border-right: 1px solid;border-bottom: 1px solid;font-size:14px;font-weight:600;width: 105px">Country Name:</td>
                <td style="border-right: 1px solid;border-bottom: 1px solid;font-size: 14px;">{{ObjCurrency.CountryName}}</td>
                <td style="border-right: 1px solid;border-bottom: 1px solid;font-size: 14px;text-align: end;">{{ObjCurrency.ACountryName}}</td>
                <td style="border-right: 1px solid;border-bottom: 1px solid;font-size:14px;font-weight:600;text-align: end;width: 70px">دولة</td>
              </tr>
              <tr style="line-height: 10px;">
                <td style="border-right: 1px solid;border-bottom: 1px solid;font-size:14px;font-weight:600;width: 105px">City Name:</td>
                <td style="border-right: 1px solid;border-bottom: 1px solid;font-size: 14px;">{{ObjCompanyInfo.CityName}}</td>
                <td style="border-right: 1px solid;border-bottom: 1px solid;font-size: 14px;text-align: end">{{ObjCompanyInfo.CityNameArabic}}</td>
                <td style="border-right: 1px solid;border-bottom: 1px solid;font-size:14px;font-weight:600;text-align: end;width: 70px">مدينة</td>
              </tr>
              <tr style="line-height: 10px;">
                <td style="border-right: 1px solid;border-bottom: 1px solid;font-size:14px;font-weight:600;width: 105px">Postal Code:</td>
                <td style="border-right: 1px solid;border-bottom: 1px solid;font-size: 14px;">{{ObjCompanyInfo.PostalZone}}</td>
                <td style="border-right: 1px solid;border-bottom: 1px solid;font-size: 14px;text-align: end;">{{ObjCompanyInfo.PostalZone}}</td>
                <td style="border-right: 1px solid;border-bottom: 1px solid;font-size:14px;font-weight:600;text-align: end;width: 70px">الرمز البريدي</td>
              </tr>
              <tr style="line-height: 10px;">
                <td style="border-right: 1px solid;border-bottom: 1px solid;font-size:14px;font-weight:600;width: 105px">Additional No:</td>
                <td style="border-right: 1px solid;border-bottom: 1px solid;font-size: 14px;">{{ObjCompanyInfo.AdditionalNo}}</td>
                <td style="border-right: 1px solid;border-bottom: 1px solid;font-size: 14px;text-align: end">{{ObjCompanyInfo.AdditionalNo}}</td>
                <td style="border-right: 1px solid;border-bottom: 1px solid;font-size:14px;font-weight:600;text-align: end;width: 70px">الرقم الفرعي</td>
              </tr>
              <tr style="line-height: 10px;">
                <td style="border-right: 1px solid;border-bottom: 1px solid;font-size:14px;font-weight:600;width: 105px">Contact No:</td>
                <td style="border-right: 1px solid;border-bottom: 1px solid;font-size: 14px;">{{ObjCurrency.Mobile}}</td>
                <td style="border-right: 1px solid;border-bottom: 1px solid;font-size: 14px;text-align: end;">{{ObjCurrency.Mobile}}</td>
                <td style="border-right: 1px solid;border-bottom: 1px solid;font-size:14px;font-weight:600;text-align: end;width: 70px">اتصال</td>
              </tr>
              <tr style="line-height: 10px;">
                <td style="border-right: 1px solid;border-bottom: 1px solid;font-size:14px;font-weight:600;width: 105px">VAT No:</td>
                <td style="border-right: 1px solid;border-bottom: 1px solid;font-size: 14px;">{{ObjCompanyInfo.VATNo}}</td>
                <td style="border-right: 1px solid;border-bottom: 1px solid;font-size: 14px;text-align: end">{{ObjCompanyInfo.VATNo}}</td>
                <td style="border-right: 1px solid;border-bottom: 1px solid;font-size:14px;font-weight:600;text-align: end;width: 70px">الرقم الضريبي</td>
              </tr>
              <tr style="line-height: 10px;">
                <td style="border-right: 1px solid;border-bottom: 1px solid;font-size:14px;font-weight:600;width: 105px">CRN No:</td>
                <td style="border-right: 1px solid;border-bottom: 1px solid;font-size: 14px;">{{ObjCompanyInfo.CRNNo}}</td>
                <td style="border-right: 1px solid;border-bottom: 1px solid;font-size: 14px;text-align: end;">{{ObjCompanyInfo.CRNNo}}</td>
                <td style="border-right: 1px solid;border-bottom: 1px solid;font-size:14px;font-weight:600;text-align: end;width: 70px">رقم تجاري</td>
              </tr>
              <tr style="line-height: 10px;">
                <td style="border-right: 1px solid;border-bottom: 1px solid;font-size:14px;font-weight:600;width: 105px">COID NO:</td>
                <td style="border-right: 1px solid;border-bottom: 1px solid;font-size: 14px;">{{ObjCompanyInfo.COIDNO}}</td>
                <td style="border-right: 1px solid;border-bottom: 1px solid;font-size: 14px;text-align: end">{{ObjCompanyInfo.COIDNO}}</td>
                <td style="border-right: 1px solid;border-bottom: 1px solid;font-size:14px;font-weight:600;text-align: end;width: 70px">الرقم الموحد</td>
              </tr>
  
            </tbody>
          </table>
        </div>
        
       <div  class="col-md-6 col-sm-6 col-xs-6 col-lg-6" style="padding: 0px;">
  
        <table style="border: 1px solid black;width: 100%;table-layout: auto;">
         
          <tbody>
            <tr style="line-height: 10px;">
              <th style="border-right: 1px solid;border-bottom: 1px solid;text-align: center;font-size: 14px;background: #ededed;"  colspan="4">Customer Detail</th>
            </tr>
            <tr style="line-height: 10px;">
              <td style="border-right: 1px solid;border-bottom: 1px solid;font-size:14px;font-weight:600;width: 105px">Customer Name:</td>
              <td style="border-right: 1px solid;border-bottom: 1px solid;font-size: 14px;">{{ObjOrderMaster.CustomerName}}</td>
              <td style="border-right: 1px solid;border-bottom: 1px solid;font-size: 14px;text-align: end;">{{ObjOrderMaster.CustomerNameArabic}}</td>
              <td style="border-right: 1px solid;border-bottom: 1px solid;font-size:14px;font-weight:600;text-align: end;width: 70px">اسم الزبون</td>
            </tr>
            <tr style="line-height: 10px;">
              <td style="border-right: 1px solid;border-bottom: 1px solid;font-size:14px;font-weight:600;width: 105px">Building No:</td>
              <td style="border-right: 1px solid;border-bottom: 1px solid;font-size: 14px;">{{ObjOrderMaster.BuildingNo}}</td>
              <td style="border-right: 1px solid;border-bottom: 1px solid;font-size: 14px;text-align: end">{{ObjOrderMaster.BuildingNo}}</td>
              <td style="border-right: 1px solid;border-bottom: 1px solid;font-size:14px;font-weight:600;text-align: end;width: 70px">رقم المبنى</td>
            </tr>
            <tr style="line-height: 10px;">
              <td style="border-right: 1px solid;border-bottom: 1px solid;font-size:14px;font-weight:600;width: 105px">Street Name:</td>
              <td style="border-right: 1px solid;border-bottom: 1px solid;font-size: 14px;">{{ObjOrderMaster.StreetName}}</td>
              <td style="border-right: 1px solid;border-bottom: 1px solid;font-size: 14px;text-align: end;">{{ObjOrderMaster.StreetName}}</td>
              <td style="border-right: 1px solid;border-bottom: 1px solid;font-size:14px;font-weight:600;text-align: end;width: 70px">اسم الشارع</td>
            </tr>
            <tr style="line-height: 10px;">
              <td style="border-right: 1px solid;border-bottom: 1px solid;font-size:14px;font-weight:600;width: 105px">District:</td>
              <td style="border-right: 1px solid;border-bottom: 1px solid;font-size: 14px;">{{ObjOrderMaster.District}}</td>
              <td style="border-right: 1px solid;border-bottom: 1px solid;font-size: 14px;text-align: end">{{ObjOrderMaster.DistrictArabic}}</td>
              <td style="border-right: 1px solid;border-bottom: 1px solid;font-size:14px;font-weight:600;text-align: end;width: 70px">الحي</td>
            </tr>
            <tr style="line-height: 10px;">
              <td style="border-right: 1px solid;border-bottom: 1px solid;font-size:14px;font-weight:600;width: 105px">Country Name:</td>
              <td style="border-right: 1px solid;border-bottom: 1px solid;font-size: 14px;">{{ObjOrderMaster.CountryName}}</td>
              <td style="border-right: 1px solid;border-bottom: 1px solid;font-size: 14px;text-align: end;">{{ObjOrderMaster.CountryName}}</td>
              <td style="border-right: 1px solid;border-bottom: 1px solid;font-size:14px;font-weight:600;text-align: end;width: 70px">دولة</td>
            </tr>
            <tr style="line-height: 10px;">
              <td style="border-right: 1px solid;border-bottom: 1px solid;font-size:14px;font-weight:600;width: 105px">City Name:</td>
              <td style="border-right: 1px solid;border-bottom: 1px solid;font-size: 14px;">{{ObjOrderMaster.City}}</td>
              <td style="border-right: 1px solid;border-bottom: 1px solid;font-size: 14px;text-align: end">{{ObjOrderMaster.City}}</td>
              <td style="border-right: 1px solid;border-bottom: 1px solid;font-size:14px;font-weight:600;text-align: end;width: 70px">مدينة</td>
            </tr>
            <tr style="line-height: 10px;">
              <td style="border-right: 1px solid;border-bottom: 1px solid;font-size:14px;font-weight:600;width: 105px">Postal Code:</td>
              <td style="border-right: 1px solid;border-bottom: 1px solid;font-size: 14px;">{{ObjOrderMaster.ZipCode}}</td>
              <td style="border-right: 1px solid;border-bottom: 1px solid;font-size: 14px;text-align: end;">{{ObjOrderMaster.ZipCode}}</td>
              <td style="border-right: 1px solid;border-bottom: 1px solid;font-size:14px;font-weight:600;text-align: end;width: 70px">الرمز البريدي</td>
            </tr>
            <tr style="line-height: 10px;">
              <td style="border-right: 1px solid;border-bottom: 1px solid;font-size:14px;font-weight:600;width: 105px">Additional No:</td>
              <td style="border-right: 1px solid;border-bottom: 1px solid;font-size: 14px;">{{ObjOrderMaster.AdditionalNo}}</td>
              <td style="border-right: 1px solid;border-bottom: 1px solid;font-size: 14px;text-align: end">{{ObjOrderMaster.AdditionalNo}}</td>
              <td style="border-right: 1px solid;border-bottom: 1px solid;font-size:14px;font-weight:600;text-align: end;width: 70px">الرقم الفرعي</td>
            </tr>
            <tr style="line-height: 10px;">
              <td style="border-right: 1px solid;border-bottom: 1px solid;font-size:14px;font-weight:600;width: 105px">Contact No:</td>
              <td style="border-right: 1px solid;border-bottom: 1px solid;font-size: 14px;">{{ObjOrderMaster.PhoneNo}}</td>
              <td style="border-right: 1px solid;border-bottom: 1px solid;font-size: 14px;text-align: end;">{{ObjOrderMaster.PhoneNo}}</td>
              <td style="border-right: 1px solid;border-bottom: 1px solid;font-size:14px;font-weight:600;text-align: end;width: 70px">اتصال</td>
            </tr>
            <tr style="line-height: 10px;">
              <td style="border-right: 1px solid;border-bottom: 1px solid;font-size:14px;font-weight:600;width: 105px">VAT No:</td>
              <td style="border-right: 1px solid;border-bottom: 1px solid;font-size: 14px;">{{ObjOrderMaster.VATNo}}</td>
              <td style="border-right: 1px solid;border-bottom: 1px solid;font-size: 14px;text-align: end">{{ObjOrderMaster.VATNo}}</td>
              <td style="border-right: 1px solid;border-bottom: 1px solid;font-size:14px;font-weight:600;text-align: end;width: 70px">الرقم الضريبي</td>
            </tr>
            <tr style="line-height: 10px;">
              <td style="border-right: 1px solid;border-bottom: 1px solid;font-size:14px;font-weight:600;width: 105px">CRN No:</td>
              <td style="border-right: 1px solid;border-bottom: 1px solid;font-size: 14px;">{{ObjOrderMaster.CRNumber}}</td>
              <td style="border-right: 1px solid;border-bottom: 1px solid;font-size: 14px;text-align: end;">{{ObjOrderMaster.CRNumber}}</td>
              <td style="border-right: 1px solid;border-bottom: 1px solid;font-size:14px;font-weight:600;text-align: end;width: 70px">رقم تجاري</td>
            </tr>
            <tr style="line-height: 10px;">
              <td style="border-right: 1px solid;border-bottom: 1px solid;font-size:14px;font-weight:600;width: 105px">COID NO:</td>
              <td style="border-right: 1px solid;border-bottom: 1px solid;font-size: 14px;">{{ObjOrderMaster.COID}}</td>
              <td style="border-right: 1px solid;border-bottom: 1px solid;font-size: 14px;text-align: end">{{ObjOrderMaster.COID}}</td>
              <td style="border-right: 1px solid;border-bottom: 1px solid;font-size:14px;font-weight:600;text-align: end;width: 70px">الرقم الموحد</td>
            </tr>
  
          </tbody>
        </table>
  
        </div>
  
      </div>
     
    </div>
  </div>
    
     
    
    <div style="margin-right: -15px;margin-left: -15px;">
    <div id="bot">
      <div id="table">
        <!-- min-height: 500px !important; -->
        <table style="border: 1px solid black;width: 100%;table-layout: auto;min-height: 360px !important;">
          <thead>
            <tr style="background: #ededed;line-height: 14px;">
              <th style="border-right: 1px solid;border-bottom: 1px solid;text-align: center;">Sr. No<br /> رقم</th>
              <th style="border-right: 1px solid;border-bottom: 1px solid;text-align: center;width: 50px !important">ITEM DESCRIPTION<br /> وصف السلعة</th>
              <th style="border-right: 1px solid;border-bottom: 1px solid;text-align: center;">QTY<br />كمية </th>
              <th style="border-right: 1px solid;border-bottom: 1px solid;text-align: center;padding-left: 5px;padding-right: 5px;">Rate<br />سعر</th>
              <th style="border-bottom:1px solid ;text-align: center;padding-left: 5px;padding-right: 5px;">AMOUNT<br />المبلغ</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let row of ArrOrderDetail; let i = index" style="vertical-align: text-top;">
              <td style="border-right: 1px solid;text-align: center;width: 5% !important;height: 10px;">{{row.SrNo}}</td>
              <td *ngIf="row.AddOnID == '0'" style="border-right: 1px solid;padding-left: 5px;width: 50% !important;height: 10px;">{{row.ProductName}} {{(row.ProductSizeName === null || row.ProductSizeName.trim() === '' || row.ProductSizeName === 'N/A') ? '' : '(' + row.ProductSizeName +')'}}   <span style="text-indent: 10px">{{row.AProductName}}</span> <br /> <span *ngIf="row.SpecialRequest" style="text-indent: 10px"><b>Note: </b>{{row.SpecialRequest}}</span></td>
              <td *ngIf="row.AddOnID != '0'" style="text-indent: 30px;border-right: 1px solid;width:10%;height: 10px;">{{row.AddOnName}}</td>
              <td style="border-right: 1px solid;text-align: center;width:5%;height: 10px;">{{row.Quantity}}</td>
              <td style="border-right: 1px solid;text-align: center;width: 10%;height: 10px;">{{row.Price|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</td>
              <td class="amountcls" style="width:10%;height: 10px;">{{row.OrignalAmount|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</td>
            </tr>
            <tr>
              <td style="border-right: 1px solid;width:5%;"></td>
              <td style="border-right: 1px solid;width:50%;"></td>
              <td style="border-right: 1px solid;width:10%;"></td>
              <td style="border-right: 1px solid;width:10%;"></td>
              <td style="border-right: 1px solid;width:10% ;"></td>
            </tr>
          </tbody>
        </table>
  
        <table style="border: 1px solid black;width: 100%;border-top: none;" class="mb-2">
          <ng-container *ngIf="ArrVoucherData?.length > 0">
            <tr *ngFor="let d of ArrVoucherData;" style="border-bottom: 1px solid;">
              <td style="text-align:right;font-weight: 600">Discount / تخفيض </td>
              <td style="text-align:right !important;font-weight: 600">{{CurrencyCode}}</td>
              <td style="text-align:right !important;font-weight: 600">(-{{d.VoucherAmount|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}})</td>
            </tr>
          </ng-container>
          <tr *ngIf="ObjOrderMaster.TotalDiscount" style="border-bottom: 1px solid;">
            <td style="text-align:right;font-weight: 600">Discount  / <span>تخفيض</span></td>
            <td style="text-align:right !important;font-weight: 600">{{CurrencyCode}}</td>
            <td style="text-align:right !important;font-weight: 600">(-{{ObjOrderMaster.TotalDiscount|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}})</td>
          </tr>
          <tr *ngIf="ObjOrderMaster.LoyaltyAmount > 0" style="border-bottom: 1px solid;">
            <td style="text-align:right;font-weight: 600">Discount (Reward Points)</td>
            <td style="text-align:right !important;font-weight: 600">{{CurrencyCode}}</td>
            <td style="text-align:right !important;font-weight: 600">(-{{ObjOrderMaster.LoyaltyAmount|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}})</td>
          </tr>
          <tr style="border-bottom: 1px solid;">
            <td style="text-align:right;font-weight: 600">Net Total / <span>المجموع النهائي</span></td>
            <td style="text-align:right !important;font-weight: 600">{{CurrencyCode}}</td>
            <td style="text-align:right !important;font-weight: 600">{{ObjOrderMaster.TotalAmount|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</td>
          </tr>
          <tr *ngIf="ObjOrderMaster.IsExtraCharge == 1 && ObjOrderMaster.ExtraChargeAmount >0" style="border-bottom: 1px solid;">
            <td style="text-align:right;font-weight: 600">{{ObjOrderMaster.ExtraChargeDescription}}<span *ngIf="ObjOrderMaster.ExtraChargeDescriptionArabic"> / {{ObjOrderMaster.ExtraChargeDescriptionArabic}}</span></td>
            <td style="text-align:right !important;font-weight: 600">{{CurrencyCode}}</td>
            <td style="text-align:right !important;font-weight: 600">{{ObjOrderMaster.ExtraChargeAmount|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</td>
          </tr>
          <tr style="border-bottom: 1px solid;">
            <td style="text-align:right;font-weight: 600">VAT Amount 15%  / <span>الضريبة</span></td>
            <td style="text-align:right !important;font-weight: 600">{{CurrencyCode}}</td>
            <td style="text-align:right !important;font-weight: 600">{{ObjOrderMaster.TotalTaxAmount|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</td>
          </tr>
          <tr *ngIf="ObjOrderMaster.OrderType == 'Delivery' && ObjOrderMaster.DeliveryFee > 0" style="border-bottom: 1px solid;">
            <td style="text-align:right;font-weight: 600">Delivery Fee</td>
            <td style="text-align:right !important;font-weight: 600">{{CurrencyCode}}</td>
            <td style="text-align:right !important;font-weight: 600">{{ObjOrderMaster.DeliveryFee|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</td>
          </tr>
          <tr style="border-bottom: 1px solid;">
            <td style="text-align:right;font-weight: 600">Final Total / <span>المجموع يشمل ضريبة القيمة المضافة</span></td>
            <td style="text-align:right !important;font-weight: 600">{{CurrencyCode}}</td>
            <td style="text-align:right !important;font-weight: 600">{{ObjOrderMaster.NetAmount|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</td>
          </tr>
        </table>
        <!-- <div *ngIf="ObjOrderMaster.Remarks != 'N/A' && ObjOrderMaster.Remarks != ''">
          <br />
          <div style="text-decoration:underline"><strong>Special Instructions</strong> </div>
          <div style="text-indent:5px"></div>
        </div>
        <br />
        <br /> -->
        <!-- <table *ngIf="ReturnAmount">
          <tr>
            <td style="width: 30% !important;">المبلغ المسترد</td>
            <td style="width: 20% !important;"></td>
            <td style="width: 30% !important;">Cash returned</td>
          </tr>
        </table>
        <table *ngIf="ReturnAmount" style=" border: 1px solid black; border-bottom:2px solid !important">
          <tr>
            <td><strong>Return Amount</strong></td>
            <td style="text-align:right !important">{{CurrencyCode}}</td>
            <td>{{ReturnAmount|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</td>
          </tr>
        </table> -->
        <div  style="border: 1px solid black;" class="mb-1 ">
         <div class="row "  >
          <!-- English Bank Details - Left Side -->
          <div style="width: 50%;padding-left: 10px;">
              <div style="padding-left: 10px;margin: 0px;font-weight: 600;">Bank Details</div>
              <p style="font-family: Calibri !important;font-size: 13px;padding-left: 10px;margin: 0px;"><strong>{{ObjCompanyInfo.CompanyRegName}}</strong></p>
              <p style="font-family: Calibri !important;font-size: 13px;padding-left: 10px;margin: 0px;"><strong>Account Number:</strong> {{ObjCompanyInfo.AccountNumber}}</p>
              <p style="font-family: Calibri !important;font-size: 13px;padding-left: 10px;margin: 0px;"><strong>IBAN:</strong> {{ObjCompanyInfo.IBAN}}</p>
              <p style="font-family: Calibri !important;font-size: 13px;padding-left: 10px;margin: 0px;">{{ObjCompanyInfo.BankName}}</p>
              <!-- Add more details as needed -->
          </div>

          <!-- Arabic Bank Details - Right Side -->
          <div style="width: 50%;padding-right: 10px;">
              <div dir="rtl" class="text-right" style="padding-right: 10px;margin: 0px;font-weight: 600;" >تفاصيل البنك باللغة</div>
              <p dir="rtl" class="text-right" style="font-family: Calibri !important;font-size: 13px;padding-right: 10px;margin: 0px;"><strong>{{ObjCompanyInfo.CompanyRegNameAra}}</strong></p>
              <p dir="rtl" class="text-right" style="font-family: Calibri !important;font-size: 13px;padding-right: 10px;margin: 0px;"><strong>رقم الحساب:</strong> {{ObjCompanyInfo.AccountNumber}}</p>
              <p dir="rtl" class="text-right" style="font-family: Calibri !important;font-size: 13px;padding-right: 10px;margin: 0px;"><strong>الايبان:</strong> {{ObjCompanyInfo.IBAN}}</p>
              <p dir="rtl" class="text-right" style="font-family: Calibri !important;font-size: 13px;padding-right: 10px;margin: 0px;">{{ObjCompanyInfo.BankNameArabic}}</p>
              <!-- Add more details as needed -->
          </div>
      </div></div>

        <div  style="border: 1px solid black;">
          <div class="row">
          <div  style="border-right: 1px solid black;width: 50%;padding-left: 15px;">
             <div>
          <ngx-qrcode [elementType]="elementType" [value]="QRUrl" [width]=100  cssClass="aclass" errorCorrectionLevel="L"> </ngx-qrcode>
          <p style="font-size: 20px;font-weight: 600;text-align: center;margin-top: -100px;padding-left: 100px;font-size: 18px;">CUSTOMER SIGNATURE / توقيع العميل</p>
        </div>
           
            <div class="signature-line"></div>
            <!-- Add space for the signature -->
          </div>
        
          <div style="width: 50%;padding-right: 10px;">
            <p style="font-size: 20px;font-weight: 600;text-align: center;font-size: 18px;">MANAGER SIGNATURE / توقيع المدير</p>
            <div class="signature-line"></div>
            <!-- Add space for the signature -->
          </div>
        </div>
        </div>
      </div>
 

      <!-- <div style="display:flex">
      <span style="width:50% ;"><p style="border-bottom:1px solid;width:80%;padding-bottom:60px;text-align: center;font-weight: 600;font-size: 14px;">Prepared By / أُعدت بواسطة</p></span>
      <span style="width:50% ;"><p style="border-bottom:1px solid;width:80%;padding-bottom:60px;text-align: center;font-weight: 600;font-size: 14px;">Approved By / تمت الموافقة عليه من قبل</p></span>
      <span style="width:50% ;"><p style="border-bottom:1px solid;width:80%;padding-bottom:60px;text-align: center;font-weight: 600;font-size: 14px;">Received By / استلمت من قبل</p></span>
    </div> -->
  
      <div id="bot">
        <div id="legalcopy">
          <button class="label clsB1 doNotPrint" style="border:0" (click)="printComponent()">Print order</button>
        </div>
      </div> 
    </div>
  </div>
  </div>
  </div>
<div *ngIf="ObjCompany.CompanyID  !=5">
<div id="invoice-POS" class="invoice-POS">
  <div id="top" >
    <div class="logo" style="display: inline;">
      <img *ngIf="ObjOrderMaster.Channel == 'Website'" [src]="this.ObjCompanyInfo.LogoImageName" style="width: 15vh;border-radius: 5px;" />
      <img *ngIf="ObjOrderMaster.Channel == 'POS'" [src]="this.ObjOrderMaster.ImagePath" style="width: 15vh;border-radius: 5px;" />
    </div>
      <div *ngIf="ObjOrderMaster.OutletName != 'N/A'" style="float:right;font-weight:700;max-width: 40%;">
        <div style="font-size: 20px;">{{ObjOrderMaster.OutletName}}</div>
        <div style="font-size: 22px;">{{ObjOrderMaster.ArabicOutletName}}</div>
        <div>{{ObjOrderMaster.outletAddress}} {{ObjOrderMaster.AreaName}}</div>
        <div style="font-size: 14px;">{{ObjOrderMaster.ArabicArea}} {{ObjOrderMaster.ArabicAddress}}</div>
        <div *ngIf="ObjCompanyInfo.VATNo != 'N/A'">الرقم الضريبي / VAT No. : {{ObjCompanyInfo.VATNo}}</div>
        <div *ngIf="ObjOrderMaster.OutletPhone != 'N/A'">Tel : {{ObjOrderMaster.OutletPhone}}</div>
      </div>
    
  </div>
  <label *ngIf="ObjCurrency.CountryName == 'Bahrain' || 'Saudi Arabia'" style="width: 100%;font-size: 23px;font-weight: 600;text-align: center;padding: 8px;margin-bottom: 0px;">فاتورة ضريبية / Tax Invoice</label>
  <label *ngIf="ObjCurrency.CountryName == 'Pakistan'" style="width: 100%;font-size: 23px;font-weight: 600;text-align: center;padding: 8px;margin-bottom: 0px;">Invoice</label>
  <div >
    <div style="border: 1px solid black;width: 49%;min-height: 145px;;float:right">
      <div style="padding-left: 5px;padding-top: 5px;width: 60%;display: inline-block;" *ngIf="ObjOrderMaster.OutletName != 'N/A'">
        <div style="font-weight:600;">تفاصيل الفاتورة / Invoice Detail</div>
        <div style="font-size: 14px"><span>رقم الفاتورة / Invoice No. </span> {{ObjOrderMaster.OrderInvoiceNo}}</div>
        <div style="font-size: 14px"><span>تاريخ الفاتوره / Invoice Date: </span> {{ObjOrderMaster.OrderDate}}</div>
        <div style="font-size: 14px"><span>رقم طلب الشراء / PO No: </span> {{ObjOrderMaster.PurchaseOrderNo}}</div>
     </div>
     <div style="float: right;">
      <ngx-qrcode [elementType]="elementType" [value]="QRUrl" [width]=140  cssClass="aclass" errorCorrectionLevel="L"> </ngx-qrcode>
    </div>
    </div>
  <div style="border: 1px solid black;display: inline-block;width: 49%;min-height: 145px;">
      <div style="padding-left: 5px;min-height: 100px;" *ngIf="ObjOrderMaster.OutletName != 'N/A'">
        <div style="font-weight:600;">Customer Detail:</div>
        <div style="font-weight:600;font-size: 14px;">{{ObjOrderMaster.Company}}</div>
        <div style="font-size: 14px">Name: {{ObjOrderMaster.CustomerName}}</div>
        <div style="font-size: 14px">Contact No: {{ObjOrderMaster.PhoneNo}}</div>
        <div title="" *ngIf="ObjCurrency.CountryName != 'Pakistan'">Address: {{ObjOrderMaster.Address}} {{ObjOrderMaster.AdditionalDirection == 'N/A' || ObjOrderMaster.AdditionalDirection == '' ? '' : ' , ' +ObjOrderMaster.AdditionalDirection}}</div>
        <div *ngIf="ObjCurrency.CountryName == 'Pakistan'">Address: {{ObjOrderMaster.AddressPK}}</div>
        <div style="font-size: 14px">VAT No. : {{ObjOrderMaster.VATNo}}</div>
        <div style="font-size: 14px">CR No. : {{ObjOrderMaster.CRNumber}}</div>
        <div style="font-size: 14px">CO ID. : {{ObjOrderMaster.COID}}</div>
     </div>
    </div>
   
    </div>
   
  <br />
  
  <div id="bot">
    <div id="table">
      <table style="border: 1px solid black;width: 100%;table-layout: auto;min-height: 680px !important;">
        <thead>
          <tr style="background: #ededed;">
            <th style="border-right: 1px solid;border-bottom: 1px solid;text-align: center;">Sr. No<br /> رقم</th>
            <th style="border-right: 1px solid;border-bottom: 1px solid;text-align: center;width: 50px !important">ITEM DESCRIPTION<br /> وصف السلعة</th>
            <th style="border-right: 1px solid;border-bottom: 1px solid;text-align: center;">QTY<br />كمية </th>
            <th style="border-right: 1px solid;border-bottom: 1px solid;text-align: center;padding-left: 5px;padding-right: 5px;">Rate<br />سعر</th>
            <th style="border-bottom:1px solid ;text-align: center;padding-left: 5px;padding-right: 5px;">AMOUNT<br />المبلغ</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of ArrOrderDetail; let i = index" style="vertical-align: text-top;">
            <td style="border-right: 1px solid;text-align: center;width: 5% !important;height: 10px;">{{row.SrNo}}</td>
            <td *ngIf="row.AddOnID == '0'" style="border-right: 1px solid;padding-left: 5px;width: 50% !important;height: 10px;">{{row.ProductName}} {{(row.ProductSizeName === null || row.ProductSizeName.trim() === '' || row.ProductSizeName === 'N/A') ? '' : '(' + row.ProductSizeName +')'}}   <span style="text-indent: 10px">{{row.AProductName}}</span> <br /> <span *ngIf="row.SpecialRequest" style="text-indent: 10px"><b>Note: </b>{{row.SpecialRequest}}</span></td>
            <td *ngIf="row.AddOnID != '0'" style="text-indent: 30px;border-right: 1px solid;width:10%;height: 10px;">{{row.AddOnName}}</td>
            <td style="border-right: 1px solid;text-align: center;width:5%;height: 10px;">{{row.Quantity}}</td>
            <td style="border-right: 1px solid;text-align: center;width: 10%;height: 10px;">{{row.Price|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</td>
            <td class="amountcls" style="width:10%;height: 10px;">{{row.OrignalAmount|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</td>
          </tr>
          <tr>
            <td style="border-right: 1px solid;width:5%;"></td>
            <td style="border-right: 1px solid;width:50%;"></td>
            <td style="border-right: 1px solid;width:10%;"></td>
            <td style="border-right: 1px solid;width:10%;"></td>
            <td style="border-right: 1px solid;width:10% ;"></td>
          </tr>
        </tbody>
      </table>

      <table style="border: 1px solid black;width: 100%;border-top: none;">
        <ng-container *ngIf="ArrVoucherData?.length > 0">
          <tr *ngFor="let d of ArrVoucherData;" style="border-bottom: 1px solid;">
            <td style="text-align:right;font-weight: 600">Discount / تخفيض </td>
            <td style="text-align:right !important;font-weight: 600">{{CurrencyCode}}</td>
            <td style="text-align:right !important;font-weight: 600">(-{{d.VoucherAmount|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}})</td>
          </tr>
        </ng-container>
        <tr *ngIf="ObjOrderMaster.TotalDiscount" style="border-bottom: 1px solid;">
          <td style="text-align:right;font-weight: 600">Discount  / <span>تخفيض</span></td>
          <td style="text-align:right !important;font-weight: 600">{{CurrencyCode}}</td>
          <td style="text-align:right !important;font-weight: 600">(-{{ObjOrderMaster.TotalDiscount|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}})</td>
        </tr>
        <tr style="border-bottom: 1px solid;">
          <td style="text-align:right;font-weight: 600">{{SubTotal}} / <span>صافي المجموع</span></td>
          <td style="text-align:right !important;font-weight: 600">{{CurrencyCode}}</td>
          <td style="text-align:right !important;font-weight: 600">{{ObjOrderMaster.TotalAmount|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</td>
        </tr>
        <tr *ngIf="ObjOrderMaster.IsExtraCharge == 1 && ObjOrderMaster.ExtraChargeAmount > 0" style="border-bottom: 1px solid;">
          <td style="text-align:right;font-weight: 600">{{ObjOrderMaster.ExtraChargeDescription}}<span *ngIf="ObjOrderMaster.ExtraChargeDescriptionArabic"> / {{ObjOrderMaster.ExtraChargeDescriptionArabic}}</span></td>
          <td style="text-align:right !important;font-weight: 600">{{CurrencyCode}}</td>
          <td style="text-align:right !important;font-weight: 600">{{ObjOrderMaster.ExtraChargeAmount|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</td>
        </tr>

        <tr *ngIf="ObjOrderMaster.LoyaltyAmount > 0" style="border-bottom: 1px solid;">
          <td style="text-align:right;font-weight: 600">Discount (Reward Points)</td>
          <td style="text-align:right !important;font-weight: 600">{{CurrencyCode}}</td>
          <td style="text-align:right !important;font-weight: 600">(-{{ObjOrderMaster.LoyaltyAmount|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}})</td>
        </tr>
        <tr style="border-bottom: 1px solid;">
          <td style="text-align:right;font-weight: 600">VAT Amount 15%  / <span>الضريبة</span></td>
          <td style="text-align:right !important;font-weight: 600">{{CurrencyCode}}</td>
          <td style="text-align:right !important;font-weight: 600">{{ObjOrderMaster.TotalTaxAmount|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</td>
        </tr>
        <tr *ngIf="ObjOrderMaster.OrderType == 'Delivery' && ObjOrderMaster.DeliveryFee > 0" style="border-bottom: 1px solid;">
          <td style="text-align:right;font-weight: 600">Delivery Fee</td>
          <td style="text-align:right !important;font-weight: 600">{{CurrencyCode}}</td>
          <td style="text-align:right !important;font-weight: 600">{{ObjOrderMaster.DeliveryFee|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</td>
        </tr>
        <tr style="border-bottom: 1px solid;">
          <td style="text-align:right;font-weight: 600">{{NetAmount}} / <span>المجموع النهائي</span></td>
          <td style="text-align:right !important;font-weight: 600">{{CurrencyCode}}</td>
          <td style="text-align:right !important;font-weight: 600">{{ObjOrderMaster.NetAmount|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</td>
        </tr>
      </table>
      <div *ngIf="ObjOrderMaster.Remarks != 'N/A' && ObjOrderMaster.Remarks != ''">
        <br />
        <div style="text-decoration:underline"><strong>Special Instructions</strong> </div>
        <div style="text-indent:5px"></div>
      </div>
      <br />
      <br />
      <!-- <table style=" border: 1px solid black;">
    <tr *ngFor="let row of _PaymentDetails; let i = index">
      <td><strong>{{row.Description}}</strong></td>
      <td style="text-align:right !important">{{CurrencyCode}}</td>
      <td>{{row.CustomerPaid|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</td>
    </tr>
  </table> -->
      <!-- <table *ngIf="ReturnAmount">
        <tr>
          <td style="width: 30% !important;">المبلغ المسترد</td>
          <td style="width: 20% !important;"></td>
          <td style="width: 30% !important;">Cash returned</td>
        </tr>
      </table>
      <table *ngIf="ReturnAmount" style=" border: 1px solid black; border-bottom:2px solid !important">
        <tr>
          <td><strong>Return Amount</strong></td>
          <td style="text-align:right !important">{{CurrencyCode}}</td>
          <td>{{ReturnAmount|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</td>
        </tr>
      </table> -->
    </div>
    <!-- <div id="legalcopy">
      <p class="legal" style="text-align:center;font-size:20px" *ngIf="ObjCompanyInfo.ReceiptMsg != 'N/A' && ObjCompanyInfo.ReceiptMsg != ''">
        <strong class="">{{ObjCompanyInfo.ReceiptMsg}}</strong><br />
      </p>
    </div> -->
    <br />
    <div style="display:flex">
    <span style="width:50% ;"><p style="border-bottom:1px solid;width:80%;padding-bottom:60px;text-align: center;font-weight: 600;font-size: 14px;">Prepared By / أُعدت بواسطة</p></span>
    <span style="width:50% ;text-align: -webkit-center"><p style="border-bottom:1px solid;width:80%;padding-bottom:60px;text-align: center;font-weight: 600;font-size: 14px;">Approved By / تمت الموافقة عليه من قبل</p></span>
    <span style="width:50% ;text-align: -webkit-right;"><p style="border-bottom:1px solid;width:80%;padding-bottom:60px;text-align: center;font-weight: 600;font-size: 14px;">Received By / استلمت من قبل</p></span>
  </div>

  <!-- <div style="display:block ;">
    <div class="col-md-4" style="padding-left:0px; float:left ;"><p style="border-bottom:1px solid;padding-bottom:60px;text-align: center;font-weight: 600;font-size: 12px;">Prepared By / Checked By</p></div>
    <div class="col-md-4" style="float: left;"><p style="border-bottom:1px solid;padding-bottom:60px;text-align: center;font-weight: 600;font-size: 12px;">Approved By</p></div>
    <div class="col-md-4" style="float: left;"><p style="border-bottom:1px solid;padding-bottom:60px;text-align: center;font-weight: 600;font-size: 12px;">Received By</p></div>
  </div> -->

  

    <div id="bot">
      <!--<div id="legalcopy">
        <p class="legal" style="text-align:center;font-size:14px;font-weight: 700;"> تلفون / Tel # : {{ObjOrderMaster.OutletPhone}} , مكان / Address : {{ObjOrderMaster.outletAddress}} {{ObjOrderMaster.AreaName}}  <span *ngIf="ObjOrderMaster.ArabicArea"> / {{ObjOrderMaster.ArabicArea}} {{ObjOrderMaster.ArabicAddress}}</span>
        </p>
      </div>-->
      <!-- <div>
        <p class="legal" style="text-align:center;font-size:12px">Tel # 0557897488 - Address No : {{ObjOrderMaster.outletAddress}} {{ObjOrderMaster.AreaName}}</p>
      </div> -->
      <div id="legalcopy">
        <button class="label clsB1 doNotPrint" style="border:0" (click)="printComponent()">Print order</button>
      </div>
    </div> 
  </div>

</div>
</div>



<!-- <div id="invoice-POS" class="invoice-POS">
  <div id="top" style="display:flex;">
    <div class="logo">
      <img [src]="this.ObjCompanyInfo.LogoImageName" style="width: 18vh;border-radius: 5px;" />
    </div>
  </div>

  <label
    style="border: 1px solid;background: #ededed;width: 100%;font-size: 17px;font-weight: 600;text-align: center;padding: 8px;margin-bottom: 0px;border-bottom: 0px;">Tax Invoice / فاتورة ضريبية    </label>
  <div id="mid" style="border: 1px solid black;">

    <div style="display:flex ;">    
      <label style="flex: 1;border-right: 1px solid;font-weight: 600;margin-bottom: 0px;padding-left: 5px;">From/<br/> من</label>
      <div style="flex: 7;padding-left: 5px;" *ngIf="ObjOrderMaster.OutletName != 'N/A'">
        <div style="font-weight:600;">{{ObjOrderMaster.OutletName}}</div>
        <div style="font-weight:600;">{{ObjOrderMaster.ArabicOutletName}}</div>
        <div style="font-size: 12px;">{{ObjOrderMaster.outletAddress}} {{ObjOrderMaster.AreaName}}</div>
        <div style="font-size: 12px;" *ngIf="ObjCompanyInfo.VATNo != 'N/A'">الرقم الضريبي / VAT No. : {{ObjCompanyInfo.VATNo}}</div>
        <div style="font-size: 12px;" *ngIf="ObjOrderMaster.OutletPhone != 'N/A'">Tel : {{ObjOrderMaster.OutletPhone}}</div>
      </div>
      <div style="flex:2;border-right: 1px solid;border-left: 1px solid;font-weight: 600">
        <div style="font-size: 12px;border-bottom: 1px solid;padding-left: 5px;">Invoice No.<br/>رقم الفاتورة</div>
        <div style="font-size: 12px;padding-left: 5px;">Invoice Date<br/>تاريخ الفاتورة</div>
      </div>
      <div style="flex:2;">
        <div style="font-size: 12px;border-bottom: 1px solid;padding-left: 5px;padding-top: 10px;padding-bottom: 10px;">{{ObjOrderMaster.OrderInvoiceNo}}</div>
        <div style="font-size: 12px;padding-left: 5px;;padding-top: 10px;padding-bottom: 10px;">{{ObjOrderMaster.OrderDate}}</div>
      </div>
    </div>
    <div style="display:flex ;border-top: 1px solid;">
      <label style="flex: 1;border-right: 1px solid;font-weight: 600;margin-bottom: 0px;padding-left: 5px;">To/<br/> :إلى</label>
      <div style="flex: 7;padding-left: 5px;">
        <div style="font-weight:600;">{{ObjOrderMaster.Company}}</div>
        <div>{{ObjOrderMaster.CustomerName}}</div>
        <div *ngIf="ObjCurrency.CountryName != 'Pakistan'">Address: {{ObjOrderMaster.Address}} {{ObjOrderMaster.AdditionalDirection == 'N/A' || ObjOrderMaster.AdditionalDirection == '' ? '' : ' , ' +ObjOrderMaster.AdditionalDirection}}</div>
        <div *ngIf="ObjCurrency.CountryName == 'Pakistan'">Address: {{ObjOrderMaster.AddressPK}}</div>
        <div> الرقم الضريبي / VAT No. : {{ObjOrderMaster.VATNo}}</div>
        <div>Tel : {{ObjOrderMaster.PhoneNo}}</div>
      </div>
      <div style="flex:2;border-right: 1px solid;border-left: 1px solid;font-weight: 600">
        <div style="font-size: 12px;"></div>
        <div style="font-size: 12px;"></div>
        <div style="font-size: 12px;"></div>
      </div>
      <div style="flex:2;font-weight: 600">
        <div style="font-size: 12px;"></div>
        <div style="font-size: 12px;"></div>
        <div style="font-size: 12px;"></div>
      </div>
    </div>
  </div>
  <br />
  <br />
  <div id="bot">
    <div id="table">
      <table style="border: 1px solid black;">
        <thead>
          <tr style="background: #ededed ;">
            <th style="border-right: 1px solid;border-bottom: 1px solid;text-align: center;">ITEM DESCRIPTION<br/> وصف السلعة</th>
            <th style="border-right: 1px solid;border-bottom: 1px solid;text-align: center;">QTY<br/>كمية </th>
            <th style="border-bottom:1px solid ;text-align: center;">AMOUNT<br/>المبلغ</th>
          </tr>
        </thead>
        <tbody >
          <tr *ngFor="let row of ArrOrderDetail; let i = index" >
            <td *ngIf="row.AddOnID == '0'" style="border-right: 1px solid;padding-left: 5px;">{{row.ProductName}} {{row.ProductSizeName == 'N/A' ? '' : '(' + row.ProductSizeName +')'}}  <br /> <span style="text-indent: 10px">{{row.AProductName}}</span> <br /> <span *ngIf="row.SpecialRequest" style="text-indent: 10px"><b>Note: </b>{{row.SpecialRequest}}</span></td>
            <td *ngIf="row.AddOnID != '0'" style="text-indent: 30px;border-right: 1px solid;">{{row.AddOnName}}</td>
            <td style="border-right: 1px solid;text-align: center;">{{row.Quantity}}</td>
            <td class="amountcls">{{row.OrignalAmount|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="1" style="text-align:right;border-right: 1px solid;">Net Total  <br><span>المجموع النهائي</span></td>
            <td style="text-align:right !important">{{CurrencyCode}}</td>
            <td style="text-align:right !important">{{ObjOrderMaster.TotalAmount|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</td>
          </tr>
          <ng-container *ngIf="ArrVoucherData?.length > 0">
            <tr *ngFor="let d of ArrVoucherData;">
              <td colspan="1" style="text-align:right;border-right: 1px solid;">Discount /تخفيض :</td>
              <td style="text-align:right !important">{{CurrencyCode}}</td>
              <td style="text-align:right !important">(-{{d.VoucherAmount|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}})</td>
            </tr>
          </ng-container>
          <tr *ngIf="ObjOrderMaster.TotalDiscount">
            <td colspan="1" style="text-align:right;border-right: 1px solid;">Discount <br> <span>تخفيض</span></td>
            <td style="text-align:right !important">{{CurrencyCode}}</td>
            <td style="text-align:right !important">(-{{ObjOrderMaster.TotalDiscount|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}})</td>
          </tr>
          <tr *ngIf="ObjOrderMaster.LoyaltyAmount > 0">
            <td colspan="1" style="text-align:right;border-right: 1px solid;">Discount (Reward Points)</td>
            <td style="text-align:right !important">{{CurrencyCode}}</td>
            <td style="text-align:right !important">(-{{ObjOrderMaster.LoyaltyAmount|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}})</td>
          </tr>
          <tr>
            <td colspan="1" style="text-align:right;border-right: 1px solid;">VAT 15% <br> <span>الضريبة</span></td>
            <td style="text-align:right !important">{{CurrencyCode}}</td>
            <td style="text-align:right !important">{{ObjOrderMaster.TotalTaxAmount|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</td>
          </tr>
          <tr *ngIf="ObjOrderMaster.OrderType == 'Delivery' && ObjOrderMaster.DeliveryFee > 0">
            <td colspan="1" style="text-align:right;border-right: 1px solid;">Delivery Fee</td>
            <td style="text-align:right !important">{{CurrencyCode}}</td>
            <td style="text-align:right !important">{{ObjOrderMaster.DeliveryFee|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</td>
          </tr>
          <tr>
            <td colspan="1" style="text-align:right;border-right: 1px solid;">Total <br><span>المجموع يشمل ضريبة القيمة المضافة</span></td>
            <td style="text-align:right !important">{{CurrencyCode}}</td>
            <td style="text-align:right !important">{{ObjOrderMaster.NetAmount|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</td>
          </tr>
        </tfoot>
      </table>
      <div *ngIf="ObjOrderMaster.Remarks != 'N/A' && ObjOrderMaster.Remarks != ''">
        <br />
        <div style="text-decoration:underline"><strong>Special Instructions</strong> </div>
        <div style="text-indent:5px"></div>
      </div>
      <br />
      <table >     
        <tr >
          <td style="width: 10% !important;">طريقةالدفع</td>
          <td style="width: 65% !important;"></td>
          <td style="width: 25% !important;" >Paid By</td>
        </tr>
      </table>
      <table style=" border: 1px solid black;">
        <tr *ngFor="let row of _PaymentDetails; let i = index">
          <td><strong>{{row.Description}}</strong></td>
          <td style="text-align:right !important">{{CurrencyCode}}</td>
          <td>{{row.CustomerPaid|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</td>
        </tr>
      </table>
      <table  *ngIf="ReturnAmount">     
        <tr >
          <td style="width: 30% !important;">المبلغ المسترد</td>
          <td style="width: 20% !important;"></td>
          <td style="width: 30% !important;" >Cash returned</td>
        </tr>
      </table>
      <table  *ngIf="ReturnAmount" style=" border: 1px solid black; border-bottom:2px solid !important">
      <tr>
        <td><strong>Return Amount</strong></td>
        <td style="text-align:right !important">{{CurrencyCode}}</td>
        <td>{{ReturnAmount|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</td> </tr>
        </table>
    </div>
    <div id="legalcopy">
      <p class="legal" style="text-align:center;font-size:20px" *ngIf="ObjCompanyInfo.ReceiptMsg != 'N/A' && ObjCompanyInfo.ReceiptMsg != ''">
        <strong class="">{{ObjCompanyInfo.ReceiptMsg}}</strong><br />
      </p>
    </div>

    <div id="mid" style="border: 1px solid black;border-top:none">
      <div style="display:flex ;border-top: 1px solid;">
        <div style="flex: 1;border-right: 1px solid;">
          <div class="info">
            <ngx-qrcode [elementType]="elementType" [value]="QRUrl" cssClass="aclass" errorCorrectionLevel="L">
            </ngx-qrcode>
          </div>
        </div>
        <div style="flex: 2;border-right: 1px solid;">
          <p style="margin:0px ;font-size: 12px;padding: 5px;">Customer's Seal and Signature</p>
        </div>
        <div style="flex: 2;">
          <p style="margin:0px ;font-size: 12px;;text-align: right;padding: 5px;">{{ObjOrderMaster.OutletName}}</p>
        </div>
      </div>
    </div>
    <div id="bot">
      <div id="legalcopy">
        <p class="legal" style="text-align:center;font-size:12px">This is Computer Generated invoice no signature is required. / هذه فاتورة تم إنشاؤها بواسطة الكمبيوتر ولا يلزم التوقيع عليها</p>
      </div>
      <div id="legalcopy">
        <button class="label clsB1 doNotPrint" style="border:0" (click)="printComponent()">Print order</button>
      </div>
    </div> 
  </div>

</div> -->


  
  <!-- <div id="mid" style="border: 1px solid black;">
    <div style="display:flex ;">
      <label
        style="flex: 1;background: #ededed;font-size: 15px;font-weight: 600;margin-bottom: 0px;padding-top: 5px;padding-bottom: 5px;padding-left: 5px;border-right: 1px solid;text-align: center;">S.No</label>
      <label
        style="flex: 5;background: #ededed;font-size: 15px;font-weight: 600;margin-bottom: 0px;padding-top: 5px;padding-bottom: 5px;border-right: 1px solid;text-align: center;">ITEM
        DESCRIPTION</label>
      <label
        style="flex: 1;background: #ededed;font-size: 15px;font-weight: 600;margin-bottom: 0px;padding-top: 5px;padding-bottom: 5px;border-right: 1px solid;text-align: center;">QTY</label>
      <label
        style="flex: 1;background: #ededed;font-size: 15px;font-weight: 600;margin-bottom: 0px;padding-top: 5px;padding-bottom: 5px;border-right: 1px solid;text-align: center;">UNIT
        RATE</label>
      <label
        style="flex: 1;background: #ededed;font-size: 15px;font-weight: 600;margin-bottom: 0px;padding-top: 5px;padding-bottom: 5px;text-align: center;">TOTAL
        AMOUNT</label>
    </div>
    
    <div style="display:flex ;border-top: 1px solid;height: 310px;">
      <label style="flex: 1;border-right: 1px solid;text-align: center;margin-bottom: 0px;padding-left: 5px;">1</label>
      <label style="flex: 5;border-right: 1px solid;margin-bottom: 0px;">On Site Support & Services</label>
      <label style="flex: 1;border-right: 1px solid;margin-bottom: 0px;text-align: right;">1</label>
      <label style="flex: 1;border-right: 1px solid;margin-bottom: 0px;text-align: right;">608.70</label>
      <label style="flex: 1;margin-bottom: 0px;text-align: right;">608.70</label>
    </div>

    <div style="display:flex ;border-top: 1px solid;">
      <div style="flex: 8;border-right: 1px solid;">
        <p style="margin:0px ;font-size: 15px;text-align: right;font-weight: 600;">Total Excluding VAT</p>
        <p style="margin:0px ;font-size: 15px;text-align: right;font-weight: 600;">VAT(15%)</p>
      </div>
      <div style="flex: 1;">
        <p style="margin:0px ;font-size: 15px;text-align: right;;font-weight: 600">608.70</p>
        <p style="margin:0px ;font-size: 15px;text-align: right;;font-weight: 600">91.30</p>
      </div>
    </div>
    <div style="display:flex ;border-top: 1px solid;">
      <div style="flex: 8;border-right: 1px solid;">
        <p style="margin:0px ;font-size: 15px;text-align: right;font-weight: 600;">Total Including VAT</p>
      </div>
      <div style="flex: 1;">
        <p style="margin:0px ;font-size: 15px;text-align: right;;font-weight: 600">950.70</p>
      </div>
    </div>
  </div>
  <br />
  <div id="mid" style="border: 1px solid black;border-top:none">
    <div style="display:flex ;border-top: 1px solid;">
      <div style="flex: 1;border-right: 1px solid;">
        <div class="info">
          <ngx-qrcode [elementType]="elementType" [value]="QRUrl" cssClass="aclass" errorCorrectionLevel="L">
          </ngx-qrcode>
        </div>
      </div>
      <div style="flex: 2;border-right: 1px solid;">
        <p style="margin:0px ;font-size: 12px;">Customer's Seal and Signature</p>
      </div>
      <div style="flex: 2;">
        <p style="margin:0px ;font-size: 12px;;text-align: right">for M.I.A Arabia Company </p>
      </div>
    </div>
  </div>
  <div id="bot">
    <div id="legalcopy">
      <p class="legal" style="text-align:center;font-size:12px">This is Computer Generated invoice no signature is required</p>
    </div>
    <div id="legalcopy">
      <button class="label clsB1 doNotPrint" style="border:0" (click)="printComponent()">Print order</button>
    </div>
  </div> -->




<!-- <div id="invoice-POS" class="invoice-POS">
    <div id="top">
      <div class="logo" [ngStyle]="{'background': 'url(' + this.ObjCompanyInfo.LogoImageName + ')','height':'140px'}"></div>
    </div>
    <div id="mid">
      <div class="info" *ngIf="ObjOrderMaster.OutletName != 'N/A'">
        <div class="hd">{{ObjOrderMaster.OutletName}}</div>
        <div class="hd" style="text-align: center;">{{ObjOrderMaster.ArabicOutletName}}</div>
        <div class="mdatal">{{ObjOrderMaster.outletAddress}}</div>
        <div class="mdatal"></div>
        <div class="mdatal">{{ObjOrderMaster.AreaName}}</div>
        <div class="mdatal" *ngIf="ObjCompanyInfo.VATNo != 'N/A'">رقم الرضيبة / VAT No. : {{ObjCompanyInfo.VATNo}}</div>
        <div class="mdatal" *ngIf="ObjOrderMaster.OutletPhone != 'N/A'">Tel : {{ObjOrderMaster.OutletPhone}}</div>
      </div>
      <div class="info" style="border-top: 2px dotted #0e0d0d;border-bottom: 2px dotted #0e0d0d">
        <div class="dlvy">فاتورة ضريبية مبسطة</div>
        <div class="dlvy">Simplified Tax Invoice</div>
      </div>
      <div class="info" style="border-top: 2px dotted #0e0d0d;border-bottom: 2px dotted #0e0d0d">
        <div class="dlvy" *ngIf="ObjOrderMaster.OrderNo" > Order No.: {{ObjOrderMaster.OrderNo}}   رقم الطلب</div>
      </div>
      <div class="info" style="border-top: 2px dotted #0e0d0d;border-bottom: 2px dotted #0e0d0d">
        <div class="dlvy" *ngIf="ObjOrderMaster.OrderType != 'Dine In'">{{ObjOrderMaster.OrderType}}</div>
        <div *ngIf="ObjOrderMaster.CustomerName != 'N/A' && ObjOrderMaster.OrderType != 'Dine In'">
          <div class="mdatal" style="font-weight: 700">{{ObjOrderMaster.CustomerName}}
        </div>
          <div class="mdatal">{{ObjOrderMaster.PhoneNo}}</div>
          <div class="mdatal" *ngIf="ObjCurrency.CountryName != 'Pakistan'">Address: {{ObjOrderMaster.Address}} {{ObjOrderMaster.AdditionalDirection == 'N/A' || ObjOrderMaster.AdditionalDirection == '' ? '' : ' , ' +ObjOrderMaster.AdditionalDirection}}</div>
          <div style="border-bottom: 2px dotted #0e0d0d;" class="mdatal" *ngIf="ObjCurrency.CountryName == 'Pakistan'">Address: {{ObjOrderMaster.AddressPK}}</div>
        </div>
        <div class="info" *ngIf="ObjOrderMaster.OrderType == 'Dine In' && ObjOrderMaster.TableNumber != 0">
          <div class="dlvy">Dine In</div>
          <div class="dlvy">Table No.</div>
          <div class="mdatal" style="font-weight: 700">{{ObjOrderMaster.TableNumber}}</div>
        </div>
      </div>
  
      <div style="border-bottom: 2px dotted #0e0d0d">
        <div class="mdatal">Tran# / رقم الفاتورة :&nbsp; {{ObjOrderMaster.OrderInvoiceNo}} &nbsp;&nbsp; <span *ngIf="ObjOrderMaster.OrderType == 'Dine In' && ObjOrderMaster.TableNumber != 0">Cust# :&nbsp; 001</span></div>
        <div class="mdatal">Order Date :&nbsp; {{ObjOrderMaster.OrderDate}} , {{ObjOrderMaster.OrderTime}}</div>
        <div class="mdatal" *ngIf="ObjOrderMaster.Cashier != 'N/A'">Cashier :&nbsp; {{ObjOrderMaster.Cashier}}</div>
      </div>
  
    </div>
  
    <div id="bot">
      <div id="table">
        <table>
          <thead>
            <tr>
              <th>Description</th>
              <th>Qty</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let row of ArrOrderDetail; let i = index" [ngClass]="row.AddOnID != '0' ? 'borderDoted' : 'borderDoted'">
              <td *ngIf="row.AddOnID == '0'">{{row.ProductName}} {{row.ProductSizeName == 'N/A' ? '' : '(' + row.ProductSizeName +')'}}  <br /> <span style="text-indent: 10px">{{row.AProductName}}</span> <br /> <span *ngIf="row.SpecialRequest" style="text-indent: 10px"><b>Note: </b>{{row.SpecialRequest}}</span></td>
              <td *ngIf="row.AddOnID != '0'" style="text-indent: 10px">{{row.AddOnName}}</td>
              <td>{{row.Quantity}}</td>
              <td class="amountcls">{{row.OrignalAmount|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colspan="1">Net Total  <br><span>المجموع النهائي</span></td>
              <td style="text-align:right !important">{{CurrencyCode}}</td>
              <td style="text-align:right !important">{{ObjOrderMaster.TotalAmount|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</td>
            </tr>
            <ng-container *ngIf="ArrVoucherData?.length > 0">
              <tr *ngFor="let d of ArrVoucherData;">
                <td colspan="1">
                  Discount /تخفيض :
                </td>
                <td style="text-align:right !important">{{CurrencyCode}}</td>
                <td style="text-align:right !important">(-{{d.VoucherAmount|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}})</td>
              </tr>
            </ng-container>
            <tr *ngIf="ObjOrderMaster.TotalDiscount">
              <td colspan="1">Discount <br> <span>تخفيض</span></td>
              <td style="text-align:right !important">{{CurrencyCode}}</td>
              <td style="text-align:right !important">(-{{ObjOrderMaster.TotalDiscount|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}})</td>
            </tr>
            <tr *ngIf="ObjOrderMaster.LoyaltyAmount > 0">
              <td colspan="1">Discount (Reward Points)</td>
              <td style="text-align:right !important">{{CurrencyCode}}</td>
              <td style="text-align:right !important">(-{{ObjOrderMaster.LoyaltyAmount|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}})</td>
            </tr>
            <tr>
              <td colspan="1">VAT 15% <br> <span>الضريبة</span></td>
              <td style="text-align:right !important">{{CurrencyCode}}</td>
              <td style="text-align:right !important">{{ObjOrderMaster.TotalTaxAmount|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</td>
            </tr>
            <tr *ngIf="ObjOrderMaster.OrderType == 'Delivery' && ObjOrderMaster.DeliveryFee > 0">
              <td colspan="1">Delivery Fee</td>
              <td style="text-align:right !important">{{CurrencyCode}}</td>
              <td style="text-align:right !important">{{ObjOrderMaster.DeliveryFee|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</td>
            </tr>
            <tr>
              <td colspan="1">Total <br><span>المجموع يشمل ضريبة القيمة المضافة</span></td>
              <td style="text-align:right !important">{{CurrencyCode}}</td>
              <td style="text-align:right !important">{{ObjOrderMaster.NetAmount|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</td>
            </tr>
          </tfoot>
        </table>
        <div *ngIf="ObjOrderMaster.Remarks != 'N/A' && ObjOrderMaster.Remarks != ''">
          <br />
          <div style="text-decoration:underline"><strong>Special Instructions</strong> </div>
          <div style="text-indent:5px"></div>
        </div>
        <br />
        <table >     
          <tr >
            <td style="width: 10% !important;">طريقةالدفع</td>
            <td style="width: 65% !important;"></td>
            <td style="width: 25% !important;" >Paid By</td>
          </tr>
        </table>
        <table style=" border: 1px solid black; border-bottom:2px solid !important">
          <tr *ngFor="let row of _PaymentDetails; let i = index">
            <td><strong>{{row.Description}}</strong></td>
            <td style="text-align:right !important">{{CurrencyCode}}</td>
            <td>{{row.CustomerPaid|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</td>
          </tr>
        </table>
        <table  *ngIf="ReturnAmount">     
          <tr >
            <td style="width: 30% !important;">المبلغ المسترد</td>
            <td style="width: 20% !important;"></td>
            <td style="width: 30% !important;" >Cash returned</td>
          </tr>
        </table>
        <table  *ngIf="ReturnAmount" style=" border: 1px solid black; border-bottom:2px solid !important">
        <tr>
          <td><strong>Return Amount</strong></td>
          <td style="text-align:right !important">{{CurrencyCode}}</td>
          <td>{{ReturnAmount|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</td> </tr>
          </table>
      </div>
      <div id="legalcopy">
        <p class="legal" style="text-align:center;font-size:20px" *ngIf="ObjCompanyInfo.ReceiptMsg != 'N/A' && ObjCompanyInfo.ReceiptMsg != ''">
          <strong class="">{{ObjCompanyInfo.ReceiptMsg}}</strong><br />
        </p>
      </div>
      <div class="info">
        <ngx-qrcode [elementType]="elementType"
                    [value]="QRUrl"
                    cssClass="aclass"
                    errorCorrectionLevel="L">
        </ngx-qrcode>
      </div>
      <div id="legalcopy">
        <button class="label clsB1 doNotPrint" style="border:0" (click)="printComponent()">Print order</button>
      </div>
    </div>
  </div> -->
