<div class="login-container" style="overflow: hidden !important;">
  <img class="align-center" src="./assets/images/logo.png" alt="Global Pos Logo" style="width: 60%; 
  padding-bottom: 40px; text-align: center;">

  <div style="margin-top: -20px;">
    <label for="bLocation" class="label">Language</label>
    <nb-select fullWidth (selectedChange)="changeLocation($event)" [(ngModel)]="DefaultLanguage" name="bLocation" style="width: 100%;">
      <!-- <nb-option value="ar">Arabic (عربي)</nb-option> -->
      <nb-option value="en">English</nb-option>
    </nb-select>
  </div>

  <br />

  <div class="form-group">
    <label for="username" class="label">User Name</label>
    <input type="text" [(ngModel)]="objUser.Name" placeholder="User ID" (keydown.enter)="Login()" class="form-control" />
    <p class="caption status-danger" *ngIf="ShowlblUserName">
      User Name is required!
    </p>
  </div>

  <div class="form-group">
    <label for="password" class="label">Password</label>
    <input type="password" [(ngModel)]="objUser.Password" (keydown.enter)="Login()" placeholder="Password" class="form-control" />
    <p class="caption status-danger" *ngIf="ShowlblPassword">
      Password is required!
    </p>
  </div>

  <p class="caption status-danger" *ngIf="showlblInvalid">
    User Name or Password is Invalid!
  </p>

  <p class="caption status-danger" *ngIf="showlblSubsEndDate">
    Your subscription has expired, kindly contact the support team!
  </p>

  <div class="form-group">
    <button #loginID [disabled]="loading" type="button" class="btnlogin btn btn-info" autofocus (click)="Login()">Login</button>
  </div>

  <br />

  <p style="text-align: center; font-weight: bold;">
    {{VersionNo}}
  </p>
</div>

 