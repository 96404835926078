import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'mindiff' })

export class GetMinutesDifference implements PipeTransform {

  transform(PrepPerIn: any, RecordUpdatedDate: any): any {
   
    if (PrepPerIn == null || PrepPerIn == undefined || PrepPerIn <= 0)
      return 0;


    let RemainingMinutes:any=0;

    var CurrentDate: any = new Date();

    //CreatedDate = new Date('2021-02-04 08:00:00.000');
    RecordUpdatedDate = new Date(RecordUpdatedDate);

  

    let diffMs = CurrentDate - RecordUpdatedDate; // milliseconds
    let diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
    let diffDays = Math.floor(diffMs / 86400000); // days
    let diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours

    if (diffDays > 0 || diffHrs > 2)
      return 0;

    if (diffMins <= PrepPerIn) {
      RemainingMinutes = PrepPerIn - diffMins;
    }



    return RemainingMinutes;
  }

}
