
<nb-card>

    <nb-card-body>
      <div class="row parent1">
        <div class="col-md-4">
          <div class="child1">
            Update Customer and Order Type
          </div>
        </div>
        <div class="col-md-6"></div>
        <div class="col-md-2">
          <img src="./assets/images/lookupclose.png" (click)="dialogRef.close()" style="float: right; width: 25px;cursor: pointer;">
  
        </div>
      </div>
      <hr />
      <div class="row">
        <!-- <div class="col-md-3 col-sm-12">
          <div class="form-group">
            <label for="bTransaction" class="label">{{ReturnInvoicNo}}</label>
            <input nbInput fullWidth name="bTransaction" [(ngModel)]="trxNumber" placeholder="Return Invoice No" style="width:86%" readonly>
            &nbsp;&nbsp;
            <i class="fa fa-search" (click)="openDialogSaleReturn()"></i>
          </div>
        </div>
        <div class="col-md-3 col-sm-12">
          <div class="form-group">
            <label for="bod" class="label">{{ReturnDate}}</label>
            <input nbInput fullWidth placeholder="Return Date" [(ngModel)]="ObjSaleReturnMaster.ReturnDate" name="bod" [nbDatepicker]="formpicker" autocomplete="off">
            <nb-datepicker #formpicker></nb-datepicker><p class="caption status-danger" *ngIf="RetDate_Mandatory">{{PleaseSelectDate}}</p>&nbsp;&nbsp;&nbsp;
          </div>
        </div> -->
  
        <div class="col-md-4 col-sm-12">
          <div class="form-group">
            <label for="bPOInvoiceNo" class="label">{{OrderNo}}</label>
            <input nbInput fullWidth name="bPOInvoiceNo" placeholder="Order No" [(ngModel)]="ObjSaleReturnMaster.OrderNo" style="width:90%" readonly>
            &nbsp;
            <i class="fa fa-search" (click)="openDialogOrderkUp()"></i>
            <p class="caption status-danger" *ngIf="OrderId_Mandatory">{{Selectorderno}}</p>
          </div>
        </div>
  
  
        <div class="col-md-2 col-sm-12">
          <div class="form-group">
            <label for="bOrderDate" class="label">{{OrderDateTime}}</label>
            <input nbInput fullWidth name="bOrderDate" [(ngModel)]="ObjSaleReturnMaster.OrderDate" placeholder="Order Date & Time" readonly>
          </div>
        </div>
        <!-- <div class="col-md-4 col-sm-12">
            <div class="form-group">
              <label for="bOrderDate" class="label">{{Status}}</label>
              <input nbInput fullWidth name="bOrderDate" [(ngModel)]="ObjSaleReturnMaster.Status" readonly>
            </div>
          </div> -->
  
        <!-- <div class="col-md-2 col-sm-12">
          <div class="form-group">
            <label for="bOrderType" class="label">{{OrderType}}</label>
            <input nbInput fullWidth name="bOrderType" [(ngModel)]="ObjSaleReturnMaster.OrderType" placeholder="Order Type" readonly>
          </div>
        </div>
  
        <div class="col-md-2 col-sm-12">
          <div class="form-group">
            <label for="bPayType" class="label">{{PayType}}</label>
            <input nbInput fullWidth name="bPayType" [(ngModel)]="ObjSaleReturnMaster.PayType" placeholder="Pay Type" readonly>
          </div>
        </div> -->
  
        <div class="col-md-2 col-sm-12">
          <div class="form-group">
            <label for="bOrderAmount" class="label">{{OrderAmount}}</label>
            <input nbInput fullWidth name="bOrderAmount" value="{{getdecimalAmount(ObjSaleReturnMaster.OrderAmount)}}" placeholder="Order Amount" readonly>
          </div>
        </div>
    
        <!-- <div class="col-md-2 col-sm-12">
          <div class="form-group">
            <label for="bPhoneNo" class="label">{{PhoneNo}}</label>
            <input nbInput fullWidth name="bPhoneNo" [(ngModel)]="ObjSaleReturnMaster.PhoneNo" placeholder="Phone No" readonly>
          </div>
        </div> -->
  
        <div class="col-md-2 col-sm-12">
          <div class="form-group">
            <label for="bCashier" class="label">{{Cashier}}</label>
            <input nbInput fullWidth name="bCashier" [(ngModel)]="ObjSaleReturnMaster.Cashier" placeholder="Cashier" readonly>
          </div>
        </div>
        <div class="col-md-2 col-sm-12">
            <div class="form-group">
              <label for="bCashier" class="label">{{Status}}</label>
              <input nbInput fullWidth name="bCashier" [(ngModel)]="ObjSaleReturnMaster.OrderPerpStatus" placeholder="Cashier" readonly>
            </div>
          </div>
  
        <!-- <div class="col-md-6 col-sm-12">
          <div class="form-group">
            <label for="bReturnReason" class="label">{{ReturnReason}}</label>
            <nb-select fullWidth id="IdRejectDD" [(selected)]="ObjSaleReturnMaster.Reason">
              <nb-option *ngFor="let _data of ddlRejectReasons" [value]="_data.RejectReasonID">{{_data.RejectReason}}</nb-option>
            </nb-select>
          </div>
        </div>
  
        <div class="col-md-6 col-sm-12">
          <div class="form-group">
            <label for="bDescription" class="label">{{Description}}</label>
            <input nbInput fullWidth name="bDescription" [(ngModel)]="ObjSaleReturnMaster.Remarks" placeholder="Description">
          </div>
        </div> -->
  
  
  
        <!-- <div class="col-md-3 col-sm-12">
          <nb-card-body class="example-items-rows">
            <span>
  
              <nb-toggle status="success" [(ngModel)]="Pram_PaymentMode_Refund" (checkedChange)="Changetg1($event)">
                {{RefundPaymentType}}
              </nb-toggle>&nbsp;
            </span>
          </nb-card-body>
        </div>
  
        <div class="col-md-3 col-sm-12">
          <nb-card-body class="example-items-rows">
            <span>
              <nb-toggle status="success" [(ngModel)]="Pram_PaymentMode_CIssNote" (checkedChange)="Changetg2($event)">
                {{Creditissuenote}}
              </nb-toggle>&nbsp;
            </span>
          </nb-card-body>
        </div>
    
        <div class="col-md-6 col-sm-12">
          <div class="form-group">
            <label for="bsritems" class="label">{{Items}}</label>
            <input nbInput fullWidth name="bsritems" placeholder="Items" style="width:92%" readonly>
            &nbsp;&nbsp;
            <i class="fa fa-search" (click)="openDialogItem()"></i>
            <p class="caption status-danger" *ngIf="Items_Mandatory">{{Selectatleastoneitem}}</p>
          </div>
        </div> -->
      </div>
<!-- <br/> -->
      <!-- <div class="child1">
        Update Customer
      </div> -->
  <hr>
  <div class="row">
  <div class="col-md-4" >
    <div class="form-group">
      <label for="bCustomer" class="label">{{Customer}}</label>
      <input nbInput fullWidth name="bCustomer" [(ngModel)]="ObjSaleReturnMaster.Customer" placeholder="Customer" style="width:90%" readonly>
      &nbsp;
      <i class="fa fa-search" (click)="openDialogCustomer()"></i>
      <p class="caption status-danger" *ngIf="Customer_Mandatory">{{SelectCustomer}}</p>
    </div>
    </div>
    <div class="col-md-4" >
        <div class="form-group">
          <label for="UserGroup" class="label">{{SalesType}}</label>
          <nb-select fullWidth [selected]="ObjSaleReturnMaster.OrderTypeID" (selectedChange)="onChangeType($event)">         
            <nb-option *ngFor="let loc of listOrderTypes" [value]="loc.ID">{{loc.Type}}</nb-option>
          </nb-select>   
            <p class="caption status-danger" *ngIf="OrderType_Mandatory" >please select Sales Type!</p>
        </div>
      </div>
      <div class="col-md-4 col-sm-12" *ngIf="ObjSaleReturnMaster.OrderTypeID==1 && ObjSaleReturnMaster.noOfCustomers !='' ">
        <div class="form-group">
          <label  class="label">{{NoOfCustomer}}</label>
          <input nbInput fullWidth [(ngModel)]="ObjSaleReturnMaster.noOfCustomers" readonly>
          <i class="far fa-keyboard" (click)="NumberKeypadPopup()" style="cursor: pointer;cursor: pointer;padding-right: 10px;position: relative;float: right;margin-top: -30px;font-size: 22px;"></i>
        </div>
      </div>
      </div>
      <!-- <div class="col-md-7"> -->
<!-- <br/>
        <label  class="label">Payment Details</label>
        <div class="table-responsive " style="height: auto;max-height: 235px;min-height: 200px;margin-bottom: 30px;">
          <table class="table table-hover">
            <thead>
              <tr>
                <th class="label">{{Action}}</th>
                <th class="label" style="white-space: nowrap;">{{PayMethod}}</th>
                <th class="label">{{Amount}}</th>
              </tr>
            </thead>

            <tbody>
              <tr *ngFor="let row of lstPaymentDetail; let i = index; trackBy: trackByFn" [attr.data-index]="i" style="font-size: 12px;">
                <td>
                    <div class="col-md-1" title="Void Item" style="padding:0px;margin-top:-4px">
                        <i (click)="removeitem(i,row)" class="fa fa-trash-alt" style="font-size: 15px;margin-top: 8px;cursor: pointer;"></i>
                      </div>
                  </td>
                <td>
                    <nb-select fullWidth [selected]="row.PaymentCode" (selectedChange)="onChangePaymentType($event)">         
                        <nb-option *ngFor="let obj of lstPaymentMode" [value]="obj.Code">{{obj.PaymentName}}</nb-option>
                      </nb-select>   
                </td>
                <td>
                  <input type="text" id="textbox{{i}}" tabindex="-1" [ngModel]="row.LocalCustomerPaid | number:'1.2-2'" nbInput fullWidth  style="background-color: #f7f9fc;" >
                </td>
              </tr>

            </tbody>
          </table>
        </div> -->
      <!-- </div> -->
<!--   
      <div class="row">
  
        <div class="col-md-6 col-sm-12" style="padding-right: 0px;">
          <hr class="horz" />
          <div class="vl"></div>
          <div id="rowdiv" class="row" *ngIf="Pram_PaymentMode_Refund">
            <div *ngFor="let objPayModes of lstPaymentMode" [ngClass]="[rdPaymentMethod == objPayModes.PaymentName ? 'innerbox_selected' : 'inner_box']" (click)="PaymentTypeChangeFn(objPayModes)">
              <img class="icon_style" loading="lazy" style="width: 54%;height: 38%;" [src]="objPayModes.Image!= null ? objPayModes.Image : moneyImg" />
  
              <p [ngClass]="[rdPaymentMethod == objPayModes.PaymentName ? 'iconheading_selected' : 'icon_heading']">{{objPayModes.PaymentName}}</p>
            </div>
          </div>
        </div>
  
        <div class="form-group col-lg-6 col-md-6 col-xs-6 col-sm-6">
          <div class="row">
            <div class="cls1 col-lg-1 col-md-1 col-xs-1 col-sm-1">
              <label class="label">{{Action}}</label>
            </div>
            <div class="cls2 col-lg-4 col-md-4 col-xs-4 col-sm-4">
              <label class="label">{{ItemDescription}}</label>
            </div>
            <div class="cls1 col-lg-1 col-md-1 col-xs-1 col-sm-1">
              <label class="label">{{Qty}}</label>
            </div>
            <div class="cls1 col-lg-2 col-md-2 col-xs-2 col-sm-2">
              <label class="label">{{ReturnQty}}</label>
            </div>
            <div class="cls3 col-lg-2 col-md-2 col-xs-2 col-sm-2">
              <label class="label">{{Price}}</label>
            </div>
            <div class="cls3 col-lg-2 col-md-2 col-xs-2 col-sm-2">
              <label class="label">{{Amount}}</label>
            </div>
  
          </div>
          <hr class="hrdivider" />
          <div *ngFor="let dod of ArrSaleReturnDetailSaving;let i = index;" [ngClass]="[dod.DelFlag == 1 ? 'padd_btm_background row' : 'row']">
  
            <div class="cls1 col-lg-1 col-md-1 col-xs-1 col-sm-1">
              <input type="image" *ngIf="dod.AddOnID == 0" width="20" height="18" (click)="deleteDetailData(i)" src="assets/images/delete.png">
            </div>
            <div class="cls2 col-lg-4 col-md-4 col-xs-4 col-sm-4" *ngIf="dod.AddOnID == 0">
              <label class="label">{{ cultre == 'ar' ? dod.AProductName :  dod.ProductName}} {{dod.ProductSizeName == null || dod.ProductSizeName == 'N/A' ? '' :  cultre == 'ar' ? '('+ dod.ArabicProductSizeName+')' : '('+dod.ProductSizeName +')'}}</label>
            </div>
            <div class="cls1 col-lg-1 col-md-1 col-xs-1 col-sm-1">
              <label class="label" *ngIf="dod.AddOnID == 0">{{dod.Quantity}}</label>
            </div>
  
            <div class="cls2 col-lg-4 col-md-4 col-xs-4 col-sm-4" *ngIf="dod.AddOnID != 0">
              <label class="label" style="color:#7d7b7b!important">{{cultre == 'ar' ? dod.ArabicAddOnName : dod.AddOnName}}</label>
            </div>
            <div class="cls1 col-lg-2 col-md-2 col-xs-2 col-sm-2">
              <input type="text" class="returnqty" *ngIf="dod.AddOnID == 0" id="txtReturnQty{{i}}" (input)="ArrayChange(i,dod)" [(ngModel)]="dod.ReturnQty" (keypress)="numberOnly($event,dod.ReturnQty)" onpaste="return false" ondrop="return false;" style="background-color: #f7f9fc">
            </div>
  
            <div class="cls3 col-lg-2 col-md-2 col-xs-2 col-sm-2">
              <label class="label" [ngStyle]="{'color':dod.AddOnID != 0 ? '#7d7b7b' : '#444' }">{{CurrencyCode}}&nbsp;{{dod.Price == null ? '0.00' : dod.Price| number:'1.'+NumOfDecimal+'-'+NumOfDecimal+''}}</label>
            </div>
            <div class="cls3 col-lg-2 col-md-2 col-xs-2 col-sm-2">
              <label class="label" [ngStyle]="{'color':dod.AddOnID != 0 ? '#7d7b7b' : '#444' }">{{CurrencyCode}}&nbsp;{{dod.TotalAmount== null ? '0.00' : dod.TotalAmount| number:'1.'+NumOfDecimal+'-'+NumOfDecimal+''}}</label>
            </div>
            <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12" *ngIf="dod.AddOnID != 0"> <hr class="hrdivider" /></div>
          </div>
  
        </div>
  
      </div>
      <div class="row">
  
        <div class="col-md-6 col-sm-12">
          <div class="form-group">
            <label for="bPaymentReference" class="label">{{ReferenceCode}}</label>
            <input nbInput fullWidth name="bPaymentReference" [(ngModel)]="ObjSaleReturnMaster.PaymentReference" placeholder="Reference Code">
          </div>
        </div>
  
        <div class="col-md-6 col-sm-12">
          <div class="row">
            <hr style="width:650px" />
            <div class="col-md-9 col-sm-9 col-xs-9">
              <ul class="list-unstyled" style="margin-bottom: 0px;">
                <li>
                  <a class="calc"><b>{{SubTotal}}</b></a>
                </li>
                <li>
                  <a class="calcvat"><b>{{Vat}} ({{ObjOutletInfo.TaxRate}} %)</b></a>
                </li>
                <li>
                  <a class="calc"><b>{{NetAmount}}</b></a>
                </li>
              </ul>
            </div>
            <div class="col-md-2 col-sm-2 col-xs-2 mx-auto" style="padding: 0px;text-align: left;">
              <ul class="list-unstyled" style="margin-bottom: 0px;">
                <li>
                  <a class="calc"><b>{{CurrencyCode}}&nbsp;&nbsp;{{getdecimalAmount(ObjSaleReturnMaster.TotalAmount) }}</b></a>
                </li>
  
                <li>
                  <a class="calcvat"><b>{{CurrencyCode}}&nbsp;&nbsp;{{getdecimalAmount(ObjSaleReturnMaster.TotalTaxAmount) }}</b></a>
                </li>
                <li>
                  <a class="calc"><b>{{CurrencyCode}}&nbsp;&nbsp;{{getdecimalAmount(ObjSaleReturnMaster.NetAmount) }}</b></a>
                </li>
              </ul>
            </div>
  
          </div>
  
        </div>
      </div> -->

      <!-- <div class="row">
        <div class="col-md-6"></div> -->
        <br/>
        <div class="col-md-12">
          <div class="row" style="text-align:right">
            <div class="col-sm col-md col-lg">
              <button class="buttoncss btn btnfinish" (click)="SaveData()">{{Update}}</button>
            </div>
            <div>
              <button class="buttoncss btn btnfinish" (click)="clear()">{{Cancel}}</button>
            </div>
          </div>
          
          
        </div>
  
      <!-- </div> -->
      <!-- <iframe name="onpaysalereturn" #printfarme id="onpaysalereturn"  [src]="ReceiptURL | SaleReturnUrlSafe"  width="0" height="0" frameborder="0"></iframe> -->
    </nb-card-body>
  
  </nb-card>
  
  
  