<link href="//stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet">

<div class="bubble-greetings" *ngIf="OrderNowVisible">
  <span class="close-button" (click)="OrderNowClose()">
  <svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.9 21.9" class="">
    <path  d="M14.1 11.3c-.2-.2-.2-.5 0-.7l7.5-7.5c.2-.2.3-.5.3-.7s-.1-.5-.3-.7L20.2.3c-.2-.2-.5-.3-.7-.3-.3 0-.5.1-.7.3l-7.5 7.5c-.2.2-.5.2-.7 0L3.1.3C2.9.1 2.6 0 2.4 0s-.5.1-.7.3L.3 1.7c-.2.2-.3.5-.3.7s.1.5.3.7l7.5 7.5c.2.2.2.5 0 .7L.3 18.8c-.2.2-.3.5-.3.7s.1.5.3.7l1.4 1.4c.2.2.5.3.7.3s.5-.1.7-.3l7.5-7.5c.2-.2.5-.2.7 0l7.5 7.5c.2.2.5.3.7.3s.5-.1.7-.3l1.4-1.4c.2-.2.3-.5.3-.7s-.1-.5-.3-.7l-7.5-7.5z"></path></svg>
  </span>
  <h1>Hey there, 👋</h1>
  <p>Order now and get your favorite food delivered to your doorstep.</p>
  <a (click)="open()" class="order-button" style="background-color: rgb(5, 142, 255); color: rgb(255, 255, 255); cursor:pointer">
    Order Now
  </a>
  <span class="powered-by">
    We're <svg width="7" height="13" class=""><g fill="none" fill-rule="evenodd"><path d="M4.127.496C4.51-.12 5.37.356 5.16 1.07L3.89 5.14h2.33c.483 0 .757.616.464 1.044l-4.338 6.34c-.407.595-1.244.082-1.01-.618l1.384-4.25H.778c-.47 0-.748-.59-.48-1.02L4.13.495z" fill="#F6A623"></path><path fill="#FEF79E" d="M4.606.867L.778 7.007h2.807l-1.7 5.126 4.337-6.34H3.16"></path></g></svg> by
    <a href="https://kaem.solutions" target="_blank">KAEM Solutions</a>
  </span>
</div> 

<iframe #frame frameborder="0"></iframe>


