import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef,MatDialog ,  MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DataserviseService } from '../../shared/dataservice';
import { UtilitiesService } from '../../shared/utilities.service';
import { PopupService } from '../../shared/popup';
import {RejectPopupComponent} from '../../pages/live/liveorder/rejectpopup/rejectpopup.component';
import {DinetablePopupComponent} from '../dinetable-popup/dinetable-popup.component';
import {TranslatePipe} from '../../shared/pipe/translate.pipe';

@Component({
  selector: 'ngx-transfertable',
  templateUrl: './transfertable.component.html',
  styleUrls: ['./transfertable.component.scss']
})

export class TransfertableComponent implements OnInit {
culture:any;
outletID:any;
NumOfDecimal:any;
SalesTypeID:any;
DayID:any;

  constructor(private translate : TranslatePipe,private UtilitiesService: UtilitiesService,private popup: PopupService,private dataservice: DataserviseService,public dialog: MatDialog,public dialogRef: MatDialogRef<TransfertableComponent>,@Inject(MAT_DIALOG_DATA) public data: any ) 
  {debugger
    this.OrderID  = this.data.data[0];
    this.culture  = this.data.data[1];
    this.outletID  = this.data.data[2];
    this.NumOfDecimal  = this.data.data[3];
    this.SalesTypeID= this.data.data[4];
    this.OrderType = this.data.data[5];
    this.DayID = this.data.data[6];
  }

  TransferTable : any = this.translate.transform('TransferTable'); 
  MergeTable : any = this.translate.transform('MergeTable'); 
  JoinTable : any = this.translate.transform('JoinTable'); 
  SplitCheck : any = this.translate.transform('SplitCheck'); 
  TableSetting : any = this.translate.transform('TableSetting'); 
  OrderNo : any = this.translate.transform('OrderNo.');
  OrderDate : any = this.translate.transform('OrderDate');
  OrderType : any = this.translate.transform('Order Type');
  TableNo : any = this.translate.transform('TableNo.');
  Customer : any = this.translate.transform('Customer');
  PhoneNo : any = this.translate.transform('PhoneNo');
  TransferDineOrderToAnotherTable : any = this.translate.transform('TransferDineOrderToAnotherTable'); 
  MergeDineOrderToAnotherTable : any = this.translate.transform('MergeDineOrderToAnotherTable'); 
  Pleaseselectdestinationtablewheretotransferorderofcurrenttable : any = this.translate.transform('Pleaseselectdestinationtablewheretotransferorderofcurrenttable'); 
  Pleaseselectdestinationtablewheretomergeorderofcurrenttable : any = this.translate.transform('Pleaseselectdestinationtablewheretomergeorderofcurrenttable'); 
  ActiveTabTransferTable:boolean = true;
  ActiveTabMergeTable:boolean = false;
  ActiveTabJoinTable:boolean = false;
  ActiveTabSplitCheck:boolean = false;

  ngOnInit(): void {debugger
    // this.LoadGrid();
    
      this.getMasterDetailData(this.OrderID,0);
      this.GetDineinTableInfo();
      
      if (sessionStorage.getItem('ObjCompany') != null && sessionStorage.getItem('ObjCompany') != undefined) 
      {
        this.ObjCompany = JSON.parse(sessionStorage.getItem('ObjCompany'));   
      }
  }

  onChangeTab(event: any): void {
    debugger
    if (event.tabTitle == this.translate.transform('TransferTable')) {
      this.ActiveTabTransferTable = true;
      this.ActiveTabMergeTable = false;
      this.ActiveTabJoinTable = false;
      this.ActiveTabSplitCheck = false;
      this.selecttable = false;
      this.TableName=null;
    }
    else if (event.tabTitle == this.translate.transform('MergeTable')) {
      this.ActiveTabTransferTable = false;
      this.ActiveTabMergeTable = true;
      this.ActiveTabJoinTable = false;
      this.ActiveTabSplitCheck = false;
      this.reserved = false;
      this.selecttable = false;
      this.TableName=null;
    }
    else if (event.tabTitle == this.translate.transform('JoinTable')) {
      this.ActiveTabTransferTable = false;
      this.ActiveTabMergeTable = false;
      this.ActiveTabJoinTable = true;
      this.ActiveTabSplitCheck = false;
    }
    else if (event.tabTitle == this.translate.transform('SplitCheck')) {
      this.ActiveTabTransferTable = false;
      this.ActiveTabMergeTable = false;
      this.ActiveTabJoinTable = false;
      this.ActiveTabSplitCheck = true;
    }
    else {
      this.ActiveTabMergeTable = false;
      this.ActiveTabJoinTable = false;
      this.ActiveTabSplitCheck = false;
    }
  }
  // onChangeTab(event): void {debugger
  //    if(this.cultures == 'ar')
  //    this.SectionID  = +this.lstTableGroupInfo.filter(x => x.ArabicSection == event.tabTitle)[0].ID;
  //    else{
  //     this.SectionID  = +this.lstTableGroupInfo.filter(x => x.Section == event.tabTitle)[0].ID;
  //    }
  //   }

    btnclose() : void
    {
      debugger
      this.dialogRef.close();
    }

  ObjCompany: Company = new Company();
  ObjOrderMaster: OrderMaster = new OrderMaster();
  ObjOrderMaster_parent: OrderMaster = new OrderMaster();
  lstMaster = [];
  ObjOrderMaster_merged: OrderMaster = new OrderMaster();
  ArrOrderDetail: OrderDetail[] = [];
  ArrOrderDetail_parent: OrderDetail[] = [];
  ArrOrderDetail_merged: OrderDetail[] = [];
  lstOrderDetail = [];
  SearchInList: any = '';
  HighlightRow: any;
  CurrencyCode: any = '';
  tableNumber : any;
  reserved : boolean = false;
  selecttable : boolean = false;

  lstarray = [];
  lstBasket: any = [];
  lstSelectModifier: any = [];
  OrderID : any;
  TableName : any;
  TableID:any;

  LoadGrid() 
  {
    
    this.lstarray = [];
    this.dataservice.get('pos/GetTransferOrders/' + this.UtilitiesService.getSubscriptionID()).subscribe(data => 
      {
      
          debugger;
        if (this.isArrayHasValue(data[0]["Pendingprder"]))
          {
            this.lstarray = data[0]["Pendingprder"];

            this.getMasterDetailData(data[0]['Pendingprder'][0]["OrderID"], 0);
          }    
          
        });
      }


  ReviewClick(event)
  {
    debugger

    this.OrderID = event.OrderID;
    this.getorderdetails(event.OrderID)
    // this.dialogRef.close(event.OrderID);



  }
  SelectOrder() : void
  {
    if(this.OrderID != undefined &&  this.OrderID != null  && this.OrderID != '')
    {
      // this.dialogRef.close(this.OrderID);
    }
    else
    {
      alert('Please select Order first.');
    }
    
  }

  CancelOrder() : void
  {
    if(this.OrderID != undefined &&  this.OrderID != null  && this.OrderID != '')
    {
     
    this.ObjOrderMaster.OrderPerpStatus = 'Cancel';
    this.ObjOrderMaster.Channel = 'POSRestaurants';

      let data = this.ObjOrderMaster;

    let dialogpopup = this.dialog.open(RejectPopupComponent, {
      width: '700px',
      
      data: { data },
    })

    dialogpopup.afterClosed().subscribe(result => {
      
      if (result != undefined) 
      {
        this.popup.ShowPopup('Update')
       this.LoadGrid();
      }
    });

    }
    else
    {
      alert('Please select order first.');
    }
  }

  getorderdetails(orderid : any)
  {
    debugger
  
    this.OrderID = orderid;
    this.dataservice.get('pos/GetOrdersdetails/'+this.UtilitiesService.getSubscriptionID() + '/' + orderid).subscribe(data => 
      {
        debugger
        if(this.isArrayHasValue(data[0]['Ordersdetails']))
        {
          this.lstBasket = data[0]['Ordersdetails'];   
        } 
        if(this.isArrayHasValue(data[0]['OrdersModifiers']))
        {

          this.lstSelectModifier = [];
       
          let lst = data[0]['OrdersModifiers'];
          for(let i = 0; i < this.lstBasket.length; i++)
          {
            let row =lst.filter(t => t.ProductID == this.lstBasket[i].ProductID);
              this.lstSelectModifier.push(row);    
          } 
        }

       });
}

    RefreshOrders(): void
    {
     
      this.LoadGrid();
    }

    getMasterDetailData(OrderID, index): void {     
      this.HighlightRow = index;
      this.ObjOrderMaster = new OrderMaster();
      this.OrderID = OrderID;
    
      this.dataservice.get('pos/GetPendingOrdersdetails/' +  this.UtilitiesService.getSubscriptionID() + '/' + OrderID +'/'+this.culture+'/'+0)
        .subscribe(data => {
    

          if (data[0]['Master'])
          {            
            this.ObjOrderMaster = data[0]['Master'][0];                                     
           
          }

          if (this.isArrayHasValue(data[0]['Details'])) 
          {
            this.ArrOrderDetail = data[0]['Details'];
          }
          else 
          {
            this.ArrOrderDetail = [];
          }
          // this.getReceipt();

        }, error => alert('error'));
    }


    getMasterDetailDataforParentOrder(OrderIDParent,OrderIDChild): void {
      this.dataservice.get('pos/GetPendingOrdersdetails_MergerTable/' +  this.UtilitiesService.getSubscriptionID() + '/' + OrderIDParent +'/'+  this.OrderID +'/'+this.culture).subscribe(data => {debugger
          if (data[0]['Master'])
          {            
            this.lstMaster = data[0]['Master'];                                 
          }
          if (this.isArrayHasValue(data[0]['Details'])) 
          {
            this.lstOrderDetail = data[0]['Details'];
          }
          debugger
          if (this.isArrayHasValue(data[0]['DummyModifier']) && this.lstOrderDetail.length ==0) {
            this.lstOrderDetail = [];
            for (let i = 0; i < this.lstMaster.length; i++) {
              this.lstOrderDetail.push([{ AllowMultipleSeletion: null, DetailId: null, IsSelected: null, ListName: null, MasterId: null, MaxModifiers: null, MinModifiers: null, ModifierId: null, Modifires: null, Price: null, PriceStatic: null, ProductID: null, rowid: null }]);
            }
            //this.lstSelectModifier = Array.of(this.lstSelectModifier);
          }
          //  this.ArrOrderDetail_parent = this.ArrOrderDetail_parent.concat(this.ArrOrderDetail)
          //  this.ArrOrderDetail_merged = this.ArrOrderDetail_parent;

        }, error => alert('error'));
    }


    // this.ObjOrderMaster_merged.ExtraChargeAmount = this.ObjOrderMaster_parent.ExtraChargeAmount + this.ObjOrderMaster.ExtraChargeAmount;                                      
            // this.ObjOrderMaster_merged.NetAmount = this.ObjOrderMaster_parent.NetAmount + this.ObjOrderMaster.NetAmount;                                      
            // this.ObjOrderMaster_merged.TotalAmount = this.ObjOrderMaster_parent.TotalAmount + this.ObjOrderMaster.TotalAmount; 
            // this.ObjOrderMaster_merged.TipAmount = this.ObjOrderMaster_parent.TipAmount + this.ObjOrderMaster.TipAmount; 
            // this.ObjOrderMaster_merged.TotalTaxAmount = 0.000;                                    
            // this.ObjOrderMaster_merged.TotalDiscount = 0.000;                                    
            // this.ObjOrderMaster_merged.PromoCode = null;                                    
            // this.ObjOrderMaster_merged.PromoAmount = 0.000;                                    
            // this.ObjOrderMaster_merged.LoyalityAmount = null;    

    // ReceiptURL: any = '';
    // lstUser = [];
    // getReceipt(): void {
      
    // this.lstUser = JSON.parse(localStorage.getItem('sessionObject'+this.UtilitiesService.getURLCompanyName()));
    //   const CompanyName = this.UtilitiesService.getCompanyName();
    //   const url = this.UtilitiesService.getReceiptURL(this.UtilitiesService.getEncrypt(CompanyName), this.UtilitiesService.getEncrypt(this.ObjOrderMaster.OrderID), this.lstUser[0]['CountryCode']);
    //   this.ReceiptURL = url;
    // }
      

      isArrayHasValue(obj: any[]): boolean
      {
        let isArrayWithLength = Boolean(Array.isArray(obj) && obj.length);
        return isArrayWithLength;
      }

      lstDineinTableInfo : any = [];
      GetDineinTableInfo(): void 
      {
    
        this.lstDineinTableInfo = [];
        var CompanyId = this.UtilitiesService.getSubscriptionID()
        this.dataservice.get('pos/GetDineinTableInfo2/' + CompanyId).subscribe(data => {debugger;
            if (this.isArrayHasValue(data[0]['DineinTableInfo'])) {
              this.lstDineinTableInfo = data[0]['DineinTableInfo'];
            }
    
          }, error => alert('error'));
      } 

      ClickTable(event,status : any) : void
      {
        debugger
        if(status == '0')
        {
        // this.dialogRef.close(event);
        this.tableNumber = event;

        }
        else
        {
          alert('Table is already in use.')
        }
    
      }

      Dinedata : any = [];
      noOfCustomers:any;
      OrderID_Parent:any;
      OrderID_Child:any;
      OrderInvoiceNo_Parent:any;

      openDineInTablePopup() {
        this.reserved = false;
        const dialogRef = this.dialog.open(DinetablePopupComponent, {
          width: '1150px',
          height: '600px',
          data: {culture:this.culture,outletID:this.outletID,MergeTable:this.ActiveTabMergeTable,SplitCheck:this.ActiveTabSplitCheck,TableNumber:this.ObjOrderMaster.TableNumber,IsTransferTable:true,NumOfDecimal:this.NumOfDecimal,SalesTypeID:this.SalesTypeID,SalesTypeName:this.OrderType,DayID:this.DayID}
        });
    
      
        dialogRef.afterClosed().subscribe(result => {
    this.selecttable=false;
          debugger
          if (result != undefined) 
          {
            this.Dinedata = result;
            this.TableID = this.Dinedata["ID"]; 
            this.TableName = this.Dinedata["TableName"]; 
            this.OrderID_Parent = this.Dinedata["OrderID"];
            this.OrderInvoiceNo_Parent = this.Dinedata["OrderInvoiceNo_Parent"];
            this.getMasterDetailDataforParentOrder(this.OrderID_Parent,this.OrderID_Child);
            
            if(this.Dinedata["Status"] == 0 || this.ActiveTabMergeTable == true)
            {
              this.noOfCustomers = this.Dinedata["noOfCustomers"]
              this.reserved = false;
            }
          
            else
            {
              this.reserved = true;
             
            }
          
          }
    
        });
      }

      TableUpdate(): void 
      {debugger
       
        if( this.TableName != undefined && this.TableName != null && this.TableName != '' )
        {
          if(this.reserved == false &&  this.selecttable == false){
       var body = [this.UtilitiesService.getSubscriptionID(),this.TableName,this.OrderID,this.noOfCustomers,this.TableID,this.SalesTypeID]
        this.dataservice.post('pos/TransferTable/' , body)
          .subscribe(data => {
            debugger;
            if (data == "Update") 
            {
              this.popup.ShowPopup('Update');
              // this.lstDineinTableInfo = data[0]['DineinTableInfo'];
              // this.LoadGrid();
              // this.TableName = '';
              var body = [this.ActiveTabTransferTable,this.ActiveTabMergeTable];
              this.dialogRef.close(body);
            }
    
          }, error => alert('error'));
        }
        else{
          return
        }
      }
        else
        {
           this.selecttable = true;
        }
      } 

      MergeTableclick(): void {debugger
        if( this.TableName != undefined && this.TableName != null && this.TableName != '' ) {
          this.selecttable = false;
          if (window.confirm('Are you sure you want to Merge Order Table ' + this.ObjOrderMaster.TableNumber + ' to ' + this.TableName + '?')) {
            var body = [this.ActiveTabTransferTable,this.ActiveTabMergeTable,this.OrderID_Parent,this.OrderID,this.lstMaster,this.lstOrderDetail,this.TableName,this.OrderInvoiceNo_Parent];
            debugger
            this.dialogRef.close(body);
          } 
          else {     
            
          }
        }
        else
        {
          this.selecttable = true;
        }
      } 

}
 class OrderMaster {
  OrderID: any;
  TableNumber : any;
  OrderInvoiceNo: any = '';
  PrepIn: any = '';
  PrepInOutlet: any = '';
  OrderPerpStatus: any = 'ALL';
  Brand: any = '';
  OutletID: any = '';
  OutletId: any = '';
  OutletName: any = '';
  OrderType: any = '';
  Address: any = '';
  CustomerID: any = '';
  CustomerName: any = '';
  CustomerEmail: any = '';
  CountryCode: any = '';
  PhoneNo: any = '';
  PayType: any = '';
  Channel: any = '';
  PickupFrom: any = '';
  Remarks: any = '';
  SubTotal: any = '';
  TotalTaxAmount: any = '';
  TotalAmount: any = '';
  DeliveryFee: any = '';
  NetAmount: any = '';
  TotalDiscount: any = '';
  TaxID: any = '';
  TaxDescription: any = '';
  TaxPercent: any = '';
  OrderDate: any;
  OrderDateReal: any;
  CreatedOn: any;
  CreatedBy: any;
  ModifyOn: any;
  ModifyBy: any;
  RejectReasonID: any;
  RejectReason: any;
  RiderID: any;
  CompanyId: any;
  RejectReasonDetail: any = '';
  AddressPK: any = '';
  ExtraChargeAmount:any
  PromoCode:any;
  PromoAmount:any;
  LoyalityAmount:any;
  TipAmount:any;
  Amount:any;
}
 class OrderDetail {
  OrderDetailID: any;
  OrderID: any;
  ProductId: any;
  ProductName: any = '';
  Quantity: any;
  UnitID: any;
  AddOnID: any;
  AddOnName: any;
}

class Company {
  CompanyID: any;
  CompanyName: any;
  outletsCount: any;
  CountryID: any;
  hearbout: any;
  Mobile: any;
  CurrencyCode: any;
  Rate: any;
  NumOfDecimal: any;
  CountryCode: any;
  IsOTPRequired: boolean;
}



// Address: null
// AddressPK: null
// Brand: "N/A"
// Channel: "POS"
// Column1: "N/A"
// Column2: "3"
// CountryCode: "N/A"
// CountryName: "saudi arabia"
// CreatedOn: "2022-11-21T14:07:23.687"
// CustomerEmail: "N/A"
// CustomerId: 0
// CustomerName: "N/A"
// DeliveryFee: 0
// ExtraChargeAmount: 43.48
// ExtraChargeDescription: "Tobocco Charge 100%"
// IsExtraCharge: true
// ModifyOn: "1900-01-01T00:00:00"
// NetAmount: 100
// OrderDate: "21-Nov-2022"
// OrderDateReal: "2022-11-21T16:05:45"
// OrderID: 27606
// OrderInvoiceNo: "ORD01000612"
// OrderPerpStatus: "New"
// OrderType: "Dine In"
// OutletId: 56
// OutletName: "Demo Outlet One"
// PayType: "Cash"
// PhoneNo: "N/A"
// PickupFrom :"N/A"
// PrepIn: "0"
// PrepInOutlet: 30
// RejectReason: null
// Remarks: ""
// ShiftID: 572
// TableNumber: "3"
// TaxID: 0
// TotalAmount: 43.48
// TotalDiscount: 0
// TotalTaxAmount: 13.04


// Address: null
// AddressPK: null
// Brand: "N/A"
// Channel: "POS"
// Column1: "N/A"
// Column2: "01"
// CountryCode: "N/A"
// CountryName: "saudi arabia"
// CreatedOn: "2022-11-21T14:02:11.117"
// CustomerEmail: "N/A"
// CustomerId: 0
// CustomerName: "N/A"
// DeliveryFee: 0
// ExtraChargeAmount: 21.74
// ExtraChargeDescription: "Tobocco Charge 100%"
// IsExtraCharge: true
// ModifyOn: "1900-01-01T00:00:00"
// NetAmount: 50
// OrderDate: "21-Nov-2022"
// OrderDateReal: "2022-11-21T16:00:33"
// OrderID: 27605
// OrderInvoiceNo: "ORD01000611"
// OrderPerpStatus: "New"
// OrderType: "Dine In"
// OutletId: 56
// OutletName: "Demo Outlet One"
// PayType: "Cash"
// PhoneNo: "N/A"
// PickupFrom: "N/A"
// PrepIn: "0"
// PrepInOutlet: 30
// RejectReason: null
// Remarks: ""
// ShiftID: 572
// TableNumber: "01"
// TaxID: 0
// TotalAmount: 21.74
// TotalDiscount: 0
// TotalTaxAmount: 6.52