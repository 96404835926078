import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslatePipe } from '../../../shared/pipe/translate.pipe';

@Component({
  selector: 'ngx-specialinstruction-popup',
  templateUrl: './specialinstruction-popup.component.html',
  styleUrls: ['./specialinstruction-popup.component.scss']
})
export class SpecialinstructionPopupComponent implements OnInit {
  txtboxComment:any;
  SpecialInstruction : any = this.translate.transform('SpecialInstruction');
  Comment : any = this.translate.transform('Comment');


  constructor(private translate : TranslatePipe,public dialogRef: MatDialogRef<SpecialinstructionPopupComponent>, @Inject(MAT_DIALOG_DATA) public parentData: any,) { }

  ngOnInit(): void {
    if(this.parentData){debugger
      var index  = this.parentData["Index"];
      this.txtboxComment = this.parentData["lstbasket"][index].SpecialRequest;  
  }
  }

  btnclose() : void
  {
    debugger
    this.dialogRef.close();
  }

  commentClick(){
    this.dialogRef.close(this.txtboxComment);
  }
}
