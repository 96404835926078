import { Component, OnInit, Inject } from '@angular/core';
import { DataserviseService } from '../../shared/dataservice';
import { PopupService } from '../../shared/popup';
import { UtilitiesService } from '../../shared/utilities.service';
import { SpinnerService } from '../../shared/spinner';
import { OrderListPopupComponent } from '../../pages/ordermanagements/orderlistpopup/orderlistpopup.component';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslatePipe } from '../../shared/pipe/translate.pipe';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'ngx-salereturnorderpopuop',
  templateUrl: './salereturnorderpopuop.component.html',
  styleUrls: ['./salereturnorderpopuop.component.scss']
})


export class SaleReturnOrderComponent implements OnInit {

  ArrPaidOrders: PaidOrders[] = [];
  ArrPaidOrdersDetail: PaidOrdersDetail[] = [];
  ObjCountry: Country = new Country();
  ObjSearchFilters: SearchFilters = new SearchFilters();


  OrderNo: any = this.translate.transform('OrderNo');
  PhoneNo: any = this.translate.transform('PhoneNo');
  FromDate: any = this.translate.transform('FromDate');
  ToDate1: any = this.translate.transform('ToDate');
  Search: any = this.translate.transform('Search');
  PaidOrderslist: any = this.translate.transform('PaidOrderslist');
  PaidOrders: any = this.translate.transform('PaidOrders');
  OrderDate: any = this.translate.transform('OrderDate');
  Invaliddate: any = this.translate.transform('Invaliddate');

  showPerPage = 5;
  settings = {
    actions: {
      columnTitle: '',
      add: false,
      edit: false,
      delete: false,
    },
    mode: 'external',
    pager: {
      display: true,
      perPage: this.showPerPage,
    },

    columns: {

      OrderInvoiceNo: {
        title: this.translate.transform('OrderNo'),// 'Order No',
      },
      OrderDate: {
        title: this.translate.transform('OrderDate'),//'Order Date',
      },
      OrderType: {
        title: this.translate.transform('OrderType'),//'Order Type',
      },
      PayType: {
        title: this.translate.transform('PayType'),//'Pay Type',
      },
      OrderPerpStatus: {
        title: this.translate.transform('OrderStatus'),//'Cust Mobile',
      },
      NetAmount: {
        title: this.translate.transform('Amount'),//'Amount',
        type: 'html',
        valuePrepareFunction: (value, row, cell) => {
          return this._DecimalPipe.transform(cell.row.data.NetAmount, '1.' + this.ObjCountry.NumOfDecimal + '-' + this.ObjCountry.NumOfDecimal);
        },
      },

    },
    attr: {
      class: 'table table-bordered handicursor',
    },
    hideSubHeader: false,
  };

  CompanyId: any;
  CreatedBy: any = '';
  CreatedOn: any = '';
  lstDetail: any = [];

  constructor(private translate: TranslatePipe, public dialogRef: MatDialogRef<OrderListPopupComponent>, @Inject(MAT_DIALOG_DATA) public parentData: any, public dialog: MatDialog, private _spinnerService: SpinnerService, private Utilities: UtilitiesService, private dataservice: DataserviseService, private popup: PopupService, private _DecimalPipe: DecimalPipe) { }

  ngOnInit(): void {
    debugger
    this.CompanyId = this.Utilities.getSubscriptionID();
    this.CreatedBy = this.Utilities.getUserId();
    this.CreatedOn = this.Utilities.GetCurrentDateTime();
    this.ObjSearchFilters.OutletId = this.parentData.OutletId;
    this.getGridData();

  }

  InvalidDateFrom: boolean = false;
  InvalidDateTo: boolean = false;

  _DateFrom: Date = new Date();
  _DateTo: Date = new Date();

  getGridData(): void {
    this._spinnerService.show();

    this.ObjSearchFilters.CompanyId = this.Utilities.getSubscriptionID();
    this.ObjSearchFilters.Channel = 'POS';

    this.ObjSearchFilters.DateFrom = this.Utilities.getCalenderDate(this._DateFrom);
    this.ObjSearchFilters.DateTo = this.Utilities.getCalenderDate(this._DateTo);
    this.ObjSearchFilters.culture = sessionStorage.getItem('LanguageCulture');
    this.dataservice.post('pos/GetPaidOrders/', this.ObjSearchFilters)
      .subscribe(data => {
        debugger
        if (this.isArrayHasValue(data[0]['PaidOrders'])) {
          this.ArrPaidOrders = data[0]['PaidOrders'];
        } else {
          this.ArrPaidOrders = [];
        }
        this.ObjCountry = data[0]['Currency'][0];

        this._spinnerService.hide();
      }, error => alert('error'));

  }

  onUserRowSelect(event): void {

    this.dialogRef.close(event);

  }

  isArrayHasValue(obj: any[]): boolean {
    let isArrayWithLength = Boolean(Array.isArray(obj) && obj.length);
    return isArrayWithLength;
  }
}

class PaidOrders {
  OrderID: any;
  OrderInvoiceNo: any = '';
  UserName: any = '';
  OrderType: any = '';
  TableNumber: any = '';
  NetAmount: any = '';
  OrderDate: any = '';
  PhoneNo: any = '';
}

class PaidOrdersDetail {
  OrderID: any;
  ProductId: any;
  ProductName: any = '';
  AddOnID: any;
  AddOnName: any = '';
  Quantity: any = '';
  Amount: any;
}

class Country {
  CurrencyCode: any = '';
  Rate: any = '';
  NumOfDecimal: any = '';
  CountryName: any = '';
  CountryCode: any = '';
}

class SearchFilters {
  CompanyId: any;
  Channel: any = '';
  Id: any = '';
  PhoneNo: any = '';
  DateFrom: any = new Date();
  DateTo: any = new Date();
  culture: any;
  OutletId: any;
}
