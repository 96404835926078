import { Component, OnInit, Inject } from '@angular/core';
import { DataserviseService } from '../../shared/dataservice';
import { PopupService } from '../../shared/popup';
import { UtilitiesService } from '../../shared/utilities.service';
import { SpinnerService } from '../../shared/spinner';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { NumberPopupComponent } from '../pos_popup/number-popup/number-popup.component'
import { TranslatePipe } from '../../shared/pipe/translate.pipe';
import { CalculatorPopupComponent } from '../calculator-popup/calculator-popup.component';

@Component({
  selector: 'ngx-cateringfoodempinfo',
  templateUrl: './cateringfoodempinfo.component.html',
  styleUrls: ['./cateringfoodempinfo.component.scss']
})
export class CateringfoodempinfoComponent implements OnInit {


  showPerPage = 5;

  settings = {
    actions: {
      columnTitle: '',
      add: false,
      edit: true,
      delete: false
    },
    mode: 'external',
    pager: {
      display: false,
      perPage: this.showPerPage,
    },
    edit: {
      editButtonContent: '<i class="nb-edit"></i>',
    },
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
      confirmDelete: true,
    },
    columns: {
      UserFullName: {
        title: this.translate.transform('Name'),
      },
      PhoneNo: {
        title: this.translate.transform('MobileNo.'),
      },
      Deliveryat: {
        title: this.translate.transform('Delivery'),
      },
      Address: {
        title: this.translate.transform('Address'),
      },
      RemainingPoints: {
        title: this.translate.transform('LoyaltyPoints'),
      },
    },
    attr: {
      class: 'table table-bordered',
    },
    hideSubHeader: true,
  };

  CompanyId: any;
  CreatedBy: any = '';
  CreatedOn: any = '';
  lstCustomerData: any = [];
  // objCustomerMaster: CustomerMaster = new CustomerMaster();
  // objCustomerAddressDetail: CustomerAddressDetail = new CustomerAddressDetail();
  ObjRegister: Register = new Register();
  Objclscateringfood: clscateringfood = new clscateringfood();
  ObjRegisterDetail: RegisterDetail = new RegisterDetail();
  ObjGridFilterData: filterdata = new filterdata();
  buttonSave: string = this.translate.transform('Save') // 'Save';
  AddUpdateBooking: string = this.translate.transform('AddUpdateBooking') //'Add New Customer';

  AddressVisible: boolean = false;
  FirstNameMandatory: boolean = false;
  // LastNameMandatory: boolean = false;
  MobileMandatory: boolean = false;
  // MobileValid: boolean = false;
  Area: boolean = false;
  Flat: boolean = false;
  Road: boolean = false;
  Block: boolean = false;
  BuildingNo: boolean = false;
  Address: boolean = false;
  CountryCode: any;
  showvalidationEmail: boolean = true;
  showlabelEmail: boolean = false;
  filterName: any;
  filterMobileNo: any;
  SearchCustomer: any = this.translate.transform('SearchCustomer');
  Name: any = this.translate.transform('Name');
  MobileNo: any = this.translate.transform('MobileNo.');
  Search: any = this.translate.transform('Search');
  FirstName: any = this.translate.transform('FirstName');
  CustomerName: any = this.translate.transform('CustomerName');
  EventName: any = this.translate.transform('EventName');
  EventLocation: any = this.translate.transform('EventLocation');
  EventMap: any = this.translate.transform('EventMap');
  BookingDate: any = this.translate.transform('BookingDate');
  DeliveryDate: any = this.translate.transform('DeliveryDate');
  DeliveryTime: any = this.translate.transform('DeliveryTime');
  ExpectedPeople: any = this.translate.transform('ExpectedPeople');
  EventDiscription: any = this.translate.transform('EventDiscription');
  BugetAmount: any = this.translate.transform('BugetAmount');
  LastName: any = this.translate.transform('LastName');
  DeliveryAt: any = this.translate.transform('Deliveryat');
  Home: any = this.translate.transform('Home');
  Office: any = this.translate.transform('Office');
  Others: any = this.translate.transform('Others');
  lblArea: any = this.translate.transform('Area');
  Floor: any = this.translate.transform('Floor');
  Building: any = this.translate.transform('BuildingNo');
  lblRoad: any = this.translate.transform('RoadNo');
  lblBlock: any = this.translate.transform('BlockNo');
  BuildingName: any = this.translate.transform('BuildingName');
  AdditionalDirectionsOptional: any = this.translate.transform('AdditionalDirection');
  DeliveryAddress: any = this.translate.transform('DeliveryAddress');
  Cancel: any = this.translate.transform('Cancel');
  VATNo: any = this.translate.transform('VATNo');
  Companyname: any = this.translate.transform('Companyname');
  LoyaltyPoints: any = this.translate.transform('LoyaltyPoints');
  MaxCreditLimit: any = this.translate.transform('MaxCreditLimit');
  CateringFoodManagement: any = this.translate.transform('CateringFoodManagement');
  Pleaseentercustomername: any = this.translate.transform('Pleaseentercustomername');
  Pleaseentereventname: any = this.translate.transform('Pleaseentereventname');
  pleaseentereventlocation: any = this.translate.transform('pleaseentereventlocation');
  pleaseenterbookingdate: any = this.translate.transform('pleaseenterbookingdate');
  pleaseenterdeliverydate: any = this.translate.transform('pleaseenterdeliverydate');
  pleaseenterbudgetamount: any = this.translate.transform('pleaseenterbudgetamount');
  
  constructor(private translate: TranslatePipe, private _spinnerService: SpinnerService, private Utilities: UtilitiesService, private dataservice: DataserviseService, private popup: PopupService, public dialog: MatDialog, public dialogRef: MatDialogRef<CateringfoodempinfoComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }
  BillAmount: any;
  Subtoal: any;
  DiscountAmont: any;
  VAT: any;
  lstbasket: any;
  lstselectedProduct: any;
  DeliveryFee: any;
  outletInfo: any;
  lstSelectedModifiers: any;
  IsComingKOT: any;
  KotOrderID: any;
  Currency: any;
  NumOfDecimal: any;
  CustomerID: any;
  Status: any;
  OrderType: any;
  SelectedBranchID: any;
  POSID: any;
  DayID: any;
  ShiftID: any;
  PromoCode: any;
  PromoAmount: any;
  OrderPerpStatus: any;
  TableName: any;
  noOfCustomers: any;
  CustAddressID: any;
  OrderTypeID: any;
  VoucherPaymentDetail: any;
  loyaltypoints: any;
  AvailingAmount: any;
  //MobileNo: any;
  IsExtraCharge: any;
  ExtraChargeDescription: any;
  ExtraChargeAmount: any;
  ExtraChargeDescriptionArabic: any;
  IsCalculateTIPAmount: any;
  IsLoyaltySelected: any;
  Consumed_LoyaltyPoints: any;
  Consumed_LoyaltyAmount: any;
  AddnDeleteItems: any;
  custName: any;
  outletID: any;


  ngOnInit(): void {
    debugger
    this.ObjGridFilterData.branchId = this.data.outletID;
    this.BillAmount = this.data.BillAmount;
    this.Subtoal = this.data.Subtoal
    this.DiscountAmont = this.data.DiscountAmont;
    this.VAT = this.data.VAT;
    this.lstbasket= this.data.lstbasket;
    this.lstselectedProduct= this.data.lstselectedProduct;
    this.DeliveryFee= this.data.DeliveryFee;
    this.outletInfo= this.data.outletInfo;
    this.lstSelectedModifiers= this.data.lstSelectedModifiers;
    this.IsComingKOT= this.data.IsComingKOT;
    this.KotOrderID= this.data.KotOrderID;
    this.Currency= this.data.Currency;
    this.NumOfDecimal= this.data.NumOfDecimal;
    this.CustomerID= this.data.CustomerID;
    this.Status= this.data.Status;
    this.OrderType= this.data.OrderType;
    this.SelectedBranchID= this.data.SelectedBranchID;
    this.POSID= this.data.POSID;
    this.DayID= this.data.DayID;
    this.ShiftID= this.data.ShiftID;
    this.PromoCode= this.data.PromoCode;
    this.PromoAmount= this.data.PromoAmount;
    this.OrderPerpStatus= this.data.OrderPerpStatus;
    this.TableName= this.data.TableName;
    this.noOfCustomers= this.data.noOfCustomers;
    this.CustAddressID= this.data.CustAddressID;
    this.OrderTypeID= this.data.OrderTypeID;
    this.VoucherPaymentDetail= this.data.VoucherPaymentDetail;
    this.loyaltypoints= this.data.loyaltypoints;
    this.AvailingAmount= this.data.AvailingAmount;
    this.MobileNo= this.data.MobileNo;
    this.IsExtraCharge= this.data.IsExtraCharge;
    this.ExtraChargeDescription= this.data.ExtraChargeDescription;
    this.ExtraChargeAmount= this.data.ExtraChargeAmount;
    this.ExtraChargeDescriptionArabic= this.data.ExtraChargeDescriptionArabic;
    this.IsCalculateTIPAmount= this.data.IsCalculateTIPAmount;
    this.IsLoyaltySelected= this.data.IsLoyaltySelected;
    this.Consumed_LoyaltyPoints= this.data.Consumed_LoyaltyPoints;
    this.Consumed_LoyaltyAmount= this.data.Consumed_LoyaltyAmount;
    this.AddnDeleteItems= this.data.AddnDeleteItems;
    this.custName= this.data.custName;
    this.outletID= this.data.outletID

    this.CompanyId = this.Utilities.getSubscriptionID();
    this.ObjRegisterDetail.CreatedBy = this.Utilities.getUserId();
    this.ObjRegisterDetail.CreatedOn = this.Utilities.GetCurrentDateTime();
    this.ObjRegister.RegisterDate = this.Utilities.GetCurrentDateTime();
    this.CountryCode = JSON.parse(localStorage.getItem('counterID&CompanyID' + this.Utilities.getURLCompanyName()))[0].CountryCode;
    this.ObjRegister.CountryCode = this.CountryCode;
  }
  LoyaltyPointsInfo: any = [];
  lstLoyaltyPointsData: any = [];
  getGridData(): void {
    debugger
    if ((this.ObjGridFilterData.Name == undefined || this.ObjGridFilterData.Name == '') && (this.ObjGridFilterData.PhoneNo == undefined || this.ObjGridFilterData.PhoneNo == ''))
      return
    this.ObjGridFilterData.CompanyId = this.Utilities.getSubscriptionID();
    this._spinnerService.show();
    //let data: { BillAmount: this.BillAmount, Subtoal: this.Subtoal, DiscountAmont: this.DiscountAmont, VAT: this.VAT, lstbasket: this.lstBasket, lstselectedProduct: this.lstSelectedProduct, DeliveryFee: this.DeliveryFee, outletInfo: this.ObjOutletInfo, lstSelectedModifiers: this.lstSelectModifier, IsComingKOT: this.chkKOT, KotOrderID: this.KotOrderID, Currency: this.CurrencyCode, NumOfDecimal: this.NumOfDecimal, CustomerID: this.custId, Status: 'PAID', OrderType: this.OrderType, SelectedBranchID: this.SelectedBranchID, POSID: this.counterID, DayID: this.DayID, ShiftID: this.ShiftID, PromoCode: this.PromoCode, PromoAmount: this.PromoAmountforVisibility, OrderPerpStatus: 'Delivered', TableName: this.TableName, noOfCustomers: this.noOfCustomers, 'CustAddressID': this.CustomerAddressID, 'OrderTypeID': this.OrderTypeID, 'VoucherPaymentDetail': this.lstVoucherPaymentDetail, 'loyaltypoints': this.loyaltypoints, 'AvailingAmount': this.AvailingAmount, 'MobileNo': this.MobileNo, 'IsExtraCharge': this.IsExtraCharge, ExtraChargeDescription: this.ExtraChargeDescription, ExtraChargeAmount: this.ExtraChargeAmount, ExtraChargeDescriptionArabic: this.ExtraChargeDescriptionArabic, IsCalculateTIPAmount: this.IsCalculateTIPAmount, IsLoyaltySelected: this.IsLoyaltySelected, Consumed_LoyaltyPoints: this.Consumed_LoyaltyPoints, Consumed_LoyaltyAmount: this.Consumed_LoyaltyAmount, AddnDeleteItems: this.AddnDeleteItems, custName: this.custName, outletID: this.SelectedBranchID }
    this.dataservice.post('Customer/GetFilterCustomerGridData/', this.ObjGridFilterData)
      .subscribe(data => {
        debugger
        if (this.isArrayHasValue(data[0]['lstCustomer'])) {
          this.lstCustomerData = data[0]['lstCustomer'];
        }
        else {
          this.lstCustomerData = [];
        }

        if (this.isArrayHasValue(data[0]['LoyaltyPointsInfo'])) {
          this.LoyaltyPointsInfo = data[0]['LoyaltyPointsInfo'];
        }
        else {
          this.LoyaltyPointsInfo = [];
        }
        if (this.isArrayHasValue(data[0]['lstLoyaltyPointsData'])) {
          this.lstLoyaltyPointsData = data[0]['lstLoyaltyPointsData'];
        }
        else {
          this.lstLoyaltyPointsData = [];
        }
        this._spinnerService.hide();
      }, error => alert('error'));

  }
  NumberKeypadPopup() {

    const dialogRef = this.dialog.open(NumberPopupComponent, {
      width: '400px',
      height: '430px',
      data: {},
      panelClass: 'my-custom-dialog-class'
    });

    dialogRef.afterClosed().subscribe(result => {
      debugger
      if (result) {
        debugger
        this.ObjGridFilterData.PhoneNo = result;
        this.getGridData();
      }

    });
  }
  isArrayHasValue(obj: any[]): boolean {
    let isArrayWithLength = Boolean(Array.isArray(obj) && obj.length);
    return isArrayWithLength;
  }
  replaceAstrophe(val): any {
    if (val != null)
      return val.replaceAll("'", "''");
  }
  val_lblDuplicateName;
  btnSave() {

    if (this.ValidateNewForm())
      return;

    // this._spinnerService.show();
    // data: { BillAmount: this.Total, Subtoal: this.Subtoal, DiscountAmont: this.DiscountAmont, VAT: this.VAT, lstbasket: this.lstBasket, lstselectedProduct: this.lstSelectedProduct, DeliveryFee: this.DeliveryFee, outletInfo: this.ObjOutletInfo, lstSelectedModifiers: this.lstSelectModifier, IsComingKOT: this.chkKOT, KotOrderID: this.KotOrderID, Currency: this.CurrencyCode, NumOfDecimal: this.NumOfDecimal, CustomerID: this.custId, Status: 'PAID', OrderType: this.OrderType, SelectedBranchID: this.SelectedBranchID, POSID: this.counterID, DayID: this.DayID, ShiftID: this.ShiftID, PromoCode: this.PromoCode, PromoAmount: this.PromoAmountforVisibility, OrderPerpStatus: 'Delivered', TableName: this.TableName, noOfCustomers: this.noOfCustomers, 'CustAddressID': this.CustomerAddressID, 'OrderTypeID': this.OrderTypeID, 'VoucherPaymentDetail': this.lstVoucherPaymentDetail, 'loyaltypoints': this.loyaltypoints, 'AvailingAmount': this.AvailingAmount, 'MobileNo': this.MobileNo, IsExtraCharge: this.IsExtraCharge, ExtraChargeDescription: this.ExtraChargeDescription, ExtraChargeAmount: this.ExtraChargeAmount, ExtraChargeDescriptionArabic: this.ExtraChargeDescriptionArabic, IsCalculateTIPAmount: this.IsCalculateTIPAmount, IsLoyaltySelected: this.IsLoyaltySelected, Consumed_LoyaltyPoints: this.Consumed_LoyaltyPoints, Consumed_LoyaltyAmount: this.Consumed_LoyaltyAmount, AddnDeleteItems: this.AddnDeleteItems, custName: this.custName,outletID: this.SelectedBranchID  }

    const dialogRef = this.dialog.open(CalculatorPopupComponent, {
      width: '1150px',
      height: '600px',

      data: {BillAmount: this.BillAmount, Subtoal: this.Subtoal, DiscountAmont: this.DiscountAmont, VAT: this.VAT, lstbasket: this.lstbasket,
         lstselectedProduct: this.lstselectedProduct, DeliveryFee: this.DeliveryFee, outletInfo: this.outletInfo, 
         lstSelectedModifiers: this.lstSelectedModifiers, IsComingKOT: this.IsComingKOT, KotOrderID: this.KotOrderID, Currency: this.Currency,
          NumOfDecimal: this.NumOfDecimal, CustomerID: this.CustomerID, Status: 'PAID', OrderType: this.OrderType,
           SelectedBranchID: this.SelectedBranchID, POSID: this.POSID, DayID: this.DayID, ShiftID: this.ShiftID, PromoCode: this.PromoCode,
            PromoAmount: this.PromoAmount, OrderPerpStatus: 'Delivered', TableName: this.TableName, noOfCustomers: this.noOfCustomers,
             'CustAddressID': this.CustAddressID, 'OrderTypeID': this.OrderTypeID, 'VoucherPaymentDetail': this.VoucherPaymentDetail, 'loyaltypoints': this.loyaltypoints, 
             'AvailingAmount': this.AvailingAmount, 'MobileNo': this.MobileNo, IsExtraCharge: this.IsExtraCharge, 
             ExtraChargeDescription: this.ExtraChargeDescription, ExtraChargeAmount: this.ExtraChargeAmount, 
             ExtraChargeDescriptionArabic: this.ExtraChargeDescriptionArabic, IsCalculateTIPAmount: this.IsCalculateTIPAmount,
              IsLoyaltySelected: this.IsLoyaltySelected, Consumed_LoyaltyPoints: this.Consumed_LoyaltyPoints,
              Consumed_LoyaltyAmount: this.Consumed_LoyaltyAmount, AddnDeleteItems: this.AddnDeleteItems, custName: this.custName,IsCateringFood:true,
                objCateringFood: this.Objclscateringfood, }
    });

    dialogRef.afterClosed().subscribe(result => {debugger

      if (result == undefined) {debugger
        return;
      }

      else {
        this.dialogRef.close(result);
        // this.AddnDeleteItems = false;
      }
    });

    // let body = [this.Objclscateringfood]
    // this.dataservice.post('FoodCatering/SaveCatering/', body)
    //   .subscribe(result => {
    //     debugger
    //     if (result) {
    //       debugger
    //       if (result[0].SavedData[0].Column1 == 'Insert') {
    //         this.popup.ShowPopup('Insert');
    //         var customerRow = {
    //           custID: result[0].SavedData[0].CustomerID,
    //           custName: this.ObjRegister.UserFullName,
    //           CustAddressID: result[0].SavedData[0].CustomerAddressID
    //         }
    //         this.dialogRef.close(customerRow);
    //       }
    //       else if (result[0].SavedData[0].Column1 == 'Update') {
    //         debugger
    //         this.popup.ShowPopup('Update');
    //         this.val_lblDuplicateName = false;
    //         var customerRow = {
    //           custID: this.ObjRegister.CustomerID,
    //           custName: this.ObjRegister.UserFullName,
    //           CustAddressID: this.ObjRegisterDetail.CustAddressID
    //         }
    //         this.dialogRef.close(customerRow);
    //       }

    //       else if (result[0].SavedData[0].Column1 == 'Duplicate') {
    //         this.val_lblDuplicateName = true;
    //       }
    //       else {
    //         alert('error');
    //       }
    //     }
    //     this._spinnerService.hide();
    //   }, error => alert('error'));

  }

  ddlArea: any = [];
  public onUserRowSelect(event) {
    debugger
    var customerRow = {
      custID: event.data.CustomerID,
      custName: event.data.UserFullName,
      CustAddressID: event.data.CustAddressID,
      PhoneNo: event.data.PhoneNo,
      MaxCreditLimit: event.data.MaxCreditLimit

    }
    let body = [customerRow, this.LoyaltyPointsInfo, this.lstLoyaltyPointsData]
    this.dialogRef.close(body);
  }
  btnclose() {
    debugger
    this.dialogRef.close();
  }
  onDelete(event) {
    if (window.confirm('Are you sure you want to delete?')) {
      this.DeleteData(event.data.CustomerID);
    } else {
      event.confirm.reject();
    }
  }
  DeleteData(Id): void {
    this.dataservice.post('Customer/deleteCustomer/', Id)
      .subscribe(result => {
        if (result) {
          if (result) {
            this.popup.ShowPopup('Delete');
            this.getGridData();
          }
          else {
            alert('error');
          }
        }
      }, error => alert('error'));
  }
  disableDelivertButton: boolean = false;
  onEditRowSelect(event) {debugger
    this.buttonSave = this.translate.transform('Update');
    this.AddUpdateBooking = this.translate.transform('AddUpdateBooking');
    this.disableDelivertButton = true;
    this.ObjRegister.CustomerID = event.data.CustomerID;
    this.ObjRegisterDetail.CustAddressID = event.data.CustAddressID;
    this.ObjRegister.FirstName = event.data.FirstName;
    this.ObjRegister.LastName = event.data.LastName;
    this.ObjRegister.PhoneNo = event.data.PhoneNo;
    this.ObjRegister.Email = event.data.Email;
    this.ObjRegister.VATNo = event.data.VATNo;
    this.ObjRegister.Company = event.data.Company;
    this.ObjRegister.MaxCreditLimit = event.data.MaxCreditLimit;
    this.ObjRegister.ModifyBy = this.Utilities.getUserId();
    this.ObjRegister.ModifyOn = this.Utilities.GetCurrentDateTime();
    this.ObjRegisterDetail.Deliveryat = event.data.Deliveryat;
    this.ObjRegisterDetail.Area = +event.data.Area;
    this.ObjRegisterDetail.Flat = event.data.Flat;
    this.ObjRegisterDetail.Floor = event.data.Floor;
    this.ObjRegisterDetail.BuildingNo = event.data.BuildingNo;
    this.ObjRegisterDetail.Road = event.data.Road;
    this.ObjRegisterDetail.Block = event.data.Block;
    this.ObjRegisterDetail.BuildingName = event.data.BuildingName;
    this.ObjRegisterDetail.Address = event.data.Address;
    this.ObjRegisterDetail.AdditionalDirection = event.data.AdditionalDirection;
    this.ObjRegisterDetail.ModifyBy = this.Utilities.getUserId();
    this.ObjRegisterDetail.ModifyOn = this.Utilities.GetCurrentDateTime();
  }
  flatApartment: any = this.translate.transform('FlatAppartment'); // 'Flat / Appartment #';
  PleasenterFlat: any = this.translate.transform('PleasenterFlat');
  PleaseenterRoad: any = this.translate.transform('PleaseenterRoad');
  PleaseenterBlock: any = this.translate.transform('PleaseenterBlock');
  PleaseenterBuildingNo: any = this.translate.transform('PleaseenterBuildingNo');
  rdChange(val): void {
    if (val == 'Home' || val == 'Others') {
      this.flatApartment = this.translate.transform('FlatAppartment') //'Flat / Appartment #';
    }
    else {
      this.flatApartment = this.translate.transform('Office') + '#'; //'Office #';
    }
  }
  firstchar: any;
  ValidateForm(): boolean {
    this.MobileMandatory = false;
    this.FirstNameMandatory = false;

    this.Area = false;
    this.Road = false;
    this.Block = false;
    this.BuildingNo = false;
    this.Flat = false;
    this.Address = false;

    let res = false;
    if (!this.ObjRegister.FirstName || this.ObjRegister.FirstName.trim() == '') {
      this.FirstNameMandatory = true;
      res = true;
    }

    if (!this.ObjRegister.PhoneNo || this.ObjRegister.PhoneNo == '' || this.ObjRegister.PhoneNo == undefined) {
      this.MobileMandatory = true;
      res = true;
    }

    debugger
    if (this.CountryCode == 'PK') {
      if (!this.ObjRegisterDetail.Address || this.ObjRegisterDetail.Address.trim() == '') {
        this.Address = true;
        res = true;
      }
    }
    else {

      if (!this.ObjRegisterDetail.Area || this.ObjRegisterDetail.Area == '') {
        this.Area = true;
        res = true;
      }

    }

    return res;
  }
  valcustid: boolean = false;
  valeventname: boolean = false;
  valeventlocation: boolean = false;
  valBookingDate: boolean = false;
  valDeliveryDate: boolean = false;
  valBugetAmount: boolean = false;
  ValidateNewForm(): boolean {
    this.valcustid = false;
    this.valeventname = false;
    this.valeventlocation = false;
    this.valBookingDate = false;
    this.valDeliveryDate = false;
    this.valBugetAmount = false;

    let res = false;
    if (!this.Objclscateringfood.CustomerName || this.Objclscateringfood.CustomerName == '') {
      this.valcustid = true;
      res = true;
    }
    if (!this.Objclscateringfood.EventName || this.Objclscateringfood.EventName == '' || this.Objclscateringfood.EventName == undefined) {
      this.valeventname = true;
      res = true;
    }

    if (!this.Objclscateringfood.EventLocation || this.Objclscateringfood.EventLocation == '' || this.Objclscateringfood.EventLocation == undefined) {
      this.valeventlocation = true;
      res = true;
    }
    if (!this.Objclscateringfood.BookingDate || this.Objclscateringfood.BookingDate == '' || this.Objclscateringfood.BookingDate == undefined) {
      this.valBookingDate = true;
      res = true;
    }
    if (!this.Objclscateringfood.DeliveryDate || this.Objclscateringfood.DeliveryDate == '' || this.Objclscateringfood.DeliveryDate == undefined) {
      this.valDeliveryDate = true;
      res = true;
    }
    if (!this.Objclscateringfood.BugetAmount || this.Objclscateringfood.BugetAmount == '' || this.Objclscateringfood.BugetAmount == undefined) {
      this.valBugetAmount = true;
      res = true;
    }


    return res;
  }

  isValidEmail(search: string): boolean {
    debugger
    this.showlabelEmail = false;
    if (search != "") {
      var regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
      this.showvalidationEmail = regexp.test(search);
      return this.showvalidationEmail;
    }
  }
  clearControls() {
    this.ObjRegister = new Register();
    this.ObjRegisterDetail = new RegisterDetail();
    //this.ArrRegisterDetail = [];
    this.buttonSave = this.translate.transform('Save'); //'Save';
    this.AddUpdateBooking = this.translate.transform('AddUpdateBooking')// 'Add New Customer';
    this.disableDelivertButton = false;
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }


  
}

class Register {
  CustomerID: any = 0;
  FirstName: any;
  LastName: any;
  UserFullName: any;
  Email: any;
  PhoneNo: any;
  CompanyId: any;
  Address: any;
  CreatedBy: any;
  CreatedOn: any;
  ModifyBy: any;
  ModifyOn: any;
  RegisterDate: any;
  CountryCode: any;
  CustomerType: any = 'POS';
  VATNo: any;
  Company: any;
  MaxCreditLimit: any;
}
class RegisterDetail {
  CustAddressID: any = 0;
  CustomerID: any;
  Area: any;
  Flat: any;
  Floor: any;
  Road: any;
  Block: any;
  BuildingNo: any;
  BuildingName: any;
  AdditionalDirection: any;
  Deliveryat: any = 'Home';
  Address: any;
  isActive: boolean = true;
  DeleteFlag: boolean;
  CompanyId: any;
  CreatedBy: any;
  CreatedOn: any;
  ModifyBy: any;
  ModifyOn: any;
}
class filterdata {
  Name: any;
  PhoneNo: any;
  CompanyId: any;
  branchId: any;
}
class clscateringfood {

  CustomerID: any;
  CustomerName: any;
  EventName: any;
  EventLocation: any;
  EventMap: any;
  BookingDate: any;
  DeliveryDate: any;
  DeliveryTime: any;
  ExpectedPeople: any;
  EventDiscription: any;
  BugetAmount: any;
}
