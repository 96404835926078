<!-- Above is print section -->
<div style="width:100%;background-color:#EDF1F7;text-align:center" class="doNotPrint">
  <button nbButton status="basic" (click)="ClosePopup()">X {{'CloseOrder' | translate}}</button>
</div>

<div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
  <div class="row">
    <div class="orderCard__header px-3 col-lg-12 col-md-12 col-xs-12 col-sm-12" style="margin-top: -3px;">
      <span class="label clsB1"><strong>{{ObjOrderMaster.OrderInvoiceNo}}</strong></span>
      <span class="mx-2 label clsB2">{{ObjOrderMaster.OrderDateReal | datediff}}</span>
      <span class="clsB3 badge badge-lg p-2 f-size-11" style="height: 30px; padding-top: 9px!important;" [ngClass]="{'badge-acceptedNew':ObjOrderMaster.OrderPerpStatus == 'New','badge-acceptedKitchen':ObjOrderMaster.OrderPerpStatus == 'InKitchen','badge-acceptedDelivered':ObjOrderMaster.OrderPerpStatus == 'Delivered','badge-acceptedInRoute':ObjOrderMaster.OrderPerpStatus == 'InRoute','badge-acceptedReject':ObjOrderMaster.OrderPerpStatus == 'REJECTED' || ObjOrderMaster.OrderPerpStatus == 'Cancel'}">{{ObjOrderMaster.OrderPerpStatus| translate}}</span>
    </div>
  </div>
  <div class="form-group col-lg-9 col-md-9 col-xs-9 col-sm-9">
  </div>

  <div class="row" style="margin-top: 10px;margin-bottom: 10px;">
    <!-- <div class="clsP1 col-lg-2 col-md-2 col-xs-2 col-sm-2">
    </div> -->
    <!-- <div class="clsP2 col-lg-3 col-md-3 col-xs-3 col-sm-3">
      <span class="mx-2 label" style="color:#0084FF">Help</span>&nbsp;
    </div> -->
    <div class="clsP3 col-lg-8 col-md-8 col-xs-8 col-sm-8 doNotPrint" style="padding-right: 0px !important;text-align: right;">
      <!--<span class="label" style="color:#0084FF;cursor:pointer" (click)="printComponent()"><i class="fa fa-print" aria-hidden="true"></i>&nbsp;Print</span>-->
      <span class="badgeprint badge-acceptedPrint printbtn" style="margin-right:15px; padding-top: 9px !important" (click)="A4Print()">A4 Invoice <i class="fa fa-print"></i></span>
      <span class="badgeprint badge-acceptedPrint printbtn" *ngIf="ShowPrintButton" style="padding-top: 9px !important" (click)="ReceiptRePrintClick()">{{'PrintOrder' | translate}}  <i class="fa fa-print"></i></span> <!--onclick="window.frames['orderreceipt'].print();"-->
      <span *ngIf="IsCancelOrderButtonActive && ObjOrderMaster.OrderPerpStatus != 'Cancel' " (click)="CancelOrder()" class="badgeprint badge-acceptedPrint " style="padding-top: 9px !important;padding-bottom: 9px !important;width: 60px;margin-left: 8px;">Cancel</span>
      <span *ngIf="IsOpenCheckButtonActive" (click)="OpenCheck()" class="badgeprint badge-acceptedPrint " style="padding-top: 9px !important;padding-bottom: 9px !important;width: 80px;margin-left: 8px;">Open Check</span>
      <!-- | translate -->

      <iframe name="orderreceipt" #printfarme id="orderreceipt" [src]="ReceiptURL | safeUrl" width="0" height="0" frameborder="0"></iframe>
      <p style="color:red;" *ngIf="validationPrintReceipt">{{'YoucannotprinttheReceiptmorethen' | translate}} {{ObjOrderMaster.MaxReceiptPrintOut}} {{'times' | translate}} </p>
    </div>
    <div class="clsP3 col-lg-4 col-md-4 col-xs-4 col-sm-4 doNotPrint" *ngIf="!IsPrintFromRetail">
      <span class="badgeprint badge-acceptedPrint printbtn" *ngIf="ShowPrintButton" style="padding-top: 9px !important" (click)="KitchenRePrintClick()">{{'RePrintOrder' | translate}}  <i class="fa fa-print"></i></span>

    </div>
  </div>
  <p class="caption status-danger" style="margin-bottom: 8px;margin-left: 15px;" *ngIf="ShiftNotExist">{{'ShiftClosed' | translate}} </p>

  <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
    <div class="row">
      <!--<div class="clsM1 form-group col-lg-7 col-md-7 col-xs-7 col-sm-7">
        <div>
          <label class="label">Brand</label>
        </div>
        <div>
          <label class="label"><strong>{{ObjOrderMaster.Brand}}</strong></label>
        </div>
      </div>-->

      <div class="clsM2 form-group col-lg-5 col-md-5 col-xs-5 col-sm-5">
        <div>
          <label class="label">{{'Outlets' | translate}}</label>
        </div>
        <div>
          <label class="label">
            <strong>{{ObjOrderMaster.OutletName}}</strong>
          </label>
        </div>
      </div>

      <div class="clsM1 form-group col-lg-7 col-md-7 col-xs-7 col-sm-7">
        <div>
          <label class="label">{{'OrderType' | translate}}</label>
        </div>
        <div>
          <label class="label">
            <strong>{{ObjOrderMaster.OrderType}}</strong>
          </label>
        </div>
      </div>
      <div class="clsM2 form-group col-lg-5 col-md-5 col-xs-5 col-sm-5">
        <div>
          <label class="label">{{'OrderDate' | translate}}</label>
        </div>
        <div>
          <label class="label">
            <strong>{{ObjOrderMaster.OrderDateReal}}</strong>
          </label>
        </div>
      </div>

      <div class="clsM1 form-group col-lg-7 col-md-7 col-xs-7 col-sm-7">
        <div>
          <label class="label" *ngIf="ObjOrderMaster.OrderType == 'Pickup'">{{'Pickupat' | translate}}</label>
          <label class="label" *ngIf="ObjOrderMaster.OrderType == 'Delivery'">{{'Deliveryat' | translate}}</label>
        </div>
        <div>
          <label class="label" *ngIf="ObjOrderMaster.OrderType == 'Pickup' || ObjOrderMaster.OrderType == 'Delivery'">
            <strong>{{ObjOrderMaster.OrderDate}} {{ObjOrderMaster.DeliveryTime != '' ? ' - ' + ObjOrderMaster.DeliveryTime : ''}}</strong>
          </label>
        </div>
      </div>


      <div class="clsM2 form-group col-lg-5 col-md-5 col-xs-5 col-sm-5">
        <div>
          <label class="label">{{'Customer' | translate}}</label>
        </div>
        <div>
          <label class="label">
            <strong>{{ObjOrderMaster.CustomerName}}</strong>
          </label>
        </div>
      </div>
      <div class="clsM1 form-group col-lg-7 col-md-7 col-xs-7 col-sm-7">
        <div>
          <label class="label">{{'PhoneNo' | translate}}</label>
        </div>
        <div>
          <label class="label">
            <strong>{{ObjOrderMaster.PhoneNo}}</strong>
          </label>
        </div>
      </div>
      <div class="clsM2 form-group col-lg-5 col-md-5 col-xs-5 col-sm-5">
        <div>
          <label class="label">{{'PaymentMode' | translate}}</label>
        </div>
        <div>
          <label class="label">
            <strong>{{ObjOrderMaster.PayType}}</strong>
          </label>
        </div>
      </div>
      <div class="clsM1 form-group col-lg-7 col-md-7 col-xs-7 col-sm-7">
        <div>
          <label class="label">{{'Channel' | translate}}</label>
        </div>
        <div>
          <label class="label">
            <strong>{{ObjOrderMaster.Channel}}</strong>
          </label>
        </div>
      </div>
      <div class="form-group col-lg-5 col-md-5 col-xs-5 col-sm-5" *ngIf="ObjOrderMaster.OrderType == 'Delivery'">
        <div>
          <label class="label">{{'Deliverto' | translate}}</label>
        </div>
        <div>
          <label class="label">
            <strong *ngIf="!isCountryPk">{{ObjOrderMaster.Address}}</strong>
            <strong *ngIf="isCountryPk">{{ObjOrderMaster.AddressPK}}</strong>
          </label>
        </div>
      </div>
      <div class="form-group col-lg-5 col-md-5 col-xs-5 col-sm-5" *ngIf="ObjOrderMaster.OrderType == 'Pickup'">
        <div>
          <label class="label">{{'Pickupfrom' | translate}}</label>
        </div>
        <div>
          <label class="label">
            <strong>{{ObjOrderMaster.OutletName}}</strong>
          </label>
        </div>
      </div>
      <div class="form-group clsM1 col-lg-7 col-md-7 col-xs-7 col-sm-7" *ngIf="ObjOrderMaster.OrderPerpStatus == 'REJECTED' || ObjOrderMaster.OrderPerpStatus == 'Cancel'">
        <div>
          <label style="color: #dc3545 !important" class="label">{{'ReasonforRejectedCanceled' | translate}}</label>
        </div>
        <div>
          <label class="label">
            <span>{{ObjOrderMaster.RejectReason}}</span><br />
            <strong>{{ObjOrderMaster.RejectReasonDetail}}</strong>
          </label>
        </div>
      </div>


      <div class="form-group col-lg-5 col-md-5 col-xs-5 col-sm-5" *ngIf="ObjOrderMaster.Remarks != 'N/A'">
        <div>
          <label class="label">{{'Notes' | translate}}</label>
        </div>
        <div>
          <label class="label">
            <strong>{{ObjOrderMaster.Remarks}}</strong>
          </label>
        </div>
      </div>
    </div>
  </div>
  <div class="form-group col-lg-12 col-md-12 col-xs-12 col-sm-12">
    <div class="row">
      <div class="cls1 col-lg-1 col-md-1 col-xs-1 col-sm-1">
        <label class="label">{{'Qty' | translate}}</label>
      </div>
      <div class="cls2 col-lg-4 col-md-4 col-xs-4 col-sm-4">
        <label class="label">{{'Description' | translate}}</label>
      </div>
      <div class="cls2 col-lg-5 col-md-5 col-xs-5 col-sm-5">
        <label class="label">{{'SpecialInstruction'  | translate}}</label>
      </div>
      <div class="cls3 col-lg-2 col-md-2 col-xs-2 col-sm-2">
        <label class="label">{{'Amount' | translate}}</label>
      </div>

    </div>
    <hr class="hrdivider" />
    <div *ngFor="let dod of ArrOrderDetail" [ngClass]="[dod.DelFlag == 1 ? 'padd_btm_background row' : 'row']">
      <div class="cls1 col-lg-1 col-md-1 col-xs-1 col-sm-1">
        <label class="label" *ngIf="dod.AddOnID == 0">{{dod.Quantity}}</label>
      </div>
      <div class="cls2 col-lg-4 col-md-4 col-xs-4 col-sm-4" *ngIf="dod.AddOnID == 0">
        <label class="label" [ngClass]="[dod.ComboProductID != null ? 'ComboColor' : '#434444']">{{ cultre == 'ar' ? dod.AProductName :  dod.ProductName }} {{dod.ProductSizeName == 'N/A' ? '' :  cultre == 'ar' ? '('+ dod.ArabicProductSizeName+')' : '('+dod.ProductSizeName +')'}}</label>
      </div>
      <div class="cls2 col-lg-4 col-md-4 col-xs-4 col-sm-4" *ngIf="dod.AddOnID != 0">
        <label class="label" style="color:#7d7b7b!important">{{cultre == 'ar' ? dod.ArabicAddOnName : dod.AddOnName}}</label>
      </div>
      <div class="cls2 col-lg-5 col-md-5 col-xs-5 col-sm-5" *ngIf="dod.AddOnID == 0">
        <label class="label">{{dod.SpecialRequest}}</label>
      </div>
      <div class="cls3 col-lg-2 col-md-2 col-xs-2 col-sm-2">
        <label class="label" [ngStyle]="{'color':dod.AddOnID != 0 ? '#7d7b7b' : '#444' }" *ngIf="dod.OrignalAmount != undefined && dod.OrignalAmount != ''">{{ObjCurrency.CurrencyCode}}&nbsp;{{dod.OrignalAmount | number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</label>
      </div>
    </div>
  </div>



  <div class="form-group">
    <hr class="hrdivider" />
  </div>
  <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">

    <div *ngIf="ArrVoucherData?.length > 0">
      <div class="row" *ngFor="let d of ArrVoucherData;">
        <div class="cls1 col-lg-6 col-md-6 col-xs-6 col-sm-6">
        </div>
        <div class="cls2 col-lg-4 col-md-4 col-xs-4 col-sm-4">
          <label class="label">{{'VoucherDiscount' | translate}}: [ {{d.VoucherCode}} ]</label>
        </div>
        <div class="cls3 col-lg-2 col-md-2 col-xs-2 col-sm-2">
          <label class="label">{{ObjCurrency.CurrencyCode}}&nbsp;(-{{d.VoucherAmount|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}})</label>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="cls1 col-lg-6 col-md-6 col-xs-6 col-sm-6">
      </div>
      <div class="cls2 col-lg-4 col-md-4 col-xs-4 col-sm-4">
        <label class="label">{{'Discount' | translate}}:</label>
      </div>
      <div class="cls3 col-lg-2 col-md-2 col-xs-2 col-sm-2">
        <label class="label">{{ObjCurrency.CurrencyCode}}&nbsp;(-{{ObjOrderMaster.TotalDiscount| number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}})</label>
      </div>

    </div>

    <div class="row" *ngIf="ObjOrderMaster.LoyaltyAmount > 0">
      <div class="cls1 col-lg-6 col-md-6 col-xs-6 col-sm-6">
      </div>
      <div class="cls2 col-lg-4 col-md-4 col-xs-4 col-sm-4">
        <label class="label">{{'DiscountRewardPoints'| translate}}:</label>
      </div>
      <div class="cls3 col-lg-2 col-md-2 col-xs-2 col-sm-2">
        <label class="label">{{ObjCurrency.CurrencyCode}}&nbsp;(-{{ObjOrderMaster.LoyaltyAmount| number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}})</label>
      </div>

    </div>

    <div class="row">
      <div class="cls1 col-lg-6 col-md-6 col-xs-6 col-sm-6">
      </div>
      <div class="cls2 col-lg-4 col-md-4 col-xs-4 col-sm-4">
        <label class="label">{{'NetTotal' | translate}}:</label>
      </div>
      <div class="cls3 col-lg-2 col-md-2 col-xs-2 col-sm-2">
        <label class="label">{{ObjCurrency.CurrencyCode}}&nbsp;{{ObjOrderMaster.TotalAmount| number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</label>
      </div>

    </div>
    <div *ngIf="ObjOrderMaster.IsExtraCharge == 1">
      <div class="row">
        <div class="cls1 col-lg-6 col-md-6 col-xs-6 col-sm-6">
        </div>
        <div class="cls2 col-lg-4 col-md-4 col-xs-4 col-sm-4">
          <label class="label">{{ObjOrderMaster.ExtraChargeDescription}}:</label>
        </div>
        <div class="cls3 col-lg-2 col-md-2 col-xs-2 col-sm-2">
          <label class="label">{{ObjCurrency.CurrencyCode}}&nbsp;{{ObjOrderMaster.ExtraChargeAmount|number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</label>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="cls1 col-lg-6 col-md-6 col-xs-6 col-sm-6">
      </div>
      <div class="cls2 col-lg-4 col-md-4 col-xs-4 col-sm-4">
        <label class="label">{{'Tax' | translate}}:</label>
      </div>
      <div class="cls3 col-lg-2 col-md-2 col-xs-2 col-sm-2">
        <label class="label">{{ObjCurrency.CurrencyCode}}&nbsp;{{ObjOrderMaster.TotalTaxAmount| number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</label>
      </div>
    </div>
    <div class="row" *ngIf="ObjOrderMaster.OrderType == 'Delivery'">
      <div class="cls1 col-lg-6 col-md-6 col-xs-6 col-sm-6">
      </div>
      <div class="cls2 col-lg-4 col-md-4 col-xs-4 col-sm-4">
        <label class="label">{{'DeliveryFee' | translate}}:</label>
      </div>
      <div class="cls3 col-lg-2 col-md-2 col-xs-2 col-sm-2">
        <label class="label">{{ObjCurrency.CurrencyCode}}&nbsp;{{ObjOrderMaster.DeliveryFee| number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</label>
      </div>
    </div>

    <div class="row">
      <div class="cls1 col-lg-6 col-md-6 col-xs-6 col-sm-6">
      </div>
      <div class="cls2 col-lg-4 col-md-4 col-xs-4 col-sm-4">
        <label class="label"><strong>{{'lblFinalTotal'| translate}}:</strong></label>
      </div>
      <div class="cls3 col-lg-2 col-md-2 col-xs-2 col-sm-2">
        <label class="label"><strong>{{ObjCurrency.CurrencyCode}}&nbsp;{{ObjOrderMaster.NetAmount| number:'1.'+ObjCurrency.NumOfDecimal+'-'+ObjCurrency.NumOfDecimal+''}}</strong></label>
      </div>

    </div>

  </div>


  <div *ngIf="ObjOrderMaster.OrderPerpStatus == 'New' && ObjOrderMaster.OrderPayStatus != 'Paid' && ApplicationCode != 'POS' " class="form-group col-lg-12 col-md-12 col-xs-12 col-sm-12" style="margin-bottom: auto;">

    <div class="row">
      <div class="col-lg-6 col-md-6 col-xs-6 col-sm-6">
        <button nbButton fullWidth status="danger" (click)="Reject()">{{'Reject' | translate}}</button>
      </div>

      <div class="col-lg-6 col-md-6 col-xs-6 col-sm-6">
        <button nbButton fullWidth status="success" (click)="OrderStatusChange('InKitchen')">{{'Accept' | translate}}</button>
      </div>

    </div>
  </div>

  <div *ngIf="ObjOrderMaster.OrderPerpStatus == 'InKitchen' && ObjOrderMaster.OrderPayStatus != 'Paid'" class="form-group col-lg-12 col-md-12 col-xs-12 col-sm-12" style="margin-bottom: auto;">

    <div class="row">
      <div class="col-lg-2 col-md-2 col-xs-2 col-sm-2">
      </div>
      <div class="col-lg-3 col-md-3 col-xs-3 col-sm-3">
        <button nbButton fullWidth status="danger" (click)="OrderStatusChange('Cancel')">{{'Cancel' | translate}}</button>
      </div>
      <div class="col-lg-6 col-md-6 col-xs-6 col-sm-6">
        <button nbButton fullWidth status="success" (click)="OrderStatusChange('InRoute')">{{OrderType| translate}}</button>
      </div>

    </div>
  </div>


  <div *ngIf="ObjOrderMaster.OrderPerpStatus == 'InRoute' && ObjOrderMaster.OrderPayStatus != 'Paid'" class="form-group col-lg-12 col-md-12 col-xs-12 col-sm-12" style="margin-bottom: auto;">

    <div class="row">
      <div class="col-lg-3 col-md-3 col-xs-3 col-sm-3">
      </div>
      <div class="col-lg-6 col-md-6 col-xs-6 col-sm-6">
        <button nbButton fullWidth status="success" (click)="OrderStatusChange('Delivered')">{{'Complete' | translate}}</button>
      </div>

    </div>
  </div>
  <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
    <br>
  </div>
</div>



