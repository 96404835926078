<!-- <div *ngIf="isLoyaltyProgramAvailable && LoyaltyPoints > 0"> -->
    <div>
    <div class="loyaltychk" style="color:#222b45 ;">
      <div class="checkbox-selector__box" style="height:72px ;">
        <span class="m-0 px-1 font-medium"  style="color:#fd7e14 ;">
          You'll earn <strong>&nbsp;{{loyaltypoints| number:'1.'+NumOfDecimal +'-'+NumOfDecimal+''}}</strong> loyality points = {{CurrencyCode}}&nbsp;{{AvailingAmount| number:'1.'+NumOfDecimal +'-'+NumOfDecimal+''}} cashback when you complete this order.
        </span>
      </div>
      <div class="checkbox-selector__box">
        You have {{ LoyaltyRemainingPoints| number:'1.'+NumOfDecimal +'-'+NumOfDecimal+''}} loyality points in your wallet = {{CurrencyCode}} {{LoyaltyRemainingAmount| number:'1.'+NumOfDecimal +'-'+NumOfDecimal+''}}
      </div>
      <input id="chkloyalty" (change)="chkloyalty($event)" type="checkbox" name="chk1" class="checkbox-selector__input">
      <label *ngIf="LoyaltyRemainingPoints > 0" for="chkloyalty" class="checkbox-selector__box">
        <div class="w-10">
          <span class="checkbox-selector__check" style="border:1px solid #fd7e14 !important ;"></span>
        </div>
        <div  class="checkbox-selector__modifier">
          <div class="checkbox-selector__modifier-title">
            You can Consume {{CurrencyCode}} {{LoyaltyAmountConsume| number:'1.'+NumOfDecimal +'-'+NumOfDecimal+''}} as &nbsp; <strong>Loyalty reward</strong>
          </div>
        </div>
      </label>
    </div>
    <br />
    <div class="form-group">
        <button nbButton status="success" [disabled]="!isLoyaltySelected" style="width:100px;float: right;" (click)="applyLoyality()">APPLY</button>
      </div>
  </div>