import { Component, OnInit, Inject} from '@angular/core';
import { DataserviseService } from '../../shared/dataservice';
import { PopupService } from '../../shared/popup';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NbToastrService } from '@nebular/theme';
import { SpinnerService } from '../../shared/spinner';
import { TranslatePipe } from '../../shared/pipe/translate.pipe';
import { UtilitiesService } from '../../shared/utilities.service';
@Component({
  selector: 'ngx-opencashdrawer',
  templateUrl: './opencashdrawer.component.html',
  styleUrls: ['./opencashdrawer.component.scss']
})
export class OpencashdrawerComponent implements OnInit {
  // btnText: any = '';
  RejectReason:any='';

  Pleaseselectareason : any = this.translatePipe.transform('Pleaseselectareason');
  Description : any = this.translatePipe.transform('Description');
  Close : any = this.translatePipe.transform('Close');
  OpenCashdrawer : any = this.translatePipe.transform('OpenCashdrawer');
  CharachtersRemaining : any = this.translatePipe.transform('CharachtersRemaining');
  SelectedBranchID: any;
  SelectedBrancRow: any;
  companyName : any;  
  ServerLink: any;
  UserID : any = this.Utilities.getUserId();
   objOpenDrawer : OpenDrawer = new OpenDrawer();

  constructor(private Utilities: UtilitiesService, private dataservice: DataserviseService, private popup: PopupService, private toastrService: NbToastrService, public dialogRef: MatDialogRef<OpencashdrawerComponent>, @Inject(MAT_DIALOG_DATA) public data: any,private _spinnerService: SpinnerService,private translatePipe : TranslatePipe) {
  }
  ngOnInit(): void 
  {

    if (localStorage.getItem('counterID&CompanyID' + this.Utilities.getURLCompanyName()) != null) {
      this.companyName = JSON.parse(localStorage.getItem('counterID&CompanyID' + this.Utilities.getURLCompanyName()))[0].CompanyName; 
    }
    if (sessionStorage.getItem('DayID&ShiftID&DayStartDate') != null) {
      this.objOpenDrawer.DayID = JSON.parse(sessionStorage.getItem('DayID&ShiftID&DayStartDate'))[0];
      this.objOpenDrawer.ShiftID = JSON.parse(sessionStorage.getItem('DayID&ShiftID&DayStartDate'))[1];
    }
     if (localStorage.getItem('SelectedBranchRow' + this.companyName) != null && localStorage.getItem('SelectedBranchRow' + this.companyName) != undefined) {

      this.SelectedBrancRow = JSON.parse(localStorage.getItem('SelectedBranchRow' + this.companyName));      
      this.SelectedBranchID = this.SelectedBrancRow.ID;
      
    }
    debugger
    this.objOpenDrawer.CompanyID = this.Utilities.getSubscriptionID();
    this.objOpenDrawer.CreatedBy = this.Utilities.getUserId();
    this.objOpenDrawer.CreatedOn = this.Utilities.GetCurrentDateTime();
    this.GetDropdownSetups();
  }
  ClosePopup(): void {
    this.dialogRef.close(undefined);
  }
  ddlRejectReasons: any = [];
  RejectReasonID: any;

  GetDropdownSetups(): void {

    this.dataservice.get('pos/GetSetupData/' + this.Utilities.getSubscriptionID())
      .subscribe(data => {

        if (this.isArrayHasValue(data[0]['RejectReasons'])) {
          this.ddlRejectReasons = data[0]['RejectReasons'];
          this.RejectReasonID = this.ddlRejectReasons[0].ID;
        }
        if (this.isArrayHasValue(data[0]['POSInfo'])) 
        {
        this.ServerLink = data[0]['POSInfo'][0].ServerLink;
        }
      }, error => alert('error'));

  }

  SaveCashDrawerOpenResason(): void {

    this.dataservice.post('pos/SaveOpenCashDrawerReasons/', this.objOpenDrawer)
      .subscribe(data => 
        {
          debugger

          if(data)
          {
            let link = this.ServerLink + '/Default?C=' + this.objOpenDrawer.CompanyID + '&DayID=' + this.objOpenDrawer.DayID + '&type=OPCD' + '&OutletID=' + this.SelectedBranchID + '&ShiftID=' + this.objOpenDrawer.ShiftID + '&RowID=' + data;
            this.win = window.open(link, '_blank', 'width=300,height=200');
          }
        
      }, error => alert('error'));

  }
  maxChars: number = 500;

  isArrayHasValue(obj: any[]): boolean {
    let isArrayWithLength = Boolean(Array.isArray(obj) && obj.length);
    return isArrayWithLength;
  }
  win;
  OpenCashdrawerClcik()
  {
    debugger
    this.SaveCashDrawerOpenResason();

  }
}

class OpenDrawer
{
ReasonID	 : any;
ReasonDescription : any;	
DayID	 : any;
ShiftID	: any;
CompanyID	 : any;
CreatedBy : any;
CreatedOn : any;
}