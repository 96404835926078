

      <!-- <div class="child1" style="position: absolute;">Search Customer</div>
      <div class="child1" style="margin-left: 52%;">
        <img src="./assets/images/lookupclose.png" (click)="btnclose()" style="float: right; width: 25px;cursor: pointer;">
      </div> -->
      <!-- <div class="child1" >Search Customer</div> -->
      <div style="display: block;padding-top: 0px;">
        <div class="child1" style="max-width: none;">{{SearchCustomer}} <img src="./assets/images/lookupclose.png" class="cross-icon" style="float: right;cursor: pointer;margin-top: -5px;" (click)="btnclose()"/></div>
      </div>
      <hr style="margin-top: 10px;"/>
  <!-- <div class="row"> -->
    <div class="row">
    
    </div>
    <div class="row">
    <div class="col-md-12">
      <div class="row" style="padding-bottom:5px">
        <div class="col-md-3 col-sm-6">
          <label class="label">{{Name}}</label>
          <input nbInput fullWidth [(ngModel)]="ObjGridFilterData.Name">
        </div>
        <div class="col-md-3 col-sm-6">
          <label class="label">{{Companyname}}</label>
          <input nbInput fullWidth [(ngModel)]="ObjGridFilterData.Company">
        </div>
        <div class="col-md-3 col-sm-6">
          <label class="label">{{MobileNo}}</label>
          <div>
            <input nbInput fullWidth [(ngModel)]="ObjGridFilterData.PhoneNo">
            <i class="far fa-keyboard" (click)="NumberKeypadPopup()" style="cursor: pointer;cursor: pointer;padding-right: 10px;position: relative;float: right;margin-top: -30px;font-size: 22px;"></i>
          </div>
        </div>
        <div class="col-md-3 col-sm-6">
          <label class="label">&nbsp;</label>
          <button class="btn btnfinish" (click)="getGridData()">{{Search}}</button>
        </div>
      </div>
      <div class="table-responsive" style="max-height: 42vh;" >
        <ng2-smart-table [settings]="settings" [source]="lstCustomerData" (delete)="onDelete($event)"  (edit)="onEditRowSelect($event)">
        </ng2-smart-table>
      </div>
    </div>
    <!-- <div class="col-md-4 col-sm-12 hideonsmall" >
      <div class="calculator card">
        <div class="calculator-keys">      
          <button type="button" value="7" class="btn btn-light waves-effect Calculator-button" (click)="Click7()">7</button>
          <button type="button" value="8" class="btn btn-light waves-effect Calculator-button" (click)="Click8()">8</button>
          <button type="button" value="9" class="btn btn-light waves-effect Calculator-button" (click)="Click9()">9</button>
          <button type="button" value="4" class="btn btn-light waves-effect Calculator-button" (click)="Click4()">4</button>
          <button type="button" value="5" class="btn btn-light waves-effect Calculator-button" (click)="Click5()">5</button>
          <button type="button" value="6" class="btn btn-light waves-effect Calculator-button" (click)="Click6()">6</button>
          <button type="button" value="1" class="btn btn-light waves-effect Calculator-button" (click)="Click1()">1</button>
          <button type="button" value="2" class="btn btn-light waves-effect Calculator-button" (click)="Click2()">2</button>
          <button type="button" value="3" class="btn btn-light waves-effect Calculator-button" (click)="Click3()">3</button>
          <button type="button" value="0" class="btn btn-light waves-effect Calculator-button" (click)="Click0()">0</button>
          <button type="button" class="all-clear function btn btn-danger btn-sm Calculator-button" value="all-clear" (click)="ClickAC()">AC</button>  
        </div>
      </div>
</div>     -->
  </div>
<!-- <div  style="padding-left: 0px;padding-right: 0px;border: 1px solid  #e5e5e5;;"></div> -->
<br/>
<div class="row">    
<div class="col-md-12">  
      <div class="child1" >{{AddUpdateCustomer}}</div>
      <hr style="margin-top: 5px;"/>
<!-- <div class="bottom_margin">
        <label class="label">First Name</label>
        <input type="text" fullWidth nbInput class="input" [(ngModel)]="ObjSearchFilters.FirstName" />
        <p class="caption status-danger" *ngIf="val_lblCustName">Please enter Customer Name!</p>
</div>
<div class="bottom_margin">
        <label class="label">Last Name</label>
        <input type="text" fullWidth nbInput class="input"  [(ngModel)]="ObjSearchFilters.LastName"/>
    </div>
    <div class="bottom_margin">
        <label class="label">Mobile Number</label>
        <input type="text" fullWidth nbInput class="input" [(ngModel)]="ObjSearchFilters.PhoneNo" />
        <p class="caption status-danger" *ngIf="val_lblNumber">Please enter Mobile Number!</p>
    </div>
    <div class="bottom_margin">
      <label class="label">Email</label>
      <input type="text" fullWidth nbInput class="input" [(ngModel)]="ObjSearchFilters.Email"/>
      <p class="caption status-danger" *ngIf="val_lblEmail">Please enter Email!</p>
      <p class="caption status-danger" *ngIf="val_lblDuplicateName">Email already exists!</p>
    </div>
    <div class="bottom_margin">
        <label class="label">Address</label>
        <input type="text" fullWidth nbInput class="input" [(ngModel)]="ObjSearchFilters.Address"/>
    </div> -->
  
    <form class="flex-grow flex flex-col">
      <div class="row">
        <div class="col-md-3 col-sm-12 ">
        <div class="form-group">
        <label class="label">{{FirstName}}</label>
        <input type="text" fullWidth nbInput [(ngModel)]="ObjRegister.FirstName" name="FName" />
        <p class="caption status-danger" *ngIf="FirstNameMandatory">{{Pleaseenterfirstname}}</p>
        </div>
        </div>
        <div class="col-md-3 col-sm-12 ">
        <div class="form-group">
        <label class="label">{{LastName}}</label>
        <input type="text" fullWidth nbInput name="LName" [(ngModel)]="ObjRegister.LastName"  />
        <!-- <p class="caption status-danger" *ngIf="LastNameMandatory">Please enter Last Name</p> -->
        </div>
        </div>
        <div class="col-md-3 col-sm-12 ">
          <div class="form-group">
        <label class="label">{{MobileNo}}</label>
        <input type="text" maxlength="15" fullWidth nbInput name="MNo" [(ngModel)]="ObjRegister.PhoneNo" (keypress)="numberOnly($event)" onpaste="return false" ondrop="return false;" />
        <!-- <p class="caption status-danger" *ngIf="MobileValid">Please enter valid Mobile No</p> -->
        <p class="caption status-danger" *ngIf="MobileMandatory">Please enter Mobile No</p>
        </div>
        </div>
        <div class="col-md-3 col-sm-12 ">
          <div class="form-group">
        <label class="label">{{DeliveryAt}}</label>
        <div style="display:flex">
          <div class="radio-toolbar" style="margin:0px ;">
            <input type="radio" id="radioHome" [(ngModel)]="ObjRegisterDetail.Deliveryat" (change)="rdChange('Home')" name="radioLoc" value="Home">
            <label for="radioHome">{{Home}}</label>
            &nbsp;
            <input type="radio" id="radioOffice" [(ngModel)]="ObjRegisterDetail.Deliveryat" (change)="rdChange('Office')" name="radioLoc" value="Office">
            <label for="radioOffice">{{Office}}</label>
            &nbsp;
            <input type="radio" id="radioOthers" [(ngModel)]="ObjRegisterDetail.Deliveryat" (change)="rdChange('Others')" name="radioLoc" value="Others">
            <label for="radioOthers">{{Others}}</label>
          </div>
        </div>
        </div>
        </div>
        </div>
        <!-- <label class="label">Email</label>
        <input type="email" name="email" [(ngModel)]="ObjRegister.Email" (ngModelChange)="isValidEmail($event)" class="relative flex items-center bg-black-100 text-black border-gray-200 rounded px-4 min-h-11  w-full focus:border-primary" />
        <p class="caption status-danger" *ngIf="showlabelEmail">Please enter Email</p>
        <p class="caption status-danger" *ngIf="!showvalidationEmail">Please enter valid Email</p> -->
        
    

        <div *ngIf="AddressVisible">
<div class="row">
  <div class="col-md-3 col-sm-12 ">
    <div class="form-group">
  <label class="label">{{lblArea}}</label>
     <nb-select fullWidth [(ngModel)]="ObjRegisterDetail.Area"  name="ddlArea"  [disabled]="OrderType == 'Delivery'">
      <nb-option *ngFor="let _data of ddlArea" [value]="_data.AreaId">{{_data.AreaName}}</nb-option>
    </nb-select>
  <p class="caption status-danger" *ngIf="Area">Please select Area</p>
  </div></div>
  <div class="col-md-3 col-sm-12 ">
    <div class="form-group">
  <label class="label">{{flatApartment}}</label>
          <input type="text" fullWidth nbInput  [(ngModel)]="ObjRegisterDetail.Flat" name="Flatn"  />
          <p class="caption status-danger" *ngIf="Flat">{{PleasenterFlat}}</p>
          </div></div>
      <div class="col-md-3 col-sm-12 ">
        <div class="form-group">
          <label class="label">{{Floor}} #</label>
          <input type="text" fullWidth nbInput  [(ngModel)]="ObjRegisterDetail.Floor" name="FloorN"  />
      </div></div>
      <div class="col-md-3 col-sm-12 ">
        <div class="form-group">
        <label class="label">{{Building}} #</label>
        <input type="text" fullWidth nbInput  [(ngModel)]="ObjRegisterDetail.BuildingNo" name="BuildingN"  />
        <p class="caption status-danger" *ngIf="BuildingNo">{{PleaseenterBuildingNo}}</p>
</div></div>
</div>
          
 
<div class="row">

<div class="col-md-3 col-sm-12 ">
  <div class="form-group">
          <label class="label">{{lblRoad}} #</label>
          <input type="text" fullWidth nbInput  [(ngModel)]="ObjRegisterDetail.Road" name="RoadN"  />
          <p class="caption status-danger" *ngIf="Road">{{PleaseenterRoad}}</p>
          </div></div>
          <div class="col-md-3 col-sm-12 ">
            <div class="form-group">
            <label class="label">{{lblBlock}} #</label>
            <input type="text" fullWidth nbInput  [(ngModel)]="ObjRegisterDetail.Block" name="BlockN"  />
            <p class="caption status-danger" *ngIf="Block">{{PleaseenterBlock}}</p>
  </div></div>
  <div class="col-md-3 col-sm-12 ">
    <div class="form-group">
    <label class="label">{{BuildingName}}</label>
    <input type="text" fullWidth nbInput  [(ngModel)]="ObjRegisterDetail.BuildingName" name="BuildingName"  />
    </div></div>
<div class="col-md-3 col-sm-12 ">
  <div class="form-group">
    <label class="label">{{AdditionalDirectionsOptional}}</label>
    <input fullWidth nbInput  [(ngModel)]="ObjRegisterDetail.AdditionalDirection"  name="AdditionalDirection" />
    </div></div>
</div>

</div>
        <div *ngIf="!AddressVisible">
          <div class="row">
  <div class="col-md-3 col-sm-12 ">
    <div class="form-group">
          <label class="label">{{DeliveryAddress}}</label>
          <textarea type="text" fullWidth nbInput [(ngModel)]="ObjRegisterDetail.Address" name="Address" class="textarea relative flex items-center bg-black-100 text-black border-gray-200 rounded px-4 min-h-11 w-full focus:border-primary"></textarea>
          <p class="caption status-danger" *ngIf="Address">Please Delivery Address</p>
</div></div>
<div class="col-md-3 col-sm-12 ">
  <div class="form-group">
          <label class="label">{{AdditionalDirectionsOptional}}</label>
          <textarea type="text" fullWidth nbInput  [(ngModel)]="ObjRegisterDetail.AdditionalDirection" name="AdditionalDirection" class="textarea relative flex items-center bg-black-100 text-black border-gray-200 rounded px-4 min-h-11 w-full focus:border-primary"></textarea>
          <p class="caption status-danger" *ngIf="Deliveryat">Please additional directions</p>
</div></div>
</div>
        </div>
     
        <div class="row">
          <div class="col-md-3 col-sm-12 ">
          <div class="form-group">
          <label class="label">{{VATNo}}</label>
          <input type="text" fullWidth nbInput maxlength="25" (keypress)="numberOnly($event)" [(ngModel)]="ObjRegister.VATNo" name="VATNo" />
          </div>
          </div>
          <div class="col-md-3 col-sm-12 ">
          <div class="form-group">
          <label class="label">{{Companyname}}</label>
          <input type="text" fullWidth nbInput name="Company" [(ngModel)]="ObjRegister.Company"  />
          </div>
          </div>

          <div class="col-md-3 col-sm-12 ">
            <div class="form-group">
              <label class="label">{{MaxCreditLimit}}</label>
              <input type="text" fullWidth maxlength="5" nbInput name="MaxCreditLimit" (keypress)="numberOnly($event)" [(ngModel)]="ObjRegister.MaxCreditLimit" />
            </div>
          </div>
          <div class="col-md-3 col-sm-12 " style="text-align: right;padding-top: 15px;">
            <button class="btnfinish" (click)="btnSave()">{{buttonSave}}</button>&nbsp;&nbsp;
            <button class="btnfinish" (click)="btnclose()">{{Cancel}}</button>
          </div>

          </div>
      
    
  
    </form>
    
      

    </div>

  </div>

    

