import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataserviseService } from '../../shared/dataservice';
import { UtilitiesService } from '../../shared/utilities.service';
import { PopupService } from '../../shared/popup';
import { RejectPopupComponent } from '../../pages/live/liveorder/rejectpopup/rejectpopup.component';
import { TranslatePipe } from '../../shared/pipe/translate.pipe';

@Component({
  selector: 'ngx-transferorderpopup',
  templateUrl: './transferorderpopup.component.html',
  styleUrls: ['./transferorderpopup.component.scss']
})
export class TransferorderpopupComponent implements OnInit {

  constructor(private _popup:PopupService ,private translate: TranslatePipe, private UtilitiesService: UtilitiesService, private popup: PopupService, private dataservice: DataserviseService, public dialog: MatDialog, public dialogRef: MatDialogRef<TransferorderpopupComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  cultures: any;
  PendingOrders: any = this.translate.transform('PendingOrders');
  TransferSelectedOrder: any = this.translate.transform('TransferSelectedOrder');
  TransferAllOrders: any = this.translate.transform('TransferAllOrders');
  PrintOrder: any = this.translate.transform('PrintOrder');
  RePrintOrder: any = this.translate.transform('RePrintOrder');
  OrderNo: any = this.translate.transform('OrderNo.');
  OrderDate: any = this.translate.transform('OrderDate');
  OrderType: any = this.translate.transform('OrderType');
  TableNo: any = this.translate.transform('TableNo.');
  Customer: any = this.translate.transform('Customer');
  PhoneNo: any = this.translate.transform('PhoneNo');
  Qty: any = this.translate.transform('Qty');
  Description: any = this.translate.transform('Description');
  Amount: any = this.translate.transform('Amount');
  SubTotal: any = this.translate.transform('SubTotal');
  Discount: any = this.translate.transform('Discount');
  Tax: any = this.translate.transform('Tax');
  DeliveryFee: any = this.translate.transform('Delivery Fee');
  NetAmount: any = this.translate.transform('NetAmount');
  SpecialInstruction: any = this.translate.transform('SpecialInstruction');
  TransferOrder: any = this.translate.transform('TransferOrder');
  TotalPendingOrders : any = this.translate.transform('TotalPendingOrders');
  lstUser = [];
PosID:any;
DayID:any;
ShiftID:any;

  ngOnInit(): void {debugger
    if (sessionStorage.getItem('ObjCompany') != null && sessionStorage.getItem('ObjCompany') != undefined) {
      this.ObjCompany = JSON.parse(sessionStorage.getItem('ObjCompany'));
    }
    if (localStorage.getItem('SelectedBranchRow'+this.UtilitiesService.getURLCompanyName()) != null && localStorage.getItem('SelectedBranchRow'+this.UtilitiesService.getURLCompanyName()) != undefined) {
      this.SelectedBrancRow = JSON.parse(localStorage.getItem('SelectedBranchRow'+this.UtilitiesService.getURLCompanyName()));
      this.SelectedOutletID = this.SelectedBrancRow.ID;
    }
    if (localStorage.getItem('sessionObject'+this.UtilitiesService.getURLCompanyName()) != null && localStorage.getItem('sessionObject'+this.UtilitiesService.getURLCompanyName()) != undefined) {
      this.lstUser = JSON.parse(localStorage.getItem('sessionObject'+this.UtilitiesService.getURLCompanyName()));
    }
    this.cultures = this.data["culture"];
    this.PosID = this.data["PosID"];
    this.DayID = this.data["DayID"];
    this.ShiftID = this.data["ShiftID"];
    this.LoadGrid();
    
  }

  ObjCompany: Company = new Company();
  ObjOrderMaster: OrderMaster = new OrderMaster();
  ArrOrderDetail: OrderDetail[] = [];
  SearchInList: any = '';
  HighlightRow: any;
  CurrencyCode: any = '';
  SelectedBrancRow: any;
  SelectedOutletID: any;

  lstarray = [];
  lstBasket: any = [];
  lstSelectModifier: any = [];
  OrderID: any;
  btnclose(): void {
    debugger
    this.dialogRef.close();
  }
  PendingOrderCount : any = 0;
  LoadGrid() {debugger
    this.lstarray = [];
    if (this.SelectedOutletID != undefined && this.SelectedOutletID != null) {
      this.dataservice.get('pos/GetPendingOrdersforTransfer/' + this.UtilitiesService.getSubscriptionID() + '/' + this.SelectedOutletID + '/' + localStorage.getItem('LanguageCulture'+this.UtilitiesService.getURLCompanyName()) +'/'+this.PosID+'/'+this.DayID+'/'+this.ShiftID).subscribe(data => {

        debugger;
        if (this.isArrayHasValue(data[0]["PendingOrders"])) {
          this.lstarray = data[0]["PendingOrders"];
          this.getMasterDetailData(data[0]['PendingOrders'][0]["OrderID"], 0);
          this.PendingOrderCount = this.lstarray.length;

        }
      else{
        this.ObjOrderMaster = new OrderMaster();
        this.ArrOrderDetail = [];

      }
      });

    }
    else {
      alert('Please Select Outlet first.');
    }
  }
   win;
  // KitchenRePrintClick() {
  //   debugger
  //   if (this.lstPrinterserversetting.length > 0) {
  //     if (this.lstPrinterserversetting[0]['IsKOTDirectPrint'] == 2) {
  //       let link = this.lstPrinterserversetting[0]['ServerLink'] + '/Default?O=' + this.OrderID + '&C=' + this.UtilitiesService.getSubscriptionID() + '&type=KRePrint' + '&OutletID=' + this.ObjOrderMaster.OutletId + '&ShiftID=' + this.ObjOrderMaster.ShiftID;
  //       this.win = window.open(link, '_blank', 'width=300,height=200');
  //     }
  //     if (this.lstPrinterserversetting[0]['IsKOTDirectPrint'] == 3) {
  //       this.ReceiptURL = this.UtilitiesService.getKitchenReceiptURL(this.UtilitiesService.getEncrypt(this.UtilitiesService.getCompanyName()), this.UtilitiesService.getEncrypt(this.OrderID), this.lstUser[0][0]['CountryCode']);
  //       this.checkIframeLoaded();
  //     }
  //   }
  //   else {
  //     this.ReceiptURL = this.UtilitiesService.getKitchenReceiptURL(this.UtilitiesService.getEncrypt(this.UtilitiesService.getCompanyName()), this.UtilitiesService.getEncrypt(this.OrderID), this.lstUser[0][0]['CountryCode']);
  //     this.checkIframeLoaded();
  //   }
  // }
  // ReceiptRePrintClick() {
  //   debugger
  //   if (this.lstPrinterserversetting.length > 0) {
  //     if (this.lstPrinterserversetting[0]['IsReceiptDirectPrint'] == 2) {
  //       let link = this.lstPrinterserversetting[0]['ServerLink'] + '/Default?O=' + this.OrderID + '&C=' + this.UtilitiesService.getSubscriptionID() + '&type=ReceiptREPRINT' + '&OutletID=' + this.ObjOrderMaster.OutletId + '&ShiftID=' + this.ObjOrderMaster.ShiftID;
  //       this.win = window.open(link, '_blank', 'width=300,height=200');
  //     }
  //     if (this.lstPrinterserversetting[0]['IsReceiptDirectPrint'] == 3) {
  //       this.ReceiptURL = this.UtilitiesService.getReceiptURL(this.UtilitiesService.getEncrypt(this.UtilitiesService.getCompanyName()), this.UtilitiesService.getEncrypt(this.OrderID), this.lstUser[0][0]['CountryCode']);
  //       this.checkIframeLoaded();
  //     }
  //     if (this.lstPrinterserversetting[0]['IsReceiptDirectPrint'] == 4) {
  //       const url = this.UtilitiesService.geta4SizeReport(this.UtilitiesService.getEncrypt(this.UtilitiesService.getCompanyName()), this.UtilitiesService.getEncrypt(this.OrderID));
  //     this.ReceiptURL = url;
  //     this.checkIframeLoaded();
  //     }
      

  //   } else {
  //     this.ReceiptURL = this.UtilitiesService.getReceiptURL(this.UtilitiesService.getEncrypt(this.UtilitiesService.getCompanyName()), this.UtilitiesService.getEncrypt(this.OrderID), this.lstUser[0][0]['CountryCode']);
  //     this.checkIframeLoaded();
  //   }
  // }
  // CancelOrderClickPrint() {
  //   debugger
  //   if (this.lstPrinterserversetting.length > 0) {
  //     if (this.lstPrinterserversetting[0]['IsReceiptDirectPrint'] == 2) {
  //       let link = this.lstPrinterserversetting[0]['ServerLink'] + '/Default?O=' + this.OrderID + '&C=' + this.UtilitiesService.getSubscriptionID() + '&type=CANCELORDERPRINT' + '&OutletID=' + this.ObjOrderMaster.OutletId + '&ShiftID=' + this.ObjOrderMaster.ShiftID;
  //       this.win = window.open(link, '_blank', 'width=300,height=200');
  //     }
  //     if (this.lstPrinterserversetting[0]['IsReceiptDirectPrint'] == 3) {
  //       this.ReceiptURL = this.UtilitiesService.getReceiptURL(this.UtilitiesService.getEncrypt(this.UtilitiesService.getCompanyName()), this.UtilitiesService.getEncrypt(this.OrderID), this.lstUser[0][0]['CountryCode']);
  //       this.checkIframeLoaded();
  //     }
  //   }
  //   else {
  //     this.ReceiptURL = this.UtilitiesService.getReceiptURL(this.UtilitiesService.getEncrypt(this.UtilitiesService.getCompanyName()), this.UtilitiesService.getEncrypt(this.OrderID), this.lstUser[0][0]['CountryCode']);
  //     this.checkIframeLoaded();
  //   }
  // }

 
  ReviewClick(event) {
    debugger

    this.OrderID = event.OrderID;
    this.getorderdetails(event.OrderID)
    // this.dialogRef.close(event.OrderID);



  }
  SelectedOrderTransfer(): void {
    debugger
    if (this.OrderID != undefined && this.OrderID != null && this.OrderID != '') {
      let companyID = this.UtilitiesService.getSubscriptionID();
      let body =[companyID,this.OrderID,this.PosID,this.DayID,this.ShiftID];
      this.dataservice.post('pos/TransferSelectedOrder/', body).subscribe(data => {debugger
        if (data == 'Update') {
          this._popup.ShowPopup('Update');
         this.LoadGrid();
        }  
      });
    }
    else {
      alert('Please select Order first.');
    }
  }

  commaSeparatedOrderIds:any ='';
  TransferAllOrder(): void {debugger
    if (this.OrderID != undefined && this.OrderID != null && this.OrderID != '') {
      //  let data = this.ObjOrderMaster;
      // let result = objArray.map(a => a.foo);
       let lstOrderIDs = this.lstarray.map(t => t.OrderID);
       this.commaSeparatedOrderIds = lstOrderIDs.toString();
       let body =[this.PosID,this.DayID,this.ShiftID,this.commaSeparatedOrderIds];
       this.dataservice.post('pos/TransferAllOrders/', body).subscribe(data => {debugger
         if (data == 'Update') {
          this._popup.ShowPopup('Update');
          this.LoadGrid();
         }  
       });

    
    }
    else {
      alert('Please select order first.');
    }
  }
 

  getorderdetails(orderid: any) {
    debugger

    this.dataservice.get('pos/GetOrdersdetails/' + this.UtilitiesService.getSubscriptionID() + '/' + orderid).subscribe(data => {
      debugger
      if (this.isArrayHasValue(data[0]['Ordersdetails'])) {
        this.lstBasket = data[0]['Ordersdetails'];
      }
      if (this.isArrayHasValue(data[0]['OrdersModifiers'])) {

        this.lstSelectModifier = [];

        let lst = data[0]['OrdersModifiers'];
        for (let i = 0; i < this.lstBasket.length; i++) {
          let row = lst.filter(t => t.ProductID == this.lstBasket[i].ProductID);
          this.lstSelectModifier.push(row);
        }
      }

    });
  }

  RefreshOrders(): void {

    this.LoadGrid();
  }

  getMasterDetailData(OrderID, index): void {
    this.HighlightRow = index;
    this.ObjOrderMaster = new OrderMaster();
    this.OrderID = OrderID;

    this.dataservice.get('pos/GetPendingOrdersdetails/' + this.UtilitiesService.getSubscriptionID() + '/' + OrderID + '/' + localStorage.getItem('LanguageCulture'+this.UtilitiesService.getURLCompanyName())+'/'+0)
      .subscribe(data => {

        debugger
        if (data[0]['Master']) {
          this.ObjOrderMaster = data[0]['Master'][0];
        }

        if (this.isArrayHasValue(data[0]['Details'])) {
          this.ArrOrderDetail = data[0]['Details'];
          console.log(this.ArrOrderDetail)
        }
        else {
          this.ArrOrderDetail = [];
        }
        // this.getReceipt();

      }, error => alert('error'));
  }
  // ReceiptURL: any = '';
  // getReceipt(): void {
  //   const CompanyName = this.UtilitiesService.getCompanyName();
  //   const url = this.UtilitiesService.getReceiptURL(this.UtilitiesService.getEncrypt(CompanyName), this.UtilitiesService.getEncrypt(this.ObjOrderMaster.OrderID), this.lstUser[0][0]['CountryCode']);
  //   this.ReceiptURL = url;
  // }


  isArrayHasValue(obj: any[]): boolean {
    let isArrayWithLength = Boolean(Array.isArray(obj) && obj.length);
    return isArrayWithLength;
  }




}
class OrderMaster {
  OrderID: any;
  TableNumber: any;
  OrderInvoiceNo: any = '';
  PrepIn: any = '';
  PrepInOutlet: any = '';
  OrderPerpStatus: any = 'ALL';
  Brand: any = '';
  OutletID: any = '';
  OutletId: any = '';
  OutletName: any = '';
  OrderType: any = '';
  Address: any = '';
  CustomerID: any = '';
  CustomerName: any = '';
  CustomerEmail: any = '';
  CountryCode: any = '';
  PhoneNo: any = '';
  PayType: any = '';
  Channel: any = '';
  PickupFrom: any = '';
  Remarks: any = '';
  SubTotal: any = '';
  TotalTaxAmount: any = '';
  TotalAmount: any = '';
  DeliveryFee: any = '';
  NetAmount: any = '';
  TotalDiscount: any = '';
  TaxID: any = '';
  TaxDescription: any = '';
  TaxPercent: any = '';
  OrderDate: any;
  OrderDateReal: any;
  CreatedOn: any;
  CreatedBy: any;
  ModifyOn: any;
  ModifyBy: any;
  RejectReasonID: any;
  RejectReason: any;
  RiderID: any;
  CompanyId: any;
  RejectReasonDetail: any = '';
  AddressPK: any = '';
  ShiftID: any;
  IsExtraCharge: any;
}
class OrderDetail {
  OrderDetailID: any;
  OrderID: any;
  ProductId: any;
  ProductName: any = '';
  Quantity: any;
  UnitID: any;
  AddOnID: any;
  AddOnName: any;
}

class Company {
  CompanyID: any;
  CompanyName: any;
  outletsCount: any;
  CountryID: any;
  hearbout: any;
  Mobile: any;
  CurrencyCode: any;
  Rate: any;
  NumOfDecimal: any;
  CountryCode: any;
  IsOTPRequired: boolean;
}
