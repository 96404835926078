import { Component, OnInit ,Inject} from '@angular/core';
import { DataserviseService } from '../../../shared/dataservice';
import { UtilitiesService } from '../../../shared/utilities.service';
import { SpinnerService } from '../../../shared/spinner';
import { PopupService } from '../../../shared/popup';
import { DatePipe } from '@angular/common';
import { MatDialogRef,MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { TranslatePipe } from '../../../shared/pipe/translate.pipe';
import { AboutUsComponent } from '../../../about-us/about-us.component';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'ngx-hotkeyspopup',
  templateUrl: './hotkeyspopup.component.html',
  styleUrls: ['./hotkeyspopup.component.scss']
})
export class HotKeysPopupComponent implements OnInit {


  lblHotKeys: any = this.translate.transform('HotKeysList');
  ProductNotFound: any = this.translate.transform('ProductNotFound');
  BarcodeProduct: any = this.translate.transform('BarcodeProduct');
  lstarray = [];
  OutletId: any;
  UserId: any;
  culture: any = 'en';
  CounterId: any;
  ModuleCode: any;
  CurrencyCode: any;
  NumOfDecimal: any;
  IsItemFound: boolean = false;
  SearchBarcode: any = '';

  constructor(private _decimalPipe: DecimalPipe, private dialog: MatDialog, private translate: TranslatePipe, public dialogRef: MatDialogRef<HotKeysPopupComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private Utilities: UtilitiesService,private dataservice: DataserviseService,private _spinnerService: SpinnerService,private popup: PopupService, private datePipe: DatePipe) { }


  ngOnInit(): void {

    debugger
    this.OutletId = this.data.OutletId;
    this.UserId = this.data.UserId;
    this.culture = this.data.Culture;
    this.CounterId = this.data.CounterId;
    this.ModuleCode = this.data.ModuleCode;
    this.CurrencyCode = this.data.CurrencyCode;
    this.NumOfDecimal = this.data.NumOfDecimal;

    this.LoadGrid();
  }
  SearchProduct(): void {
    this.IsItemFound = false;

    if (this.SearchBarcode == undefined && this.SearchBarcode.trim() == '') {
      this.IsItemFound = true;
      this.ProductNotFound = this.translate.transform('PleaseenterItemName');
      return;
    }

    this.LoadGrid();

  }

  LoadGrid() {
    debugger
    this.IsItemFound = false;
    this.lstarray = [];

    let body = [this.Utilities.getSubscriptionID(), this.OutletId, this.UserId, this.culture, this.CounterId, this.ModuleCode, this.SearchBarcode  ];
    this.dataservice.post('retailpos/GetPOSSetupHotKeys/', body).subscribe(data => {
      debugger
      if (this.isArrayHasValue(data[0].Items)) {
        debugger
        this.lstarray = data[0].Items;
      }
      else {
        this.ProductNotFound = this.translate.transform('ProductNotFound');
        this.IsItemFound = true;
      }
    })
  }

  ItemSelcted(row): void {
    this.dialogRef.close(row);
  }

  btnclose() : void
  {
    debugger
    this.dialogRef.close(undefined);
  }


  isArrayHasValue(obj: any[]): boolean {
    let isArrayWithLength = Boolean(Array.isArray(obj) && obj.length);
    return isArrayWithLength;
  }
}

