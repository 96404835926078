

<nb-card>

  <nb-card-body>
    <div class="row parent1">
      <div class="col-md-4">
        <div class="child1">
          {{SaleReturnList}}
        </div>
      </div>
      <div class="col-md-6"></div>
      <div class="col-md-2">
        <img src="./assets/images/lookupclose.png" (click)="dialogRef.close()" style="float: right; width: 25px;cursor: pointer;">

      </div>
    </div>
    <hr />
    <div class="row">
      <div class="col-md-2 col-sm-6">
        <div class="form-group">
          <label class="label">{{CreditNoteNo}}</label>
          <input nbInput fullWidth name="Id" [(ngModel)]="ObjSearchFilters.Id">

        </div>
      </div>
      <div class="col-md-2 col-sm-6">
        <div class="form-group">
          <label class="label">{{PhoneNo}}</label>
          <input nbInput fullWidth name="Id" [(ngModel)]="ObjSearchFilters.PhoneNo">

        </div>
      </div>

      <div class="col-md-2 col-sm-6">
        <div class="form-group">
          <label class="label">{{FromDate}}</label>
          <input nbInput fullWidth [(ngModel)]="_DateFrom" [nbDatepicker]="fromDate" name="ifromDate" autocomplete="off">
          <nb-datepicker #fromDate></nb-datepicker>
          <p class="caption status-danger" *ngIf="InvalidDateFrom">{{Invaliddate}}!</p>
        </div>
      </div>
      <div class="col-md-2 col-sm-6">
        <div class="form-group">
          <label class="label">{{ToDate1}}</label>
          <input nbInput fullWidth [(ngModel)]="_DateTo" [nbDatepicker]="ToDate" name="iToDate" autocomplete="off">
          <nb-datepicker #ToDate></nb-datepicker>
          <p class="caption status-danger" *ngIf="InvalidDateTo">{{Invaliddate}}!</p>
        </div>
      </div>

      <div class="col-md-2 col-sm-6">
        <div class="form-group">
          <label class="label">&nbsp;</label>
          <button class="btn btnfinish" (click)="getGridData()">{{Search}}</button>
        </div>
      </div>


    </div>
    <div class="row">
      <div class="col-md-12">
        <p class="caption status-danger" *ngIf="formRightsmessage">{{message}}</p>
      </div>
      <div class="col-md-12 col-sm-12">
        <div class="form-group">
          <div>
            <ng2-smart-table [settings]="settings" (userRowSelect)="onUserRowSelect($event)" (edit)="rowEdit($event)" (delete)="rowDelete($event)" [source]="ArrPaidOrders"></ng2-smart-table>

          </div>
          <br />
        </div>
      </div>
    </div>

    <iframe name="saleretReceipt" #printfarme id="saleretReceipt" [src]="ReceiptURL | SaleReturnUrlSafe" width="0" height="0" frameborder="0"></iframe>
    
  </nb-card-body>

</nb-card>
