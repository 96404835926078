import { Component, OnInit, Inject, ChangeDetectorRef  } from '@angular/core';
import { DataserviseService } from '../../shared/dataservice';
import { PopupService } from '../../shared/popup';
import { UtilitiesService } from '../../shared/utilities.service';
import { SpinnerService } from '../../shared/spinner';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {TranslatePipe} from  '../../shared/pipe/translate.pipe';
import { DecimalPipe } from '@angular/common';
import { SplitBillNumpadPopupComponent } from '../splitbillnumpad-popup/splitbillnumpad-popup.component';
@Component({
  selector: 'ngx-splitbillpopup',
  templateUrl: './splitbillpopup.component.html',
  styleUrls: ['./splitbillpopup.component.scss'],
})

export class SplitBillPopupComponent {


  CompanyId: any;
  CreatedBy: any = '';
  CreatedOn: any = '';
  ObjMasterData1: MasterData = new MasterData();
  ObjMasterData2: MasterData = new MasterData();
  lst1: Items[] = [];
  lst2: Items[] = [];
  HighlightRow: any;

  lstmodifir1: ModifierList[] = [];
  lstmodifir2: ModifierList[] = [];
  ObjOutletInfo: OutletInfo = new OutletInfo();

  CurrencyCode: any = '';
  NoOfDecimal: any = '';
  btnDisabled: boolean = true;
  FirstBill : any = this.translate.transform('FirstBill');
  SecondBill : any = this.translate.transform('SecondBill');
  SplitBills : any = this.translate.transform('SplitBills');
  Price : any = this.translate.transform('Price');
  OrderNo : any = this.translate.transform('OrderNo.');
  OrderDate : any = this.translate.transform('OrderDate');
  lblOrderType : any = this.translate.transform('Order Type');
  TableNo : any = this.translate.transform('TableNo.');
  Customer : any = this.translate.transform('Customer');
  PhoneNo : any = this.translate.transform('PhoneNo');
  Qty : any = this.translate.transform('Qty');
  Description : any = this.translate.transform('Description');
  Amount : any = this.translate.transform('Amount');
  SubTotal: any = this.translate.transform('NetTotal');
  Discount : any = this.translate.transform('Discount');
  Tax: any = this.translate.transform('VAT');
  lblDeliveryFee : any = this.translate.transform('Delivery Fee');
  NetAmount: any = this.translate.transform('lblFinalTotal');

  Culture : any = sessionStorage.getItem('LanguageCulture');

  IsExtraCharge: boolean = false;
  ExtraChargeDescription: any = '';
  ExtraChargeDescriptionArabic: any = '';
  ExtraChargePercentage: any;

  ExtraChargeAmount1: any;
  ExtraChargeAmount2: any = 0;

  NumOfDecimal: any;
  comboproduct: any = [];

  lstCombo1: any = [];
  lstCombo2: any = [];

  IsPercentPromoDiscount: boolean = false;
  ISCalculateTaxProductWise: boolean = false;
  constructor(private translate : TranslatePipe,private changeDetection: ChangeDetectorRef, public dialogRef: MatDialogRef<SplitBillPopupComponent>, private dialog: MatDialog, @Inject(MAT_DIALOG_DATA) private data: any, private _spinnerService: SpinnerService, private Utilities: UtilitiesService, private dataservice: DataserviseService, private popup: PopupService, private _decimalPipe: DecimalPipe) {
    debugger
    this.lst1 = JSON.parse( data.dt);
    this.ObjMasterData1.OrderID = this.lst1[0].OrderID;
    this.ObjMasterData1.OrderInvoiceNo = this.lst1[0].OrderInvoiceNo;
    this.ObjMasterData1.TableNumber = this.lst1[0].TableNumber;
    this.ObjMasterData1.TotalAmount = data.Subtoal;
    this.ObjMasterData1.TotalDiscount = 0.00;//data.DiscountAmont;
    this.ObjMasterData1.TotalTaxAmount = data.VAT;
    this.ObjMasterData1.NetAmount = data.Total;
    this.ObjMasterData1.OrderType = this.lst1[0]['OrderType'];
    this.ObjMasterData1.OrderTypeID = this.lst1[0]['OrderTypeID'];
    this.ObjMasterData1.ExtraChargeAmount = this.lst1[0]['ExtraChargeAmount'];

    this.lstmodifir1 = Array.prototype.concat.apply([], data.modifier);
  

    this.IsExtraCharge = data.ObjOutletInfo.IsExtraCharge;
    this.ExtraChargeDescription = data.ObjOutletInfo.ExtraChargeDescription;
    this.ExtraChargeDescriptionArabic = data.ObjOutletInfo.ExtraChargeDescriptionArabic;

    this.ExtraChargePercentage = data.ObjOutletInfo.ExtraChargePercentage;

    this.CurrencyCode = data.CurrencyCode;
    this.NoOfDecimal = data.NoOfDecimal;
    this.ObjOutletInfo = data.ObjOutletInfo;
    this.NumOfDecimal = data.NoOfDecimal;
    this.comboproduct = data.comboproduct;

    if (this.data.IsPercentPromoDiscount != undefined)
      this.IsPercentPromoDiscount = this.data.IsPercentPromoDiscount;
    debugger
    if (this.data.ISCalculateTaxProductWise != undefined)
      this.ISCalculateTaxProductWise = this.data.ISCalculateTaxProductWise;

    this.lstCombo1 = Array.prototype.concat.apply([], this.comboproduct);
   

    //console.log(this.comboproduct);
    //console.log(this.lst1);
    //console.log(this.lstmodifir1);
  }


  ngOnInit(): void {
 debugger
    this.CompanyId = this.Utilities.getSubscriptionID();
    this.CreatedBy = this.Utilities.getUserId();
    this.CreatedOn = this.Utilities.GetCurrentDateTime();
    
    this.ObjMasterData1.TotalTaxAmount = this.getDeciamValue(this.ObjMasterData1.TotalTaxAmount);
    this.ObjMasterData1.NetAmount = this.getDeciamValue(this.ObjMasterData1.NetAmount);
    this.ObjMasterData1.ExtraChargeAmount = this.getDeciamValue(this.ObjMasterData1.ExtraChargeAmount);

  }



  replaceAstrophe(val): any {
    if (val != null && val != undefined) {
      return val.replaceAll("'", "`");
    }
    
  }

  btnclose()
  {
      this.dialogRef.close(undefined);
  }

  List1ClickBindings(data, index, qty) {
    debugger
    let generate_rowid = Math.floor(100000 + Math.random() * 900000);

    let rowid;
    if (this.lst2.length > 0) {
      const lastElement = this.lst2[this.lst2.length - 1];
      rowid = lastElement.rowid + 1;
    } else {
      rowid = 1;
    }
    debugger
    let lst: any = [];
    lst.push(this.lstmodifir1.filter((x) => { return x.ProductID == data.ProductID && x.rowid === data.rowid }));

    this.lst2.push({
      'AddOnID': data.AddOnID,
      'Amount': data.Amount,
      'DisAmount': data.DiscountPrice,
      'DiscountPrice': data.DiscountPrice,
      'IsSelected': 1,
      'NetAmount': data.NetAmount,
      'OrderID': data.OrderID,
      'OrderInvoiceNo': data.OrderInvoiceNo,
      'Price': data.Price,
      'ProductID': data.ProductID,
      'ProductName': this.replaceAstrophe(data.ProductName),
      'Quantity': qty,
      'TableNumber': data.TableNumber,
      'TaxAmount': data.TaxAmount,
      'TotalAmount': data.TotalAmount,
      'TotalDiscount': data.TotalDiscount,
      'TotalTaxAmount': data.TotalTaxAmount,
      'rowid': lst.length > 0 ? generate_rowid : rowid,
      'pk': '123',
      'ProductCategoryID': data.ProductCategoryID,
      'ProductDetailID': data.ProductDetailID,
      'ProductSizeID': data.ProductSizeID,
      'AProductName': this.replaceAstrophe(data.AProductName),
      'ProductSizeName': data.ProductSizeName,
      'SpecialRequest': data.SpecialRequest,
      'DelFlag': data.DelFlag,
      'TaxRate': data.TaxRate,
      'ExtraChargePercentage': data.ExtraChargePercentage,
      'IsExtraCharge': data.IsExtraCharge,
      'ComboProductId': data.ComboProductId,
      'TaxPercent': data.TaxPercent,
      'ProductDiscount': data.ProductDiscount,
      'PriceWithOutVAT': data.PriceWithOutVAT,
      'DiffPriceWithOutVAT': data.DiffPriceWithOutVAT,
      'DiffProductDiscount': data.DiffProductDiscount,
      'DiffTaxAmount': data.DiffTaxAmount
    });

    for (var i = 0; i < this.lstmodifir1.length; i++) {
      if (this.lstmodifir1[i].ProductID == data.ProductID && this.lstmodifir1[i].rowid === data.rowid) {
        // Create a shallow copy of the object
        const copiedObject = { ...this.lstmodifir1[i] };

        // Update the property of the copied object
        copiedObject.rowid = generate_rowid;

        // Push the copied object into lstmodifir2
        this.lstmodifir2.push(copiedObject);
      }
    }

    for (var i = 0; i < this.lstCombo1.length; i++) {
      if (this.lstCombo1[i].MainProductID == data.ProductID && this.lstCombo1[i].rowid === data.rowid) {
        // Create a shallow copy of the object
        const copiedObject = { ...this.lstCombo1[i] };

        // Update the property of the copied object
        copiedObject.rowid = generate_rowid;

        // Push the copied object into lstmodifir2
        this.lstCombo2.push(copiedObject);
      }
    }
    //this.lstmodifir2.push(this.lstmodifir1.filter((x) => { return x.ProductID == data.ProductID && x.rowid === data.rowid }));

    //this.lstmodifir2.forEach((element, index) => {
    //  this.lstmodifir2[index].rowid = generate_rowid;
    //});

    

 

    this.lst1[index].Quantity = data.Quantity - qty;
    if (this.lst1[index].Quantity == 0) {
      this.lst1.splice(index, 1);
      this.lstmodifir1.filter(x => x.ProductID == data.ProductID && x.rowid == data.rowid).forEach(x => this.lstmodifir1.splice(this.lstmodifir1.indexOf(x), 1));
  
    }

    debugger;

    this.changeDetection.detectChanges();


    ///////////////////////////////////////////////////////// Calculation ///////////////////////////////////////////////


    this.plusquntyList2(data, index);
    this.ObjMasterData2.TotalAmount = parseFloat(this.Subtoal2);// + parseFloat(this.ModifiresAmount2);
    // this.ObjMasterData2.TotalDiscount = this.DiscountAmont2;
    this.ObjMasterData2.TotalTaxAmount = this.VAT2;
    this.ObjMasterData2.NetAmount = this.Total2;
    this.ObjMasterData2.ExtraChargeAmount = this.ExtraChargeAmount2;

    this.minuquntyList1(data, index);
    this.ObjMasterData1.TotalAmount = parseFloat(this.Subtoal1);// + parseFloat(this.ModifiresAmount1);
    // this.ObjMasterData1.TotalDiscount = this.DiscountAmont1;
    this.ObjMasterData1.TotalTaxAmount = this.VAT1;
    this.ObjMasterData1.NetAmount = this.Total1;
    this.ObjMasterData1.ExtraChargeAmount = this.ExtraChargeAmount1;
    this.btnDisabled = false;
  }

  getDeciamValue(val): any {
    return this._decimalPipe.transform(val, '1.' + this.NoOfDecimal + '-' + this.NoOfDecimal).replace(',', '');
  }
  lst1Click(data, event, index): void {
    debugger
    let msg: any = 'At least one item should be exists in this list!';

    this.HighlightRow = index;


    if (this.lst1[index].Quantity > 1) {
      const dialogRef = this.dialog.open(SplitBillNumpadPopupComponent, {
        width: '430px',
        height: '450px',
        data: { qty: data.Quantity }
      });

      dialogRef.afterClosed().subscribe(result => {
        debugger
        if (result != undefined) {

          if (this.lst1.length <= 1 && this.lst1[index].Quantity <= result) {
            alert(msg);
            return;
          } else {
            this.List1ClickBindings(data, index, result);
          }
        }

      });
    } else {
      if (this.lst1.length <= 1 && this.lst1[index].Quantity <= 1) {
        alert(msg);
        return;
      } else {
        this.List1ClickBindings(data, index, this.lst1[index].Quantity);
        //this.lst1.splice(index, 1);
      }
    }

  }

  lst2Click(data, event, index):void {
    debugger
    this.HighlightRow = index;

    this.lst1.push({
      'AddOnID': data.AddOnID,
      'Amount': data.Amount,
      'DisAmount': data.DiscountPrice,
      'DiscountPrice': data.DiscountPrice,
      'IsSelected': 1,
      'NetAmount': data.NetAmount,
      'OrderID': data.OrderID,
      'OrderInvoiceNo': data.OrderInvoiceNo,
      'Price': data.Price,
      'ProductID': data.ProductID,
      'ProductName': this.replaceAstrophe(data.ProductName),
      'Quantity': data.Quantity,
      'TableNumber': data.TableNumber,
      'TaxAmount': data.TaxAmount,
      'TotalAmount': data.TotalAmount,
      'TotalDiscount': data.TotalDiscount,
      'TotalTaxAmount': data.TotalTaxAmount,
      'rowid': data.rowid,
      'pk': '22',
      'ProductCategoryID': data.ProductCategoryID,
      'ProductDetailID': data.ProductDetailID,
      'ProductSizeID': data.ProductSizeID,
      'AProductName': this.replaceAstrophe(data.AProductName),
      'ProductSizeName': data.ProductSizeName,
      'SpecialRequest': data.SpecialRequest,
      'DelFlag': data.DelFlag,
      'TaxRate': data.TaxRate,
      'ExtraChargePercentage': data.ExtraChargePercentage,
      'IsExtraCharge': data.IsExtraCharge,
      'ComboProductId': data.ComboProductId,
      'TaxPercent': data.TaxPercent,
      'ProductDiscount': data.ProductDiscount,
      'PriceWithOutVAT': data.PriceWithOutVAT,
      'DiffPriceWithOutVAT': data.DiffPriceWithOutVAT,
      'DiffProductDiscount': data.DiffProductDiscount,
      'DiffTaxAmount': data.DiffTaxAmount
    });

    this.lstmodifir1.push(...this.lstmodifir2.filter((x) => { return x.ProductID == data.ProductID && x.rowid === data.rowid }));

    this.lstmodifir2.filter(x => x.ProductID == data.ProductID && x.rowid == data.rowid).forEach(x => this.lstmodifir2.splice(this.lstmodifir2.indexOf(x), 1));

    this.lstCombo1.push(...this.lstCombo2.filter((x) => { return x.MainProductID == data.ProductID && x.rowid === data.rowid }));

    this.lstCombo2.filter(x => x.MainProductID == data.ProductID && x.rowid == data.rowid).forEach(x => this.lstCombo2.splice(this.lstCombo2.indexOf(x), 1));

    let ModifierPrice = 0;

    //for (var i = 0; i < this.lstmodifir1.length; i++) {
    //  if (!isNaN(this.lstmodifir1[i].Price)) {
    //    if (this.lstmodifir1[i].ProductID == data.ProductID && this.lstmodifir1[i].rowid == data.rowid){
    //      ModifierPrice += this.lstmodifir1[i].Price * data.Quantity;
    //    }
    //  }
    //}


     

    this.changeDetection.detectChanges();

    this.lst2.splice(index, 1);
    this.changeDetection.detectChanges();



    this.plusquntyList1(data, index);
    this.minuquntyList2(data, index);

    this.ObjMasterData1.TotalAmount = parseFloat(this.Subtoal1);// + parseFloat(this.ModifiresAmount1);
    // this.ObjMasterData1.TotalDiscount = this.DiscountAmont1;
    this.ObjMasterData1.TotalTaxAmount = this.VAT1;
    this.ObjMasterData1.NetAmount = this.Total1;
    this.ObjMasterData1.ExtraChargeAmount = this.ExtraChargeAmount1;

    this.ObjMasterData2.TotalAmount = this.getDeciamValue(parseFloat(this.Subtoal2));// + parseFloat(this.ModifiresAmount2));
    // this.ObjMasterData2.TotalDiscount = this.DiscountAmont2;
    this.ObjMasterData2.TotalTaxAmount = this.getDeciamValue(this.VAT2);
    this.ObjMasterData2.NetAmount = this.getDeciamValue(this.Total2);
    this.ObjMasterData2.ExtraChargeAmount = this.getDeciamValue(this.ExtraChargeAmount2);
    if (this.lst2.length < 1) {
      this.btnDisabled = true;
      this.ObjMasterData2.TotalAmount = 0.00;
      this.ObjMasterData2.TotalTaxAmount = 0.00;
      this.ObjMasterData2.NetAmount = 0.00;
      this.ObjMasterData2.ExtraChargeAmount = 0.00;
    }
  }

  ModifiresAmount1: any = 0;
  SubtoalForVAT1: any = 0;
  VAT1: any = 0;
  DiscountAmont1: any = 0;
  Total1: any = 0;
  Subtoal1: any = 0;

  ModifiresAmount2: any = 0;
  SubtoalForVAT2: any = 0;
  VAT2: any = 0;
  DiscountAmont2: any = 0;
  Total2: any = 0;
  Subtoal2: any = 0;

  plusquntyList1(row, index) {
    debugger
    //this.lst1[index].Quantity += 1;
    let lst = this.lstmodifir1;
    if (lst != undefined) {
      this.ModifiresAmount1 = this.CalculateModifireAmount(lst);
      // this.ModifiresAmount1 = this.ModifiresAmount1;
    }
    
    this.StartCalculation1();
  }
  minuquntyList1(row, index) {
    debugger;
    //if (this.lst1[index].Quantity == 1) {
    //  return;
    //}
    debugger
    //this.lst1[index].Quantity -= 1;
    let lst = this.lstmodifir1;
    if (lst != undefined) {
      this.ModifiresAmount1 = this.CalculateModifireAmount(lst);
      // this.ModifiresAmount1 = this.ModifiresAmount1;
    }
    this.StartCalculation1();
    //this.CalculateTaxMinusEvent1(row, index);
  }

  plusquntyList2(row, index) {
    debugger
    //this.lst2[index].Quantity += 1;
    let lst = this.lstmodifir2;
    if (lst != undefined) {
      this.ModifiresAmount2 = this.CalculateModifireAmount(lst);
    }
    this.StartCalculation2();
    //this.CalculateTaxPlusEvent2(row);
  }
  minuquntyList2(row, index) {
    //if (this.lst2[index].Quantity == 1) {
    //  return;
    //}
    //this.lst2[index].Quantity -= 1;
    let lst = this.lstmodifir2;
    if (lst != undefined) {
      this.ModifiresAmount2 = this.CalculateModifireAmount(lst);
      // this.ModifiresAmount2 = this.ModifiresAmount2;
    }
    this.StartCalculation2();
    //this.CalculateTaxMinusEvent2(row, index);
  }

  TotalAmountforDiscount1: number = 0;
  TotalVatAmount1: number = 0;
  SubTotalAmount1: number = 0;
  PromoAmount1: any=0;

  /*Product Wies Variables*/
  ProductVat1: number = 0;
  productDiscount1: number = 0;
  TotalDiscountAmount1: number = 0;
  ProdcutTotalAmount1: number = 0;
  productmodifiresum1: number = 0;
  productsubtotal1: number = 0;
  productTax1: number;

  /*Product Wies Variables*/
  ExtraChargeProductVat1: number = 0;
  ExtraChargeproductDiscount1: number = 0;
  ExtraChargeproductmodifiresum1: number = 0;
  ExtraChargeproductsubtotal1: number = 0;
  ExtraChargeproductTax1: number;
  /*Discount Variables */
  VATOnDiscountAmount1: number = 0;
  DiscountAmountWithOutVAT1: number = 0;
  PromoAmountforVisibility1: any;

  lstName1: any = '';
  StartCalculation1() {
    debugger
   
    this.TotalAmountforDiscount1 = this.Total1;
    this.TotalVatAmount1 = this.VAT1;
    this.SubTotalAmount1 = this.Subtoal1;


    this.Subtoal1 = 0;
    this.PromoAmountforVisibility1 = 0;
    this.VAT1 = 0;
    this.TotalDiscountAmount1 = 0;
    this.ProdcutTotalAmount1 = 0;
    this.Total1 = 0;
    this.DiscountAmountWithOutVAT1 = 0;
    this.VATOnDiscountAmount1 = 0;
    this.productDiscount1 = 0;
    this.ExtraChargeAmount1 = 0;
    this.ExtraChargeProductVat1 = 0;
    this.ExtraChargeproductDiscount1 = 0;
    this.ExtraChargeproductmodifiresum1 = 0;

    this.ExtraChargeproductTax1 = 0;
    if (this.lst1.length > 0) {
      this.CalculateTotalDiscountAmount1();
      for (var i = 0; i < this.lst1.length; i++) {
        let ProdcutPrice: any;
        let ModifirePrice: any;
        let BogoAmount: any = 0;
        let ComboProductAmount: any;

        this.ExtraChargeproductsubtotal1 = 0;
        this.lstName1 = '';

        if (this.lst1[i].DelFlag == null || this.lst1[i].DelFlag == false) {
          ProdcutPrice = this.lst1[i].Price * parseFloat(this.lst1[i].Quantity);
          for (var j = 0; j < this.lstmodifir1.length; j++) {
        
            if (this.lstmodifir1[j] != undefined && this.lstmodifir1[j].ProductID == this.lst1[i].ProductID) {
              if (this.lst1[i].rowid == this.lstmodifir1[j].rowid && this.lstmodifir1[j].Price > 0) {
                ModifirePrice = (this.lstmodifir1[j].Price * parseFloat(this.lst1[i].Quantity));

                  this.lstName1 = 'lstModifier';
                  this.CalculateDiscountAmount1(0 + ModifirePrice + 0);
                  this.CallingCalculationMethod1(0, ModifirePrice, 0, 0, 0, j);

                }
              if (this.lstmodifir1[j].IsBogo == true) {
                  BogoAmount += this.lst1[i].Price * parseFloat(this.lst1[i].Quantity);
                }
              }
            
          }

          if (this.lstCombo1.filter(t => t.rowid == this.lst1[i].rowid && (this.lst1[i].DelFlag == null || this.lst1[i].DelFlag == false) && t.Price > 0).length > 0)
            ComboProductAmount = this.lstCombo1.filter(t => t.rowid == this.lst1[i].rowid && this.lst1[i].DelFlag == null || this.lst1[i].DelFlag == false).map(t => t.Price * parseFloat(this.lst1[i].Quantity)).reduce((a, b) => a + b);
          else
            ComboProductAmount = 0;
          ModifirePrice = (ModifirePrice == undefined || ModifirePrice == null) ? 0 : ModifirePrice;
          this.lstName1 = 'lstBasket';
          this.CalculateDiscountAmount1(ProdcutPrice + 0 + ComboProductAmount);
          this.CallingCalculationMethod1(ProdcutPrice, 0, ComboProductAmount, BogoAmount, i, 0);

          /*this.CalculateDiscountAmount1(ProdcutPrice + ModifirePrice + ComboProductAmount);*/
          
          //if (this.ObjOutletInfo.IsTaxInclusive == true) {
          //  this.CalculateTaxInclusive1(ProdcutPrice, ModifirePrice, ComboProductAmount, BogoAmount, this.lst1[i].TaxRate, this.lst1[i].ExtraChargePercentage)
          //}
          //else
          //  this.CalculateTaxExclusive1(ProdcutPrice, ModifirePrice, ComboProductAmount, BogoAmount, this.lst1[i].TaxRate, this.lst1[i].ExtraChargePercentage)
        }
      }

      if (this.ObjOutletInfo.IsTaxInclusive == true) {
        this.FinalDiscountCalculationInclusive1(this.ObjOutletInfo.IsTaxOnDiscountAmount);
      }
      else {
        this.FinalDiscountCalculationExclusive1(this.ObjOutletInfo.IsTaxOnDiscountAmount);
      }
      //this.addVoucherPaymentDetail();
      this.TotalAmountforDiscount1 = 0;
      this.TotalVatAmount1 = 0;
      this.SubTotalAmount1 = 0;
    }

    this.PromoAmountforVisibility1 = this.getDeciamValue(this.PromoAmountforVisibility1);
    this.Total1 = this.getDeciamValue(this.Total1); 
    this.Subtoal1 = this.getDeciamValue(this.Subtoal1);
    this.VAT1 = this.getDeciamValue(this.VAT1);
    this.ExtraChargeAmount1 = this.getDeciamValue(this.ExtraChargeAmount1);
  }
  //CalculateTaxExclusive1(ProdcutPrice, ModifirePrice, ComboProductAmount, BogoAmount, ProductPercentage, ExtraChargePercentage) {
  //  debugger
  //  let taxpercent = this.ISCalculateTaxProductWise == false ? this.ObjOutletInfo.TaxRate : ProductPercentage;
  //  BogoAmount = BogoAmount == undefined ? 0 : BogoAmount;
  //  this.productmodifiresum1 = (ProdcutPrice + ModifirePrice + ComboProductAmount);
  //  let extrachrgepercentamount = 0;
  //  let ProductPriceAfterDiscount = 0;
  //  if (this.IsExtraCharge == true && ExtraChargePercentage > 0) {

  //    this.CalculateProductPriceAfterWithTotalDiscountAmount1(taxpercent);
  //    ProductPriceAfterDiscount = this.ItemWiseAmountAfterDisc1 > 0 ? this.ItemWiseAmountAfterDisc1 : this.productmodifiresum1;
  //    extrachrgepercentamount = (ProductPriceAfterDiscount * ExtraChargePercentage) / 100;
  //    this.ExtraChargeproductsubtotal1 = extrachrgepercentamount;
  //    this.productsubtotal1 = this.productmodifiresum1;

  //    this.ProductVat1 = ((this.productmodifiresum1 - this.ItemWiseDiscountAmount1 + this.ExtraChargeproductsubtotal1 + BogoAmount) * taxpercent) / 100;

  //  }
  //  else {
  //    this.CalculateProductPriceAfterWithTotalDiscountAmount1(taxpercent);
  //    this.productsubtotal1 = this.ItemWiseAmountAfterDisc1;
  //    this.ProductVat1 = ((this.ItemWiseAmountAfterDisc1 + BogoAmount) * taxpercent) / 100;
  //  }

  //  this.Subtoal1 += this.productsubtotal1;
  //  this.PromoAmountforVisibility1 = this.TotalDiscountAmount1;
  //  this.VAT1 += this.ProductVat1;
  //  this.ExtraChargeAmount1 += this.ExtraChargeproductsubtotal1;
  //}

  //CalculateTaxInclusive1(ProdcutPrice, ModifirePrice, ComboProductAmount, BogoAmount, ProductPercentage, ExtraChargePercentage) {

  //  this.productmodifiresum1 = (ProdcutPrice + ModifirePrice + ComboProductAmount);
  //  let taxpercent = this.ISCalculateTaxProductWise == false ? this.ObjOutletInfo.TaxRate : ProductPercentage;
  //  BogoAmount = BogoAmount == undefined ? 0 : BogoAmount;

  //  let extrachrgepercentamount = 0;
  //  let ProductPriceAfterDiscount = 0;
  //  if (this.IsExtraCharge == true && ExtraChargePercentage > 0) {
  //    this.CalculateProductPriceAfterWithTotalDiscountAmount1(taxpercent);
  //    ProductPriceAfterDiscount = this.ItemWiseAmountAfterDisc1 > 0 ? this.ItemWiseAmountAfterDisc1 : this.productmodifiresum1;
  //    extrachrgepercentamount = (ProductPriceAfterDiscount * ExtraChargePercentage) / 100;
  //    this.ExtraChargeproductsubtotal1 = ((extrachrgepercentamount) * 100) / (100 + taxpercent);

  //    this.productsubtotal1 = ((this.ItemWiseAmountAfterDisc1 - extrachrgepercentamount) * 100) / (100 + taxpercent);
  //    this.ProductVat1 = ((this.ItemWiseAmountAfterDisc1) * taxpercent) / (100 + taxpercent);
  //  }
  //  else {
  //    this.CalculateProductPriceAfterWithTotalDiscountAmount1(taxpercent);

  //    this.productsubtotal1 = ((this.ItemWiseAmountAfterDisc1) * 100) / (100 + taxpercent);
  //    this.ProductVat1 = ((this.ItemWiseAmountAfterDisc1) * taxpercent) / (100 + taxpercent);
  //  }
  //  this.Subtoal1 += this.productsubtotal1;
  //  this.PromoAmountforVisibility1 = this.TotalDiscountAmount1;
  //  this.VAT1 += this.ProductVat1;
  //  this.ExtraChargeAmount1 += this.ExtraChargeproductsubtotal1;

  //}
  CalculateDiscountAmount1(PriceWithOutVAT) {
    if (this.IsPercentPromoDiscount) {
      this.productDiscount1 += (parseFloat(PriceWithOutVAT) * this.PromoAmount1) / 100;
    }
    else {
      this.productDiscount1 = this.PromoAmount1;
    }
  }
  CalculateTotalDiscountAmount1() {

    let modifireamount = 0;
    let ComboProductAmount = 0
    debugger
    for (var i = 0; i < this.lst1.length; i++) {
      let Quant = this.lst1[i].Quantity;
      if (this.lstmodifir1.filter(t => t.rowid == this.lst1[i].rowid && (this.lst1[i].DelFlag == null || this.lst1[i].DelFlag == false) && t.Price > 0).length > 0)
        modifireamount += this.lstmodifir1.filter(t => t.rowid == this.lst1[i].rowid && this.lst1[i].DelFlag == null || this.lst1[i].DelFlag == false).map(t => t.Price * Quant).reduce((a, b) => a + b);
      else
        modifireamount += 0;


      if (this.lstCombo1.filter(t => t.rowid == this.lst1[i].rowid && (this.lst1[i].DelFlag == null || this.lst1[i].DelFlag == false) && t.Price > 0).length > 0)
        ComboProductAmount = this.lstCombo1.filter(t => t.rowid == this.lst1[i].rowid && this.lst1[i].DelFlag == null || this.lst1[i].DelFlag == false).map(t => t.Price * parseFloat(this.lst1[i].Quantity)).reduce((a, b) => a + b);
      else
        ComboProductAmount = 0;
    }

    let sum = 0;
    if (this.lst1.length > 0) {
      if (this.lst1.filter(x => x.DelFlag == null || x.DelFlag == false).length > 0) {
        sum = this.lst1.filter(x => x.DelFlag == null || x.DelFlag == false).map(x => x.Price * x.Quantity).reduce((a, b) => a + b);
      }
    }
    else {
      sum = 0;
    }
    this.ProdcutTotalAmount1 = (sum + modifireamount + ComboProductAmount);
    if (this.IsPercentPromoDiscount) {
      this.TotalDiscountAmount1 = ((sum + modifireamount + ComboProductAmount) * this.PromoAmount1) / 100;
    }
    else {
      this.TotalDiscountAmount1 = +this.PromoAmount1;
    }

  }

  CalculateVATOnDiscountAmountExclusive1(taxpercent) {
    if (this.ObjOutletInfo.IsTaxOnDiscountAmount == true && this.productDiscount1 > 0) {
      this.VATOnDiscountAmount1 = (this.productDiscount1 * taxpercent) / 100;
      this.DiscountAmountWithOutVAT1 = this.productDiscount1 - this.VATOnDiscountAmount1;
    }
    else {
      this.VATOnDiscountAmount1 = 0;
      this.DiscountAmountWithOutVAT1 = 0;
    }
  }
  FinalDiscountCalculationInclusive1(discountwitout) {
    debugger;
    if (this.TotalDiscountAmount1 == this.ProdcutTotalAmount1) {
      this.Subtoal1 = 0;
      this.Total1 = 0;
      this.VAT1 = 0;
    }
    else {
      if (discountwitout == false)
        this.Subtoal1 = this.Subtoal1 - this.PromoAmountforVisibility1;
      this.Total1 = this.Subtoal1 + this.VAT1 + this.ExtraChargeAmount1;

    }
  }
  FinalDiscountCalculationExclusive1(discountwitout) {
    debugger;
    if (this.TotalDiscountAmount1 == this.ProdcutTotalAmount1) {
      this.VAT1 = 0;
      this.ExtraChargeAmount1 = 0;
      this.Subtoal1 = 0;
      this.Total1 = this.Subtoal1 + this.VAT1 + this.ExtraChargeAmount1;
    }
    else {
      if (discountwitout == false)
        this.Subtoal1 = this.Subtoal1 - this.PromoAmountforVisibility1;
      this.Total1 = this.Subtoal1 + this.VAT1 + this.ExtraChargeAmount1;
    }
  }
  ItemWiseAmountAfterDisc1: number = 0;
  ItemWiseVat1: number = 0;
  ItemWiseDiscountAmount1: number = 0;
  CalculateProductPriceAfterDiscount1(taxpercent) {
    debugger
    this.ItemWiseAmountAfterDisc1 = 0;
    this.ItemWiseVat1 = 0;
    this.ItemWiseDiscountAmount1 = 0;
    this.ItemWiseDiscountAmount1 = this.productDiscount1 / this.TotalAmountforDiscount1 * this.productmodifiresum1;
    this.ItemWiseAmountAfterDisc1 = this.productmodifiresum1 - this.ItemWiseDiscountAmount1;
    this.ItemWiseVat1 = this.ItemWiseAmountAfterDisc1 * taxpercent / (100 + taxpercent);
    return this.ItemWiseAmountAfterDisc1;
  }
  CalculateProductPriceAfterWithTotalDiscountAmount1(taxpercent) {
    debugger
    this.ItemWiseAmountAfterDisc1 = 0;
    this.ItemWiseVat1 = 0;
    this.ItemWiseDiscountAmount1 = 0;
    if (this.TotalDiscountAmount1 > 0 && this.ObjOutletInfo.IsTaxOnDiscountAmount == true) {
      this.ItemWiseDiscountAmount1 = this.TotalDiscountAmount1 / parseFloat(this.ProdcutTotalAmount1.toFixed(5)) * this.productmodifiresum1;
      this.ItemWiseAmountAfterDisc1 = this.productmodifiresum1 - this.ItemWiseDiscountAmount1;
      this.ItemWiseVat1 = this.ItemWiseAmountAfterDisc1 * taxpercent / (100 + taxpercent);
    } else {
      this.ItemWiseAmountAfterDisc1 = this.productmodifiresum1;
      this.ItemWiseVat1 = 0;
    }

  }
  CallingCalculationMethod1(ProdcutPrice, ModifirePrice, ComboProductAmount, BogoAmount, i, modifierindex) {
    if (this.ObjOutletInfo.IsTaxInclusive == true) {
      this.CalculateTaxInclusive1(ProdcutPrice, ModifirePrice, ComboProductAmount, BogoAmount
        , this.lst1[i].TaxRate, this.lst1[i].ExtraChargePercentage, i, modifierindex)
    }
    else
      this.CalculateTaxExclusive1(ProdcutPrice, ModifirePrice, ComboProductAmount, BogoAmount
        , this.lst1[i].TaxRate, this.lst1[i].ExtraChargePercentage, i, modifierindex)
  }
  CalculateTaxExclusive1(ProdcutPrice, ModifirePrice, ComboProductAmount, BogoAmount
    , ProductPercentage, ExtraChargePercentage, Basketindex, Modifireindex) {
    let taxpercent = this.ISCalculateTaxProductWise == false ? this.ObjOutletInfo.TaxRate : ProductPercentage;
    BogoAmount = BogoAmount == undefined ? 0 : BogoAmount;
    this.productmodifiresum1 = (ProdcutPrice + ModifirePrice + ComboProductAmount);
    let extrachrgepercentamount = 0;
    let ProductPriceAfterDiscount = 0;
    if (this.IsExtraCharge == true && ExtraChargePercentage > 0) {
      this.CalculateProductPriceAfterWithTotalDiscountAmount1(taxpercent);
      ProductPriceAfterDiscount = this.ItemWiseAmountAfterDisc1 > 0 ? this.ItemWiseAmountAfterDisc1 : this.productmodifiresum1;
      extrachrgepercentamount = (ProductPriceAfterDiscount * ExtraChargePercentage) / 100;
      this.ExtraChargeproductsubtotal1 = extrachrgepercentamount;
      this.productsubtotal1 = this.productmodifiresum1;
      this.ProductVat1 = ((this.productmodifiresum1 - this.ItemWiseDiscountAmount1 + this.ExtraChargeproductsubtotal1 + BogoAmount) * taxpercent) / 100;
    }
    else {
      this.CalculateProductPriceAfterWithTotalDiscountAmount1(taxpercent);
      this.productsubtotal1 = this.ItemWiseAmountAfterDisc1;
      this.ProductVat1 = ((this.ItemWiseAmountAfterDisc1 + BogoAmount) * taxpercent) / 100;
    }
    if (this.lstName1 == 'lstBasket') {
      this.lst1[Basketindex].PriceWithOutVAT = this.productsubtotal1;
      this.lst1[Basketindex].TaxAmount = this.ProductVat1;
      this.lst1[Basketindex].ProductDiscount = this.ItemWiseDiscountAmount1;
    }
    if (this.lstName1 == 'lstModifier') {
      this.lstmodifir1[Modifireindex].PriceWithOutVAT = this.productsubtotal1;
      this.lstmodifir1[Modifireindex].TaxAmount = this.ProductVat1;
      this.lstmodifir1[Modifireindex].ProductDiscount = this.ItemWiseDiscountAmount1;
    }
    this.Subtoal1 += this.productsubtotal1;
    this.PromoAmountforVisibility1 = this.TotalDiscountAmount1;
    this.VAT1 += this.ProductVat1;
    this.ExtraChargeAmount1 += this.ExtraChargeproductsubtotal1;
  }
  CalculateTaxInclusive1(ProdcutPrice, ModifirePrice, ComboProductAmount, BogoAmount
    , ProductPercentage, ExtraChargePercentage, Basketindex, Modifireindex) {
    this.productmodifiresum1 = (ProdcutPrice + ModifirePrice + ComboProductAmount);
    let taxpercent = this.ISCalculateTaxProductWise == false ? this.ObjOutletInfo.TaxRate : ProductPercentage;
    BogoAmount = BogoAmount == undefined ? 0 : BogoAmount;
    let extrachrgepercentamount = 0;
    let ProductPriceAfterDiscount = 0;
    if (this.IsExtraCharge == true && ExtraChargePercentage > 0) {
      this.CalculateProductPriceAfterWithTotalDiscountAmount1(taxpercent);
      ProductPriceAfterDiscount = this.ItemWiseAmountAfterDisc1 > 0 ? this.ItemWiseAmountAfterDisc1 : this.productmodifiresum1;
      extrachrgepercentamount = (ProductPriceAfterDiscount * ExtraChargePercentage) / 100;
      this.ExtraChargeproductsubtotal1 = ((extrachrgepercentamount) * 100) / (100 + taxpercent);
      this.productsubtotal1 = ((this.ItemWiseAmountAfterDisc1 - extrachrgepercentamount) * 100) / (100 + taxpercent);
      this.ProductVat1 = ((this.ItemWiseAmountAfterDisc1) * taxpercent) / (100 + taxpercent);
    }
    else {
      this.CalculateProductPriceAfterWithTotalDiscountAmount1(taxpercent);
      this.productsubtotal1 = ((this.ItemWiseAmountAfterDisc1) * 100) / (100 + taxpercent);
      this.ProductVat1 = ((this.ItemWiseAmountAfterDisc1) * taxpercent) / (100 + taxpercent);
    }
    if (this.lstName1 == 'lstBasket') {
      this.lst1[Basketindex].PriceWithOutVAT = this.productsubtotal1;
      this.lst1[Basketindex].TaxAmount = this.ProductVat1;
      this.lst1[Basketindex].ProductDiscount = this.ItemWiseDiscountAmount1;
    }
    if (this.lstName1 == 'lstModifier') {

      this.lstmodifir1[Modifireindex].PriceWithOutVAT = this.productsubtotal1;
      this.lstmodifir1[Modifireindex].TaxAmount = this.ProductVat1;
      this.lstmodifir1[Modifireindex].ProductDiscount = this.ItemWiseDiscountAmount1;
    }

    this.Subtoal1 += this.productsubtotal1;
    this.PromoAmountforVisibility1 = this.TotalDiscountAmount1;
    this.VAT1 += this.ProductVat1;
    this.ExtraChargeAmount1 += this.ExtraChargeproductsubtotal1;
  }
  ///////////////////////////////////////////////////////////////////////////////////////////////////////

  TotalAmountforDiscount2: number = 0;
  TotalVatAmount2: number = 0;
  SubTotalAmount2: number = 0;
  PromoAmount2: any=0;

  /*Product Wies Variables*/
  ProductVat2: number = 0;
  productDiscount2: number = 0;
  TotalDiscountAmount2: number = 0;
  ProdcutTotalAmount2: number = 0;
  productmodifiresum2: number = 0;
  productsubtotal2: number = 0;
  productTax2: number;

  /*Product Wies Variables*/
  ExtraChargeProductVat2: number = 0;
  ExtraChargeproductDiscount2: number = 0;
  ExtraChargeproductmodifiresum2: number = 0;
  ExtraChargeproductsubtotal2: number = 0;
  ExtraChargeproductTax2: number;
  /*Discount Variables */
  VATOnDiscountAmount2: number = 0;
  DiscountAmountWithOutVAT2: number = 0;
  PromoAmountforVisibility2: any;

  lstName2: any = '';
  StartCalculation2() {
    debugger

    this.TotalAmountforDiscount2 = this.Total2;
    this.TotalVatAmount2 = this.VAT2;
    this.SubTotalAmount2 = this.Subtoal2;


    this.Subtoal2 = 0;
    this.PromoAmountforVisibility2 = 0;
    this.VAT2 = 0;
    this.TotalDiscountAmount2 = 0;
    this.ProdcutTotalAmount2 = 0;
    this.Total2 = 0;
    this.DiscountAmountWithOutVAT2 = 0;
    this.VATOnDiscountAmount2 = 0;
    this.productDiscount2 = 0;
    this.ExtraChargeAmount2 = 0;
    this.ExtraChargeProductVat2 = 0;
    this.ExtraChargeproductDiscount2 = 0;
    this.ExtraChargeproductmodifiresum2 = 0;

    this.ExtraChargeproductTax2 = 0;
    if (this.lst2.length > 0) {
      this.CalculateTotalDiscountAmount2();
      for (var i = 0; i < this.lst2.length; i++) {
        let ProdcutPrice: any;
        let ModifirePrice: any;
        let BogoAmount: any = 0;
        let ComboProductAmount: any;

        this.ExtraChargeproductsubtotal2 = 0;
        this.lstName2 = '';

        if (this.lst2[i].DelFlag == null || this.lst2[i].DelFlag == false) {
          ProdcutPrice = this.lst2[i].Price * parseFloat(this.lst2[i].Quantity);
          for (var j = 0; j < this.lstmodifir2.length; j++) {
           
         
            if (this.lstmodifir2[j] != undefined && this.lstmodifir2[j].ProductID == this.lst2[i].ProductID) {
              if (this.lst2[i].rowid == this.lstmodifir2[j].rowid && this.lstmodifir2[j].Price > 0) {
                ModifirePrice = (this.lstmodifir2[j].Price * parseFloat(this.lst2[i].Quantity));
                  this.lstName2 = 'lstModifier';
                  this.CalculateDiscountAmount2(0 + ModifirePrice + 0);
                  this.CallingCalculationMethod2(0, ModifirePrice, 0, 0, i, j);
                }
              if (this.lstmodifir2[j].IsBogo == true) {
                  BogoAmount += this.lst2[i].Price * parseFloat(this.lst2[i].Quantity);
                }
              }
            
          }

          if (this.lstCombo2.filter(t => t.rowid == this.lst2[i].rowid && (this.lst2[i].DelFlag == null || this.lst2[i].DelFlag == false) && t.Price > 0).length > 0)
            ComboProductAmount = this.lstCombo2.filter(t => t.rowid == this.lst2[i].rowid && this.lst2[i].DelFlag == null || this.lst2[i].DelFlag == false).map(t => t.Price * parseFloat(this.lst2[i].Quantity)).reduce((a, b) => a + b);
          else
            ComboProductAmount = 0;

          ModifirePrice = (ModifirePrice == undefined || ModifirePrice == null) ? 0 : ModifirePrice;
          this.lstName2 = 'lstBasket';
          this.CalculateDiscountAmount2(ProdcutPrice + 0 + ComboProductAmount);
          this.CallingCalculationMethod2(ProdcutPrice, 0, ComboProductAmount, BogoAmount, i, 0);

          //this.CalculateDiscountAmount2(ProdcutPrice + ModifirePrice + ComboProductAmount);
     
          //if (this.ObjOutletInfo.IsTaxInclusive == true) {
          //  this.CalculateTaxInclusive2(ProdcutPrice, ModifirePrice, ComboProductAmount, BogoAmount, this.lst2[i].TaxRate, this.lst2[i].ExtraChargePercentage)
          //}
          //else
          //  this.CalculateTaxExclusive2(ProdcutPrice, ModifirePrice, ComboProductAmount, BogoAmount, this.lst2[i].TaxRate, this.lst2[i].ExtraChargePercentage)
        }
      }

      if (this.ObjOutletInfo.IsTaxInclusive == true) {
        this.FinalDiscountCalculationInclusive2(this.ObjOutletInfo.IsTaxOnDiscountAmount);
      }
      else {
        this.FinalDiscountCalculationExclusive2(this.ObjOutletInfo.IsTaxOnDiscountAmount);
      }

      //if (this.TotalDiscountAmount2 > 0)
      //  this.addVoucherPaymentDetail();
      
      this.TotalAmountforDiscount2 = 0;
      this.TotalVatAmount2 = 0;
      this.SubTotalAmount2 = 0;
    }

    this.PromoAmountforVisibility2 = this.getDeciamValue(this.PromoAmountforVisibility2);
    this.Total2 = this.getDeciamValue(this.Total2);
    this.Subtoal2 = this.getDeciamValue(this.Subtoal2);
    this.VAT2 = this.getDeciamValue(this.VAT2);
    this.ExtraChargeAmount2 = this.getDeciamValue(this.ExtraChargeAmount2);
  }
  CalculateTaxExclusive2(ProdcutPrice, ModifirePrice, ComboProductAmount, BogoAmount
    , ProductPercentage, ExtraChargePercentage, Basketindex, Modifireindex) {
    let taxpercent = this.ISCalculateTaxProductWise == false ? this.ObjOutletInfo.TaxRate : ProductPercentage;
    BogoAmount = BogoAmount == undefined ? 0 : BogoAmount;
    this.productmodifiresum2 = (ProdcutPrice + ModifirePrice + ComboProductAmount);
    let extrachrgepercentamount = 0;
    let ProductPriceAfterDiscount = 0;
    if (this.IsExtraCharge == true && ExtraChargePercentage > 0) {
      this.CalculateProductPriceAfterWithTotalDiscountAmount2(taxpercent);
      ProductPriceAfterDiscount = this.ItemWiseAmountAfterDisc2 > 0 ? this.ItemWiseAmountAfterDisc2 : this.productmodifiresum2;
      extrachrgepercentamount = (ProductPriceAfterDiscount * ExtraChargePercentage) / 100;
      this.ExtraChargeproductsubtotal2 = extrachrgepercentamount;
      this.productsubtotal2 = this.productmodifiresum2;
      this.ProductVat2 = ((this.productmodifiresum2 - this.ItemWiseDiscountAmount2 + this.ExtraChargeproductsubtotal2 + BogoAmount) * taxpercent) / 100;
    }
    else {
      this.CalculateProductPriceAfterWithTotalDiscountAmount2(taxpercent);
      this.productsubtotal2 = this.ItemWiseAmountAfterDisc2;
      this.ProductVat2 = ((this.ItemWiseAmountAfterDisc2 + BogoAmount) * taxpercent) / 100;
    }
    if (this.lstName2 == 'lstBasket') {
      this.lst2[Basketindex].PriceWithOutVAT = this.productsubtotal2;
      this.lst2[Basketindex].TaxAmount = this.ProductVat2;
      this.lst2[Basketindex].ProductDiscount = this.ItemWiseDiscountAmount2;
    }
    if (this.lstName2 == 'lstModifier') {
      this.lstmodifir2[Modifireindex].PriceWithOutVAT = this.productsubtotal2;
      this.lstmodifir2[Modifireindex].TaxAmount = this.ProductVat2;
      this.lstmodifir2[Modifireindex].ProductDiscount = this.ItemWiseDiscountAmount2;
    }
    this.Subtoal2 += this.productsubtotal2;
    this.PromoAmountforVisibility2 = this.TotalDiscountAmount2;
    this.VAT2 += this.ProductVat2;
    this.ExtraChargeAmount2 += this.ExtraChargeproductsubtotal2;
  }
  CalculateTaxInclusive2(ProdcutPrice, ModifirePrice, ComboProductAmount, BogoAmount
    , ProductPercentage, ExtraChargePercentage, Basketindex, Modifireindex) {
    this.productmodifiresum2 = (ProdcutPrice + ModifirePrice + ComboProductAmount);
    let taxpercent = this.ISCalculateTaxProductWise == false ? this.ObjOutletInfo.TaxRate : ProductPercentage;
    BogoAmount = BogoAmount == undefined ? 0 : BogoAmount;
    let extrachrgepercentamount = 0;
    let ProductPriceAfterDiscount = 0;
    if (this.IsExtraCharge == true && ExtraChargePercentage > 0) {
      this.CalculateProductPriceAfterWithTotalDiscountAmount2(taxpercent);
      ProductPriceAfterDiscount = this.ItemWiseAmountAfterDisc2 > 0 ? this.ItemWiseAmountAfterDisc2 : this.productmodifiresum2;
      extrachrgepercentamount = (ProductPriceAfterDiscount * ExtraChargePercentage) / 100;
      this.ExtraChargeproductsubtotal2 = ((extrachrgepercentamount) * 100) / (100 + taxpercent);
      this.productsubtotal2 = ((this.ItemWiseAmountAfterDisc2 - extrachrgepercentamount) * 100) / (100 + taxpercent);
      this.ProductVat2 = ((this.ItemWiseAmountAfterDisc2) * taxpercent) / (100 + taxpercent);
    }
    else {
      this.CalculateProductPriceAfterWithTotalDiscountAmount2(taxpercent);
      this.productsubtotal2 = ((this.ItemWiseAmountAfterDisc2) * 100) / (100 + taxpercent);
      this.ProductVat2 = ((this.ItemWiseAmountAfterDisc2) * taxpercent) / (100 + taxpercent);
    }
    if (this.lstName2 == 'lstBasket') {
      this.lst2[Basketindex].PriceWithOutVAT = this.productsubtotal2;
      this.lst2[Basketindex].TaxAmount = this.ProductVat2;
      this.lst2[Basketindex].ProductDiscount = this.ItemWiseDiscountAmount2;
    }
    if (this.lstName2 == 'lstModifier') {

      this.lstmodifir2[Modifireindex].PriceWithOutVAT = this.productsubtotal2;
      this.lstmodifir2[Modifireindex].TaxAmount = this.ProductVat2;
      this.lstmodifir2[Modifireindex].ProductDiscount = this.ItemWiseDiscountAmount2;
    }

    this.Subtoal2 += this.productsubtotal2;
    this.PromoAmountforVisibility2 = this.TotalDiscountAmount2;
    this.VAT2 += this.ProductVat2;
    this.ExtraChargeAmount2 += this.ExtraChargeproductsubtotal2;
  }
  

  //CalculateTaxExclusive2(ProdcutPrice, ModifirePrice, ComboProductAmount, BogoAmount, ProductPercentage, ExtraChargePercentage) {
  //  debugger
  //  let taxpercent = this.ISCalculateTaxProductWise == false ? this.ObjOutletInfo.TaxRate : ProductPercentage;
  //  BogoAmount = BogoAmount == undefined ? 0 : BogoAmount;
  //  this.productmodifiresum2 = (ProdcutPrice + ModifirePrice + ComboProductAmount);
  //  let extrachrgepercentamount = 0;
  //  let ProductPriceAfterDiscount = 0;
  //  if (this.IsExtraCharge == true && ExtraChargePercentage > 0) {

  //    this.CalculateProductPriceAfterWithTotalDiscountAmount2(taxpercent);
  //    ProductPriceAfterDiscount = this.ItemWiseAmountAfterDisc2 > 0 ? this.ItemWiseAmountAfterDisc2 : this.productmodifiresum2;
  //    extrachrgepercentamount = (ProductPriceAfterDiscount * ExtraChargePercentage) / 100;
  //    this.ExtraChargeproductsubtotal2 = extrachrgepercentamount;
  //    this.productsubtotal2 = this.productmodifiresum2;

  //    this.ProductVat2 = ((this.productmodifiresum2 - this.ItemWiseDiscountAmount2 + this.ExtraChargeproductsubtotal2 + BogoAmount) * taxpercent) / 100;

  //  }
  //  else {
  //    this.CalculateProductPriceAfterWithTotalDiscountAmount2(taxpercent);
  //    this.productsubtotal2 = this.ItemWiseAmountAfterDisc2;
  //    this.ProductVat2 = ((this.ItemWiseAmountAfterDisc2+ BogoAmount) * taxpercent) / 100;
  //  }

  //  this.Subtoal2 += this.productsubtotal2;
  //  this.PromoAmountforVisibility2 = this.TotalDiscountAmount2;
  //  this.VAT2 += this.ProductVat2;
  //  this.ExtraChargeAmount2 += this.ExtraChargeproductsubtotal2;
  //}

  //CalculateTaxInclusive2(ProdcutPrice, ModifirePrice, ComboProductAmount, BogoAmount, ProductPercentage, ExtraChargePercentage) {

  //  this.productmodifiresum2 = (ProdcutPrice + ModifirePrice + ComboProductAmount);
  //  let taxpercent = this.ISCalculateTaxProductWise == false ? this.ObjOutletInfo.TaxRate : ProductPercentage;
  //  BogoAmount = BogoAmount == undefined ? 0 : BogoAmount;

  //  let extrachrgepercentamount = 0;
  //  let ProductPriceAfterDiscount = 0;
  //  if (this.IsExtraCharge == true && ExtraChargePercentage > 0) {
  //    this.CalculateProductPriceAfterWithTotalDiscountAmount2(taxpercent);
  //    ProductPriceAfterDiscount = this.ItemWiseAmountAfterDisc2 > 0 ? this.ItemWiseAmountAfterDisc2 : this.productmodifiresum2;
  //    extrachrgepercentamount = (ProductPriceAfterDiscount * ExtraChargePercentage) / 100;
  //    this.ExtraChargeproductsubtotal2 = ((extrachrgepercentamount) * 100) / (100 + taxpercent);

  //    this.productsubtotal2 = ((this.ItemWiseAmountAfterDisc2 - extrachrgepercentamount) * 100) / (100 + taxpercent);
  //    this.ProductVat2 = ((this.ItemWiseAmountAfterDisc2) * taxpercent) / (100 + taxpercent);
  //  }
  //  else {
  //    this.CalculateProductPriceAfterWithTotalDiscountAmount2(taxpercent);

  //    this.productsubtotal2 = ((this.ItemWiseAmountAfterDisc2) * 100) / (100 + taxpercent);
  //    this.ProductVat2 = ((this.ItemWiseAmountAfterDisc2) * taxpercent) / (100 + taxpercent);
  //  }
  //  this.Subtoal2 += this.productsubtotal2;
  //  this.PromoAmountforVisibility2 = this.TotalDiscountAmount2;
  //  this.VAT2 += this.ProductVat2;
  //  this.ExtraChargeAmount2 += this.ExtraChargeproductsubtotal2;

  //}
  CalculateDiscountAmount2(PriceWithOutVAT) {
    if (this.IsPercentPromoDiscount) {
      this.productDiscount2 += (parseFloat(PriceWithOutVAT) * this.PromoAmount2) / 100;
    }
    else {
      this.productDiscount2 = this.PromoAmount2;
    }
  }
  CalculateTotalDiscountAmount2() {
 
    let modifireamount = 0;
    let ComboProductAmount = 0;
    debugger
    for (var i = 0; i < this.lst2.length; i++) {
      let Quant = this.lst2[i].Quantity;
      if (this.lstmodifir2.filter(t => t.rowid == this.lst2[i].rowid && (this.lst2[i].DelFlag == null || this.lst2[i].DelFlag == false) && t.Price > 0).length > 0)
        modifireamount += this.lstmodifir2.filter(t => t.rowid == this.lst2[i].rowid && this.lst2[i].DelFlag == null || this.lst2[i].DelFlag == false).map(t => t.Price * Quant).reduce((a, b) => a + b);
      else
        modifireamount += 0;

      if (this.lstCombo2.filter(t => t.rowid == this.lst2[i].rowid && (this.lst2[i].DelFlag == null || this.lst2[i].DelFlag == false) && t.Price > 0).length > 0)
        ComboProductAmount = this.lstCombo2.filter(t => t.rowid == this.lst2[i].rowid && this.lst2[i].DelFlag == null || this.lst2[i].DelFlag == false).map(t => t.Price * parseFloat(this.lst2[i].Quantity)).reduce((a, b) => a + b);
      else
        ComboProductAmount = 0;
    }

    let sum = 0;
    if (this.lst2.length > 0) {
      if (this.lst2.filter(x => x.DelFlag == null || x.DelFlag == false).length > 0) {
        sum = this.lst2.filter(x => x.DelFlag == null || x.DelFlag == false).map(x => x.Price * x.Quantity).reduce((a, b) => a + b);
      }
    }
    else {
      sum = 0;
    }
    this.ProdcutTotalAmount2 = (sum + modifireamount + ComboProductAmount);
    if (this.IsPercentPromoDiscount) {
      this.TotalDiscountAmount2 = ((sum + modifireamount + ComboProductAmount) * this.PromoAmount2) / 100;
    }
    else {
      this.TotalDiscountAmount2 = +this.PromoAmount2;
    }

  }

  CalculateVATOnDiscountAmountExclusive2(taxpercent) {
    if (this.ObjOutletInfo.IsTaxOnDiscountAmount == true && this.productDiscount2 > 0) {
      this.VATOnDiscountAmount2 = (this.productDiscount2 * taxpercent) / 100;
      this.DiscountAmountWithOutVAT2 = this.productDiscount2 - this.VATOnDiscountAmount2;
    }
    else {
      this.VATOnDiscountAmount2 = 0;
      this.DiscountAmountWithOutVAT2 = 0;
    }
  }
  FinalDiscountCalculationInclusive2(discountwitout) {
    debugger;
    if (this.TotalDiscountAmount2 == this.ProdcutTotalAmount2) {
      this.Subtoal2 = 0;
      this.Total2 = 0;
      this.VAT2 = 0;
    }
    else {
      if (discountwitout == false)
        this.Subtoal2 = this.Subtoal2 - this.PromoAmountforVisibility2;
      this.Total2 = this.Subtoal2 + this.VAT2 + this.ExtraChargeAmount2;

    }
  }
  FinalDiscountCalculationExclusive2(discountwitout) {
    debugger;
    if (this.TotalDiscountAmount2 == this.ProdcutTotalAmount2) {
      this.VAT2 = 0;
      this.ExtraChargeAmount2 = 0;
      this.Subtoal2 = 0;
      this.Total2 = this.Subtoal2 + this.VAT2 + this.ExtraChargeAmount2;
    }
    else {
      if (discountwitout == false)
        this.Subtoal2 = this.Subtoal2 - this.PromoAmountforVisibility2;
      this.Total2 = this.Subtoal2 + this.VAT2 + this.ExtraChargeAmount2;
    }
  }
  ItemWiseAmountAfterDisc2: number = 0;
  ItemWiseVat2: number = 0;
  ItemWiseDiscountAmount2: number = 0;
  CalculateProductPriceAfterDiscount2(taxpercent) {
    debugger
    this.ItemWiseAmountAfterDisc2 = 0;
    this.ItemWiseVat2 = 0;
    this.ItemWiseDiscountAmount2 = 0;
    this.ItemWiseDiscountAmount2 = this.productDiscount2 / this.TotalAmountforDiscount2 * this.productmodifiresum2;
    this.ItemWiseAmountAfterDisc2 = this.productmodifiresum2 - this.ItemWiseDiscountAmount2;
    this.ItemWiseVat2 = this.ItemWiseAmountAfterDisc2 * taxpercent / (100 + taxpercent);
    return this.ItemWiseAmountAfterDisc2;
  }
  CalculateProductPriceAfterWithTotalDiscountAmount2(taxpercent) {
    debugger
    this.ItemWiseAmountAfterDisc2 = 0;
    this.ItemWiseVat2 = 0;
    this.ItemWiseDiscountAmount2 = 0;
    if (this.TotalDiscountAmount2 > 0 && this.ObjOutletInfo.IsTaxOnDiscountAmount == true) {
      this.ItemWiseDiscountAmount2 = this.TotalDiscountAmount2 / parseFloat(this.ProdcutTotalAmount2.toFixed(5)) * this.productmodifiresum2;
      this.ItemWiseAmountAfterDisc2 = this.productmodifiresum2 - this.ItemWiseDiscountAmount2;
      this.ItemWiseVat2 = this.ItemWiseAmountAfterDisc2 * taxpercent / (100 + taxpercent);
    } else {
      this.ItemWiseAmountAfterDisc2 = this.productmodifiresum2;
      this.ItemWiseVat2 = 0;
    }

  }
  CallingCalculationMethod2(ProdcutPrice, ModifirePrice, ComboProductAmount, BogoAmount, i, modifierindex) {
    if (this.ObjOutletInfo.IsTaxInclusive == true) {
      this.CalculateTaxInclusive2(ProdcutPrice, ModifirePrice, ComboProductAmount, BogoAmount
        , this.lst2[i].TaxRate, this.lst2[i].ExtraChargePercentage, i, modifierindex)
    }
    else
      this.CalculateTaxExclusive2(ProdcutPrice, ModifirePrice, ComboProductAmount, BogoAmount
        , this.lst2[i].TaxRate, this.lst2[i].ExtraChargePercentage, i, modifierindex)
  }
//  CalculateTaxPlusEvent1(row) {
//    debugger
//    var priceTotal = this.lst1.reduce(function(res,item) { return res + (item.Price * item.Quantity)}, 0);
//    priceTotal = priceTotal + this.ModifiresAmount1;

//    if (this.ObjOutletInfo.IsTaxInclusive == false) {
//      this.Subtoal1 = priceTotal;

//      if (this.IsExtraCharge == true) {
//        this.ExtraChargeAmount1 = (this.Subtoal1) * this.ExtraChargePercentage / 100;
//      }

//      this.SubtoalForVAT1 = parseFloat(row.DisAmount && this.ObjOutletInfo.IsTaxOnDiscountAmount == true ? (row.DisAmount > 0 ? row.DisAmount + this.ModifiresAmount1 : row.Price + this.ModifiresAmount1) : priceTotal);
//      this.VAT1 = ((this.SubtoalForVAT1 + this.ExtraChargeAmount1) * this.ObjOutletInfo.TaxRate) / 100;
//      if (row.DisAmount)
//        this.DiscountAmont1 += (row.Price - row.DisAmount);
//      this.Total1 = (this.Subtoal1 + this.VAT1 + this.ExtraChargeAmount1 - this.ObjMasterData1.TotalDiscount);

     
//    }
//    else {
//      let A = 0;
//      let price = row.DiscountPrice > 0 ? row.DiscountPrice + this.ModifiresAmount1 : row.Price + this.ModifiresAmount1;

//      if (this.ObjOutletInfo.IsTaxOnDiscountAmount == false) {
//        this.Subtoal1 = ((priceTotal) * 100) / (100 + this.ObjOutletInfo.TaxRate);
//        this.VAT1 = priceTotal - this.Subtoal1;
//      }
//      if (this.ObjOutletInfo.IsTaxOnDiscountAmount == true) {
//        A = ((priceTotal) * 100) / (100 + this.ObjOutletInfo.TaxRate);
//        this.VAT1 = (priceTotal) - A;
//      }
//      if (row.DiscountPrice)
//        this.DiscountAmont1 += ((row.Price * row.Quantity) - row.DiscountPrice);
//      if (row.DiscountPrice && this.ObjOutletInfo.IsTaxOnDiscountAmount == true)
//        this.Subtoal1 += A + ((row.Price * row.Quantity) - row.DiscountPrice);
//      else
//        this.Subtoal1 = A;
//      this.Total1 = (parseFloat(this.Subtoal1) + parseFloat(this.VAT1));
//      // this.Total2 = (this.Subtoal2 + this.VAT2 - this.DiscountAmont2);

//      if (this.IsExtraCharge == true) {
//        this.Subtoal1 = (this.Total1 - this.VAT1) / ((100 + this.ExtraChargePercentage) / 100)

//        this.ExtraChargeAmount1 = (this.Subtoal1 * this.ExtraChargePercentage / 100)
//        this.Subtoal1 = +this._decimalPipe.transform(this.Subtoal1, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal);
//        this.ExtraChargeAmount1 = +this._decimalPipe.transform(this.ExtraChargeAmount1, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal);
//      }

//    }
//    this.Total1 = +this._decimalPipe.transform(this.Total1, '1.' + this.NoOfDecimal + '-' + this.NoOfDecimal);
//    this.Subtoal1 = +this._decimalPipe.transform(this.Subtoal1, '1.' + this.NoOfDecimal + '-' + this.NoOfDecimal);
//    this.VAT1 = +this._decimalPipe.transform(this.VAT1, '1.' + this.NoOfDecimal + '-' + this.NoOfDecimal);
//    this.ModifiresAmount1 = 0;
//  }
//  CalculateTaxMinusEvent1(row, index) {
//debugger;
//var pricetotal = this.lst1.reduce(function(res,item) { return res + (item.Price * item.Quantity)}, 0);
//pricetotal = pricetotal+this.ModifiresAmount1;

//    if (this.ObjOutletInfo.IsTaxInclusive == false) {
//      // this.Subtoal1 = this.ObjMasterData1.TotalAmount - row.Price - this.ModifiresAmount1;
//      this.Subtoal1 = pricetotal;
//      if (this.IsExtraCharge == true) {
//        this.ExtraChargeAmount1 = (this.Subtoal1) * this.ExtraChargePercentage / 100;
//      }

//      if (row.DisAmount && this.ObjOutletInfo.IsTaxOnDiscountAmount == true) {
//        this.SubtoalForVAT1 = this.SubtoalForVAT1 - row.DisAmount - this.ModifiresAmount1;
//      } else {
//        this.SubtoalForVAT1 = pricetotal - this.SubtoalForVAT1 - this.ModifiresAmount1;
//      }
//      this.VAT1 = ((this.Subtoal1 + this.ExtraChargeAmount1) * this.ObjOutletInfo.TaxRate) / 100;
//      if (row.DisAmount)
//        this.DiscountAmont1 = this.DiscountAmont1 - (row.Price - row.DisAmount);
//      this.Total1 = (this.Subtoal1 + this.VAT1 + this.ExtraChargeAmount1 - this.ObjMasterData1.TotalDiscount);


      
//    }
//    else {
//      let A = 0;
//      let price = row.DisAmount > 0 ? row.DisAmount + this.ModifiresAmount1 : row.Price + this.ModifiresAmount1;
      
//      if (this.ObjOutletInfo.IsTaxOnDiscountAmount == false) 
//      {
//        this.Subtoal1 = (pricetotal * 100) / (100 + this.ObjOutletInfo.TaxRate);
//        this.VAT1 = this.VAT1 - (pricetotal - this.Subtoal1);
//      }
      
//      if (this.ObjOutletInfo.IsTaxOnDiscountAmount == true) 
//      {
//        // A = ((Price) * 100) / (100 + this.ObjOutletInfo.TaxRate);
//        // this.VAT1 =  this. VAT1 -  ((Price) - A);

//        A = ((pricetotal) * 100) / (100 + this.ObjOutletInfo.TaxRate);
//        this.VAT1 =  ((pricetotal) - A);
//      }

//      // if (row.DisAmount)
//      //   this.DiscountAmont1 = this.DiscountAmont1 - (row.Price - row.DisAmount);
//      // if (row.DisAmount && this.ObjOutletInfo.IsTaxOnDiscountAmount == true)
//      // //   this.Subtoal1 = this.ObjMasterData1.TotalAmount - (A + (row.Price - row.DisAmount));
//      // else
//        // this.Subtoal1 = this.ObjMasterData1.TotalAmount - A;
//        this.Subtoal1 = pricetotal - this.VAT1;
//      this.Total1 = (parseFloat(this.Subtoal1.toFixed(2)) + parseFloat(this.VAT1.toFixed(2)) - this.ObjMasterData1.TotalDiscount);

//      if (this.IsExtraCharge == true) {
//        this.Subtoal1 = (this.Total1 - this.VAT1) / ((100 + this.ExtraChargePercentage) / 100)

//        this.ExtraChargeAmount1 = (this.Subtoal1 * this.ExtraChargePercentage / 100)
//        this.Subtoal1 = +this._decimalPipe.transform(this.Subtoal1, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal);
//        this.ExtraChargeAmount1 = +this._decimalPipe.transform(this.ExtraChargeAmount1, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal);
//      }
//    }
//    this.Total1 = this._decimalPipe.transform(this.Total1, '1.' + this.NoOfDecimal + '-' + this.NoOfDecimal);
//    this.Subtoal1 = this._decimalPipe.transform(this.Subtoal1, '1.' + this.NoOfDecimal + '-' + this.NoOfDecimal);
//    this.VAT1 = this._decimalPipe.transform(this.VAT1, '1.' + this.NoOfDecimal + '-' + this.NoOfDecimal);
//    this.ModifiresAmount1 = 0;
//  }

//  CalculateTaxPlusEvent2(row) {debugger
//    var priceTotal = this.lst2.reduce(function(res,item) { return res + (item.Price * item.Quantity)}, 0);
//    priceTotal = priceTotal + this.ModifiresAmount2;

//    if (this.ObjOutletInfo.IsTaxInclusive == false) {
//      this.Subtoal2 = priceTotal;

//      if (this.IsExtraCharge == true) {
//        this.ExtraChargeAmount2 = (this.Subtoal2) * this.ExtraChargePercentage / 100;
//      }

//      this.SubtoalForVAT2 = parseFloat(row.DiscountPrice && this.ObjOutletInfo.IsTaxOnDiscountAmount == true ? (row.DiscountPrice > 0 ? row.DiscountPrice + this.ModifiresAmount2 : row.Price + this.ModifiresAmount2) : priceTotal);
//      this.VAT2 = ((this.SubtoalForVAT2 + this.ExtraChargeAmount2) * this.ObjOutletInfo.TaxRate) / 100;
//      if (row.DiscountPrice)
//        this.DiscountAmont2 += ((row.Price * row.Quantity) - row.DiscountPrice);
//      this.Total2 = (parseFloat(this.Subtoal2) + parseFloat(this.VAT2) + parseFloat(this.ExtraChargeAmount2));
//      // this.Total2 = (parseFloat(this.Subtoal2.toFixed(2)) + parseFloat(this.VAT2.toFixed(2)) - parseFloat(this.DiscountAmont2.toFixed(2)));
//    }
//    else {
//      let A = 0;
//      let price = row.DiscountPrice > 0 ? row.DiscountPrice + this.ModifiresAmount2 : row.Price + this.ModifiresAmount2;
    
//      if (this.ObjOutletInfo.IsTaxOnDiscountAmount == false) {
//        this.Subtoal2 = ((priceTotal) * 100) / (100 + this.ObjOutletInfo.TaxRate);
//        this.VAT2 = priceTotal - this.Subtoal2;
//      }
//      if (this.ObjOutletInfo.IsTaxOnDiscountAmount == true) {
//        A = ((priceTotal) * 100) / (100 + this.ObjOutletInfo.TaxRate);
//        this.VAT2 = (priceTotal) - A;
//      }
//      if (row.DiscountPrice)
//        this.DiscountAmont2 += ((row.Price * row.Quantity) - row.DiscountPrice);
//      if (row.DiscountPrice && this.ObjOutletInfo.IsTaxOnDiscountAmount == true)
//        this.Subtoal2 += A + ((row.Price * row.Quantity)- row.DiscountPrice);
//      else
//        this.Subtoal2 = A;
//      this.Total2 = (parseFloat(this.Subtoal2) + parseFloat(this.VAT2));
//      // this.Total2 = (this.Subtoal2 + this.VAT2 - this.DiscountAmont2);

//      if (this.IsExtraCharge == true) {
//        this.Subtoal2 = (this.Total2 - this.VAT2) / ((100 + this.ExtraChargePercentage) / 100)

//        this.ExtraChargeAmount2 = (this.Subtoal2 * this.ExtraChargePercentage / 100)
//        this.Subtoal2 = +this._decimalPipe.transform(this.Subtoal2, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal);
//        this.ExtraChargeAmount2= +this._decimalPipe.transform(this.ExtraChargeAmount2, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal);
//      }



//    }
//    this.Total2 = +this._decimalPipe.transform(this.Total2, '1.' + this.NoOfDecimal + '-' + this.NoOfDecimal);
//    this.Subtoal2 = +this._decimalPipe.transform(this.Subtoal2, '1.' + this.NoOfDecimal + '-' + this.NoOfDecimal);
//    this.VAT2 = +this._decimalPipe.transform(this.VAT2, '1.' + this.NoOfDecimal + '-' + this.NoOfDecimal);
//    this.ModifiresAmount2 = 0;
//  }
//  CalculateTaxMinusEvent2(row, index) {
//    debugger;
//    var pricetotal = this.lst2.reduce(function(res,item) { return res + (item.Price * item.Quantity)}, 0);
//    pricetotal = pricetotal+this.ModifiresAmount2;

//    if (this.ObjOutletInfo.IsTaxInclusive == false) {
//      this.Subtoal2 = pricetotal;

//      if (this.IsExtraCharge == true) {
//        this.ExtraChargeAmount2 = (this.Subtoal2) * this.ExtraChargePercentage / 100;
//      }

//      if (row.DisAmount && this.ObjOutletInfo.IsTaxOnDiscountAmount == true) {
//        this.SubtoalForVAT2 = this.SubtoalForVAT2 - row.DisAmount - this.ModifiresAmount2;
//      } else {
//        this.SubtoalForVAT2 = this.SubtoalForVAT2 - pricetotal - this.ModifiresAmount2;
//      }
//      this.VAT2 = ((this.SubtoalForVAT2 + this.ExtraChargeAmount2) * this.ObjOutletInfo.TaxRate) / 100;
//      if (row.DisAmount)
//        this.DiscountAmont2 = this.DiscountAmont2 - ((row.Price*row.Quantity) - row.DisAmount);
//      this.Total2 = (this.Subtoal2 + this.VAT2 + this.ExtraChargeAmount2);
//      // this.Total2 = (this.Subtoal2 + this.VAT2 - this.DiscountAmont2);
//    }
//    else {
//      let A = 0;
//      let price = row.DiscountPrice > 0 ? row.DiscountPrice + this.ModifiresAmount2 : row.Price + this.ModifiresAmount2;

//      if (this.ObjOutletInfo.IsTaxOnDiscountAmount == false) {
//        this.Subtoal2 = ((pricetotal) * 100) / (100 + this.ObjOutletInfo.TaxRate);
//        this.VAT2 = pricetotal - this.Subtoal2;
//      }
//      if (this.ObjOutletInfo.IsTaxOnDiscountAmount == true) {
//        A = ((pricetotal) * 100) / (100 + this.ObjOutletInfo.TaxRate);
//        this.VAT2 = (pricetotal) - A;
//      }
//      if (row.DiscountPrice)
//        this.DiscountAmont2 += ((row.Price * row.Quantity) - row.DiscountPrice);
//      if (row.DiscountPrice && this.ObjOutletInfo.IsTaxOnDiscountAmount == true)
//        this.Subtoal2 += A + ((row.Price * row.Quantity) - row.DiscountPrice);
//      else
//        this.Subtoal2 = A;
//      this.Total2 = (parseFloat(this.Subtoal2) + parseFloat(this.VAT2));
//      // this.Total2 = (this.Subtoal2 + this.VAT2 - this.DiscountAmont2);

//      if (this.IsExtraCharge == true) {
//        this.Subtoal2 = (this.Total2 - this.VAT2) / ((100 + this.ExtraChargePercentage) / 100)

//        this.ExtraChargeAmount2 = (this.Subtoal2 * this.ExtraChargePercentage / 100)
//        this.Subtoal2 = +this._decimalPipe.transform(this.Subtoal2, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal);
//        this.ExtraChargeAmount2 = +this._decimalPipe.transform(this.ExtraChargeAmount2, '1.' + this.NumOfDecimal + '-' + this.NumOfDecimal);
//      }
//    }
//    this.ModifiresAmount2 = 0;
//    this.Total2 = +this._decimalPipe.transform(this.Total2, '1.' + this.NoOfDecimal + '-' + this.NoOfDecimal);
//    this.Subtoal2 = +this._decimalPipe.transform(this.Subtoal2, '1.' + this.NoOfDecimal + '-' + this.NoOfDecimal);
//    this.VAT2 = +this._decimalPipe.transform(this.VAT2, '1.' + this.NoOfDecimal + '-' + this.NoOfDecimal);
//  }

  CalculateModifireAmount(row): number {
    let a = 0;
    for (var i = 0; i < row.length; i++) {

      if (row[i].Price != null && row[i].Price != undefined && row[i].IsSelected == true) {
        a += row[i].Price;
      }
    }

    return a;
  }

  ArrMasterData1: any = [];
  ArrSelectedDetailData1: any = [];


  list1Prepare(): void {
    debugger
    //////////////////////////////////////////////////////////////////////////LIST 1
    if (this.ArrMasterData1.length > 0)
      this.ArrMasterData1 = new Array();

    this.ArrMasterData1.push({
      'OrderDate': this.Utilities.GetCurrentDateTime(),
      'TotalAmount': this.ObjMasterData1.TotalAmount,
      'NetAmount': this.ObjMasterData1.NetAmount,
      'TotalDiscount': this.ObjMasterData1.TotalDiscount,
      'TotalTaxAmount': this.ObjMasterData1.TotalTaxAmount,
      'CompanyId': this.CompanyId,
      'CreatedBy': this.Utilities.getUserId(),
      'IsExtraCharge': this.IsExtraCharge,
      'ExtraChargeDescription': this.ExtraChargeDescription,
      'ExtraChargeDescriptionArabic': this.ExtraChargeDescriptionArabic,
      'ExtraChargeAmount': this.ExtraChargeAmount1
    });

    this.ArrSelectedDetailData1 = new Array();

    for (var i = 0; i < this.lst1.length; i++) {
        this.ArrSelectedDetailData1.push({
          'ProductId': this.lst1[i].ProductID,
          'Quantity': this.lst1[i].Quantity,
          'ProductCategoryID': this.lst1[i].ProductCategoryID,
          'ProductDetailID': this.lst1[i].ProductDetailID,
          'ProductSizeID': this.lst1[i].ProductSizeID,
          'AddOnID': null,
          'ProductModifireReferenceKey': this.lst1[i].rowid,
          'SpecialRequest': this.lst1[i].SpecialRequest,
          'TaxPercent': this.lst1[i].TaxPercent,
          'ExtraChargePercentage': this.lst1[i].ExtraChargePercentage,
          'ProductDiscount': this.lst1[i].ProductDiscount,
          'PriceWithOutVAT': this.lst1[i].PriceWithOutVAT,
          'DiffPriceWithOutVAT': this.lst1[i].DiffPriceWithOutVAT,
          'DiffTaxAmount': this.lst1[i].DiffTaxAmount,
          'DiffProductDiscount': this.lst1[i].DiffProductDiscount,
          'TaxAmount': this.lst1[i].TaxAmount,
        });

      for (var j = 0; j < this.lstmodifir1.length; j++) {
          if (this.lst1[i].ProductID == this.lstmodifir1[j].ProductID && this.lst1[i].rowid == this.lstmodifir1[j].rowid) {
            this.ArrSelectedDetailData1.push({
              'ProductId': this.lstmodifir1[j].ProductID,
              'Quantity': this.lst1[i].Quantity,
              'ProductCategoryID': this.lst1[i].ProductCategoryID,
              'ProductDetailID': this.lst1[i].ProductDetailID,
              'ProductSizeID': this.lst1[i].ProductSizeID,
              'AddOnID': this.lstmodifir1[j].ModifierId,
              'ProductModifireReferenceKey': this.lst1[i].rowid,
              'SpecialRequest': null,
              'TaxPercent': this.lst1[i].TaxPercent,
              'TaxAmount': this.lstmodifir1[j].TaxAmount,
              'PriceWithOutVAT': this.lstmodifir1[j].PriceWithOutVAT,
              'ProductDiscount': this.lstmodifir1[j].ProductDiscount
            });
          }
      }

      for (var k = 0; k < this.lstCombo1.length; k++) {
        debugger
        if (this.lstCombo1[k].MainProductID == this.lst1[i].ProductID && this.lstCombo1[k].rowid == this.lst1[i].rowid && this.lstCombo1[k].IsComboItem == 1) {
          debugger
          this.ArrSelectedDetailData1.push({
            'ComboProductId': this.lstCombo1[k].MainProductID == undefined ? this.lstCombo1[k].MainProductID : this.lstCombo1[k].MainProductID,
            'Price': this.lstCombo1[k].Price,
            'ProductId': this.lstCombo1[k].ProductId == undefined ? this.lstCombo1[k].ProductID : this.lstCombo1[k].ProductId,
            'Quantity': this.lst1[i].Quantity,
            'AddOnID': null,
            'TaxAmount': this.lst1[i].TaxAmount,
            'TaxPercent': this.lst1[i].TaxPercent,
            'ProductDetailID': this.lst1[i].ProductDetailID,
            'ProductDiscount': this.lst1[i].ProductDiscount,
            'PriceWithOutVAT': this.lst1[i].PriceWithOutVAT,
            'DiffPriceWithOutVAT': this.lst1[i].DiffPriceWithOutVAT,
            'DiffProductDiscount': this.lst1[i].DiffProductDiscount,
            'DiffTaxAmount': this.lst1[i].DiffTaxAmount,
            'ProductModifireReferenceKey': this.lstCombo1[k].rowid,

          });
        }
      }

    }

  }


  ArrMasterData2: any = [];
  ArrSelectedDetailData2: any = [];
  DeliveryFee: any = 0;
  rdPaymentMethod: any = 'Cash';
  SelectedBranch: any = '0';
  OrderType: any = '';
  OrderPerpStatus: any = 'New';
  DiscountCode: any = '';
  TimeStampSelected: any = '';

  list2Prepare(): void {
    debugger;
    if (this.ArrMasterData2.length > 0)
      this.ArrMasterData2 = new Array();

    this.ArrMasterData2.push({
      'DeliveryFee': this.DeliveryFee,
      'OrderDate': this.Utilities.GetCurrentDateTime(),
      'PayType': this.rdPaymentMethod,
      'OrderPayStatus': 'Pending',
      'OutletID': this.ObjOutletInfo.BranchID,
      'Channel': 'POS',
      'TotalAmount': this.ObjMasterData2.TotalAmount,
      'NetAmount': this.ObjMasterData2.NetAmount,
      'TotalDiscount': this.ObjMasterData2.TotalDiscount,
      'OrderType': this.ObjMasterData1.OrderType,
      'OrderPerpStatus': this.OrderPerpStatus,
      'DiscountCode': this.DiscountCode,
      'DeliveryTime': this.TimeStampSelected,
      'TaxID': '0',
      'TotalTaxAmount': this.ObjMasterData2.TotalTaxAmount,
      'CompanyId': this.CompanyId,
      'OrderTypeID': this.ObjMasterData1.OrderTypeID,
      'CreatedBy': this.Utilities.getUserId(),
      'IsExtraCharge': this.IsExtraCharge,
      'ExtraChargeDescription': this.ExtraChargeDescription,
      'ExtraChargeDescriptionArabic': this.ExtraChargeDescriptionArabic,
      'ExtraChargeAmount': this.ExtraChargeAmount2
    });

    this.ArrSelectedDetailData2 = new Array();

    for (var i = 0; i < this.lst2.length; i++) {
      this.ArrSelectedDetailData2.push({
        'ProductId': this.lst2[i].ProductID,
        'Quantity': this.lst2[i].Quantity,
        'AddOnID': null,
        'ProductCategoryID': this.lst2[i].ProductCategoryID,
        'ProductDetailID': this.lst2[i].ProductDetailID,
        'ProductSizeID': this.lst2[i].ProductSizeID,
        'ProductModifireReferenceKey': this.lst2[i].rowid,
        'SpecialRequest': this.lst2[i].SpecialRequest,
        'TaxPercent': this.lst2[i].TaxPercent,
        'ExtraChargePercentage': this.lst2[i].ExtraChargePercentage,
        'ProductDiscount': this.lst2[i].ProductDiscount,
        'PriceWithOutVAT': this.lst2[i].PriceWithOutVAT,
        'DiffPriceWithOutVAT': this.lst2[i].DiffPriceWithOutVAT,
        'DiffTaxAmount': this.lst2[i].DiffTaxAmount,
        'DiffProductDiscount': this.lst2[i].DiffProductDiscount,
        'TaxAmount': this.lst2[i].TaxAmount
        
      });

      for (var j = 0; j < this.lstmodifir2.length; j++) {
        if (this.lst2[i].ProductID == this.lstmodifir2[j].ProductID && this.lst2[i].rowid == this.lstmodifir2[j].rowid) {
          this.ArrSelectedDetailData2.push({
            'ProductId': this.lstmodifir2[j].ProductID,
            'Quantity': this.lst2[i].Quantity,
            'ProductCategoryID': this.lst2[i].ProductCategoryID,
            'ProductDetailID': this.lst2[i].ProductDetailID,
            'ProductSizeID': this.lst2[i].ProductSizeID,
            'AddOnID': this.lstmodifir2[j].ModifierId,
            'ProductModifireReferenceKey': this.lst2[i].rowid,
            'SpecialRequest': null,
            'TaxPercent': this.lst2[i].TaxPercent,
            'TaxAmount': this.lstmodifir2[j].TaxAmount,
            'PriceWithOutVAT': this.lstmodifir2[j].PriceWithOutVAT,
            'ProductDiscount': this.lstmodifir2[j].ProductDiscount
          });
        }
      }


      for (var k = 0; k < this.lstCombo2.length; k++) {
        debugger
        if (this.lstCombo2[k].MainProductID == this.lst2[i].ProductID && this.lstCombo2[k].rowid == this.lst2[i].rowid && this.lstCombo2[k].IsComboItem == 1) {
          debugger
          this.ArrSelectedDetailData2.push({
            'ComboProductId': this.lstCombo2[k].MainProductID == undefined ? this.lstCombo2[k].MainProductID : this.lstCombo2[k].MainProductID,
            'Price': this.lstCombo2[k].Price,
            'ProductId': this.lstCombo2[k].ProductId == undefined ? this.lstCombo2[k].ProductID : this.lstCombo2[k].ProductId,
            'Quantity': this.lst2[i].Quantity,
            'AddOnID': null,
            'TaxAmount': this.lst2[i].TaxAmount,
            'TaxPercent': this.lst2[i].TaxPercent,
            'ProductDetailID': this.lst2[i].ProductDetailID,
            'ProductDiscount': this.lst2[i].ProductDiscount,
            'PriceWithOutVAT': this.lst2[i].PriceWithOutVAT,
            'DiffPriceWithOutVAT': this.lst2[i].DiffPriceWithOutVAT,
            'DiffProductDiscount': this.lst2[i].DiffProductDiscount,
            'DiffTaxAmount': this.lst2[i].DiffTaxAmount,
            'ProductModifireReferenceKey': this.lstCombo2[k].rowid,

          });
        }
      }
    }
  }

  SplitBill(): void {
    this.list1Prepare();
    this.list2Prepare();
    debugger
   
    const body = [this.ArrMasterData1, this.ArrSelectedDetailData1, this.ArrMasterData2, this.ArrSelectedDetailData2, this.ObjMasterData1.OrderID, this.CompanyId];

    this._spinnerService.show();
    this.dataservice.post('pos/SaveSplitBill/', body)
      .subscribe(result => {
        debugger
        if (result) {
          if (this.isArrayHasValue(result[0]['SavedData'])) {
            this.popup.ShowPopup('Insert');
            this.dialogRef.close('splitBill');
          } else {
            this.popup.ShowPopup('Error');
          }

        }
        else {
          this.popup.ShowPopup('Error');
        }
        this._spinnerService.hide();

      }, error => alert('response error'));
  }


  isArrayHasValue(obj: any[]): boolean {
    let isArrayWithLength = Boolean(Array.isArray(obj) && obj.length);
    return isArrayWithLength;
  }
}

class Items {
  AddOnID: any;
  Amount: any;
  DisAmount: any;
  DiscountPrice: any;
  IsSelected: any;
  NetAmount: any;
  OrderID: any;
  OrderInvoiceNo: any;
  Price: any;
  ProductID: any;
  ProductName: any;
  Quantity: any;
  TableNumber: any;
  TaxAmount: any;
  TotalAmount: any;
  TotalDiscount: any;
  TotalTaxAmount: any;
  rowid: any;
  pk: any;
  ProductCategoryID;
  ProductDetailID;
  ProductSizeID;
  AProductName: any;
  ProductSizeName: any;
  SpecialRequest: any;
  DelFlag: boolean;
  TaxRate: any;
  ExtraChargePercentage: any;
  IsExtraCharge: boolean;

  ComboProductId: any;
  TaxPercent: any;
  ProductDiscount: any;
  PriceWithOutVAT: any;
  DiffPriceWithOutVAT: any;
  DiffProductDiscount: any;
  DiffTaxAmount: any;
}
class MasterData {
  OrderID: any;
  OrderInvoiceNo: any;
  TableNumber: any;
  TaxAmount: any = 0;
  TotalAmount: any = 0;
  TotalDiscount: any = 0;
  TotalTaxAmount: any = 0;
  NetAmount: any = 0;
  OrderType : any;
  OrderTypeID: any;
  ExtraChargeAmount: any;
}

class ModifierList {
  AllowMultipleSeletion: any;
  DetailId: any;
  IsSelected: any;
  ListName: any;
  MasterId: any;
  MaxModifiers: any;
  MinModifiers: any;
  ModifierId: any;
  Modifires: any;
  Price: any;
  PriceStatic: any;
  ProductID: any;
  rowid: any;
  TaxAmount: any;
  PriceWithOutVAT: any;
  ProductDiscount: any;
  IsBogo: any;
}
class OutletInfo {
  BranchID: any = 0;
  BranchName: any = '';
  CityId: any = 0;
  ImageName: any = '';
  TaxRate: any = 0;
  TaxID: any = 0;
  IsTaxInclusive: boolean = false;
  IsTaxOnDiscountAmount: boolean = false;
}
